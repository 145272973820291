@mixin transparent($color, $alpha) {
    $rgba: rgba($color, $alpha);
    $ie-hex-str: ie-hex-str($rgba);
    background-color: transparent;
    background-color: $rgba;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
    zoom: 1;
  }
  
@mixin wh($w, $h: null) {
    width: $w;
    @if($h == null) { 
        height: $w; 
    } @else { 
        height: $h; 
    }
}

// responsive div width mixin
@function cp($px, $max: 1280px) {
    @return $px/$max * 100%;
}  
  
@mixin general-breakpoint($break, $type: min) {
    @if $type == min {
        @if $break == mobile {
            // 320px - xxsmall WindowSize
            @media only screen and (min-width: 20em) {
                @content;
            }
        } @else if $break == lgmobile {
            // 375px - Robin added this because fan week sucks at 320px
            @media only screen and (min-width: 375px) {
                @content;
        }
        } @else if $break == landscape {
            // 640px - xsmall WindowSize
            @media only screen and (min-width: 40em) {
                @content;
            }
        } @else if $break == smtablet {
            // 768px - small WindowSize
            @media only screen and (min-width: 48em) {
                @content;
            }
        } @else if $break == tablet {
            // 960px - smalllg WindowSize
            @media only screen and (min-width: 60em) {
                @content;
            }
        } @else if $break == smdesktop {
            // 1024px - medium WindowSize
            @media only screen and (min-width: 64em) {
                @content;
            }
        } @else if $break == desktop {
            // 1280px - large WindowSize
            @media only screen and (min-width: 80em) {
                @content;
            }
        } @else if $break == lgdesktop {
            // 1440px --- new! inherited from breakpoint(xlarge) px, xlarge WindowSize
            @media only screen and (min-width: calc((1440/16) * 1em)) {
                @content;
            }
        } @else if $break == xldesktop {
            // 1600px - xxlarge WindowSize
            @media only screen and (min-width: 100em) {
                @content;
            }
        }
    } @else if $type == max {
        @if $break == mobile {
            // 319px
            @media only screen and (max-width: 19.9375em) {
                @content;
            }
        } @else if $break == lgmobile {
            // 374px
            @media only screen and (max-width: 23.375em) {
                @content;
            }
        } @else if $break == landscape {
            // 639px
            @media only screen and (max-width: 39.9375em) {
                @content;
            }
        } @else if $break == smtablet {
            // 767px
            @media only screen and (max-width: 47.9375em) {
                @content;
            }
        } @else if $break == tablet {
            // 959px
            @media only screen and (max-width: 59.9375em) {
                @content;
            }
        } @else if $break == smdesktop {
            // 1023px
            @media only screen and (max-width: 63.9375em) {
                @content;
            }
        } @else if $break == desktop {
            // 1279px
            @media only screen and (max-width: 79.9375em) {
                @content;
            }
        } @else if $break == lgdesktop {
            // 1439px --- new! inherited from breakpoint(xlarge) px
            @media only screen and (min-width: calc((1439/16) * 1em)) {
                @content;
            }
        } @else if $break == xldesktop {
            // 1599px
            @media only screen and (max-width: 99.9375em) {
                @content;
            }
        }
    }
}

/** [TODO] When 2022 design breakpoints are confirmed,
*   update the section using @include breakpoint(medium)  
*   to use general-breakpoint(<new_breakpoint>)
*/
@mixin breakpoint($break, $type : min) {
    @if $type == min {
      @if $break == medium { // 992px
        @media only screen and (min-width: calc((992/16) * 1em)) { @content; } 
      }
    } @else if $type == max {
      @if $break == medium { // 991px
        @media only screen and (max-width: calc((991/16) * 1em)) { @content; }
      }
    }
  }

@mixin draws-breakpoint($break, $type: min) {
    @if $type == min {
        @if $break == xsmall {
            // 320px
            @media only screen and (min-width: 320px) {
                @content;
            }
        } @else if $break == small {
            // 640px
            @media only screen and (min-width: 640px) {
                @content;
            }
        } @else if $break == medium {
            // 768px
            @media only screen and (min-width: 768px) {
                @content;
            }
        } @else if $break == large {
            // 1024px
            @media only screen and (min-width: 1024px) {
                @content;
            }
        } @else if $break == mxlarge {
            // 1140px
            @media only screen and (min-width:1140px) {
                @content;
            } //robin made this up for draws text overlap (medium-extra-large). DC adjusted for ipad webview
        } @else if $break == xlarge {
            // 1601px
            @media only screen and (min-width: 1601px) {
                @content;
            }
        } @else if $break == xxlarge {
            // 1920px
            @media only screen and (min-width: 1940px) {
                @content;
            }
        } @else if $break == sidepanel {
            // 1366px
            @media only screen and (min-width: 1366px) {
                @content;
            } // specifically for minimum width at which optional sidepanel is visible
        } @else {
            @media only screen and (min-width: $break) {
                @content;
            }
        }
    } @else if $type == max {
        @if $break == xsmall {
            // 319px
            @media only screen and (max-width: 319px) {
                @content;
            }
        } @else if $break == small {
            // 639px
            @media only screen and (max-width: 639px) {
                @content;
            }
        } @else if $break == medium {
            // 767px
            @media only screen and (max-width: 767px) {
                @content;
            }
        } @else if $break == large {
            // 1023px
            @media only screen and (max-width: 1023px) {
                @content;
            }
        } @else if $break == xlarge {
            // 1600px
            @media only screen and (max-width: 1600px) {
                @content;
            }
        } @else {
            @media only screen and (max-width: $break) {
                @content;
            }
        }
    }
}

@mixin scores-ad-breakpoint($break, $type: min) {
    @if $type == min {
        @if $break == small {
            // 320px
            @media only screen and (min-width: 320px) {
                @content;
            }
        } @else if $break == medium {
            // 768px
            @media only screen and (min-width: 768px) {
                @content;
            }
        } @else if $break == large {
            // 1024px
            @media only screen and (min-width: 1024px) {
                @content;
            }
        } 
    } @else if $type == max {
        @if $break == small {
            // 319px
            @media only screen and (max-width: 319px) {
                @content;
            }
        } @else if $break == medium {
            // 767px
            @media only screen and (max-width: 767px) {
                @content;
            }
        } @else if $break == large {
            // 1023px
            @media only screen and (max-width: 1023px) {
                @content;
            }
        } 
    }
}

//border radius mixin
@mixin border-radius-all($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

/** mixin for caret up and down used mainly for menu collapse and expand 
 *  - position absolute is used for mobile menu
 */
@mixin caret-down($color: $dk-gray2, $size: 4px, $position: relative, $topPos: 25px, $rightPos: 30px) {
    display: inline-block;
    content: '';
    // width: calc(#{$size} * 2);
    // height: 0px;
    border-top: $size solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: none;
    margin-left: 5px;
    position: $position;
    transform: translateY(-2px);
    
    @if $position == absolute {
        top: $topPos;
        right: $rightPos;
    }
}

@mixin caret-up($color: $dk-gray2, $size: 4px) {
    border-top:none;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom:  $size solid $color;
}

@mixin slick-slider-arrows {

    .slick-arrow{
        @include uso-icon('Icons');
        &.slick-prev {
            left: 0px;
            height: 100%;
            // background-image: linear-gradient(to left, rgba(255,0,0,0), rgba(255, 255, 255, 1) 60%);
            background: linear-gradient(to right, #ffffff, rgba(#ffffff, 0));
            z-index: 1;
            text-align: left;
            width: 45px;
            &::before{
                font-size: px2rem(14);
                content: "\e901";
                color: $usoDkGray;
                height: 100%;
            }
        }
        &.slick-next{
            right: 0px;
            height: 100%;
            // background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255, 255, 255, 1) 60%);
            background: linear-gradient(to left, #ffffff, rgba(#ffffff, 0));
            text-align: right;
            width: 45px;
            &::before{
                font-size: px2rem(14);
                content: "\e902";
                color: $usoDkGray;
                height: 100%;
            }
        }
        &.slick-disabled {
            display: none !important;
            &:hover {
                cursor: default;
            }
        }
    }
}

/**
* button mixin for buttons defined in the colors, components, and grids figma file.
* mixin does not account for any icons needed for the buttons, just colors, font, 
* border-radius, and hovers.  Param to be passed is the button style - currently
* defined styles are listed below.
*
* styles:
*   basic - blue button with with white text/white hover with blue text and blue outline
*   livescores - dark blue button with white text/white hover with dark blue text an darkblue outline
*   livemedia - white button with gray text and gray outline/dark blue hover with white text/gray disabled state
*   yellow - yellow button with blue text/white hover with blue text and blue outline
*   hero - dark blue button with bold white text/white hover with dark blue text
*
**/
@mixin button($style: basic) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    @include interstate;
    text-transform: uppercase;
    width: 280px;
    height: 40px;
    min-height: 40px;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: $white;
        color: #2478CC;
        border: 2px solid #2478CC;
        a {color: #2478CC;}
    }
    @if $style == basic {
        background-color: #2478CC;
        color: $white;
       a { color: $white; 
            &:hover {color: #2478CC;}
        }

        &.disabled {
            opacity: .5;
            background-color: #2478CC;
            color: $white;
            border:none;
            &:hover {
                border:none;
                background-color: #2478CC;
                color: $white;
                a {color: $white;
                    &:hover {color: $white;}
                }
            }
        }
    }
    @else if $style == oppbasic {
        background-color: $white;
        color: #2478CC;
        border: 2px solid #2478CC;
        &:hover {
            color: $white; 
            background-color:#2478CC;
        }
       a { color: #2478CC; 
            &:hover {color: $white; background-color:#2478CC;}
        }
    }
    @else if $style == yellow {
        background-color: #FFD400;
        color: #2C4B99;
    }
    @else if $style == livescores {
        background-color: #00288C;
        color: $white;

        &:hover {
            color: #00288C;
            background-color: $white;
            border: 2px solid #00288C;
        }
    }
    @else if $style == livemedia {
        background-color: $white;
        border: 1px solid #6F6F6F;
        color: #4A4A4A;

        &:hover {
            color: $white;
            background-color: #00288C;
            border: 1px solid #00288C;
        }
        &.disabled {
            color: #ACB3B5;
            background-color: #E0E2E3;
            border: 1px solid #6F6F6F;
            cursor: default;
        }
    }
    @else if $style == hero {
        background-color: #00288C;
        color: $white;
        @include interstate(bold);
        font-size: px2rem(18);
        text-transform: unset;

        &:hover {
            color: #00288C;
            background-color: $white;
            border: 2px solid #00288C;
        }
    }
}