$delay: 3s;
$delay1: 1.7s + $delay;
$delay2: 1.7s + ($delay * 2);

/** match insights **/
.MITeaser {
    // margin-top: -45px;

    @include general-breakpoint(smtablet) {
        // margin-top: -155px;
    }

    @include general-breakpoint(smdesktop) {
        // margin-top: -155px;
    }
}

// /** for slamtracker  **/
// .slamtracker-powerranking{
//     margin-bottom: 10px;

//     .mi-alert{
//         .watson-fader-container{            
//             @include general-breakpoint(smtablet, max){
//                 top: 5px;
//                 width: 152px;
//             }
//             @include general-breakpoint(smtablet){
//                 top: 8px;
//             }
//             @include general-breakpoint(smdesktop){
//                 top: 14px;
//             }            
//             width: 200px;
//             height: 32px;
//         }
//         .watson-logo{
//             margin-bottom: -6px;
//             @include general-breakpoint(smtablet, max){
//                 margin-bottom: -20px;
//             }
//             &::before{
//                 width: 35px;
//                 height: 35px;
//             }
//             &::after{
//                 font-size: 11px;
//             }
//         }
//     }
// }

.likelihood-win-btn {
    width: 150px;
    margin: 0 auto 16px auto;
    
    .fader-container{
        position: absolute;
        overflow: hidden;
        width: 150px;
        height: 70px;
        top: -16px;
        left: 50%;
        // transform: translateX(-50%);
        z-index: 100;
        @include general-breakpoint(smtablet){
            top: -23px;
        }
        @include general-breakpoint(smdesktop){
            width: 380px;
        }
        // .fader{
        //     height: 60px;
        //     position: absolute;
        //     width: 530px;
        //     z-index: 900;
        //     top: 0px;
        //     @include general-breakpoint(smtablet){
        //         width: 590px;                
        //     }
        //     @include general-breakpoint(smdesktop){
        //         width: 650px;
        //     }
        //     &.no-upset{
        //         &.right{
        //             animation: slideRightSm 0.8s forwards;
        //             animation-delay: $delay1;
        //             @include general-breakpoint(smtablet){
        //                 animation: slideRightMd 0.8s forwards;
        //                 animation-delay: $delay1;
        //             }
        //             @include general-breakpoint(smdesktop){
        //                 animation: slideRightLg 0.8s forwards;
        //                 animation-delay: $delay1;
        //             }   
        //         }
        //         &.left{
        //             animation: slideLeftSm 0.8s forwards;
        //             animation-delay: $delay1;
        //             @include general-breakpoint(smtablet){
        //                 animation: slideLeftMd 0.8s forwards;
        //                 animation-delay: $delay1;
        //             }
        //             @include general-breakpoint(smdesktop){
        //                 animation: slideLeftLg 0.8s forwards;
        //                 animation-delay: $delay1;
        //             }    
        //         }
        //     }
        //     &.upset{
        //         &.right{
        //             animation: slideRightSm 0.8s forwards;
        //             animation-delay: $delay2;
        //             @include general-breakpoint(smtablet){
        //                 animation: slideRightMd 0.8s forwards;
        //                 animation-delay: $delay2;
        //             }
        //             @include general-breakpoint(smdesktop){
        //                 animation: slideRightLg 0.8s forwards;
        //                 animation-delay: $delay2;
        //             }   
        //         }
        //         &.left{
        //             animation: slideLeftSm 0.8s forwards;
        //             animation-delay: $delay2;
        //             @include general-breakpoint(smtablet){
        //                 animation: slideLeftMd 0.8s forwards;
        //                 animation-delay: $delay2;
        //             }
        //             @include general-breakpoint(smdesktop){
        //                 animation: slideLeftLg 0.8s forwards;
        //                 animation-delay: $delay2;
        //             }    
        //         }
        //     }
            
        //     &.right{
        //         background: rgb(2,0,36);
        //         background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
        //         right: 0px;                             
        //     }
        //     &.left{
        //         background: rgb(255, 255, 255);
        //         background: linear-gradient(90deg, rgba(255,255,255,1) 50%,  rgba(255,255,255,0) 100%);
        //         left: 0px;          
        //     }
        // }
    }

    .btn {
        position: relative;
        margin: 0 auto;
        width: calc(429px * .35);
        height: calc(296px * .35);
        padding: 0;

        img {
            width: 100%;
            height: 100%;
        }

        @include interstate(medium);
    }

    @include general-breakpoint(smtablet) {
        width: calc(690px * .20);
        margin: 0 auto 40px auto;

        .btn {
            width: calc(526px * .27);
            height: calc(176px * .27); 
            
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    @include general-breakpoint(smdesktop) {
        width: calc(854px * .32);

        .btn {
            width: calc(526px * .54);
            height: calc(176px * .54); 
        }
    }
}

.MITeaser {
    position: relative;
}

.mi-alert {
    width: 150px;
    margin: 0 auto 14px auto;
    height: 24px;
    background-color: $likelihoodwin-purple;
    color: $white;
    justify-content: center;
    z-index: 101;

    h4 {
        @include interstate(bold);
        font-size: px2rem(14);
        text-transform:uppercase;
        margin: 0;
        line-height: 24px;
    }

    @include general-breakpoint(smtablet) {
        margin: 0 auto 10px auto;
    }

    &.upset{
        display: flex;

        @include general-breakpoint(smtablet) {
            visibility: visible;
            margin-bottom: 20px;
        }
    }
    &.no-upset{
        display: none;

        @include general-breakpoint(smtablet) {
            display: flex;
            visibility: hidden;
            margin-bottom: 12px;
        }
    }
}

/** Slamtracker page override 
    - btn sizing
*/
.slamtracker {
    .likelihood-win-btn {
        width: 150px;
        margin: 0 auto 16px auto;

        .fader-container{
            position: absolute;
            overflow: hidden;
            width: 150px;
            height: 70px;
            top: -16px;
            left: 50%;
            z-index: 100;
            @include general-breakpoint(smtablet){
                top: -23px;
            }
            @include general-breakpoint(smdesktop){
                width: 380px;
            }
        }
        .btn {
            position: relative;
            margin: 0 auto;
            width: calc(429px * .35);
            height: calc(296px * .35);
        }
    }
    @include general-breakpoint(smtablet) {
        .likelihood-win-btn {
        } // .likelihood-win-btn

        .mi-alert {
            &.upset{
                display: flex;
                margin-bottom: 20px;
        
                @include general-breakpoint(smtablet) {
                    // display: flex;
                }
            }
            &.no-upset{
                display: none;
                margin-bottom: 30px;
        
                @include general-breakpoint(smtablet) {
                    // display: flex;
                    // visibility: hidden;
                    margin-bottom: 30px;
                }
            }
        } // .mi-alert
        .mi-pr-projection {
            font-size: px2rem(14);
        }
    }
}

/** animation stuff **/

/** logo **/
@keyframes watson-shutter{
    0% { left: 0px; }
    50% { left: -1030px; }
    100% { left: 0px; }
}

@-webkit-keyframes watson-shutter{
    0% { left: 0px; }
    50% { left: -1030px; }
    100% { left: 0px; }
}

@keyframes upset-alert{
    0% { content: "Upset Alert"}
    100% { content: "Upset Alert"}
}

@-webkit-keyframes upset-alert{
    0% { content: "Upset Alert"}
    100% { content: "Upset Alert"}
}

@keyframes logo-loop {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

@-webkit-keyframes logo-loop {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

/** pill */

@-webkit-keyframes slideRightSm {
    0% { right: 0px; }
    100% { right: -530px; }
}

@keyframes slideRightSm {
    0% { right: 0px; }
    100% { right: -530px; }
}

@-webkit-keyframes slideLeftSm {
    0% { left: 0px; }
    100% { left: -530px; }
}

@keyframes slideLeftSm {
    0% { left: 0px; }
    100% { left: -530px; }
}

/** md **/
@-webkit-keyframes slideRightMd {
    0% { right: 0px; }
    100% { right: -590px; }
}

@keyframes slideRightMd {
    0% { right: 0px; }
    100% { right: -590px; }
}

@-webkit-keyframes slideLeftMd {
    0% { left: 0px; }
    100% { left: -590px; }
}

@keyframes slideLeftMd {
    0% { left: 0px; }
    100% { left: -590px; }
}

/** lg **/
@-webkit-keyframes slideRightLg {
    0% { right: 0px; }
    100% { right: -650px; }
}

@keyframes slideRightLg {
    0% { right: 0px; }
    100% { right: -650px; }
}

@-webkit-keyframes slideLeftLg {
    0% { left: 0px; }
    100% { left: -650px; }
}

@keyframes slideLeftLg {
    0% { left: 0px; }
    100% { left: -650px; }
}

/** dot **/

@-webkit-keyframes dot{
    0% { top: -30px; opacity: 0; }
    100% { top: 0px; opacity: 1; }
}

@keyframes dot{
    0% { top: -30px; opacity: 0; }
    100% { top: 0px; opacity: 1; }
}
