.filters {
	color: $white;
	text-align: center;
	padding: 0;
	background-color: $usoDkBlue;

	.selection {
		@include interstate(light);
		font-size: px2rem(12);
		display: inline-block;
		line-height: 2.2rem;
		color: $white;
		cursor: pointer;
		margin-bottom: 0;
		padding-bottom: 5px;

		.photos.active {
			color: $white;
			font-weight: bold;
			position: relative;
			text-decoration: none;

			&:after {
				content: '';
				border-bottom: solid 3px $white;
				position: absolute;
				bottom: -11px;
				left: 0;
				width: 100%;
			}
		}

		div {
			display: inline-block;
			&.radio {
				&:after {
					border: none;
				}
			}
		}
		.photos {
			color: $white;
			// padding: 10px 0 20px 0;
			margin: 0 20px;
			line-height: 16px;

			span {
				display: block;
			}
		}
	}

	&.third {
		display: block;
		background-color: $usoltMedGray;
		padding: 5px;
	}

	.news-dropdown-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		.filter-dropdown-years,
		.filter-dropdown-months {
			line-height: 2.2rem;
			select {
				@include interstate();
				border-radius: 3px;
				color: $usoLtBlue;
				border: 1px solid $usoLtBlue;

				appearance: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				background-image: url('/assets/images/icons/dropdown-arrow.svg');
				background-repeat: no-repeat;
				background-position-x: 91%;
				background-position-y: 50%;
			}
		}
		.filter-dropdown-years select {
			padding: 0 20px 0 15px;
			text-align: center;
			margin-right: 10px;
		}
		.filter-dropdown-months select {
			padding: 0 20px 0 8px;
			text-align: center;
		}
	}

	.filter-dropdown {
		@include interstate(regular);
		font-size: px2rem(14);
		line-height: 2.2rem;
		display: inline-block;
		vertical-align: top;
		height: inherit;
		float: none;
		width: auto;
		border: 1px solid $bright-blue;
		box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.23);
		border-radius: 4px;
		background-repeat: no-repeat;
		background-position: right 10px center;
		background-size: 9px;
		padding-right: 25px;
		background-color: $white;
		background-image: url(/assets/images/nav/carrot-down.png) !important;
		margin-left: 10px;
		margin-bottom: 5px;

		select {
			@include interstate(regular);
			font-size: px2rem(12);
		}

		.photos {
			display: none;
		}

		div {
			background: transparent;
			width: auto;
			padding: 5px;
			font-size: 14px;
			line-height: 16px;
			border: 0;
			border-radius: 0;
			height: 45px;
			-webkit-appearance: none;
			-moz-appearance: none;
			padding-left: 50px;
			color: #ffffff;
			outline: none;
		}
	}
} // filters
