#summary-scoreboard-wrapper {
    width: 100%;
    height: auto;

    .error-indicator {
        background-color: $white;
        width: 100%;
        height: 104px;
        // line-height: 104px;
        text-align: center;
        padding: 20px;
        font-size: 14px;
        @include interstate(light);
    }

    .favorites-toggle {
        font-size: px2rem(11);

        span {
            top: unset;
        }

        &:before, &:after {
            font-size: px2rem(16);
            margin-left: 10px;
        }

        &.on {
            color: $usoDkGray;

            &:before, &:after {
                font-size: px2rem(16);
                margin-left: 10px;
            }

            &.outline {
                &:after {
                    top: 5px;
                }
            }
        }
    }

    .link {
        a {
            color: $usoDkGray;
            font-size: px2rem(12);
            text-decoration: none;
            @include interstate(regular);
        }
    }
    .ssb-favorites{ display: flex; flex-direction: row; flex-wrap: nowrap; margin: 0px 10px 0px 15px;
        .favorites-button-wrapper{ width: auto;}
        .favorites-toggle{ height: auto; width: 25px;
            &:before{ top: 0px; left: 0px;margin-left: 5px; }
            &.on.outline{
                &:after{ top: 0px; margin-left: 5px;}
            }
            @include general-breakpoint(smdesktop){width: 15px;}
            @include general-breakpoint(lgdesktop){width: 25px;}
        }
        .view-favorites-button{ cursor: pointer; text-transform: uppercase;}
    }

    #summary-scoreboard {
        // width:calc(100% - 66px);
        width: 100%;
        height: 100%;
        margin: 0px auto;
        position: relative;
        background-color: $usoBkgGray;

        &.stub {
            text-align: center;
            padding: 20px 45px;
            color: $usoMdGray;
            @include interstate();
            font-size: 14px;
        }

        .sumscoresSlide {
            position: relative;

            &.slick-slider {
                @include slick-slider-arrows;

                .slick-arrow{
                    &.slick-prev {
                        left: 0;
                    }
                    &.slick-next{
                        right: 0;
                    }
                }
            }
        }
        
        .rolex-slide {
            height: 104px;
            min-width: 274px;
            text-align: center;
            padding: 5px 10px 0px;
            border: solid 1px #979797;
            border-left: none;

            .schedule-link {
                height: 20px;
                line-height: 20px;
                background-color: #D8D8D8;
                font-size: 14px;
                text-transform: uppercase;
                margin-bottom: 4px;
                
                a {
                    display: flex;
                    justify-content: space-between;
                    padding-right: 10px;
                    padding-left: 15px;
                    color: #4A4A4A;
                    &:hover {text-decoration: none;}
                }
                i {
                    font-size: 12px;
                    height: 20px;
                    line-height: 20px;
                }
            }
            .rolex-clock {
                margin: 0px auto;
            }
        }
       
        .match-container {
            position: relative;

            .hover-container {
                display: none; 
                width: 274px; 
                // height:104px;
                position: absolute;
                top: 0; 
                left: 0; 
                background: #418FDE;
                color: $white;
                border:solid 1px $white;
                padding: 10px;
                cursor: pointer;

                &.hide {display: none;}
                &.show {display: block;}
                &:after {
                    content: "\e908";
                    @include uso-icon('Icons');
                    color: #FFFFFF;
                    position: absolute;
                    top: 47px;
                    right: 22px;
                    font-size: 11px;
                }
                a {
                    color: $white;
                    text-decoration: none;
                    &:hover {
                        text-decoration: none;
                    }
                }
                .inner-container {
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    background: rgba(255,255,255,.15);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .line-1 {@include interstate(light); font-size: 18px;}
                    .line-2 {@include interstate(bold); font-size: 24px;}
                }
                &.match-insights {
                    background: $gray_d8;
                    color: $gray_4a;

                    &:after {
                        color: $gray_4a;
                    }
                }
            }
        }
        
        .match-box-container {
            width: 296px;
            // height:104px;
            padding: 6px 10px 6px;
            background-color: $usoBkgGray;

            @include general-breakpoint(smdesktop) {
                width: 100%;
            }
        }
        .match-box .match-status {
            height:100%;

            .event-info {
                .event-status{width: 40%; text-align: right; align-self: center; text-transform: normal;}
                .event-court {width: 60%; align-self: center;}
            }

            .team-info-wrapper {
                // box-shadow: 0 0 10px 1px rgba(0,0,0,0.10);
            }
            
            .team-info {
                border: none;
                display: flex;
                justify-content: space-between;
                background: $white;
                border: solid 1px $usoltMedGray;
                .team-name {
                    width: calc(100% - 120px)
                }
                .match-results {
                    display: flex;
                    flex: 0 0 118px;
                    justify-content: flex-end;
                    .results-wrapper {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
                &.team-one,&.team-two {
                    .match-results {
                        .results-wrapper {
                            .crticon {
                                // margin:13px auto 0 auto;
                                // font-size:11px;
                            }
                        }
                    }
                }

                &:last-of-type {
                    border-top: none;
                }
            } // .team-info
        }    
    }
    .slick-track {
        display: flex;
    }

    /** override what's in media-wall style just for Sum Scores */
    .slick-slider .slick-list {
        height: auto;
    }
}
