/** styles for BlueBar component **/
.blue-bar-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #001d67;
	padding: 23px 28px 22px 28px;
	font-size: 14px;
}

.events-wrapper {
	position: relative;
	width: 65%;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	white-space: nowrap;
	@media screen and (min-width: 1025px) {
		width: 75%;
	}
}
.empty {
	justify-content: center;
}

.event-name {
	list-style-type: none;
	color: #fff;
	cursor: pointer;
	margin-right: 16px;
	line-height: 1;

	&:last-child {
		margin-right: 0;
	}
	&.active {
		text-decoration: underline;
		text-underline-offset: 24px;
		text-decoration-thickness: 3px;
		font-weight: 700;
		color: #fff;
	}

	&::after {
		content: attr(data-text);
		content: attr(data-text) / '';
		height: 0;
		display: block;
		visibility: hidden;
		overflow: hidden;
		user-select: none;
		pointer-events: none;
		font-weight: 700;
	}

	&:hover {
		color: #fff;
		font-weight: 700;
	}
}

.select-dropdown-wrapper {
	position: relative;
	background-color: #00288c;
	border: 1px solid #979797;
	font-weight: 700;
	color: #fff;
	width: auto;
	width: 100%;
	margin-left: 4rem;
	border-radius: 4px;
	box-sizing: border-box;
}

/** styles for BlueBarMenu component **/
.blue-bar-menu-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #001d67;
	font-size: 12px;
	padding: 23px 2rem 22px 2rem;
	white-space: nowrap;
	a {
		&:hover {
			text-decoration: none;
		}
	}
	@include breakpoint(landscape) {
		font-size: 14px;
	}
}

.blue-bar-menu-event {
	list-style-type: none;
	color: #fff;
	cursor: pointer;
	padding: 0 1rem;

	&::after {
		content: attr(data-text);
		content: attr(data-text) / '';
		height: 0;
		display: block;
		visibility: hidden;
		overflow: hidden;
		user-select: none;
		font-weight: 700;
	}

	&:hover {
		text-decoration: none;
		color: #fff;
		font-weight: 700;
	}

	&.active {
		text-decoration: underline;
		text-underline-offset: 24px;
		text-decoration-thickness: 3px;
		font-weight: 700;
		color: #fff;
	}
	@media only screen and (max-width: 425px) {
		font-size: 12px;
	}
}

/** styles for BlueBarDropdown component **/
.blue-bar-dropdown-selected {
	cursor: pointer;
	background-color: #00288c;
	border: 1px solid #979797;
	border-radius: 4px;
	color: #fff;
	margin-left: 4rem;
	min-width: 10rem;

	@media only screen and (max-width: 1024px) {
		width: 120px;
	}
}

/** styles for LightBlueBar component **/
.light-blue-bar-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #00288c;
	padding: 23px 2rem 21px 2rem;
	font-size: 14px;
	border-top: 1px solid #888b8d;
	min-height: 60px;
}
.lb-item-wrapper {
	a {
		&:hover {
			text-decoration: none;
		}
	}
}

.lb-events-wrapper {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	font-size: 12px;
}

.lb-event-name {
	list-style-type: none;
	color: #fff;
	cursor: pointer;
	padding: 0 1rem;

	&.active {
		text-decoration: underline;
		text-underline-offset: 23px;
		text-decoration-thickness: 3px;
		font-weight: 700;
		color: #fff;
	}

	&::after {
		content: attr(data-text);
		content: attr(data-text) / '';
		height: 0;
		display: block;
		visibility: hidden;
		overflow: hidden;
		user-select: none;
		pointer-events: none;
		font-weight: 700;
	}

	&:hover {
		color: #fff;
		font-weight: 700;
	}
}

.visit-select-dropdown-wrapper select {
	appearance: none;
}

.visit-select-menu {
	background-color: #00288c;
	color: #fff;
	border-radius: 3px;
	border-color: #fff;
	text-align: center;
	padding: 2px 7px 2px 4px;
	margin: 8px;
	width: 160px;
	text-align-last: center;
}

.visit-menu-arrow {
	position: absolute;
	font-size: 18px;
	line-height: 10px;
	display: inline-block;
	position: relative;
	top: 0px;
	right: 28px;
	&:after {
		content: '\25BE';
	}
	color: #fff;
}
