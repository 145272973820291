/** premium styles **/

.premium-section {
	@include media-breakpoint-down(xs) {
		// @include make-container;
	}
	.premium-content {
		@include make-row;
		border-bottom: 1px solid $usoLtGray;
		background-color: $usoBkgGray;
		// @include general-breakpoint(landscape) { padding-top: 20px;padding-bottom: 20px;}
		padding-top: 20px;
		padding-bottom: 20px;
		// padding-right: 32px;
		// padding-left: 32px;
		ul {
			color: $dk-gray3;
			padding: 10px 30px;
			margin-left: 0 !important;
			text-align: left;
			li {
				line-height: 1.4rem !important;
			}
		}
		.image {
			@include make-col(12);
			@include media-breakpoint-up(sm) {
				@include make-col-ready;
				@include make-col(7);
			}
			img {
				width: 100%;
				/* height: 212px; */
				/* @include general-breakpoint(landscape){ height: 425px; } */
				@include general-breakpoint(tablet) {
					height: auto;
				}
			}
			.video {
				@include media-breakpoint-up(sm) {
					margin-left: -15px;
					margin-right: -15px;
				}
				position: relative;
				&:before {
					display: block;
					content: '';
					width: 100%;
					padding-top: 56.25%;
				}
				.inlineVideo {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}
			h2 {
				font-size: 1.5rem;
				color: $dk-gray2;
				@include interstate(bold);
				padding: 5px 0;
				border: none;
				@include general-breakpoint(landscape) {
					font-size: 3rem;
					@include interstate(regular);
					padding: 10px 0;
				}
			}
			a.playVideo {
				display: block;
				cursor: pointer;
			}
		}
		.hosp-gallery {
			height: auto;
			@include make-col-ready();
			@include media-breakpoint-down(sm) {
				padding: 0;
			}
			@include media-breakpoint-up(sm) {
				@include make-col(7);
			}
			@include general-breakpoint(tablet) {
				width: 50%;
			}

			#related-photo-container {
				margin: 0;
			}
			#related-photo-nav-container {
				position: absolute !important;
				z-index: 1 !important;
				width: 100% !important;
				height: 50px;
				top: 17% !important;
				@include general-breakpoint(landscape) {
					width: 100% !important;
					top: 30% !important;
				}
				@include general-breakpoint(tablet) {
					width: 50% !important;
					top: 45% !important;
				}
				.prev {
					background: transparent url('/assets/images/misc/hosp_arrow_left_inline_gallery.png') no-repeat 0 0 !important;
					background-size: 30px;
					width: 30px !important;
					height: 30px !important;
				}
				.next {
					background: transparent url('/assets/images/misc/hosp_arrow_right_inline_gallery.png') no-repeat 0 0 !important;
					background-size: 30px;
					width: 30px;
					height: 30px;
				}
			}
			.bx-container {
				width: 100%;
				height: auto;
				.bx-wrapper {
					margin: 0 auto !important;
					img {
						max-width: auto !important;
						width: 100%;
					}
					.bx-viewport {
						left: 0 !important;
						border: 0 none !important;
						box-shadow: none !important;
					}
				}
			}
		}
		.text {
			@include make-col-ready;
			@include media-breakpoint-up(sm) {
				@include make-col(5);
			}
			@include interstate(light);
			font-size: 1.2rem;
			color: $md-gray;
			padding: 10px;
			@include general-breakpoint(landscape) {
				padding: 15px 30px;
				font-size: 1.4rem;
			}
			@include general-breakpoint(tablet) {
				padding: 0px 30px 20px;
			}
			h1,
			h2 {
				font-size: 1.5rem;
				color: $dk-gray2;
				@include interstate(bold);
				padding: 5px 0;
				border: none;
				@include general-breakpoint(landscape) {
					font-size: 3rem;
					@include interstate(regular);
					padding: 10px 0;
				}
			}
			p {
				padding-top: 0;
				@include interstate();
				line-height: 1.4em;
				color: $usoDkGray;
				font-size: 1.4rem;

				@include general-breakpoint(landscape) {
					line-height: 1.4em;
					@include interstate(light);
					// font-size: 1.4rem;
				}
			}
			.uso-button {
				// width: 130px;
				// height: 40px;
				margin: 15px auto;
				@include general-breakpoint(smtablet) {
					margin: 15px 0;
				}
			}
			.button-wrapper {
				width: 100%;
				display: inline-block;
				text-align: center;
				@include general-breakpoint(smtablet) {
					text-align: left;
				}
			}
		}
		&.subpage {
			background-color: $pale-yellow;
			color: $white;
			padding: 30px 10px;
			@include general-breakpoint(landscape) {
				padding: 30px;
			}
			h1 {
				color: $white;
			}
			ul {
				color: $white;
			}
			.hosp-gallery {
				#relatedPhotoNavContainer {
					width: 95% !important;
					@include general-breakpoint(landscape) {
						width: 90% !important;
					}
					@include general-breakpoint(tablet) {
						width: 47% !important;
					}
				}
			}
			.text {
				color: $white;
				@include general-breakpoint(landscape) {
					padding: 15px;
				}
			}
		}
		&.left {
			@include general-breakpoint(smdesktop) {
				.text {
					order: -1;
					padding-right: 5px;
					padding-left: 40px;
					@include media-breakpoint-up(xl) {
						padding-left: 8%;
					}
				}
			}
		}
		&.right {
			.text {
				@include media-breakpoint-up(xl) {
					padding-right: 8%;
				}
			}
		}
		&.button-under {
			margin-left: 0;
			margin-right: 0;
			.image {
				&:hover {
					a {
						width: 100%;
						display: block;
						overflow: hidden;
						img {
							width: 100%;
							transition: transform 0.5s ease;
							&:hover {
								transform: scale(1.15);
							}
						}
					}
				}
			}

			.button-wrapper {
				margin: 18px 15px 0;
			}
			.no-button {
				height: 40px;
				text-align: center;
				text-transform: uppercase;
				width: 100%;
				border-top: 1px solid $md-gray;
				border-bottom: 1px solid $md-gray;
				@include interstate;
				color: $dk-gray2;
				font-size: 16px;
				line-height: 40px;
			}
			.image {
				h2 {
					display: block;
					margin: 18px 15px 0;
				}
			}
			.text {
				padding-left: 15px;
				padding-right: 15px;
				font-size: 16px;

				h2 {
					display: none;
				}
				h3 {
					color: $dk-gray2;
					text-transform: uppercase;
					font-size: 18px;
					@include interstate();
					margin-bottom: 15px;
				}
				ul {
					padding-left: 17px;
					li {
						// padding-bottom: 20px;
					}
				}
				p {
					@include interstate(light);
				}
				> p {
					&:first-of-type {
						@include interstate(regular);
					}
				}
			}
			@include general-breakpoint(smtablet) {
				.image {
					h2 {
						display: none;
					}
				}
				.button-wrapper {
					margin: 18px 0 0;
				}
				.text {
					padding: 0 15px 0 6px;

					.image {
						max-width: 33%;

						h2 {
							display: none;
						}
					}
					h2 {
						display: block;
						font-size: 22px;
						margin-bottom: 0;
						padding-top: 0;
					}
					h3 {
						padding-top: 0;
						margin-bottom: 20px;
						@include interstate(light);
					}
				}
			}
			@include general-breakpoint(smdesktop) {
				.image {
					@include make-col(4);
				}
				.text {
					@include make-col(8);

					h2,
					h3 {
						display: inline-block;
						margin-bottom: 15px;
					}
					h2 {
						margin-right: 30px;
					}
				}
			}
		}
		.premium-content-video-button {
			@include button();
			width: 280px;
			height: 44px;
			font-size: 14px;
		}
	}
	&.left,
	&.right {
		.premium-content {
			margin-left: 0;
			margin-right: 0;

			@include general-breakpoint(smdesktop, max) {
				margin-bottom: 30px;

				.image,
				.text {
					max-width: 100%;
					flex: 0 0 100%;
				}
				.text {
					text-align: left;

					.button-wrapper {
						text-align: center;
					}
					@include general-breakpoint(landscape) {
						text-align: center;
					}
				}
			}
		}
	}
	&.centered {
		.premium-content {
			margin-left: 0;
			margin-right: 0;

			.image,
			.text {
				max-width: 100%;
				flex: 0 0 100%;
			}
			.text {
				text-align: left;

				.button-wrapper {
					text-align: center;
				}
				@include general-breakpoint(landscape) {
					text-align: center;
				}
			}
			&:nth-of-type(odd) {
				border-right: 1px solid #c1c6c8;
			}
		}
	}
	&.overlay {
		/*** 16x9 section w/ text overlay ***/
		width: 100%;
		position: relative;
		margin-top: 30px;

		@include media-breakpoint-up(xl) {
			padding-bottom: 0;
		}
		.premium-content {
			margin-bottom: 30px;
			margin-left: 0;
			margin-right: 0;

			.image-bg {
				width: 100%;
				background-size: cover;
				background-position: center;
				position: relative;
				background-image: none;
				img {
					width: 100%;
				}

				@include general-breakpoint(landscape) {
					width: calc(100% - 30px);
					margin: 0 15px;
				}
				.caption {
					background: $white;
					background-color: $usoBkgGray;
					@include make-col-ready;
					@include interstate(light);
					font-size: 1.2rem;
					color: $md-gray;
					padding: 10px;

					@include general-breakpoint(landscape) {
						text-align: center;
						padding: 15px 30px;
					}
					@include general-breakpoint(smtablet) {
						background: rgba(0, 40, 140, 0.85);
						color: $white;
						@include make-col-ready-abs-bottom;
						text-align: left;
					}
					h2 {
						font-size: 1.5rem;
						color: $dk-gray2;
						@include interstate(bold);
						padding: 5px 0;

						@include general-breakpoint(landscape) {
							font-size: 3rem;
							@include interstate(regular);
							padding: 10px 0;
						}
						@include general-breakpoint(smtablet) {
							color: $white;
							padding-left: 15px;
						}
					}
					.text {
						@include interstate(light);
						font-size: 1.2rem;
						width: 100%;
						padding-top: 10px;
						padding-bottom: 0;
						display: inline-block;
						color: $dk-gray2;
						@include make-col-ready;
						padding-left: 0;
						padding-right: 0;

						@include general-breakpoint(landscape) {
							@include make-col(12);
						}
						@include general-breakpoint(smtablet) {
							@include make-col(8);
							font-size: 1.4rem;
							color: $white;
						}
						@include general-breakpoint(tablet) {
							width: 80%;
						}
						p {
							@include interstate();
							line-height: 1.4em;
							color: $usoDkGray;
							font-size: 1.4rem;
							padding-top: 0;

							@include general-breakpoint(landscape) {
								line-height: 1.4em;
								@include interstate(light);
							}
							@include general-breakpoint(smtablet) {
								line-height: 1.2em;
								color: $white;
								padding-left: 15px;
								font-size: 1.6rem;
							}
						}
						ul {
							color: $dk-gray2;
							padding: 0 15px;
							@include media-breakpoint-up(sm) {
								color: $white;
								padding: 0 30px;
							}
						}
					}
					@include general-breakpoint(landscape) {
						// a { display: inline; }
					}
					.uso-button,
					.uso-button.transFill {
						display: block;
						position: relative;
						margin: 15px auto;
						border-radius: 7px;
						background-color: $usoLtBlue;
						color: $white !important;
						border: 2px solid transparent;
						font-size: 14px;
						cursor: pointer;
						width: 280px;
						&:hover {
							background-color: #fff;
							color: #418fde !important;
							border: 2px solid $usoLtBlueBG;
						}

						@include general-breakpoint(smtablet) {
							display: inline-block;
							float: right;
							margin: 10px 30px 10px 20px;
							width: 130px;
						}
					}
				}
			}
			@include general-breakpoint(smdesktop) {
				margin-bottom: 0px;
			}
		}
	}
}

.hospitality_headerImg {
	margin-bottom: -3px;
	position: relative;
	height: 100%;
	width: 100%;
	min-height: 224px;
	display: inline;
	@include general-breakpoint(landscape) {
		display: table;
		min-height: 414px;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: transparent; /* For browsers that do not support gradients */
			background-image: -webkit-linear-gradient($dark_blue, transparent 45%); /* For Safari 5.1 to 6.0 */
			background-image: -o-linear-gradient($dark_blue, transparent 45%); /* For Opera 11.1 to 12.0 */
			background-image: -moz-linear-gradient($dark_blue, transparent 45%); /* For Firefox 3.6 to 15 */
			background-image: linear-gradient($dark_blue, transparent 45%); /* Standard syntax */
		}
	}
	.title {
		display: block;
		position: relative;
		text-align: center;
		width: 100%;
		height: 44px;
		z-index: 1;
		@include general-breakpoint(landscape) {
			display: inline-block;
			position: absolute;
			top: 0;
			height: auto;
		}
		h1 {
			color: $dark_blue;
			font-size: 18px;
			@include interstate(medium);
			margin-top: 10px;
			@include general-breakpoint(landscape) {
				color: $white;
				font-size: 36px;
				margin: 20px auto;
			}
		}
	}
	.headerImgNavItem {
		display: inline-block;
		position: relative;
		width: 100%;
		height: 33.3%;
		margin-bottom: -2px;
		@include general-breakpoint(landscape) {
			display: table-cell;
			width: 33.3%;
			height: 100%;
		}

		.navImage {
			img {
				width: 100%; /* height: 75px; */
				display: block;
				@include general-breakpoint(landscape) {
					width: 100%;
					height: 100%;
					display: inline-block;
				}
			}
		}
		.navLinks {
			@include transparent(rgb(36, 62, 97), 0.7);
			position: absolute;
			bottom: 0px;
			padding: 10px;
			width: 40%;
			height: 100%;
			text-align: left;
			right: 0px;
			z-index: 1;
			@include general-breakpoint(landscape) {
				width: 100%;
				height: 122px;
				text-align: center;
			}
			.text {
				color: $white;
				display: block;
				font-size: 18px;
				@include interstate(bold);
			}
			.hospButton {
				color: $white;
				font-size: 12px;
				@include interstate(medium);
				background-color: transparent;
				width: auto;
				height: auto;
				text-transform: capitalize;
				padding: 5px 0px 0px;
				text-decoration: underline;
				@include general-breakpoint(landscape) {
					display: inline-block;
					margin-top: 10px;
					background-color: #397abd;
					width: 130px;
					height: 40px;
					color: $white;
					text-transform: uppercase;
					font-size: 14px;
					cursor: pointer;
					border: 0px;
					@include interstate(medium);
					padding: 0px;
					text-decoration: none;
				}
			}
		}
	}
}

.hospitality_sub_headerImg {
	margin-bottom: -3px;
	position: relative;
	.header_gallery_container {
		width: auto;
		height: auto;
		.bx-wrapper {
			margin: 0 auto !important;
			img {
				max-width: auto !important;
				width: 100%;
			}
			.bx-viewport {
				left: 0 !important;
				border: 0 none !important;
				box-shadow: none !important;
			}
			.bx-pager {
				bottom: 15px !important;
				z-index: 1;
				&.bx-default-pager a {
					background: transparent;
					border: 1px solid $white;
					&.active {
						background: $white;
					}
				}
			}
		}
	}
	> img {
		width: 100%;
	}
	.title {
		display: block;
		position: relative;
		text-align: center;
		width: 100%;
		height: 44px;
		@include general-breakpoint(landscape) {
			position: absolute;
			bottom: 0;
			@include transparent(rgb(36, 62, 97), 0.7);
			width: 100%;
			height: 94px;
			text-align: left;
		}
		h1 {
			color: $dark_blue;
			font-size: 18px;
			@include interstate(medium);
			margin-top: 10px;
			@include general-breakpoint(landscape) {
				color: $white;
				font-size: 36px;
				margin-left: 30px;
			}
		}
	}
}

.hospitality_content {
	max-width: 1280px;
	margin: auto;
	.premium_content_full {
		// background-color: $lt-gray;
		p {
			br[data-mce-bogus='1'] {
				display: none;
			}
		}
	}
	&.white {
		.premium_content_full {
			background-color: $white;
		}
	}
}

.hosp_intro {
	background-color: $white;
	width: 100%;
	text-align: center;
	position: relative;
	padding-bottom: 20px;
	/* @include general-breakpoint(tablet) { height: 145px; } */
	h2 {
		font-size: 14px;
		@include interstate(light);
		color: $dk-gray;
		text-align: center;
		padding-top: 30px;
		@include general-breakpoint(landscape) {
			font-size: 24px;
		}
	}
	h3 {
		padding-top: 5px;
		color: $dk-gray2;
		@include interstate(light);
	}
	p {
		@include interstate(light);
		font-size: 18px;
		color: #666;
		padding: 10px 20px 0 !important;
		line-height: 1.4em !important;
		a:link {
			color: #397abd;
			text-decoration: none;
		}
	}
	&.center p {
		text-align: center;
	}
	iframe {
		width: 100%;
	}
	.email {
		width: auto;
		display: inline-block;
		margin: 10px auto;
		.text {
			border: 1px solid #cbdcee;
			color: #afbac7;
			@include interstate(light);
			font-size: 18px;
			height: 32px;
			margin-right: -3px;
			@include general-breakpoint(landscape) {
				height: 40px;
			}
		}
		.hospButton {
			width: 75px;
			height: 32px;
			font-size: 14px;
			display: inline-block;
			@include general-breakpoint(landscape) {
				width: 80px;
				height: 45px;
			}
		}
	}
	.fsBody.fsEmbed {
		width: 95%;
		margin: 10px auto;
		overflow: auto;
		@include general-breakpoint(tablet) {
			width: 50%;
		}
		form {
			margin: 0;
			padding: 0;
			height: auto;
			.fsPage {
				width: 70%;
				float: left;
				@include general-breakpoint(tablet) {
					width: 80%;
				}
			}
			.fsSubmit {
				width: 30%;
				float: left;
				clear: none;
				margin-top: 3px;
				padding: 0;
				@include general-breakpoint(tablet) {
					width: 20%;
				}
			}
		}
	}
}

.fsRowBody input[type='checkbox'],
.fsRowBody input[type='radio'] {
	top: 1px !important;
	width: auto !important;
	height: auto !important;
}

.premium_content_full {
	@include interstate(light);
	position: relative;
	width: 100%;
	color: #666;
	margin-bottom: 10px;
	/* @include general-breakpoint(tablet) { height: 145px; } */
	&.white {
		background-color: $white;
	}
	h2 {
		font-size: 14px;
		@include interstate(regular);
		color: #0c2340;
		text-align: center;
		padding-top: 30px;
		@include general-breakpoint(landscape) {
			font-size: 2.5rem;
		}
	}
	p {
		@include interstate(light);
		font-size: 1.8rem;
		text-align: left;
		color: #666;
		padding: 10px 20px 10px;
		line-height: 2em !important;
		a:link {
			color: #397abd;
			text-decoration: none;
		}
	}
	ul,
	ol {
		margin-left: 30px;
	}
	.image {
		width: 100%;
		display: inline-block;
		position: relative;
		img {
			width: 100%; /* height: 215px; */
			/* @include general-breakpoint(landscape){ height: 420px;} */
			@include general-breakpoint(tablet) {
				display: none;
			}
		}
		.caption {
			width: 100%;
			height: auto;
			position: relative;
			color: $white;
			padding: 30px;
			margin-top: -3px;
			@include general-breakpoint(landscape) {
				height: 230px;
			}
			@include general-breakpoint(tablet) {
				width: 95%;
				// height: 125px;
				@include transparent(rgb(0, 0, 0), 0.7);
				position: absolute;
				left: 3%;
				right: 3%;
				top: 30%;
				display: inline-block;
				color: $white;
				padding: 10px 20px;
			}
			h2 {
				font-size: 22px;
				@include interstate(regular);
				color: $white;
				text-align: left;
				padding-top: 0;
				@include general-breakpoint(landscape) {
					font-size: 30px;
				}
			}
			.text {
				@include interstate;
				font-size: 16px;
				width: 100%;
				padding-top: 10px;
				display: inline-block;
				color: $white;
				@include general-breakpoint(landscape) {
					font-size: 18px;
					padding-right: 10px;
				}
				@include general-breakpoint(tablet) {
					width: 80%;
				}
				p {
					padding-top: 0;
					line-height: 1.3em;
					color: $white;
				}
			}
			.hospButton {
				display: block;
				position: relative;
				margin: 15px 0px;
				@include general-breakpoint(tablet) {
					display: inline-block;
					position: absolute;
					margin: 10px 0px;
				}
			}
		}
	}
	&.overlay {
		@include general-breakpoint(tablet) {
			height: 720px;
		}
		.image {
			height: 100%;
			background-size: cover;
			@include media-breakpoint-down(md) {
				background-image: none !important;
			}
			.caption {
				position: relative;
			}
		}
	}
	iframe {
		width: 100%;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}
	.form {
		font-size: 18px;
		margin: 10px auto;
		width: 90%;
		height: 100%;
		margin: 0px auto;
		padding: 20px 0px;
		position: relative;
		@include general-breakpoint(landscape) {
			width: 60%;
		}
		@include general-breakpoint(tablet) {
			width: 50%;
		}
		hr {
			border-color: #cbdcee;
			border-width: 0.25px;
		}
		input[type='text'] {
			border: 1px solid #cbdcee;
			color: #afbac7;
			@include interstate(light);
			font-size: 18px;
			height: 40px;
			width: 90%;
			margin: 5px 5px 20px;
			@include general-breakpoint(landscape) {
				width: 80%;
				margin: 10px 10px 30px;
			}
		}
		form {
			margin-top: 40px;
			.hospButton,
			.title {
				display: inline-block;
				left: 5%;
				position: relative;
				@include general-breakpoint(landscape) {
					left: 10%;
				}
			}
		}
		.required {
			color: $red;
			font-size: 14px;
			padding-bottom: 10px;
		}
		.formList {
			list-style: none;
			text-align: center;
		}
	}
	.fsBody.fsEmbed {
		width: 95%;
		margin: 10px auto;
		overflow: auto;
		// @include general-breakpoint(tablet) { width: 50%; }
		// form { margin: 0; padding: 0; height: auto;
		//   .fsPage { width: 70%; float: left;
		//     @include general-breakpoint(tablet) { width: 80%; }
		//   }
		//   .fsSubmit { width: 30%; float: left; clear: none; margin-top: 3px; padding: 0;
		//     @include general-breakpoint(tablet) { width: 20%; }
		//   }
		// }
	}
	.fsBody,
	.fsForm {
		margin-top: 0 !important;
		padding-top: 0 !important;
		p {
			line-height: normal !important;
		}
	}
	.fsRowBody input[type='checkbox'],
	.fsRowBody input[type='radio'] {
		top: 1px;
		height: auto;
	}
}
