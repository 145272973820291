.slamtracker {
    .challenges {
        position: relative;
        width: calc(100% / 3);
        display: table-cell;
        vertical-align: middle;

        .item {
            width: 13px;
            height: 8px;
            margin: 0px 1px;
            background-color: $gray-light;
            display: inline-block;
        }
        &.team-one {
            // float: left;
            text-align: left;

            .item {
                &.available {
                    background-color: $team1_color;
                }
            }
        }
        &.team-two {
            // float: left;
            text-align: right;

            .item {
                &.available {
                    background-color: $team2_color;
                }
            }
        }
        .text {
            clear: left;
            @include interstate;
            color: $dk-gray2;
            font-size: 7px;
            text-transform: uppercase;
        }
    }
    .fixed {
        .challenges {
            display: none;
        }
    }
}

@include general-breakpoint(smtablet) {
    .slamtracker {
        .challenges {
            margin-top: -33px;
            display: block;
            width: auto;

            &.team-one {
                float: left;
                margin-left: 6px;

                &.doubles {
                    margin-left: 28px;
                }
            }
            &.team-two {
                float: right;
                margin-right: 6px;

                &.doubles {
                    margin-right: 28px;
                }
            }
        }
    }
}