#uso-topLeaders {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    display:none;

    @include general-breakpoint(smtablet) {
        display: flex;
    }
}

.leaderWrapper {
    @include interstate(light);
    border: solid 1px $md-gray2;
    color: $dk-gray2;
    width: 100%;

    @include general-breakpoint(smtablet) {
        width: calc(50% - #{$spacer});
        
        &:first-child {
            margin-right: $spacer * 2;
        }
    }

    

    &.hide {
        display: none;

        @include general-breakpoint(smtablet) {
            display: block;
        }
    }

    h3 { 
        background: $lt-gray2;
        border: solid 1px $white;
        font-size: 16px;
        padding: calc($spacer / 2) $spacer;
        text-transform: capitalize;

        @include general-breakpoint(smdesktop) {
            font-size: 18px;
            padding: $spacer;
        }
    }

    h4 {
        font-size: 14px;

        @include general-breakpoint(smdesktop) {
            font-size: 18px;
        }
    }

    .country {
        @include interstate(regular);
        background: $md-gray2;
        color: $white;
        font-size: 14px;
        padding: 4px $spacer;

        img {
            vertical-align: middle;
            padding-right: 14px;
        }

        @include general-breakpoint(smdesktop) {
            font-size: 16px;
            padding: 7px $spacer;
        }
    }

    .player-image {
        display: inline-block;
        margin: $spacer calc($spacer / 2) $spacer $spacer;
        width: 88px;

        img {
            border: solid 1px $md-gray2;
            width: 100%;
        }

        @include general-breakpoint(smdesktop) {
            margin: $spacer;
            width: 113px;
        }
    }

    .player-info {
        display: inline-block;
        padding: $spacer calc($spacer / 2);
        vertical-align: top;
        width: calc(100% - 88px - #{$spacer} - #{$spacer} /2);

        @include general-breakpoint(smdesktop) {
            padding: $spacer;
            width: calc(100% - 113px - #{$spacer} - #{$spacer});
        }
    }

    .name {
        @include interstate(regular);
        color: $bright-blue;
        font-size: 24px;
        line-height: 28px;
        padding-top: 6px;

        @include general-breakpoint(smdesktop) {
            font-size: 28px;
            line-height: 32px;
            padding-top: 10px;
        }
    }

    .stat {
        font-size: 40px;
        text-transform: uppercase;

        @include general-breakpoint(smdesktop) {
            font-size: 56px;
        }
    }

    .symbol {
        font-size: 28px;
    }
} // .leaderWrapper