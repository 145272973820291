.live_show {
	.live_show_desc,
	.chips_content {
		max-width: 1280px;
		margin: auto;
	}

	.live_show_desc {
		padding-top: 12px;
		padding-bottom: 20px;
		border-bottom: solid 1px #d8d8d8;
	}
	#hpLoad {
		background-color: transparent;
	}
}

.live-page,
.live_show {
	.page-submenu-wrapper {
		.submenu-details-wrapper {
			display: none;
		}
	}
}

.live-page {
	display: flex;
	flex-direction: column;

	.youtube-embed {
		width: 100%;
	}
	.youtube-wrapper {
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		position: relative;
		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
	.youtube-tag {
		background: #d8d8d8;
		border: 1px solid #979797;
		margin-top: 10px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		color: #4a4a4a;
		letter-spacing: 0;
	}
}

/**live video subnav**/
.live-subnav {
	background: #00288c;
	display: flex;
	justify-content: center;
	height: 60px;
	align-items: center;
	border-top: 1px solid #888b8d;
	.live-subnav-item-wrapper {
		display: flex;
		justify-content: center;
		height: 100%;
		align-items: center;
		min-height: 60px;
		.select-menu {
			max-width: 250px;
		}
	}
	.live-subnav-select-menu {
		@include interstate();
		height: 28px;
		background-color: #00288c;
		border: 1px solid $white;
		color: $white;
		border-radius: 3px;
		text-align: center;
		padding: 2px 7px 2px 4px;
		margin: 8px;
		text-align-last: center;
		width: 175px;
		
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url('/assets/images/icons/dropdown-arrow-ltgray.svg');
		background-repeat: no-repeat;
		background-position-x: 94%;
		background-position-y: 50%;
		background-color: $usoDkBlue;
	}
	.live-subnav-items {
		@include interstate();
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		margin: 0;
		padding: 0;
		white-space: nowrap;
		li {
			margin: 0 20px;
			border-bottom: 2px solid transparent;
			&:first-child {
				margin-left: 0;
			}
			&.selected {
				@include interstate(bold);
				text-decoration: underline;
				text-underline-offset: 24px;
				text-decoration-thickness: 3px;
				color: #fff;
			}
			&::after {
				content: attr(data-text);
				content: attr(data-text) / '';
				height: 0;
				display: block;
				visibility: hidden;
				overflow: hidden;
				user-select: none;
				pointer-events: none;
				font-weight: 700;
			}
			&:hover {
				@include interstate(bold);
			}
			a {
				// font-size: 14px;
				color: #ffffff;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.select-menu {
		text-align: right;
		padding: 8px 8px 0 0;
		.select-menu-dropdown.show {
			margin-left: unset;
			transform: unset;
			right: 7px;
		}
	}
}

.practice-court-menu {
	text-align: center;
	padding: 10px 0;
	font-size: 1.5em;
	ul {
		margin-bottom: 0px;
		display: inline-block;
		padding-inline-start: 10px;
		li {
			display: inline-block;
			line-height: 2em;
			border-right: 1px solid $md-gray;
			padding-right: 7px;
			padding-left: 7px;
			a {
				text-decoration: none;
				color: $md-gray;
				&:hover {
					border-bottom: 2px solid $md-gray;
				}
			}
			&.active {
				color: $md-gray;
				font-weight: bold;
			}
			&.selected {
				color: $md-gray;
				font-weight: bold;
				span,
				a {
					border-bottom: 2px solid $md-gray;
				}
			}
			&.offair {
				color: $dk-gray;
			}
			&:last-child {
				border: none;
			}
		}
	}
}

.courtLive {
	display: flex;
	flex-direction: row;
	align-items: center;
	.practice-schedule-court-name {
		flex: 1 1 auto;
		&.live {
			text-align: left;
		}
	}
	@include general-breakpoint(landscape) {
		.practice-schedule-court-name {
			padding-left: 100px;
			&.live {
				text-align: center;
			}
		}
	}
	@include general-breakpoint(tablet) {
		flex-direction: column;
		margin: 10px 0;
		.practice-schedule-court-name {
			padding-left: 0;
		}
	}
	.practice-schedule-buttons-cta {
		width: auto;
		flex: 0 0 100px;
		padding-bottom: 0 !important;
		&.offair {
			display: none;
		}
		a {
			display: block;
			text-decoration: none;

			&:hover {
				color: $bright-blue;
			}
		}
		.live-cta-button {
			@include button();
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100px;
			height: 40px;
			font-size: 12px;
			margin: 0 !important;
		}

		@include general-breakpoint(tablet) {
			float: none;
			display: table;
			table-layout: fixed;
			width: 100%;
			flex-basis: auto;
			.live-cta-button {
				font-size: 16px;
				width: 120px;
				margin: 5px auto !important;
			}
		}
		@include general-breakpoint(smdesktop) {
			width: 100%;
			clear: both;
			padding-bottom: 10px !important;

			.live-cta-button {
				font-size: 16px;
				width: 120px;
				height: 40px;
				line-height: 18px;
				padding-top: 2px !important;
				margin: 10px auto 0 !important;
			}
		}
	}
}

.live-video {
	width: 100%;
	max-height: 720px;
	max-width: 1280px;
	margin: auto;

	.live-video-slate {
		position: relative;
		height: 0;
		padding-bottom: 56.25%;
		margin: auto;
		.slate-container {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			img {
				width: 100%;
				height: 100%;
			}
			.slate-message {
				position: absolute;
				top: 0;
				padding: 20px 15px;
				color: $white;
				display: block;
				width: 100%;
				text-align: center;
				@include general-breakpoint(landscape) {
					position: absolute;
					top: 0;
					padding: 30px 50px;
					color: $white;
					text-shadow: $black 2px 2px 2px;
					background-color: transparent;
				}
				@include general-breakpoint(desktop) {
					position: absolute;
					top: 0;
					padding: 30px 60px;
				}
				a {
					color: $white;
				}
				h1 {
					padding: 0;
					margin-bottom: 10px;
					border: none;
					color: $white;
					@include interstate(regular);
					font-size: 2.4rem;
					line-height: 2.6rem;
					display: inline;
					margin-right: 10px;
					@include general-breakpoint(landscape) {
						display: block;
						color: $white;
					}
					@include general-breakpoint(tablet) {
						margin-right: 0;
						margin-bottom: 15px;
						font-size: 4rem;
						line-height: 4.2rem;
					}
					@include general-breakpoint(desktop) {
						margin-bottom: 25px;
						font-size: 5rem;
						line-height: 5.2rem;
					}
				}
				h2 {
					padding: 0;
					margin-bottom: 10px;
					@include interstate(regular);
					font-size: 2.4rem;
					line-height: 2.6rem;
					color: $white;
					display: inline;
					@include general-breakpoint(landscape) {
						@include interstate(bold);
						text-transform: uppercase;
						display: block;
						color: $white;
					}
					@include general-breakpoint(tablet) {
						font-size: 1.8rem;
					}
					@include general-breakpoint(desktop) {
						font-size: 2rem;
					}
				}
				p {
					display: none;
					padding: 0;
					@include interstate(regular);
					font-size: 1.2rem;
					@include general-breakpoint(landscape) {
						display: block;
						font-size: 1.3rem;
					}
					@include general-breakpoint(desktop) {
						display: block;
						font-size: 1.4rem;
					}
				}
			}
		}
	}
}
