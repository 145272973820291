.moduleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;

    .module-container {
        margin-top: 30px !important;
        width: 100%;

        &:first-child {
            margin-top: 14px !important;
        }
    }
    @include general-breakpoint(smdesktop) {
        align-items: stretch;
       
        .module-container {
            width: 478px;
            background-color: $white;

            &:first-child {
                margin-top: 30px !important;
            }
        }
    }
    @include general-breakpoint(desktop) {
        .module-container {
            width: 620px;
        }
    }
}
a {
    &.page-module-item, &.double-stack-module-item {
        color: unset;
        text-decoration: none;
        cursor: pointer;
    }
    &.page-module-item {
        width: 100%;
    }
    &.double-stack-module-item {
        width: calc((100% - 20px) / 2);
    }
}
.content-module {
    width: 100%;
    background-color: $white;
    height: auto;

    &.page {
        .content-module-image {
            width: 100%;
    
            img {
                width: 100%;
                @include aspect-ratio(16, 9);
            }
        }
        .content-module-description {
            padding: 15px;
            display: flex;
            flex-direction: column;

            .title {
                @include interstate(bold);
                font-size: 20px;
            }
            .text {
                @include interstate(); 
                font-size: 14px;
                p {
                    padding-top: 0;
                    margin-bottom: 0;
                    line-height: 1.5;
                }
            }
            &:hover {
                color: $usoLtBlue !important;
            }
        }
    }
    &.double-stack {
        padding: 21px 15px 11px 16px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        height: auto;

        .double-stack-item {
            height: auto;

            &-image {
                margin-bottom: 5px;
                width: 100%;
                
                img {
                    width: 100%;
                    @include aspect-ratio(16, 9);
                }
            }
            &-description {
                width: 100%;
                @include interstate();
                color: $gray-4a;
                font-size: 14px;

                &:hover {
                    color: $usoLtBlue !important;
                }
            }
        }
        /** set width of item with no link **/
        > .double-stack-item {
            width: calc((100% - 20px) / 2);
        }
        /** set width of item with link **/
        a > .double-stack-item {
            width: 100%;
        }
        a { margin-bottom: 10px;}
    }
    @include general-breakpoint(landscape) {
        &.page {
            .content-module-description {
                padding: 15px 20px;
            }
        }
        &.double-stack {
            .double-stack-item {
                &-image {
                    margin-bottom: 5px;
                }
                &-description {
                    font-size: 16px;
                }
            }
        }
    }
    @include general-breakpoint(smtablet) {
        &.page {
            .content-module-image {
                border: 1px solid $white;
            }
        }
    }
    @include general-breakpoint(smdesktop) {
        min-height: 416px;

        &.page {
            .content-module-description {
                padding: 20px;
            }
        }
        &.double-stack {
            padding: 21px 20px 21px 19px;
        }
    }
    @include general-breakpoint(desktop) {
        min-height: 480px;
    }
}