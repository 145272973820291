.dining,
.countries {
	&-wrapper {
		background-color: $white;

		.filter {
			background-color: $usoBkgGray;
			border-bottom: solid 1px $usoMdGray;
			padding: 10px;
			text-align: center;
			display: flex;
			justify-content: space-around;
			@include general-breakpoint(tablet) {
				justify-content: center;
			}
			flex-wrap: wrap;

			.select-menu {
				display: inline-block;
				width: auto;
				margin: 5px;
				@include general-breakpoint(tablet) {
					margin: 0 10px;
				}
				// &:first-of-type {
				//     margin-right: 20px;
				// }

				.title {
					min-width: 230px;
					text-align: left;

					.menu-arrow {
						position: absolute;
						top: 6px;
						right: 10px;
					}
				}
			}
		} // filter

		h3.title {
			@include interstate(bold);
			border-bottom: solid 1px $usoLtGray;
			color: $usoDkGray;
			text-transform: uppercase;
			padding: 16px;

			@include general-breakpoint(smdesktop) {
				padding: 20px;
			}
		}

		.red {
			color: $live-color;
		}
	} // -wrapper

	&-content {
		padding: 8px;

		@include general-breakpoint(smdesktop) {
			padding: 10px;
		}

		/** set one col, two cols, three col display */
		display: flex;
		flex-flow: row wrap;

		.click-overlay-wrapper {
			@include general-breakpoint(smtablet) {
				width: calc((100% / 2) - 16px);
				margin: 8px; // total pixel in b/w click-overlay-wrapper is 16px (8px + click-overlay-content padding 8px)
			}

			@include general-breakpoint(smdesktop) {
				width: calc((100% / 3) - 20px);
				margin: 10px; // total pixel in b/w click-overlay-wrapper is 20px (10px + click-overlay-content padding 10px)
			}
			&.hovering {
				border: 1px solid #2478cc;
				transform: translate(-2px, -2px);
				box-shadow: 0 2px 4px $gray-light;
			}
		}
	} // -content
} // .dining

// override
.general_content {
	&.dining {
		overflow: visible;
	}
}

.dining-dropdown-wrapper {
	display: flex;
	background-color: transparent;
	@media only screen and (max-width: 425px) {
		flex-direction: column;
	}
}

.dining-location-wrapper,
.dining-food-types-wrapper {
	select {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url('/assets/images/icons/dropdown-arrow.svg');
		background-repeat: no-repeat;
		background-position-x: 96%;
		background-position-y: 50%;

		@include interstate();
		background-color: $white;
		color: $usoLtBlue;
		border-color: $usoLtBlue;
	}
}
.dining-location-select-menu {
	border-radius: 3px;
	padding: 0 1rem;
	margin-right: 20px;
	max-width: 300px;
	@media only screen and (max-width: 530px) {
		width: 180px;
	}
	@media only screen and (max-width: 425px) {
		margin-bottom: 8px;
		margin-right: 0;
	}
}

.dining-food-types-select-menu {
	width: 180px;
	border-radius: 3px;
	padding: 0 1rem;
}

.dining-content {
	display: flex;
	justify-content: space-around;
}

.dining-card-wrapper {
	max-width: 385px;
	height: 540px;
	margin-bottom: 21px;
	overflow: hidden;
	position: relative;
	border: 1px solid #c1c6c8;
	@include general-breakpoint(smtablet) {
		max-width: 337px;
	}
	@include general-breakpoint(smdesktop) {
		max-width: 290px;
	}
	@include general-breakpoint(desktop) {
		max-width: 385px;
	}

	.reserve {
		position: absolute;
		bottom: 15px;
	}
}

.dining-image-wrapper {
	img {
		max-width: 385px;
		@include general-breakpoint(smtablet) {
			max-width: 337px;
		}
		@include general-breakpoint(smdesktop) {
			max-width: 290px;
		}
		@include general-breakpoint(desktop) {
			max-width: 385px;
		}
	}
}

.dining-card-body {
	padding: 15px;
	font-weight: 300;
	line-height: 20px;
	.dining-card-title {
		@include interstate(bold);
		font-size: 16px;
		color: $usoDkGray;
		padding-top: 0;
	}
	.find-on-map {
		margin-top: 19.5px;
		font-weight: 400;
		@include interstate(regular);
	}
	.dining-card-description {
		@include interstate(light);

		p {
			margin-bottom: 0;
		}
	}
}
