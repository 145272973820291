// Mixin to run inside of for loop - creates visibility classes * Not for developer use *
@mixin vis-factory($thisPrefix: null) {
    @if $thisPrefix {
        .show-#{$thisPrefix} {
            display: block !important;
        }

        .hide-#{$thisPrefix} {
            display: none !important;
        }
    } @else {
        .show {
            display: initial;
        }
        .hide {
            display: none;
        }
    }
}

@include vis-factory();

// Mixin to run inside of for loop - creates visibility classes * Not for developer use *
@each $prefix in $breakpoint-all-prefixes {
    @include general-breakpoint($prefix) {
        @include vis-factory($prefix);
    }
}
