.media-landing{
    background-color:#FFFFFF;
    border:1px solid #C1C6C8;
   

}
.latest-media-tile{
        
    background-color: transparent;
    border:none;
    margin: 0px auto;
    padding: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    .story{
        width:30%!important;
        border: 1px solid #c1c6c8;
    }
}
.captionContainer{
    padding-left:10px!important;
    color:#4a4a4a;
    .caption{
        height:70px;
    }
    .credit{
        text-align: left;
        color: #858a83;
        font-family: "interstate",Helvetica, Arial, sans-serif;
        font-weight: 400 !important;
        font-size: 1.3rem;
        padding: 20px 0px 0px 0px;
    }
    .date{
        color: #858a83;
        padding-bottom:10px;
    }
}
.content-type{
    color:#4a4a4a;
    text-transform:uppercase;
    padding: 10px 10px;
    display:flex;
    letter-spacing: 2.2px;;
    border-top: 1px solid #c1c6c8;
    background-color: #f3f3f3;
    i{
        margin-left:auto;
        color:#4a4a4a;
    }
}
.load-more-button{
    margin: 0px auto;
    width: 128px;
    height: 40px;
    font-size: 1.2em;
    line-height: 40px;
    background: #418FDE;
    color: #FFFFFF;
    font-family: Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    border: none;
}