/***********
 General Content Styles - mainly used for Tickets
**********/
$general_outer_margin: 20px;
$general_inner_margin: 10px;

/** override to make header hero 100vs */
#uso-main .wrapper {
	.hero-container-wrapper {
		visibility: hidden;
	}

	// &.tickets, &.ticket_plans, &.mobile_ticketing, &.tennis_plays_for_peace{
	&.page-header-hero {
		max-width: unset;
		// .page-header-hero-wrapper{}

		.hero-container-wrapper {
			visibility: visible;
			background: linear-gradient($usoDkBlue, rgba(0, 0, 0, 0));
			height: calc(100vh - 475px);
			width: 100%;
			position: absolute;
			z-index: 20;
			top: 75px;
			&.hide {
				background: transparent;
			}
			@include general-breakpoint(landscape) {
				top: unset;
				height: 400px;
			}
		}
		
		.mobile-container-wrapper {
			width: 100%;
			height: 75px;
			background: $usoDkBlue;
			position: absolute;
			z-index: 20;
			&.hide {
				background: transparent;
			}
			@include general-breakpoint(landscape) {
				visibility: hidden;
			}
		}

		.hero_container .hero_content,
		.accordion-container,
		.siteDetailContainer,
		.uso-interactive {
			@include general-breakpoint(landscape) {
				max-width: $sm-alt-max-content-width;
			}

			@include general-breakpoint(smtablet) {
				max-width: $md-alt-max-content-width;
			}

			@include general-breakpoint(smdesktop) {
				max-width: $md-max-content-width;
			}

			@include general-breakpoint(desktop) {
				max-width: $lg-max-content-width;
			}
		}

		.premium-section {
			position: relative;
			width: auto;
			margin: 0px 15px;
			@include general-breakpoint(landscape) {
				top: -75px;
				margin: 0px auto;
			}
		}
		
		.uso-interactive {
			display: flex;
			justify-content: center;
		}

		&.fan-week {
			flex-direction: column;

			@include media-breakpoint-down(md) {
                section:nth-of-type(2){
                    margin-top: 0px;
                }
            }
            @include general-breakpoint(smtablet) {
                section:nth-of-type(2){
                    margin-top: -116px;
                }
            }
			@include general-breakpoint(smdesktop) {
				section:nth-of-type(2){
                    margin-top: -156px;
                }
			}
			@include general-breakpoint(desktop) {
				section:nth-of-type(2){
                    margin-top: -159px;
                }
			}
        }

		&.fan-week-page {
			flex-direction: column;

			.hero_container {
				height: 211px;
				max-height: 211px;

				@include general-breakpoint(smtablet, max) {
					margin-bottom: 0;
				}
				@include general-breakpoint(smtablet) {
					height: 500px;
					max-height: 500px;
				}
				@include general-breakpoint(smdesktop) {
					padding-bottom: 0;
				}
			}
			.siteDetailContainer {
				margin-top: 30px;
			}
			&.fan-week {
				.hero_container {
					height: 446px;
					max-height: 446px;
				}
			}
			&.schedule-of-events {
				.hero_container {
					height: 488px;
					max-height: 488px;
				}
			}
			@include general-breakpoint(smtablet) {
				section:nth-of-type(2){
                    margin-top: -143px;
                }
			}
        }
		.contentWrapper + .uso-media {
			margin-top: -20px;
		}
		.uso-media.photo-16x9 {
			padding: 0;
			@include general-breakpoint(landscape) {
				max-width: $sm-alt-max-content-width;
			}
	
			@include general-breakpoint(smtablet) {
				max-width: $md-alt-max-content-width;
			}
	
			@include general-breakpoint(smdesktop) {
				max-width: $md-max-content-width;
			}
	
			@include general-breakpoint(desktop) {
				max-width: $lg-max-content-width;
			}
			.photo.center {
				margin: 20px auto 0 auto;
				position: relative;
				img {
					height: auto;
					aspect-ratio: 16 / 9;
				}
				.caption {
					display: block;
				}
				&.hide-caption {
					.caption {
						display: none;
					}
				}
			}
		}
	}

	// &.tennis_plays_for_peace{
	//     .siteDetailContainer{
	//         @include general-breakpoint(landscape){
	//             max-width: $sm-alt-max-content-width;
	//         }

	//         @include general-breakpoint(smtablet){
	//             max-width: $md-alt-max-content-width;
	//         }

	//         @include general-breakpoint(smdesktop){
	//             max-width: $md-max-content-width;
	//         }

	//         @include general-breakpoint(desktop){
	//             max-width: $lg-max-content-width;
	//         }
	//     }
	// }
	&.accessiblity {
		padding-top: 30px;
	}
}

.general_content {
	.visit-mode {
		display: none;
	}
	// &.landing {
	.premium-section {
		@include general-breakpoint(landscape) {
			max-width: $sm-alt-max-content-width;
		}

		@include general-breakpoint(smtablet) {
			max-width: $md-alt-max-content-width;
		}

		@include general-breakpoint(smdesktop) {
			max-width: $md-max-content-width;
		}

		@include general-breakpoint(desktop) {
			max-width: $lg-max-content-width;
		}
	}

	.premium-section.centered {
		position: relative;
		margin: 0 15px;
		display: flex;
		flex-direction: row;
		align-items: stretch;
		flex-wrap: wrap;
		overflow: hidden;

		.premium-content {
			margin-bottom: 30px;

			.image {
				a {
					display: block;
				}
			}
			@include general-breakpoint(smdesktop) {
				width: 50%;
				margin-bottom: 0;
			}
			@include general-breakpoint(desktop) {
				.text {
					padding-bottom: 0;
					flex: unset;
				}
			}
		}

		@include general-breakpoint(landscape) {
			margin: 0px auto;
		}
	}

	.premium-section.right,
	.premium-section.left {
		@include general-breakpoint(smdesktop, max) {
			display: flex;
			flex-direction: row;
			align-items: stretch;
			flex-wrap: wrap;
			overflow: hidden;
			margin-left: 0;
			margin-right: 0;
		}
	}
	// }
}

.general_content {
	width: 100%;
	position: relative;
	overflow: hidden;
	font-size: 14px;

	.contentWrapper, .moduleWrapper {
		@include general-breakpoint(landscape) {
			max-width: $sm-alt-max-content-width;
		}

		@include general-breakpoint(smtablet) {
			max-width: $md-alt-max-content-width;
		}

		@include general-breakpoint(smdesktop) {
			max-width: $md-max-content-width;
		}

		@include general-breakpoint(desktop) {
			max-width: $lg-max-content-width;
		}
	}

	&.blue {
		background-color: $blue;
		color: $white;
	}

	&.light-blue {
		background-color: $light-blue2;
		color: $white;
	}

	&.page-sponsors-logo{ //override for sponsor logo to display center 
		.photo-inline {
			img{ padding-top: 70px; }
			&.left{ text-align: center!important; width: 100%!important;}
		}
	}

	h3 {
		font-size: calc((24/14) * 1em);
		padding-top: $general_outer_margin;

		a {
			text-decoration: none;
		}

		&.hospitality {
			a {
				text-decoration: underline;
			}
		}
	}

	h4 {
		font-size: calc((18/14) * 1em);
		padding-top: $general_outer_margin;
	}

	// a { color: $white; }
	p {
		padding-top: 20px;
		line-height: 1.4em;

		@include general-breakpoint(tablet) {
			line-height: 1.8em;
		}

		&:first-child {
			padding-top: $general_outer_margin;
		}
	}

	ul,
	ol {
		padding-top: $general_inner_margin;
		margin-left: 40px;
	}

	li {
		line-height: 1.4em;

		@include general-breakpoint(tablet) {
			line-height: 1.8em;
		}
	}

	hr {
		float: left !important;
		background-color: $light-blue;
		border: none;
		clear: both;
		color: $light-blue;
		height: 1px;
		margin: $general_outer_margin 0;
		width: 100%;
	}

	hr + h3,
	hr + h4 {
		padding-top: 0;
	}

	div.leftAlign + hr,
	div.rightAlign + hr {
		height: 2px;
		background-color: $dark_blue;
	}

	.columns-2 {
		column-count: 2;
		column-gap: 25px;

		ul {
			padding-top: 0;
		}
	}

	.promoWrapper {
		padding: 20px 10px;
		background-color: $light-blue2;

		@include general-breakpoint(landscape) {
			padding: $general_outer_margin;
		}

		.left {
			h2 {
				font-size: calc((24/14) * 1em);
			}

			h4 {
				a {
					text-decoration: none;
				}
			}

			@include general-breakpoint(landscape) {
				float: left !important;
				width: 100%;
				padding-right: $general_inner_margin;
			}

			@include general-breakpoint(tablet) {
				float: left !important;
				width: 33%;
				padding-right: $general_inner_margin;
			}
		}

		.promo {
			width: 32%;
			height: 175px;
			display: inline-table;
			position: relative;

			span {
				display: table-cell;
				vertical-align: middle;
				text-align: center;
				font-size: calc((24/14) * 1em);
			}

			&.center {
				display: none;

				@include general-breakpoint(tablet) {
					display: inline-table;
					width: 32%;
					margin-left: 0px;
					margin-right: 15px;
				}
			}

			&.right {
				display: none;

				@include general-breakpoint(tablet) {
					display: inline-table;
				}
			}
		}
	}

	.stadiumWrapper {
		background-color: $light-blue2;
		overflow: hidden;
		padding: 20px 5px;

		@include general-breakpoint(landscape) {
			padding: $general_outer_margin;
		}

		h3 {
			&:first-child {
				padding-top: 0px;
			}
		}

		.left {
			h4 {
				padding-top: $general_inner_margin;
			}

			h4 + p {
				padding-top: 0;
			}

			@include general-breakpoint(landscape) {
				float: left !important;
				width: 33%;
				padding-right: $general_inner_margin;
				display: inline;
			}
		}

		.stadiumContainer {
			padding-top: $general_outer_margin;

			@include general-breakpoint(landscape) {
				/*float: left !important;*/
				float: none;
				padding-top: 0;
				width: 90%;
				margin: 0px auto;
			}

			.stadium {
				float: left !important;
				@include interstate(thin);
				text-align: center;
				padding: 0px;
				width: 33%;

				@include general-breakpoint(landscape) {
					padding: 0 15px;
				}

				img {
					max-width: 70px;

					@include general-breakpoint(landscape) {
						max-width: 85px;
					}
				}

				h5 {
					padding-top: 5px;
					font-size: calc((12/14) * 1em);

					@include general-breakpoint(landscape) {
						font-weight: bold;
						font-size: calc((14/14) * 1em);
					}
				}

				p {
					font-size: calc((12/14) * 1em);
					line-height: 1.3em;
					padding-top: 0;
					text-align: center;

					@include general-breakpoint(landscape) {
						font-size: calc((14/14) * 1em);
					}

					@include general-breakpoint(tablet) {
						white-space: nowrap;
					}
				}

				.seatView {
					display: none;

					@include general-breakpoint(landscape) {
						display: block;
					}
				}
			}
		}

		&.ticketPlans .stadiumContainer {
			@include general-breakpoint(landscape) {
				float: left;
				width: 66%;
			}
		}
	}

	.uso-tickets-calendar {
		display: inline-block;
		margin-bottom: 5px;
		width: 100%;
		// overflow: hidden;
	}

	.selectDatesCalendar {
		width: 100%;
		display: block;

		.date {
			border-top: 1px solid $usoLtGray;
			border-bottom: 1px solid $usoLtGray;
			border-right: 1px solid $usoLtGray;
			&:first-of-type {
				border-left: 1px solid $usoLtGray;
			}

			.date-as-calendar {
				background-color: $white;
				margin: 0px;
				@include interstate(exlight);
				color: $dk-gray2;

				@include media-breakpoint-up(md) {
					width: 40px;
					height: 80px;
					// width: 75px;
					// height: calc(111px - 10px - 10px);
					// display: inline-block;
					// margin: 10px;
				}

				/** [MD] commenting this out in case this is necessary on different page
                         uncommenting this section requires _fanweek.scss updates to override
                */
				@include media-breakpoint-up(lg) {
					// width: 59px;
					// height: 81px;
					//     display: inline-block;
					//     margin: 10px;
				}

				@include media-breakpoint-up(xl) {
					// width: 78.7px;
					// height: 81px;
					//     display: inline-block;
					//     margin: 19px;
				}

				.day,
				.fw-day {
					font-size: px2rem(22);
					top: 24px;
					height: 30px;
					line-height: 30px;

					@include media-breakpoint-up(md) {
						// font-size: px2rem(36);
						// top: 37px;
					}
				}

				.month {
					line-height: normal;
					font-size: px2rem(12);

					@include general-breakpoint(landscape) {
						// top: 5px;
					}

					@include media-breakpoint-up(md) {
						// top: 10px;
						// font-size: px2rem(14);
					}
				}

				.fw-weekday,
				.weekday {
					line-height: normal;
					line-height: normal;
					font-size: px2rem(12);

					@include media-breakpoint-up(md) {
						// font-size: px2rem(14);
					}
				}
			}

			&.select {
				background-color: $usoDkBlue;
				border-bottom: solid 4px $usoYellow;
				color: $white;
				cursor: pointer;

				.date-as-calendar {
					background-color: $usoDkBlue;
					@include interstate(light);
				}

				.day,
				.month,
				.weekday,
				.week,
				.fw-week,
				.fw-day {
					color: $white;
				}
			}

			&:hover {
				color: $usoLtBlueBG;
				cursor: pointer;

				.date-as-calendar {
					@include interstate(light);
				}

				.day,
				.month,
				.weekday,
				.week,
				.fw-week,
				.fw-day {
					color: $usoLtBlueBG;
				}
			}

			&.disabled {
				// border-color: $md-gray4;
				cursor: default;

				.day,
				.month,
				.weekday,
				.week,
				.fw-day,
				.fw-week {
					color: $md-gray4;
				}
			}
			&.fanweek .date-as-calendar {
				width: 80%;
			}
		}

		.ticket_row {
			background-color: $white;
			padding-top: 14px;
			padding-bottom: 20px;
			text-align: center;
			display: flex;
		}

		@include general-breakpoint(smtablet) {
		}
	}

	.selectDatesInfo {
		width: 100%;
		padding: 0;
		position: relative;
		display: inline-block;

		@include general-breakpoint(smtablet) {
			display: block;
		}

		.filtersWrapper {
			background-color: $usoDkBlue;
			width: 100%;
		}

		.ticketWrapper {
			text-align: center;
			width: 100%;
			background-color: $white;

			.ticket_row {
				display: block;
				vertical-align: top;

				&.disabled {
					// border-color: $md-gray4;
					cursor: default;

					.date {
						cursor: default;
					}

					.day,
					.month,
					.weekday,
					.week,
					.fw-day,
					.fw-week,
					.arrow_down {
						color: $md-gray4;
					}
				}
			}

			@include general-breakpoint(landscape) {
				line-height: 0;
			}
		}
	}

	.selectDatesCalendar,
	.selectDatesInfo {
		.date {
			// height: 81px;
			position: relative;
			background: $white;
			padding: 0;
			cursor: pointer;
			// width: calc((100% / 14) - 6px);
			// margin-left: 3px;
			// margin-right: 3px;
			display: inline-block !important;

			.arrow_down {
				margin-right: 10px;
				margin-top: 25px;
				width: 17px;
				height: 9px;
				background: url('/images/nav/chevron-down.png') 0 0;
				display: inline-block;
				float: right;
				cursor: pointer;
				background-size: 17px 9px;

				&.open {
					background: url('/images/nav/chevron-up.png') 0 0;
					background-size: 17px 9px;
				}
			}

			&.mobile {
				display: block;
			}
			&.disabled {
				// border-color: $md-gray4;
				cursor: default;

				.day,
				.month,
				.weekday,
				.week,
				.fw-day,
				.fw-week {
					color: $md-gray4;
				}
			}
			&.select {
				background-color: $usoDkBlue;
				border-bottom: solid 4px $usoYellow !important;
				color: $white !important;
				cursor: pointer;
				padding-bottom: 1px;

				.date-as-calendar {
					background-color: $usoDkBlue;
				}

				.day,
				.month,
				.weekday,
				.week,
				.fw-week,
				.fw-day {
					color: $white !important;
				}

				.day,
				.fw-day {
					@include interstate(regular);
				}
			}
			&:hover {
				color: $usoLtBlueBG;
				cursor: pointer;

				.date-as-calendar {
					@include interstate(light);
				}

				.day,
				.month,
				.weekday,
				.week,
				.fw-week,
				.fw-day {
					color: $usoLtBlueBG;
				}
			}
		}
		.stadium_ticket {
			display: inline-block;
			width: 100%;
			height: auto;
			background-color: $lt-gray;
			position: relative;
			margin: 5px 0px;
			border: 1px solid $usoLtGray;
			text-align: left;
			overflow: hidden;
			vertical-align: top;

			/* @include general-breakpoint(landscape) { width: 48%; margin: 10px 5px;height: 315px; } */
			/* @include general-breakpoint(tablet) { width: 48%; height: 245px; } */
			&.show {
				display: inline-block;
			}
			&.no-tickets {
				height: 100px;
				text-align: center;
				padding-top: 38px;
				@include interstate(regular);
				font-size: 14px;
			}

			.titleHeader {
				width: 100%;
				background-color: $white;
				padding: 5px;
				border-bottom: 1px solid $usoLtGray;

				h2 {
					display: inline-block;
					width: 100%;
					margin: 40px 20px;
					@include interstate(regular);
					font-size: calc((18/14) * 1em);
					color: $dk-gray2;
					text-transform: uppercase;
					text-align: left;
				}

				h3 {
					display: inline-block;
					width: 100%;
					margin: 0 0 0 20px;
					padding: 0;
					@include interstate(light);
					font-size: calc((14/14) * 1em);
					color: $dk-gray2;

					@include media-breakpoint-up(sm) {
						font-size: calc((18/14) * 1em);
					}

					@include media-breakpoint-up(md) {
						font-size: calc((24/14) * 1em);
					}

					a {
						color: $dk-gray2;
					}
				}

				.amt {
					float: right;
					text-align: right;
					width: 30%;
					display: inline-block;
					font-size: calc((16/14) * 1em);
					@include interstate(light);
					color: $white;

					@include general-breakpoint(tablet) {
						font-size: calc((18/14) * 1em);
						width: 20%;
					}
				}

				.info {
					display: none;
				}

				@include general-breakpoint(smdesktop) {
					display: flex;
					justify-content: space-between;
					align-items: center;

					h2 {
						width: 25%;
					}
					.amt {
						display: none;
					}
					.info {
						display: inline-block;
						padding: 0;
						width: 65%;
						margin: 0 0 0 2%;
						border: none;
						font-size: 14px;
						@include interstate(light);

						.ticket_row {
							display: inline-block;
							width: 48%;
							margin-right: 5px;
						}
						.ticket_desc {
							padding-left: 0;

							ul {
								margin-left: 0;
								padding-inline-start: 25px;

								li {
									line-height: 1.2em;
								}
							}
						}
					}
				}
			}

			.info {
				display: inline-block;
				padding: 5px;
				@include interstate(light);
				font-size: calc((14/14) * 1em);
				width: 94%;
				margin: 0 3%;
				// border-top: 1px solid gray;

				@include general-breakpoint(landscape) {
					font-size: calc((16/14) * 1em);
				}

				@include general-breakpoint(smdesktop) {
					display: none;
				}

				.ticket_row {
					display: block;
					padding-top: 10px;
					text-align: left;
				}

				.ticket_name {
					display: block;
					padding-right: 5px;
					padding-bottom: 10px;
				}

				.ticket_desc {
					padding-left: 10px;
					font-size: 1em;

					@include general-breakpoint(tablet) {
						padding-left: 20px;
					}

					ul {
						padding-top: 0;
						margin-left: 20px;

						li {
							line-height: 1.6em;
						}
					}
				}

				&.compact-info {
					width: 100% !important;
					padding: 10px 2% !important;
					margin: 0 !important;
				}
			}

			.tickets {
				display: block;
				width: 100%;
				padding: 10px 20px;
				vertical-align: top;
				@include interstate(regular);

				.header_row {
					margin-bottom: 12px;
				}
				.single_ticket {
					margin: 0;
					font-size: calc((14/14) * 1em);
					@include interstate(light);
					background-color: $white;
					border-top: 1px solid $usoLtGray;
					color: $dk-gray2;

					&:last-child {
						border-bottom: 1px solid $usoLtGray;
						margin-bottom: 12px;
					}
					.day,
					.session,
					.time,
					.event {
						padding-top: 10px;
						padding-bottom: 10px;
					}
					.day {
						padding-left: 15px;
					}
					.event {
						padding-right: 0;
					}
					.link {
						padding-right: 15px;
					}
				}

				.day {
					display: inline-block;
					width: 14%;
					vertical-align: middle;
					padding-left: 15px;
				}

				.session {
					display: inline-block;
					width: 13%;
					vertical-align: middle;
				}

				.time {
					display: inline-block;
					width: 11%;
					vertical-align: middle;
					line-height: 14px;
				}

				.time_icon {
					display: block;
					margin: 0 auto;

					&.am {
						background: url(/assets/images/misc/day_icon.png) no-repeat 0 0;
						background-size: contain;
						width: 14px;
						height: 14px;
					}

					&.pm {
						background: url(/assets/images/misc/night_icon.png) no-repeat 0 0;
						background-size: contain;
						width: 12px;
						height: 12px;
					}

					@include general-breakpoint(landscape) {
						display: inline-block;
						margin: 0;
					}
				}

				.event {
					display: inline-block;
					vertical-align: middle;
					width: 35%;
				}

				.link {
					display: inline-block;
					text-align: right;
					padding: 12px 15px 12px 0;
					width: 27%;

					a {
						text-decoration: none;
						cursor: pointer;
						text-transform: uppercase;
						white-space: nowrap;
						color: $white;
						background-color: $usoLtBlueBG;
						padding: 10px;
						@include interstate;
						height: 40px;
						width: 160px;
						border-radius: 7px;
						font-size: 14px;
						display: inline-block;
						text-align: center;
						border: 2px solid transparent;
						span {
							position: relative;
							top: -3px;
							cursor: pointer;
							@include general-breakpoint(landscape) {
								top: 6px;
							}
						}

						@include media-breakpoint-up(md) {
							// padding: 5px 10px;
						}
						&:hover {
							background-color: #fff;
							color: #418fde !important;
							border: 2px solid $usoLtBlueBG;
						}
					}
				}
			}

			.highlight {
				font-size: calc((14/14) * 1em);
				color: $dk-gray2;
				text-transform: uppercase;
				@include interstate(bold);
				text-align: left !important;

				@include general-breakpoint(landscape) {
					// font-size: calc((10/14) * 1em);
				}

				@include general-breakpoint(tablet) {
					// font-size: calc((12/14) * 1em);
				}

				&.link {
					display: none;
				}
			}
		}

		@include general-breakpoint(smtablet, max) {
			.selectDatesInfo {
				padding: 0;
			}
			.date {
				height: 81px;
				padding: 5px;
				width: calc(100% / 7);
				// width: 46px;
				display: inline-block !important;
				border: 1px solid $lt-gray3;
				margin-left: 0;
				margin-right: 0;

				&.select {
					padding-bottom: 1px;
					height: 81px;
				}
				// &:first-child,
				// &:nth-child(8n) {
				// 	border-left: 0;
				// }
				// &:nth-child(7n),
				// &:last-child {
				// 	border-right: 0;
				// }
				// &:nth-child(n + 0):nth-child(-n + 7) {
				// 	border-bottom: 0;
				// }
				// &:nth-child(n + 2):nth-child(-n + 7) {
				// 	border-left: 0;
				// }
				// &:nth-child(n + 9):nth-child(-n + 14) {
				// 	border-left: 0;
				// }
				&.select {
					&:nth-child(n + 7):nth-child(-n + 14) {
						padding-bottom: 2px;
					}
				}
			}
			&.fanweek {
				.ticketWrapper {
					.date {
						width: calc(100% / 7);
						&.fanweek {
							width: 115px;
							height: 81px;
						}
					}
				}
			}
			.stadium_ticket {
				width: 100%;

				.titleHeader {
					h2 {
						font-size: calc((16/14) * 1em);
						margin: 20px 0;
						text-align: center;
					}

					h3 {
						font-size: calc((14/14) * 1em);
					}
				}

				.info {
					@include interstate(light);
					padding-top: 0px;

					@include general-breakpoint(landscape) {
						font-size: calc((16/14) * 1em);
					}

					.ticket_row {
						padding-top: 0px;

						ul {
							margin-left: 0;
							padding-left: 15px;
						}
					}
				}

				.tickets {
					padding: 10px 15px;

					.single_ticket {
						margin: 0 0 12px 0;
						border-bottom: 1px solid $usoLtGray;

						.day {
							padding-left: 7px;
							vertical-align: top;
						}
						.session {
							padding-left: 15px;
							vertical-align: top;
						}
						.event {
							padding-right: 7px;
							vertical-align: top;
						}
						.time {
							// vertical-align: top;
						}
						.link {
							width: 100%;
							border-top: 1px solid $usoLtGray;
							padding: 15px 10px;

							a {
								width: 100%;
							}
						}
					}
					.day {
						padding-left: 7px;
						width: 17%;
					}

					.session {
						width: 19%;
					}

					.time {
						width: 19%;
					}

					.event {
						width: 45%;
					}
				}

				.highlight {
					font-size: calc((12/14) * 1em);

					&.link {
						display: none;
					}
				}
			}
		}
		@include general-breakpoint(smtablet) {
			.date {
				width: 44.27px;
				&.fanweek {
					width: 89.4px;
				}
			}
		}
		@include general-breakpoint(smdesktop) {
			.date {
				width: 59px;
				&.fanweek {
					width: 119.2px;
				}
			}
		}
		@include general-breakpoint(desktop) {
			.date {
				width: 78px;
				&.fanweek {
					width: 159px;
				}
			}
		}
	}

	.selectTime {
		background-color: $usoDkerBlue2;
		padding: 18px 20px;
		width: 100%;
		height: 62px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.text {
			color: $white;
			text-transform: uppercase;
			@include interstate(bold);
			font-size: 12px;
			width: 40%;
			text-align: left;
		}
		.tickets-select-menu {
			width: 164px;
			select {
				@include interstate();
				appearance: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				background-image: url('/assets/images/icons/dropdown-arrow-ltgray.svg');
				background-repeat: no-repeat;
				background-position-x: 94%;
				background-position-y: 50%;
				background-color: $usoDkBlue;
				width: 180px;
				color: #fff;
				padding: 4px 16px 4px 4px;
				border-radius: 3px;
				border-color: $white;
				text-align: center;
				line-height: normal;
			}
		}

		@include general-breakpoint(landscape) {
			justify-content: center;

			.text {
				margin-right: 15px;
				width: 45%;
			}
		}
		@include general-breakpoint(smdesktop) {
			.text {
				width: auto;
			}
		}
	}

	.general_full {
		overflow: hidden;
		padding: $general_outer_margin;
		width: 100%;

		h3 {
			clear: both;
		}

		&.background {
			@include general-breakpoint(tablet) {
				background: transparent url(/images/misc/bg_twocolumns.png) repeat-y 0 0;
				background-size: contain;
				background-position: center;
			}
		}

		&.calendar {
			padding: 0px;

			@include general-breakpoint(landscape) {
				padding: 20px 0px;
			}
		}

		.intro {
			padding-bottom: $general_outer_margin;

			@include general-breakpoint(landscape) {
				border-bottom: solid 1px $light-blue;
			}

			@include general-breakpoint(tablet) {
				float: left !important;
				width: 68%;
				padding-right: $general_outer_margin;
			}

			h3 {
				&:first-child {
					padding-top: 0px;
				}
			}
		}

		.leftAlign {
			@include general-breakpoint(tablet) {
				float: left !important;
				width: 68%;
				padding-right: $general_outer_margin;
			}
		}

		.rightAlign {
			padding-top: $general_outer_margin;
			padding-left: $general_inner_margin;

			@include general-breakpoint(tablet) {
				float: right !important;
				padding-top: 0;
				width: 32%;
			}

			&.lightBlue {
				background-color: $light-blue2;
			}

			&.embed {
				margin: 0 - $general_outer_margin;
				padding: $general_outer_margin;

				@include general-breakpoint(landscape) {
					margin: 0 - $general_outer_margin;
				}

				@include general-breakpoint(tablet) {
					padding: $general_outer_margin $general_outer_margin;
				}
			}

			/* responsiveImg in the right column that adjust the size of the image relative to the container width
         used to adjust the sponsors logos on travel packages */
			.responsiveImg {
				text-align: center;

				img {
					max-width: 100%;
				}
			}

			h3 {
				&:first-child {
					padding-top: 0px;
				}
			}

			hr {
				background-color: $border_color;
				color: $border_color;
			}
		}
	}

	.general_right {
		background-color: $light-blue2;
		color: $white;
		padding: $general_outer_margin;

		@include general-breakpoint(tablet) {
			float: right !important;
			width: 32%;
		}
	}

	.general_left {
		background-color: $blue;
		color: $white;
		padding: $general_outer_margin;

		@include general-breakpoint(tablet) {
			float: left !important;
			width: 68%;
		}
	}

	.linkBtn {
		background: $dark_blue2 url(/images/misc/arrow_right_ltblue.png) no-repeat 90% 50%;
		border: solid 1px $border_color;
		font-size: calc((11/13) * 1em);
		margin-top: $general_outer_margin;
		padding: 0.5em;
		text-align: center;
		text-transform: uppercase;
		width: 100%;

		a {
			@include interstate(bold);
			color: $button_link_color;
			display: block;
			text-decoration: none;
		}
	}

	.teaser {
		background: $dark_blue2;
		clear: both;
		color: $button_link_color;
		border: solid 1px $border_color;
		margin-top: $general_outer_margin;
		padding: 0.5em;
		width: 100%;

		h4 {
			@include interstate;
			padding-top: 0.5em;
		}

		p {
			padding-top: 0.5em;
		}
	}

	.q {
		font-size: calc((18/14) * 1em);
		padding-top: $general_outer_margin;
	}

	.a {
		p {
			padding-top: 0;
			margin-left: 17px;
			text-indent: -15px;

			&:before {
				content: ' \25CF';
				font-size: 10px;
				padding-right: $general_inner_margin;
			}
		}
	}

	/* mobile entry accordian settings */

	#accordian_container {
		> h3 {
			background: $white url(/images/misc/accordion_open.png) no-repeat 95% 50%;
			color: $dk-gray2;
			@include interstate(light);
			border: solid 1px #c1c6c8;
			margin-top: 20px;
			padding: 0.5em;
			width: 100%;
			padding-top: 10px;
		}

		.accordian-cnt {
			padding: 5px;

			.wrapped {
				img {
					display: inline;
					vertical-align: top;

					@include general-breakpoint(landscape) {
						width: 50%;
					}

					@include general-breakpoint(tablet) {
						width: 33%;
					}
				}
			}

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	.siteDetailContainer {
		@include make-container();
		padding: 10px 0;
		@include media-breakpoint-up(sm) {
			padding: 20px 0;
			display: flex;
			justify-content: center;
		}
		background-color: $white;
		.intro {
			@include make-col-ready();
			@include media-breakpoint-up(sm) {
				@include make-col-offset(1);
				@include make-col(10);
			}
		}

		.siteDetail {
			@include make-col-ready();
			font-size: 1.3rem;

			@include media-breakpoint-up(sm) {
				@include make-col(9);
			}
			.photo-inline {
				&.center {
					img {
						width: 100%;
					}
				}
			}
			&.two-column-desktop {
				columns: 300px 2;
				column-gap: 45px;
				justify-content: center;
				ul {
					padding: 0;
				}
			}
		}
		&.image {
			padding: 0 !important;
			.photo-inline {
				img {
					width: 100%;
				}
				&.left {
					width: 75%;
					img {
						width: auto;
					}
				}
			}
		}
		&.center {
			text-align: center;
		}
	}
	.tabs-container {
		background-color: $usoBkgGray;
	}
	.contentWrapper, .moduleWrapper {
		margin-top: 40px;
	}

	.intro-text {
		margin: 0 auto 25px auto;
		padding: 30px;
		color: $usoDkGray;
		background-color: $white;
		&:nth-child(2) {
			margin-top: 0;
		}
		.center {
			text-align: center;
		}
	}
} // general_content

.contentWrapper {
	margin-left: auto;
	margin-right: auto;

	&.contentWrapper-border {
		border-top: solid 4px $usoLtBlue;

		&-dkblue {
			border-color: $usoDkBlue;
		}

		&-blue {
			border-color: $usoLtBlue;
		}

		&-ltblue {
			border-color: $usoLtBlue;
		}

		&-green {
			border-color: $usoGreen;
		}

		&-yellow {
			border-color: $usoYellow;
		}

		&-orange {
			border-color: $usoOrange;
		}

		&-red {
			border-color: $usoRed;
		}
		&-none {
			border-top: 0;
		}
	} // .contentWrapper-border

	.title-bar {
		display: flex;
		padding: 10px 15px;
		position: relative;
		border-bottom: solid 1px $usoltMedGray;
		background-color: $white;
		height: 60px;

		&.no-shadow {
			box-shadow: none;
		}

		h3 {
			align-self: center;
			color: $usoDkGray;
			text-transform: uppercase;
			@include interstate(bold);
			font-size: px2rem(20);
			line-height: 20px;
			margin-bottom: 0;
			padding-top: 0;
		}

		.sponsor {
			margin-left: auto;
			align-self: center;

			.label {
				color: $usoMdGray;
				display: block;
				font-size: px2rerm(10);
				&.noshow {
					visibility: hidden;
				}
			}

			.logo {
				display: block;

				img {
					border: 0;
					height: 17px;
					&.rolex {
						height: 30px;
					}
					&.amex {
						height: 30px;
						display: block;
						margin-left: auto;
						margin-right: 0;
					}
				}
			}

			a,
			a:hover {
				text-decoration: none;

				&.uppercase {
					text-transform: uppercase;
				}
			}
		} // .sponsor

		.col {
			align-self: center;

			&.right {
				text-align: right;
			}
		}
	} // .title-bar

	.full-col {
		width: 100%;

		&.center {
			text-align: center;
		}
	}
}

.siteDetailContainer {
	#sitemap {
		ul {
			list-style: none;
			&.main_menu {
				columns: 2;
			}
		}
		.main_menu_item {
			@include interstate(bold);
			font-size: 1.3rem;
			color: $dk-gray2;
			break-inside: avoid-column;
			a {
				color: $dk-gray2;
				// text-decoration: none;
				&:hover {
					color: $cyan;
				}
			}
		}
		.submenu_item {
			@include interstate;
			a {
				color: $dk-gray2;
				text-decoration: underline;
				&:hover {
					color: $cyan;
				}
			}
		}
	}
}

.webview-back-link {
	height: 24px;
	font-size: 22px;
	margin: 5px 10px;
	@include interstate;
	a {
		color: $dk-gray2;
		.icon-arrow-large-left {
			font-size: 26px;
			vertical-align: text-bottom;
		}
	}
}

/** override to make some pages not so bad looking */
.prize_money {
	.siteDetailContainer {
		background-color: $usoBkgGray;
	}

	.general_content {
		background-color: $usoBkgGray;
	}
}

.court_of_champions {
	.siteDetailContainer {
		color: $usoDkGray;
		padding-top: 20px;
	}
}
