/** daynav styles **/
@mixin scores-button-reset {
	border: none;
	padding: 0px;
	align-items: inherit;
	color: inherit;
	background-color: transparent;
}
@mixin scores-dropdown-reset {
	background: none;
	overflow: auto;
	border: none;
	position: relative;
	top: auto;
	right: auto;
	z-index: auto;
	width: auto;
}
.daynav-wrapper {
	width: 100%;
	.tournament-day-selector-wrapper {
		@include interstate();
	}
	.daynav {
		clear: both;
		&.scores-select-menu {
			padding: 0;
			background-color: #f3f3f3;
			.category {
				display: none;
			}
			.select-menu {
				float: none;
				@include interstate(medium);
				font-size: 14px;
				.text-value {
					display: none;
				}
				.select-menu-dropdown {
					list-style: none;
					&.tablet {
						display: none;
					}
					button {
						&[data-type='label'] {
							display: none;
						}
						&[data-event=''] {
							display: none;
						}
						&.disabled {
							cursor: default;
						}
					}
				}

				@include general-breakpoint(smtablet, max) {
					.title {
						padding: 4px;
					}
				}

				button[data-type='ms'] {
					display: none;
				}
			}
			@include general-breakpoint(smtablet) {
				width: 100%;
				.page .wrapper.scorespage & {
					clear: both;
					float: none;
					margin-bottom: 0px;
				}
				.selected {
					color: $dk-gray2;
				}
				.category {
					display: block;
					width: auto;
					text-transform: uppercase;
					font-size: 16px;
					text-align: center;
					color: $md-gray;
					@include interstate(regular);
					display: flex;
					flex-direction: row;
					justify-content: center;
					.daynav-cat {
						padding: 0;
						cursor: pointer;
						height: 30px;
						line-height: 30px;
						&.selected {
							color: $dk-gray2;
							border-bottom: solid 2px $dk-gray2;
						}
					}
					.separator {
						border-left: solid 1px $lt-gray3;
						margin: 0px 5px;
					}
				}
				> .select-menu {
					position: relative;
					text-align: center;
					color: gray;
					width: auto;
					height: 32px;
					.dropdown-value {
						display: none;
					}
					.text-value {
						display: block;
					}
					i {
						display: none;
					}

					button {
						@include scores-button-reset;
						width: 100%;
						text-transform: uppercase;
						font-size: 14px;
						margin-bottom: 10px;
						border: solid 1px black;
						span {
							padding: 0px 15px;
						}
						&.title {
							display: none;
						}
					}
					.select-menu-dropdown {
						@include scores-dropdown-reset;
						display: inline;
						margin: 0px;
						padding: 0px;
						height: 32px;
						&.mobile {
							display: none;
						}
						&.tablet {
							display: flex;
							flex-direction: row;
							transform: translateX(0);
							justify-content: center;
						}
						button {
							@include scores-button-reset;
							display: inline-block;
							float: none;
							margin: 0px;
							width: 32px;
							height: 32px;
							line-height: 30px;
							text-align: center;
							font-size: 14px;
							&:first-child {
								margin-left: 0;
							}
							&[data-type='label'] {
								width: auto;
								display: inline-block;
								border-right: solid 1px $md-gray2;
								padding-right: 5px;
							}
							&[data-type=''] {
								display: none;
							}
							&[data-event=''] {
								display: block;
							}
							&.option {
								border: solid 1px $md-gray2;
								border-left: none;
								background-color: $lt-gray2;
								color: $dk-gray2;
							}
							&.selected {
								color: $white;
								background-color: $dk-gray2;
								font-weight: bold;
								line-height: 30px;
							}
							&.disabled {
								color: $md-gray;
							}

							.date {
								display: none;
							}
							a {
								color: gray;
								display: block;
								width: 100%;
								height: 100%;
								text-align: center;
							}
							span {
								padding: 0px;
								&.option-name {
									display: block;
									width: 100%;
									height: 100%;
								}
							}
						}
					}
					&[data-type='tourn'] {
						//button[data-type="quali"]{display:none;}
						//button[data-type="ms"]{display:block;}
					}
					&[data-type='quali'] {
						//button[data-type="tourn"]{display:none;}
						//button[data-type="ms"]{display:none;}
					}
				}
			}

			&.dark {
				@include general-breakpoint(smtablet) {
					> .select-menu {
						color: $white;

						button {
							border: none;
						}

						.select-menu-dropdown {
							button {
								&[data-type='label'] {
									border: none !important;
								}

								&.option {
									// border: none!important;
									border-color: $usoDkBlue;
									background-color: transparent !important;
									color: $white !important;
								}

								&:focus,
								&:active {
									border-top-color: $usoDkBlue;
									border-right-color: $usoDkBlue;
									border-left-color: $usoDkBlue;
									outline: unset;
								}

								&.selected {
									border-top-color: $usoDkBlue;
									border-right-color: $usoDkBlue;
									border-left-color: $usoDkBlue;
									border-bottom: 3px solid $white;
									background-color: transparent !important;
									font-weight: bold;
								}
							}
						}
					}
				}
			}
		}
	}
}

@include general-breakpoint(tablet) {
	[data-dna='daynav'] {
		height: auto;
	}
}

@include general-breakpoint(tablet, max) {
	.scores-select-menu {
		.select-menu {
			.title {
				padding: 4px;
			}
		}
	}
}

@include general-breakpoint(smtablet) {
	.daynav-wrapper {
		.daynav {
			&.scores-select-menu {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-content: center;

				.select-menu {
					.title {
						font-size: 16px;
					}
				}
			}
		}
	}
}

@include general-breakpoint(smdesktop) {
	.daynav-wrapper {
		.daynav {
			&.scores-select-menu {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				align-content: center;
			}
		}
	}
}
