.wrapper {
    &.slamtracker {
        background: $white;
            
        #sub_header { display: none; }
        h1.header {
            height: 48px;
            line-height: 48px;
            text-align: center;
            font-size: 22px;
            padding-top:0px;
            padding-bottom:0px;
            border-top: 1px solid $md-gray2;
            border-bottom: 1px solid $md-gray2;

            &:after {
                border: none;
            }
        }
        .content-main {
            background-color: $lt-gray2;
            padding-bottom: 0px;
            padding-top: 12px;
            display: flex;
            flex-direction: row;
                
            .content--filters-scroll {
                margin-top: 0px;
            }
            .content-filters-spacer {
                width: 100%;
                height: 85px;
                display: none;
            }
            &.fixed {
                .content-filters-spacer {
                    display: block;
                }
            }
            .connection-type {
                position: absolute;
                bottom:-3px;
                left:0px;
                text-align: left;
            }
            @include general-breakpoint(landscape) {
                padding-top: 0;
            }
        }
        .top-border {
            width: 100%;
            height: 9px;
            margin-bottom: 10px;

            .team1 {
                float: left;
                width: calc(50% - 1px);
                height: 100%;
                background-color: $team1_color;
            }
            .team2 {
                float: right;
                width: calc(50% - 1px);
                height: 100%;
                background-color: $team2_color;
            }
        }
        &.webview {
            .content-main {
                // min-height: 100vh;
                // height: 100vh;
            }
        }

        @include general-breakpoint(smtablet) {
            #sub_header { display: block; }
            h1.header {
                height:auto;
                line-height: auto;
                text-align: left;
                padding: 25px 0 17px 16px;
                padding-left: 5%;
                font-size: 2.8rem;
            }
            .content-main {
                // height: 675px;
                padding-top: 32px;
                padding-bottom: 20px;
                    
                .connection-type {
                    // color:$tennis-court-green;
                }
            }
            &.webview {
                .content-main {
                    // min-height: 100vh;
                    // height: 100vh;
                }
            }
        }
    }
    &.hideHeader {
        .header-global { display: none; }
        .mainNav { display: none; }
        .page-header { display: none; }
    }
    &.st-mobile-view{
      // height: 100%;
      // overflow-y: scroll;
      height: 100% !important;
      .content-filters-spacer{ height: 0px !important; }
      .content-area {height: 100% !important; }
    }
    &.st-page{
      .sticky-header-wrapper { 
        min-width: 373px;
        width: inherit !important;
        left: 0;
        right: 0;
      }
    }
}

.slamtracker {
    .slamtracker-content {
        width: 100%;
        height: 100%;
        margin: 0px;
        position: relative;
        // background-image: url('/assets/images/scores/slamtracker/backgrounds/background_1x1_slamtracker.png');
        // background-repeat: repeat-y;

        .error-indicator {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
    .featured-match {
        width: 100%;
        height: auto;
        margin: 0;
        // padding: 15px;
        position: relative;
        background-color: $dark-blue3;
        // padding: 12px 12px;

        .match-box-container{
          // width: 343px;
          margin: auto;
          padding: 12px 12px;
        }
        .match-box {
          min-height: unset;
            .match-status {
                // height: unset;
                // min-height: unset;
            }
            .prematch-container {
                margin-bottom: 0;
            }
        }
        .more { 
            display: none;
            width: 100%;

            @include general-breakpoint(smtablet) {
                display: table;
            }
        }
    }
    .content-tabs {
        width: 100%;
        height: 37px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: $dark-blue3;
        border-top: 1px solid $md-gray;
        border-bottom: 1px solid $md-gray;

        a, a:hover {
            color: $white;
            font-size: 14px;
            cursor: pointer;
            @include interstate;
            position: relative;
            line-height: 34px;
            margin: 0 30px;

            &.selected, &.selected:hover {
                &:after {
                    content: '';
                    height: 3px;
                    width: 100%;
                    background-color: $white;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                @include interstate(bold);
            }
            &.disabled, &.disabled:hover {
                color: $white;
                opacity: .4;
                cursor: default;
            }
        }
        // .full-content {
        //     display: flex;
        //     flex-direction: row;
        //     justify-content: space-around;
        //     align-items: center;
        //     height: 100%;
        // }
    }
    .content-area {
        height: calc(100vh - 188px); // if I want boxes to stop just short of the browser view height
        @include panel-content-background;
        // background-color: $white;
        // border-top: 1px solid $md-gray2;

        // .full-content {
        //     background-color: $white;
        // }
    }
    .panel {
        width: 100%;
        height: 100%;
        min-height: 100%;
        display: none;

        &.selected {
            display: block;
            // background-color: $usoltMedGray;
            @include panel-content-background;
        }
        .panel-content {
            @include panel-content-background;
        }
        .content {
            width: 100%;
            height: 100%;
            position: relative;
            // overflow-y: auto;

            &.stub { 
                display: flex;
                align-items: center;
                justify-content: center;
                
                .stub-text {
                    .stub-content {
                        color: $dk-gray2;
                        border-top: 1px solid $dk-gray2;
                        border-bottom: 1px solid $dk-gray2;
                        padding: 12px 20px;
                        text-transform: uppercase;
                        font-size: 16px;
                        text-align: center;
                        @include interstate(medium); 
                    }
                }
            }
        }
        .error-indicator {
            color: $dk-gray2;
            padding: 60px 20px 0 ;
            text-transform: uppercase;
            font-size: 16px;
            text-align: center;
            @include interstate(medium); 
            margin-top: 0px;
        }
    }
    // .full-content {
    //     width: 100%;
    // }
    .content-allmatches {
        display: none;
    }
    .fixed {
        .featured-match {
          .sticky-header-wrapper {
            position: fixed;
            top: 56px;
            // height: 113px;
            z-index: 12;
            // left: 0;
            // right: 0;
            padding-top: 13px;
            background-color: #00288c;
            width: inherit;
            // max-width: inherit;
            margin: 0 auto;
            width: 373px;
            @media only screen and (max-width: 767px) {
              width: 100%;
              left: 0;
              right: 0;
            }
            @include general-breakpoint(smtablet) {
                width: 385px;
                margin-left: -5px;
            }
          }
            
            // .match-box-container {
            //     .match-box {
            //         min-height: auto;

            //         .match-location {
            //             display: none;
            //         }
            //         .match-status {
            //             height: 85px;
            //             padding-top: 0px;
            //             background-color: unset;
                        
            //             .team-info.team-one { margin-top: 0px; }
            //             .results-info { top: 40px; }
            //             .event-info, .event-notes {
            //                 display: none;
            //             }
            //         }
            //         .live-buttons {
            //             display: none;
            //         }
            //     }
            // }
        }
        // .content-container {
        //     position: fixed;
        //     top: 160px;
        //     width: 100%;
        //     height: calc(100vh - 311px);
        // }
        // .content-tabs {
        //     position: fixed;
        //     top: 169px;
        //     left: 0;
        //     right: 0;
        //     z-index: 12;
        // }
        .sticky-header-wrapper {
          box-shadow: 0px 2px 3px rgb(0 0 0 / 25%);
        }
    }
    .news-tile {
        .image-container {
            margin-top: 15px;
        }
        .image-container, .news-timestamp, .news-title {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .slamtracker-powerranking {
        background-color: $white;
        width: 100%;
        margin-bottom: 10px;

        .mi-section {
            width: $panel_content_width;
            margin: 0 auto !important;

            .card-head {
                .courtName {
                    padding-top: 0;
                }
            }
        }
        .where-to-watch {
            padding-bottom: 5px;
        }
    }
    &.webview {
        .slamtracker {
            .slamtracker-content {
                // height: 100%;
                margin: 0 auto;
            }
            .content-area {
                // height: calc(100% - 194px);
                // overflow-y: auto;
                background-color: transparent;
            }
        }
    }

    // @include general-breakpoint(smtablet) { // 768px wide
    //     .slamtracker-content {
    //         float: none;
    //         width: 94%;
    //         height: 100%;
    //         margin: 0px auto;
    //     }
    //     .featured-match {
    //         width: 100%;
    //         height: auto;
    //         padding: 0px;
    //         background-color: unset;
    //         margin-bottom: 15px;

    //         .more { 
    //             display: inline;
    //         }
    //     }
    //     .content-area {
    //         // background-image: url('/assets/images/scores/slamtracker/backgrounds/background_1x1_slamtracker.png');
    //         // background-repeat: repeat-y;
    //         background-color: $white;
    //         width: 100%;
    //         padding: 8px 10px;
    //         padding-top: 0;
    //         left: 0px;
    //         right: 0px;
    //         border-top: none;
    //         // height: auto;
    //         display: flex;
    //         flex-direction: row;
    //         justify-content: space-between;
        
    //         // .right-content {
    //         //     background-color: $white;
    //         //     height: 100%;
    //         //     overflow-y: auto;
    //         //     border: 1px solid rgba(0,0,0,0.4);
    //         // }
    //         // .left-content {
    //         //     background-color: $white;
    //         //     height: 100%;
    //         //     overflow-y: auto;
    //         //     border: 1px solid rgba(0,0,0,0.4);
    //         // }
    //         // .full-content {
    //         //     position: absolute;
    //         //     top: 0px; 
    //         //     left: 0px;
    //         //     width: 100%;
    //         //     height: 100%;
    //         //     background-color: $white;
    //         // }
    //     }
    //     // .right-content, .left-content {
    //     //     width: calc(50% - 4px);
    //     // }
    //     &.webview {
    //         .content-area {
    //             background-color: $white;
    //         }
    //     }
    // }

    .immersiv-container {
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid $usoLtGray;

        .immersiv-cta {
            width: 347px;
            height: 31px;
            margin: 0 auto;
            @include button();

            &:hover {
                background-color: #2478CC;
                color: $white;
                border: none;
            }
            .arrow-icon {
                letter-spacing: -4px;
                padding-left: 6px;

                i{
                    @include interstate(bold);
                    font-size: 9px;
                }
            }
        }
    }

    .relatedContentContainer {
        .showMoreContainer {
            .showMoreBtn {
                margin-bottom: 0;
                margin-top: 0;
                @include button();
                width: 157px;
                height: 34px;
                padding: unset;
                letter-spacing: unset;
            }
        }
    }

    .view-more-matches-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #F5F5F5;
        margin-top: 20px;
        @include panel-content-background;

        .view-more-matches button {
            min-width: 100px;
            width: auto;
            height: 35px;
            line-height: 20px;
            margin: auto;
            @include interstate;
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
        }
    }

    .t1, .t2{
        &.profile-button{
            .player-profile-button-container{
                display: flex;
            }
        }
    }
    .t2.profile-button .player-profile-button-container{
        justify-content: flex-end;
    }
    .player-profile-button-container{
        .player-profile-button{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $usoLtBlueBG;
            border-radius: 6px;
            padding: 0px;
            height: 100%;
            min-height: 28px;
            cursor: pointer;
            color: white;
            text-transform: uppercase;
            font-size: px2rem(10);
            width: 90px;
            &:hover{
                background-color: white;
                text-decoration: none;
                border: 1px solid $usoLtBlueBG;
                color: $usoLtBlueBG;
            }
        }
    }

    // @include general-breakpoint(smdesktop) { // 1024px wide
    //     .content-allmatches {
    //         display: block;
    //         width: 34%;
    //         height: 623px;
    //         // overflow-y: auto;

    //         .slamtracker-matches {  
    //             width: 94%;
    //             margin-left: 4%;
    //             margin-right: 2%;
    //             margin-bottom:10px;
    //             padding:0px;
                
    //             .content {
    //                 position: relative;
    //                 padding: 0 0px 50px;
    //                 display: flex;
    //                 flex-flow: column;
    //                 flex-grow: 1;
    //                 min-height: 0;
                    
    //                 .slamtracker-match-box-container {  
    //                     > a {
    //                         display: block;
    //                         margin-bottom: 10px;
    //                         text-decoration: none;
                            
    //                         &.selected {
    //                             .match-box-border { border: 2px solid $gray-4a; }
    //                             // .event-court {
    //                             //     border: 2px solid $gray-4a;
    //                             //     border-bottom: none;
    //                             // }
    //                             // .team-info {
    //                             //     border-right: 2px solid $gray-4a;
    //                             //     border-left: 2px solid $gray-4a;
    //                             // }
    //                             // .team-info.team-two {
    //                             //     border-bottom: 2px solid $gray-4a;
    //                             // }
    //                         }
    //                         .match-box-link {
    //                             text-decoration: none;
    //                             display: block;
    //                         }
    //                     }
    //                 }
    //                 .header-content {
    //                     width: 100%;
    //                     display: table;
    //                     margin-bottom: 15px;

    //                     .header-text {
    //                         display: table-cell;
    //                         vertical-align: middle;
    //                         width: 50%;
    //                         @include interstate;
    //                         font-size: 18px;
    //                         color: $black;
    //                         text-transform: uppercase;

    //                         .live {
    //                             display: inline;
    //                             margin: 0px 5px;
    //                             color: #bde403;
    //                         }
    //                     }
    //                     .header-favorites {
    //                         display: table-cell;
    //                         vertical-align: middle;
    //                         //width: 25%;

    //                         span {
    //                             display: none;
    //                         }
    //                         .favorites-toggle.on.outline:after {
    //                             left: 4px;
    //                             top: 4px;
    //                             color: $usoLtBlue;
    //                             font-size: 29px;
    //                         }
    //                     }
    //                     .header-scores {
    //                         display:table-cell;
    //                         vertical-align: bottom;
    //                         text-align: right;
    //                         font-size: 25px;
    //                         color:$white;
    //                         width:26px;
    //                         padding-bottom:2px;
    //                         line-height: 0px;
    //                         a {color:white;}
    //                     }
    //                 }
    //                 .member-one,.member-two{cursor: default;}
    //                 .nomatches {
    //                     background-color: $gray-light;
    //                     padding: 50px 15px;
    //                     font-size: 1.5em;
    //                     color: $dk-gray2;
    //                     @include interstate();
    //                     text-align: center;
    //                     width: 80%;
    //                     margin: 0 auto;
    //                 }
    //             }
    //         }
    //     }
    //     .slamtracker-content {
    //         width: 59%;
    //     }
    //     .featured-match {
    //         width: 94%;
    //         margin-left: 3%;
    //         margin-right: 3%;
    //     }
    //     .content-area {
    //         width: 100%;
    //         // margin-left: 3%;
    //         // margin-right: 3%;
    //         // left: auto;
    //     }
    //     &.webview {
    //         .content-allmatches {
    //             height: calc(100% - 8px);
    //         }
    //     }
    // }
}

/** RLL added these styles when consolidating all the slamtracker code into one place */
.webview {
	.wrapper {
		&.slamtracker {
			.content-main {
				// min-height: 100vh;
        // min-height: calc(100vh - 166px);
				// height: 100vh;
        padding-top: 0px;
        background: none;
			}
			.slamtracker-content {
        max-width: 1260px;
				height: 100% !important;
        width: 100% !important;
        margin: 0 auto;
        .featured-match {
          position: fixed;
          z-index: 12;
          height: 165px;
          // background-color: #fff;
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: cover;
          // left: 0;
          // right: 0;
          max-width: inherit; 
          width: inherit;
        //   margin: 0 auto;

          @include general-breakpoint(smtablet){
            background-color: #fff !important;
          }

          .match-box-container{ 
            width: 100%; 
            margin: unset; 

            @include general-breakpoint(smtablet){
              padding: 0px !important;
            }
          }
        }  
        .content-tabs {
          position: fixed;
          top: 165px;
          z-index: 12;
          // left: 0;
          // right: 0;
          max-width: inherit;
          width: inherit;
          margin: 0 auto;
        }
			}
      .content-area {
        background-color: transparent;
        top: 200px;
        position: relative;
        padding: 0px;

        .panel-content {
          // height: calc(100% - 40px);
          // overflow-y: scroll;
          // overflow-x: hidden;
          @include panel-content-background;
        }
      }
			.header {
				display: none;
			}
			@include general-breakpoint(smtablet) {
				.content-main {
					// min-height: 100vh;
					// height: 100vh;
				}
			}
			@include general-breakpoint(smdesktop) {
				// 1024px wide
				.content-allmatches {
					height: calc(100% - 8px);
				}
			}
		}
	}
}

/** RLL 7/2022 override styles for slamtracker panel **/
.stpanel-container {
    .slamtracker {
        width: 100%;
        max-width: 100%;
        // height: 100%;

        // awt:  usta wanted panel to scroll and not pin scoreboard.  
        // only momentum has pinned scoreboard.
        // overflow-y:auto;

        h1.header {
            display: none;
        }
        .content-allmatches {
            display: none;
        }
        .content-main {
            height: 100%;
            padding-top: 0;
            padding-bottom: 0;
        }
        .slamtracker-content {
            width: 100%;
            height: 100%;
            margin: 0;
        }
        .st-ad-container {
            display: none;
        }
        .featured-match {
            width: 100%;
            height: auto;
            // min-height: 173px;
            background-color: $dark-blue3;
            // padding: 16px 15px;
            margin: 0;
           .match-box {
                &.prematch {
                    // min-height: auto;
                }
                .match-status {
                    background-color: $white; 
                    padding: 6px;

                    .event-info {
                        .event-type, .event-round {
                            font-size: unset;
                            color: unset;
                        }
                        .event-court {
                            display: unset;
                            width: unset;
                            font-size: unset;
                            color: unset;
                        }
                        .results-info-status {
                            width: auto;
                        }
                    }
                    .team-info {
                        &.team-one, &.team-two  {
                            .team-name {
                                border-left: none;
                            }
                        }
                    }
                    .event-notes {
                        margin-left: -6px;
                        width: calc(100% + 12px);
                        background-color: $dark-blue3;
                    }
                }
            }
        }
        .content-area {
            position: static;
            width: 100%;
            margin: 0;
            top: unset;
            // height: auto;
            background-color: $white;
            padding: 0;

            // .full-content {
            //     position: static;
            //     top: unset;

            //     .top-border {
            //         // display: none;
            //     }
            // }
        }
        .panel {
            // awt:  usta wanted panel to scroll and not pin scoreboard.  
            // only momentum has pinned scoreboard.
            // &.selected {
            //     overflow: auto;
            // }
            .content {
                // overflow-y:unset;
            }
            #momentum_panel.content {
                overflow-y:auto;
            }
        }
        @include general-breakpoint(smtablet) {
            width: calc(100% - 10px);
            margin-left: auto;
            margin-right: auto;
        }
    }
}


/**
 * These styles are used for the webview and the panel 
 **/
 .slamtracker {
    .slamtracker-content {
        /** override page-nav-container styles for tabs */
        .page-nav-container {
            .nav-container {
                .page-nav-list {
        
                    &.white-text{
                        .divider:after{
                            background: $gray-4a
                        }
                    }
        
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    list-style: none;
                    margin-bottom: 0;
                    width: 100%;
                    // padding: 17px 0 0;
        
                    margin: auto;
                    margin-top: 0;
        
                    li.page-nav-items {
                        &.white-text{
                            color: $white;
                            font-weight: normal;
                            a{
                                color: $white;
                                font-weight: normal;
                            }
                        }
                        cursor: pointer;
                        color: $dk-gray2;
                        font-size: 14px;
                        font-weight: bold;
                        padding-bottom: 4px;
                        position: relative;
                        @include interstate();
                        white-space: nowrap;
                        text-transform: uppercase;
                        // margin-top: 10px;
        
                        &.active {
                            &.white-text{
                                border-bottom: 2px solid $white;
                            }
                            border-bottom: 3px solid $dk-gray2;
                        }
                    }
                    li.divider {
                        position: relative;
                        margin-left: 10px;
                        margin-right: 10px;
                        margin-top: 10px;
                        visibility: hidden;
                        &:after {
                            position: absolute;
                            top: 0;
                            height: 20px;
                            width: 1px;
                            content: '';
                            background: grey;
                        }
                        &:last-child {
                            display: none;
                        }
                    }
                    a{
                        color: unset;
                    }
                    a:hover {
                        text-decoration: none;
                        padding-bottom: 0px;
                        border-bottom-style: solid;
                        border-bottom-width: 1.5px;
                        width: fit-content;
                    }
                }
            }
        }
    }
}