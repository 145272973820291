.generic-button_wrapper {
	margin-bottom: 10px;
	cursor: pointer;

	&.center {
		float: none;
	}

	a {
		text-decoration: none;

		&:hover, &:active, &:visited {
			text-decoration: none;
		}
	}
}

.disabled{
	opacity: .5;
	cursor: default !important;
}

.disabled_generic-button_wrapper {
	cursor:not-allowed;
	pointer-events:none;
	opacity: 0.6;
}

.generic-button_wrapper > .generic-button,
a > .generic-button {
	min-width: 100px;
	width: auto;
	// width: 200px;
	height: 35px;
	line-height: 20px;
	margin: auto;
	position: relative;
	@include interstate(regular);
	font-size: 16px;
	cursor: pointer;
	display: inline-block;
		
	.content {
		color: $usoLtBlueBG;
		text-transform: uppercase;
		text-align: center;

		// position: absolute;
		position: relative;
		margin: auto;
		width: 100%;
		height: 100%;
		// padding-top: 8px;
		white-space: nowrap;
		padding: 8px 12px 0 12px;
	}

	&.auto {
		width: auto;
		display: inline-block;

		.content {
			color: $white;
			white-space: nowrap;
			position: relative;
			padding: 9px 12px 0px 12px;
		}
	}

	&.auto {
		width: auto;
    	display: inline-block;

		.content{
			text-transform: none;
			white-space: nowrap;
			position: relative;
			padding: 9px 12px 0px 12px;
		}
	}

	.btn-border { // name it btn-border as this conflicts with bootstrap's style .broder
		position: absolute;
		width: 100%;
		height: 100%;
		border: 1px solid $usoLtBlueBG;
		top: 0;
		left: 0;

		&:hover {
			border: 2px solid $usoLtBlueBG;
			cursor: pointer;
		}
	}

	&:active {
		background-color: $usoLtBlueBG;
		
		.content {
			color: $white;
		}
		.btn-border {
			border: unset;
		}
	}

	&.solid {
		background-color: $usoLtBlueBG;

		.content {
			color: $white;

			&:hover {
				// background-color: $button-hover;
                background-color: $white;
                color: $usoLtBlueBG;
				text-decoration: none;
			}
			&:active {
				// background-color: $button-active;
                color: $usoLtBlueBG;
				text-decoration: none;
			}
		}

		.btn-border {
			display: none;
		}

		&.disabled{
			.content {
				&:hover {
					background-color: $usoLtBlueBG;
				}
				&:active {
					background-color: $usoDkerBlue;
				}
			}
		}
	}

	&.compact {
		background-color: $usoLtBlueBG;
		height: 25px;
		line-height: 14px;
		.content {
			color: $white;
			font-size: 11px;
			padding-top: 6px;
		}
		&:hover {
			// background-color: $button-hover;
		}
		&:active {
			// background-color: $button-active;
		}
		.btn-border {
			display: none;
		}
	}

    &.rounded-corner {
        .btn-border {
            border-radius: 7px;
        }

        &.full {
            width: 100%;
        }

        .content {

            &:hover, &:active {
                border-radius: 7px !important;
            }
        }
    }
}

.generic-button_wrapper {
	&.cms {
		text-align: center;

		.generic-button {
			width: auto;
			display: inline-block;

			.content {
				white-space: nowrap;
				position: relative;
				padding: 9px 12px 0px 12px;
			}
		}
	}
}

.generic-button_alt_text {
	text-align: center;

	font-size: 18px;
	font-weight: bold;

}