/** uso-header styles **/
.uso-header-preview {
    #uso-header {
        position: relative;
    }
}
#uso-header {
    @include interstate;
    background-color: $white;
    color: $gray;
    .search-icon {
        margin-right: 15px;
    }
    .date {
        font-size: 9.6px;
        padding-top: 5px;
        display: none !important;
    }

    @include general-breakpoint(mobile) {
        #header-links {
            ul {
                display: none;
            }
        }
    }
    @include general-breakpoint(landscape) {
        #header-links {
            ul {
                display: none;
            }
        }
    }
    @include media-breakpoint-down(xl) {
        #header-links {
            margin-left: 0;
            ul.nav-list {
                display: flex;
                flex: 1 1 auto;
                justify-content: flex-start;
                list-style-type: none;
                margin: 0 0 0 50px;
                padding: 0;
                overflow: visible;
            }
            li.menu-item {
                display: inline-block;
                padding: 0;
                position: relative;
                @include interstate(bold);
            }
            // li.menu-item.more {
            //     a:after {
            //         @include caret-down($dk-gray2, 4px, relative);
            //     }
            // }
            li.menu-item a {
                display: block;
                color: $dk-gray2;
                text-align: center;
                padding: 10px 5px;
                text-decoration: none;
                text-transform: uppercase;
            }
            li.menu-item:hover, li.menu-item.hover {
                // background-color: $lt-gray;
                a{                  
                    color: $usoLtBlueBG;
                }                
            }
            // li.menu-item.more:hover {
            //     a:after {
            //         @include caret-up($dk-gray2, 4px);
            //     }
            // }
            li.menu-item:hover .submenu, li.menu-item.hover .submenu {
                display: block;
            }
            .submenu {
                display: none;
                position: absolute;
                list-style: none;
                background-color: $lt-gray;
                min-width: 150px;
                z-index: 1;
                left: 50%;
                transform: translateX(-50%);
                @include interstate();
                padding-left: 0;         
                border: 1px solid $md-gray5;
                border-top: 4px solid $bright-blue;   
                li {
                    // &.selected{
                    //     text-decoration: underline;
                    // }
                    padding: 0;
                    a {
                        padding: 8px 32px;
                        white-space: nowrap;
                        text-transform: inherit;
                        color: $dk-gray2 !important;
                        &:hover {
                            // background-color: $dk-gray;
                            color: $usoLtBlueBG !important;
                        }
                    }
                }
            }

            .live-status {
                bottom: 5px;
                font-size: 9px;
                display: inline-block;
                position: absolute;
                right: 40px;
                text-transform: uppercase;
                z-index: 2;

                .isLive {
                    margin-bottom: 5px;
                }

                a:hover {
                    text-decoration: none;
                    font-weight: bold;
                }

                span {
                    margin-bottom: 0;

                    &.onAir::before, &.offAir::before {
                        color: $white;
                        display: inline-block;
                        line-height: 12px;
                        margin-right: 3px;
                        text-align: center;
                        white-space: nowrap;
                        width: 40px;
                    }

                    &.onAir {
                        color: $red_live;
						cursor: pointer;

                        &::before {
                            content: "ON AIR";
                            background: $red_live;
                            font-weight: bold;
                        }

						&:hover {
							font-weight: bold;
						}
                    }

                    &.offAir {
                        color: $md-gray;

                        &::before {
                            content: "OFF AIR";
                            background: $md-gray;
                        }
                    }

                }
            }
        }
    }

    // couldn't add the same breakpoint, (llg) as web
    // using alternative breakpoint mixin
    @include draws-breakpoint(mxlarge) {
        #header-links {
            margin-left: -15px;
            .li-date {
                font-size: 1.1rem;
            }
    
            ul.nav-list {
                display: flex;
                flex: 1 1 auto;
                justify-content: flex-start;
                list-style-type: none;
                margin: 0 0 0 40px;
                padding: 0;
                overflow: visible;
                white-space: nowrap;
            }
            li.menu-item {
                display: inline-block;
                padding: 0;
                margin-right: 5px;
                position: relative;
                @include interstate(condensedbold);

                //manual override to fix nav item overlap
                @media only screen and (max-width: 1200px){
                    @include interstate(condensed);
                    margin-right: 3px;
                }
                &.selected{
                    text-decoration: underline;
                }
                .tablet-item{
                    font-size: 11px;
                    display: block;
                    color: $dk-gray2;
                    text-align: center;
                    padding: 10px 5px;
                    text-decoration: none;
                    text-transform: uppercase;
                    &:hover{ cursor: pointer;}
                }
            }
                // li.menu-item.more {
                //     a:after {
                //         @include caret-down($dk-gray2, 4px, relative);
                //     }
                // }
                li.menu-item a {
                    display: block;
                    color: $dk-gray2;
                    text-align: center;
                    padding: 10px 5px;
                    text-decoration: none;
                    text-transform: uppercase;
                }
                li.menu-item:hover, li.menu-item.hover {
                    a{                    
                        color: $usoLtBlueBG;
                    }
                }
                li.menu-item.more:hover {
                    // a:after {
                    //     @include caret-up($dk-gray2, 4px);
                    // }
                }
                li.menu-item:hover .submenu, li.menu-item.hover .submenu {
                    display: block;

                    a {
                        &:hover {  
                            &:after {
                                border: none; // don't show caret
                            }
                        }
                        &:after {
                            border: none; // don't show caret
                        }
                    }
                }
                .submenu {
                    display: none;
                    position: absolute;
                    list-style: none;
                    background-color: $lt-gray;
                    min-width: 150px;
                    z-index: 1;
                    left: 50%;
                    transform: translateX(-50%);
                    @include interstate();
                    padding-left: 0;
                    border: 1px solid $md-gray5;
                    border-top: 4px solid $bright-blue;
                    li {
                        padding: 0;
                        a {
                            padding: 5px 32px;
                            white-space: nowrap;
                            text-transform: inherit;
                            color: $dk-gray2 !important;
                            &:hover {
                                // background-color: $dk-gray;
                                color: $usoLtBlueBG !important;
                            }
                        }
                    }
                }
            .live-status {
				bottom: 5px;
				font-size: 9px;
				display: inline-block;
				position: absolute;
				right: 40px;
				text-transform: uppercase;
				z-index: 2;

				.isLive {
					margin-bottom: 5px;
				}

				a:hover {
					text-decoration: none;
					// font-weight: bold;
				}

				span {
					margin-bottom: 0;

					&.onAir::before, &.offAir::before {
						color: $white;
						display: inline-block;
						line-height: 12px;
						margin-right: 3px;
						text-align: center;
						white-space: nowrap;
						width: 40px;
					}

					&.onAir {
						color: $red_live;

						&::before {
							content: "ON AIR";
							background: $red_live;
							font-weight: bold;
						}
					}

					&.offAir {
						color: $md-gray;

						&::before {
							content: "OFF AIR";
							background: $md-gray;
						}
					}

				}
			}
        }
    }

	@include media-breakpoint-up(xl) {
        #header-links {
            li.menu-item {
                display: inline-block;
                padding: 0;
                position: relative;

                //manual override to fix nav item overlap
                @media only screen and (max-width: 1330px){
                    @include interstate(condensedbold);
                }
                @media only screen and (min-width: 1331px){
                    @include interstate(bold);
                }
            }

           .live-status {
                right: 40px;
           }
        }
    }

    #header-links {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .li-date {
            position: relative;
            @include interstate(condensedbold);
            font-size: 1.2rem;
            flex: 0 0 auto;
            padding: 0 20px 0 12px;
            font-weight: 700;
            // display: none !important; /* hide for tournament */
        }
        @include media-breakpoint-down(lg) {
            li a {
                font-size: 11px;
            }
        }
    }
}

// couldn't add the llg breakpoint like web,
// creating necessary classes used in the header here
@include draws-breakpoint(mxlarge) {
    .d-llg-block {
        display: block !important;
    }
    .d-llg-flex {
        display: flex !important;
    }
    .d-llg-inline-block {
        display: inline-block !important;
    }
    .d-llg-none {
        display: none !important;
    }

    .col-llg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-llg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-llg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
