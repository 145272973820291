$innovations-text: #4A4A4A;
$powerrank-color:#418FDE;
$powerrank-header-bg:#C1C6C8;
$powerrank-bar:#418FDE; //#B5DD5A;
$powerrank-bar-bg: #ECECEC;
$powerrank-text:#000000;
$powerrank-movement-up:  $usoGreen;
$powerrank-movement-up-text: #3F8623;
$powerrank-movement-down: #C8102E; 
$powerrank-table-border:#ECECEC;
$powerrank-divider: #BBBBBB;
$powerrank-won: $powerrank-color;
$powerrank-loss: #00288C;
$powerrank-series-color:$powerrank-color;
$tourrank-color: #C1C6C8;
$chart-label-color: #4A4A4A;
$sentiment-text: #616161;
$powerrrank-border:#4A4A4A;


.powerrank-movement {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @include interstate(bold);
    position: relative;
    .rank {font-size: px2rem(18);color:$innovations-text}
    .movement {
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        font-size: px2rem(16);
        align-items: center;
        &.up {
            color: $powerrank-movement-up;

            .movement-value {
                color: $powerrank-movement-up-text;
            }
        }
        &.down {
            color: $powerrank-movement-down;
        }
        i {margin-left:8px; margin-right:2px; font-size:px2rem(9); line-height: 51px;}
        .movement-value {}
    }
}

.determining-factors-container {
    display:flex;
    flex-direction: column;
    width:100%;

    .bar-graph-wrapper {   
        position: relative;  
        margin-bottom:11px;   
        .bar-graph-title {
            @include interstate();
            font-size: px2rem(14);
            line-height: 18px;
            margin-bottom:6px;
            color:$innovations-text;
        }
        .bar-graph-footer {
            text-align: center;
            text-transform: uppercase;
            font-size: px2rem(12);
            line-height: 18px;
            color: $sentiment-text;
        }
        
        .bar-graph-container {
            background-color: $powerrank-bar-bg;
            padding:2px;
            width:100%;
            height:24px;
            .bar-graph-bar {
                background-color: $powerrank-bar;
                height:100%;
            }

            &.double {
                display:flex;
                justify-content:center;
                width:100%;
                position: relative;
                margin-bottom:4px;
                color:$sentiment-text;
                &::before {
                    content:"neg";
                    color:$sentiment-text;
                    position: absolute;
                    text-transform: uppercase;
                    left:2px;
                    font-size:px2rem(12);
                    line-height: 20px;
                    //@include interstate(bold);
                }
                &::after {
                    content:"pos";
                    color:$sentiment-text;
                    text-transform: uppercase;
                    position: absolute;
                    right:2px;
                    font-size:px2rem(12);
                    line-height: 20px;
                    //@include interstate(bold);
                }

                .bar-graph-bar {
                    width:50%;
                    background-color: transparent;
                    margin:0px 2px;
                    &.neg {
                        background-color: $powerrank-bar;
                    }
                    &.pos {
                        background-color: $powerrank-bar;
                    }
                }
                .bar-graph-divider {
                    height: 30px;
                    position: absolute;
                    margin: 0px auto;
                    border-left: solid 1px $black;
                    bottom: -4px;
                    width: 0px;
                    left: 0px;
                    right: 0px;
                }
            }
        }
    }
}

.rank-over-time-container {
    width:100%;

    .line-graph-container {
        position:relative;
        //height:200px;
    }
    .ct-chart {
        height:200px;
        width:100%;
        position: relative;
        z-index: 1;
        &.chart-1 {
            span.ct-label.ct-horizontal.ct-end {
                position: relative;
                transform: rotate(-45deg);
                display:inline-block;
                width:auto !important;
                margin-left:-3px;
            }
        }
        &.chart-2 {
            position: absolute;
            top:0px;
            left:0px;
            z-index: 0;

            span.ct-label.ct-vertical.ct-end {
                display:none;
            }

            span.ct-label.ct-horizontal.ct-end {
                position: relative;
                visibility: hidden;
                &:before {
                    content: '|';
                    position: absolute;
                    top: -13px;
                    left: 7px;
                    display:block;
                    visibility: visible;
                }

                @include general-breakpoint(smtablet) {
                    //top: -170px;
                }

                @include general-breakpoint(smdesktop) {
                    //top: -205px;
                }
            }
        }
    }
    .ct-vertical ~ .ct-vertical {
        stroke: none;
    }
    .ct-horizontal ~ .ct-horizontal {
        stroke: none;
    }
    .ct-grid {
        stroke-dasharray:0px;
        stroke:$black;
    }

    .ct-series {
        .ct-label {
            display:none;
        }
    }
    .ct-label {
        color:$chart-label-color;
        font-size: px2rem(11);
    }
    .ct-line {
        stroke-width: 1px;
    }
    .ct-point {
        stroke-width:1px;
    }
    .tour-series {
        .ct-line, .ct-point {
            stroke: $tourrank-color;
            stroke-dasharray: 2px;
        }
    }
    .powerrank-series {
        .ct-line, .ct-point {
            stroke: $powerrank-series-color;
        }
    }

    .lastmatch-series {
        .ct-line, .ct-point {
            stroke: $powerrank-series-color;
        }
        .ct-point {
            stroke-width:10px;
        }
        .ct-label {
            display:block;
            fill: white;
            font-size: px2rem(10);
            //@include interstate(bold);
        }

        &.loss {
            .ct-line, .ct-point {
                stroke: $powerrank-loss;
            }
        }
    }
    .prevmatch-series {
        .ct-line, .ct-point {
            stroke: $powerrank-series-color;
        }
        .ct-point {
            stroke-width:8px;
        }
        .ct-label {
            display:block;
            fill: white;
            font-size: px2rem(10);
            //@include interstate(bold);
        }
    }

    .legend-container {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        width:100%;
        padding-left:10px;
        color:$chart-label-color;
        .legend {
            display:flex;
            flex-direction: row;
            align-items: center;
            //@include interstate(regular);
            font-size: px2rem(12);
            line-height: 12px;
            margin-bottom:8px;
            .bullet {
                display: block;
                position: relative;
                margin-right:6px;
                &.dot {
                    width: 8px;
                    height: 8px;
                    min-width: 8px;
                    min-height: 8px;
                    border-radius: 50%;
                    background: black;
                }
                &.dashed {
                    width:10px;
                    height:1px;
                    border-top:dashed 1px black;
                }
                &.line {
                    border-top:solid 1px black;
                    width:10px;
                    height:1px;
                }  
                    
            }
            
            &.tour {
                width:50%;
                .bullet {border-color:$tourrank-color}
            }
            &.pr {
                width:50%;
                .bullet {border-color:$powerrank-color}
            }
            &.won {
                width:50%;
                .bullet.dot {background:$powerrank-won}
            }
            &.loss {
                width:50%;
                .bullet.dot {background:$powerrank-loss}
            }
        }
    }
}

.graph-title {
    .determining-factors-container &, .rank-over-time-container & {
        @include interstate();
        font-size: px2rem(14);
        color:$innovations-text;
        margin-bottom:12px;
        text-transform: uppercase;
        text-align: center;
    }
}

.player-img-container {
    .player-image {width:100%}
    .powerrank-section & {
        width:80px;
        height:80px;
    }

    .leaderboard-table & {
        width:20%;
        //height:210px;
    }
}

.powerrank-container {
    display:flex;
    @include interstate(bold);
    color:$powerrank-color;
    align-items:center;
    .value {@include interstate(bold); font-size: px2rem(32);}
    .label {@include interstate(regular); font-size:px2rem(16); text-transform: uppercase;}
}

.powerrank-data-container {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding:15px 0px;

    @include general-breakpoint(smtablet) {
        padding-top:16px;
        padding-bottom:16px;
        justify-content: space-between;
        
        // .nextmatch-container {
        //     display:none;
        // }
    }

    @include general-breakpoint(tablet) {
        //border-bottom: solid 1px #ECECEC;
        margin-bottom: 20px;
        padding-bottom: 0px;
        justify-content: space-around;

        .player-image-wrapper {
            width: 20%;
            height:auto;
            display: flex;
            flex-direction: column;
            align-content: center;
            padding: 0px;

            .player-img-container {
                width:100%;
                padding:0px 15px;
                margin:0px;
            }
        }

        .powerrank-container {
        }

        .determining-factors-container {
            width:30%;
            // max-width: calc(100% - 240px);
            border-bottom: none;

            .bar-graph-wrapper {
                 .bar-graph-container {
                     height:24px;
                 }
            }
        }

        .rank-over-time-container {
            width:50%;
            padding-top:0px;
            border-left: solid 1px #ECECEC;
            border-top:none;

            .ct-chart, .chart2 {
                height:240px;
            }

            
        }
    }

    .sect { /** four sections */
        padding-bottom: 10px;
        width: 100%;

        .msg {
            padding-left: 20px
        }

        /** oiverride the normal draw-analysis-wrapper styles only for Power Index page */
        .draw-analysis-wrapper {
            margin-top: 0;
            max-width: unset;

            .draw-analysis-inner-wrapper {
                padding: 0;
            }

            button {
                &.full {
                    width: 100% !important;
                }
            }
        }

        .determining-factors-container {
            margin-top:13px;
            width:100%;
            border-bottom:solid 1px $powerrank-divider;
    
            .bar-graph-wrapper {
                .bar-graph-title {
                    font-size: px2rem(12);
                    margin-bottom:0px;
                }
                .bar-graph-container {
                    height:8px;
                    padding:1px;
                    &.double {
                        &::before,&::after {
                            top:-6px;
                            font-size:px2rem(10);
                            text-transform: capitalize;
                        }
                        .bar-graph-divider {
                            height:14px;
                            bottom:-3px;
                        }
                    }
                }
                .bar-graph-footer {
                    font-size: px2rem(10);
                }
            }
    
            .powerrank-container {display:none;}
        } // .determining-factors-container

        .rank-over-time-container {
            width:100%;
            margin-top:13px;
            padding-bottom:16px;
            border-bottom:solid 1px $powerrank-divider;
            .ct-chart, .chart-2 {
                height:225px;
            }
        } // .rank-over-time-container

        &.player-image { // 1
            display: flex;
            border-bottom: solid 1px $powerrank-divider;

            .player-img-container {
                width: 61px;
            }

            .player-info {
                display: flex;
                align-items: center;
                width: calc(100% - 61px);

                .nextmatch-container {
                    display: none;
                }
            }

            .powerrank-container {
                padding-left: 16px;
                display: flex;
                align-items: center;

                .label {
                    margin-left:16px;
                }
            }

            .nextmatch-container {
                width: calc(100% - 61px);
                display: none;
            }

        } // .player-image
        
        &.draw-analysis { // 2
            border-bottom: solid 1px $powerrank-divider;
            padding: 10px;
        }

        &.key-factors { // 3
            padding-top: 10px;
        }

        &.rank-overtime { // 4
            padding-top: 10px;
        }


        @include general-breakpoint(smtablet) {
            width: 50%;

            .graph-title {
                text-align: left;
            }

            .determining-factors-container {
                margin-top:0px;
                padding: 0px 15px;
                border-bottom: none;
    
                .powerrank-container {
                    display:flex;
                    width:100%;
                    padding-left:0px;
                    margin-bottom:10px;
                    .value {
    
                    }
                    .label {
                        width:auto; 
                        font-size:px2rem(14)
                    }
                }
    
                .bar-graph-wrapper {
                    .bar-graph-container {
                        height:16px;
                        padding:2px;
                        &.double {
                            &::before,&::after {
                                top:-2px;
                                font-size:px2rem(12);
                                text-transform: uppercase;
                            }
                        }
                    }
                    .bar-graph-footer {
                        font-size:px2rem(12);
                    }
                }
    
                .bar-graph-wrapper {
                    margin-bottom: 12px;
                    .bar-graph-title {
                        font-size: px2rem(14);
                        margin-bottom:6px;
                    }
                    .bar-graph-container {
                        height:20px;
                        padding:3px;
    
                        &.double {
                            &::before, &::after {
                                margin-top: 2px;
                            }
                            .bar-graph-divider {
                                height: 23px;
                            }
                        }
                    }
                    .bar-graph-footer {
                        font-size: px2rem(12);
                    }
                }
            } // determining-factors-container

            .rank-over-time-container {
                padding-top: 0px;
                margin-top:0px;
                border-top:none;
                border-bottom:none;
                padding: 0px 15px;
                
                .ct-chart, .chart2 {
                    height:200px;
                }
                .legend-container {
                    flex-wrap: nowrap;
                    justify-content: space-evenly;
                    .legend {
                        &.tour, &.pr, &.won, &.loss {width:auto}
    
                        &.pr {order:1}
                        &.tour {order:2}
                        &.won {order:3}
                        &.loss {order:4}
    
                        .bullet {
                            &.dashed, &.line {
                                width:18px;
                            }
                        }
                    }
                }
            }

            &.player-image {
                border-right: solid 1px $powerrank-divider;
                flex-wrap: wrap;

                .player-img-container {
                    width: 100px;
                }

                .player-info {
                    display: unset;
                    width: calc(100% - 100px);

                    .nextmatch-container {
                        display: block;

                        .player-match-status, .player-oponent {
                            display: block;
                        }

                        button {
                            display: none;
                        }
                    }
                }

                .powerrank-container {
                    .value {
                        font-size: px2rem(46);
                    }
                    .label {
                        width: 100px;
                    }
                }
                
                .nextmatch-container {
                    width: 100%;

                    /** show only the button on tablet size */
                    &.desktop-style {
                        display: block;
    
                        .player-match-status {
                            display: none;
                        }
    
                        .player-oponent {
                            display: none !important; // higher style set this as display: flex. Need to use important to override it
                        }
    
                        button {
                            display: block;
                        } 
                    }
                }
            }

            &.key-factors {
                border-right: solid 1px $powerrank-divider;
            }

        }

        @include general-breakpoint(smdesktop) {
            &.player-image { // order 1
                .player-img-container {
                    width: 119px;
                }

                .player-info {
                    width: calc(100% - 119px);

                    .nextmatch-container {
    
                        button {
                            display: block;
                        }                
                    }
                }

                .powerrank-container {
                    .label {
                        width: auto;
                    }
                }

                .nextmatch-container {

                    /** hide all */
                    &.desktop-style {
                        display: none;
                    }
                }
            }
        }

        @include general-breakpoint(desktop) {
            width: 25%;

            &.player-image { // order 1
                order: 1;
                flex-wrap: wrap;
                border-bottom: none;

                .player-info {
                    .powerrank-container {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .nextmatch-container {
                        display: none;
                    }
                } 
                
                .powerrank-container {
                    .value {
                        margin-left: 16px;
                    }

                    .label {
                        width: 70px;
                        line-height: 18px;
                    }
                }

                .nextmatch-container {
                    display: block;

                    /** show only the button on tablet size */
                    &.desktop-style {
                        display: block;
    
                        .player-match-status {
                            display: block;
                        }
    
                        .player-oponent {
                            display: block !important; // higher style set this as display: flex. Need to use important to override it
                        }
    
                        button {
                            display: block;
                        } 
                    }
                }
            }
        
            &.draw-analysis { // 4
                border-left: solid 1px $powerrank-divider;
                border-bottom: none;
                order: 4;
            }

            &.key-factors { // 2
                padding-top: 10px;
                order: 2;
            }

            &.rank-overtime { // 3
                padding-top: 10px;
                order: 3;
            }
        }
    } // .sect

} //.powerrank-data-container

/** watsonx branding - on IBMWatsonx component **/
.watsonx-branding {
    @include interstate();
    font-size: px2rem(12);
    color: $gray_4a;
    display: flex;
    text-align: center;
    align-items: center;

    .watsonx-logo {
        @include ibmplexsans(semibold);
        font-size: px2rem(17);
        padding-left: 5px;
        padding-right: 5px;
        display: block;
    }

    .onclick {
        color: $usoLtBlueBG;
        &:hover {
            cursor: pointer;
        }
    }

    .icon-watsonx {
        font-size: px2rem(24);
        margin-top: -5px;
        margin-left: 5px;
    }

    @include general-breakpoint(smtablet) {
        font-size: px2rem(16);

        .icon-watsonx {
            font-size: px2rem(30);
        }
    }

}
