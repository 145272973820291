.favorites-module {
	max-width: 1366px;
	margin: auto;

	@include general-breakpoint(smtablet) {
		display: flex;
		height: 108px;
		align-items: center;
	}

	.instruction {
		position: relative;
		@include interstate(bold);
		font-size: px2rem(12);
		padding: 10px 0;
		text-align: center;
		white-space: nowrap;

		@include general-breakpoint(smtablet) {
			// align-self: center;
			// margin-right: 20px;
			// width: 110px;
			padding: 0;
			// text-align: left;
			display: inline-block;

			&:before {
				@include interstate(regular);
				position: absolute;
				color: $usoMdGray;
				top: -8px;
				right: -20px;
				content: '\00BB';
				display: inline-block;
				font-size: px2rem(30);
				font-weight: normal !important;
			}
		}
	} // instruction

	&.no-favorites {
		position: relative;
		display: flex;
		justify-content: center;
		gap: 13px;

		.fav-no-favs-dropdown {
			display: flex;
			flex-direction: column;
		}
		.instruction {
			&:before {
				content: '';
			}
		}

		.find-player {
			@include interstate();
			@include general-breakpoint(smtablet) {
				font-size: px2rem(14);
			}

			a {
				color: $dk-gray2;
				text-decoration: none;

				&:after {
					@include uso-icon('Icons');
					content: '\e921';
					display: inline-block;
					font-size: px2rem(10);
					padding-left: 8px;
					vertical-align: middle;
				}
			}
		}
	}
	&.scores,
	&.schedule,
	&.draws {
		.favorite-card {
			// max-width: 94px;
			justify-content: center;
			width: 100%;
			height: 100%;
			margin-bottom: 0;
			.player-name {
				position: absolute;
				bottom: 4px;
				white-space: nowrap;
				font-size: 9px;
				// font-size: px2rem(9);
				justify-content: center;
				// @include general-breakpoint(smtablet) {
				// 	font-size: px2rem(12);
				// }
			}
			.player-img-container {
				top: 6px;
				position: absolute;
			}
			.flag-image {
				bottom: 23px;
				right: -10px;
			}
		}
	}
	.favorite-card {
		display: flex !important;
		box-sizing: border-box;
		font-size: px2rem(12);
		padding: 5px;
		position: relative;
		margin-bottom: 20px;
		&.hide-message {
			.player-unavailable-message {
				display: none;
			}
			.player-with-flag,
			.player-name {
				display: block;
			}
		}

		&.show-message {
			background-color: $favorite-background-color;
			@include interstate();
			padding: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			.player-with-flag,
			.player-name {
				display: none;
			}
		}
		a {
			color: $usoDkGray;
		}
		.player-with-flag {
			position: relative;
			width: 33px;
		}
		.player-img-container {
			width: 33px;
			margin: auto;

			img {
				border: solid 1px #d3d5d2;
				margin: $general-fav-card-margin-small 0;
				width: auto;
				// height: 36px;
				width: 33px;
			}

			@include general-breakpoint(smtablet) {
				margin: unset;
				width: 32px;

				img {
					margin: unset;
				}
			}
		}

		.flag-image {
			height: 10px;
			position: absolute;
			// top: 26px;
			// right: 20px;
			bottom: -2px;
			right: -10px;

			img {
				width: 16px;
			}
		}

		&.disabled {
			opacity: 0.5;
		}

		&.selected {
			background-color: $favorite-background-color;
			border-color: $usoLtBlue;
			border: 1px solid $usoLtBlue;
		}

		&.hoverable {
			&:hover {
				cursor: pointer;
			}
		}
		.unavailable {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			text-align: center;
			background-color: $favorite-background-color;
			display: flex;
			flex-direction: column;
			height: 100%;
			line-height: 1em;
			justify-content: space-around;
			.message {
				font-size: px2rem(11);
			}
		}
	} // favorite-card

	.slick-slider {
		@include general-breakpoint(smtablet) {
			display: inline-block;
			width: 100%;
		}
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 20px;
			background-color: $usoBkgGray;
			top: 0;
			left: 0;
			z-index: 0;
		}
		.slick-list {
			height: 108px;
		}

		@include slick-slider-arrows;
	}

	&.scores,
	&.schedule,
	&.draws {
		.slick-slide {
			display: flex !important;
			align-items: center;
			justify-content: center;
			height: 88px;
			position: relative;
			border-right: solid 1px $gray-light;
			border-top: 2px solid transparent;
			border-bottom: 2px solid transparent;
			border-left: 2px solid transparent;
			> div {
				width: 100%;
				height: 100%;
				// width: 94px;
				display: flex;
				justify-content: center;
			}
			&:hover {
				border: 2px solid $usoLtBlue;
			}

			// .label-wrapper {
			.players-active {
				@include interstate();
				position: relative;
				width: 100%;
				height: 20px;
				background-color: $usoBkgGray;
				top: 0;
				left: 0;
				z-index: 99;
				display: none;
				&.display-label {
					font-size: 11px;
					display: block;
					white-space: nowrap;
					text-transform: uppercase;
					padding-left: 7px;
					position: absolute;
					top: -22px;
				}
			}
			.players-inactive {
				@include interstate();
				position: relative;
				width: 100%;
				height: 20px;
				background-color: $usoBkgGray;
				top: 0;
				left: 0;
				z-index: 99;
				display: none;
				&.display-label {
					font-size: 11px;
					display: block;
					top: -22px;
					border-left: 1px solid #d8d8d8;
					margin-left: -3px;
					text-transform: uppercase;
					padding-left: 7px;
					white-space: nowrap;
					position: absolute;
				}
			}
			// }
		}

		.slick-track {
			:first-child &::before {
				@include interstate();
				position: relative;
				width: 100%;
				height: 20px;
				background-color: $usoBkgGray;
				top: 0;
				left: 0;
				z-index: 99;
			}
		}
	}
} // favorites-module

/** players index Favorites Module style */
.favorites-module {
	&.playersindex {
		height: auto;
		display: block;

		.title-bar {
			h3 {
				&.star {
					display: inline-block;
					position: relative;
					padding-left: 25px;

					&:before {
						@include uso-icon('Icons');
						position: absolute;
						left: 0;
						top: -3px;
						content: '\e934';
						font-size: px2rem(20);
						color: $usoYellow;
					}

					&:after {
						@include uso-icon('Icons');
						position: absolute;
						left: 0px;
						top: -3px;
						content: '\e935';
						font-size: px2rem(20);
						color: $usoMdGray;
					}
				}
			}
		} // title-bar

		.slick-slider {
			margin-left: unset;
			width: 100%;
		}

		.players-favorites-content {
			@include interstate();
			background-color: $lt-gray2;
			height: 123px;

			&.no-player {
				background-color: $white;
				color: $dk-gray2;
				font-size: px2rem(16);
				padding: $general-fav-card-margin;
				height: auto;

				p {
					text-align: center;
				}
			}
		}

		.favorite-card {
			display: flex !important;
			background-color: $white;
			border-right: solid 1px $usoltMedGray;
			padding: $general-fav-card-margin;
			width: 220px;
			@include general-breakpoint(smtablet) {
				align-items: unset;
			}

			@include general-breakpoint(smdesktop) {
				width: 207px;
			}

			@include general-breakpoint(desktop) {
				width: 291px;
			}

			.player-info {
				color: $usoDkGray;
				margin-left: 10px;
				a {
					&:hover {
						text-decoration: none;
						color: $usoLtBlue;
					}
				}
			}

			.player-img-container {
				width: auto;
				height: 81px;
				margin: auto;

				img {
					border: none;
					margin: $general-fav-card-margin-small 0;
					width: auto;
					height: 81px;
				}

				@include general-breakpoint(smtablet) {
					margin: unset;
					width: auto;

					img {
						margin: unset;
					}
				}
			} //.player-img-container

			.player-name {
				font-size: px2rem(12);
				text-align: left;
				@include general-breakpoint(landscape, max) {
					font-size: px2rem(9);
				}
				.last-name {
					display: block;
					font-size: px2rem(18);
					line-height: px2rem(15);
					margin-bottom: 7px;

					@include general-breakpoint(landscape, max) {
						font-size: px2rem(11);
						line-height: px2rem(15);
					}
				}
			}

			.flag-image {
				display: flex;
				align-items: center;
				margin-bottom: 3px;

				position: relative;
				top: unset;
				right: unset;

				img {
					margin-right: 5px;
				}
			}

			.country {
				font-size: px2rem(10);
				line-height: px2rem(14);
			}
		}
	}
} //.favorites-module.playersindex
