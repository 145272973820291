// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  // [md]llg added for menu breakpoint to display all the elements including live indicators
  xs: 0,
  // sm: 320px, easier to test 576px 
  sm: 576px,
  md: 768px,
  lg: 1024px,
  llg: 1140px,
  xl: 1280px
) !default;

/* DEFAULT BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
*/

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  // sm: 288px, easier to test 540px 
  sm: 540px,
  md: 720px,
  lg: 960px,
  llg: 1024px,
  xl: 1280px
) !default;

/*
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
*/

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
