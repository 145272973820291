/** menu styles **/

$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);
$menu-items: 14;
$liveItems: 2;
.page-wrap .menu-container.menu-opened,
.page-wrap .submenu-container {
	padding-top: 0;
}

#header-container {
	.left-col {
		flex: 0 0 200px;
		@include general-breakpoint(tablet, max) {
			flex: 1 1;
		}
	}
	.middle-col {
		display: flex;
		// flex: 1 1;
		@include general-breakpoint(smtablet) {
			flex: 1 1;
		}
		position: relative;
		justify-content: center;
		@include draws-breakpoint(mxlarge) {
			justify-content: flex-start;
		}
	}
	.right-col {
		flex: 0 0 230px;
		@include general-breakpoint(tablet, max) {
			flex: 1 1;
		}
	}
	.search-box {
		display: none;
		position: absolute;
		z-index: 99;
		background: $white;
		width: 25px;
		height: 100%;
		right: 0;
		top: 0;
		@include transition(width 0.5s);
		@include draws-breakpoint(mxlarge) {
			display: block;
		}
		&.open {
			white-space: nowrap;
			right: 10px;
			@include draws-breakpoint(medium) {
				width: 60%;
			}
			@include draws-breakpoint(large) {
				width: 100%;
				.close-search {
					right: 0px !important;
				}
			}
			#search-input {
				display: inline-block !important;
				overflow: hidden;
				opacity: 1 !important;
			}
			.close-search {
				display: inline-block !important;
				opacity: 1 !important;
			}
		}
		#mainSearch {
			position: relative;
			top: 50%;
			transform: perspective(1px) translateY(-50%);
			a {
				display: inline-block;
			}
			#search-input {
				display: none;
				text-transform: uppercase;
				color: $gray;
				width: 80%;
				border: none;
				opacity: 0;
				outline: 0;
				line-height: 1.5em;
				font-size: 1.5em;
				@include transition(opacity 0.5s);
				transition-property: opacity;
				transition-delay: 0.5s;
				-webkit-transition-property: opacity;
				-webkit-transition-delay: 0.5s;
			}
			.close-search {
				display: none;
				position: absolute;
				right: -100px;
				top: 0;
				bottom: 0;
				opacity: 0;
				@include transition(opacity 0.5s);
				transition-property: opacity;
				transition-delay: 0.5s;
				-webkit-transition-property: opacity;
				-webkit-transition-delay: 0.5s;
				img {
					position: relative;
					top: 50%;
					transform: perspective(1px) translateY(-50%);
				}
			}
			#searchSubmit {
				display: none;
			}
		}
	}
}

.menu-icon-livebar-wrapper {
	display: flex;
}
.menu-icon-container {
	cursor: pointer;
	// display: inline-block;
	display: flex;
	align-items: center;
	// margin-left: 10px;
	@include draws-breakpoint(mxlarge) {
		display: none;
	}

	.ar-icon {
		display: inline-block;

		.icon {
			display: inline-block;
			position: relative;
			color: $gray;
			font-size: 16px;
			@include general-breakpoint(smtablet) {
				font-size: px2rem(24px);
			}
			&.hide {
				display: none;
			}
		}
	}
}

.global-mobile-navigation {
	.search-box {
		&.sticky {
			position: fixed;
			width: 100%;
			top: 56px;
			z-index: 1000;
		}
		#mainSearch {
			display: flex;
			align-items: center;
		}
		text-align: center;
		background: white;
		border-bottom: 1px solid $md-gray2;
		border-top: 1px solid #d8d8d8;
		.ar-icon {
			display: inline-block;
		}
		.icon {
			display: inline-block;
			position: relative;
			height: 100%;
			line-height: 40px;
			width: 50px;
			height: 40px;
			left: 60%;
			transform: translateX(-50%);
			font-size: 22px;
			color: #888b8d;
		}
		#mobileSearchInput,
		#search-input {
			color: $gray;
			outline: none;
			border: none;
			background: transparent;
			text-transform: uppercase;
			width: calc(100% - 50px);
			line-height: 40px;
			font-size: 1.5em;
			@include transition(width 0.5s);
			&:focus {
				width: calc(100% - 50px);
			}
		}
		.close-search {
			display: none;
		}
		#mobileSearchSubmit {
			display: none;
		}
	}

	#uso-livebar {
		border-bottom: 1px solid #d8d8d8;
		.menu-title-bar {
			background-color: $usoDkBlue;
			border: 1px solid $usoBkgGray;
			margin: 10px;
			.menu-title {
				color: white;
			}
		}
	}
}

.menu-container {
	position: absolute;
	display: block;
	left: 0;
	height: 0;
	@include general-breakpoint(desktop, 'max') {
		width: 160px;
	}
	@include general-breakpoint(landscape, 'max') {
		width: 160px;
	}
	background: rgba($white, 1);
	overflow: hidden;
	transition: all 0.5s ease-out, background 1s ease-out;
	transition-delay: 0.2s;
	z-index: 1000;

	.burger-container {
		position: relative;
		display: inline-block;
		height: 50px;
		width: 50px;
		cursor: pointer;
		transform: rotate(0deg);
		transition: all 0.3s $cubic;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		#burger {
			width: 18px;
			height: 8px;
			position: relative;
			display: block;
			margin: -4px auto 0;
			top: 50%;
			.bar {
				width: 100%;
				height: 1px;
				display: block;
				position: relative;
				background: $white;
				transition: all 0.3s $cubic;
				transition-delay: 0s;
				&.top-bar {
					transform: translateY(0) rotate(0deg);
				}
				&.btm-bar {
					transform: translateY(6px) rotate(0deg);
				}
			}
		}
	}
	.icon {
		display: inline-block;
		position: absolute;
		height: 100%;
		line-height: 50px;
		width: 50px;
		height: 50px;
		text-align: center;
		color: $white; //font-size: 22px;
		left: 50%;
		transform: translateX(-50%);
		&.icon-bag {
			right: 0;
			top: 0;
			left: auto;
			transform: translateX(0);
			transition: transform 0.5s $cubic;
			transition-delay: 0.65s;
		}
	}
	ul.menu {
		position: relative;
		display: block;
		padding: 0;
		list-style: none;
		li.menu-item {
			border-bottom: 1px solid #d8d8d8;
			display: flex;
			align-items: center;
			&.selected {
				// text-decoration: underline;
				i,
				span {
					color: $usoLtBlueBG;
				}
			}
			i {
				font-size: 20px;
				padding: 0 16px;
				color: #888b8d;
			}
			&.open,
			&:hover {
				background-color: white;
				border-left: 4px solid $usoLtBlueBG;
				border-bottom: 1px solid #d8d8d8;
				a,
				span,
				i {
					color: $usoLtBlueBG;
				}

				i {
					padding: 0 16px 0 12px;
				}
			}
			padding: 0;
			transform: scale(1.15) translateY(-30px);
			opacity: 0;
			transition: transform 0.5s $cubic, opacity 0.6s $cubic;
			@for $i from 1 through $menu-items {
				&:nth-child(#{$i}) {
					transition-delay: #{0.56 - ($i * 0.07)}s;
				}
			}
			ul.submenu {
				padding: 0;
				list-style: none;
				display: none;
				li.submenu-item {
					// &.selected{
					//     text-decoration: underline;
					// }
					a {
						display: block;
						padding-left: 40px;
						&:hover,
						&:active {
							color: $usoLtBlueBG;
						}
					}
				}
			}
			a,
			span {
				display: block;
				position: relative;
				color: $dk-gray2;
				@include interstate;
				text-transform: uppercase;
				text-decoration: none;
				width: 100%;
				height: 100%;
				cursor: pointer;
				font-size: 14px;
				color: #4a4a4a;
				letter-spacing: 0;
				line-height: 38px;
			}
		}
		/** RLL added temp for 2022 Tournament menu item styles 4/18 **/
		[class*='Tournament'] {
			min-height: 50px;
			> i {
				// display: none;
			}
			> span {
				padding: 5px 10px 5px 0px; //right padding to force text wrap
				line-height: 24px !important;
			}
			&.open,
			&:hover {
				> span {
					// padding-left: 11px;
				}
			}
		}
	}
	&.menu-opened {
		height: 250%;
		background-color: #f3f3f3;
		transition: all 0.3s ease-in, background 0.5s ease-in;
		transition-delay: 0.25s;
		overflow: hidden;

		@include general-breakpoint(landscape, 'min') {
			min-height: 590px;
		}

		&.sticky {
			position: fixed;
			top: 100px;
			left: 0;
		}
		.burger-container {
			transform: rotate(90deg);
			#burger {
				.bar {
					transition: all 0.4s $cubic;
					transition-delay: 0.2s;
					&.top-bar {
						transform: translateY(4px) rotate(45deg);
					}
					&.btm-bar {
						transform: translateY(3px) rotate(-45deg);
					}
				}
			}
		}
		ul.menu {
			li.menu-item {
				transform: scale(1) translateY(0);
				opacity: 1;
				@for $i from 1 through $menu-items {
					&:nth-child(#{$i}) {
						transition-delay: #{0.07 * $i + 0.2}s;
					}
				}
			}
		}
		.icon {
			&.icon-bag {
				transform: translateX(75px);
				transition-delay: 0.3s;
			}
		}

		.live-status {
			opacity: 1;
			-webkit-transition: opacity 1s ease-in-out;
			-moz-transition: opacity 1s ease-in-out;
			-ms-transition: opacity 1s ease-in-out;
			-o-transition: opacity 1s ease-in-out;
			transition: opacity 1s ease-in-out;
			transition-delay: 1s;
			> div {
				transform: scale(1) translateY(0px);
				@for $i from 1 through $liveItems {
					&:nth-child(#{$i}) {
						transition-delay: #{0.07 * $i + 0.2}s;
					}
				}
			}
		}
	} // menu opened

	.live-status {
		opacity: 0;
		position: relative;
		@include interstate;
		text-transform: uppercase;
		text-decoration: none;
		font-size: 1.5em;
		line-height: 2.35;
		width: 100%;

		.isLive,
		.isRadio {
			border-bottom: 1px solid #c1c6c8;
			padding-left: 20px;

			@include general-breakpoint(landscape) {
				border-bottom: none;
			}

			a {
				&:hover {
					text-decoration: none;
				}
			}

			&:hover {
				background-color: $dk-gray;
			}
		}

		span {
			margin-bottom: 0;

			&.onAir::before,
			&.offAir::before {
				color: $white;
				display: inline-block;
				font-size: 9px;
				margin-right: 3px;
				text-align: center;
				white-space: nowrap;
				width: 40px;
			}

			&.onAir {
				color: $red_live;

				&::before {
					content: 'ON AIR';
					background: $red_live;
					font-weight: bold;
				}
			}

			&.offAir {
				color: $md-gray;

				&::before {
					content: 'OFF AIR';
					background: $md-gray;
				}
			}
		}
	}
} // menu-container

.submenu-container {
	position: absolute;
	// display: none;
	// top: 0;
	left: 150px;
	// min-height: 100%;
	// width: 224px;
	width: 0;
	background-color: rgba(255, 255, 255, 1);
	overflow-y: auto;
	z-index: 1000;
	&.sticky {
		position: fixed;
		top: 100px;
	}
	&.shown {
		// display: block;
		width: 224px;
		@include general-breakpoint(desktop) {
			display: none;
		}
	}
	ul.submenu {
		position: relative;
		display: none;
		padding: 0;
		list-style: none;
		&.shown {
			display: block;
			@include general-breakpoint(desktop) {
				display: none;
			}
		}
		li.submenu-item {
			padding: 0 0 0 20px;
			// &.selected{
			//     text-decoration: underline;
			// }
			a {
				&:hover {
					color: $usoLtBlueBG;
				}
				display: block;
				position: relative;
				@include interstate;
				text-decoration: none;
				width: 100%;
				font-size: 14px;
				letter-spacing: 0;
				line-height: 40px;
				color: #4a4a4a;
			}
		}
	}
}

.uso-logo {
	width: 142px;
	@include general-breakpoint(smtablet) {
		width: 148px;
	}
}
.ibm-logo {
	width: 42px;
	@include general-breakpoint(smtablet) {
		width: 61px;
	}
}

.ibm-ribbon {
	width: 225px;
	height: 35px;
}

body.modal-on-noscroll {
	position: fixed;
	width: 100%;
	height: 100%;
	overflow-y: hidden;
}

body.livebar-open-noscroll {
	position: fixed;
	width: 100%;
	height: 100%;
	overflow-y: hidden;
}

.modal-on-noscroll-nofixed {
	overflow-y: hidden;
}
