.LikelihoodToWinBanner {
    .watson-logo {
        background: url("/assets/images/logos/watson-white-logo.svg") no-repeat; 
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        transform: translateY(5px);
        height: 19px;
        width: 19px;
        margin-bottom: 8px;

        &.left {
            left: 10%;
        }

        &.right {
            right: 10%;
        }
    }
    .likelihood-win-btn {
        @include interstate(bold);
        border-radius: 7px;
        // background-color: $powerrank-purple;
        @include watsonBackgroundColor;
        color: $white;
        font-size: px2rem(16);
        margin: auto;
        margin-bottom: 17px;
        // line-height: px2rem(35);
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: calc(100% - 10px);

        &:after {
            content: ' ';
            height: 0;
            position: absolute;
            width: 0;
            border: 10px solid transparent; /* arrow size */
            // border-top-color: $watson-bg-default;
            bottom: -20px;
            z-index: 1;
        }

        &.powered-by {
            &:before {
                content: 'Powered by IBM';
                height: 11px;
                width: 100%;
                position: absolute;
                bottom: -16px;
                text-align: center;
                @include interstate();
                font-size: px2rem(10);
                color: $powerrank-text;
                text-transform: capitalize;
            }
        }

        &.left {
            &:after {
                left: 25%; //11%;
                border-top-color: $likelihoodwin-caret-left;  /* arrow color */
                // border-top-color: #1163FF;
            }
        }

        &.center {
            &:after {
                left: 48%;
                border-top-color: $likelihoodwin-caret-center;  /* arrow color */
            }
        }

        &.right {
            &:after {
                right: 25%; //10%;
                border-top-color: $likelihoodwin-caret-right;  /* arrow color */
                // border-top-color: $watson-bg-start;
            }
        }
    }
}

.insights-index {
    .LikelihoodToWinBanner {
        .likelihood-win-btn {
            &.left {
                &:after {
                    left: 38px;
                }
            }
    
            &.right {
                &:after {
                    right: 38px;
                }
            }

            @include general-breakpoint(smtablet, max){
                &.left {
                    &:after {
                        left: 28px;
                    }
                }
        
                &.right {
                    &:after {
                        right: 28px;
                    }
                }
            }
            @include general-breakpoint(mobile, max){
                &.left {
                    &:after {
                        left: 18px;
                    }
                }
        
                &.right {
                    &:after {
                        right: 18px;
                    }
                }
            }

        }

    }
}

/** override for Match Insights details page */
.match-insights-content{
    .LikelihoodToWinBanner {
        .watson-logo {
            @include general-breakpoint(desktop) {
                height: 38px;
                width: 33px;
                margin-bottom: 6px;
            }
        }

        .likelihood-win-btn {
            font-size: px2rem(16);

            &.left {
                &:after {
                    left: 20px;
                }
            }
    
            &.right {
                &:after {
                    right: 18px;
                }
            }
    
            @include general-breakpoint(smtablet) {
                font-size: px2rem(12);
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;

                &.left {
                    &:after {
                        left: 38px;
                    }
                }
        
                &.right {
                    &:after {
                        right: 38px;
                    }
                }
            }
    
            @include general-breakpoint(desktop) {
                font-size: px2rem(18);
    
                &.center {
                    &:after {
                        left: 46%;
                    }
                }
            }
    
            @include general-breakpoint(smtablet, max) {
                max-width: 434px;
            }
        }
    }
}

/** override for news embedded component */
.news .LikelihoodToWinBanner {
    .likelihood-win-btn {
        width: 100%;

        @include general-breakpoint(smdesktop) {
            font-size: px2rem(14);
        }
    }
}

.slamtracker .LikelihoodToWinBanner {
    .likelihood-win-btn {
        @include general-breakpoint(smdesktop) {
            font-size: px2rem(14);
        }
    }
}

/** slamtracker override **/
.slamtracker {
    .LikelihoodToWinBanner {
        .likelihood-win-btn {
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            .watson-logo {
                display: unset;
                vertical-align: unset;
                transform: none;
                margin-bottom: 0;
                margin-right: 10px;
            }
            &.left {
                &:after {
                    left: 17.5%;
                }
            }
            &.right {
                &:after {
                    right: 17.5%;
                }
            }
        }
    }
}

/** slamtracker panel override **/
// .stpanel-container {
    .slamtracker {
        .LikelihoodToWinBanner {
            .likelihood-win-btn {
                font-size: px2rem(16);

                &.left {
                    &:after {
                        left: 10.5%;
                    }
                }
                &.right {
                    &:after {
                        right: 10.5%;
                    }
                }
            }
        }
    }
// }
