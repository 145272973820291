.playerWrapper {
	position: absolute;
	width: 600px;

	.ecpRadio {
		position: absolute;	
		top: -500px;
	}
}

#radioModule {
	position: absolute;
	width: 100%;
	max-width: 600px;
	min-height: 175px;
	background: url("/assets/images/live-radio/radio_bg.jpg") no-repeat left top;
	background-size: auto;
	background-position-y: -75px;
	top: 0px;
	left: 0px;

	.channel {
		// make ecp player invisible
		&.akamai-player {
			background: transparent;
			overflow: visible;
		}

		.akamai-layer {
			visibility: hidden;
		}
	}

	.top {
		.logo {
			position: absolute;
			width: 46px;
			height: 46px;
			top: 10px;
			left: 20px;
			background-image: url("/assets/images/logos/Wimbledon_logo.png");
			background-repeat: no-repeat;
			background-size: 100%;
		}
		.title {
			text-transform: uppercase;
			font-size: 18px;
			line-height: 46px;
			color: $white;
			margin-top: 10px;
			margin-left: 80px;
			@include interstate(medium);
		}
		.ribbon {
			position: absolute;
			right: 15px;
			top: 24px;
			img {
				height: 15px;
			}
		}
	}
	.bottom {
		margin-top: 37px;
		margin-left: 10px;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: center;
		}
		li {
			list-style: none;
			margin: 0;
			padding: 0;
			color: $white;
			margin-left: 10px;
			min-width: 32%;
		}
		.channels {
			margin-top: 14px;
		}
		.radio-play {
			display: block;
			float: left;
			margin-top: 5px;
			width: 22px;
			height: 27px;
			img {
				display: block;
				width: 22px;
				height: 27px;
			}
		}
		.station {
			float: left;
			margin-left: 8px;
			position: relative;
			.status {
				font-size: 8px;
				text-transform: uppercase;
				position: absolute;
				top: -18px;
				left: 0;
				width: 48px;
				height: 16px;
				padding: 1px 0;
				line-height: 14px;
				text-align: center;
				@include interstate();
			}
			.name {
				font-size: 16px;
				white-space: nowrap;
				@include interstate(medium);
			}
			.schedule {
				font-size: 10px;
				font-style: italic;
				white-space: nowrap;
				@include interstate();
			}
		}
		.channel.offair {
			.radio-play {
				opacity: 0.5;
			}
			.station {
				opacity: 0.5;
			}
			.status {
				border: 1px solid white;
				&:after {
					content: "Off Air";
				}
			}
		}
		.channel.onair {
			.radio-play {
				cursor: pointer;
			}
			.status {
				border: 1px solid green;
				background: green;
				color: $black;
				&:after {
					content: "On Air";
				}
			}
		}
		.channel {
			// make ecp player invisible
			&.akamai-player {
				background: transparent;
				overflow: visible;
			}

			.akamai-layer {
				visibility: hidden;
			}
		}
	}
}
#radioModule.offair {
	#status {
		display: block;
		.live {
			display: none;
		}
		.offair {
			display: block;
		}
	}
	.controls {
		display: block;
		.radio-play {
			display: block;
			cursor: default;
		}
		.radio-stop {
			display: none;
		}
	}
	#volume {
		display: none;
	}
	#loading {
		display: none;
	}
}
#radioModule.livenotplaying {
	#status {
		display: block;
		.live {
			display: block;
		}
		.offair {
			display: none;
		}
	}
	.controls {
		display: block;
		.radio-play {
			display: block;
		}
		.radio-stop {
			display: none;
		}
	}
	#volume {
		display: none;
	}
	#loading {
		display: none;
	}
}
#radioModule.liveplaying {
	#status {
		display: block;
		.live {
			display: block;
		}
		.offair {
			display: none;
		}
	}
	.controls {
		display: block;
		.radio-play {
			display: none;
		}
		.radio-stop {
			display: block;
		}
	}
	#volume {
		display: block;
	}
	#loading {
		display: none;
	}
}
#radioModule.liveplayingipad {
	#status {
		display: block;
		.live {
			display: block;
		}
		.offair {
			display: none;
		}
	}
	.controls {
		display: block;
		.radio-play {
			display: none;
		}
		.radio-stop {
			display: block;
		}
	}
	#volume {
		display: none;
	}
	#loading {
		display: none;
	}
}
#radioModule.loading {
	#loading {
		display: block;
	}
}
@media only screen and (min-width: 100px) and (max-width: 599px) {
	#radioModule {
		.top {
			.title {
				width: 50%;
				line-height: 23px;
			}
		}
		.bottom {
			ul {
				flex-direction: column;
				align-items: center;
			}
			li {
				margin-bottom: 40px;
				min-width: 60%;
			}
		}
	}
}