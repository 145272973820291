.uso-tickets-options {
	margin: auto;
	@include general-breakpoint(landscape) {
		max-width: $sm-alt-max-content-width;
	}

	@include general-breakpoint(smtablet) {
		max-width: $md-alt-max-content-width;
	}

	@include general-breakpoint(smdesktop) {
		max-width: $md-max-content-width;
	}

	@include general-breakpoint(desktop) {
		max-width: $lg-max-content-width;
	}
}
.ticket-options {
	margin-bottom: 30px;

	@include general-breakpoint(smtablet) {
		margin-bottom: 40px;
	}

	.to-container {
		padding: 20px 10px 35px;
		// display: flex;
		// flex-direction: row;
		background-color: $white;
		// justify-content: space-around;
		.to-option {
			border: 1px solid $usoLtGray;
			margin: 0 10px;
			flex: 1 1 25%;
			display: flex;
			flex-direction: column;
			// min-width: 290px;
			width: calc(100% - 20px) !important;
			.to-photo-container {
				width: 100%;
				overflow: hidden;
				img {
					width: 100%;
					transition: transform 0.5s ease;
					&:hover {
						transform: scale(1.05);
					}
				}
			}
			.to-button {
				padding: 20px;
				&.to-empty {
					min-height: 84px;
				}
			}
			h4 {
				font-size: 16px;
				font-weight: bold;
				padding: 12px 15px;
				@include interstate(bold);
				@include general-breakpoint(landscape, 'max') {
					min-height: 65px;
				}
			}
			.to-session {
				font-size: 14px;
				color: $usoDkGray;
				@include interstate(light);

				b,
				strong {
					@include interstate(bold);
				}
				h3 {
					padding: 6px 20px;
					font-size: 14px;
					border-top: 1px solid $usoLtGray;
					border-bottom: 1px solid $usoLtGray;
					@include interstate(bold);
					color: $dark-blue3;
					text-transform: uppercase;
				}
				&.to-day {
					background-color: $white;
					min-height: 340px;
					@include general-breakpoint(landscape) {
						min-height: 300px;
					}
					h3 {
						width: 100%;
						background-color: $usoYellow;
					}
				}
				&.to-night {
					flex: 1;
					background-color: $usoBkgGray;
					height: 210px;
					overflow-y: auto;
					h3 {
						background-color: $usoDkBlue;
						color: $white;
					}
				}
				ul {
					margin-left: 0px;
					margin-right: 10px;
					margin-bottom: 0;
					padding-top: 0;
					padding-bottom: 10px;
				}
				p {
					padding: 5px 15px 0 20px;
					margin-bottom: 0;
				}
			}
		}

		.slick-dots {
			bottom: -25px;
			// left: 50%;
			// position: absolute;
			// transform: translate(-50%, 0);
			margin: 0;

			li {
				width: 8px;

				button {
					&:before {
						font-size: 7px;
						@include uso-icon('Icons');
						content: '\e917';
						color: $dk-gray2;
					}
				}

				&.slick-active {
					button {
						&:before {
							@include uso-icon('Icons');
							content: '\e916';
							color: $dk-gray2;
						}
					}
				}
			}
		}
		.slick-next,
		.slick-prev {
			bottom: -20px;
			top: unset;
			transform: none;
			z-index: 1;

			&.slick-disabled {
				cursor: default;
			}
		}
		.slick-prev {
			left: 20px;
		}
		.slick-next {
			right: 20px;
		}
		.slick-next:before {
			@include uso-icon('Icons');
			content: '\e902';
			color: $usoDkGray;
			font-weight: bolder;
			font-size: 14px;
		}
		.slick-prev:before {
			@include uso-icon('Icons');
			content: '\e901';
			color: $usoDkGray;
			font-weight: bolder;
			font-size: 14px;
		}
	}
}
