.homeHeroLatestStandalone, .homeHeroStandalone { 
    background-color: $white;

    a, a:hover{ 
        text-decoration: none;
    }

    .mainHero{ 
        background-color: $white;
        width: 100%; 
        overflow: hidden;

        .storyImg{ 
            position: relative;

            img {
                width: 100%; 
                height: auto;
            }

            .playBtn{ 
                @include wh(100px);
                background-size: 100px; 
                left: 35%;
                top: 25%;
                margin-top: 0px;
                margin-left: 0px; 
                @include general-breakpoint(smtablet){
                    left: 45%;
                    top: 35%;
                }

                &.hide { 
                    display: none;
                }
            }
        }
        .storySect{ 
            padding: 20px 25px;
            color: $usoDkGray; 

            .title{ 
                @include interstate; 
                color: $usoDkGray; 
                font-size: px2rem(18); 
                // line-height: 40px; 
                padding-bottom: 10px;

                a{ 
                    color: $usoDkGray; 
                    text-decoration: none;

                    &:hover { 
                        text-decoration: none;
                    }
                }

                @include general-breakpoint(smtablet){
                    font-size: px2rem(20); 
                }

                @include general-breakpoint(smdesktop){
                    font-size: px2rem(36); 
                }
            }

            .articleInfo { 
                @include interstate; 
                font-size: px2rem(12); 
                line-height: px2rem(20); 
                color: $usoDkGray;
            }

            .desc { 
                display: flex; 
                justify-content: space-between; 
                align-items: center; 
                margin-top: 10px; 
                overflow: hidden;

                .text { 
                    // width: 70%; 
                    @include interstate(light); 
                    font-size: px2rem(14); 
                    line-height: 20px;
                }

                .btn { 
                    background-color: $bright-blue; 
                    color: $white; 
                    @include interstate; 
                    font-size: px2rem(14); 
                    line-height: 16px; 
                    height: 30px; 
                    text-align: center;
                }
            }
        }
        // @include general-breakpoint(smtablet){
        //     width: 100%; height: 311px; order: 1;
        //     .storyImg{ 
        //         img{min-width: 347px; width: 100%; height: 195px; object-fit: cover; }
        //         .playBtn{ top: 50%; left: 55%; margin-top: -50px; margin-left: -50px; width: 75px; height: 75px; background-size: 75px; }
        //     }
        //     .storySect{
        //         .title{ font-size: px2rem(20); line-height: px2rem(26); }
        //         .desc{visibility: hidden;
        //             .text{ font-size: px2rem(14); line-height: px2rem(20); vertical-align: top; height: 100%; }
        //         }
        //     }
        // }
        // @include general-breakpoint(smdesktop){
        //     width: 595px; height: 600px; 
        //     .storyImg img{ width: 100%; height: 363px;}
        //     .storySect{
        //         .title{ font-size: px2rem(28); line-height: px2rem(30); }
        //         .article{font-size: px2rem(16); line-height: px2rem(20); margin-top: 15px; }
        //         .desc{ visibility: visible;}
        //     }
        // }
        // @include general-breakpoint(desktop){
        //     margin: 0px 25px; order: 2; width: 555px; flex-grow: 2;
        //     .storySect{
        //         .title{ padding-bottom: 20px;}
        //         .article{ margin-top: 0px; font-size: px2rem(14); line-height: px2rem(20);}
        //         .desc{ visibility: visible;}
        //     }
        // }

        &.dark { /* color background option */
            background-color: $dark-blue3;
            .storySect { 
                    color: $white;
                .title {
                    a {
                        color: $white;
                    }
                }
            }
        }
    }

}