#uso-livebar {
	z-index: 1;
	left: 0;
	bottom: 0;
	width: 100%;
	display: block;

	@include draws-breakpoint(mxlarge) {
		order: 1;
		width: unset;
		margin-right: 15px;
	}
	.uso-livebar-wrapper {
		cursor: pointer;
		margin-left: 26px;
		margin-right: 25px;
		margin-bottom: calc((#{$mobile-header-height} - 34px) / 2 * -1);
		margin-top: 3px;
		padding-bottom: calc((#{$mobile-header-height} - 34px) / 2);
		@include general-breakpoint(smtablet) {
			margin-left: 30px;
		}
		@include general-breakpoint(desktop) {
			// padding: 10px 0;
			width: 130px;
		}
		&:hover {
			.menu-bar-items.desktop-show {
				display: flex;
			}
			.menu-title-bar {
				background-color: $usoDkBlue;
				border: 1px solid white;
				border-radius: 3px;

				.menu-title {
					color: #fff;
				}
			}
		}
	}
	.live-bar {
		&.icon-close {
			position: absolute;
			top: 12px;
			right: 9px;
			color: $usoLtGray;
			font-size: 15px;
			cursor: pointer;
		}
	}

	.livebar-mobile-show {
		display: none;
		@include general-breakpoint(desktop, max) {
			@include interstate(bold);
			display: flex;
			position: absolute;
			font-size: 16px;
			color: white;
			top: 0;
			right: 0;
			width: 100%;
			background-color: $usoDkBlue;
			justify-content: center;
			align-items: center;
			padding: 6px 0;
		}
	}

	.menu-title-bar {
		border-radius: 3px;
		width: 65px;
		height: 34px;
		border: 1px solid $usoMdGray;
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;

		@include general-breakpoint(smtablet) {
			width: 122px;
		}
		@include draws-breakpoint(mxlarge) {
			width: 130px;
		}

		&:hover,
		&.active {
			background-color: $usoDkBlue;
			border: 1px solid white;
			border-radius: 3px;
			.menu-title {
				color: #fff;
			}
		}

		.menu-title {
			color: $usoDkGray;
			text-transform: uppercase;
			cursor: pointer;
			font-size: 12px;
			@include interstate(black);
			display: flex;
			justify-content: flex-start;
			align-items: center;

			&:after {
				font-size: 25px;
				font-weight: 100;
				vertical-align: middle;
				color: #d8d8d8;
				margin-left: 10px;
			}
		}
	}
	.dot {
		height: 8px;
		width: 8px;
		background-color: red;
		border-radius: 50%;
		display: inline-block;
		margin: 2px 8px 2px 0;
		&.longTitle {
			position: absolute;
			left: 10px;
			+ div {
				margin-left: 10px;
				width: 75px;
			}
		}
	}
	.menu-title-onair {
		display: inline-block;
	}
	.menu-bar-items {
		&.desktop-show {
			display: none;
		}
		position: fixed;
		width: 100%;
		left: 0;
		display: flex;
		flex-wrap: wrap;
		padding: 20px 208px;
		justify-content: flex-start;
		align-items: center;
		background-color: $usoDkerBlue2;
		z-index: 99;

		margin-top: calc((#{$mobile-header-height} - 34px) / 2);
		align-content: flex-start;
		height: 100vh;
		padding: 50px 7px 30px 7px;

		@include general-breakpoint(smtablet) {
			height: unset;
			padding: 40px 68px;
		}

		@include general-breakpoint(smdesktop) {
			padding: 39px 49px;
		}

		@include general-breakpoint(desktop) {
			justify-content: center;
		}
	}
	.titlebtn-onair {
		margin-left: 20px;
		width: 68px;
		height: 23px;
		vertical-align: middle;
		text-align: center;
		font-size: px2rem(14);
		line-height: px2rem(16);
		text-transform: uppercase;
		border-radius: 0px;
		padding: 3px 5px;
		background-color: $usoRed;
		color: $white;
		display: inline-block;
		@include interstate(bold);
	}
	.link-tile {
		width: 100%;
		height: 100%;

		&.onAir:hover {
			text-decoration: none;
		}
	}

	.bar-item {
		@media only screen and (max-device-width: 425px) {
			width: calc(100% / 2 - 24px);
			max-width: 170px;
		}
		@media screen and (max-width: 374px) {
			margin: 5px;
			width: calc(100% / 2 - 10px);
			// max-width: 143px;
		}

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 134px;
		max-width: 134px;
		height: auto;
		margin: 12px;
		position: relative;
		background-size: cover;
		background-position: center center;

		a {
			display: flex;
			width: 100%;
			height: 100%;
			justify-content: center;

			&:hover {
				text-decoration: none;
			}
		}
		&:hover {
			outline: 3px solid $bright-blue;
			text-decoration: none;
		}
		&.offAir {
			opacity: 0.5;
		}
		.btn {
			position: absolute;
			bottom: -23px;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 68px;
			height: 23px;
			vertical-align: middle;
			text-align: center;
			font-size: px2rem(14);
			line-height: px2rem(16);
			text-transform: uppercase;
			border-radius: 0px;
			padding: 3px 5px;
			@include interstate(bold);

			&.onAir {
				background-color: $usoRed;
				color: $white;
			}

			&.offAir {
				background-color: $bright_blue;
				color: $white;
			}
		}
		.title,
		.link-tile {
			font-size: px2rem(16);
			line-height: px2rem(16);
			color: $usoDkBlue;
			text-transform: uppercase;
			display: flex;
			justify-content: center;
			align-items: center;
			@include interstate(condensedbold);
			position: relative;
			width: 100%;
			text-align: center;

			i {
				font-size: 11px;
				margin-left: 5px;
				margin-top: -1px;
				font-weight: bold;
			}
			&::before {
				padding-top: 56.25%;
				content: '';
			}
		}
	}
}
