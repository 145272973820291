.contents-in-header-wrapper {
    display: flex;
    // position: absolute;
    width: 100%;
    bottom: 100%;
    color: $white;

    // &::before{
    //     content: '';
    //     height: 180%;
    //     width: 100%;
    //     position: absolute;
    //     bottom: 0px;
    // }

    .contents-in-header {
        &.hide {
            opacity: 0;
            transition: opacity .5s linear;

        }
        &.show {
            opacity: 1;
            transition: opacity .5s linear;
        }
        margin: 0 auto;
        width: 100%;
        color: $white;
        background-color: $usoBkgGray;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; //pointer-events: none;
        opacity: 1;
        z-index: 2;
        h2,
        h3,
        h4 {
            margin: 0px;
        }
        &-date {
            flex: 0 1 auto;
            align-self: center;
            margin: 0 0 4px 0;
            text-align: center;
            letter-spacing: 1px;
            color: $white;
            h3 {
                text-transform: uppercase;
                font-size: px2rem(12);

                @include general-breakpoint(tablet) {
                    font-size: px2rem(18);
                }
            }
        }
        &-title {
            width: 100%;
            align-self: center;
            margin: 0 auto;
            padding: 22px 4%;
            text-align: left;
            color: $white;
            background: $usoDkBlue;
            display: flex;
            align-items: center;
            justify-content: center;

            h1{ 
                margin: 0;
                font-size: px2rem(28);
                @include interstate(regular);
                line-height: 28px;
                text-shadow: 2px 2px 1px rgba(0,0,0,0.80);
                color: #FFFFFF;
                text-align: center;

                .icon-info {
                    font-size: 55%;
                }
            }

            a,
            a:hover,
            a:active,
            a:visited {
                color: $white;
                text-decoration: none;
            }

            i.icon-info {
                padding-left: 10px;
            }
        }
        &-byline {
            width: 100%;
            height: 88px;
            align-self: center;
            margin: 0 auto;
            padding: 0 4%;
            text-align: left;
            color: $dk-gray2;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h4.sub-title {
                margin: 0;
                font-size: px2rem(14);
                @include interstate(light);
                line-height: 18px;

                div {
                    display: inline;
                }
            }
        }

        hr.short-line {
            border: none;
            border-top: 3px solid $white;
            color: $white;
            margin: 15px auto;
            overflow: visible;
            text-align: center;
            height: 2px;
            width: 30px;
        }

        /**** SOCIAL SHARE ***/
        .socialShare {
            display: flex;
            .SocialMediaShareButton {
                margin-right: 15px;
                cursor: pointer;
            }
        }

        @include general-breakpoint(smtablet) {
            &-title {
                min-height: 88px;

                h1 {
                    font-size: px2rem(36);
                    line-height: 45px;
                }
            }
            &-subtitle {
                h4.sub-title {
                    font-size: px2rem(18);
                    line-height: 36px;
                }
                .subtitle-divider {
                    margin: 0 10px;
                }
            }
        }

        @include general-breakpoint(smdesktop) {
            &-title {
                padding: 0 4%;

                h1 {
                    line-height: 52px;
                }
            }
        }
    }

    /* overrides for IBM Power Index header */
    &.power-rankings {
        position:relative;

        &::before {
            background-image:none;
        }
        .contents-in-header {
            width:100%;
            &-title {
                h1 {
                    text-transform: uppercase;
                    font-size:px2rem(20)
                }
            }
            &-date {
                h3 {
                    font-size:px2rem(12)
                }
            }
            h4.sub-title {
                    @include interstate();
                    font-size: px2rem(12);
            }
        }

        hr.short-line {
            margin: 10px auto;
        }

        @include general-breakpoint(tablet) {
            .contents-in-header {
                &-title {
                    h1 {
                        font-size: px2rem(28);
                    }
                }
                h4.sub-title {
                    font-size: px2rem(16);
                    padding: 0px 12.5%;
                }
            }
        }
    }
}

/* override for Webview - Match Insights */
.match-insights {
    &.webview {
        .contents-in-header-wrapper {
            position: relative;
            
            &::before {
                background-image:none;
            }
        }
    }
}