.PowerIndexChart {
    position: relative;
    display: flex;
    margin-top: 30px;

    .probability {
        color: $usoDkGray;
        font-size: px2rem(18);
        white-space: nowrap;
        margin-top: 30px;

        .slamtracker-only {
            display: none;
        }

        .player-name {
            font-size: px2rem(12);
            display: block;
        }

        &.team1 {
            // color: $team1_color;
            margin-right: 20px;

            @include general-breakpoint(smtablet, max){
                margin-right: 5px;
                margin-left: 5px;
            }
        }

        &.team2 {
            // color: $team2_color;
            margin-left: 20px;
            
            @include general-breakpoint(smtablet, max){
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        @include general-breakpoint(smtablet) {
            font-size: px2rem(36);
            margin-top: 60px;
        }
    }

    .team2-chart-style {
        fill: $team2_color;
        stroke: $white;
        stroke-width: 1px;
    }

    .team1-chart-style {
        fill: $team1_color;
        stroke: $white;
        stroke-width: 1px;
    }

    // fallback 'vs' display
    .team0-chart-style {
        fill: $team2_color;
    }

    hr.vs{
        height: 1px;
        width: 85px;
        @include breakpoint(tablet){
            width: 110px;
        }
        background: transparent;
        border: 0;
        border-top: 2px solid #4C2178;
        position: absolute;
        top: 24px;
        padding: 0;
        z-index: 0;
        &.team1{
            right: 70px;
        }
        &.team2{
            left: 70px;
        }
    }

    .ct-chart, .donutChart{
        width: 70px;
        height: 70px;
        position: relative;

        &:before{
            content: 'VS.';
            color: $usoDkGray;
            @include interstate();
            // color: $team1_color;
            display: inline-block;
            font-size: px2rem(14);
            line-height: 24px;
            position: absolute;
            top: 25px;
            left: 26px;
        }
    }

    .winnerCheck {
        position: absolute;
        // bottom: -3px;

        &.team2 {
            right: 10px;
            top: -18px;

            .icon-check:before {
                // color: $team2_color;
                color: $scores-check-color;
            }
        }

        &.team1 {
            left: 10px;
            top: -18px;

            .icon-check:before {
                // color: $team1_color;
                color: $scores-check-color;
            }
        }
    }
}

.insights-cards
    .PowerIndexChart {
        .probability {
            margin-top: 20px;

            &.team1, &.team2 {
                font-size: px2rem(18);
            }
    
            &.team1 {
                margin-right: 10px;
                margin-left: 5px;
    
                @include general-breakpoint(smtablet, max){
                    margin-right: 10px;
                }
            }
    
            &.team2 {
                margin-left: 10px;
                margin-right: 5px;
            }
        }
        
    }

/** override for Match Insight details page & Embedded article MatchInsightsCard */
#match-insights, .news {
    .PowerIndexChart {
        .probability {
            @include general-breakpoint(smtablet) {
                margin-top: 30px;
            }

            @include general-breakpoint(smdesktop) {
                margin-top: 40px;
            }

            &.team1 {
    
                @include general-breakpoint(smtablet, max){
                    margin-right: 5px;
                }
            }
    
            &.team2 {
                @include general-breakpoint(smtablet, max){
                    margin-left: 5px;
                }
            }

            &.team1, &.team2 {
                @include general-breakpoint(smtablet) {
                    font-size: px2rem(26);
                }

                @include general-breakpoint(smdesktop) {
                    font-size: px2rem(36);
                }
            }
        }

        .ct-chart {
            @include general-breakpoint(smtablet){
                width: 100px;
                height: 100px;

                &:before{
                    font-size: px2rem(26);
                    top: 39px;
                    left: 33px;
                }
            }

            @include general-breakpoint(smdesktop){
                width: 140px;
                height: 140px;

                &:before{
                    font-size: px2rem(36);
                    top: 59px;
                    left: 43px;
                }
            }
        }
        
        .winnerCheck {

            @include general-breakpoint(smdesktop) {
                font-size: px2rem(20);
            }

            &.team1 {
                left: -10px;

                @include general-breakpoint(smtablet) {
                    left: -10px;
                }

                @include general-breakpoint(smdesktop) {
                    left: 0px;
                    bottom: -60px;
                }

                @include general-breakpoint(desktop) {
                    left: -30px;
                }
            }
            
            &.team2 {
                right: -10px;

                @include general-breakpoint(smtablet) {
                    right: -10px;
                }

                @include general-breakpoint(smdesktop) {
                    right: 0px;
                    bottom: -60px;
                }

                @include general-breakpoint(desktop) {
                    right: -30px;
                }
            }
        } // .winnerCheck
    } // .powerIndexChart
}

/** news article embedded MatchInsightsCard override */
.news {
    .PowerIndexChart {
        width: 100%;
        justify-content: space-between;
    }
}

/** slamtracker override */
.slamtracker {
    .PowerIndexChart {
        @include interstate;

        .probability {
            text-align: center;

            @include general-breakpoint(smtablet) {
                font-size: px2rem(24);
            }

            @include general-breakpoint(smdesktop) {
                &.team1 {
                    margin-right: 10px;
                }
                
                &.team2 {
                    margin-left: 10px;
                }
            }

            .slamtracker-only {
                .player-name {
                    @include general-breakpoint(smtablet) {
                        font-size: px2rem(12);
                    }
                }
            }
        }
        .ct-chart {
            &:before {
                content: 'VS.';
                font-size: px2rem(14);
                top: 23px;
                left: 26px;
            }
        }
    }
}

/** slamtracker page (only) override */
.slamtracker {
    &.st-page {
        .PowerIndexChart {
            margin-top: 10px;

            .winnerCheck {
                &.team1 {
                    left: 30px;
                    top: 0;
                }
                &.team2 {
                    right: 30px;
                    top: 0;
                }
            }
        }
    }
}

/** slamtracker panel override */
// .stpanel-container 
.slamtracker .PowerIndexChart .probability {
    font-size: px2rem(20);

    &.team1 {
        margin-right: 5px;
    }
    &.team2 {
        margin-left: 5px;
    }
}

/** insights index override */
.insights-cards-container {
    .PowerIndexChart {
        .probability {
            .slamtracker-only {
                .player-name {
                    display: none;
                }
            }
        }
    }
}