.mi-section-header {
    display: flex;
    flex-wrap: wrap;
    color: $dk-gray2;
    padding-bottom: 35px;
    justify-content: space-between;

    @include general-breakpoint(desktop) {
        padding-bottom: 35px;
    }

    i.icon-info {
        margin-left: 15px;
    }
    
    &.power-index {
        @include general-breakpoint(smtablet, max) {
            margin-bottom: -15px;
            padding-bottom: 10px;
            justify-content: flex-end;
            // display: block;
            // margin-top: -60px;
            // position: relative;
            // z-index: 3;
        }
    }
}

.mi-section-title {
    text-transform: uppercase;
    font-size: px2rem(16);
    display: inline-flex;
    @include interstate(bold);
    color: $gray-4a;

    &.close {
        position: relative;

        &:after {
            @include uso-icon('Icons');
            font-size: px2rem(16);
            font-weight: normal;
            position: absolute;
            content: '\e91e'; // check icon
            right: 16px;
            top: 16px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    @include general-breakpoint(smtablet) {
        font-size: px2rem(16);
    }

    @include general-breakpoint(smdesktop) {
        font-size: px2rem(16);
    }
}

.mi-last-updated {
    color: $md-gray3;
    display: inline-flex;
    font-size: px2rem(12);
    text-transform: uppercase;
    @include interstate(light);
    
    @include general-breakpoint(smtablet, max) {
        // width: 100%;
        margin-top: 7px;
        // margin-right: 10px;
    }

    @include general-breakpoint(smtablet) {
        display: inline-block;
        // margin-right: 42px;
        margin-top: 24px;
    }

    &.right {
        display: inline-flex;
        margin-left: auto;
    }

    // this is because Time components returns with div tag,
    div {
        display: inline-block;
        margin-left: 3px;
    }
}

/** slamtracker override */
.slamtracker {
    .mi-section-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 0px;

        &.tab-style {

            .mi-section-title {
                font-size: px2rem(12);
                background-color: $white;
                // color: $uso;
                line-height: normal;
                padding-top: 20px;
                // border-bottom: solid 1px $gray_bb;
            }
        }

        &.power-index {
            .mi-last-updated {
                // margin-top:25px;
                
                @include general-breakpoint(smtablet) {
                    justify-content: flex-end;
                }
                
                @include general-breakpoint(smtablet, max) {
                    // margin-top: 15px;
                }
            }
            @include general-breakpoint(smtablet, max) {
                margin-bottom: unset;
            }
        }
    }

    .mi-last-updated {
        // right align the updated time
        display: inline-flex !important;
        margin: 0px;
        width: auto;
        font-size: px2rem(10);
    }

    h4.mi-section-title {
        font-size: px2rem(16);
        @include interstate();
        margin-top: 0 !important;
        color: $gray-4a;
    }

    @include general-breakpoint(smdesktop) {
        .mi-section-title {
            font-size: px2rem(16);
        }
    }
}
