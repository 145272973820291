.relatedContentContainer {
	margin-top: 30px;
	background-color: $white;
	.contentNavContainer {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid $usoLtGray;
		@include general-breakpoint(smdesktop) {
			height: 55px;
		}
		.contentTitle {
			width: 100%;
			height: 52px;
			display: flex;
			justify-content: left;
			align-items: center;
			padding-left: 20px;
			border-bottom: 1px solid $md-gray2;
			@include general-breakpoint(smtablet) {
				width: unset;
				border-bottom: none;
			}
			@include general-breakpoint(smdesktop) {
				position: absolute;
			}
			p {
				line-height: 1;
				margin: 0;
				padding: 0;
			}
			color: $dk-gray2;
			font-size: 18px;
			@include interstate(bold);
		}
		.relatedContent-nav {
			width: 100%;
			@include general-breakpoint(smtablet) {
				width: unset;
				align-self: center;
				margin: 0 auto;
			}
		}
	}
	.showMoreContainer {
		background-color: $usoltMedGray;
		width: 100%;
		justify-content: center;
		align-items: center;
		display: flex;
		.showMoreBtn {
			@include button();
			font-size: 14px;
			padding: 8px;
			width: 30%;
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	.related-cnt {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		text-align: initial;
		width: 100%;
		padding: 20px;
		@include general-breakpoint(smdesktop) {
			justify-content: space-between;
		}
		@include general-breakpoint(desktop) {
			justify-content: space-between;
		}
		&.three-col {
			margin-left: 0px;
			margin-right: 0px;
			@include clearfix;
		}
		.news-tile,
		.related-content-tile {
			width: 100%;
			flex: 0 0 auto;
			padding-bottom: 20px;
			@include general-breakpoint(landscape) {
				width: calc(49% - 5px * 2);
			}
			@include general-breakpoint(desktop) {
				width: calc(34% - 10px * 2);
			}
			.click-wrapper {
				cursor: pointer;
			}
			.tile-container,
			.click-wrapper {
				width: 100%;
				border: 1px solid $light-grey;
				height: 100%;
				display: block;
				a {
					text-decoration: none;
				}
				.image-container {
					position: relative;
					img {
						width: 100%;
						height: auto;
					}
					.playBtn {
						position: absolute;
						background: url('/assets/images/misc/video_play_icon.png') no-repeat 0 0;
						top: 50%;
						left: 50%;
						margin-top: -35px;
						margin-left: -35px;
						width: 75px;
						height: 75px;
						background-size: 75px;
						z-index: 1;
					}
				}
				.news-content,
				.content-text {
					padding: 10px 20px 0px;
					.type-tag {
						display: none;
					}
					.news-title,
					span .content-title {
						@include interstate(light);
						font-size: 12px;
						color: $usoDkGray;
						padding-bottom: 15px;
						@include general-breakpoint(tablet) {
							font-size: 14px;
						}
						@include general-breakpoint(smdesktop) {
							font-size: 16px;
						}
					}
					.news-byline,
					.news-timestamp,
					span .content-timestamp {
						@include interstate(light);
						font-size: 12px;
						color: $usoDkGray;
						line-height: 20px;
						text-transform: capitalize;
					}
				}
			}
		}
	}
	.news-tile {
		padding-bottom: 0;
	}
}

.slamtracker {
	.relatedContentContainer {
		float: none;
		padding-top: 20px;
		margin-top: 0;
		@include panel-content-background;

		.title {
			text-align: center;
			font-size: 14px;
			line-height: 16.8px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: #333333;
			@include interstate(light);
		}

		.related-cnt {
			padding: 0;

			@include general-breakpoint(desktop) {
				justify-content: center;
			}

			.news-tile,
			.related-content-tile {
				width: 80%;
				margin: 10px 10%;
				.tile-container,
				.click-wrapper {
					width: 100%;
					@include general-breakpoint(smdesktop) {
						width: 100%;
					}
					@include general-breakpoint(desktop) {
						width: 100%;
					}

					.news-content,
					.content-text {
						padding: 20px;
						.type-tag {
							display: none;
						}
						.news-title,
						span .content-title {
							@include general-breakpoint(tablet) {
								font-size: 12px;
							}
							@include general-breakpoint(smdesktop) {
								font-size: 12px;
							}
						}
						.news-byline,
						.news-timestamp,
						span .content-timestamp {
							@include general-breakpoint(smdesktop) {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
	.news-tile {
		min-height: unset;
		.newsChips {
			max-width: unset;
			background-color: $white;
			padding: 10px 16px;

			&.related-cnt {
				.chip_content {
					width: 100%;
					.chip {
						width: 100%;
						background-color: $white;
						padding: 0;
						margin: 0 0 10px;
						height: 64px;
						display: flex;
						flex-direction: flex-start;
						border: 1px solid rgba(151, 151, 151, 0.5);

						.credit,
						.date,
						.tile-footer {
							display: none;
						}
						.adunitContainer {
							display: none;
						}

						.photoContainer {
							height: 64px !important;
							width: auto;
							img {
								height: 100%;
								width: auto;
							}
							i {
								font-size: 32px;
							}
						}
						.caption:not(:first-child) {
							--max-lines: 3;
							margin: 8px 12px;
							padding: 0;
							height: 48px;
							@include interstate(bold);
							font-size: 13px;
							line-height: 16px;
							min-height: unset;
							margin-bottom: 0;
							max-height: calc(16 * 3);
							overflow: hidden;
							text-overflow: ellipsis;
							line-clamp: 3;
							-webkit-line-clamp: 3;
							display: -webkit-box;
							-webkit-box-orient: vertical;
						}

						&:first-child {
							height: auto !important;
							flex-direction: column;
							margin: 0 0 20px;

							.photoContainer {
								height: unset !important;
								img {
									width: 100%;
								}
								i {
									font-size: 56px;
								}
							}
							.caption {
								margin: 0 0 17px 0;
								height: unset;
								font-size: 14px;
								line-height: 16px;
								padding: 20px 16px 0 16px;
								min-height: 60px;
								max-height: unset;
								position: unset;
								overflow: unset;
								text-overflow: unset;
								line-clamp: unset;
								-webkit-line-clamp: unset;
								display: unset;
								-webkit-box-orient: unset;
							}
						}
					}

					.chip.ad {
						display: none;
					}
					.chip.ad-chip {
						display: none;
					}
					.chip,
					.news-ad {
						&.hovering {
							transform: none;
							box-shadow: none;
						}
					}
					.photoContainer {
						img {
							position: static;
							transform: none;
							height: auto;
						}
					}
				}
			}
		}
	}
	.showMoreContainer {
		background-color: transparent;
	}
}
