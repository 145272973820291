$general_outer_margin: 20px;
$general_inner_margin: 10px;

/** override to make header hero 100vs */
#uso-main .wrapper{ 
    .hero-container-wrapper{ visibility: hidden;}

    &.page-header-hero { max-width: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .hero_container{
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            // .hero_button{
            //     display: none;
            // }
            &.top {
                background-position: top;
            }
            &.bottom {
                background-position: bottom;
            }
        }
        .hero-container-wrapper{ visibility: visible;
            background: linear-gradient($usoDkBlue, rgba(0, 0, 0, 0));
            height: calc(100vh - 475px); width: 100%; position: absolute; z-index: 20; top: 75px;
            @include general-breakpoint(landscape){ top: unset; height: 400px;}
        }
        .mobile-container-wrapper{
            width: 100%; height: 75px; background: $usoDkBlue; position: absolute; z-index: 20;
            @include general-breakpoint(landscape){ visibility: hidden;}
        }
        
        .hero_container .hero_content{
            @include general-breakpoint(landscape){
                max-width: $sm-alt-max-content-width;
            }

            @include general-breakpoint(smtablet){
                max-width: $md-alt-max-content-width;
            }

            @include general-breakpoint(smdesktop){
                max-width: $md-max-content-width;
            }

            @include general-breakpoint(desktop){ 
                max-width: $lg-max-content-width;
            }
        }

        &.us_open_at_home {
            flex-direction: column;
        }

        @include media-breakpoint-up(sm) {
            section:nth-of-type(2){
                position: relative;
                margin-top: -80px;
            }
        }

        .premium-section{ 
            position: relative; 
            width: auto; 
            margin: 30px 15px 0 15px; 

            @include general-breakpoint(landscape){top: 0; margin: 0px auto;}
            // .premium-content{
            //     margin-left: 0px;
            //     margin-right: 0px;
            //     // padding-right: 32px;
            //     // padding-left: 32px;
            //     .image-bg{
            //         width: 100%;
            //         margin: 0px;
            //     }
            // }
        }

        .chip-container{
            margin: 30px auto;
        }

        .chip-container, .uso-news, .uso-interactive, .siteDetailContainer {
            @include general-breakpoint(landscape){
                max-width: $sm-alt-max-content-width;
            }

            @include general-breakpoint(smtablet){
                max-width: $md-alt-max-content-width;
            }

            @include general-breakpoint(smdesktop){
                max-width: $md-max-content-width;
            }

            @include general-breakpoint(desktop){ 
                max-width: $lg-max-content-width;
            }
        }

        .chip{
            .caption{
                font-size: 1.3rem;
                color: #4A4A4A;
                letter-spacing: 0;
            }
            
            .description{
                font-size: 1.2rem;
            }
        }
    }
}