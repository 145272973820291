@mixin interstate($weight: null) {
    $fallback_fonts: 'Helvetica, Arial, sans-serif';
    $font: 'interstate';
    @if ($weight==thin) {
      font-family: 'interstate', #{$fallback_fonts};
      font-weight: 200!important;
    }
    @else if ($weight==exlight) {
      font-family: 'interstate', #{$fallback_fonts};
      font-weight: 300!important;
    }
    @else if ($weight==light) {
      font-family: 'interstate', #{$fallback_fonts};
      font-weight: 400!important;
    }
    @else if ($weight==lightit) {
      font-family: 'interstate', #{$fallback_fonts};
      font-style: italic;
      font-weight: 400!important;
    }
    @else if ($weight==bold) {
      font-family: 'interstate', #{$fallback_fonts};
      font-weight: 700!important;
    }
    @else if ($weight==boldit) {
      font-family: 'interstate', #{$fallback_fonts};
      font-style: italic;
      font-weight: 700!important;
    }
    @else if($weight==medium) {
      font-family: 'interstate', #{$fallback_fonts};
      font-weight: 800!important;
    }
    @else if($weight==medium600) {
      font-family: 'interstate', #{$fallback_fonts};
      font-weight: 600!important;
    }
    @else if ($weight==condensed) {
      font-family: 'interstate-condensed', #{$fallback_fonts};
      font-weight: 500!important;
    }
    @else if ($weight==condensedbold) {
      font-family: 'interstate-condensed', #{$fallback_fonts};
      font-weight: 700!important;
    }
    @else if ($weight==black) {
      font-family: 'interstate-black', #{$fallback_fonts};
      font-weight: 700!important;
    }
    @else {
      font-family: 'interstate', #{$fallback_fonts};
      font-weight: 500!important;
    }
}