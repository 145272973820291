/* By the Number section*/
.mi-by-the-numbers-wrapper {

    @include general-breakpoint(smdesktop) {
        border-top: solid 1px $usoLtGray;
    }

    .mi-section-header {
        h4.mi-section-title {
            margin-top: 12px;
        }
        .mi-last-updated {
            display: none;
        }
    }
}

.mi-player-glance {
    .col {
        flex-basis: 50%;
    }
}

.mi-stats-wrapper {
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @include general-breakpoint(smtablet, max) {
        flex-wrap: wrap;
    }

    .mi-stats-label {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding-right: 20px;
        @include interstate();
        font-size: px2rem(12);
        padding-top: 15px;
        color: $gray-4a;
        order: 2;
        flex-basis: 100%;
        text-align: center;

        @include general-breakpoint(smtablet) {
            font-size: px2rem(18);
        }

        @include general-breakpoint(smtablet, max) {
            color: #171717;
            padding-right: 0;
        }
    }

    .chart-content {
        order: 1;
        text-align: center;

        &.circle {
            width: 118px;
            height: 118px;
            margin: auto;
            border: solid 8px $donut-fill-color;
            border-radius: 50%;
            background-color: $white;
            position: relative;
            top: 3px;

            &.team1 {
                border-color: $team1_color;
            }
            &.team2 {
                border-color: $team2_color;
            }
            .circle-labels {
                position: absolute;
                text-align: center;
                top: 30%;
                width: 100%;
                @include interstate();

                .value {
                    font-size: px2rem(44);
                    line-height: px2rem(28);
                }

                .unit {
                    display: block;
                    font-size: px2rem(24);
                    text-transform: uppercase;
                }
            }

            @include general-breakpoint(smtablet, max) {
                width: 90px;
                height: 90px;

                .circle-labels {
                    .value {
                        font-size: px2rem(34);
                        line-height: px2rem(22);
                    }
                    .unit {
                        display: block;
                        font-size: px2rem(14);
                        text-transform: uppercase;
                    }
                }
            }
        } // circle
    } // chart-content

    .ct-chart {
        position: relative;
        left: 0px;
        top: 3px;
        width: 100%;
    
        .chart-value {
            @include interstate();
            position: absolute;
            text-align: center;
            width: 100%;
            top: 22%;
            bottom: 25%;
            font-size: px2rem(44);
            color: $gray-4a;

            sup {
                font-size: px2rem(18);
                top: -14px;
                right: -1px;
                position: relative;
            }

            @include general-breakpoint(smtablet, max) {
                top: 20%;
                bottom: 25%;
                font-size: px2rem(34);

                sup {
                    font-size: px2rem(12);
                    top: -13px;
                    right: -1px;
                }
            }
        }

        .ct-chart-donut {
            overflow: visible;
        }
    }// .ct-chart

    /* chart overrides */
    .ct-fill-donut-label {
        height: 100%;
        left: 0px !important;
        right: 0px !important;
        top: 0px !important;
    }

    .team1 {
        .ct-chart-donut .ct-series-a .ct-slice-donut {
            stroke: $team1_color; 
        }

        // this is the background fill that makes it look like there are borders
        .ct-chart-donut .ct-fill-donut .ct-slice-donut {
            stroke: $donut-fill-color;
            opacity: 1;
            stroke-width: 14px!important;
        }
        .ct-chart-donut .ct-series-b .ct-slice-donut {
            stroke: $donut-fill-color;
            opacity: 1;
            stroke-width: 14px!important;
        }

        @include general-breakpoint(smtablet, max) {
            .ct-chart-donut .ct-fill-donut .ct-slice-donut {
                stroke-width: 10px!important;
            }
            .ct-chart-donut .ct-series-b .ct-slice-donut {
                stroke-width: 10px!important;
            }
        }                        
    }
    .team2 {
        .ct-chart-donut .ct-series-a .ct-slice-donut {
            stroke: $team2_color; 
        }

        // this is the background fill that makes it look like there are borders
        .ct-chart-donut .ct-fill-donut .ct-slice-donut {
            stroke: $donut-fill-color;
            opacity: 1;
            stroke-width: 14px!important;
        }
        .ct-chart-donut .ct-series-b .ct-slice-donut {
            stroke: $donut-fill-color;
            opacity: 1;
            stroke-width: 14px!important;
        }	

        @include general-breakpoint(smtablet, max) {
            .ct-chart-donut .ct-fill-donut .ct-slice-donut {
                stroke-width: 10px!important;
            }
            .ct-chart-donut .ct-series-b .ct-slice-donut {
                stroke-width: 10px!important;
            }	
        }
    }
} // mi-stats-wrapper

.slamtracker {
    .show-slamtracker {
        display: block;
    }
    .mi-by-the-numbers-wrapper {
        margin-left: 14px !important;
        margin-right: 14px !important;
        padding-top: 20px !important;
        padding-bottom: 0 !important;
        border-bottom: none;
        // border-top: none;

        .mi-section-title {
        //    display: none;
            order: 2;
            align-self: center;
            margin-bottom: 0;
        }
        .mi-last-updated {
            order: 1;
            margin-bottom: 15px;
            align-self: flex-end;
        }
        .mi-section-header {
            justify-content: flex-end;
            flex-direction: column;
        }
    }

    .mi-stats-wrapper {
        .mi-stats-label {
            font-size: px2rem(16);
        }
        .chart-content {
            margin: 0 auto;
            position: relative;

            &.circle {
                margin-left: auto;
                margin-right: auto;
            }
        }
        .circle {
            width: 99px;
            height: 99px;
            margin-bottom: 20px;

            @include general-breakpoint(smtablet) {
               .circle-labels {
                    .value {
                        font-size: px2rem(34);
                        line-height: px2rem(22);
                    }
                    .unit {
                        display: block;
                        font-size: px2rem(14);
                        text-transform: uppercase;
                    }
                }
            }
        }
        .ct-chart {
            margin: 0 auto;
            
            .chart-value {
                top: 18%;
                bottom: 25%;
                font-size: px2rem(40);
                color: $gray-4a;
    
                sup {
                    font-size: px2rem(14);
                    top: -16px;
                    right: -1px;
                }
            }
        }// .ct-chart
        .team1, .team2 {
            .ct-chart-donut .ct-fill-donut .ct-slice-donut {
                stroke-width: 10px!important;
            }
            .ct-chart-donut .ct-series-b .ct-slice-donut {
                stroke-width: 10px!important;
            }      
        }
    }
}