.wrapper,
.playerIndex {
	max-width: 1280px;
	margin: auto;
}

.news-mode,
.watch-mode,
.fan-week-mode {
	padding-top: 0px;
	.page-submenu {
		padding: 23px 2rem 22px 2rem;
		line-height: 1;
		.item {
			white-space: nowrap;
		}
		.item.selected {
			text-underline-offset: 22px;
		}
	}
}

.playerCountry {
	.players-dropdown-container {
		display: flex;
		justify-content: center;
		background-color: $usoBkgGray;
		width: 100%;
		padding: 8px;
	}
}
.playerIndex,
.playerCountry,
.playerSeeds,
.playerSearch {
	.content-title {
		display: none; //removed - added to subheader; add attribute to subheader_attributes: page_header - amb07162022
		color: $dk-gray2;
		margin: 20px 0 20px 15px;
		font-size: 24px;
		@include interstate(light);
		@include general-breakpoint(landscape) {
			margin: 20px 0 20px 48px;
		}
		@include general-breakpoint(smtablet) {
			font-size: 32px;
		}
		@include general-breakpoint(desktop) {
			margin: 20px 0 20px 69px;
		}
	}

	.favorite-star {
		display: inline-block;
		width: 30px;
		height: 15px;
	}
	.favorite,
	.favorite.favorited.outline {
		&:after,
		&:before {
			font-size: px2rem(18);
		}
	}
	.content-filters {
		background-color: $usoDkerBlue2;
	}
	.page-submenu-wrapper {
		.blue-bar-wrapper {
			background-color: $usoDkBlue;
			height: 65px;
		}
		.submenu-details-wrapper {
			display: none;
		}
		padding: 0;
		.page-submenu {
			width: 100%;
			justify-content: space-around;
			@include general-breakpoint(smdesktop, max) {
				justify-content: space-between;
				padding-left: 18px;
				padding-right: 18px;
			}
			.events-wrapper {
				//@at-root general-breakpoint(mobile) {}
				width: initial;
			}
			.results-select-menu {
				text-align: end;
				width: initial;
			}
		}
		.results-select-menu {
			.select-menu {
				.title {
					background-color: $usoDkBlue;
					color: $white;
				}
			}
		}
	}
}
.results-select-menu {
	select {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url('/assets/images/icons/dropdown-arrow.svg');
		background-repeat: no-repeat;
		background-position-x: 94%;
		background-position-y: 50%;
		background-color: $white;
	}
	.by-country-players-select-menu {
		width: 220px;
		height: 26px;
		font-size: 14px;
		text-align: center;
		border-radius: 3px;
		color: $usoLtBlue;
		border-color: $usoLtBlue;
		text-align-last: center;
	}
}

.mobileSearchContainer {
	display: flex;
	justify-content: center;
	.searchInputContainer {
		display: none;
		background: white;
		margin-right: 0;
		input.player-search {
			background: white;
		}
	}
}
.searchInputContainer {
	display: flex;
	align-items: center;
	border-radius: 20px;
	input.player-search {
		margin-right: 20px;
		background: #f3f3f3;
		border: none;
		border-radius: 4px;
		display: inline-block;
		height: 26px;
		font-size: 16px;
		padding: 0px 15px;
		@include general-breakpoint(tablet, max) {
			width: auto;
			padding: 0px 5px;
		}
	}

	// removes IE's x icon
	input.player-search::-ms-clear {
		display: none;
	}

	.searchIcon {
		display: inline-block;
		cursor: pointer;
		position: relative;
		color: $gray-dark;
		i {
			font-size: 32px;
			padding-left: 7px;
			color: $white;
		}
		.inline {
			display: inline-block;
		}
	}
}
.content-filters {
	background-color: $usoDkBlue;
	@include general-breakpoint(smtablet) {
		margin: initial;
		padding-top: 25px;
	}
	//margin: 0 25px;
	.select-menu-dropdown {
		overflow: hidden;
	} //hide scroll bars for players search
	.results-select-menu {
		//show scroll bars for countries
		.select-menu-dropdown {
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
	.centerMargins,
	.centerMargins-c {
		width: 100%;
		margin: 0px auto;
		display: flex;
		height: 100%;
		position: relative;
		align-items: center;
		@include general-breakpoint(smtablet, max) {
			flex-direction: column-reverse;
		}
	}
	.filterContainer,
	.searchContainer,
	.searchContainer-c {
		flex: 1 0 auto;
		@include interstate(regular);
		font-size: 12px;
		color: $md-gray;
	}
	.searchContainer,
	.searchContainer-c {
		display: flex;
		justify-content: flex-end;
		@include general-breakpoint(smtablet) {
			position: absolute;
			right: 10px;
			//padding-top: 5px;
			padding-bottom: 10px;
		}
		@include general-breakpoint(smtablet, max) {
			width: 100%;
			padding-right: 10px;
			padding-top: 10px;
			padding-bottom: 20px;
		}
		#atoznav {
			margin-right: 12px;
			padding: 0;
			.dropdown_nav.filter-dropdown {
				width: 100%;
				margin-bottom: 0;
				.menu-arrow {
					display: inline-block;
					font-size: 14px;
				}
			}
		}
	}
	.filterContainer {
		display: flex;
		justify-content: center;
		.playerFilterSelection {
			color: $white;
			cursor: pointer;
			padding-bottom: 15px;
			line-height: 1;
			width: 82px;
			text-align: center;
			font-size: 14px;
			&:not(:first-child) {
				margin-left: 40px;
			}
			&.selected {
				border-bottom: 4px solid $white;
				@include interstate(bold);
			}
			&:last-child {
				border-right: none;
				margin-right: 0;
				padding-right: 0;
			}
			&::after {
				content: attr(data-text);
				content: attr(data-text) / '';
				height: 0;
				display: block;
				visibility: hidden;
				overflow: hidden;
				user-select: none;
				pointer-events: none;
				font-weight: 700;
			}

			&:hover {
				color: #fff;
				font-weight: 700;
			}
		}
	}
	.separator {
		width: 2%;
		flex: 1 0 auto;
	}
	.filterList {
		background: rgba($white, 0.5);
		border: 0px;
		border-radius: 0px;
		color: $md-gray;
		@include interstate(regular);
		font-size: 16px;
		height: 26px; // for ff
		line-height: 32px;
		outline: 0;
		text-align: center;
		text-align-last: right;
		width: calc(100% - 20px);
		display: inline-block;
	}
}

.separator {
	display: inline-flex;
	border-left: 1px solid $md-gray2;
	margin: 0px 10px;
}

#playersList {
	.separator {
		margin: 0px 0px;
	}

	clear: both;
	padding: 0px 25px;
	display: block;
	@include general-breakpoint(landscape, max) {
		display: none;
	}
	#atoznav .text_nav a {
		color: $dk-gray2;
		@include interstate(light);
	}
	@include general-breakpoint(smtablet) {
		padding: 0 0;
	}
	#atoznav {
		.text_nav,
		.azAlphaInner {
			li {
				::after {
					content: attr(data-text);
					content: attr(data-text) / '';
					height: 0;
					display: block;
					visibility: hidden;
					overflow: hidden;
					user-select: none;
					pointer-events: none;
					font-weight: 700;
				}
				:hover {
					background-color: $white;
					color: $usoLtBlue;
					@include interstate(bold);
				}
				@include general-breakpoint(tablet) {
					margin: 0 3px;
				}
				@include general-breakpoint(smdesktop) {
					margin: 0 5px;
				}
				@include general-breakpoint(desktop) {
					margin: 0 10px;
				}
				a {
					font-size: 12px;
					padding: 0 14px 5px;
					@include general-breakpoint(smtablet) {
						padding: 0 4px;
						margin-right: 1px;
					}
					@include general-breakpoint(smdesktop) {
						padding: 0 7px;
						margin-right: 0;
					}
				}
			}
			.searchContainer-c {
				position: absolute;
				top: 10px;
				right: 10px;
				.icon-playerSearch {
					color: #4a4a4a;
				}
				.icon-close {
					right: 10px;
					visibility: hidden;
					position: relative;
					cursor: pointer;
				}
				&.showInput {
					display: flex;
					width: 100%;
					right: 0px;
					.searchInputContainer,
					input {
						width: 100%;
					}
					input {
						-webkit-animation: 1s blink step-end infinite;
						animation: 1s blink step-end infinite;
						&:focus {
							outline: none;
						}
					}
					.icon-close.show {
						visibility: visible;
					}
				}
			}
		}
		.dropdown_nav.filter-dropdown {
			width: 100%;
			margin-bottom: 0;
			@include general-breakpoint(smtablet) {
				display: none;
			}
			.select-menu {
				.title {
					.menu-arrow {
						display: none;
						font-size: 14px;
						&.after {
							display: none;
						}
					}
				}
			}
		}
	}
	.playerContainer {
		.letter-section {
			.player-section {
				.column {
					ul {
						li {
							a {
								color: $dk-gray2;
								&:hover {
									text-decoration: none;
									.playerName {
										color: $usoLtBlue;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.column-header {
		//width: 65%;
		//display: block;
		width: 100%;
		display: flex;
		margin: 0px auto;
		//padding: 15px 0px;
		border-top: 3px solid $bright-blue;
		//background-color: $lt-gray;
		.header-type-title {
			background-color: $lt-gray;
			width: 50%;
			color: $dk-gray2;
			font-size: 14;
			text-transform: uppercase;
			display: inline-block;
			padding: 20px;
			border-bottom: 1px solid #e0e2e3;
			border-top: 1px solid #e0e2e3;
			margin-left: 3px;
		}
		.letter-header {
			text-align: center;
			display: inline-flex;
			width: 40%;
			color: $dk-gray2;
			font-size: 32px;
			margin-bottom: 10px;
			margin-left: 10px;
			flex-direction: row;
			&:before,
			&:after {
				content: '';
				flex: 1 1;
				border-bottom: 1px solid $dk-gray2;
				margin: auto 8px;
			}
		}
	}
	.letter-section {
		@include general-breakpoint(smtablet) {
			margin: 0px 0px;
		}
		@include general-breakpoint(desktop) {
			max-width: 1280px;
			margin: 0px auto;
		}
		@include general-breakpoint(xldesktop) {
			max-width: 1280px;
			margin: 0px auto;
		}
	}
	.player-section {
		width: 100%;
		display: flex;
		position: relative;
		padding: 0px;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		.flag {
			display: inline-block;
			margin-right: 15px;
			img {
				width: 20px;
			}
		}
		.column {
			width: 50%;
			position: relative;
			flex: 1;
			flex-grow: 1;
			background-color: $white;
			margin-bottom: 1px;
			padding-bottom: 30px;
			.letter-header {
				padding-top: 10px;
				padding-left: 25px;
			}

			&.hideCol {
				background-color: $usoBkgGray;
				visibility: hidden;
			}
		}

		ul {
			list-style: none;
			margin: 0px;
			padding: 0px;
			li {
				line-height: 30px;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0px 10px;
				padding: 0px 10px;

				&.favorite {
					background-color: $favorite-background-color-light;
					border: solid 1px $md-gray;
				}
				&.highlightClass {
					background-color: $favorite-background-color-light;
					outline: solid 1px #e0e2e3;
				}
			}
		}
		// a {
		// 	color: $dk-gray2;
		// 	text-decoration: none;
		// 	@include interstate(light);
		// }
	}
}

#mobileList {
	display: block;
	background-color: #f0f2f3;
	@include general-breakpoint(smtablet) {
		display: none;
	}

	#atoznav {
		.searchContainer-c {
			.icon-playerSearch,
			.icon-close {
				color: #4a4a4a !important;
			}
			.icon-close {
				right: 10px;
				visibility: hidden;
				position: relative;
				cursor: pointer;
			}
			&.showInput {
				display: flex;
				width: 100%;
				right: 0px !important;
				.searchInputContainer,
				input {
					width: 100%;
				}
				.searchInputContainer {
					background-color: $usoBkgGray;
				}
				.searchIcon {
					margin-left: 10px;
				}
				input {
					-webkit-animation: 1s blink step-end infinite;
					animation: 1s blink step-end infinite;
					&:focus {
						outline: none;
					}
				}
				.icon-close.show {
					visibility: visible;
				}
			}
		}
		.dropdown_nav.filter-dropdown {
			display: flex;
			justify-content: center;
			.select-menu {
				.title {
					width: 60px;
					border: 1px solid $bright-blue;
					letter-spacing: 3px;
					.menu-arrow {
						display: none;
						font-size: 14px;
						&.after {
							display: none;
						}
					}
				}
				.select-menu-dropdown {
					border-radius: 10px;
				}
			}

			.searchContainer-c {
				position: absolute;
				right: 10px;
				.player-search {
					margin-right: -35px;
					@include general-breakpoint(landscape) {
						margin-right: 0px;
					}
				}
			}

			@include general-breakpoint(landscape) {
				//display: block;
			}
		}
	}
	.mobile-column-header {
		justify-content: center;
		background-color: $white;
		text-align: center;
		border-top: 3px solid $bright-blue;
		border-bottom: 1px solid $usoltMedGray;
		display: flex;

		.header-title {
			color: $usoDkGray;
			padding: 0px 5px 3px;
			cursor: pointer;
			margin-right: 40px;
			margin-top: 10px;
			&.show {
				color: $bright-blue;
				border-bottom: 2px solid $bright-blue;
			}
		}
	}

	.column-header {
		display: block;
		color: $md-gray;
		font-size: 14px;
		line-height: 24px;
		padding-left: 12px;
		&.tab {
			@include general-breakpoint(mobile) {
				display: flex;
				justify-content: center;
				background-color: $white;
				text-align: center;
				border-top: 3px solid $bright-blue;
				border-bottom: 1px solid $usoltMedGray;
				// .header-title{ color: $usoDkGray; padding: 0px 5px 3px; cursor: pointer; margin-right: 40px;
				//   &:hover{}
				//   &.show{ color: $bright-blue; border-bottom: 2px solid $bright-blue;}
			}
		}
		.header-title {
			color: $usoDkGray;
			padding: 0px 5px 3px;
			cursor: pointer;
			margin-right: 40px;
			&.show {
				color: $bright-blue;
				border-bottom: 2px solid $bright-blue;
			}
		}

		// .header-title{ display: inline-flex; padding: 0px 5px; cursor: pointer;
		//     //&:hover{ font-weight: bold;}
		//     &.show{ font-weight: bold; border-bottom: 1px solid $md-gray;}
		// }
	}
	.eventsEntered {
		width: 50%;
		text-align: left;
		@include general-breakpoint(smdesktop, max) {
			display: none;
		}
	}
	.tabPanel {
		display: flex;
		justify-content: center;
		background-color: $white;
		text-align: center;
		border-top: 3px solid $bright-blue;
		border-bottom: 1px solid $md-gray2;
	}

	.letter-section {
		margin: initial;
		background-color: $white;
		border-bottom: 1px solid $usoltMedGray;
		padding-bottom: 20px;

		.letter-header {
			display: block;
			width: 100%;
			color: $dk-gray2;
			font-size: 16px;
			padding: 15px 0;
		}
	}
	.player-section {
		.column {
			background-color: $white;
			margin-bottom: 1px;
			&.hideCol {
				background-color: $usoBkgGray;
			}
		}
		ul {
			list-style: none;
			margin: 0px;
			padding: 0px 0px;
			li {
				line-height: 24px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: flex;
				align-items: baseline;
				padding: 10px 10px;
				margin-right: 10px;
				&.favorite {
					background-color: $favorite-background-color-light;
					border: solid 1px $md-gray;
				}

				.flag,
				.playerName {
					display: inline-block;
					padding-right: 10px;
				}

				.flag img {
					height: 12px;
					margin-left: 10px;
					margin-right: 10px;
				}
			}
		}
		a {
			color: $dk-gray2;
			text-decoration: none;
			@include interstate(light);
		}
	}

	.mobileView {
		&.current {
			display: block;
			.highlightClass {
				background-color: $favorite-background-color-light;
				outline: solid 1px #e0e2e3;
			}
		}
		&.hide {
			display: none;
		}
	}
	.topPage {
		padding-top: 2px;
		text-align: right;
		width: 100%;
		a {
			text-decoration: none;
			color: $md-gray2;
			font-size: 12px;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.no-players {
		min-height: 200px;
		background-color: $usoBkgGray;
		text-align: center;
		display: block;
		text-align: center;
		line-height: 48px;
	}
}

.playerCountry,
.playerSeeds {
	.centerMargins {
		flex-direction: column;
		.centerMargins-c {
			display: flex;
			width: 100%;

			.searchContainer-c {
				position: absolute;
				right: 0;
				padding-bottom: 10px;
				padding-right: 10px;
			}
		}
	}
	.searchContainer {
		display: none !important;
	}
	.column-subtitle {
		width: 100%;
		@include interstate(light);
		font-size: 16px;
		color: $dk-gray2;
		text-align: center;
		background-color: $powerrank-bar-bg;
		padding: 15px;
		border-top: 3px solid $usoLtBlue;
		img {
			margin-right: 12px;
			width: 43px;
			height: 26px;
		}
		span {
			margin-right: 5px;
			&.numOfParticipants {
				font-size: 11px;
			}
		}
		//@include general-breakpoint(landscape){ margin: 20px auto; padding: 0px;}
	}

	@include general-breakpoint(landscape, max) {
		.content-filters {
			height: auto;
			padding-top: 30px;
			.centerMargins {
				flex-direction: column;
				.searchContainer,
				.filterContainer {
					justify-content: center;
					margin-right: 0;
				}
			}
		}
	}
}

.playerSeeds {
	.content-filters .searchContainer {
		display: flex;
		justify-content: flex-end;
		@include general-breakpoint(smtablet) {
			position: absolute;
			right: 10px;
			//padding-top: 5px;
			padding-bottom: 10px;
		}
		@include general-breakpoint(smtablet, max) {
			width: 100%;
			padding-right: 10px;
			padding-top: 10px;
			padding-bottom: 20px;
		}
	}
	.content-filters .filterContainer {
		flex: 1 0 auto;
		@include interstate(regular);
		font-size: 12px;
		color: $md-gray;
	}
}

.playerCountry {
	.searchContainer {
		display: none;
	}
	.content-filters .searchContainer {
		justify-content: center;
		position: relative;
		right: initial;
		width: 100%;
		padding: 8px;
		background-color: $usoltMedGray;
		.select-menu {
			max-width: 243px;
			.title {
				@include interstate(light);
				border: 1px solid $bright-blue;
				//letter-spacing: 3px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: px2rem(11);
				img {
					padding: 6px;
					width: 33px;
					margin-right: 6px;
				}

				.menu-arrow {
					font-size: 18px;
					&.after {
						display: none;
					}
				}
			}
		}
	}
	.content-filters .filterContainer {
		justify-content: center;
		width: 100;
	}
}

#countryList {
	width: 100%;
	margin: 20px auto;
	.playerContainer {
		.column-header {
			display: flex;
			font-size: 14px;
			.header-type-title {
				width: 50%;
				background-color: $lt-gray;
				padding: 20px;
				//margin-right: 1px;
				border-bottom: 1px solid $usoltMedGray;
				border-top: 1px solid $usoltMedGray;
			}
		}
		.player-section {
			#playerLink {
				@include general-breakpoint(smdesktop) {
					width: 50%;
				}
			}
		}
	}
	//@include general-breakpoint(landscape){ width: 75%; }
	.separator {
		margin: 0px 0px;
	}
	.player-section {
		//display: flex; width: 100%; flex-direction: row; flex-wrap: wrap; justify-content: space-between; background-color: $white;
		width: 100%;
		display: flex;
		position: relative;
		padding: 0px;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		.column {
			width: 50%;
			position: relative;
			flex: 1;
			flex-grow: 1;
			background-color: #fff;
			padding-top: 15px;
			ul {
				list-style: none;
				margin: 0px;
				padding: 0px;
				li {
					display: flex;
					line-height: 18px;
					overflow: hidden;
					text-overflow: ellipsis;
					padding: 20px 10px;
					margin: 0px 20px;
					border-bottom: 1px solid $usoltMedGray;
					align-items: baseline;
					@include general-breakpoint(smdesktop, max) {
						padding: 5px 15px;
						border-bottom: initial;
						margin: 0px 10px;
					}
					a {
						&:hover {
							text-decoration: none;
						}
						.playerName {
							width: 50%;
							&:hover {
								color: $usoLtBlue;
							}
							@include general-breakpoint(smdesktop, max) {
								width: 80%;
							}
						}
					}
					.flag,
					.playerName,
					.eventsEntered {
						padding: 0 8px;
					}
					img {
						width: 20px;
					}
					.eventsEntered {
						width: 50%;
						text-align: left;
						@include general-breakpoint(smdesktop, max) {
							display: none;
						}
					}
					&.highlightClass {
						background-color: $favorite-background-color-light;
						outline: solid 1px #e0e2e3;
					}
				}
				&.selected {
					margin: 10px 0;
					li {
						padding: 5px 15px;
						border-bottom: initial;
					}
				}
			}
			&.hideCol {
				background-color: $usoBkgGray;
			}
		}
		a {
			color: $dk-gray2;
			@include interstate(light);
		}
		.column-title {
			width: 100%;
			display: block;
			margin: 0px auto;
			padding: 15px 0px;
			text-align: center;
			color: $dk-gray2;
			font-size: 18px;
			@include interstate;
			text-transform: uppercase;
		}
		.column-info {
			width: 100%;
			border: 1px solid $md-gray2;
		}
		.player-row {
			display: table;
			width: 100%;
			padding: 10px;
			border-bottom: 1px solid $md-gray2;
			background-color: $white;
			&:nth-child(odd) {
				background-color: $lt-gray2;
			}
			&.header {
				background-color: $md-gray2;
			}
			// a {
			//     color: #007bff;
			// }
			a:hover {
				text-decoration: none;
			}
		}
		.player-name,
		.player-events {
			display: table-cell;
			width: 50%;
			@include interstate(light);
			font-size: 16px;
			&.title {
				@include interstate(regular);
				font-size: 16px;
				text-transform: uppercase;
				color: $dk-gray2;
			}
		}
	}
}

#seedsList {
	width: 100%;
	margin: 20px auto;
	.player-seed {
		display: flex;
		@include general-breakpoint(smtablet, max) {
			display: unset;
		}
		.separator {
			margin: 0 0;
			@include general-breakpoint(smtablet, max) {
				display: none;
			}
		}
		//.playerInfo{padding-top: 6px;}
		.mensContainer,
		.womensContainer,
		.column {
			&.hideCol {
				@include general-breakpoint(smtablet) {
					background-color: $usoBkgGray;
					visibility: hidden;
				}
			}
			background-color: $white;
			padding-top: 15px;
			width: 100%;
			@include general-breakpoint(smtablet) {
				display: flex;
				width: 50%;
			}
			.men,
			.women {
				width: 100%;
				height: 100%;
				background-color: unset;
				.player-box {
					//margin: 0 15px;
					//border-bottom: 1px solid #E0E2E3;
					border-right: unset;
					padding-bottom: 10px;
					//align-items: baseline;
					@include general-breakpoint(smdesktop, max) {
						//margin: 0 10px;
						padding: 5px 0;
						font-size: px2rem(9);
					}
					.number {
						height: 50px;
						margin-top: 5px;
						// width: 10%;
						// padding-left: 5px;
						width: 10px; // align seeded player photos
					}
					.playerPic {
						//@include general-breakpoint(desktop, max){  }
						margin-left: 6px;
						padding-top: 10px;
						img {
							width: 59.32px;
							height: 81px;
						}
					}
					#player-info {
						display: flex;
						width: 55%;
						@include general-breakpoint(desktop, max) {
							width: 50%;
						}
						//@include general-breakpoint(smdesktop, max){width: 60%;}
						@include general-breakpoint(smtablet, max) {
							margin-left: 10px;
						}
						.playerName {
							a {
								display: flex;
								flex-direction: column;
								&:hover {
									text-decoration: none;
									.firstName,
									.lastName {
										color: $usoLtBlue;
									}
								}
								.firstName {
									color: #4a4a4a;
									font-size: 1rem;
									text-align: left;
									white-space: nowrap;
									@include general-breakpoint(smdesktop, max) {
										font-size: 0.85rem;
									}
								}
								.lastName {
									color: #4a4a4a;
									font-size: 1.5rem;
									line-height: 1.5rem;
									@include general-breakpoint(mobile) {
										white-space: nowrap;
									}
									@include general-breakpoint(smdesktop, max) {
										font-size: 1rem;
									}
								}
							}
						}
						.withFavs {
							@include general-breakpoint(smdesktop) {
								margin-top: -3px;
							}
						}
						.countryName {
							white-space: nowrap;
						}
					}
					#oppnt-Info {
						display: flex;
						height: 70%;
						width: 60%;
						justify-content: space-around;
						@include general-breakpoint(smdesktop) {
							width: 50%;
						}
						@include general-breakpoint(smdesktop, max) {
							width: 100px;
							margin-left: 50px;
						}
						p {
							@include general-breakpoint(smdesktop, max) {
								width: unset;
								text-align: end;
							}
						}
						#result {
							width: 60%;
							// @include general-breakpoint(desktop, max){width: 60%;}
							// @include general-breakpoint(smdesktop, max){width: 60%;}
							@include general-breakpoint(smtablet, max) {
								width: 50%;
							}
							@include general-breakpoint(landscape, max) {
								width: 70%;
							}
							#outcome {
								width: 50px;
								text-align: right;
								white-space: nowrap;
							}
						}
						#round {
							width: 25%;
							@include general-breakpoint(desktop, max) {
							}
							@include general-breakpoint(smdesktop, max) {
								margin: 0 auto;
							}
							#roundNum {
								@include interstate();
								text-align: center;
								margin-left: 4px;
								@include general-breakpoint(desktop) {
									width: 50px;
									white-space: nowrap;
								}
								// @include general-breakpoint(smdesktop){ margin-left: 4px;}
							}
						}
						#round,
						#result {
							display: flex;
							//justify-content: flex-end;
							@include general-breakpoint(smdesktop, max) {
								justify-content: unset;
								margin: 0;
							}
							p {
								margin-right: 10px;
								@include general-breakpoint(smdesktop, max) {
									margin: 0;
									white-space: nowrap;
								}
							}
						}
						.playerName {
							margin-left: 5px;
							width: 60px;
							//@include general-breakpoint(smdesktop){width: 65px;}
							a {
								@include interstate();
								display: flex;
								flex-direction: column;
								&:hover {
									text-decoration: none;
									.firstName,
									.lastName {
										color: $usoLtBlue;
									}
								}
								.firstName {
									color: #4a4a4a;
									font-size: 1rem;
									text-align: left;
									white-space: nowrap;
									@include general-breakpoint(smdesktop, max) {
										font-size: 0.85rem;
									}
								}
								.lastName {
									color: #4a4a4a;
									font-size: 1.5rem;
									line-height: 1.5rem;
									@include general-breakpoint(smdesktop, max) {
										font-size: 1rem;
									}
								}
							}
							.opp-name {
								a {
									padding-top: 5px;
								}
							}
						}
					}
					&.highlightClass {
						background-color: $favorite-background-color-light;
						outline: solid 1px #e0e2e3;
					}
				}
				.player-box:last-child {
					border-bottom: none;
				}
			}
		}
	}
	.tableTitle {
		width: 100%;
		@include interstate(light);
		font-size: 16px;
		color: $dk-gray2;
		text-align: center;
		background-color: $powerrank-bar-bg;
		padding: 15px;
		border-top: 3px solid $usoLtBlue;
	}
	.column-header {
		display: flex;
		font-size: 14px;
		.header-type-title {
			width: 50%;
			background-color: #f0f2f3;
			padding: 20px;
			border-bottom: 1px solid #e0e2e3;
			border-top: 1px solid #e0e2e3;
		}
		.separator {
			margin: 0px 0px;
		}
	}
	.player-section {
		width: 100%;
		.player-title {
			display: block;
			width: 100%;
			margin: 0px auto;
			padding: 15px 0px;
			@include general-breakpoint(landscape) {
				width: 75%;
			}
		}

		.dataTable {
			width: 100%;
			display: table;
			border: 1px solid $md-gray2;
			.player-row {
				display: table;
				width: 100%;
				padding: 10px;
				border-bottom: 1px solid $md-gray2;
				background-color: $white;
				a {
					text-decoration: none;
					color: $link-color;
					&:hover {
						color: $link-hover-color;
					}
				}
				&.headers {
					background-color: $md-gray2 !important;
					color: $dk-gray2;
					font-size: 18px;
					@include interstate;
					text-transform: uppercase;
					padding: 10px;
					font-weight: bold;
				}
				&:nth-child(odd) {
					background-color: $lt-gray2;
				}
				.cell {
					display: table-cell;
					@include interstate(light);
					font-size: 16px;
					color: $dk-gray2;
				}
				.cell:nth-child(1) {
					width: 5%;
				}
				.cell:nth-child(2) {
					width: 30%;
					text-align: center;
				}
				.cell:nth-child(3) {
					width: 20%;
					text-align: center;
				}
				.cell:nth-child(4) {
					width: 30%;
					text-align: center;
				}
				.cell:nth-child(5) {
					width: 15%;
					text-align: right;
				}
			}
		}
	}
	.mobile-column-header {
		justify-content: center;
		background-color: $white;
		text-align: center;
		border-top: 3px solid $bright-blue;
		border-bottom: 1px solid $usoltMedGray;
		display: flex;

		.header-title {
			color: $usoDkGray;
			padding: 0px 5px 3px;
			cursor: pointer;
			margin-right: 40px;
			margin-top: 10px;
			&.show {
				color: $bright-blue;
				border-bottom: 2px solid $bright-blue;
			}
		}
	}

	.no-players {
		min-height: 200px;
		background-color: $usoBkgGray;
		text-align: center;
		display: block;
		text-align: center;
		line-height: 48px;
	}
}

.stub-content {
	display: block;
	padding: 20px;
	color: $md-gray;
	@include interstate(bold);
	font-size: 16px;
	text-align: center;
}

.searchResultsList {
	padding: 20px;
	background-color: white;
	.results {
		@include interstate(regular);
		font-size: 16px;
		color: $md-gray2;
		list-style-type: none;
		.flags {
			padding-right: 10px;
			vertical-align: middle;
		}
		li {
			padding: 15px 0px;
			a {
				text-decoration: none;
			}
		}
	}
}
