.insights-index {
	@extend .wrapper;
	background-color: #f3f3f3;
	.headtext {
		// font-family: IBMPlexSans;
		position: relative;
		background: white;
		border-bottom: 1px solid #c1c6c8;
		font-size: 36px;
		color: #4a4a4a;
		padding: 45px 0 0 45px;
		.info {
			position: absolute;
			font-size: initial;
			cursor: pointer;
			right: -25px;
			top: 0;
			@include general-breakpoint(tablet, max) {
				right: 15px;
				top: 45px;
			}
		}
	}
	.daynav-wrapper {
		background-color: $usoDkBlue;
		width: 100%;

		.daynav {
			border-top: 1px solid #979797;
			border-bottom: 1px solid #979797;
			.tournament-day-selector-wrapper {
				border-top: 1px solid #979797;
				border-bottom: 1px solid #979797;
			}
			&.scores-select-menu {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 46px;
			}
			// .select-menu-dropdown.mobile {
			// 	top: 0 !important;
			// 	max-height: unset !important;
			// }
			// .select-menu-dropdown.tablet {
			// color: $usoDkGray !important;
			// .option {
			// 	border: none !important;
			// 	}
			// }
			// }
		}
	}
	.insights-cards-container {
		padding-top: 20px;
	}

	.idx-header {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.filters {
			display: flex;
			right: 0;
			top: 10px;
			background-color: unset;
			.players-container {
				.searchInputContainer {
					background-color: white;
					.player-search {
						background-color: white;
					}
				}
			}
			@include general-breakpoint(tablet) {
				position: absolute;
				flex-direction: column;
				right: 28px;
				.search-box {
					width: 200px;
				}
				.players-container {
					margin-right: 0px;
					.searchInputContainer {
						background-color: white;
						.player-search {
							background-color: white;
						}
					}
				}
			}
			.players-container {
				margin-right: 0px;
				.searchInputContainer {
					background-color: white;
					.player-search {
						background-color: white;
					}
				}
			}
			@include general-breakpoint(tablet, max) {
				.search-box {
					width: 240px;
				}
			}
		}

		.idx-header-inner {
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-bottom: 25px;
			.spacerLine {
				width: 22px;
				border-top: 2px solid white;
			}
			.subtext {
				max-width: 390px;
				font-size: 16px;
				color: #888b8d;
				text-align: center;
				line-height: 20px;
				margin-top: 22px;
			}
		}
	}

	.insights-mode {
		.filters .searchInputContainer {
			justify-content: flex-end;
			margin-right: 20px;
		}
		.filters {
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
		.page-submenu {
			height: 49px;
			border-bottom: 1px solid $usoMdGray;
			gap: 0px;

			.item {
				&:not(:first-child) {
					padding-left: 40px;
				}
				&.selected {
					text-underline-offset: 22px;
				}
				&::after {
					content: attr(data-text);
					content: attr(data-text) / '';
					height: 0;
					display: block;
					visibility: hidden;
					overflow: hidden;
					user-select: none;
					pointer-events: none;
					font-weight: 700;
				}

				&:hover {
					color: #fff;
					font-weight: 700;
				}
			}
		}
	}

	.insights-cards {
		display: flex;
		justify-content: center;
		.no-matches,
		.no-matches-error {
			font-size: px2rem(16);
			color: #888b8d;
			text-align: center;
			min-height: 300px;
			text-transform: uppercase;
		}
		a {
			text-decoration: none;
		}
		.button-text-link {
			&.insights-index-btn {
				&:hover {
					text-decoration: none;
				}
			}
		}
		.match-card {
			margin-right: 7px;
			margin-left: 7px;
			margin-bottom: 16px;

			@include general-breakpoint(mobile, max) {
				margin-right: 0px;
				margin-left: 0px;
			}
			&.no-margin {
				margin-right: 0px;
				margin-left: 0px;
			}
			.generic-button_wrapper {
				margin-bottom: 0;
			}
			.card-cta {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 11px;
				color: $white;
				background: $usoLtBlueBG;
				letter-spacing: 0.61px;
				text-align: center;
				text-transform: uppercase;
				height: 25px;
				padding-left: 16px;
				padding-right: 16px;
				border-radius: 7px;
				margin-left: 15px;
				margin-right: 15px;

				.content {
					padding-top: 2px;
					color: $white;
					border-radius: 7px;
				}

				.btn-border {
					border-radius: 7px;
				}

				&:active,
				&:focus {
					border: 1px solid $usoLtBlueBG;
				}

				&:hover {
					background: $white;
					color: $usoLtBlueBG;
					
					.content {
						color: $usoLtBlueBG;
					}
				}
			}
			.card {
				background-color: white;
				width: 392px; //410px
				height: 320px;
				padding-top: 18px;
				@include general-breakpoint(tablet, max) {
					// width: 352px;
				}
				@include general-breakpoint(mobile, max) {
					width: 350px;
					margin-left: 15px;
				}
				&.no-margin {
					margin-right: 0px;
				}
				.card-head {
					text-align: center;
					margin-bottom: 17px;
					.courtName {
						// text-transform: uppercase;
						color: #616161;
						font-size: 12px;
					}
					.eventName {
						text-transform: uppercase;
						font-size: 14px;
						font-weight: bold;
						color: #4a4a4a;
						letter-spacing: 0.86px;
					}
				}
				.teams {
					display: flex;
					justify-content: space-between;
					margin-bottom: 7px;
					padding-left: 16px;
					padding-right: 16px;

					.team-wrapper {
						width: 25%;
					}

					.team-name {
						margin-top: 5px;

						.team1,
						.team2 {
							display: flex;
							justify-content: center;
							align-items: center;
							// width: 165px;
							// height: 38px;
							width: 90px;
							height: 25px;
							overflow: visible;
							white-space: nowrap;
						}
					}

					.playerName {
						// display: flex;
						// flex-direction: column;
						// align-items: center;
						// justify-content: center;
						font-size: 12px;
						color: #4a4a4a;
						letter-spacing: 0;
						position: relative;
						text-align: center;
						.seed {
							position: absolute;
							bottom: 0;
							margin-left: 3px;
							font-size: 10px;
						}
						.player-flag {
							img {
								width: 17px;
							}
							margin: 0 auto;
						}
					}
				}

				.team1 .player-img-container {
					.player-image {
						border-bottom: 7px solid rgb(65, 143, 222);
					}
				}

				.team2 .player-img-container {
					.player-image {
						border-bottom: 7px solid rgb(255, 212, 0);
					}
				}
			}
		}
	}
}

/** SlamTracker's special post match Fan predicted the winner! content **/
.mi-post-match-prediction-wrapper {
	display: flex;
	@include watsonBackgroundColor;
	border-radius: 7px;
	margin: 10px;

	.mi-post-match-prediction-content {
		padding: 10px;
		color: $white;
		@include interstate(regular);
		margin: auto;

		h3 {
			font-size: px2rem(16);
			text-transform: uppercase;
			text-align: center;
		}
	}

	.player-info {
		display: flex;

		h4 {
			font-size: px2rem(12);
			text-transform: uppercase;
		}

		.image-name-box {
			img.player-image {
				width: 70px;
				height: auto;

				@include general-breakpoint(smdesktop) {
					width: 60px;
				}

				@include general-breakpoint(desktop) {
					width: 70px;
				}
			}

			span {
				display: block;
				font-size: px2rem(11);
				text-align: center;

				@include general-breakpoint(smtablet) {
					font-size: px2rem(10);
				}

				@include general-breakpoint(smdesktop) {
					font-size: px2rem(11);
				}
			}
		}

		.vote-info {
			padding: 5px 10px;

			h4 {
				font-size: px2rem(12);
				text-align: center;

				@include general-breakpoint(smtablet) {
					margin-top: 20px;
				}

				@include general-breakpoint(smdesktop) {
					margin-top: unset;
				}
			}

			.numbers {
				display: flex;
				justify-content: space-between;

				span {
					font-size: px2rem(18);

					@include general-breakpoint(smtablet) {
						font-size: px2rem(12);
					}

					@include general-breakpoint(smdesktop) {
						font-size: px2rem(18);
					}
				}
			}
		}
	}
}
