@font-face {
    font-family: 'IBM Plex Sans Light';
    font-style: normal;
    // font-weight: 300;
    src: url("/assets/fonts/ibmplex/IBMPlexSans-Light.woff") format("woff");
  }
  @font-face {
    font-family: 'IBM Plex Sans Regular';
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/ibmplex/IBMPlexSans-Regular.woff") format("woff");
  }
  @font-face {
    font-family: 'IBM Plex Sans Semibold';
    font-style: normal;
    font-weight: 600;
    src: url("/assets/fonts/ibmplex/IBMPlexSans-SemiBold.woff") format("woff");
  }

  @mixin ibmplexsans( $weight: null ) {
    $fallback_fonts: 'Arial, Helvetica, sans-serif';
    $font: 'IBM Plex Sans';
    @if($weight == light) {
      font-family: $font + ' Light', #{$fallback_fonts};
      font-weight: 300;
    } @else if $weight == regular {
      font-family: $font + ' Regular', #{$fallback_fonts};
      font-weight: 400;
    }@else if $weight == semibold {
      font-family: $font + ' SemiBold', #{$fallback_fonts};
      font-weight: 600;
    } @else {
      font-family: $font + ' Light', #{$fallback_fonts};
      font-weight: normal;
    }
  }