.slamtracker-momentum {
    .momentum-header {
        display:flex;
        height:40px;
        flex-direction: row;
        justify-content: space-evenly;
        font-size:px2rem(12);
        // text-transform: uppercase;
        align-items: center;
        //padding:0px 17px;
        width: 100%;
        background-color: #FFFFFF;
        border-bottom:solid 2px #888B8D;
        // display:none;

        .team {
            width:35%;
            display:flex;
            flex-direction: column;
            color: $dk-gray2;
            @include interstate(bold);
            
            &.team1 {
                // color: $team1_color; 
                text-align: left;
            }
            &.team2 {
                // color: $team2_color;
                text-align: right;
            }
        }
        .timer {
            //width: 30%;
            display: flex;
            flex-direction: column;
            color: $black;
            @include interstate;
            text-align: center;
            margin-top: -10px;
            color: $black;

            .time {
                font-size: 14px;
                font-weight: 500;
            }
            .text {
                font-size: 9px;
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        .content-main.fixed & {
            position:fixed;
            top:168px;
            left:0px;
            z-index: 5;
            border-top:solid 2px #888B8D;
        }
    }

    @include breakpoint(smtablet) {
        display:flex;
    }
}