.blue-bar-menu-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #001d67;
	font-size: 14px;
	padding: 23px 2rem 22px 2rem;
	white-space: nowrap;
	a {
		&:hover {
			text-decoration: none;
		}
	}
}

.blue-bar-menu-event {
	list-style-type: none;
	color: $white;
	cursor: pointer;
	padding: 0 1rem;

	&::after {
		content: attr(data-text);
		content: attr(data-text) / '';
		height: 0;
		display: block;
		visibility: hidden;
		overflow: hidden;
		user-select: none;
		font-weight: 700;
	}

	&:hover {
		text-decoration: none;
		color: $white;
		font-weight: 700;
	}

	&.active {
		text-decoration: underline;
		text-underline-offset: 24px;
		text-decoration-thickness: 3px;
		font-weight: 700;
		color: $white;
	}
	@media only screen and (max-width: 425px) {
		font-size: 12px;
	}
}
.watch-blue-bar-wrapper {
	@include interstate();
	display: flex;
	height: 60px;
	align-items: center;
	justify-content: space-around;

	select {
		// dropdown arrow
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url('/assets/images/icons/dropdown-arrow-ltgray.svg');
		background-repeat: no-repeat;
		background-position-x: 94%;
		background-position-y: 50%;
		background-color: $usoDkBlue;

		// select styling
		@include interstate();
		text-indent: 10px;
		width: 140px;
		height: 25px;
		background-color: $usoDkBlue;
		color: $white;
		border-radius: 4px;
		border: 1px solid $white;
	}
}
