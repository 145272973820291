.highlight-wrapper {
    width:90%;
    margin:0px auto;
    padding-bottom:56.25%;
    position: relative;
    &.playing {
        .icon-play-button {
            display:none;
        }
    }
    .icon-play-button {
        display:block;
        color:$white;
        position: absolute;
        left: 0px;
        right:0px;
        margin:0px auto;
        top:-50%;
        margin-top: 50%;
        font-size:50px;
        width:50px;
        height:50px;
        cursor: pointer;
    }
    img {
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        cursor: pointer;
    }
}