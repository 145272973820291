.photosPage {
	max-width: unset;
	/**
    * Gallery view
    */
	.galleryContainer {
		position: fixed;
		height: 100vh;
		width: 100vw;
		// z-index: 10000000;
		z-index: 2147483642;
		top: 0;
		left: 0;
	}

	.filters {
		position: relative;
		padding-bottom: 0;
		// padding-top: 5px;
		padding: 14px 0;
		.selection {
			margin-left: 0;
		}
		.gridList {
			position: absolute;
			right: 12px;
			top: 18px;
			display: flex;
			align-items: center;
			cursor: pointer;
			.gridListLabel {
				color: #4a4a4a;
				@include interstate(regular);
				font-size: 1.3em;
				margin-right: 5px;
			}
		}
		.filterButton {
			position: relative;
			background: white;
			padding: 5px 20px 0 20px;
			z-index: 999;
			&.show {
				box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
				border-bottom: none;
				&:after {
					content: ' ';
					width: 100%;
					height: 10px;
					background-color: white;
					position: absolute;
					bottom: -7px;
					left: 0;
				}
			}
			.ar-icon {
				margin-right: 5px;
			}
		}
		.filterBox {
			position: absolute;
			box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
			min-width: 300px;
			left: 50%;
			top: calc(100% + 2px);
			transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			z-index: 995;
			background: white;
			padding: 15px;
			white-space: nowrap;
			.yearFilter {
				@include interstate(regular);
				font-size: 1.3rem;
				line-height: 2.2rem;
				vertical-align: top;

				margin-right: 17px;
				padding-right: 17px;
				border-right: 1px solid $md-gray5;
				.yearSelect {
					display: block;
					input {
						margin-right: 5px;
					}
				}
			}
			.filterPlayersContainer {
				display: inline-block;
				.playerSearch {
					border-top: none;
					border-right: none;
					border-left: none;
					min-width: 200px;
				}
				.filterPlayers {
					list-style: none;
					text-align: left;
					padding: 0;
					.playerOption {
						input {
							margin-right: 5px;
						}
					}
				}
			}
		}
	}

	// .content-main{
	//     .newsChips{
	//         .mip-ad-container.photo-page-ads{
	//             background: transparent;
	//             width: 100%;
	//             margin: 17px 0;
	//         }
	//         .chip_content{
	//             .newsLoad{
	//                 border: none;
	//             }
	//         }
	//     }
	//     &.grid{
	//         .newsChips{
	//             .chip{
	//                 height: initial;
	//                 .date{
	//                     display: none;
	//                 }
	//                 .caption{
	//                     display: none;
	//                     width: 100%;
	//                     position: absolute;
	//                     bottom: 0;
	//                     text-align: center;
	//                     background: rgba(0, 0, 0, 0.75);
	//                     color: #ffffff;
	//                     font-weight: bold;
	//                     margin-bottom: 0;
	//                     font-size: 1em;
	//                     line-height: 1.2em;
	//                     padding: 10px 20px;
	//                     max-height: 50%;
	//                     &.visible{
	//                         display: block;
	//                     }
	//                 }
	//             }
	//         }
	//     }
	//     &.list{
	//         .newsChips{
	//             max-width: 1024px;
	//             .chip{
	//                 width: calc(100% - 40px);
	//                 height: auto;
	//                 .date{
	//                     display: none;
	//                 }
	//                 .caption{
	//                     font-size: 1rem;
	//                     text-align: center;
	//                 }
	//             }
	//         }
	//     }
	// }
}
