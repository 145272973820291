// Past champs select container and form
.champs-sub-header {
    @include make-col-ready();
    // @include media-breakpoint-up(sm) {
    //   @include make-col-offset(1);
    //   @include make-col(10);
    // }
    margin: 10px 0;
}
/*** Select Menu ***/
.champs-select-menu {
    // background-color: $lt-gray;
    // border-bottom: solid 1px $md-gray2;
    padding: 12px 0;
    overflow: hidden;

    .champs-select-menu {
        button.title {
            text-align: center;
        }

        @include general-breakpoint(mobile) {
            margin-top: 30px;
        }

        @include general-breakpoint(tablet) {
            margin-top: 0px;
        }
    }

    .champs-select-menu-dropdown {
        min-width: 90px;
        background: url(/assets/images/nav/carrot-down.png) no-repeat 95% 50%;
		background-color: $white;
		color: $bright_blue !important;
        border: 1px solid $bright-blue;
        padding: 5px 20px 5px 10px;
        @include interstate;
        // font-weight: 400;
        font-size: 1.4rem;
        max-height: 400px;
        overflow: scroll;
        margin-left: 50%;
        transform: translateX(-50%);
        text-align: center;
        text-align-last: center;
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none;

		border-radius: 4px;
    }
}
