$sizes: (
    "m": (margin),
    "mt": (margin-top),
    "mr": (margin-right),
    "mb": (margin-bottom),
    "ml": (margin-left),
    "mx": (margin-left, margin-right),
    "my": (margin-top, margin-bottom),
    "p": (padding),
    "pt": (padding-top),
    "pr": (padding-right),
    "pb": (padding-bottom),
    "pl": (padding-left),
    "px": (padding-left, padding-right),
    "py": (padding-top, padding-bottom)
);

@mixin size-factory($thisPrefix: null) {
    @each $prop-name, $props in $sizes {
        @each $size in $sizing {
            @if ($thisPrefix) {
                .#{$prop-name}-#{$thisPrefix}-#{$size} {
                    @each $prop in $props {
                        #{$prop}: px2rem($size) !important;
                    }
                }
                .#{$prop-name}-#{$thisPrefix}--#{$size} {
                    @each $prop in $props {
                        #{$prop}: px2rem(-($size)) !important;
                    }
                }
            } @else {
                .#{$prop-name}-#{$size} {
                    @each $prop in $props {
                        #{$prop}: px2rem($size) !important;
                    }
                }
                .#{$prop-name}--#{$size} {
                    @each $prop in $props {
                        #{$prop}: px2rem(-($size)) !important;
                    }
                }
            }
        }
    }
}

@each $prefix in $breakpoint-all-prefixes {
    @include general-breakpoint($prefix) {
        @include size-factory($prefix);
    }
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}

@each $prefix in $breakpoint-all-prefixes {
    @include general-breakpoint($prefix) {
        .mx-#{$prefix}-auto {
            margin-left: auto;
            margin-right: auto;
        }
        .ml-#{$prefix}-auto {
            margin-left: auto;
        }
        .mr-#{$prefix}-auto {
            margin-right: auto;
        }
    }
}


@include size-factory();
