$left_padding: 28px;
$left_padding_mobile: 16px;

.openquestions_modal{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, 0.7);
    .openquestions_modal_content{
        width: 644px;
        min-height: 470px;
        @include draws_breakpoint(medium, max){
            width: 350px;
            min-height: 552px;
            padding: 25px 25px 65px 25px;
        }
        @include draws_breakpoint(medium){
            padding: 25px 50px 65px 25px;
        }
        background: white;
        border: 1px solid #E0E0E0;
        
        position: relative;
        .modal_header{
            @include interstate(regular);
            font-size: 24px;
            color: #262626;
            line-height: 30px;
            max-width: 180px;
        }
        .modal_text{
            color: #262626;
            line-height: 24px;
            margin-top: 25px;
            @include draws_breakpoint(medium, max){
                font-size: 16px;
            }
            @include draws_breakpoint(medium){
                font-size: 18px;
            }
        }
        .open_question_submit{
            position: absolute;
            bottom: 0;
            background: #418FDE;
            @include interstate(regular);
            font-size: px2rem(14);
            color: #FFFFFF;
            text-align: center;
            border: none;
            width: 112px;
            height: 32px;
            margin-bottom: 20px;
            text-transform: uppercase;
            cursor:pointer;
        }
    }
    
}

.openquestions{
    // .shown{
    //     display: block;
    // }
    .hidden{
        display: none;
    }
    .open_questions_watson_loader{
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        background: white;
        .open_questions_watson_loader_inner{
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100vh - 175px);
            width: 100%;
        }
    }
    .end_message{
        padding: 10px 0;
        font-size: px2rem(18);
        color: #888B8D;
        display: flex;
        @include draws_breakpoint(large){
            margin: 0 10px 0 $left_padding;
        }
        @include draws_breakpoint(large, max){
            margin: 0 10px 0 $left_padding_mobile;
        }
    }
    .open_questions_header{
        padding: 10px 0;
        font-size: px2rem(24);
        color: #171717;
        display: flex;
        align-items: center;
        img{
            height: 43px;
            @include draws_breakpoint(large){
                margin: 0 10px 0 $left_padding;
            }
            @include draws_breakpoint(large, max){
                margin: 0 10px 0 $left_padding_mobile;
            }
            display: inline-block;
            vertical-align: middle;
        }
        .header_text{
            flex: 1;
            @include draws_breakpoint(medium, max){
                display: inline-flex;
                flex-direction: column;
                .first{
                    font-size: px2rem(24);
                    line-height: 28px;
                }
                .second{
                    font-size: px2rem(18);
                    line-height: 18px;
                }
            }
        }
        .info{
            line-height: 24px;
            @include draws_breakpoint(medium){
                padding-right: 55px;
            }
            @include draws_breakpoint(medium, max){
                padding-right: 15px;
            }
            cursor: pointer;
            .icon-info-icon{
                font-size: 24px;
                color: #418FDE;
            }
        }
    }
    .go_back{
        a:hover{
            text-decoration: none;
            color: #418FDE;
        }
        @include interstate(regular);
        font-size: px2rem(18);
        color: #418FDE;
        display: flex;
        align-items: center;
        background-color: $lt-gray;
        padding: 10px 0;
        .icon-arrow-left{
            @include draws_breakpoint(large){
                margin: 0 12px 0 $left_padding;
            }
            @include draws_breakpoint(large, max){
                margin: 0 12px 0 $left_padding_mobile;
            }
        }
    }
    .video_container{
        // width: 100%;
        // min-height: 430px;
        // background-color: $lt-gray;
        // border: 1px solid black;
        width: 100%;
        overflow: hidden;
        position: relative;
        min-height: 500px;
        padding-bottom: 0;
    }
    .question_title{
        @include interstate(regular);
        font-size: px2rem(24);
        line-height: px2rem(24);
        color: $gray_4a;
        @include draws_breakpoint(large){
            padding: 20px 0 20px $left_padding;
        }
        @include draws_breakpoint(large, max){
            padding: 20px 0 20px $left_padding_mobile;
        }
        border-bottom: 1px solid #C1C6C8;
        .question_ended{
            font-size: px2rem(18);
            color: #888B8D;
            display: block;
            margin-top: 10px;
        }
    }
    .question_content{
        .question_results{
            // position: absolute;
            // width: 100%;
            // height: 100%;
            background: white;
            // z-index: 99;
            position: relative;
            .closeX{
                position: absolute;
                right: 20px;
                top: 10px;
                button{
                    border: none;
                    padding: 0;
                    background: transparent;
                    cursor: pointer;
                }
            }
            .results_title{
                padding-top: 10px;
                @include interstate(regular);
                @include draws_breakpoint(medium){
                    font-size: px2rem(24);
                }
                @include draws_breakpoint(medium, max){
                    font-size: px2rem(20);
                }
                @include draws_breakpoint(small, max){
                    font-size: px2rem(18);
                }
                color: #171717;
                img{
                    height: 43px;
                    @include draws_breakpoint(large){
                        margin: 0 10px 0 $left_padding;
                    }
                    @include draws_breakpoint(large, max){
                        margin: 0 10px 0 $left_padding_mobile;
                    }
                }
            }
            .results_percent{
                .progress_bar{
                    position: relative;
                    margin: 4px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }
                .bar_overflow{ /* Wraps the rotating .bar */
                    position: relative;
                    overflow: hidden; /* Comment this line to understand the trick */
                    width: 290px; height: 145px; /* Half circle (overflow) */
                    margin-bottom: -14px; /* bring the numbers up */
                    .progress_bar_labels{
                        opacity: 0;
                        transition: opacity .75s;
                        transition-delay: .75s;
                        &.shown{
                            opacity: 1;
                        }
                        position: absolute;
                        display: flex;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%);
                        // .percent_agree{
                        //     margin-right: 25px;
                        //     padding-right: 25px;
                        //     border-right: 1px solid #D8D8D8;
                        // }
                        .vl{
                            border-left: 1px solid #D8D8D8;
                            margin: 0 25px;
                        }
                        .percent_agree, .percent_disagree{
                            display: flex;
                            flex-direction: column;
                            text-align: left;
                            width: 60px;
                            .percent_value{
                                @include interstate(light);
                                font-size: px2rem(35);
                                line-height: px2rem(35);
                                color: #161616;
                                white-space: nowrap;
                                &:after{
                                    content: '%';
                                    font-size: px2rem(17);
                                    line-height: px2rem(17);
                                    vertical-align: top;
                                    margin-top: 5px;
                                    display: inline-block;
                                }
                            }
                            .percent_label{
                                @include interstate(light);
                                font-size: px2rem(14);
                                line-height: px2rem(14);
                                color: #161616;
                            }
                        }
                    }
                }
                .bar{
                    transition: transform .75s cubic-bezier(.25,.75,.5,1);
                    position: absolute;
                    top: 0; left: 0;
                    width: 290px; height: 290px; /* full circle! */
                    border-radius: 50%;
                    box-sizing: border-box;
                    background: white;
                    background-clip: padding-box;
                    // border: 5px;     /* half gray, */
                    // border-bottom-color: #0bf;  /* half azure */
                    // border-right-color: rgb(108, 18, 168);
                    background: linear-gradient(to right, #0F62FE, #A56EFF);
                    &:before{
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 1;
                        border-bottom: 0;
                        border: 8px solid #C1C6C8;
                        margin: 0;
                        border-bottom-left-radius: 0;
                        border-bottom: 0;
                        border-top-right-radius: 145px;
                        border-top-left-radius: 145px;
                        width: 290px;
                        height: 145px;
                    }
                    &:after{
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 2;
                        border-radius: inherit;
                        background: white;
                        margin: 8px;
                    }
                }
            }
            .results_summary{
                font-size: px2rem(18);
                color: #333333;
                @include draws_breakpoint(large){
                    padding: 55px 150px 0 $left_padding;
                }
                @include draws_breakpoint(large, max){
                    padding: 55px 15px 0 $left_padding_mobile;
                }
                // max-width: 970px;
                .sourcelist{
                    font-style: italic;
                }
            }
        }

        .agree_disagree{
            display: flex;
            align-items: center;
            margin: 15px;
            // @include draws_breakpoint(large){
            //     margin: 15px 15px 15px $left_padding;
            // }
            // @include draws_breakpoint(large, max){
            //     margin: 15px 15px 15px $left_padding_mobile;
            // }
            // @include draws_breakpoint(medium, max){
                justify-content: center;
            // }
            .agree{
                border-right: 1px solid #C1C6C8;
                margin-right: 20px;
                padding-right: 20px;
            }
            .agree, .disagree{
                // width: 172px;                
                // border: 1px solid $gray_4a;
                text-align: center;
                button{
                    @include interstate(regular);
                    width: 100%;
                    height: 100%;
                    padding: 5px 0;
                    background: transparent;
                    border: none;
                    font-size: px2rem(18);
                    line-height: px2rem(18);
                    // color: $gray_4a;
                    color: #418FDE;
                    cursor: pointer;
                }
                &.selected{
                    button{
                        // color: white;
                        @include interstate(bold);
                        text-decoration: underline;
                        font-weight: bold;
                    }
                    // background-color: $gray_4a;
                }
            }
        }
        .open_question_carousel{
            .carousel-root{
                .carousel-slider{
                    .slide{
                        // @include draws_breakpoint(medium){
                        //     min-height: 425px;
                        // }
                        // @include draws_breakpoint(medium, max){
                        min-height: 210px;
                        // }
                        background: transparent;
                        @include interstate(regular);
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        .slide-item{
                            text-align: left;
                            @include draws_breakpoint(medium){
                                padding: 55px $left_padding_mobile 60px $left_padding_mobile;
                            }
                            @include draws_breakpoint(medium, max){
                                padding: 55px $left_padding_mobile 40px $left_padding_mobile;
                            }
                            max-width: 850px;
                            @include draws_breakpoint(medium, max){
                                max-width: 480px;
                            }
                            .carousel_source{
                                &.nolink{
                                    color: #4a4a4a;
                                }
                            }
                            .carousel_text{
                                display: block;
                                font-style: italic;
                                margin-bottom: 10px;
                                .bigquote{
                                    font-size: 26px;
                                    font-weight: bold;
                                    position: absolute;
                                    margin-left: -12px;
                                    transform: rotate(180deg);
                                    margin-top: -17px;
                                }
                            }
                        }
                    }
                    .control-dots{
                        padding: 0;
                        .dot{
                            box-shadow: none;
                            background-color: #C1C6C8;
                            &.selected{
                                background-color: white;
                                border: 1px solid #979797;
                            }
                        }
                    }
                }
            }
        }
        .open_question_submit_form{
            background-color: #F3F3F3;
            .open_question_prompt{
                @include interstate(regular);
                font-size: px2rem(20);
                color: $gray_4a;
                @include draws_breakpoint(large){
                    padding: 15px 15px 15px $left_padding;
                }
                @include draws_breakpoint(large, max){
                    padding: 15px 15px 15px $left_padding_mobile;
                }
            }
            .open_question_form{
                display: flex;
                align-items: center;
                flex-direction: column;
                .form_area{
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    .errorMessage{
                        color: #C8102E;
                    }
                }
                .response{
                    &.error{
                        border: 1px solid #C8102E;
                    }
                    background: #FFFFFF;
                    border: 1px solid #C1C6C8;
                    font-size: px2rem(18);
                    color: $gray_4a;
                    @include draws_breakpoint(xlarge){
                        width: 1142px;
                    }
                    @include draws_breakpoint(xlarge, max){
                        width: 85%;
                    }
                    height: 254px;
                    margin-bottom: 60px;
                }
                .terms_of_use{
                    max-width: 230px;
                    text-align: center;
                    margin-bottom: 15px;
                }
                .open_question_submit{
                    background: #418FDE;
                    @include interstate(regular);
                    font-size: px2rem(14);
                    color: #FFFFFF;
                    text-align: center;
                    border: none;
                    width: 112px;
                    height: 32px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    text-transform: uppercase;
                }
            }
        }
    }
    .question_chips{
        @include draws_breakpoint(large, max){
            padding: 15px 38px;
        }
        @include draws_breakpoint(medium, max){
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        @include draws_breakpoint(large){
            padding: 15px 55px;
        }
        .debate_chip_wrapper{
            @include draws_breakpoint(large, max){
                width: 320px;
                height: 318px;
            }
            @include draws_breakpoint(medium, max){
                width: 320px;
                height: 318px;
            }
            @include draws_breakpoint(large){
                width: 375px;
                height: 345px;
            }
            border: 1px solid $lt-gray;
            margin: 10px;
            display: inline-block;
            vertical-align: top;
            a{
                display: block;
                color: gray;
                &:hover{
                    text-decoration: none;
                    color: gray;
                }
                .debate_chip{
                    .video_img{
                        min-height: 100px;
                        position: relative;
                        img{
                            width: 100%;
                        }
                        .playBtn{
                            position: absolute;
                            background: url(/assets/images/misc/video_play_icon.png) no-repeat 0 0;
                            top: 50%;
                            left: 50%;
                            margin-top: -50px;
                            margin-left: -50px;
                            width: 75px;
                            height: 75px;
                            background-size: 75px;
                            z-index: 1;
                        }
                    }
                    .caption {
                        padding: 20px 17px 10px 17px;
                        min-height:px2rem(110);
                        @include interstate(regular);
                        font-size: px2rem(20);
                        color: $gray_4a;
                        // .date {
                        //     @include bentonsans(medium); font-size: px2rem(9); color:$gray_c; line-height: 32px;
                        //     @include breakpoint(medium) {font-size: px2rem(10)};
                        //     @include bgeneral-breakpoint(desktop) {font-size: px2rem(12)};
                        // }
                        .title {
                            // @include tiempos(medium); font-size: (16/16)*1em; width:100%; height:auto; @include multitruncate($font-size:(16/16), $line-height:(22/16), $lines-to-show:2);
                            @include general-breakpoint(desktop) {font-size: calc((18/16) * 1em); @include multitruncate($font-size:calc(18/16), $line-height:calc(26/18), $lines-to-show:2)};
                            @include general-breakpoint(lgdesktop) {font-size: calc((21/16) * 1em); @include multitruncate($font-size:calc(21/16), $line-height:calc(28/21), $lines-to-show:2)};
                        }
                    }
                }
            }
        }
    }
    
    .loading_results{
        &.shown{
            display: flex;
        }
        justify-content: center;
        align-items: center;
        height: 600px;
        flex-direction: column;
        .spinner_label{
            @include interstate(regular);
            font-size: 18px;
            color: #545454;
            text-align: center;
        }
    }
}

.open-questions.terms{
    display: flex; 
    justify-content: center;
    .term-content{
        max-width: 860px;
        padding: 0 20px;
    }
}

.watson_animated{
    width: 100px;
    height: 125px;
    transform: scale(1);
    transform-origin: top left;
    overflow: hidden;
    background-repeat: no-repeat;
    background-image: url("/assets/images/misc/watson_animated.jpg");
    animation: play 10s steps(215) infinite;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@keyframes play{
    0% {background-position: left;}
    100% {background-position: right;}
}