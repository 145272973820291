.heroSection{
    &.video{
        @include general-breakpoint(landscape, max){
            .inlinePlayer{
                position: relative;
            }
        }
        
            .heroInfo{
                h2{
                    color: white;
                    @include general-breakpoint(landscape, max){
                        color: #4a4a4a !important;
                    }
                }
            }
            .regionWarning {
                color: $white;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                .regionMessage {
                    background-color: rgba(0, 0, 0, .75);
                    padding: 20px;
                    text-align: center;
                }
            }
        .heroVideo.bottomPadding{   
            padding-bottom: 20px;     
        }
    }
}

.filters {
    color: "#888B8D";
    text-align: center;
    padding: 12px 0;
    .selection {
        margin-left: 20px;
        margin-bottom: 5px;
        font-family: "interstate", Helvetica, Arial, sans-serif;
        font-weight: 500;
        font-size: 1.3rem;
        display: inline-block;
        line-height: 2.2rem;
        cursor: pointer;
    }
    .videoDD{
        //margin-left: 10px;
        margin-bottom: 5px;
    }
    .video-dropdown{
        border: 1px solid #979797;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.23);
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 9px;
        padding-right: 25px;
        background-image: url("/assets/images/nav/carrot-down.png") !important;
    }
    .filterDD, .videoDD, .filter-dropdown, .video-dropdown {
        font-size: 1.3rem;
        line-height: 2.2rem;
        display: inline-block;
        vertical-align: top;
        height: inherit;
        float: none;
        width: auto;
        
        select {
            @include interstate(bold);
            background: transparent;
            width: auto;
            padding: 5px;
            font-size: 14px;
            line-height: 16px;
            border: 0;
            border-radius: 0;
            height: 45px;
            -webkit-appearance: none;
            -moz-appearance: none;
            color: #FFFFFF;
            outline: none;
            padding-left: 10px;
            color: $bright_blue !important;
            padding-top: 0;
            padding-bottom: 0;
            font-size: 1.3rem;
            line-height: 2.2rem;
            height: inherit;
            cursor: pointer;
        }

        &.dark {
            background-color: $usoDkBlue;
            background-image: none !important;
            border-color: $usoLtGray;
            color: $white;
            position: relative;

            &:after{
                width: 0; 
                height: 0; 
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid $white;
                position: absolute;
                top: 40%;
                right: 5px;
                content: "";
                z-index: 98;
               }

            select {
                color: $white !important;
            }

        }
    }
}

/** override to match - News dropdown */
.videolanding {
    .filters {
        @extend .third; // use .third styles from news_filter.scss

        .video-dropdown {
            border: 1px solid $bright-blue;
        }
    }
}

#vloadMore {
    padding-top: 30px;
    padding-bottom: 30px;
    .label {
        margin: 0px auto;
        width: 128px;
        height: 40px;
        font-size: 1.2em;
        line-height: 40px;
        background: #418FDE;
        color: #FFFFFF;
        font-family: Helvetica,Arial,sans-serif;
        text-transform: uppercase;
        text-align: center;
    }
}

.pip-window.small{
    .overlayNextUp .nextup .next_banner{
        .next-content{
            display: none;
        }
    }
}

.pip-window .overlayNextUp{
    outline: 5px solid red;
    .nextup .next_banner{
        .photo{
            display: flex;
            height: 92px;
            align-items: center;
            img{
                position: relative;
                width: 90px !important;
            }
        }
        .next{
            width: 45px;
        }
        .countdown{
            width:50px;
            .label{
                white-space: nowrap;
                transform: translateX(-20px);
            }
            .count{
                font-size: 35px;
                margin-top: -10px;
            }
            .allow{
                white-space: normal;
                transform: translateX(-40px);
            }
        }
        .next-content{
            padding: 0;
            margin-left: 90px;
            width: 145px;
            .prompt{
                font-size: 15px;
                text-align: left;
            }
            .title{
                font-size: 13px;
                white-space: normal;
                text-align: left;
                height: 60px;

                
            }
        }
    }   
}

.overlayNextUp {
    // position: relative;
    width: 100%;
    max-width: 1280px;
    // margin: auto;
    .nextup {
        // height: 58px;
        height: 102px;
        width: calc(100% - 4px);
        bottom: 1px;
        margin-left: 2px;
        position: absolute;
        background-color: #ffffff;
        z-index: 20;
        display: none;
        cursor: pointer;
        border-top: 2px solid #ffffff;
        z-index: 100000;
        .photo {
            flex: 0 0 175px;
            margin: 2px 20px 2px 2px;
            float: left;
            display: block;
            @include general-breakpoint(landscape, max){
                display: flex;
                height: 92px;
                align-items: center;
                img{
                    position: relative;
                    width: 90px !important;
                }
            }
            img {
                width: 157px;
                height: auto ! important;
                display: block;
                flex-shrink: 0;
            }
        }
        .next {
            margin: 14px 0 10px;
            height: 64px;
            width: 85px;
            float: right;
            border-left: 1px solid #999999;
            text-align: center;
            position: relative;
            @include general-breakpoint(landscape, max){
                width: 75px;
            }
            img {
                width: 25px;
                height: 28px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                margin: auto;
                display: block;
                flex-shrink: 0;
            }
        }
        .countdown {
            width: 120px;
            height: 75px;
            float: right;
            text-align: center;
            @include general-breakpoint(landscape, max){
                width:50px;
            }
            .label {
                @include general-breakpoint(landscape, max){
                    white-space: nowrap;
                    transform: translateX(-20px);
                }
                padding-top: 5px;
                font-size: 14px;
            }
            .count {
                @include general-breakpoint(landscape, max){
                    font-size: 35px;
                    margin-top: -10px;
                }
                font-size: 45px;
                height: 55px;
                margin-top: -8px;
            }
            .allow {
                font-size: 12px;
                width: 100px;
                margin-left: 0;
                bottom: 5px;
                text-transform: uppercase;
                position: absolute;
                white-space: nowrap;
                @include general-breakpoint(landscape, max){
                    white-space: normal;
                    transform: translateX(-40px);
                }
                a {
                    color: #000000;
                }
            }
        }
        .next-content {
            padding-left: 177px;
            padding-right: 90px;
            display: block;
            float: none;
            color: #000000;
            width: auto;
            @include general-breakpoint(landscape, max){
                padding: 0;
                margin-left: 90px;
                width: 145px;
            }
            .prompt {
                @include general-breakpoint(landscape, max){
                    font-size: 15px;
                    text-align: left;
                }
                font-size: 18px;
                padding-top: 15px;
                text-transform: uppercase;
            }
            .title {
                @include general-breakpoint(landscape, max){
                    font-size: 13px;
                    white-space: normal;
                    text-align: left;
                }
                font-size: 26px;
                white-space: nowrap;
                overflow: hidden;
                font-weight: bold;

                text-overflow: ellipsis;
                line-clamp: 3;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
        }
    }
}
.react-draggable{
    z-index: 2147483647;    
    .pip-window{        
        &:before {
            content: '';
            height: calc(100% + 20px);
            width: calc(100% + 20px);
            position: absolute;
            top: -10px;
            left: -10px;
            border: solid 10px rgba(0,0,0,0.6);
            border-radius: 5px;
            background-color: rgba(0,0,0,0.5);
        }
        
        &:after {
            content: '';
            box-sizing: border-box;
            position: absolute;
            width: 10px;
            height: 10px;
            left: -6px;
            top: -6px;
            border-top: 5px solid rgba(255, 255, 255, 0.6);
            border-left: 5px solid rgba(255, 255, 255, 0.6);
            border-right: 5px solid transparent;
            border-bottom: 5px solid transparent;
        }
        .close-wrapper {
            position: absolute;
            right: 5px;
            top: 5px;
            z-index: 30;
            cursor: pointer;
            i.icon-close {
                display:block;
                color: rgba(255,255,255,0.8);
                font-weight: bold;
                font-size: px2rem(16);
                padding:5px;
            }
        }
    }
} 

.socialWrapper {
    width: 100%;
    border-bottom: solid 1px #d8d8d8;
    padding-bottom: 10px;
    .socialContainerNew {
        text-align: center;
        vertical-align: middle;
        // width: 220px;
        width: 470px;
        @include media-breakpoint-down(sm){ width: 100%;}
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;

        .mobileView{
            @include media-breakpoint-down(sm){ margin: 0px auto;}
        }
        .title {
            order: 1;
            color: #888B8D;
            padding-right: 10px;
            font-size: 14px !important;
            float: left;
            padding-top: 13px;
            margin-top: 12px;
        }
        .social {
            order: 2;
            display: flex;
            margin-top: 15px;
            justify-content: space-between;
            width: 50px;
            margin-right: 15px;
            .facebook, .twitter {
                margin-top: 12px;
            }
        }    
        .link {
            order: 3;
            margin-top: 12px;
            max-width: 330px;
            @include media-breakpoint-down(sm){ max-width: unset; width: 100%;}
            input {
                width: 270px;
                float: left;
                @include media-breakpoint-down(sm){ float: none;}
                background-color: #ebebeb;
                border: none;
                height: 40px;
                font-size: 14px;
                padding: 0px 10px;
                border-radius: 0;
                color: #418FDE;
            }
        }
        
    }
}

.videoClose.icon-close {
    z-index: 99999;
    position: absolute;
    color: white;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
}