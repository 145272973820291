$title-height: 100px;
$title-tablet-height: 76px;
$title-sm-height: 48px;
.playerProfile {
	max-width: 1280px;
	margin: 40px 0px 40px 0px;
	.content-main {
		padding-bottom: 20px;
	}
	.relatedContentContainer {
		.news-tile {
			.related-cnt {
				padding: 0;
			}
		}
	}
}

.playerMargins {
	height: 100%;
	background-color: $table-light-grey;
	border: solid 1px #e5e5e5;
}

.playerContainer,
.mobileView {
	width: 100%;
	background-color: $usoBkgGray;
	.no-players {
		min-height: 310px;
		text-align: center;
		padding: 20px 0;
		font-size: px2rem(16);
	}
}

.playerInfoContainer {
	display: flex;
	border-bottom: none;
	position: relative;
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
	@include general-breakpoint(smtablet, max) {
		display: initial;
		padding-top: 0;
		background-size: cover;
	}
	.contentNavContainer {
		display: none !important;
	}

	.collapsibleMenu {
		@include interstate();
		@include general-breakpoint(mobile, max) {
			display: none;
		}
		.header {
			i {
				@include interstate(bold);
			}
		}
		.content {
			.playerOverviewContainer {
				width: 100%;
				display: initial;
				border: initial;
				background-color: $white;
				.playerOverview {
					color: $usoDkGray;
					font-size: 14px;
					.playerOverviewLabel {
						display: none;
					}
					.statsTable {
						background-color: $white;
						.playerStats {
							margin-top: initial;
							display: flex;
							justify-content: space-between;
						}
						.profile-courtesy {
							padding: 8px;
						}
					}
				}
			}
		}
		.tabPanel {
			border-bottom: 1px solid $lt-gray;
			@include general-breakpoint(smtablet) {
				height: 52px;
			}
		}
	}

	.player {
		background-color: $dark-blue3;
		width: 299px;
		height: 442px;
		margin-right: 20px;
		position: relative;
		@include general-breakpoint(smdesktop, max) {
			height: 420px;
			width: 310px;
		}
		@include general-breakpoint(smtablet, max) {
			height: 366px;
			width: 100%;
		}

		.playerName {
			@include interstate(light);
			display: inline-flex;
			margin: 15px 20px 5px;
			flex-direction: column;
			@include general-breakpoint(smtablet, max) {
				margin: 15px 15px 2px;
			}
			img {
				margin-right: 10px;
				height: 100%;
			}
			.firstName {
				font-size: 14px;
				color: white;
				line-height: 1;
				@include general-breakpoint(tablet, max) {
					font-size: 18px;
				}
			}
			.lastName {
				font-size: 24px;
				line-height: 1.5;
				color: white;
				@include general-breakpoint(tablet, max) {
					font-size: 32px;
					line-height: 1;
				}
			}
		}
		.playerCountry {
			display: flex;
			margin: 15px 20px;
			@include general-breakpoint(smtablet, max) {
				margin: 10px 15px 15px 15px;
			}
			.countryContainer {
				@include interstate();
				margin-left: 10px;
				color: white;
				font-size: 14px;

				@include general-breakpoint(smdesktop, max) {
					margin: 0;
					padding-top: 1px;
					padding-left: 8px;
					height: 16px;
					width: 109px;
				}
			}
		}
		.playerInfo-box {
			background-color: $dark-blue3;
			display: flex;

			@include general-breakpoint(smtablet, max) {
				margin-left: 15px;
				margin-top: 10px;
				flex-direction: initial;
			}
			@include general-breakpoint(mobile, max) {
				margin: 10px 0px 5px 15px;
			}
			.playerPic img {
				width: 147px;
				height: 201px;
				@include general-breakpoint(desktop, max) {
					width: 152px;
					height: 201px;
				}
				@include general-breakpoint(smdesktop, max) {
					width: 140px;
					height: 185px;
				}
				@include general-breakpoint(smtablet, max) {
					width: 96px;
					height: 132px;
				}
			}
		}

		.playerInfo {
			@include interstate();
			padding: 16px 20px;
			background: $dark-blue;
			position: absolute;
			bottom: 0;
			width: 100%;
			@include general-breakpoint(smdesktop, max) {
				padding: 10px 10px;
			}
			@include general-breakpoint(smtablet, max) {
				padding: 15px 15px;
				width: 100%;
				bottom: 0;
				position: absolute;
			}
			.info-row {
				display: inline-block;
				width: 100%;
				white-space: nowrap;
			}
			.info-field {
				color: white;
				display: inline-block;
				text-align: left;
				vertical-align: top;
				margin-right: 20px;
				width: 30%;
			}
			.info-value {
				color: white;
				display: inline-block;
				text-align: left;
			}
		}
		.playerStanding {
			@include general-breakpoint(mobile, max) {
				text-align: left;
				padding: 0;
				margin-left: 10px;
			}
			.playerRankContainer {
				display: flex;
				width: 192px;
				margin-left: 20px;
			}
			.playerRank {
				width: 92px;
				margin-bottom: 5px;
				@include general-breakpoint(smdesktop) {
					width: 113px;
				}
				@include general-breakpoint(mobile) {
					width: 97px;
				}
			}
			margin: 10px 15px 0px;
			position: relative;
			text-align: center;
			display: flex;
			flex-direction: column;
			&.wc {
				margin: 10px 7px 0px;
			}
			@include general-breakpoint(smtablet, max) {
				width: 100%;
				margin-top: 0;
				margin: 10px auto 0px;
			}
			.rankLabel {
				font-size: 12px;
				color: #ffd400;
				display: flex;
				@include interstate();
				@include general-breakpoint(smtablet) {
					white-space: nowrap;
				}
				@include general-breakpoint(mobile, max) {
					text-align: left;
					font-size: 11px;
					padding: 0;
				}
				@include general-breakpoint(smdesktop, max) {
					overflow: hidden;
				}
			}

			.rank {
				font-size: 34px;
				color: white;
				line-height: 57.6px;
				margin-left: 5px;
				@include interstate(bold);
				@include general-breakpoint(smdesktop, max) {
					padding-bottom: 0;
				}
				@include general-breakpoint(mobile, max) {
					flex-direction: initial;
					font-size: 26px;
				}
				&.rankBorder {
					border-right: 1px solid #ffd400;
				}
			}
		}
		.favorite {
			margin-left: 3px;
			position: absolute;
			bottom: 0;
			height: 35px;
			@include general-breakpoint(smtablet, max) {
				position: relative;
				margin-left: 146px;
			}
			&:after {
				position: relative;
				@include general-breakpoint(desktop, max) {
					font-size: 24px;
				}
			}
		}
		.media-favorite {
			display: flex;
			@include general-breakpoint(smtablet, max) {
				width: 100%;
				margin-top: -70px;
			}
			.playerNewsTile {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				padding-right: 20px;
				@include general-breakpoint(mobile, max) {
					width: unset;
				}
			}
			.newsTileBtnLabel {
				@include general-breakpoint(smtablet, max) {
					background-color: initial;
					border: 1px solid $white;
					margin-top: 10px;
				}
				@include general-breakpoint(mobile, max) {
					margin-left: 50px;
					font-size: 10px;
					margin-top: 0;
					text-align: center;
				}
				span {
					text-align: center;
				}
			}
			span.favorite {
				&:after {
					font-size: 20px;

					@include general-breakpoint(mobile, max) {
						font-size: 18px;
						margin-top: 0;
					}
				}
			}
		}
	}
	.playerBoxContainer {
		display: flex;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		.playerBoxLeft {
			width: 452px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.playerBoxRight {
			flex: 1 1 0;
			display: flex;
			align-items: center;
			justify-content: center;
			@include general-breakpoint(tablet, max) {
				align-items: flex-start;
				margin-top: 20px;
			}
		}
	}
	.playerEvents {
		display: table-cell;
		vertical-align: top;
		padding: 15px 0px;
		height: 100%;
		@include general-breakpoint(smdesktop) {
			padding: 30px 0px;
		}
	}
	.playerImage {
		img {
			height: 216px;
			width: auto;
		}
		border: 2px solid white;
		@include general-breakpoint(landscape) {
			img {
				height: 300px;
				width: auto /*300px*/;
				border: 1px solid $md-gray2;
			}
		}
	}
	.playerEvents {
		display: block;
		background-color: $lt-gray2;
		@include general-breakpoint(landscape, max) {
			margin-left: -20px;
			margin-right: -20px;
			padding: 15px;
			border-bottom: 1px solid $md-gray2;
		}
		@include general-breakpoint(landscape) {
			display: none;
		}
		@include general-breakpoint(tablet) {
			display: none;
		}
		@include general-breakpoint(smdesktop) {
			display: table-cell;
			width: 25%;
			padding-left: 20px;
			padding-right: 20px;
		}
		.event-label {
			display: block;
			@include interstate;
			font-size: 10px;
			color: $md-gray;
			text-transform: uppercase;
			padding-bottom: 5px;
		}
		.event-names {
			display: block;
			@include interstate;
			font-size: 18px;
			color: $dk-gray2;
			padding-bottom: 15px;
		}
	}
	.playerNewsTile {
		margin-right: 20px;
		width: 540px;
		height: 442px;
		@include general-breakpoint(desktop, max) {
			width: 634px;
			height: 499px;
			margin-right: 0;
		}
		@include general-breakpoint(smdesktop, max) {
			width: 448px;
			height: 420px;
		}
		@include general-breakpoint(smtablet, max) {
			width: 141px;
			height: 46px;
		}
		@include general-breakpoint(smtablet, desktop) {
			display: none;
		}
		.news-tile {
			padding-bottom: 0;
			min-height: initial;
			width: 540px;
			height: 406px;
			@include general-breakpoint(desktop, max) {
				width: 634px;
				height: 461px;
			}
			@include general-breakpoint(smdesktop, max) {
				width: 448px;
				height: 383px;
			}
			margin-bottom: 6px;
			.newsChips.related-cnt {
				max-width: initial;
				padding: 0;
				margin: 0;
				.chip_content {
					max-width: initial;
					width: 100%;
					height: 100%;
					.chip {
						width: 540px;
						height: 406px;
						@include general-breakpoint(desktop, max) {
							width: 634px;
							height: 461px;
						}
						@include general-breakpoint(smdesktop, max) {
							width: 448px;
							height: 383px;
						}

						margin: 0;
						.photoContainer {
							height: 75.25% !important;
						}
						.caption {
							font-size: px2rem(18);
						}
						.credit {
							display: none;
						}
						&.hovering {
							border: none;
							transform: none;
							box-shadow: none;
						}
					}
					.chip.video-chip {
						.photoContainer {
							i {
								color: #fff;
								font-size: 56px;
								position: absolute;
							}
						}
					}
				}
			}
		}

		.newsTileBtnLabel {
			@include interstate(light);
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $usoLtBlue;
			border-radius: 6px;
			color: $white;
			font-size: 14px;
			padding: 5px;
			position: relative;
			bottom: 0;
			//width: 541px;
			height: 31px;
			cursor: pointer;
			@include general-breakpoint(smtablet, max) {
				width: 141px;
				height: 50px;
			}
			@include general-breakpoint(mobile, max) {
				width: unset;
				height: 50px;
				margin-right: -10px;
			}

			.icon-arrow-down {
				padding-left: 5px;
				transform: scale(0.7, 1);
			}
		}
	}
	.playerMargins {
		display: flex;
		flex-direction: column;
		.playerStatsContainer {
			display: flex;
			justify-content: center;
		}
	}
	.playerMargins,
	.playerNewsTile {
		.relatedContent {
			margin-bottom: 5px;
			.related-cnt {
				background-color: $white;
				padding-bottom: 0px;
				.related-content-tile {
					.click-wrapper {
						width: 100%;
						height: 100%;
						display: block;
						.image-container {
							position: relative;
							width: 100%;
							img {
								width: 550px;
								height: 330px;
								vertical-align: middle;
								border-style: none;
								@include general-breakpoint(desktop, max) {
									width: 630px;
								}
							}
						}
						.content-text {
							padding: 15px 20px 0px;
							.type-tag {
								display: none;
							}
							.content-title {
								@include interstate(light);
								font-size: 18px;
								margin-bottom: 10px;
								color: $usoDkGray;
							}
							.content-timestamp {
								@include interstate(light);
								//font-weight: 300 !important;
								font-size: 12px;
								color: #4a4a4a;
								line-height: 20px;
								text-transform: capitalize;
								padding-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}
	.playerOverviewContainer {
		background-color: $white;
		width: 377px;
		display: inline-block;
		border: solid 1px $light-grey;
		@include general-breakpoint(desktop, max) {
			display: none;
		}
		h1 {
			color: white;
			max-width: 1280px;
			width: 100%;
			margin-bottom: 15px;
			font-size: 24px;
			@include interstate(light);
			@include general-breakpoint(smtablet) {
				font-size: 32px;
			}
		}
		.playerOverviewLabel {
			border-top: solid 3px $usoYellow;
			border-bottom: solid 1px $light-grey;
			padding: 15px;
		}
		.playerMargins {
			display: flex;
			flex-direction: column;
			.playerStatsContainer {
				display: flex;
				justify-content: center;
			}
		}
		.playerOverview {
			white-space: nowrap;
			font-size: 12px;
			@include interstate(light);
			.careerEarnings {
				padding-top: 20px !important;
			}
			.statsTable {
				padding: 10px;

				.playerStats {
					margin-top: -5px;

					.view-label {
						padding: 4px 8px;
					}
				}
				.profile-courtesy {
					padding: 8px;
				}
			}
		}
	}
}
.tabletViewContainer,
.tabletViewContent {
	width: 100%;
}
.tabletViewContainer,
#tabletView {
	display: flex;
	margin-bottom: 20px;
}

.playerEventsTablet {
	display: none;
	@include general-breakpoint(landscape) {
		display: block;
		min-height: 113px;
		background-color: $lt-gray2;
		border-bottom: 1px solid $md-gray2;
		padding: 15px 0px;
	}
	@include general-breakpoint(smdesktop) {
		display: none;
	}
	@include general-breakpoint(desktop) {
		display: none;
	}
	.column {
		display: inline-block;
		width: 20%;
		&.xl {
			width: 40%;
		}
	}
	.event-label {
		display: block;
		@include interstate;
		font-size: 10px;
		color: $md-gray2;
		text-transform: uppercase;
		padding-bottom: 5px;
	}
	.event-names {
		display: block;
		@include interstate;
		font-size: 18px;
		color: $dk-gray2;
		padding-bottom: 15px;
	}
}
.playerOverviewMobile {
	background-image: url('/assets/images/misc/stats_gradient.svg');
	padding: 30px 0px;
	width: 100%;
	background-color: $white;
	display: block;
	@include general-breakpoint(smdesktop) {
		display: none;
	}
	h1 {
		color: $white;
		max-width: 1280px;
		width: 100%;
		margin-bottom: 15px;
		font-size: 24px;
		@include interstate(light);
	}
	.container-row {
		padding: 5px 0;
		background-color: transparent;
		color: white;
	}
	.view-label,
	.view-info {
		display: inline-block;
		@include interstate(light);
		color: white;
		font-size: 11px;
	}
	.view-label {
		width: 70%;
	}
	.view-info {
		width: 30%;
		text-align: right;
	}
}

.recentMatchesContainer {
	background-color: $usoBkgGray;
	display: inline-block;
	width: 100%;
	.contentTitle {
		height: 55px;
		border-bottom: solid 2px $light-grey;
		color: $dk-gray2;
		display: flex;
		justify-content: left;
		align-items: center;
		font-size: 18px;
		padding-left: 20px;
		@include interstate(bold);
	}
	.player-matchup {
		display: flex;
		flex-wrap: wrap;
		max-width: 1280px;
		margin: 10px;
	}
	.featuredtable {
		width: 100%;
		padding-bottom: 10px;
		margin-right: 20px;
		@include general-breakpoint(mobile, max) {
			flex: 1 1 auto;
		}
		@include general-breakpoint(smtablet) {
			width: calc(49% - 5px * 2);
			margin: 0 8px;
		}
		@include general-breakpoint(smdesktop) {
			width: calc(34% - 10px * 3);
		}
	}
	.player-score-table {
		width: 100%;
		margin: 10px 10px;
		@include general-breakpoint(smtablet) {
			margin: 10px 0;
		}
	}
}

.historyOverviewMobile {
	display: flex;
	color: $usoDkGray;
	text-transform: uppercase;
	.contentTitle {
		font-size: 18px;
		padding: 20px 20px 20px;
		color: $usoDkGray;
		position: relative;
		font-weight: 300 !important;
		border-bottom: 1px solid $usoBkgGray;
	}
	.singlesContainer,
	.doublesContainer {
		width: 100%;
		background-color: $white;

		&.no-history {
			min-height: 189px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.playerHistory {
		width: 100%;
		font-size: 1rem;
		border-collapse: collapse;
		text-align: center;
		thead {
			border-top: solid 1px $usoLtGray;
			border-bottom: solid 1px $usoLtGray;
			background-color: $usoBkgGray;
			th {
				font-weight: 200 !important;
				padding: 8px 10px 8px 8px;
			}
		}
	}

	.playerHistory td,
	tbody tr {
		border-bottom: 1px solid $usoBkgGray;
		border-collapse: collapse;
		padding: 8px 10px 8px 8px;
		font-weight: 200 !important;
		vertical-align: initial;
	}
}
.historyOverviewContainer {
	max-width: 1280px;
	display: flex;
	color: $usoDkGray;
	text-transform: uppercase;
	margin-top: 30px;
	@include general-breakpoint(desktop, max) {
		display: none;
	}
	.contentTitle {
		font-size: 18px;
		padding: 20px 20px 20px;
		color: $usoDkGray;
		position: relative;
		font-weight: 300 !important;
		border-bottom: 1px solid $usoBkgGray;
	}
	.singlesContainer {
		margin-right: 20px;
	}
	.singlesContainer,
	.doublesContainer {
		background-color: $white;
		width: 50%;
	}
	.playerHistory {
		width: 100%;
		font-size: 1rem;
		border-collapse: collapse;
		thead {
			background-color: $usoBkgGray;
			border-top: 1px solid $usoLtGray;
			border-bottom: 1px solid $usoLtGray;
		}
	}

	.playerHistory th {
		border-collapse: collapse;
		padding: 8px 10px 8px 8px;
		text-align: center;
		font-weight: 200 !important;
	}
	.playerHistory {
		td,
		tbody tr {
			border-bottom: 1px solid $usoltMedGray;
			border-collapse: collapse;
			padding: 8px 10px 8px 8px;
			text-align: center;
			font-weight: 200 !important;
		}
	}
}
