$mobile-player-box-width: 80px;
$tablet-player-box-width: 156px;
$mobile-layout-width: 320px; //284px;
$tablet-layout-width: 690px;
$desktop-layout-width: 854px;

.mi-power-ranking-wrapper {
    @extend %match-insights-content-padding;
    border-bottom: solid 1px $md-gray3;
}

/** match insights page **/
.mi-pr-player-info-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 404px;
    margin: auto;
    position: relative;

    @include general-breakpoint(smtablet) {
        max-width: 710px;
    }

    @include general-breakpoint(smdesktop) {
        max-width: 966px;
    }

    @include general-breakpoint(desktop) {
        max-width: 1202px;
    }

    .mi-pr-player-info {
        position: relative;
        text-align: center;

        @include general-breakpoint(smdesktop) {
            width: 316px;
        }

        @include general-breakpoint(desktop) {
            width: 423px;
        }
    }
    
    .v{
        display: flex;
        flex-flow: column;
        padding: 0;
        position: relative;

        @include general-breakpoint(smdesktop) {
            width: auto;
        }
    }

    .mi-pr-player-country-name {
        @include interstate(regular);
        color: $gray-4a;
        // display: flex;
        font-size: px2rem(11);
        justify-content: center;
        padding-bottom: 30px;
        overflow: visible;

        @include general-breakpoint(smtablet) {
            font-size: px2rem(24);
            margin-top: 10px;
            white-space: nowrap;
            width: auto;
        }

        .mi-player-flag {
            display: block;
            img {
                height: 12px;

                @include general-breakpoint(smtablet) { 
                    height: 26px;
                }
            }
        }
        
        .player-name {
            text-align: center;
        }

        a {
            color: $gray-4a;
        }
    } // .mi-pr-player-country-name

    /* circular image override */
    img.player-image {
        width: $mobile-player-box-width;
        height: auto;
        border: solid 1px $usoLtGray;

        @include general-breakpoint(smtablet) {
            width: $tablet-player-box-width;
            height: auto;
        }

        @include general-breakpoint(smdesktop) {
            width: 220px;
            height: auto;
        }
    }

    .player1 img.player-image {
        border-bottom: solid 8px $team1_color;

        @include general-breakpoint(smtablet) {
            margin-left: 20px;
        }
    }

    .player2 img.player-image {
        border-bottom: solid 8px $team2_color;

        @include general-breakpoint(smtablet) {
            margin-right: 20px;
        }
    }

    .mi-pr-chart {
        position: relative;
        width: $mobile-player-box-width;
        height: 85px;
        order: 3;
        z-index: 10;

        .ct-chart {
            margin: 0 auto;
            width: 100%;
            height: 100%;

            .ct-chart-donut {
                overflow: visible;
            }
        }

        .ct-fill-donut-label { 
            height: 66px;
            width: 66px;
			left: 29px !important;
			top: 9px !important; 
            background-color: $white;
            border-radius: 50%;
        }

        .mi-pr-chart-center {
            color: $gray-4a;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include interstate();
    
            .chart-value {
                font-size: px2rem(34);
                line-height: 32px;
            }
    
            sup {
                font-size: px2rem(14);
                line-height: 32px;
            }
        }
    }

    @include general-breakpoint(smtablet) {
        .mi-pr-chart {
            margin-top: 50px;
            width: 92px;
            height: 92px;

            .ct-fill-donut-label { 
                left: 13px !important;
            }
    
            .mi-pr-chart-center {
                .chart-value {
                    font-size: px2rem(36);
                }
                sup {

                }
            }
        }
    }

    @include general-breakpoint(smdesktop) {
        .mi-pr-chart {
            width: 100px;
            height: 92px;

            .ct-fill-donut-label { 
                left: 16px !important;
            }
    
            .mi-pr-chart-center {
                .chart-value {
                    // font-size: px2rem(36);
                }
            }
        }
    }
    
    /* favorite star override */
    .favorite {
        position: absolute;
        cursor: pointer;
        //display: none;
        z-index: 2;

        @include general-breakpoint(smtablet) {
            display: block;
        }
        
        &:after {
            position: absolute;
            left: 0;
            content: "\2606";
            font-size: 23px;
            color: $wim-green;

            @include general-breakpoint(smtablet) {
                font-size: 33px;
            }
        }
        
        &.favorited {
            &:after {
                content: "\2605";
                font-size: 23px;
                color: #F9E300;

                @include general-breakpoint(smtablet) {
                    font-size: 33px;
                }
            }
        }
    }

    /* player specific positioning where head shots are */
    .mi-pr-player-chart { 
        &.player1 {
            .favorite {
                left: -20px;
            }
    
            .ct-chart-donut .ct-series-a .ct-slice-donut {
                stroke: $team1_color; 
            }
    
            .player-img-container {
                position: relative;
                padding-bottom: 10px;
        
                &:after {
                    content:'';
                    position: absolute;
                    width: 100%;
                    height: 8px;
                    left: 0;
                    bottom: 0;
                    background-color: $team1_color;
                }
            }

            .ct-chart-donut .ct-fill-donut .ct-slice-donut {
                stroke: $donut-fill-color;
                opacity: 1;
                stroke-width: 10px!important;
            }
            .ct-chart-donut .ct-series-b .ct-slice-donut {
                stroke: $donut-fill-color;
                opacity: 1;
                stroke-width: 10px!important;						
            }
        } // .player1
    
        &.player2 {
            .favorite {
                right: 5px;

                @include general-breakpoint(smtablet) {
                    right: 8px;
                }
            }
    
            .ct-chart-donut .ct-series-a .ct-slice-donut {
                stroke: $team2_color;
            }
    
            .player-img-container {
                position: relative;
                padding-bottom: 10px;
        
                &:after {
                    content:'';
                    position: absolute;
                    width: 100%;
                    height: 8px;
                    left: 0;
                    bottom: 0;
                    background-color: $team2_color;
                }
            }

            .ct-chart-donut .ct-fill-donut .ct-slice-donut {
                stroke: $donut-fill-color;
                opacity: 1;
                stroke-width: 10px!important;
            }
            .ct-chart-donut .ct-series-b .ct-slice-donut {
                stroke: $donut-fill-color;
                opacity: 1;
                stroke-width: 10px!important;						
            }
        } // .player2
    } // .mi-pr-player-info    
} // .mi-pr-player-info-wrapper


/* ranks numbers and likelihood win button */
.mi-pr-ranks-wrapper {
    background-color: $usoBkgGray;
    border-radius:  7px;
    padding-top: 10px;
    margin-top: 10px;

    @include general-breakpoint(smtablet) {
        padding-top: 0;
        margin-top: 0;
    }

    @include general-breakpoint(smdesktop) {
        background-color: $white;
    }

    a {
        @include interstate(regular);
        font-size: px2rem(12);
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        height: 31px;
        line-height: 29px;
        text-decoration: none;

        &.border {
            border: 1px solid $bright-blue !important;
            color: $bright-blue;
            background-color: $white;
        }
        &.center {
            display: block;
            margin: 20px auto 0;
        }
    }
} // .mi-pr-ranks-wrapper

.mi-pr-ranks {
    display: block;
    // margin: 30px auto 0;
    // width: 100%;
    margin: 0px auto 10px auto;
    min-width: 205px;
    max-width: 100%;

    @include general-breakpoint(smtablet) {
        margin: 0px auto 20px auto;
        max-width: 600px;
        // margin: 0 auto;
        // width: 100%;
    }

    @include general-breakpoint(smdesktop) {
        max-width: $desktop-layout-width;
        max-height: unset;
    }

    .ranking-row {
        display: flex;
        justify-content: space-between;

        /** override global.scss,
        the breakpoint for mobile-only is extended to tablet on this component*/
        .mobile-only {
            align-self: center;

            display: block;

            @include  general-breakpoint(smdesktop) {
                display: none;
            }
        }

        /** override global.scss,
        the breakpoint for mobile-only is extended to tablet on this component
        above-tablet is now over 1024px on this component */
        .above-tablet {
            display: none;

            @include general-breakpoint(smdesktop) {
                display: block;
            }
        }

        .ranking-cell {
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            width: 25%;

            a > .generic-button {
                @include general-breakpoint(smtablet) {
                    @include interstate(bold);
                    background-color: $white;

                    &.rounded-corner {
                        .btn-border {
                            border-radius: 4px;
                            border-color: $usoLtBlue;
                        }

                        .content {
                            color: $usoLtBlue;
                            
                            &:active {
                                color: $usoLtBlue;
                                background-color: $usoLtBlueBG;
                                border-radius: 4px !important;
                            }
                        }
                    }
                }
            }

        /** override global.scss,
        the breakpoint for mobile-only is extended to tablet on this component*/
        .mobile-only {
            align-self: center;

            display: block;

            @include  general-breakpoint(smdesktop) {
                display: none;
            }
        }
        }

        .player1-pr-rank, .player2-pr-rank, .atp-rank  {
            @include interstate();
            color: $team1_color;
            font-size: px2rem(36);

            @include general-breakpoint(smtablet) {
                font-size: px2rem(54);
            }

            @include general-breakpoint(smdesktop) {
                font-size: px2rem(72);
            }
            
            .player-name {
                @include interstate(regular);
                display: block;
                color: $usoDkGray;
                font-size: px2rem(20);

                &.blog-name {
                    @include general-breakpoint(smtablet, max) {
                            font-size: px2rem(12);
                    }
                }
            }
        }

        .player2-pr-rank {
            color: $team2_color;
        }

        .rank-label {
            @include interstate();
            color: $gray-4a;
            font-size: px2rem(14);
            text-align: center;
            text-transform: uppercase;
            width: 49%;
            align-items: center;
            align-self: center;
            line-height: 24px;
            white-space: nowrap;

            @include general-breakpoint(smtablet) {
                font-size: px2rem(20);
                margin-left: 30px;
                margin-right: 30px;
                width: auto;
            }

            @include general-breakpoint(smdesktop) {
                font-size: px2rem(32);
            }

            &.atp {
                color: $md-gray;
            }
        }

        .atp-rank {
            color: $md-gray2;
        }
    }
} // mi-pr-ranks

/** Slamtracker page override 
    - Mobile size is the same as the standard MI page
    - above Tablet size, no head shots
    - hide player names and country flags
    - display player names above the likelihood to win number on desktop size
*/
.webview {
    .wrapper.slamtracker {
        .content-main {
            .mi-section {
                border-bottom:none;
                &.mi-footer {
                    display:none;
                }
            }
            .slamtracker-powerranking {
                .mi-section {
                    margin-left: auto;
                    margin-right: auto;
                    padding-bottom: 0;
                }
            }
        }
    }
}

/** slamtracker override */
.slamtracker {
    .slamtracker-powerranking {
        padding-top: 10px;
    }
    .mi-section-header.power-index {
        .mi-last-updated {
            margin-left: auto;
            display: none;
        }
    }
    .PowerIndexChart {
        align-items: center;
    
        .probability {
            margin-top: 0px;

            .slamtracker-only {
                .player-name {
                    white-space: normal;
        
                    @include general-breakpoint(smtablet, max) {
                        display: none;
                    }
                }
            }
        } 
    }

    .mi-power-ranking-wrapper {
        margin-left: 14px !important;
        margin-right: 14px !important;
        padding-top: 20px !important;
        padding-bottom: 0 !important;
        border-bottom: none;
    }

    .mi-pr-ranks-wrapper {
        width: 100%;
        max-width: 100%;
        background-color: $usoBkgGray;
        border: 1px solid $md-gray2;
        border-radius:  5px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        a {
            width: 100%;
            height: 26px;
            display: block;
        }
        .generic-button_wrapper {
            margin-bottom: 0;
            
            &.center {
                text-align: center;
            }
        }

        .generic-button_wrapper>.generic-button, a>.generic-button {
            font-size: px2rem(12);
            height: 26px;

            .content {
                padding-top: 4px;
            }
            &.rounded-corner {
                .btn-border { border-radius: 5px; }
            }
        }

        @include general-breakpoint(smtablet) {
            .mobile-only {
                display: block;
            }
        }

    }

    .mi-pr-player-info-wrapper {
        max-width: $panel_content_width;
        justify-content: space-between;
        width: $panel_content_width;

        @include general-breakpoint(smtablet) {
            // justify-content: center;
        }

        @include general-breakpoint(smdesktop) {
            max-width: $panel_content_width;
            // transform: translateX(-8px);
        }

        @include general-breakpoint(desktop){
            max-width: $panel_content_width;
        }

        .mi-pr-player-country-name, .favorite {
            display: none;
        }

        .v {
            margin: 0 5px;
        }

        .mi-pr-player-info {
            width: 80px;

            .mi-pr-player-container,
            .mi-pr-player-country-name,
            .player-img-container {
                // display: none;
                display: flex;
                flex-direction: column;
                @include interstate();
                font-size: px2rem(12);
                white-space: unset;

                @include general-breakpoint(smtablet, max) {
                    // display: block;
                }

                .player-image {
                    height: auto;
                    margin: 0;
                }
                .player-box-link {
                    cursor: pointer;
                }
            }
            .mi-pr-player-country-name {
                justify-content: space-between;
                padding-bottom: 0px;
            }
            &.player1, &.player2 {
                justify-content: center;

                .mi-pr-chart {
                    top: 0;
                    left: 0;
                }
            }

            @include general-breakpoint(smtablet) {
                // display: none;
            }
        }

        .player-image {
            width: 80px;
        }
        .mi-player-flag {
            img {
                height: 12px !important;
            }
        }
    } 

   .mi-pr-ranks {
        margin: 0;
        min-width: 205px;
        display: flex;
        align-items: center;
        width: 100%;

        .ranking-row {
            width: 100%;

            .player1-pr-rank, .player2-pr-rank, .atp-rank  {
                font-size: px2rem(44);
            }
            .rank-label {
                font-size: px2rem(16);
                color: #333333;
            }

            .above-tablet {
                display: none;
            }

            .mobile-only {
                display: block;
            }
        }
    } 

    .where-to-watch {
        width: $panel_content_width;
        padding-bottom: 16px;
        padding-top: 10px;
        margin: 0 auto;

        .button {
            @include button();
            width: 100%;
            height: 31px;
            min-height: 31px !important;
            i {
                margin-right: 8px;
            }
        }
    }
} // .slamtracker

.webview {
    .slamtracker {
        .mi-section-header.power-index {
            margin-bottom: 0;
        }
    }
}
