.heroSection.home-page {
    .carousel {
        .control-dots {
            @include media-breakpoint-down(sm) {
                display: none;
            }
            bottom: 10px;
            display: inline-block;
            background-color: rgba(0, 0, 0, 0.56);
            padding: 6px 5px 4px 5px;
            -webkit-border-radius: 15px;
            border-radius: 15px;
            background-clip: padding-box;
            width: auto;
            left: 50%;
            transform: translateX(-50%);
            .dot {
                opacity: 1;
                position: relative;
                background: none !important;
                width: 10px !important;
                height: 10px !important;
                border-radius: 7px !important;
                border: solid 2px #fff;
                &.selected {
                    background: #f3f3f3 !important;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .bx-caption {
            bottom: 50px;
            left: 32px;
            text-align: left;
            @include media-breakpoint-down(sm) {
                bottom: 0px;
                left: 0px;
                text-align: center;
            }
            span {
                text-shadow: 2px 2px 2px $black;
                @include general-breakpoint(landscape){ left: 32px; bottom: 32px; }
            }            
        }
    }
}