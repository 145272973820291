/** amex styles **/
$amex-bg: #00175a;
$amex-bg-lt: #f6f6f6;
$amex-text: #ffffff;
$amex-text-dk: #00175a;
$amex-text-md: #006fcf;
$amex-text-lt: #66A9E2;
$amex-text-gr: #ababab;
$amex-text-gr-dk: #898b8d;

#uso-main {
	.wrapper {
		&.page-header-hero {
			&.american_express_on_site_benefits {
				@include media-breakpoint-down(md) {
					section:nth-of-type(2) {
						position: relative;
						margin-top: -40px;
					}
				}
			}
		}
		&.american_express_on_site_benefits {
			max-width: 100%;
		}
	}
}

.general_content {
	&.amex {
		color: $amex-text;
		// background-color: $amex-bg;
		.hero_container {
			@include media-breakpoint-down(sm) {
				min-height: calc(100vh - 220px);
				background-image: url('https://photo-assets.usopen.org/images/pics/misc/US_Open_AMEX_header_image_2x.jpg') !important;
			}
			.hero_content {
				.title_container,
				.hero_buttons {
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					top: 0;
					.landing_title {
						max-width: 75%;
						display: inline-block;
						h1,
						h2 {
							font-size: 4rem;
							line-height: 4.1rem;
							margin-bottom: 1rem;
							@include general-breakpoint(landscape) {
								font-size: 5rem;
								line-height: 5.1rem;
								padding: 10px 0;
							}
							@include general-breakpoint(tablet) {
								font-size: 6rem;
								line-height: 6rem;
								padding: 10px 0;
							}
						}
						&.light-condensed {
							h1,
							h2 {
								span {
									&.alt {
										color: $amex-text-lt;
									}
								}
							}
						}
					}
				}

				@include general-breakpoint(landscape) {
					.title_container {
						.hero-button-container {
							display:flex;
							flex-direction: row;
							width:100%;
							flex-wrap: wrap;
							justify-content: center;
							.hero_button {
								margin-bottom:0px;
								flex: 0 0 50%;
								max-width: 50%;
							}
						}
					}
				}
				
			}
		}
		.hospitality_content {
			max-width: 100%;
			background-color: $amex-bg-lt;
			color: $amex-text-gr-dk;
			.hosp_intro {
				background-color: $amex-bg-lt;
				padding: 30px 0;
				p {
					font-size: 14px;
					color: $amex-text-gr-dk;
					& + p {
						padding-top: 0 !important;
					}
				}
			}
		}
		h1 {
			color: $amex-text;
		}
		.intro-text {
			color: $amex-text-dk;
			h2 {
				@include interstate(condensedbold);
				color: $amex-text-dk;
				padding-top: 20px;
				padding-bottom: 10px;
				width: 85%;
				margin: auto;
				font-size: 3.75rem;
				line-height: 3.75rem;
				@include media-breakpoint-down(sm) {
					padding-top: 35px;
					width: 90%;
					font-size: 2.8rem;
					line-height: 2.8rem;
				}
				&:empty {
					display: none;
				}
			}
			p {
				font-size: 1.2rem !important;
				color: $amex-text-gr !important;
				padding: 0;
				@include media-breakpoint-down(sm) {
					padding: 0;
				}
			}
		}
		.uso-news,
		.intro-text {
			position: relative;
			@include media-breakpoint-down(sm) {
				padding: 2rem 3rem;
			}
			.rich-text {
				h3 {
					font-size: 3rem;
					@include interstate(condensedbold);
					padding: 0;
					margin: 2rem 0;
					@include media-breakpoint-down(sm) {
						font-size: 2rem;
						margin: 0.75rem 0;
					}
				}
			}
			&:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 0;
				height: 0;
				z-index: 1;
				border-top: solid 20px $amex-bg;
				border-left: solid 20px transparent;
				border-right: solid 20px transparent;
			}
		}
	}
}
.amex-section {
	@include media-breakpoint-down(xs) {
		@include make-container;
	}
	.amex-content {
		@include make-row;
		// margin-bottom: 10px;
		&.dark {
			.image-bg {
				.caption {
					color: $dark-blue3 !important;
					h1,
					h2 {
						color: $dark-blue3 !important;
					}
					.text {
						color: $dark-blue3 !important;
					}
				}
			}
		}
		ul {
			color: $dk-gray3;
			padding: 10px 30px;
			margin-left: 0 !important;
			text-align: left;
			li {
				line-height: 1.4rem !important;
			}
		}
		.image {
			@include make-col(12);
			@include media-breakpoint-up(sm) {
				@include make-col-ready;
				@include make-col(7);
			}
			img {
				width: 100%;
				/* height: 212px; */
				/* @include general-breakpoint(landscape){ height: 425px; } */
				@include general-breakpoint(tablet) {
					height: auto;
				}
			}
			.video {
				@include media-breakpoint-up(sm) {
					margin-left: -15px;
					margin-right: -15px;
				}
				position: relative;
				&:before {
					display: block;
					content: '';
					width: 100%;
					padding-top: 56.25%;
				}
				.inlineVideo {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}
		}

		.text {
			@include make-col-ready;
			@include media-breakpoint-up(sm) {
				@include make-col(5);
			}
			@include interstate();
			font-size: 1.2rem;
			color: $md-gray;
			padding: 10px;
			@include general-breakpoint(landscape) {
				padding: 15px 30px;
				font-size: 1.4rem;
			}
			@include general-breakpoint(tablet) {
				padding: 30px;
			}
			h1,
			h2 {
				font-size: 2rem;
				color: $dark-blue3;
				@include interstate(condensedbold);
				padding: 5px 0;
				border: none;
				@include general-breakpoint(landscape) {
					font-size: 3rem;
					padding: 10px 0;
				}
			}
			p {
				padding-top: 0;
				line-height: 1.4em;

				&:empty {
					display: none;
				}
			}
			.uso-button {
				width: 130px;
				height: 40px;
				margin: 15px 0;
			}
		}
		&.subpage {
			background-color: $pale-yellow;
			color: $white;
			padding: 30px 10px;
			@include general-breakpoint(landscape) {
				padding: 30px;
			}
			h1 {
				color: $white;
			}
			ul {
				color: $white;
			}
			.hosp-gallery {
				#relatedPhotoNavContainer {
					width: 95% !important;
					@include general-breakpoint(landscape) {
						width: 90% !important;
					}
					@include general-breakpoint(tablet) {
						width: 47% !important;
					}
				}
			}
			.text {
				color: $white;
				@include general-breakpoint(landscape) {
					padding: 15px;
				}
			}
		}
		&.left {
			@include media-breakpoint-up(sm) {
				.text {
					order: -1;
					padding-right: 5px;
					padding-left: 40px;
					@include media-breakpoint-up(xl) {
						padding-left: 8%;
					}
				}
			}
		}
		&.right {
			.text {
				@include media-breakpoint-up(xl) {
					padding-right: 8%;
				}
			}
		}
		h2 {
			&.hdr-icon {
				background-position: right;
				&.wi-fi {
					background-image: url('/images/pics/misc/hdr-contactless.png');
				}
			}
		}
	}
	&.chip-container {
		// background-color: $bright-blue;
		background-color: $lt-gray;
		height: auto !important; /* overide the height */
		@include media-breakpoint-up(sm) {
			padding-bottom: 0 !important;
		}
		.amex-content {
			position: relative !important;
		}
		h2 {
			@include interstate(condensedbold);
			color: $white;
		}
	}
	&.overlay {
		/*** 16x9 section w/ text overlay ***/
		width: 100%;
		position: relative;
		@include media-breakpoint-up(sm) {
			// padding-bottom: 56.25%;
		}
		@include media-breakpoint-up(xl) {
			padding-bottom: 0;
			// height: 720px;
			max-width: 100%;
		}
		&.full-background-buttons-dark {
			background-color: $amex-bg;
		}
		&.one-liner {
			.amex-content {
				.image-bg {
					background-repeat: no-repeat;
					background-position: 45px bottom;
					background-size: contain;
					@include media-breakpoint-up(md) {
						background-position: 120% bottom;
					}
					@include media-breakpoint-up(lg) {
						background-position: right bottom;
					}			
					.caption {
						display: flex;
						flex-direction: column;
						align-items: center;
						@include media-breakpoint-up(md) {
							flex-direction: row;
						}
						@include media-breakpoint-up(md) {
							@include make-col(8);
						}
	
						.text {
							padding: 10px;
							flex: 1 1 auto !important;
							max-width: unset !important;
							/* align-items: center; */
							font-size: 1.667rem;
							width: auto !important;	
							p {
								margin: 5px;
							}						
						}
						.buttons {
							flex: 1 0 auto;
							justify-content: flex-start;
						}						
					}
				}	
			}
		}
		.amex-content {
			@include media-breakpoint-up(sm) {
				position: relative;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}
			.image-bg {
				.caption {
					h2 {
						@include interstate(condensedbold);
						&:empty {
							display: none;
						}
					}
				}
			}
			&.section1 {
				.image-bg {
					.caption {
						h2 {
							@include interstate(condensedbold);
						}
						.logos {
							margin-top: 0;
						}
					}
				}
			}
			&.section2 {
				&.section-bg {
					background-size: cover;
					background-position: center;
				}
				.image-bg {
					background-color: rgba(256, 256, 256, 0.8);
					.caption {
						h2,
						.text {
							color: $dark-blue3;
						}
					}
				}
			}
			&.section3 {
				.image-bg {
					background-color: $dark-blue3;
					padding: 25px 0;
					.caption {
						h2 {
							padding-top: 0;
							padding-bottom: 20px;
						}
					}
				}
				.row {
					margin-top: 20px;
				}
				.row .col {
					display: flex;
					flex-direction: column;
					padding-bottom: 20px;
					min-width: 135px;
					align-items: center;
					flex-basis: 25%;
					img {
						height: 35px;
					}
					.desc {
						@include interstate(regular);
						margin-top: 20px;
						max-width: 135px;
					}
				}
			}
			&.section4 {
				.image-bg .caption {
					position: relative;
					@include media-breakpoint-down(sm) {
						width: auto;
						margin: 20px;
					}
					h2,
					.text {
						color: $dark-blue3;
						position: relative;
					}
					&:before {
						opacity: 0.82;
						background: $white;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						content: '';
						z-index: 0;
						filter: blur(10px);
					}
				}
			}
			&.section5 {
				&.dark {
					.image-bg {
						background-position: top;
					}
				}
			}
			&.section6 {
				.image-bg .caption {
					@include media-breakpoint-up(sm) {
						@include make-col(12);
					}
					@include media-breakpoint-up(md) {
						@include make-col(10);
					}
					@include media-breakpoint-up(md) {
						padding: 0;
					}
					h2,
					.text {
						color: $white;
					}
				}
			}
			.image-bg {
				width: 100%;
				background-size: cover;
				background-position: center;
				@include media-breakpoint-up(sm) {
					@include make-row;
					background-image: none;
				}
				@include media-breakpoint-up(sm) {
					justify-content: center !important;
					align-items: center !important;
					padding: 50px 0;
					//img {
					//    display: none;
					//}
				}
				.caption {
					@include make-col-ready;
					padding: 20px;
					background: none;
					color: $white;
					text-align: center;
					/*@include media-breakpoint-up(sm) {
                        background: rgba(0, 0, 0, .6);
                        color: $white;
                    }*/
					@include media-breakpoint-up(xs) {
						.trans {
							color: $md-gray !important;
							background-color: $white;
							border-color: $md-gray !important;
						}
					}
					@include media-breakpoint-up(sm) {
						.trans {
							color: $white !important;
							background-color: rgba(0, 0, 0, 0.6);
							border-color: $white;
						}
					}
					@include make-col(12);
					@include media-breakpoint-up(sm) {
						@include make-col(10);
					}
					@include media-breakpoint-up(md) {
						@include make-col(8);
					}
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}
					h2 {
						font-size: 2.2em;
						@include media-breakpoint-up(md) {
							font-size: 2.6em;
							line-height: 46px;
						}
						color: $white;
						padding-top: 20px;
						@include general-breakpoint(landscape) {
							font-size: 2.8em;
						}
					}
					.logos {
						margin: 20px auto;
						@include general-breakpoint(landscape) {
							margin-top: 50px;
						}
						.divider {
							margin: 0 15px;
							border-left: 2px solid white;
							height: 60px;
							display: inline-block;
							vertical-align: middle;
						}
					}
					.text {
						@include interstate();
						font-size: 1.4rem;
						width: 100%;
						padding-top: 10px;
						display: inline-block;
						color: $white;
						@include make-col-ready;
						@include media-breakpoint-up(sm) {
							@include make-col(12);
						}
						//@include general-breakpoint(landscape) {
						//    font-size: 1.4rem;
						//}
						@include general-breakpoint(tablet) {
							width: 80%;
						}
						p {
							padding-top: 0;
							line-height: 1.5em;
							// color: $dk-gray2;
							// @include media-breakpoint-up(sm) {
							//     color: $white;
							// }
						}
						ul {
							color: $dk-gray2;
							padding: 0 15px;
							@include media-breakpoint-up(sm) {
								color: $white;
								padding: 0 30px;
							}
						}
					}
					.buttons {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
					}
					.uso-button {
						display: block;
						position: relative;
						margin: 10px 20px;
						&.blue {
							background: #006fcf;
							border-radius: 4px;
							font-size: 16px;
							color: #ffffff;
							letter-spacing: 0.5px;
							text-align: center;
							min-width: 180px;
							max-width: 300px;
							width: auto;
							height: auto;
							line-height: normal;
							text-transform: none;
							padding: 15px 10px;
							border: 2px solid transparent;
							&:hover {
								background-color: #fff;
								color: #418fde !important;
								border: 2px solid $usoLtBlueBG;
							}
						}
						/*@include general-breakpoint(landscape) {
                            display: inline-block;
                            float: right;
                            margin: 10px 20px;
                        }
                        @include general-breakpoint(tablet) {
                            margin-right: 50px;
                        }*/
					}
					.chips {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						max-width: 1280px;
						margin: auto;
						.chip {
							width: 384px;
							height: 384px;
							margin: 8px 0;
							@include general-breakpoint(mobile, min) {
								width: 288px;
								height: 288px;
								margin: 8px;
							}
							@include general-breakpoint(smtablet, min) {
								width: 320px;
								height: 320px;
								margin: 6px;
							}
							@include general-breakpoint(smdesktop, min) {
								width: 288px;
								height: 288px;
								margin: 12px;
							}
							@include general-breakpoint(desktop, min) {
								width: 384px;
								height: 384px;
								margin: 12px;
							}
							background: #ffffff;
							color: #4a4a4a;
							text-align: center;
							.photoContainer {
								position: relative;
								height: 30%;
								@include media-breakpoint-up(xl) {
									height: 40%;
								}
								> div {
									position: absolute;
									@include media-breakpoint-down(sm) {
										top: 0;
										display: flex;
										flex-direction: column;
										justify-content: flex-end;
									}
									bottom: 0;
									width: 100%;
									text-align: center;
									img {
										height: auto;
										width: auto;
										max-width: 300px;
										@include media-breakpoint-down(sm) {
											margin: 0 auto;
											flex-shrink: 0;
											-webkit-flex-shrink: 0;
											max-height: 100%;
										}
									}
								}
							}
							h3 {
								@include interstate(bold);
								color: $dark-blue3;
								font-size: 1.5rem;
								@include media-breakpoint-down(sm) {
									padding-top: 15px;
								}
								@include media-breakpoint-up(xl) {
									font-size: 1.8rem;
								}
							}
							.description {
								@include interstate();
								margin: 10px;
								font-size: 1rem;
								@include media-breakpoint-up(xl) {
									margin: 15px 20px;
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
	}
	+ .uso-media {
		&.photo {
			margin: -35px 0 0 0;
			@include media-breakpoint-up(sm) {
				margin: -100px 0 0 0;
			}
		}
		&.video {
			margin: 0;
			@include media-breakpoint-up(sm) {
				margin: -50px 0 0 0;
			}
		}
	}
	&.wi-fi {
		h2 {
			display: inline-block;
			background-position: right;
			background-image: url('/images/pics/misc/hdr-contactless.png');
			background-repeat: no-repeat;
			padding-right: 45px;
			padding-left: 45px;
			padding-top: 0 !important;
		}
	}
}
.amex {
	.hash-highlight {
		color: $amex-text-md;
	}

	.uso-media {
		width: 100%;
		img {
			width: 100%;
		}
		margin-bottom: 0;
		background-position: top;
		@include media-breakpoint-up(xl) {
			background-position: bottom;
		}
		background-size: cover;
		@include media-breakpoint-up(xl) {
			margin: 0;
		}
		.media-col {
			margin: 0 0 15px 0;
			@include make-col(12);
			@include media-breakpoint-up(sm) {
				@include make-col(10);
			}
			@include media-breakpoint-up(md) {
				@include make-col(8);
			}
			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}
			@include media-breakpoint-up(xl) {
				margin: 0;
				padding: 180px 0;
			}
		}
		&.video {
			+ .amex-section {
				.image-bg {
					padding-top: 0;
					background-position: top;
					@include media-breakpoint-up(xl) {
						.caption {
							margin-top: -150px;
						}
					}
				}
			}
		}
		&.photo {
			.media-bg {
				width: 100%;
				.caption {
					display: none;
				}
			}
			+ .amex-section {
				.image-bg {
					padding-top: 0;
					background-position: top;
					.caption {
						margin-top: -50px;
						@include media-breakpoint-up(md) {
							margin-top: -100px;
						}
						@include media-breakpoint-up(lg) {
							margin-top: -200px;
						}
						@include media-breakpoint-up(xl) {
							margin-top: -300px;
						}
					}
				}
			}
		}
	}
	.amex-section {
		&.overlay {
			.amex-content {
				color: $amex-text !important;
				&.dark {
					.image-bg {
						background-color: $amex-bg;
						h1,
						h2 {
							color: $amex-text !important;
						}
						.text {
							color: $amex-text !important;
						}
					}
				}
				&.section1 {
					position: relative;
					padding: 0;
					@include media-breakpoint-down(sm) {
						padding-bottom: 125%;
					}
					@include media-breakpoint-down(xs) {
						padding-bottom: 125%;
					}
					.image-bg {
						background-position: bottom;
						background-repeat: no-repeat;
						@include media-breakpoint-down(sm) {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							background-image: url('https://photo-assets-pre.usopen.org/images/pics/misc/US_Open_AMEX_header_image_2x.jpg') !important;
							background-position: center;
						}
						.caption {
							@include media-breakpoint-up(lg) {
								@include make-col(8);
							}
							h1 {
								font-size: 6rem;
								line-height: 6rem;
								padding: 10px;
								color: $amex-text-dk;
							}
							@include media-breakpoint-down(lg) {
								font-size: 5rem;
								line-height: 5rem;
								padding: 10px;
							}
							@include media-breakpoint-down(sm) {
								h1 {
									font-size: 4rem;
									line-height: 4rem;
									padding: 33px;
								}
								.logos {
									img {
										width: 220px;
									}
								}
							}
							@include media-breakpoint-down(xs) {
								h1 {
									font-size: 3.7rem;
									line-height: 3.7rem;
									padding: 15px;
								}
							}
							.text {
								width: 75%;
								@include media-breakpoint-down(lg) {
									width: 95%;
								}
								@include media-breakpoint-down(sm) {
									width: 100%;
								}
							}
						}
					}
				}
				&.section2 {
					.image-bg {
						.caption {
							h2,
							.text {
								color: $amex-text-dk;
							}
						}
					}
				}
				// section end
				&.section6 {
					background-color: $amex-bg-lt;
					color: $amex-text-dk;

					.image-bg {
						padding: 0 0 185px 0 !important;
						background-position: bottom right !important;
						background-size: 100% auto !important;
						background-repeat: no-repeat;
						padding: 0 0 75px 0 !important;
						@include media-breakpoint-down(xs) {
							padding: 0 0 75px 0 !important;
						}
						@include media-breakpoint-down(sm) {
							padding: 0 0 120px 0 !important;
							background-image: url('https://photo-assets-pre.usopen.org/images/pics/misc/US_Open_AMEX_Chips_Background_2x.jpg') !important;
						}
						@include media-breakpoint-down(md) {
							padding: 0 0 125px 0 !important;
						}
						@include media-breakpoint-down(lg) {
							padding: 0 0 160px 0 !important;
						}
						@include media-breakpoint-down(xl) {
							padding: 0 0 225px 0 !important;
						}
						@include media-breakpoint-up(xxl) {
							padding: 0 0 275px 0 !important;
						}
						.caption {
							color: $amex-text-dk;
							h2 {
								color: $amex-text-dk;
								padding-top: 50px;
								padding-bottom: 10px;
								width: 65%;
								margin: auto;
								font-size: 3.75rem;
								line-height: 3.75rem;
								@include media-breakpoint-down(sm) {
									padding-top: 65px;
									width: 90%;
									font-size: 2.8rem;
									line-height: 2.8rem;
								}
								&:empty {
									display: none;
								}
							}
							.text {
								font-size: 1.2rem !important;
								color: $amex-text-gr !important;
								padding: 0 0 50px 0;
								@include media-breakpoint-down(sm) {
									padding: 30px 0;
								}
							}
						}
						&.mobile-bg {
							@include media-breakpoint-down(xs) {
								background-image: none !important;
								background-color: transparent;
							}
							@include media-breakpoint-down(sm) {
								background-image: none !important;
								background-color: transparent;
							}
						}
						.chips {
							.chip {
								a {
									color: #4a4a4a;
									&:hover {
										text-decoration: none;
										span {
											text-decoration: underline;
										}
									}
								}
								box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.14);
								border: none;
								margin: 6px 0;
								width: 310px;
								@include general-breakpoint(mobile, min) {
									margin: 6px;
								}
								@include general-breakpoint(smtablet, min) {
									width: 270px;
									margin: 6px;
								}
								@include general-breakpoint(smdesktop, min) {
									// height: 270px;
									margin: 8px;
								}
								@include general-breakpoint(desktop, min) {
									width: 270px;
									height: 292px;
									margin: 8px;
								}
								.photoContainer {
									height: 25%;
								}
								h3 {
									@include interstate(condensedbold);
									font-size: 1.7rem;
									color: $amex-text-md;
									padding-left: 20px;
									padding-right: 20px;
								}
								.description {
									font-size: 1.15rem;
								}
							}
						}
					}
				}
				&.section4 {
					.image-bg .caption {
						h2,
						.text {
							color: $amex-text-dk;
						}
					}
				}
				&.section5 {
					position: relative;
					@include media-breakpoint-down(xs) {
						padding-bottom: 115%;
						.image-bg {
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							right: 0;
							.caption {
								display: flex;
								display: flex;
								flex-flow: column;
								height: 100%;
								div {
									flex: 1 1 auto;
								}
								.text {
									color: $amex-text-gr;
									display: none;
									p {
										&:empty {
											display: none;
										}
									}
								}
								.buttons {
									margin-top: 40px;
								}
								.logos {
									img {
										width: 316px;
										margin-top: 30px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.siteDetailContainer {
		margin: 0;
		padding: 0;
		.siteDetail {
			margin: 0;
			padding: 0;
			flex-basis: 100%;
			max-width: 100%;
			.photo-inline {
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			.siteDetail {
				height: 0;
				width: 100%;
				padding-bottom: 100%;
				position: relative;
				overflow: hidden;
				.photo-inline {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					align-items: center;
					justify-content: center;
					display: flex;
					img {
						width: auto;
						height: 100%;
						margin-left: 28%;
					}
				}
			}
		}
	}
}
.bg-wrapper {
	background-position: top;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: $dark-blue3;
	@include media-breakpoint-up(lg) {
		background-size: cover;
	}
}
