/** player-image styles **/
img {
    &.player-image {
	    &.regular {
            width: 100%;
            height: 100%;
        }
        &.small {
            width: 56px;
            height: 70px;
        }
        &.xsmall {
            width: 48px;
            height: 70px;
        }
        &.xxsmall {
            width: 35px;
            height: 35px;
        }
    }
}