// WARNING: Do not directly edit this file !!!!
// File generated by gulp styles:colors task

$color-black: #000000;
$color-gray-dark: #333333;
$color-gray: #999999;
$color-grey: #CCCCCC;
$color-grey-light: #F7F7F7;
$color-white: #FFFFFF;
$color-yellow: #F4F19C;
$color-orange: #E69840;
$color-pink: #D877A0;
$color-red: #A82C40;
$color-purple: #7A7CEF;
$color-blue: #4F82BA;
$color-green: #659A3F;
$color-green-light: #B2BB50;


$color: (
	"black": $color-black,
	"gray-dark": $color-gray-dark,
	"gray": $color-gray,
	"grey": $color-grey,
	"grey-light": $color-grey-light,
	"white": $color-white,
	"yellow": $color-yellow,
	"orange": $color-orange,
	"pink": $color-pink,
	"red": $color-red,
	"purple": $color-purple,
	"blue": $color-blue,
	"green": $color-green,
	"green-light": $color-green-light
);


@each $clr-name, $clr-code in $color {
	.#{$clr-name} { color: $clr-code; }
	.bg-#{$clr-name} { background-color: $clr-code; }
}