#powerindex-leaderboard-wrapper {
    .webview & {
        background-color: $white;
    }
    
    .helper {
        &-wrapper {
            font-size: px2rem(16);
            text-align: center;
            width:100%;
            color:$innovations-text;
            margin-top:38px;
            margin-bottom: 20px;

            &.top {
                // height:40px;
                //border-bottom: solid 1px $usoLtGray;
                margin-top: 0px;
                margin-bottom: 0px;
                padding: 10px 10px 0 10px;
                line-height: 16px;
            }
            &.middle {
                height:50px;
                //border-bottom: solid 1px $usoLtGray;
                margin-top: 0px;
                margin-bottom: 0px;
                line-height: 50px;
            }

            // .webview & {
            //     display:none;
            // }
        }

        &-title {
            //@include interstate(bold);
            font-size: px2rem(14);
            text-transform: uppercase;
        }
        &-text {
            //@include interstate();
            font-size: px2rem(13);
            margin-bottom:16px;
            @include interstate(light);
        }
        &-action {
            height:32px;
            line-height: 32px;
            padding:0px 25px;
            display:inline-block;
            // border: solid 1px #888B8D;
            background-color: $usoLtBlueBG;
            font-size:px2rem(12);
            text-transform: uppercase;
            a {
                color:$white;
                text-decoration: none;
                display:block;
            }
            .webview & {
                display:none;
            }
            
        }
    }

    @include general-breakpoint(smtablet) {
        .helper {
            &-wrapper {
                padding:0px 8%;
                margin-top:40px;
                &.top {
                    // height:62px;
                    // line-height: 62px;
                    padding-top: 20px;
                }
            }
            &-text {
                font-size:px2rem(16);
            }
            &-title {
                font-size:px2rem(18);
            }
        }
    }
}
#powerindex-leaderboard {
    //font-size:16px;
    @include interstate();
    &.content-main {
        background:#F0F2F3;

        .column-layout {
            background-color: unset;
        }
    }
    
    section {
        &.pi-header {
            background-color: $usoDkerBlue2;
            border-bottom:solid 1px #979797
        }
    }

    .radio-wrapper {
        display:flex;
        flex-direction:column;
        justify-content: center;
        text-align: center;
        width:100%;
        color:$innovations-text;
        @include interstate(light);
        margin-top: 16px;

        .radio-group {
            color:$innovations-text;
        }
    }

    .update-date {
        //@include interstate(light);
        div {display:inline;}
        &.long  {display:block;}
    }

    .header-row {
        display: flex;
        position: relative;
        padding: 10px;

        .favorites-toggle {
            color: $white;
            &:before {
                font-size: px2rem(18);
            }
        }
    }

    .data-tabs {
        padding-top: 20px;
        height:60px;
        margin-top:0px;

        .tab {
            padding-left:0px;
            padding-right:0px;
            @include data-tab-width(2, 240px)
        }
        &.secondary {
            .tab {
                @include data-tab-width(2, 302px)
            }
        }
    }

    .player-search {
        position:absolute;
        max-width: 305px;
        right: 10px;
        .searchInputContainer{
            display: flex;
            align-items: center;
            border-radius: 20px;
            input.player-search {
                margin-left: 10px;
                background: #F3F3F3;
                border: none; border-radius: 4px; display: inline-block; height: 26px; font-size: 12px;
                padding: 0px 15px;
                @include general-breakpoint(tablet, max){ width: auto; padding: 0px 5px;}
            }
    
            // removes IE's x icon
            input.player-search::-ms-clear {
                display: none;
            }
    
            .searchIcon{
                display: inline-block; cursor: pointer; position: relative; color: $gray-dark;
                i { font-size: 32px; padding-left: 7px; color: $white;}
                .inline { display: inline-block; }
            }
        }

        /*
        // .search-box {
        //     flex: 0 1 85%;
        //     margin: auto;
        //     width:100%;
        //     input {
        //         padding-right: 40px;
        //         border-radius: 25px;
        //         background: transparent;
        //         color: #D8D8D8;
        //         &::placeholder {
        //             color: #D8D8D8;
        //         }
        //     }
        //     i {
        //         color: #D8D8D8;
        //     }
        //     .wim-search-results {
        //         background: white;
        //         z-index: 10;
        //         position: relative;
        //         max-height: 300px;
        //         overflow: auto;
        //         border-radius: 10px;
        //         .result-article-title {
        //             color: purple;
        //             padding: 10px;
        //             cursor: pointer;
        //             &.highlight {
        //                 background: $gray-light;
        //             }
        //             span {
        //                 font-weight: 100;
        //                 &.bold-text {
        //                     font-weight: bold;
        //                 }
        //             }
        //         }
        //     }
        // }
        */
    }
    
    &.content-main .column-layout .favorites-toggle {
        position:absolute;
        top:auto;
        bottom:55px;
        right:0px;
        left:auto;
        cursor: pointer;
        &::before {
            position: relative;
            right:auto;
        }

        .favorite-label {
            display:none;
        }
    }

    .leaderboard-table {
        display:flex;
        flex-direction: column;
        background-color: $white;
        width:100%;
        margin-bottom: 10px;

        @include general-breakpoint(smtablet) {
            margin: 0 20px 20px 20px;
        }
        .leaderboard-row, .leaderboard-header, .leaderboard-subheader {
            display:flex;
            flex-direction: row;
            justify-content: space-between;
            font-size:px2rem(14);
            @include interstate(regular);
            height: 44px;
            border-bottom:solid 1px $powerrank-table-border;


            .leaderboard-cell {
                text-align: left;
                display:flex;
                align-items: center;
                padding: 10px 5px;

                .icon-twistie-close {
                    color: #00288C;
                    //height:5px;
                    font-size: 6px;
                    position: relative;
                    top:-4px;
                    cursor:pointer;
                }
                .icon-twistie-open {
                    color: #418FDE;
                    //height:5px;
                    font-size:6px;
                    top:-4px;
                    cursor:pointer;
                }
                &.expand {
                    width:100%; max-width:32px; 
                    padding-left:8px;
                }

                &.powerrank {
                    width:100%; 
                    max-width:68px;
                    padding-left:4px;
                }
                &.tourrank{width:100%; max-width:62px;}
                &.playername{
                    width:100%;
                    overflow: hidden;
                    //color:green;
                    a {
                        &:link {color:$innovations-text;}
                        &:visited {color: $innovations-text;}
                        &:hover {color: #0056b3;}

                    }
                    img {
                        margin-right:5px;
                        width:17px;
                    }
                    .shortname {
                        display:block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .longname {
                        display:none;
                    }
                }
                &.country{
                    display:none;
                    width:100%;
                    img {margin-right:9px;}
                }
                &.aidraw {
                    width:100%; 
                    max-width: 75px;
                    text-transform: capitalize;
                    // font-size: px2rem(11);
                    // font-weight: bold;
    
                    &:not(.no-bullet) span {
                        &:before {
                            content: "\2022";
                        }
                    }
                }
                &.star{
                    width:100%; 
                    max-width:29px; 
                    padding-right:8px;
                    a {cursor: pointer;}
                }

                @include general-breakpoint(smtablet) {
                    &.expand {max-width:40px}
                    &.powerrank {
                        max-width:80px;
                        // font-size: px2rem(14);

                        .powerrank-movement {
                            .rank {
                                font-size: px2rem(14);
                            }
                        }
                    }
                    &.tourrank {
                        // font-size:px2rem(14);
                        max-width:80px;
                    }
                    &.playername {
                        .shortname {display:none;}
                        .longname {
                            @include interstate(bold);
                            font-size: px2rem(14);
                            display:block;
                        }
                        img {display:none;}
                    }
                    &.country {display:block}
                    &.aidraw {
                        // font-size:px2rem(16);
                        max-width: 150px;
                    }
                }
                @include general-breakpoint(smdesktop) {
                    //font-size: px2rem(18);
                    // &.tourrank {font-size: px2rem(14);}
                    &.aidraw {
                        max-width: 120px;
                    }
                }


                &.difficult, .difficult {
                    @extend .difficult-color;
                }

                &.favorable, .favorable {
                    @extend .favorable-color;
                }

                &.neutral, .neutral {
                    @extend .neutral-color;
                }
            }
        }
        .leaderboard-header {
            background-color: $usoLtBlueBG;
            color:$white;
            @include interstate(regular);
            font-weight: normal;
            font-size: px2rem(11);
            // height:35px;
            text-transform: uppercase;
            align-items: center;
            
            .leaderboard-cell {
                line-height:13px;
                // border-right: solid 1px $white;
                padding: 8px 5px;
                &.powerrank {padding-right:20px; cursor: pointer;}
                &.tourrank {padding-right:16px; cursor: pointer;}
                &.playername {
                    padding-right:8px;
                    line-height:26px;
                    cursor: pointer;
                }
                &.country {
                    cursor: pointer; 
                    border-right: none; 
                    line-height:26px;
                }
                &.aidraw {
                    // font-size: px2rem(9);
                    text-transform: uppercase;
                    // line-height:26px;
                    cursor: pointer;
                    &:not(.no-bullet) span,  span {
                        &:before {
                            content: unset;
                        }
                    }
                }
                &.sort {
                    font-weight: unset;
                    span {
                        position: relative;
                        // border-bottom:solid 1px #4A4A4A;
                        &::after {
                            content: "";
                            display: block;
                            padding-top: 5px;
                            position: absolute;
                            width: 100%;
                            border-bottom: solid 3px $white;
                        }
                    }
                }
                &.expand { border-right: none;}
            }
            @include general-breakpoint(smtablet) {
                .leaderboard-cell{
                    font-size:px2rem(12);
                    // &.powerrank{font-size: 12px;}
                    // &.tourrank{font-size:12px}
                }
            }
        }
        .leaderboard-subheader {
            display: none; // no favorites to display, hide the sub header
            background-color: #f2f2f2;
            color:$black;
            @include interstate(bold);
            font-size:px2rem(14);
            line-height: 44px;
            padding-left:8px;

            @include general-breakpoint(smtablet) {
                font-size:px2rem(16);
                padding-left:42px;
            }
        }
        .leaderboard-row {
            align-items: center;
            cursor: pointer;
            &:hover {
                background-color: rgba(65, 143, 222, .15); 
            }
            .leaderboard-cell {
                // border-right: solid 1px $usoBkgGray;
                padding-left: 5px;
                &.tourrank {
                    justify-content: flex-end;
                    padding-right:20px;
                }
                &.country {
                    border-right: none;
                }
            }
        }
        .leaderboard-data {
            transition: max-height 0.3s;
            overflow: hidden;
            max-height: 0;
            padding: 0px 10px;
            &.expand {
                max-height: 1200px;
                border-bottom:solid 1px $powerrank-table-border;
            }
            .error-indicator {
                .page .wrapper .content-main & {
                    position: relative;
                    color: $black;
                    text-align: center;
                    @include interstate();
                    height:30px;
                    line-height: 30px;
                }
            }
        }
        .favorite {
            position: relative;
            //margin-right: 20px;
            top: -10px;
            
            &:after {
                //position: absolute;
                left: 0;
                content: "\2606";
                font-size: 16px;
            }
            &.favorited {
                &:after{
                    font-size: 16px;
                    content: "\2605";
                }
            }
        }
        .powerrank-movement {
            .rank {font-size:px2rem(14); min-width:25px;}
            i {margin-left:0px;}
        }
        .nextmatch-container {
            width:100%;
            //height:66px;
            color:$innovations-text;
            text-align: center;
            line-height: 20px;
            margin-top:20px;
            .player-match-status {
                @include interstate();
                font-size:px2rem(14);
            }
            .player-oponent {
                font-size:px2rem(14);
                display:flex;
                flex-direction:column;
                @include interstate();
                margin-bottom:16px;
                line-height: 16px;
                justify-content: center;
                .versus-text {
                    font-size: px2rem(10);
                    text-transform: uppercase;
                }
            }

            .generic-button_wrapper>.generic-button, a>.generic-button {
                font-size:px2rem(11);
            }
        }
        .player-img-container {
            // width:100%;
        }
    }

    .generic-button_wrapper {
        width:100%;
        max-width:305px;
        margin:0px auto;
        >.generic-button {
            width:100%;
        }
    }

    .powerrank-movement { 
        .movement {
            font-size: px2rem(14);
            i {
                display:flex;
                position: relative;
                top: 1px;
            }
        }
    }



    button, div.button {
        margin: 0px auto;
        height:32px;
        line-height:32px;
        border:none;
        padding:0px 25px;
        text-transform: uppercase;
        cursor: pointer;
        a {
            text-decoration: none;
            color:#888B8D;
            display:block;
        }

        &.solid {
            font-size:px2rem(12);
        }

        &.blue {
            background-color: $usoLtBlueBG;
            color:#FFFFFF;
            a {
                color: #FFFFFF;

                &:hover {

                    color: #418FDE;
                }
            }
        
            &:hover{
                background-color: #fff;
                border: 2px solid $usoLtBlueBG;
                text-decoration: none;
                color: #418FDE;

                a {
                    color: #418FDE;
                }
            }

            .button-text-link {
                button {
                    color: $white;
        
                    &:hover{
                        background-color: transparent;
                        text-decoration: none;
                        color: #418FDE;
                    }
                }
            }
        }

        &.full {
            width: calc(100% - 20px);
            margin: auto;
            border-radius: 7px;
        }

        &.outline {
            background-color: transparent;
            border:solid 1px $powerrrank-border;
        }

        &.disabled {
            display:none;
        }
    }

    @include general-breakpoint(landscape) {

    }

    @include general-breakpoint(smtablet) {
        &.content-main .column-layout .favorites-toggle {
            top:auto;
            bottom:55px;
            font-size:px2rem(16);
            .favorite-label {
                display:inline;
                
            }
        }
        
        .player-search {
            // position: absolute;
            // right: calc((100% - 672px) / 2);
            // top: 45px;
            max-width: 235px;
        }
        .radio-wrapper {
            position: relative;
            flex-direction: row;
            .radio-group {
                order:1;
                
            }
        }

        .update-date {
            // order:0;
            //position: absolute;
            //left:20px;
            //top:auto;
            &.short {display:none;}
            &.long {display:block}
        }

        .powerrank-data-container button {
            line-height: 11px;
            font-size: px2rem(10);
            padding:0px 5px;
        }

        .leaderboard-table {
            .nextmatch-container {
                .player-oponent {
                    flex-direction: unset;
                    
                    .versus-text { 
                        padding-right: 10px;
                    }
                }

                &.mobile-show {
                    display: none;
                }
            }
        }
    
    }

    @include general-breakpoint(tablet) {
        .player-search {
            // right: calc((100% - 928px) / 2);
        }
    }

    @include general-breakpoint(smdesktop) {
        .player-search {
            // .search-box-container {
            //     flex: 0 0 260px;
            // }
        }
        .radio-wrapper {
            .radio-group {
                .radio-button {
                    .button-label {
                        font-size:px2rem(14)
                    }
                }
            }
        }
        .update-date {
            font-size: px2rem(14);
        }
    }

    @include general-breakpoint(desktop) {
        .player-search {
            right: calc((100% - 1216px) / 2)
        }
        
    }

    @include breakpoint(largeDesktop) {
        .site-wrap.expanded & {
            .player-search {
                // position: absolute;
                // right: 180px;
                // bottom: 10px;
            }
            &.content-main .column-layout .favorites-toggle {
                bottom:13px;
            }
        }
    }

    .webview & {
        padding:20px 0px;
    }
}
