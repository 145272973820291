/** table styles **/
.datatable { 
	width: 100%; 
	height: auto; 
    display: table;
    table-layout: fixed;
    border: solid 1px $md-gray2;
    background-color:$white;
    font-size: 12px;
    @include general-breakpoint(tablet) {
        font-size: 13px;
    }
    @include general-breakpoint(desktop) {
        font-size: 14px;
    }
    @include interstate(light);
    
    .title {
        display:table-caption;
        color:$dk-gray2;
        text-transform: capitalize;
        padding:7px 0 5px;
    }
    .headers {
        @include interstate(regular);
        background: $md-gray2;
        display: table-row; 
        width: 100%; 
        color: $dk-gray2; 
        font-size: 16px;
        font-weight: bold; 
        text-transform: uppercase;
        text-align: center;
        //height:58px;
        .cell {
            vertical-align: middle;
            padding:11px 5px;
        }

        &.mobileHide {
            display: none;
            @include general-breakpoint(desktop) {
                display: table-row;
            }
        }

        &.mobileShow {
            @include general-breakpoint(desktop) {
                display: none;
            }
        }
    }
    .row { 
        display: table-row; 

        &:nth-child(even) {
            background: $lt-gray4;
        }
    }
    .cell {
        display:table-cell;
        text-align: center;
        padding:5px;
        border-right: 1px solid $lt-gray2;

        &:last-child{ 
            border-right: none;
        }
    }
}
