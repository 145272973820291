/**** SOCIAL SHARE ***/
.socialShare {
    display: flex;
    .SocialMediaShareButton {
        margin-right: 10px;
    }
}

/* native content module */
.uso-ad-unit+.uso-native {
    margin-bottom: -1rem;
}

.uso-native+.uso-ad-unit {
    margin-top: -1rem;
}

.uso-native {
    min-height: 485px; margin-bottom: 20px; position: relative; background-color: $black;
    @include general-breakpoint(landscape){
        height: 700px; max-width: 700px; overflow: hidden;
    }

    .uso-native-container {
        width: 100%;
        height: 100%;

        .uso-native-content {
            position: relative;
            width: 100%;
            @include general-breakpoint(smtablet){ position: absolute;}

            .uso-native-image {
                background-size: 0 0; 
                height: 211px; position: relative;
                max-width: 100%;

                @include general-breakpoint(smtablet) {
                    height: 700px; width: 700px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                img {
                    width: initial;
                    max-width: 100%;
                    height: auto;

                    @include media-breakpoint-up(md) {
                        display: none;
                    }
                }

                .quote-overlay {
                    margin-top: -30px;
                    padding: 0 16px;
                    background-color: rgba(0, 0, 0, 0.75);
                    overflow: hidden;
                    position: relative;
                    z-index: 10;
                    left: 0;
                    color: $white;
                    // max-height: 344px;
                    // height: 284px;

                    @include general-breakpoint(smtablet) {
                        position: absolute;
                        max-height: 350px;
                        width: 400px;
                        bottom: 0;
                    }
                    @include general-breakpoint(desktop) {
                        width: 462px;
                    }

                    .quote{display: flex; flex-direction: row; flex-wrap: wrap;}

                    .pull-quote {
                        @include interstate(regular); font-size:14px; line-height: 22px; height: 51px; width: 100%; padding: 10px 0px;color: $white; text-transform: uppercase; overflow: hidden;display: block;

                        @include general-breakpoint(smtablet) {
                            padding: 20px 0px 10px;
                            font-size: 18px;
                            line-height: 26px;
                            height: 72px; 
                        }
                    }

                    .abstract-title {
                        @include interstate(light);
                        font-size: 28px;
                        line-height: 32px;
                        color: $white;
                        height: 115px;
                        overflow: hidden;
                        padding: 10px 0px;

                        @include general-breakpoint(smtablet) {
                            font-size: 44px;
                            line-height: 48px;    
                            height: 162px;
                        }
                    }

                    .abstract {
                       height: 70px; width: 100%; display: flex; align-items: center; justify-content: center;
                       @include general-breakpoint(smtablet){
                            justify-content: left;
                       }
                    }
                }

                .sponsor-overlay {
                    width: 100%;
                    height: 15%;
                    background-color: $black;
                    color: #fff;

                    @include media-breakpoint-up(md) {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 15%;
                        background-color: rgba(0, 0, 0, 0.75);
                    }

                    .sponsor {
                        // padding: 2vh;
                        padding: 0 2vh;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: left;

                        @include media-breakpoint-up(md) {
                            position: absolute;
                            right: 0;
                            display: inline flex;
                            width: 30%;
                            height: 100%;
                            justify-content: flex-end;
                            padding: 2vh;
                        }

                        img {
                            width: initial;
                            max-width: calc(100% - 4vh);
                            max-height: 100%;
                            height: auto;
                            display: inline-block;
                        }

                        .presented-by {
                            vertical-align: bottom;
                            margin-right: 15px;
                        }
                    }
                }

                &.lower-right {
                    .quote-overlay {
                        left: unset;
                        right: 0;
                    }

                    .sponsor-overlay {
                        .sponsor {
                            @include media-breakpoint-up(md) {
                                left: 0;
                                right: unset;
                                text-align: left;
                            }
                        }
                    }
                }

                &.upper-left {
                    @include media-breakpoint-up(md) {
                        .quote-overlay {
                            top: 0;
                            bottom: unset;
                        }

                        .sponsor-overlay {
                            @include media-breakpoint-up(md) {
                                top: 0;
                                bottom: unset;
                            }
                        }
                    }
                }

                &.upper-right {
                    .quote-overlay {
                        left: unset;
                        right: 0;
                        top: 0;
                        bottom: unset;
                    }

                    .sponsor-overlay {
                        @include media-breakpoint-up(md) {
                            top: 0;
                            bottom: unset;

                            .sponsor {
                                left: 0;
                                right: unset;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}