.colorWrapper{
    background-color: $lt_gray; 
}

#ot-sdk-btn-floating.ot-floating-button{
    z-index: 2147483645 !important;
}

/** new content wrapper to set title, sponsor, border top - higher component */
.homeContentWrapper {
    margin-bottom: 30px;
    position: relative;

    &.homeContent-border {
        border-top: solid 4px $usoLtBlue;

        &-dkblue {
            border-color: $usoDkBlue;
        }

        &-blue {
            border-color: $usoLtBlue;
        }

        &-ltblue {
            border-color: $usoLtBlue;
        }

        &-green {
            border-color: $usoGreen;
        }

        &-yellow {
            border-color: $usoYellow;
        }

        &-orange {
            border-color: $usoOrange;
        }

        &-red {
            border-color: $usoRed;
        }
    } // .homeContent-border

    .title-bar {
        display: flex;
        padding:10px 15px;
        position: relative;
        // box-shadow: 0px 8px 15px -18px $usoMdGray;
        border-bottom: solid 1px $usoltMedGray;
        background-color: $white;
       
        &.no-shadow {
            box-shadow: none;
        }

        h3 {
            align-self: center;
            color: $usoDkGray;
            text-transform: uppercase;
            @include interstate(bold);
            font-size: px2rem(16);
            line-height: 20px;
            margin-bottom: 0;
        }

        .sponsor, .rightCol {
            margin-left: auto;
            align-self: center;

            .label {
                color: $usoMdGray;
                display: block;
                font-size: px2rerm(10);
                &.noshow{ visibility: hidden;}
            }

            .logo {
                display: block;

                img {
                    border: 0;
                    height: 17px;
                    &.rolex{ height: 30px;}
                    &.amex {
						height: 30px;
						display: block;
						margin-left: auto;
						margin-right: 0;
					}
                }
            }

            a, a:hover {
                text-decoration: none;

                &.uppercase {
                    text-transform: uppercase;
                }
            }
        } // .sponsor

        .col {
            align-self: center;

            &.right {
                text-align: right;
            }
        }
    } // .title-bar

    .full-col {
        width: 100%;

        &.center {
            text-align: center;
        }
    }
} // .homeContentWrapper 

/** hero component with top stories list **/
.hero-stories-wrapper{
    
    .desktopWrapper{ //only visibile at smdesktop breakpoint
        padding-bottom: 20px;
        .desktopBorder{
            max-width: 1352px; margin: 35px auto; padding: 0px 37px;
        }
        .desktopStories{ visibility: hidden;
            @include general-breakpoint(smdesktop){
                visibility: visible; background-color: $white; display: flex; flex-direction: column; order: 3; width: 100%; padding: 17px;  border: 1px solid #E0E1E2; 
                a{ text-decoration: none;
                    &:hover{ text-decoration: none;}
                }
                .header{ @include interstate; color: $dk-gray2; padding-bottom: 15px; font-size: px2rem(24); line-height: px2rem(29); }
                .headlinesWrapper{ display: flex; flex-wrap: nowrap; flex-direction: row; justify-content: space-between;
                    .headlines{ @include interstate; font-size: px2rem(16); color: $md-gray; margin-left: 5px; padding-left:15px; width: 45%;
                        li{ padding-bottom: 15px; line-height: 20px;
                            a{ color: $md-gray; 
                                &:hover{ text-decoration: none; color: $bright-blue;}
                            }
                        }
                        &.left{ order: 1; flex-grow: 1; margin-right: 10px; }
                        &.right{ order: 2; flex-grow: 1;  margin-left: 10px;}
                    }
                }
                .allStories{ @include interstate(light); font-size: px2rem(16); line-height: 19px; color: $bright-blue; display: none;}
            }    
        }
    }
    
    .hero-stories{
        background-color: $white; display: flex; flex-wrap: wrap; justify-content: space-between; 
        @include general-breakpoint(smtablet){
            padding: 40px; background-color: $lt_gray; max-width: 1352px; margin: 0px auto; flex-wrap: nowrap;
        }
        @include general-breakpoint(desktop){ 
            padding: 25px 28px; 
        }

        .ad{ order: 2; width: 300px; height: 250px; margin: 10px auto; display: flex; justify-content: center; align-content: center;
            // background-color: $md_gray;
            @include general-breakpoint(smtablet){
                order: 2;max-width: 300px; width: 300px; min-width: 300px; height: 600px; flex-grow: 1; margin: 0px auto;
            }
        }

        .stories{ order: 1; display: flex; flex-wrap: wrap;
            @include general-breakpoint(smtablet){ flex-direction: column; flex-grow: 2; margin-right: 25px; }
            @include general-breakpoint(desktop){ flex-direction: row; flex-grow: 1; margin-right: 0px;}

            .mobileHero{ order: 1; width: 100vw; height: auto; position: relative; border-bottom: 1px solid #E0E1E2; padding-bottom: 15px;
                .storyImg{position: relative;
                    img{width: 100%; height: auto; }
                    .playBtn{ 
                        top: 50%; left: 55%; margin-top: -50px; margin-left: -50px; width: 75px; height: 75px; background-size: 75px;
                        &.hide{ display: none;}
                    }
                }
                .storySect{ position: relative; 
                    // position: absolute; bottom: 0px;
                    .title{ color: $usoDkGray; font-size: px2rem(18); line-height: px2rem(24); @include interstate; padding: 15px;
                        a{ color: $usoDkGray; text-decoration: none;}
                    }
                    .articleInfo, .desc{ display: none; }
                }
            }
            .mainHero{ background-color: $white;
                .storyImg{ position: relative;
                    img{width: 100%; height: auto;}
                    .playBtn{ 
                        width: 25px; height: 25px; background-size: 25px; margin-top: 28px; margin-left: 50px; top: 0; left: 0;
                        &.hide{ display: none;}
                    }
                }
                .storySect{ padding: 20px 25px;
                    color: $usoDkGray; 
                    .title{ @include interstate; color: $usoDkGray; font-size: px2rem(36); line-height: 40px; padding-bottom: 10px;
                        a{ color: $usoDkGray; text-decoration: none;
                            &:hover{ text-decoration: none;}
                        }
                    }
                    .articleInfo{ @include interstate; font-size: px2rem(12); line-height: px2rem(20); color: $usoDkGray;}
                    .desc{ display: flex; justify-content: space-between; align-items: center; margin-top: 30px; height: 110px; overflow: hidden;
                        .text{ width: 70%; @include interstate(light); font-size: px2rem(14); line-height: 20px;}
                        .btn{ background-color: $bright-blue; color: $white; @include interstate; font-size: px2rem(14); line-height: 16px; height: 30px; text-align: center;}
                    }
                }
                @include general-breakpoint(smtablet){
                    width: 100%; height: 311px; order: 1;
                    .storyImg{ 
                        img{min-width: 347px; width: 100%; height: 195px; object-fit: cover; }
                        .playBtn{ top: 50%; left: 55%; margin-top: -50px; margin-left: -50px; width: 75px; height: 75px; background-size: 75px; }
                    }
                    .storySect{
                        .title{ font-size: px2rem(20); line-height: px2rem(26); }
                        .desc{visibility: hidden;
                            .text{ font-size: px2rem(14); line-height: px2rem(20); vertical-align: top; height: 100%; }
                        }
                    }
                }
                @include general-breakpoint(smdesktop){
                    width: 595px; height: 600px; 
                    .storyImg img{ width: 100%; height: 363px;}
                    .storySect{
                        .title{ font-size: px2rem(28); line-height: px2rem(30); }
                        .article{font-size: px2rem(16); line-height: px2rem(20); margin-top: 15px; }
                        .desc{ visibility: visible;}
                    }
                }
                @include general-breakpoint(desktop){
                    margin: 0px 25px; order: 2; width: 555px; flex-grow: 2;
                    .storySect{
                        .title{ padding-bottom: 20px;}
                        .article{ margin-top: 0px; font-size: px2rem(14); line-height: px2rem(20);}
                        .desc{ visibility: visible;}
                    }
                }

                &.dark { /* color background option */
                    background-color: $dark-blue3;
                    .storySect { 
                            color: $white;
                        .title {
                            a {
                                color: $white;
                            }
                        }
                    }
                }
            }
            
            .topStories{ background-color: $white; display: flex; flex-direction: column; order: 2; padding: 23px 16px 0px;
                a{ text-decoration: none;
                    &:hover{ text-decoration: none;}
                }
                .header{ @include interstate; color: $dk-gray2; padding-bottom: 15px; font-size: px2rem(18); line-height: px2rem(21); }
                .content{ height: 100%; }
                .headlines{ flex-grow: 1; @include interstate; font-size: px2rem(14); color: $md-gray; margin-left: 15px; padding-left:5px; height: 100%; 
                    li{ padding-bottom: 10px; line-height: 20px;
                        a{ color: $md-gray; 
                            &:hover{ text-decoration: none; color: $bright-blue;}
                        }
                    }
                }
                .allStories{ @include interstate(light); font-size: px2rem(16); line-height: 19px; color: $bright-blue; display: none;}

                @include general-breakpoint(smtablet){
                    width: 100%; min-width: 347px; height: 268px; order: 2; margin-top: 22px; padding: 20px 22px;
                    .header{ font-size: px2rem(18); line-height: px2rem(21); }
                    .content{ overflow: hidden;}
                    .headlines{ margin-left: 15px;
                        li{ line-height: 20px; font-size: px2rem(14);}
                    }
                    .allStories{ visibility: hidden;}
                }

                @include general-breakpoint(desktop){
                    order:1; margin-top: 0px; padding: 25px 15px; min-width: 300px;width: 300px; max-width: 300px; height: 600px; flex-grow: 1;
                    .header{ font-size: px2rem(24); }
                    .headlines{
                        li{ font-size: px2rem(16);}
                    }
                    .allStories{ visibility: visible;}
                }
            }
        }
    }
}

/**** 1x1 column blocks ****/
.col-blocks-1{ display: flex; flex-direction: column; max-width: 1352px; margin: 0px auto; padding: 25px 18px;
    .headerRow{ 
        display: flex;  @include interstate(light); width: 100%; align-items: flex-end;
        
        .link{ font-size: px2rem(24); line-height: px2rem(29); flex-grow: 1; color: $bright-blue; text-align: right; display: none;
            a:hover{text-decoration: none; cursor: pointer;}
        }
        .title{font-size: px2rem(24); line-height: px2rem(29); flex-grow: 2; color: $dk-gray2;}
    }
    .col-content{ display: flex; flex-direction:row; flex-wrap: wrap; justify-content: space-between; width: 100%;
        .chip{background-color: $white; border: 1px solid $dk-gray;display: flex; flex-direction: row;width: 100%; height: 74px; overflow: hidden; margin: 10px 0px;
            a{width: 100%; display: flex; text-decoration: none; flex-direction: row;
                &:hover{ text-decoration: none;}
            }
            .captionContainer{
                background-color: $white; flex-grow: 2; padding: 10px; overflow: hidden;
                .caption{
                    text-align: left; width: 100%; color: $gray; @include interstate(regular); font-size: px2rem(14); line-height: px2rem(18); display: flex; justify-content: flex-start; flex-direction: column; height: 54px; overflow: hidden;
                    .photo-right {
                        text-align: right;
                    }
                }
                .author,.date{text-align: left; @include interstate(light); font-size: px2rem(12); line-height: px2rem(16); color: $gray; display: none;}
            }
            .photoContainer{ width: 128px; min-width: 128px; height: 74px; position: relative;
                img{height: 100%; width: 100%;}
                .playBtn{ width: 25px; height: 25px; background-size: 25px; margin-top: 28px; margin-left: 50px; top: 0; left: 0;}
            }
            &.photo-right {
                a {
                    flex-direction: row-reverse;
                    .captionContainer {
                        .caption {
                            display: inline-block;
                            text-align: right;
                        }
                        .author,.date{
                            text-align: right;
                        }
                    }
                }
            }
        }
    } 

    @include general-breakpoint(smtablet){
        .headerRow{
            .link{ display: inline-block; font-size: px2rem(18); line-height: px2rem(21); }
            .title{ font-size: px2rem(32); line-height: px2rem(39); }
        }
        .col-content{
            .chip{ width: 100%; height: 131px;
                .captionContainer{ padding: 10px 10px 10px 33px;
                    .caption{ font-size: px2rem(18); line-height: px2rem(28); flex-direction: row; justify-content: left; padding: 15px 0px; height: auto; }
                    .author,.date{ display: flex; height: auto;}
                }
                .photoContainer{ width: 232px; min-width: 232px; height: 131px; 
                    .playBtn{ width: 41px; height: 41px; background-size: 41px; margin-top: 20%; margin-left: 40%; top: 0; left: 0;}
                }
            }
        }
    }

    @include general-breakpoint(smdesktop){
        padding: 25px 28px;
        .col-content{ 
            .chip{ width: 100%; height: 163px;
                .captionContainer{
                    .caption{font-size: px2rem(24); line-height: px2rem(28); }
                    .author,.date{ font-size: px2rem(16); line-height: px2rem(20); }
                }
                .photoContainer{ width: 288px; min-width: 288px; height: 163px; }
            }
        }
    }

    @include general-breakpoint(desktop){
        padding: 25px 28px;
        .col-content{flex-wrap: nowrap; 
            .chip{ margin: 15px; width: 592px; height: 158px;
                &:nth-child(odd){ margin-left: 0px; align-self: flex-start;}
                &:nth-child(even){ margin-right: 0px; align-self: flex-end;}
                .captionContainer{ width: 312px; padding: 15px 32px 15px 15px; display: flex; flex-direction: row; flex-wrap: wrap; align-content: center;
                    // padding: 25px 32px 15px 15px;
                    .caption{
                        font-size: px2rem(24); line-height: 28px; align-content: center;
                    }
                    .author,.date{ display: inline-block;}
                }
                .photoContainer{width: 280px; min-width: 280px;}
            }
        }
    }
}

/**** 3 / 4 column blocks components ***/
.col-blocks-3, .col-blocks-4{
    display: flex; flex-direction: column; max-width: 1352px; margin: 0px auto; padding: 25px 18px;
    
    .mobileLink{font-size: px2rem(16); line-height: px2rem(19); flex-grow: 1; color: $bright-blue; text-align: center; @include interstate(light); padding-top: 30px;
        a:hover{text-decoration: none; cursor: pointer;}
    }
    
    .headerRow{ 
        display: flex;  @include interstate(light); width: 100%; align-items: flex-end;
        
        .link{ font-size: px2rem(24); line-height: px2rem(29); flex-grow: 1; color: $bright-blue; text-align: right; display: none;
            a:hover{text-decoration: none; cursor: pointer;}
        }
        .title{font-size: px2rem(24); line-height: px2rem(29); flex-grow: 2; color: $dk-gray2;}
    }
    .col-content{ width: 100%; padding-top: 25px 18px 28px; display: flex; flex-direction:row; flex-wrap: wrap;
        .chip{ background-color: $white; border: 1px solid $dk-gray; width: 100%; height: auto; overflow: hidden;margin: 10px 0px;
            a{ text-decoration: none;
                &:hover{ text-decoration: none;}
            }
            .photoContainer{ position: relative;
                img{height: 100%; width: 100%;}
                .playBtn{ top: 55%; left: 55%; }
            }
            .captionContainer{ padding-bottom: 20px; }
            .caption{
                text-align: center;color: $gray;@include interstate(regular);overflow: hidden;line-height: px2rem(24);font-size: px2rem(18); padding: 16px 16px 0px 16px; margin-bottom: 12px;
            }
            .author, .date, .credit, .description {
                text-align: center;color: $gray;@include interstate(light);padding-left: 16px;font-size: px2rem(14); line-height: px2rem(20);
            }
            .description {
                margin-top: -3px;
                font-size: .9rem;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                @include general-breakpoint(smdesktop){ font-size: 1.1rem; }
            }
            @include general-breakpoint(smtablet){
                .col-content{justify-content: space-around; align-items: center; justify-items: center; flex-wrap: wrap;
                    .chip{ width: 320px; height: 320px;}
                    .author, .caption, .date, .description{ text-align: left;}
                }
            }
        }
    }

    @include general-breakpoint(smtablet){
        padding: 25px 28px;
        .mobileLink{ display: none; }
        .headerRow{
            .link{ display: inline-block; font-size: px2rem(18); line-height: px2rem(21); }
            .title{ font-size: px2rem(32); line-height: px2rem(39); }
        }
        .col-content{
            justify-content: space-between; align-items: stretch; flex-wrap: nowrap;
        }
    }
    @include general-breakpoint(desktop){
        .col-content{
            .chip{
                &:first-child{ margin-left: 0px; }
                &:last-child{ margin-right: 0px;}
            
            }
        }
    }
}
.col-blocks-3{ 
    @include general-breakpoint(smtablet){ 
        .col-content{ justify-content: space-evenly; align-items: center; justify-items: center; flex-wrap: wrap;
            .chip{ width: 320px; height: 320px;}
        }
    }
    @include general-breakpoint(smdesktop){ 
        .col-content{
            .chip{ width: 384px; height: 384px;}
        }
    }
    @include general-breakpoint(desktop){padding: 35px 28px;
        .col-content{
            .chip{ margin: 10px; }
        }
    }
}
.col-blocks-4{
    @include general-breakpoint(smtablet){
        .col-content{ justify-content: space-evenly; align-items: center; justify-items: center; flex-wrap: wrap;
            .chip{ width: 320px; height: 305px; margin: 10px;}
        }
    }
    @include general-breakpoint(smdesktop){ 
        .col-content{justify-content: space-between;
            .chip{ width: 384px; height: 384px;}
        }
    }
    @include general-breakpoint(desktop){
        .col-content{ 
            .chip{ width: 280px; height: 305px; margin: 10px;}
        }
    }
}


/**** category blocks with 3 columns ****/
.category-blocks{ display: flex; flex-direction: column; flex-wrap:wrap; max-width: 1352px; margin: 0px auto; padding: 25px 18px;
    @include general-breakpoint(smtablet){ padding: 25px 28px;flex-direction: row; justify-content: space-evenly; align-items: stretch; align-content: stretch;}
    @include general-breakpoint(smdesktop){ justify-content: space-between;}

    // .category-row{ display: table-row;}
    .block{ width: 100%; height: 100%; margin: 10px 0px; 
        .content{
            ul{ list-style-type: none; padding-left: 0px; list-style-position: outside; margin-left: 20px;
                li{ @include interstate(light); font-size: px2rem(14); line-height: 16px; color:$gray;
                padding: 5px 0px; 
                //text-indent: 10px; 
                    &:before{ content: "-";
                        // text-indent: 15px;
                        padding-right: 10px;
                        margin-left: -15px;
                    }
                }
                a{ text-decoration: none;
                    li{
                        &:hover{ text-decoration: none; color: $bright-blue;}
                    }
                }
            }
        }
        .headerRow{ width: 100%; background-color: $gray-f3; color: $black; font-size: px2rem(18); line-height: 21px; @include interstate(light); padding: 10px; }
        .photoContainer{ position: relative; overflow: hidden;
            img{ width: 100%; height: 100%; }
            .subtitle{ position: absolute; bottom: 10px; color: $white; z-index: 10;margin: 10px; @include interstate(bold); font-size: px2rem(14); line-height: 16px;
            }
            // a .subtitle{
            //     &:hover{ text-decoration: none; color: $bright-blue;}
            // }
        }
        .more{ display: block; background-color: $lt-gray2; @include interstate(light); font-size: px2rem(14); line-height: px2rem(16); text-align: right; padding: 12px 23px; width: 100%;
            a{ text-decoration: none; }
        }

        @include general-breakpoint(smtablet){ 
            width: 320px;  margin: 10px;
        }

        @include general-breakpoint(smdesktop){
            width: 289px; margin: 10px;
            
        }
        @include general-breakpoint(desktop){
            width: 384px; margin: 0px;
        }
    }  
}


/** checker board content **/
.checkered{display: flex; flex-direction: row; flex-wrap: wrap; margin: 0px auto; padding: 25px 18px;
    .checkContent{ 
        display: flex; flex-direction: row; flex-wrap: wrap; width: 100%; border: 1px solid $lt_gray; margin: 10px 0px;
        &.left{  
            .photoContainer{ order: 1;}
            .captionContainer{ order: 2;}
        }
        &.right{ 
            .photoContainer{ order:1;}
            .captionContainer{ order:2;}
        }
        .photoContainer{ position: relative; width: 100%; height: auto; position: relative;
            img{ width: 100%; height: 100%;}
            .playBtn{ top: 50%; left: 50%; margin-top: -50px; margin-left: -50px; width: 75px; height: 75px; background-size: 75px; }
        }
        .captionContainer{ margin: 25px; overflow: hidden;
            a{ text-decoration: none;
                &:hover{ text-decoration: none; }
            }
            h2{ @include interstate; font-size: px2rem(18); line-height: px2rem(24); color: $md-gray;}
            .articleInfo{
                .date{ @include interstate(light); font-size: px2rem(16); line-height: px2rem(24); color: $md-gray;}
            }
            .desc{ text-align: center; display: none;
                .text{ text-align: left; @include interstate(light); font-size: px2rem(12); line-height: px2rem(16); color: $md-gray;}
                .btn{ background-color: $bright-blue; color: $white; @include interstate; font-size: px2rem(14); line-height: 16px; height: 30px; text-align: center;
                    a{ color: $white; text-decoration: none;
                        &:hover{ text-decoration: none; }
                    }
                }
            }
        }
    }

    @include general-breakpoint(smtablet){ padding: 25px 28px;
        .checkContent{ flex-wrap: nowrap;
            &.right{ 
                .photoContainer{ order:2;}
                .captionContainer{ order:1;}
            }
            .photoContainer{ width: 352px; min-width: 352px; height: 198px;}
            .captionContainer{ margin: 20px 25px 10px;
                h2{font-size: px2rem(18); line-height: px2rem(22); }
                .desc{ display: inline-block;
                    .text{ margin: 10px 0px 25px; font-size: px2rem(12); line-height: px2rem(16); height: 46px; overflow: hidden;}
                }
            }
        }
    }

    @include general-breakpoint(smdesktop){
        .checkContent{
            .photoContainer{ width: 528px; min-width: 528px; height: 297px; }
            .captionContainer{ margin: 25px;
                h2{font-size: px2rem(24); line-height: px2rem(28); }
                .desc{ 
                    .text{ font-size: px2rem(16); line-height: px2rem(24); overflow: unset; height: auto;}
                }
            }
        }
    }

    @include general-breakpoint(desktop){
        max-width:1352px; margin: 25px auto; padding:35px 28px;
        .checkContent{margin: 15px 0px;
            .photoContainer{ width: 624px; height: auto;}
            .captionContainer{margin: 25px 32px;
                h2{font-size: px2rem(24); line-height: px2rem(28); }
                .desc{
                    .text{font-size: px2rem(16); line-height: px2rem(24); color: $md-gray; margin: 25px 0px;}
                }
            }
        }
    }
}


/** on court today schedule component **/
.oncourt{ display: block; padding:20px 0px; margin: 0px auto; width: 100%; height: 238px; overflow: hidden; background-color: $white;
    
    @include general-breakpoint(smtablet){ padding:20px 22px 15px 22px; }

    .content{ display: block; width: 100%; height: 100%; border-top: 1px solid $usoLtGray; 

        @include general-breakpoint(smtablet){border-bottom: 1px solid $usoLtGray; height: 170px; }
        
        .match-carousel-container{
            width: 100%; height: 100%; margin: 0px auto;
            
            .match{
                .matchInfo{ @include interstate; padding: 15px 0px 13px;
                    .type, .court{
                        display: block; width: 100%; font-size: px2rem(14); line-height: px2rem(16); color: $usoDkGray; text-align: center; @include interstate(bold); 
                    }
                    
                }
                .playerInfo{ display: flex; flex-direction: row; justify-content: space-evenly; align-items: center; margin: 0px auto; height: 100px;
                    .vs{ height: auto; text-transform: uppercase; padding: 0px 2px 40px 2px; font-size: px2rem(14); line-height: px2rem(16); @include interstate(light); color: $usoMdGray;}
                    .player1, .player2{ @include interstate; font-size: px2rem(12); line-height: px2rem(14); color: $usoDkGray;  width: 135px; margin: 0px auto; text-align: center; text-overflow: ellipsis; overflow: hidden;
                        img{margin: 0px auto 10px; width: 50.6px; height: 69px;}
                        a{ text-decoration: none; cursor: pointer; color: $usoDkGray;
                            &:hover{ color: $usoLtBlue;}
                        }
                    }
                    .team1,.team2{
                        display: flex; width: 100%; flex-direction: row; justify-content: space-between; 
                    }
                    
                    &.doubles{ flex-direction: row; margin: 0px auto; width: 100%!important;
                       .team1, .team2{  flex-grow: 1; display: inline-block;
                            .images{ text-align: center; margin: 0px auto;
                                img{ width:45px; height: 61px; display: inline-block; margin: 0px 5px; }
                            }
                            .names{ text-align: center; @include interstate; font-size: px2rem(12); line-height: px2rem(14); color: $usoDkGray; 
                                a{ text-decoration: none; cursor: pointer; color: $usoDkGray;
                                    &:hover{ color: $usoLtBlue;}
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 20em){ 
                        &.doubles{
                            .team1, .team2{ 
                                .images{
                                    img{ width: 38px; height: auto; margin: 0px 3px; }
                                }
                            }
                        }
                    }
                    
                    @include general-breakpoint(smtablet){
                        &.doubles{
                            .team1, .team2{ 
                                .images{
                                    img{ width: 38px; height: auto; margin: 0px 3px; }
                                }
                            }
                        }
                    }

                    @include general-breakpoint(tablet){
                       &.doubles{ 
                            .team1, .team2{ 
                                .images{
                                    img{ width: 50.6px; height: 69px; margin: 0px 5px; }
                                }
                            }
                        }
                    }
                }
            } 
            
            .slick-slider{ position: relative; height: 100%; width: 100%;
                .slick-slide{ border-right: 1px solid $usoLtGray; 
                        &:last-of-type{ border-right: unset; }
                }
                .slick-list{ height: 100%;
                    .slick-track{ height: 100%; }
                    @include general-breakpoint(smtablet){ border-left: 1px solid $usoLtGray!important; border-right: 1px solid $usoLtGray!important;}
                }
            }
            .slick-next,.slick-prev {
                width:45px; height:100%; position: absolute; padding: 0px 3px; z-index: 10;
                &.slick-disabled{ visibility: hidden;}
            }
            .slick-next {
                right: 0px; text-align: right;
                // background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255, 255, 255, 1) 60%);
                background: linear-gradient(to left, #ffffff, rgba(#ffffff, 0));
                @include general-breakpoint(smtablet){ right: 1px;}
            }
            .slick-prev {
                left:0px; text-align: left;
                // background-image: linear-gradient(to left, rgba(255,0,0,0), rgba(255, 255, 255, 1) 60%);
                background: linear-gradient(to right, #ffffff, rgba(#ffffff, 0));
                @include general-breakpoint(smtablet){ left: 1px;}
            }

            .slick-next:before {
                @include uso-icon('Icons');
                content:"\e902";
                color: $usoMdGray;
                font-weight: bolder;
                font-size: 14px;
            }
            .slick-prev:before {
                @include uso-icon('Icons');
                content:"\e901";
                color:$usoMdGray;
                font-weight: bolder;
                font-size: 14px;
            }
            
            &.noSlider{ 
                .match{ margin: 10px auto;}
            }
        }
    }
    .header{ padding: 0px 20px 15px; display: flex; width: 100%;
        .link{ width: 50%; @include interstate; font-size: px2rem(14); line-height: px2rem(16); color: $usoDkGray; text-align: right;  
            a{ color: $usoDkGray; text-decoration: none;
                &:hover{ color: $usoLtBlue; }
            }
        }
        .title{ width: 50%; @include interstate(bold); font-size: px2rem(16); line-height: px2rem(19); color: $usoDkGray; text-transform: uppercase;
        }
        @include general-breakpoint(smtablet){
            padding: unset; padding-bottom: 15px;
        }
    }
}

.live-banner{ background-color: $dark-blue3; width: 100%; height: 100%;
    @include general-breakpoint(tablet){ height: 83px;}
    @include general-breakpoint(smdesktop) {display:none;}
    .liveWrapper{
        max-width:1352px; margin: 0px auto; padding: 25px 28px; text-align: center;
        .content{
            display: flex; flex-wrap: wrap; flex-direction: row; justify-content: center; align-content: center;
            a{ text-decoration: none; color: $white; display: flex; flex-wrap: wrap; flex-direction: row; justify-content: center; align-content: center;
                &:hover{ color: $bright-blue; 
                    .button{ color: $white;}
                }
            }
            .text{ font-weight: bold; font-size: px2rem(21); line-height: px2rem(25); color: $white; text-transform: uppercase; }
            .button{ background-color: $button-yellow; color: $dark-blue3; height: 19px; width: 52px; font-weight: bold; font-size: px2rem(9); line-height: px2rem(9); text-transform: uppercase; display: flex; justify-content: center; align-items: center; align-content: center; margin: 0px 10px;
            }
        }
    }
}



.hero-stories-wrapper {
    .live-banner {
        display:none;
        align-items: center;
    }
    @include general-breakpoint('smdesktop') {
        .live-banner {
            display:flex;
            height:48px;
        }
    }
    @include general-breakpoint('desktop') {
        .live-banner {
            height:52px;
            position: absolute;
            left:0px;
            bottom:0px;

            .liveWrapper {
                padding:0px;
                .content .text {
                    font-size: px2rem(16px);
                }
            }
        }
        .topStories {
            position:relative;
        }
    }
}

.newsletter-signup{
    margin-bottom: 20px; border-top: 4px solid $usoYellow; color: $white; width: 100%;
    .header{ height: auto; padding: 8px 12px; margin: 0px auto; background-color: $usoDkBlue;
        .title{ color: $white; @include interstate; font-size: px2rem(14); line-height: px2rem(16); text-transform: uppercase;
            span{font-weight: bold; display: block; }
        }
    }
    .content{
        background-color: $white; color: $usoDkGray; @include interstate; font-size: px2rem(12); line-height: px2rem(14); padding: 5px 12px; margin: 0px auto;height: 100%;
        .newsletter-button {
            @include button();
            margin: 0 auto;
            @include general-breakpoint(desktop){
                width: unset;
            }
        }
        .input-area{ color: $white; width: 100%; border-bottom: 1px solid $usoLtGray;
            input[type=text]{
                background: transparent;
                border: none;
                font-size: 1.2rem;
                color: $usoDkGray;
                margin-bottom: 5px;
                outline: none;
                width: 100%;
            }
        }
        .msg-area{ color: $usoRed; }
    }
    // @include general-breakpoint(landscape, max){ border-bottom: 4px solid $usoYellow; 

    //     .content{background-image: url('/assets/images/misc/bg_newsletter_signup.png'); color: $white; 
    //         .input-area{
    //             input[type=text]{ color: $white;}
    //         }
    //     }
    // }
    // @include general-breakpoint(desktop){ border-bottom: 4px solid $usoYellow; 
    //     .content{background-image: url('/assets/images/misc/bg_newsletter_signup.png'); color: $white; 
    //         .input-area{
    //             input[type=text]{ color: $white;}
    //         }
    //     }
    // }
}

.social-media{
    background-color: $white; width: 100%; min-height: 90px;padding: 14px 18px; 
    .header{ height: auto; margin: 0px auto; margin-bottom: 14px;
        .title{ @include interstate(bold); font-size: px2rem(14); line-height: px2rem(16); text-transform: capitalize; }
    }
    .content{ display: flex; flex-direction: row; justify-content: space-between; vertical-align: middle; align-items: center;
        .item{ cursor: pointer; vertical-align: middle;}
    }
}

.filmstrip{ width: 100%; height: auto;
    @include general-breakpoint(smtablet){ border: 1px solid $usoLtGray; }
    .lead{width: 100%; padding: 15px; background-color: $usoBkgGray;
        a{ color: inherit; text-decoration: none;
            &:hover{ text-decoration: none; color: $usoLtBlue!important;}
        }
        .photoContainer{ position: relative;
            .photoImg img{ width: 100%;}
            .playBtn{ width: 100px; height: 100px; background-size: 100px; left: 51%; top: 44%; }
        }
        .captionContainer{background-color: $white; border: 1px solid $usoLtGray; padding: 5px 18px; font-size: px2rem(14); line-height: px2rem(16);
            .caption{ @include interstate; color: $usoDkGray; height: unset;
                &:hover{ text-decoration: none; color: $usoLtBlue!important; cursor: pointer;}
                &.leadTitle{@include interstate(bold); font-size: px2rem(18); line-height: px2rem(21); padding-bottom: 10px;}
            }
            .description{ @include interstate(light);  
                div:hover{ text-decoration: none; color: $usoLtBlue!important; cursor: pointer;}
            }
        } 
        @include general-breakpoint(smtablet){ padding: 20px; }
    }
    .gallery{ width: 100%; height: 100%; margin: 0px auto; padding: 0px 15px; padding-bottom: 15px; background-color: $usoBkgGray; overflow: hidden;
        .slick-slider .slick-track, .slick-slider .slick-list{ 
            &.no-transform{
                transform: none!important;
            }
        }
        .galleryItem{ width: 157px; padding-right: 15px;
            a{text-decoration: none; color: inherit;
                &:hover{ text-decoration: none; color: $usoLtBlue!important;}
            }
            .photoContainer{
                position: relative;
                .photoImg img{ width: 100%; } 
                .playBtn{ width: 50px; height: 50px; background-size: 50px; transform: translate(-50%, -50%); margin-left: 0px; margin-top:0px;}
            }
            .captionContainer{  padding: 10px 0px 5px; font-size: px2rem(14); line-height: px2rem(16);
                .caption{ @include interstate; color: $usoDkGray; height: unset;
                    &:hover{ text-decoration: none; color: $usoLtBlue!important; cursor: pointer;}
                    a{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-clamp: 2;
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                    }
                }
            } 
        }  
        .slick-next {
            right: -5px; text-align: right;
            background-image: linear-gradient(to right, rgba(243,0,0,0), rgba(243,243,243,1) 60%);
            // @include general-breakpoint(smtablet){ right: -20px;}
        }
        .slick-prev {
            left:-5px; text-align: left;
            background-image: linear-gradient(to left, rgba(243,0,0,0), rgba(243,243,243,1) 60%);
            // @include general-breakpoint(smtablet){ left: -20px;}
        }
    
        @include general-breakpoint(smtablet){ padding: 0px 20px 20px; height: 200px;
            .galleryItem{ width: 263px;
                .photoContainer{
                    .playBtn{ width: 75px; height: 75px; background-size: 75px;}
                }
            }
        }
    }

    .subGallery{ background-color: $usoLtGray; padding: 10px 15px; width: 100%; 
        //  height: 95px;
        .galleryItem{ width: 100%; padding-right: 15px;
            // height: 100%;
            .displayWrapper{display: inline-flex; flex-direction: row; flex-wrap: nowrap; align-items: center; height: 72px!important; overflow: hidden!important;
                a{display: inline-flex; text-decoration: none; color: inherit;
                    &:hover{ text-decoration: none; color: $usoLtBlue; cursor: pointer;}
                }
            }
            .photoContainer{ width: 49%; display: inline-flex;
                .photoImg img{ width: 100%; height: 76px;}
                .playBtn{ top: 75%; left: 30%; width: 50px; height: 50px; background-size: 50px;}
            }
            .captionContainer{ 
                width: 50%; 
                background-color: $usoltMedGray; 
                display: inline-flex;
                font-size: px2rem(14); 
                line-height: px2rem(16); 
                padding: 5px;
                // height: 76px; 
                align-items: center;
                .caption{ 
                    @include interstate; 
                    color: $usoDkGray; 
                    // height: 100%;
                    &:hover{ text-decoration: none; 
                    color: $usoLtBlue!important;}
                    a{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-clamp: 4;
                        -webkit-line-clamp: 4;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        padding-top: 5px;
                    }
                }
            }
        }
        .slick-next {
            right: -5px; text-align: right;
            background-image: linear-gradient(to right, rgba(243,0,0,0), rgba(193, 198, 200, 1) 60%);
            // @include general-breakpoint(smtablet){ right: -20px;}
        }
        .slick-prev {
            left:-5px; text-align: left;
            background-image: linear-gradient(to left, rgba(243,0,0,0), rgba(193, 198, 200, 1) 60%);
            // @include general-breakpoint(smtablet){ left: -20px;}
        }
    
        @include general-breakpoint(smtablet){ padding: 10px 20px; height: 100px;
            .galleryItem{ padding: unset; padding-right: 10px; width: 276px;}
        }
    }


    .slick-slider{ position: relative; height: 100%; width: 100%;
        @include general-breakpoint(landscape){ width: 100%; }
        .slick-slide{ border-right: unset; }
        .slick-list{ 
            // @include general-breakpoint(smtablet){ border-left: 1px solid $usoLtGray!important; border-right: 1px solid $usoLtGray!important;}
        }
    }
    .slick-next,.slick-prev {
        width:65px; height:100%; position: absolute; padding: 3px 0px; z-index: 5;
        // background: $white; border: 0.5px solid $usoLtGray; top: 40%;
        &.slick-disabled{ visibility: hidden;}
    }
    
    .slick-next:before {
        @include uso-icon('Icons');
        content:"\e902";
        color: $usoDkGray;
        font-weight: bolder;
        font-size: 14px;
    }
    .slick-prev:before {
        @include uso-icon('Icons');
        content:"\e901";
        color:$usoDkGray;
        font-weight: bolder;
        font-size: 14px;
    }
    
}

.keypoints{ width: 100%; 
    @include general-breakpoint(smtablet){ border: 1px solid $usoLtGray; }
    .lead{width: 100%; padding: 20px 15px; background-color: $usoBkgGray;
        @include general-breakpoint(smtablet){ padding: 20px;}
        a{ color: inherit; text-decoration: none;
            &:hover{ text-decoration: none; color: inherit; cursor: pointer; color: $usoLtBlue;}
        }
        .photoImg img{ width: 100%;} 
        .captionContainer{background-color: $white; border: 1px solid $usoLtGray; padding: 5px 18px; font-size: px2rem(14); line-height: px2rem(16);
            .caption{ @include interstate; color: $usoDkGray;  height: unset;
                &:hover{ text-decoration: none; color: $usoLtBlue!important;}
                &.leadTitle{@include interstate(bold); font-size: px2rem(18); line-height: px2rem(21); padding-bottom: 10px;}
            }
            .description{ @include interstate(light);  }
        } 
    }
    .bulletList{background-color: $usoltMedGray; padding: 20px 18px 10px; width: 100%; display: block;
        .col1, .col2{ width: 100%; 
            ul{ 
                margin-bottom: 0px; 
                @include general-breakpoint(landscape, max){padding-inline-start: 20px;}
                &.no-bullets{ list-style: none; padding-left: 0px; margin-bottom: 0px; }
                li{ color: $usoDkGray; @include interstate; font-size: px2rem(14); line-height: px2rem(16); padding-bottom: 10px;
                    //line-height: px2rem(20); padding-bottom: 20px;
                    a{ color: inherit; text-decoration: none;
                        &:hover{ text-decoration: none; color: $usoLtBlue;}
                    }
                }
            }
        }
        @include general-breakpoint(smtablet){ display: inline-flex; 
            .col1,.col2{ width: 50%; 
               li{ padding-bottom: 10px; line-height: px2rem(14);}
            }
        }
    }

}

.storygroup{ width: 100%; height: auto; 
    @include general-breakpoint(smtablet){ border: 1px solid $usoLtGray; background-color: $usoBkgGray; }
    .lead{width: 100%; padding: 20px 15px;
        @include general-breakpoint(smtablet){ padding: 20px; }
        a{ color: inherit; text-decoration: none;
            &:hover{ text-decoration: none; color: $usoLtBlue!important;}
        }
        .photoContainer{ position: relative; overflow: hidden;
            .photoImg img{ width: 100%;} 
            .playBtn{ width: 100px; height: 100px; background-size: 100px; left: 51%; top: 44%;cursor: pointer; }
        }
        .captionContainer{background-color: $white; border: 1px solid $usoLtGray; padding: 5px 18px; font-size: px2rem(14); line-height: px2rem(16);
            .caption{ @include interstate; color: $usoDkGray; height: unset;
                &:hover{ text-decoration: none; color: $usoLtBlue!important;}
                &.leadTitle{@include interstate(bold); font-size: px2rem(18); line-height: px2rem(21); padding-bottom: 10px;}
            }
            .description{ @include interstate(light);  }
        } 
    }
    .more-stories{ width: 100%; height: 100%; margin: 0px auto; padding: 0px 16px; padding-bottom: 15px; 
        @include general-breakpoint(smtablet){ padding: 0px 20px;}
        
        .story{ height: inherit; width: 100%; margin-bottom: 10px;
            @include general-breakpoint(smtablet){ margin-bottom: 20px;}
            .displayWrapper{display: inline-flex; flex-direction: row; flex-wrap: nowrap; justify-content: stretch; width: 100%; position: relative; overflow: hidden !important;
                a{display: inline-flex; text-decoration: none; width: 100%; color: $usoDkGray;
                    &:hover{ text-decoration: none; color: $usoLtBlue;}
                }
            }
            .photoContainer{ display: block; position: relative;
                .photoImg img{ width: 114px; } 
                .playBtn{ width: 50px; height: 50px; background-size: 50px; transform: translate(-50%, -50%);  margin-left: 0px; margin-top: 0px; 
                    @include general-breakpoint(smtablet, max){ margin-top: -10px;}
                   }
            }
            .captionContainer{ width: inherit; padding: 1%; font-size: px2rem(14); line-height: px2rem(16); background-color: $white;  border: 1px solid $usoLtGray; max-height: 64px;
                @include general-breakpoint(smtablet){
                    padding: 10px; max-height: unset;
                }
                .caption{ @include interstate(bold);padding-bottom: 10px; overflow: hidden;height: unset;}
                .description{ display: none; }
            } 
        
            @include general-breakpoint(smtablet){ height: 125px;
                .displayWrapper{ height: 125px!important; overflow: hidden !important;}
                .photoContainer{
                    .photoImg img{ width: 222px; } 
                    .playBtn{ width: 75px; height: 75px; background-size: 75px;cursor: pointer;}
                }
                .captionContainer{padding: 10px 12px;
                    .description{@include interstate(light); display: inline;}
                }
            }
        }
    }
}

.single-feature{ width: 100%; height: auto; background-color: $usoBkgGray;
    @include general-breakpoint(smtablet){ border: 1px solid $usoLtGray; }
    .story{width: 100%; padding: 20px 15px;
        @include general-breakpoint(smtablet){ padding: 20px; }
        a{ color: inherit; text-decoration: none;
            &:hover{ text-decoration: none; color: $usoLtBlue!important;}
        }
        .photoContainer{
            .photoImg img{ width: 100%;} 
            .playBtn{ width: 100px; height: 100px; background-size: 100px; left: 51%; top: 44%; }
        }
        .captionContainer{background-color: $white; border: 1px solid $usoLtGray; padding: 5px 18px; font-size: px2rem(18); line-height: px2rem(21);
            .caption{ @include interstate(bold); color: $usoDkGray; height: unset;
                &:hover{ text-decoration: none; color: $usoLtBlue!important;}
            }
            .description{ @include interstate(light); font-size: px2rem(14); line-height: px2rem(16); }
        } 
    }
}

.double-stack{ width: 100%; height: 100%;
    .story-wrapper{ 
        width: 100%; height: 100%; margin: 0px auto; padding: 15px; background-color: $usoBkgGray; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; gap: 20px;
        @include general-breakpoint(smtablet){ border: 1px solid $usoLtGray; }
    }
    .story{ width: 46%; height: auto; display: block;
        a{text-decoration: none; color: inherit;
            &:hover{ text-decoration: none; color: $usoLtBlue;}
        }
        .photoContainer{ position: relative;
            .photoImg img{ width: 100%; } 
            .playBtn{ width: 50px; height: 50px; background-size: 50px; left: 50%; top: 50%; margin-top: -25px; margin-left: -25px;}
        }
        .captionContainer{  padding: 10px 0px 5px; font-size: px2rem(12); line-height: px2rem(15); width: 100%;
            .caption{ @include interstate; color: $usoDkGray; height: unset;
                &:hover{ color: $usoLtBlue;}
            }
            .description{ display: none;}
        }
        @include general-breakpoint(smtablet){ width: 48%;
            .photoContainer{
                .playBtn{ top: 55%; left: 55%; width: 75px; height: 75px; background-size: 75px; margin-top: -50px; margin-left: -50px; cursor: pointer;}
            }
            .captionContainer{ font-size: px2rem(16); line-height: px2rem(19);}
        } 
    }  
}

.button-group{  width: 100%; height: 100%; margin: 0px auto; padding: 15px; background-color: $usoLtGray; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; gap: 20px;
    a{ flex-grow: 1;}
    .uso-button{ @include button(); width: 100%; max-width: unset; }
}