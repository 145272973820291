/** atoznav styles **/
#atoznav {
	clear: both;
	a {
		text-decoration: none;
	}
	text-align: center;
	margin-top: 0px;
	padding: 7px 0;
	select {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url('/assets/images/icons/dropdown-arrow.svg');
		background-repeat: no-repeat;
		background-position-x: 94%;
		background-position-y: 8px;
		border: 1px solid $usoLtBlue;
	}
	.dropdown_nav {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70%;
		height: 30px;
		// color: $black;
		margin: 0 auto;
		vertical-align: middle;
		border-radius: 4px;
		background-color: transparent;
		.az-dropdown-container {
			width: 70px;
			.az-select {
				width: 100%;
				height: 26px;
				border-radius: 3px;
				font-size: 14px;
				color: $usoLtBlue;
				text-align: center;
				@include interstate();
				text-align-last: center;
			}
		}
	}
	.text_nav {
		display: none;
	}
	.select-menu-dropdown {
		overflow: scroll;
		.show {
			width: 100%;
		}
	}
}
@include general-breakpoint(mobile) {
	#atoznav {
		.atoz-players-select-menu {
			width: 75px;
			height: 26.5px;
			border-radius: 3px;
			text-align: center;
			color: #418fde;
			border-color: #418fde;
		}
	}
}
@include general-breakpoint(smtablet) {
	#atoznav {
		padding: 0;
		.dropdown_nav {
			display: none;
		}
		.text_nav {
			display: block;
			background-color: $lt-gray2;
			clear: both;
			position: relative;
			width: 100%;
			text-align: center;
			left: 0;
			right: 0;
			top: 0;
			margin-top: 0px;
			margin-bottom: 0px;
			padding: 15px 0;

			ul {
				margin: 0;
				padding: 0;

				li {
					display: inline-block;
					margin: 0px 3px;

					@include general-breakpoint(tablet) {
						margin: 0px 5px;
					}
				}
			}
			a {
				padding: 0 5px 5px;
				display: block;
				color: $dk-gray2;
				font-size: 16px;
				@include interstate(regular);

				&.selected,
				&:hover {
					color: $dk-gray2;
					border-bottom-color: $dk-gray2;
				}
			}
			&.fix-nav {
				position: absolute;
				z-index: 2;
			}
			.disabled {
				a {
					color: #a4a4a4;
				}
			}
		}
	}
}
