.slamtracker-stats {
	$row-background: #f6f6f6;
	$row-label-color: #ffffff;
	$row-sublabel-color: #555555;
	$column-label-color: #999999;
	$row-height: 22px;
	$bar-height: 16px;
	padding-bottom: 15px;

	.overall {
		padding: 0px;
		width: 97%;
		margin: 5px auto;

		.stats-flag {
			display: none;
		}
		.stats-header-container,
		.stats-table {
			width: 96%;
			margin: 0px 2%;
		}
		.stats-table {
			padding-bottom: 10px;
			padding-top: 10px;
		}
		.stats-header-row {
			width: 100%;
			height: 40px;
			border-bottom: 1px solid #ececec;
			display: table;
			border-collapse: collapse;
			margin: 0px;

			.stats-header-cell {
				width: 50%;
				display: table-cell;
				vertical-align: middle;
			}
			.stats-label {
				font-size: 16px;
			}
			.stats-player {
				color: $dk-gray2;
				@include interstate(bold);
				font-size: 12px;

				.short {
					display: none;
				}
				.long {
					display: inline;
					font-size: 12px;
				}
				&.doubles {
					line-height: 16px;
				}
			}
			.t1 {
				.stats-player {
					text-align: left;
					// padding-left: 9px;
				}
			}
			.t2 {
				.stats-player {
					text-align: right;
					// padding-right: 9px;
				}
			}
		}
		.stats-row {
			height: $row-height;
			width: 100%;
			margin: 0px;
			position: relative;

			&.hide {
				display: none;
			}
		}
		.stats-data {
			width: 30%;
			height: $row-height;
			line-height: $row-height;
			font-size: 11px;
			z-index: 2;
			color: $md-gray;

			&.t1 {
				text-align: left;
				position: absolute;
				left: 5px;
				@include interstate;
			}
			&.t2 {
				text-align: right;
				position: absolute;
				right: 5px;
				@include interstate;
			}
			&.leading {
				color: $black;
				@include interstate(bold);
			}
		}
		.stats-label {
			width: 50%;
			height: $row-height;
			line-height: $row-height;
			padding: 0px;
			text-align: center;
			font-size: 11px;
			position: absolute;
			left: 25%;
			z-index: 2;
			white-space: nowrap;
			@include interstate;
			color: $dk-gray2;
			text-transform: uppercase;
		}
		.stats-visual-data {
			width: 50%;
			position: absolute;
			height: $row-height;
			vertical-align: middle;
			z-index: 1;

			&.t1 {
				left: 0px;

				.stats-player {
					text-align: left;
				}
			}
			&.t2 {
				right: 0px;

				.stats-player {
					text-align: right;
				}
			}
			.stats-arrow {
				display: none;
			}
			&.leading {
				.stats-arrow {
					display: block;
				}
			}
		}
		.stats-bar {
			display: block;
			position: relative;

			.stats-graph {
				position: absolute;
				width: 100%;
				height: $bar-height;
				top: 3px;
			}
			.stats-arrow {
				position: absolute;
				top: 3px;

				&.hide {
					display: none;
				}
			}
			&.t1 {
				float: right;

				.stats-graph {
					right: 0px;
					background: rgba(65, 143, 222, 0.4);
				}
				.stats-arrow {
					left: -8px;
					width: 0;
					height: 0;
					border-top: 8px solid transparent;
					border-bottom: 8px solid transparent;
					border-right: 8px solid rgba(65, 143, 222, 0.4);
				}
			}
			&.t2 {
				float: left;

				.stats-graph {
					left: 0px;
					background: rgba(255, 212, 0, 0.4);
				}
				.stats-arrow {
					right: -8px;
					width: 0;
					height: 0;
					border-top: 8px solid transparent;
					border-bottom: 8px solid transparent;
					border-left: 8px solid rgba(255, 212, 0, 0.4);
				}
			}
		}
	}
	.full-stats-button {
		display: none;
	}

	@include general-breakpoint(smtablet) {
		padding-bottom: 15px;

		.overall {
			width: 100%;
			margin: 0px;
			overflow-y: auto;

			.stats-table {
				border: none;
				border-bottom: 1px solid #ececec;
			}
			.stats-header-row {
				.stats-player {
					.short {
						display: none;
					}
					.long {
						display: inline;
						font-size: 12px;
					}
				}
			}
		}
		.full-stats-button {
			text-align: center;
			margin-top: 10px;
			display: block;

			a {
				width: 82px;
				height: 20px;
				line-height: 18px;
				border: 1px solid $bright-blue;
				text-align: center;
				@include interstate;
				font-size: 12px;
				text-transform: uppercase;
				color: $bright-blue;
				cursor: pointer;
				display: block;
				margin: 0px auto;
			}
			a:not([href]):not([tabindex]) {
				background-color: $bright-blue;
				color: $white;
				&:hover {
					background-color: $white;
					border: 1px solid $bright-blue;
					color: $bright-blue;
				}
			}
		}
		&.expanded {
			background-color: $gray-light;
			padding: 8px;

			.close-button {
				position: absolute;
				top: 18px;
				right: 17px;
				color: $dk-gray2;
			}
			.set-selection-bar {
				width: 100%;
				height: 35px;
				text-align: center;
				font-size: 12px;

				.match-set-tabs {
					height: 35px;
					list-style: none;
					padding-top: 4px;
					display: inline-block;
					margin: 0px auto;

					&.hidden {
						display: none;
					}
					&.visible {
						display: inline-block;
					}
					li {
						float: left;
						text-align: center;
						margin-left: 1px;
						height: 18px;
						line-height: 18px;
						padding: 0px 15px;
						position: relative;

						&.border:after {
							content: '';
							position: absolute;
							background-color: $white;
							top: 10%;
							right: 0px;
							height: 80%;
							width: 1px;
							opacity: 0.5;
						}
						a {
							float: left;
							display: block;
							width: 100%;
							text-decoration: none;
							text-transform: uppercase;
							cursor: pointer;
							outline: none;
							@include interstate;
							color: $md-gray;
						}
						&:first-child {
							margin-left: 0px;
							border-left: none;
						}
						&.selected {
							a {
								color: $dk-gray2;
								border-bottom: 2px solid $dk-gray2;
							}
						}
						&.disabled {
							a {
								cursor: default;
								color: $md-gray2;
							}
						}
					}
				}
			}
			.stat-selection-bar {
				width: 100%;
				height: 34px;
				text-align: center;
				background-color: $lt-gray;
				margin-bottom: 1px;
				font-size: 12px;

				.stats-category-tabs {
					list-style: none;
					margin: 0px auto;
					padding-top: 8px;
					height: 34px;
					display: inline-block;

					li {
						text-transform: uppercase;
						list-style: none;
						float: left;
						margin: 0px 5px;
						padding: 0px 7px;
						height: 18px;
						line-height: 18px;
						text-align: center;
						position: relative;

						&.border:after {
							content: '';
							position: absolute;
							top: 10%;
							right: 0px;
							height: 80%;
							width: 1px;
						}
						a {
							float: left;
							width: 100%;
							height: 100%;
							color: $md-gray;
							cursor: pointer;
							outline: none;
							text-decoration: none;
							@include interstate;
						}
						&.selected {
							a {
								border-bottom: 2px solid $md-gray;
							}
						}
						&.disabled {
							a {
								cursor: default;
								color: $md-gray2;
							}
						}
						&.dss {
							&.disabled {
								display: none;
							}
						}
					}
				}
			}
			.stats-table-wrapper {
				background-color: $white;
				min-height: calc(100% - 69px);
			}
			.stats-player {
				color: $dk-gray2;
				@include interstate(bold);
				font-size: 12px;
			}
			.overall {
				font-size: 12px;

				.stats-header-row {
					.stats-player {
						text-transform: none;
					}
				}
			}
			.serve,
			.return {
				padding: 0px;
				font-size: 12px;

				.stats-flag {
					display: none;
				}
				.stats-caption-table,
				.stats-header-table {
					width: 100%;
					display: table;
					border-collapse: collapse;
				}
				.stats-header-table {
					background: $lt-gray;
				}
				.stats-header-caption {
					border: none;
					height: 50px;
					line-height: 50px;
					padding: 0px;
					width: calc(100% / 3);
					display: table-cell;
					vertical-align: middle;

					&.label {
						@include interstate(bold);
						text-align: center;
						color: $dk-gray2;
						text-transform: uppercase;
					}
					&.t1 {
						padding-left: 15px;
					}
					&.t2 {
						padding-right: 15px;
						text-align: right;
					}
				}
				.stats-header-cell {
					color: $dk-gray2;
					height: 37px;
					@include interstate(bold);
					width: 10%;
					text-align: center;
					overflow: hidden;
					display: table-cell;
					vertical-align: middle;
				}
				.stats-header-spacer {
					width: 40%;
					height: 37px;
					display: table-cell;
				}
				.stats-table {
					display: table;
					border-collapse: collapse;
					width: 100%;
				}
				.stats-row {
					border-top: 1px solid #ececec;
					width: 100%;
					height: 34px;
					line-height: 34px;
					text-align: center;
					margin-bottom: 0px;
					display: table-row;

					&:first-child {
						border-top: 0px;
					}
					.stats-data {
						width: calc(60% / 6);
						height: 34px;
						@include interstate;
						display: table-cell;
						vertical-align: middle;
					}
					.stats-label {
						width: 40%;
						@include interstate(bold);
						text-transform: uppercase;
						color: $dk-gray2;
						display: table-cell;
						vertical-align: middle;
					}
				}
			}
			.rally {
				padding: 0px;
				font-size: 12px;

				.stats-flag {
					display: none;
				}
				.stats-caption-table,
				.stats-header-table,
				.stats-sub-header-table {
					display: table;
					width: 100%;
					border-collapse: collapse;
				}
				.stats-header-table,
				.stats-sub-header-table {
					background: $lt-gray;
				}
				.stats-sub-header-table {
					border-top: 2px solid $gray-light;
				}
				.stats-header-caption {
					height: 50px;
					padding: 0px;
					display: table-cell;
					width: 40%;
					vertical-align: middle;

					&.label {
						@include interstate(bold);
						text-align: center;
						color: $dk-gray2;
						width: 20%;
						text-transform: uppercase;
					}
					&.t1 {
						padding-left: 15px;
					}
					&.t2 {
						padding-right: 15px;
						text-align: right;
					}
				}
				.stats-header-cell {
					color: $dk-gray2;
					height: 37px;
					font-size: 13px;
					@include interstate(bold);
					text-align: center;
					overflow: hidden;
					display: table-cell;
					width: calc(80% / 6);
					vertical-align: middle;
				}
				.stats-header-spacer {
					width: 20%;
					height: 37px;
					display: table-cell;
				}
				.stats-header-cell-small {
					width: calc(80% / 12);
					display: table-cell;
					vertical-align: middle;
					color: $dk-gray2;
					text-align: center;
					font-size: 13px;
					@include interstate(bold);
					text-transform: uppercase;
				}
				.stats-player {
					text-transform: uppercase;
				}
				.stats-table {
					display: table;
					border-collapse: collapse;
					width: 100%;
				}
				.stats-row {
					border-top: 1px solid #ececec;
					width: 100%;
					height: 34px;
					text-align: center;
					margin-bottom: 0px;
					display: table-row;

					&:first-child {
						border-top: 0px;
					}
					.stats-data {
						width: calc(80% / 12);
						height: 34px;
						@include interstate;
						display: table-cell;
						vertical-align: middle;
					}
					.stats-label {
						width: 20%;
						@include interstate(bold);
						color: $dk-gray2;
						text-transform: uppercase;
						display: table-cell;
						vertical-align: middle;
					}
				}
			}
			.filter-container {
				float: left;
				width: 100%;
				height: 38px;
				background: $white;
				font-size: 12px;
				display: block;
				@include interstate(bold);
				padding: 2px 1%;
				margin-top: 7px;
				margin-bottom: 8px;

				form {
					padding: 0px;
					margin: 0px;
				}
				.serve-selection {
					float: left;
					width: 48%;
					text-align: left;
					color: $dk-gray2;
					line-height: 31px;

					a {
						cursor: pointer;

						i {
							margin-right: 5px;
						}
						.icon-radio-button {
							display: inline;
						}
						.icon-radio-selected {
							display: none;
						}
						&.selected {
							.icon-radio-button {
								display: none;
							}
							.icon-radio-selected {
								display: inline;
							}
						}
					}
					.second-serve {
						margin-left: 10px;
					}
				}
				.stat-selection {
					float: right;
					width: 48%;
					text-align: right;

					.select-menu {
						border: 2px solid $gray-light;
						width: 250px;
					}
				}
			}
			.directional-serve-stats {
				font-size: 12px;

				.serve-stats-container {
					background: $white;
					padding: 10px 0px;
				}
				.p1container,
				.p2container {
					position: relative;
					width: 48%;
					height: 337px;
					padding-top: 30px;
					padding-bottom: 68px;
					background: url('/assets/images/scores/slamtracker/stats_dss_bg_small.png') 50% 30px no-repeat;
					border: 3px solid $gray-light;
				}
				.p1container {
					float: left;
					margin-left: 1%;
				}
				.p2container {
					float: right;
					margin-right: 1%;
				}
				.serve-stats {
					width: 100%;
					min-height: 337px;
					position: relative;
					float: left;
					border: none;

					.box {
						height: 36px;
						width: 37px;
						line-height: 36px;
						position: absolute;
						top: 154px;
						text-align: center;
						@include interstate(bold);
						color: $dk-gray2;

						&.multiline {
							line-height: 18px;
						}
					}
					.p1dw {
						left: calc(50% - 91px);
					}
					.p1dc {
						left: calc(50% - 48px);
					}
					.p1db {
						top: 196px;
						left: calc(50% - 69px);
					}
					.p1ac {
						right: calc(50% - 44px);
					}
					.p1aw {
						right: calc(50% - 87px);
					}
					.p1ab {
						top: 196px;
						right: calc(50% - 66px);
					}
					.p2dw {
						left: calc(50% - 91px);
					}
					.p2dc {
						left: calc(50% - 48px);
					}
					.p2db {
						top: 196px;
						left: calc(50% - 69px);
					}
					.p2ac {
						right: calc(50% - 44px);
					}
					.p2aw {
						right: calc(50% - 87px);
					}
					.p2ab {
						top: 196px;
						right: calc(50% - 66px);
					}
					.flag {
						display: none;
					}
					.name {
						position: absolute;
						bottom: 0px;
						left: 0px;
						text-align: center;
						width: 100%;
						height: 38px;
						line-height: 38px;
						color: $dk-gray2;
						@include interstate(bold);
						background: $gray-light;
						border-top: 2px solid $white;

						img {
							display: inline-block;
							padding: 0px 5px;
						}
					}
					.deuce {
						position: absolute;
						left: 0px;
						bottom: 73px;
						width: 50%;
						padding-right: 28px;
						text-align: right;
						color: $dk-gray2;
						text-transform: uppercase;
						@include interstate;
					}
					.advantage {
						position: absolute;
						right: 0px;
						bottom: 73px;
						width: 50%;
						padding-left: 7px;
						text-align: left;
						color: $dk-gray2;
						text-transform: uppercase;
						@include interstate;
					}
				}
			}
		}
	}
}
