
.flex {
    @include flex-factory();

    @each $prefix in $breakpoint-all-prefixes {
        @include general-breakpoint($prefix) {
            &-#{$prefix} {
                display: flex;
                @include flex-factory();
            }
        }
    }

    &-grow {
        flex-grow: 1;
    }

    &-shrink {
        flex-shrink: 1;
    }

    &.start {
        justify-content: flex-start
    }
    &.end {
        justify-content: flex-end;
    }

    &.middle {
        align-items: center
    }

    &.center {
        justify-content: center;
    }
}
