/*** Search Results ***/
#searchContainer,
.siteDetailContainer {
  .helperText {
    margin: 15px auto;
    color: #212529;
  }

  .searchFormContainer {
    margin: auto;
    @include interstate(light);
    text-align: right;
    @include media-breakpoint-up(sm) {
      text-align: left;
    }
    .pageSearchButton {
      margin-left: 20px;
      display: inline-block;
    }

    #query {
      width: 100%;
      height: 40px;
      margin-bottom: 10px;
      @include media-breakpoint-up(sm) {
        width: 40%;
        margin-bottom: 0;
      }
    }
  }

  .searchResultsContainer {
    margin: auto;
    @include interstate(regular);
    color: #212529;

    li {
      list-style: none;
      padding-bottom: 15px;
    }

    .title {
      @include interstate(bold);
      font-size: 100%;
    }

    .paginationContainer {
      text-align: center;
      margin: auto;
      font-size: 1.6rem;

      .plink,
      .prev {
        margin: 5px;
        padding: 5px 10px;
      }

      .next,
      .prev {
        font-weight: bold;
      }
    }

    #resultsMessage {
      padding-bottom: 10px;
    }

  }
}