.toggle-button {
    label {
        position: relative;
        display: inline-block;
        margin: 0;
        width: 50px;
        height: 25px;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $toggle-button-bg-color-off;
        border: solid 1px $toggle-button-off-border;
        transition: 0.3s;
        border-radius: 25px;
    }

    span:before {
        position: absolute;
        content: "";
        height: 21px;
        width: 21px;
        left: 2px;
        bottom: 1px;
        background-color: $white;
        border-radius: 50%;
        transition: 0.3s;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
        transform: rotate(180deg);
    }
    
    input:checked + span {
        background-color: $toggle-button-bg-color-on;
        border: solid 1px $toggle-button-on-border;
    }
    
    input:checked + span:before {
        transform: translateX(24px);
    }
    
    strong {
        position: absolute;
        left: 100%;
        width: max-content;
        line-height: 25px;
        margin-left: 10px;
        cursor: pointer;
    }
}