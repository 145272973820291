/**
* Carousel Page SCSS
**/
.Gallery-page {
    padding: 0;
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &.photo{
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
        .inline-carousel-container{
            max-width: 100%;
        }
        .carousel-status{
            display: none;
        }
        .share-bar{
            position: absolute;
            right: 12px;
            bottom: 50%;
            transform: translateY(50%);
            .SocialMediaShareButton{
                display: inline-block;
                margin: 0 5px;
            }
        }
        .slider-title-mobile{
            display: none;
        }
        .slide{
            &.selected{
                .slider-caption{
                    display: block;
                    line-height: 20px;
                    padding: 20px 10%;
                    white-space: pre-wrap;
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    right: 0;
                    width: 100%;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.75);
                    color: #ffffff;
                    font-weight: bold;
                    z-index: 99;
                    @include general-breakpoint(landscape, max){
                        position: unset;
                        bottom: unset;
                        .share-bar{
                            transform: unset;
                            top: -36px;
                            right: 0;
                        }
                    }
                }
            }
            .slider-caption{
                display: none;
            }
        }
        .icon-close{
            left: unset;
            right: 20px;
            border: 1px solid white;
            padding: 6px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.65);
        }
        .carousel{
            .control-arrow{
                opacity: 1;
                background-color: transparent;
            }
            .control-next, .control-prev{
                @include uso-icon('Icons');
            }
            .control-next::before{
                content: "\e902";
                padding: 6px 4px 6px 9px;
            }
            .control-prev::before{
                content: "\e901";
                padding: 6px 9px 6px 4px;
            }
            @include general-breakpoint(landscape, max){
                overflow: visible;
                .slider-wrapper{
                    overflow: visible;
                }
            }
            .control-next::before, .control-prev::before{
                border: 2px solid white;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.65);
                font-weight: bold;
                @include general-breakpoint(landscape, max){
                    border: 1px solid white;
                    font-size: 15px;
                    padding: 6px;
                }
            }
        }
    }
    .icon-close {
        position: absolute;
        top: 3%;
        color: white;
        left: 93%;
        cursor: pointer;
        z-index: 999;
        @include general-breakpoint(desktop) {
            left: 95%;
        }
    }
    .no-content-gallery {
        color: white;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
        width: 85%;
        text-align: center;        
    }
    .slider-title-mobile {
        display:block;
        line-height: 20px;
        padding: 20px 10%;
        white-space: pre-wrap;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        text-align: center;
        background: rgba(0,0,0,.75);
        color: #ffffff;
        font-weight: bold;
        transition: opacity .5s linear 5s;
        opacity: 0;
        z-index: 99;
        .gallery-credit-mobile {
            font-style: italic;
            display: inline-block;
            margin-left: 5px;
        }
    }
    .image-gallery {
        .image-gallery-content {
            height: 100vh;
            .image-gallery-slide-wrapper {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                .image-gallery-left-nav, .image-gallery-right-nav {
                    transition: opacity .5s linear 5s;
                    opacity: 0;
                    z-index: 9999;
                }
                .image-gallery-slides {
                    overflow:hidden;
                    .slider-content {
                        background: black;
                        .slider-title {
                            display:none;
                            line-height: 20px;
                            padding: 20px 0;
                            white-space: pre-wrap;
                            position: absolute;
                            top: 0;
                            width: 100%;
                            text-align: center;
                            background: rgba(0,0,0,.75);
                            color: #ffffff;
                            font-weight: bold;
                            transition: opacity .5s linear 5s;
                            opacity: 0;
                            .gallery-credit {
                                font-style: italic;
                                // display:none;
                            }
                        }
                    }
                }
                .image-gallery-slides {
                    .image-gallery-slide {
                        overflow: hidden;
                        &.center {
                            z-index: 99;
                        }
                        .slider-content {
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }

            .image-gallery-thumbnails-wrapper {
               transition: opacity .5s linear 5s;
               opacity: 0;
               position: absolute;
               bottom: 0;
               width: 100%;
               background: black;
               z-index: 9999;
               .image-gallery-thumbnails {
                   max-height: 75px;
                   .image-gallery-thumbnail {
                       img {
                           vertical-align: top;
                       }
                   }
               }
            }
            .image-gallery-slides {
                overflow: hidden !important;
                // width: 85%;
                margin: auto;
            }
        }

        .social-media-icons {
            position: absolute;
            right: 1%;
            bottom: 5%;
            color: white;
        }
    }

    &:hover {
        .slider-title-mobile {
            transition: opacity .5s linear !important;
            opacity: 1 !important;
            .gallery-credit-mobile {
                transition: opacity .5s linear !important;                
                opacity: 1 !important;

            }
        }
        .image-gallery-content {
            .image-gallery-thumbnails-wrapper {
                transition: opacity .5s linear !important;
                opacity: 1 !important;
            }
    
        }
        .image-gallery-slide-wrapper {
            .image-gallery-left-nav, .image-gallery-right-nav {
                transition: opacity .5s linear !important;
                opacity: 1 !important;
            }
            .image-gallery-swipe {
                .image-gallery-slides {
                    .image-gallery-slide {
                        .slider-title {
                            transition: opacity .5s linear !important;
                            opacity: 1;
                        }
                    }
                }
            }

        }
    }

    @include general-breakpoint(desktop) {
        .slider-title-mobile {
            // display:none;
            .gallery-credit-mobile {
                // display: none;
            }
        }
        .image-gallery {
            .image-gallery-content {
                .image-gallery-slide-wrapper {
                    position: relative;
                    top: unset;
                    transform: unset;
                    .image-gallery-slides {
                        .image-gallery-slide {
                            .slider-content {
                                .slider-title {
                                    display: block;
                                    .gallery-credity {
                                        // display: block;
                                    }
                                }
                                img {
                                    display: block;
                                    // height: 90vh;
                                    height: 100vh;
                                    width: auto;
                                    margin:auto;
                                }
                            }
                        }
                    }
                }
            }
    
        }

    }    

}
@keyframes hideElements {
    to {
        opacity: 0;
    }
}
