.sticky-header-wrapper{
  width: 374px;
  // height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  @include general-breakpoint(smtablet, max) {
    width: 100%;
  }
  @include general-breakpoint(smtablet) {
    width: 385px;
    margin-left: -5px;
  }
}
.sticky-header-container{
  .team-container{
    width: 100%;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    .team1, .team2{
      width: 97px;
      .team-info{
        position: relative;
       .img-container{
        .team-score { 
          height: 100%;
          // .icon-container{ margin-top: -4px; }
        }
       }
        .icon-container{
          .live-score-container {
            .live-score {
              font-size: px2rem(24);
              @include interstate(exlight);
              // margin-top: -4px;
              height: 29px;
            }
            .crtIcon{
              // margin-top: -5px;
              color: #6CC24A;
              text-align: center;
              height: 10px;
            }
          }
        }
      }
      .img-container {
        display: flex;
        margin-bottom: 5px;
        height: 41px;
        .image { 
          display: flex; 
          .match-winner{
            margin-left: 3px;
            align-self: end;
            height: 14px;
            .icon-check{
              font-size: 10px;
              color: #6CC24A;
            }
          }
        }
        img {
          position: relative;
          width: 30px;
          margin-right: 5px;
        }
      }
    }
    .match-data{
      width: 35%;
      color:#FFFFFF;
      text-align: center;
      align-self: center;
      height: 50px;
      .live-indicator{
        width: 8px;
        height: 8px;        
        background-color: #D0021B;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }
      .match-status{
        @include interstate(exlight);
        font-size: px2rem(14);
      }
    }
    .team1{
      .team-info { 
        float: left; 
        .img-container { justify-content: flex-start }
        .icon-container{ margin-left: 3px; }
      }
    }
    .team2{
      .team-info { 
        float: right; 
        .img-container { 
          justify-content: flex-end;
          .match-winner{
            margin-right: 3px;
            align-self: end;
            height: 14px;
            .icon-check{
              font-size: 10px;
              color: #6CC24A;
            }
          }
        }
        .icon-container{ margin-right: 5px; }
        .name {
          .member-one, .member-two{
           justify-content: flex-end;
          }
        }
        .team-score{
          align-self: end;
          margin-right: 5px;
          display: flex;
        }
      }
    }
    .name-container{
      @include interstate(light);
      .name{
        .link-to-player{
          max-width: 75px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
        .member-one, .member-two{
          display: flex;
        }
      }
      .seed{
        margin-left: 3px;
        @include interstate(exlight);
        font-size: px2rem(9);
        align-self: center;
      }
    }
  }
}