// colors
$black: #000000;
$white: #FFFFFF;
$green:#00503C;
$blue: #009BDF;
$red: #FF0000;
$limegreen:#BDE403;
$ltgreen:#1E6E5A;
$dkgreen:#00321E;
$orange:#C85A19;
$ltorange:#F38241;
$dkorange:#AD3C00;
$yellow:#F9E300;
$dkyellow:#DBC51E;
$gray:#AFAFAF;
$gray_4a: #4a4a4a;
$gray_f3: #f3f3f3;
$gray_d8: #d8d8d8;
$gray_8: #888b8d;
$ltgray:#EEEEEE;
$ltgray2:#DDDDDD;
$ltgray3:#DEDEDE;
$dkgray:#878787;
$blue:#65CFE9;
$ltblue:#83EDFF;
$dkblue:#47B1CB;
$red: $orange;
$purple: #54008B;

//rg colors
//$t1_color : #006633;
//$t2_color : #4C2178;
$ltgray1:#F6F6F6;
$ltgray3:#F7F7F7;
$dkgray2:#666666;
$dkgray3:#999999;

//wimbledon colors
$wim_green: #003300;
$wim_purple: #4E2683;
$text_green: #006633;

//usopen colors
$t1_color: #408FDA;
$t2_color: #F5CE4D;

$ai-catchup-border: #00288C;
$ai-catchup-bg: #F8F8F8;