// .tabs {
// width: 100%;
// min-width: 375px; //needed for proper display - add to parent
.tabPanel {
	display: flex;
	justify-content: space-evenly;
	gap: 20px;
	align-items: center;
	height: 40px;
	background-color: $white;

	@include general-breakpoint(smtablet) {
		justify-content: center;
	}
	.panel-item {
		cursor: pointer;
		@include interstate();
		font-size: px2rem(14px);

		&::after {
			content: attr(data-text);
			content: attr(data-text) / '';
			height: 0;
			display: block;
			visibility: hidden;
			overflow: hidden;
			user-select: none;
			@include interstate(bold);
		}

		&:hover {
			text-decoration: none;
			color: $usoLtBlue;
			@include interstate(bold);
		}

		&.active {
			text-decoration: underline;
			text-underline-offset: 12px;
			text-decoration-thickness: 3px;
			@include interstate(bold);
			color: $usoLtBlue;
			@include general-breakpoint(smtablet) {
				text-underline-offset: 18px;
			}
		}
	}
}
// }
