.chip {
  background-color: #ffffff;
  width: 300px;
  margin: 10px auto;
  height: 300px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: bottom;
  border: 1px solid #d5d8da;
}

#videoList {
  max-width: 1280px;
  text-align: center;
  margin: auto;
}

.newsChips {
  width: 100%;
  position: relative;
  background-color: #f3f3f3;
  padding-top: 30px;
  padding-bottom: 15px;
}

.news_content {
  width: 100%;
  min-height: 500px;
  overflow: hidden;
}
