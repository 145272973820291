.tournament-day-selector-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f3f3f3;
	height: 46px;
	.day-prefix {
		font-weight: 300;
		font-size: 12px;
		padding: 6px;
	}
}

.day-selector-box {
	cursor: pointer;
	width: 32px;
	height: 32px;
	border: none;
	line-height: 30px;
	text-align: center;
	color: #4a4a4a;
	font-size: 12px;
	background-color: #f3f3f3;
	&.selectedDay {
		border: 1px solid #c1c6c8;
	}
	&:hover,
	&.selectedDay {
		background-color: #fff;
		color: #2478cc;
		font-weight: bold;
	}
	&.disabled {
		cursor: auto;
	}
}
