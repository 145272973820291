.homeLiveBlog { 
    background-color: $white; 
    // padding: 0px 16px 0px;

    a, a:hover{ 
        text-decoration: none;
    }

    .content {
        padding: 23px 16px 0px;
    }

    .bullets{ 
        @include interstate; 
        font-size: px2rem(14); 
        color: $md-gray; 
        margin-left: 15px; 
        padding-left: 5px; 
        height: 100%;

        li{
            padding-bottom: 10px; 
            line-height: 20px;
            

            &.icon-indent {
                /** makes the second line to line up with 
                    the first line when wrapped */
                text-indent: -6px;
                padding-left: 6px;
            }

            a { 
                color: $md-gray; 
                &:hover{ color: $bright-blue;}
            }

            i[class^=icon-] {
                background-color: $white;
                margin-left: -18px;
                padding-right: 10px;
                color: $usoLtBlue;
            }
        } // light

        &.no-bullets {
            list-style: none;
            margin-left: 0;
        }

        &.two-cols {
            column-count: 2;
        }

        &.four-cols {
            column-count: 4;
        }
    }

    .button{ padding-bottom: 10px;}

    .allStories { 
        @include interstate(light); 
        font-size: px2rem(16); 
        line-height: 19px; 
        color: $bright-blue; 
        display: none;
    }
}