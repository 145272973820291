#uso-main, .webview {
    .wrapper .content-main, .webview-match-insights .content-main {
        &.match-insights {
            @include general-breakpoint(smtablet, max) {
                background-color: $white;
            }
            @include interstate;
            // padding-top: 15px;

            .generic-button_wrapper {
                &.center {
                    text-align: center;
                }
            }
            
            .byline {
                margin-left: 18px;
                margin-right: 18px;
    
                @include general-breakpoint(smtablet)  {
                    margin-left: 29px;
                    margin-right: 29px;
                }
    
                @include general-breakpoint(xldesktop)  {
                    margin-left: 35px;
                    margin-right: 35px;
                }
            }

            .match-insights-content {

                @include general-breakpoint(smtablet) {
                    background-color: $white;
                }

                .loading-indicator {
                     top: calc(50% - #{$mobile-header-height});
                    @include general-breakpoint(smtablet)  {
                     top: calc(50% - #{$header-height});
                    }
                }
            }
            
            .socialShare {
                span { /* used in webview */
                    padding-right: 10px;
                }
            }
        } // .content-main &.match-insights

        /* common side padding that can apply to each component 
            use @exrtend to import those class
        */
        %match-insights-content-padding {
            margin-left: 18px;
            margin-right: 18px;
            padding: 18px 0;

            @include general-breakpoint(smtablet)  {
                margin-left: 29px;
                margin-right: 29px;
                padding: 29px 0;
            }

            @include general-breakpoint(xldesktop)  {
                margin-left: 35px;
                margin-right: 35px;
                // padding: 135px 0;
            }
        }

        %likelihood-win-purple-circle {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: $likelihoodwin-purple;
        }

        /** override generic error message style 
        *   as there is no parent relative positioning
        */
        .error-indicator {
            &.mi-section {
                color: $black;
                position: relative;
                
                top: auto;
                left: auto;
                transform: none;
                text-align: center;
            }
        }
    } // .wrapper .content-main
}

.error-indicator {
    @extend %match-insights-content-padding;
}

.mi-section {
    @extend %match-insights-content-padding;
    border-bottom: solid 1px $md-gray3;

    &.notitle {
        padding-top: 5px !important;
        
    }

    &.no-border-bottom {
        border-bottom: none;
    }

    .column-layout {
        padding: 0;
        flex-wrap: nowrap;
        background-color: $white;

        ul {
            margin-left: 0;
            padding-left: 17px;
            margin-top: 17px;

            &.spacious {
                font-size: px2rem(12);

                li {
                    padding-bottom: 15px;
                    color: $gray-4a;

                    &::marker {
                        font-size: px2rem(18);
                    }
                }

                @include general-breakpoint(smtablet) {
                    font-size: px2rem(18);
                    margin-bottom: 0;

                    li {
                        padding-bottom: 30px;

                        &::marker {
                            font-size: px2rem(24);
                        }
                        &:last-child {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }

        .col {
            color: $gray-4a;
            font-size: px2rem(12);

            &:first-of-type {
                // border-right: solid 1px $md-gray3;
                padding-right: 10px;

                li {
                    &::marker {
                        color: $team1_color;
                    }
                }
            }

            &:last-of-type {
                padding-left: 10px;

                li {
                    &::marker {
                        color: $team2_color;
                    }
                }
            }

            @include general-breakpoint(smtablet) {
                font-size: px2rem(18);
            }
        }

        .two-col {
            &:first-of-type {
                border-right: solid 1px $md-gray3;

                padding-right: 10px;
            }
            
        }
    } // .column-layout

    &.center {
        text-align: center;
    }

    &.no-bottom-border {
        border-bottom: none;
    }

    a>.generic-button {

        @include general-breakpoint(mobile, max) {
            font-size: px2rem(12) !important;
        }
        
        &.rounded-corner {
            .border {
                border-radius: 7px;
            }

            &.full {
                width: 100%;
            }

            .content {

                &:hover, &:active {
                    border-radius: 7px;
                }
            }
        }

    }

    .card-head {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 10px;
        color: $usoDkGray;

        @include general-breakpoint(smdesktop) {
            margin-bottom: 15px;
        }

        .eventName{
            text-transform: uppercase;
            font-size: px2rem(14);
            font-weight: bold;
        }

        .courtName{
            text-transform: uppercase;
            font-size: px2rem(12);
            letter-spacing: 0.86px;
            
            @include general-breakpoint(smdesktop) {
                padding-top: 10px;
            }
        }
    }
} // .mi-section


.mi-tags {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;

    li {
        flex-basis: 33%;
        border-bottom: solid 1px $gray-light;
        text-transform: uppercase;
        color: $usoDkGray;
        font-size: px2rem(13);
        padding: 3px 0;
        text-align: center;
        position: relative;

        &:hover {
            cursor: pointer;
        }
        
        &.selected {
            border-color: $usoDkGray;

            &:hover {
                cursor: default;
            }
        }

        // &:before {
        //     content: '|';
        //     color: $gray-light;
        //     position: absolute;
        //     left: 0;
        // }

        &:first-child {

            &:before {
                content: '';
            }
        }
    }

    @include general-breakpoint(smdesktop, max) {
        margin-left: 8px;
        margin-right: 8px;
    }

    @include general-breakpoint(smdesktop) {
        display: none;
    }
} // .mi-tags

.mi-inner-body {
    @extend %match-insights-content-padding;
    padding-top: 0;

    .error-message {
        p {
            padding-bottom: 15px;
        }
    }
}

.mi-footer {
    @include interstate(light);
    color: $gray-4a;
    font-size: px2rem(12);

    a {
        @include interstate();
        color: $bright-blue;
        display: block;
    }

    @include general-breakpoint(smtablet) {
        font-size: px2rem(18);

        a {
            display: inline-block;
        }
    }
}

.webview {
    .wrapper .content-main {
        &.match-insights {
            background-color:transparent;
            background: transparent;
            padding-top: 15px;

            .columm-layout.match-insights-content {
                background-color: #FFFFFF;
            }
        }
    }
}

// Slamtracker Overrides
.slamtracker-header {
    width: 96%;
    height: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    color: $gray-4a;
    @include interstate(bold);
    font-size: px2rem(12);
    border-bottom: 1px solid #ECECEC;

    .team {
        width: 50%;
    }
    .team1 {
        text-align: left;
        padding-left: 9px;
    }
    .team2 {
        text-align: right;
        padding-right: 9px;
    }
}

.mi-section {
    #uso-main .wrapper.slamtracker .content-main &, .webview .wrapper.slamtracker .content-main & {
        .column-layout {
            &.mi-media {
                flex-direction: column;
                padding-top:0px;

                .col {
                    margin:0px;
                    padding:0px;
                    padding-bottom:20px;
                    &:first-of-type {
                        //     padding-right: 0px;
                        //     padding-bottom: 0px;
                        border-bottom:solid 1px gray;
                        margin-bottom:15px;
                    }
                }

                .slick-slider {
                    .slick-dots {
                        bottom:0px;
                    }
                }
            }
            ul {
                &.spacious {
                    font-size: px2rem(14);
                    
                    li {
                        &::marker {
                            font-size: px2rem(16);
                        }
                    }
                }
            }
            .col {
                font-size: px2rem(14);
            }
        }

        @include general-breakpoint(smtablet) {
            .column-layout {
                &.mi-media {
                    flex-direction: row;

                    .col {
                        width: 50%;
                        padding-left: 8px;
                        padding-bottom: 30px;

                        &:first-of-type {
                            padding-right: 8px;
                            padding-left: 0px;
                            padding-bottom: 30px;
                            border-bottom: none;
                            margin-bottom: 0px;
                        }
                    }
                    .slick-list, .slick-slider, .slick-track {
                        height: 100%;
                    }
                    .slick-slider {
                        .slick-dots {
                            bottom: -15px;
                        }
                    }
                }
                &.mi-player-glance {
                    flex-direction: row;

                    .col {
                        width:50%;

                        &:first-of-type {
                            //padding-right: 8px;
                            padding-left: 0px;
                            border-bottom: none;
                            margin-bottom: 0px;
                        }
                    }
                    .mi-stats-wrapper {
                        .ct-chart {
                            text-align: center;
                            margin-bottom: 20px;
                        }
                        .mi-stats-label {
                            padding: 0px;
                        }
                        // ul.spacious {
                        //     font-size: px2rem(12);
                        // }
                    }

                    ul.spacious {
                        li {
                            padding-bottom: 15px;
                        }
                    }
                }
            }
        }       
    }
}

/** slamTracker override */
.slamtracker {
    .below-desktop {
        display: block;
    }
    .above-desktop {
        display: none;
    }
// }

/** slamTracker panel override */
// .stpanel-container {
    .slamtracker-insights {
       .top-border, .slamtracker-header { display: none; }
    }

    .slamtracker .mi-section {
        .card-head {
            display: block;
            margin-bottom: 10px;

            .eventName{
                @include interstate();
                font-size: px2rem(16);
            }
            .courtName {
                padding-top: 0;
                @include interstate(light);
                font-size: px2rem(12);
            }
        }
    }

    .slamtracker .mi-stats-wrapper {
        .chart-content {
            margin-bottom: 0px;
        }

        .ct-chart .chart-value {
            // top: 18%;
            // bottom: 25%;
            font-size: px2rem(34);
        }

        .mi-stats-label {
            font-size: px2rem(13);
            align-items: flex-start;
        }
    }

    .mi-player-glance {
        font-size: px2rem(14);
        .ct-chart .chart-value {
            font-size: px2rem(30);
        }
        .mi-stats-label {
            font-size: px2rem(14);
        }
        ul.spacious li {
            font-size: px2rem(14);
            padding-bottom: 10px;
        }
    }
}

.mi-cta-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 0;
    margin: 0 auto 20px auto;
    width: 180px;
    height: 20px;
    line-height: 18px;
    border: 1px solid $bright-blue;
    color: $bright-blue;
    @include interstate;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;

    .bolder {
        @include interstate(medium600);

        &:before {
            content: " ";
        }
    }
}

.slamtracker-match-cta {
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
    text-align: center;

    a {
        display: block;
        text-decoration: none;

        &:hover {
            color: $bright-blue;
        }
    }
    .mi-cta-button {
        margin: -18px auto 0;
        text-transform: capitalize;
        display: inline-block;
    }
}

/** article embedded MatchInsightsCard override */
.slamtracker {
    .mi-section {
            
        .card-head { 
            display: block;
            margin-bottom: 10px;
            margin-top: 5px !important;

            @include general-breakpoint(smtablet, max) {
                display: block;
            }
        }

        .mobile-only {

            .card-head {
                @include general-breakpoint(smtablet, max) {
                    display: block;
                }
            }
            @include general-breakpoint(tablet) {
                display: block;
            }
        }
    
        .above-tablet {
            display: none;

            @include general-breakpoint(tablet) {
                display: block;
            }
        }
    }

    .slamtracker-insights {

        .mi-section {
            margin: 10px !important;
            padding: 0 !important;
            // margin: 10px;
        }
    } 

    .mi-tags {
        display: flex;
        background-color: $white;

        @include general-breakpoint(smtablet) {
            margin-top: 15px;
        }
    }
}