.click-overlay {
    &-wrapper {
        border: solid 1px $usoLtGray;
        position: relative;

        .image-container {
            img {
                height: auto;
                width: 100%;
            }
        }
        
    } // -wrapper

    &-content {
        padding: 20px;

        @include general-breakpoint(smtablet) {
            height: 165px;
            overflow-y: auto;
        }

        h4 {
            @include interstate(bold);
            color: $usoDkGray;
            font-size: px2rem(16);
            padding-top: 0;
        }

        ul {
            margin-left: 13px;
            margin-bottom: 0;
            padding: 10px 0;
        }
    }

    &-cta {
        border-top: solid 1px $usoLtGray;
        padding: 10px 20px;
        text-align: center;

        span {
            color: $usoLtBlueBG;
            text-transform: uppercase;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &-overlay {
        display: none;
        background-color: $usoBkgGray;
        color: $usoDkGray;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &.show {
            display: block;
            .click-overlay-content{
                height: 100%;
                @include general-breakpoint(tablet){
                    p{
                        line-height: 1.3em;
                        padding-top: 5px;
                    }
                }
            }
        }

        .icon-close {
            font-size: px2rem(20);
            position: absolute;
            top: 10px;
            right: 10px;

            &:hover {
                cursor: pointer;
            }
        }
    }
        
    
}