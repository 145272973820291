/** stub-box styles **/

#root.stub, .st-content-main.stub {
    background-color:$usoDkBlue;
    background:url('/assets/images/misc/stub_bg_mobile.png') no-repeat top center;
    background-size: cover;
    @include general-breakpoint(smtablet){background:url('/assets/images/misc/stub_bg_xl.png') no-repeat center center;background-size: cover; resize: both;}
}
#root.stub, #root.notfound {
    #uso-main {min-height: calc(100vh - 140px);}
}
#stub-page, #notfound-page, #panel-stub-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    .uso-content {
        text-align: center; 
        margin-top: 20%;
        h1 {@include interstate(black); font-size:px2rem(18); line-height: 32px; color:$usoYellow; text-transform: uppercase; padding-bottom: 40px;}
        p {color:$white; @include interstate(light); font-size:px2rem(14); line-height: 20px; text-align: center;}
    }
    .column-layout {background:none;}

@include general-breakpoint(smtablet) {
    justify-content: center;
    height: 100vh;
    .uso-content {
        min-height: 87px;
        max-height: 455px;
        height: 400px;
        max-width: 670px;
        h1 {font-size:px2rem(24)}
        p {font-size:px2rem(16)}
    }
}

// @include general-breakpoint(smdesktop) {padding-top: 55px;
    
// }

// @include general-breakpoint(smdesktop) {padding-top: 57px;
    
// }

}

#notfound-page {padding-top:20px; display:block;
    .uso-content {border:none; box-shadow: none; border-radius:0;
        h1 {@include interstate(bold); font-size: px2rem(28); line-height:36px; }
        p {@include interstate; font-size: px2rem(14); line-height: 18px;}
        a {color:$white; 
            &:hover {text-decoration: underline;}
        }
    }
@include general-breakpoint(smtablet) {
    .uso-content {
        h1 {font-size: px2rem(48px)}
        p {font-size: px2rem(24); line-height: 28px;}
    }
}
}


// webview

.webview {
    .stub {height:100vh;
        h1.header {display:none}
    }
}