$draw-analysis-width: 350px;
$draw-analysis-content-width: 333px;
// .page, .webview {


.draw-analysis-wrapper {
    max-width: $draw-analysis-width;
    margin-top: 27px;
    position: relative;

    .draw-analysis-inner-wrapper {
        // border: solid 1px $powerrank-divider;
        border-radius: 10px;
        padding: 8px;
        
        &.intro {
            h4 { 
                width: 100%;
                text-align: center;
                display: none;
            }

            p {
               font-style: normal;
            }

            .demo-header {
                font-style: normal;
                margin-top: 10px;
            }
        }

        &.hide {
            display: none;
        }

    }

    h4 {
        @include interstate();
        color: $black;
        display: inline-block;
        font-size: px2rem(16);
        padding-right: 10px;

        &.winner-title, span.winner-title {
            padding-bottom: 5px;
            text-transform: uppercase;
        }

        span.winner-title {
            display: inline-block;
            padding-bottom: unset;
        }

        &.winner-title {
            display: none;
        }
    }

    .draw-label {
        @include ai-draw-label;
        font-size: px2rem(16);
    }

    .help {
        display: inline-block;
        font-size: px2rem(16);
        position: absolute;
        right: 10px;
        text-align: right;
        width: 40px;

        &:hover {
            cursor: pointer;
        }
    }

    p {
        font-size: px2rem(14);
        margin-bottom: 12px;

        &.extra-space {
            margin-top: 10px;
            padding-bottom: 5px;
        }
    }

    .generic-button_wrapper {
        min-width: $draw-analysis-content-width;
        width: 100%;
    }

    .generic-button_wrapper .generic-button {
        &.rounded-corner {
            width: 100%;
            .border {
                border-radius: 5px;
            }
        }

        &.compact {

            &:hover{
                background-color: $white;
                color: $usoLtBlueBG;
                border: 2px solid $usoLtBlueBG;
                line-height: inherit;
    
                .content{
                    color: $usoLtBlueBG;
                }
            }
        }
    }

    &.mobile {
        border-top: solid 1px $usoDkGray;
    }

    @include general-breakpoint(smtablet) {
        margin-top: 12px;

        .draw-analysis-inner-wrapper {
            // border: solid 1px $powerrank-divider;
            border-radius: 10px;
            padding: 8px;
        }

        &.mobile-hide {
            display: block;
        }
    }

    @include general-breakpoint(smdesktop) { // 1024px 
        h4 {
            span.winner-title {
                display: block;
            }
        }

    }

    @include general-breakpoint(smtablet, max) {
        /** make the help content higher than the fade 
         *   that's placed on Path to the Finbals
        */
        z-index: 10; 

        &.mobile-show {
            z-index: unset;
        }
    }
} // .draw-analysis-wrapper

#powerindex-leaderboard {
    .leaderboard-data {
        .draw-analysis-wrapper {
            border: none;

            @include general-breakpoint(smtablet, max) {
                /** in the leaderboard row, 
                *   unset z-index so this doesn't 
                *   show on the Path to the Final
                *    that ends up displaying two
                */
                z-index: unset; 
            }

            @include general-breakpoint(smtablet) {
                border-radius: 10px;
                margin-left: 16px;
            }
        }

        .draw-analysis-inner-wrapper  {
            border: none;

            @include general-breakpoint(smtablet) {
                // border: solid 1px $powerrank-divider;
            }
        }
    }
}

.path-to-the-final {
    .draw-analysis-wrapper {
        h4 {
            &.winner-title {
                display: block;
            }

            span.winner-title {
                display: none;
            }
        }
    }
}

.drawAnalysisBar {
    display: flex;
    margin-top: 12px;
    margin-bottom: 12px;
    position: relative;
    // width: $draw-analysis-content-width;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    img {
        width: 100%;
    }

    /** marker position and color */
    span {
        position: absolute;
        top: -3px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: $favorable;
        border: solid 3px $white;

		transform: translateX(-7px); // offset the marker size
        

        &.favorable {
            background-color: $favorable;
            left: 5%;
        }

        &.neutral {
            background-color: $neutral;
            left: 48%;
        }

        &.difficult {
            background-color: $difficult;
            left: 95%;
        }
    }

    .bar-label {
        font-size: px2rem(10);
    }

    .draw-analysis-bar-area {
        flex: 1;
        margin-left: 6px;
        margin-right: 6px;
        position: relative;

        .bar {
            background-color: $draw-analysis-bar-bg;
            height: 8px;
            width: 100%;
            border-radius: 10px;
        }
    }

    &.demo {
        // span {
        //     top: 4px;
        // }
    }
} // .drawAnalysisBar



.demo-labels {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 60px);
    margin: auto;

    label {
        @include interstate();
        font-size: px2rem(14);
        position: relative;

        &.favorable {
            color: $favorable;
        }

        &.neutral {
            color: $neutral;
            transform: translateX(-10px);
        }

        &.difficult {
            color: $difficult;
        }

    }
}

.draw-analysis-help-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 21; // if 20, the draw analysis content displlays on top of the help overlay

    .overlay-content {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: px2rem(14);
        color: $black;
        transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        background-color: $white;
        border-radius: 10px;
        padding: 7px;
        width: 360px;

        .close-btn-container  {
            position: absolute;
            right: 15px;
            top: 20px;
        }

        h4 {
            color: $usoDkGray;
            font-size: px2rem(18);
            text-align: center;
            padding: 10px 0;
            width: 100%;
        }

        .text {
            text-align: center;
            padding-top: 10px;
            margin: 0;
        }

        .demo-header {
            @include interstate();
            font-size: px2rem(14);
            font-weight: normal;
            transform: translateY(10px);
            padding-bottom: 10px;
        }

        .draw-analysis-switch {
            border-bottom: none;
            .toggle-button {
                text-align: center;
    
                strong {
                    display: none;
                }
            }
        }
    } // .overlay-content
} // .draw-analysis-help-overlay


// } // .page, .webview



.webview .drawAnalysisBar {
    span {
        top: unset;
        transform: translateY(-3px);


        &.difficult {
            transform: translate(-14px, -3px); // offset the marker size
        }
    }
}