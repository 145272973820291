.collapsibleMenu {
	.header {
		cursor: pointer;
		//text-align: center;
		font-size: 14px;
		padding: 10px;
		color: white;
		background-color: #00288c;
		border-top: 1px solid;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.icon-up-arrow,
		.icon-down-arrow {
			font-size: 9px;
			align-self: flex-end;
			position: absolute;
			padding-right: 10px;
		}
	}
	.content {
		cursor: pointer;
	}
}
