.select-menu{
    width: 100%;
    position: relative;

    .title{
		border: 1px solid #979797;
  	  	box-shadow: 0 0 2px 0 rgba(0,0,0,0.23);
  	  	border-radius: 4px;
	  	text-align: center;
	  	cursor: pointer;
		@include interstate(bold);
		background-color: $white;
        color: $bright_blue;
        font-size: px2rem(14);
        padding-left: 11px;
        padding-right: 10px;

        // #uso-main & {
        //     font-size:14px;
        // }

        &:focus {
            outline: none;
        }

        &.border {
            border: 1px solid $dk-gray2;
		}

		.menu-arrow {
			font-size: 18px;
            line-height: 10px;
            //transform: rotate(-180deg);
            display:inline-block;
            position:relative;
            top:0px;
            padding-right:0px;
            padding-left: 25px;
            &:after {
                content:"\25BE";
            }
        }

        &.active {
            .menu-arrow {
                transform: rotate(-180deg);
                top:2px;
                padding-left:0px;
                padding-right:25px;
            }
        }
    }

    &.openLeft {
        .select-menu-dropdown {
            right: 0px;
        }
    }
    &.openRight {
        .select-menu-dropdown {
            left: 0px;
        }
    }

    &-dropdown{
        background: $white;
        //overflow: auto;
        border: 1px solid $select-menu-border;
        position: absolute;
        z-index: 100;
        display: none;
        //width: 100%;
        padding: 0 10px;
        @include interstate(regular);
        font-size: 13px;
        max-height: 400px;
        margin-left: 50%;
        transform: translateX(-50%);
        overflow-x: hidden;
        overflow-y: auto;
        &.show {
            display: block;
        }

        .option{
            display: block;
            color: $black;
            // margin: 8px 0px 8px 15px;
            padding: 2px 0;
			text-align: left;
            background-color: unset;
            width: 100%;

			&:hover {
                background-color: #3786f5;
                color: $white;
                width: 100%;
            }

            &.clicked{
                i{
                    visibility: visible;
                }
				color: #3786f5;

				&:hover {
	                color: $white;
	            }
            }

            // &:focus {
            //     outline: none;
            // }

            &.disabled {
                color: $usoMdGray;

                &:hover {
                    background-color: none;
                    cursor: default;
                }
            }



            span {
                padding-left: 20px;
            }
        }

        /** dark theme optioh hover style **/
        &.dark {width:100%;
            .option{
                &:hover {
                    background-color: $usoDkerBlue2;
                    color: $white;
                }

                &.clicked{
                    color: $usoDkerBlue2;
    
                    &:hover {
                        color: $white;
                    }
                }
            }
        } // .dark

        i{
            visibility: hidden;
            font-size: 10px;
            text-align: left;
            position: absolute;
            left: 2px;
            top: 8px;
        }

        button {
            flex: 0 0 auto;
            position: relative;
            white-space: nowrap;
            @include interstate(regular);
            font-size: 14px;

            text-decoration: none;
            border: 0;
            cursor: pointer;
        }

        .section {
            text-align: center;
            border-bottom: 1px solid $dk-gray2;
        }
    }

    /** dark theme filter select title style */
    &.dark {
        .title{
            border: 1px solid #bfbfbf;
            @include interstate(light);
            background-color: $usoDkerBlue2;
            color: $white;
        }
        &.blueBg {
            .title {
                background-color: $usoDkBlue;
            }
        }
    } // .dark

} // select-menu

/** dropdown ----- less than desktop size 
*   list style -- above desktop
*/
.select-menu-dropdown2tabs {
    width: 100%;
    position: relative;

    .title{
		border: 1px solid #979797;
  	  	box-shadow: 0 0 2px 0 rgba(0,0,0,0.23);
  	  	border-radius: 4px;
	  	text-align: center;
	  	cursor: pointer;
		@include interstate(bold);
		background-color: $white;
        color: $bright_blue;
        font-size: px2rem(14);
        padding-left: 11px;
        padding-right: 10px;

        &:focus {
            outline: none;
        }

        &.border {
            border: 1px solid $dk-gray2;
		}

		.menu-arrow {
			font-size: 18px;
            line-height: 10px;
            //transform: rotate(-180deg);
            display:inline-block;
            position:relative;
            top:0px;
            padding-right:0px;
            padding-left: 25px;
            &:after {
                content:"\25BE";
            }
        }

        &.active {
            .menu-arrow {
                transform: rotate(-180deg);
                top:2px;
                padding-left:0px;
                padding-right:25px;
            }
        }

        @include general-breakpoint(desktop) {
            /** display dropdown */
            display: none;
        }
    }

    &.openLeft {
        .select-menu-dropdown {
            right: 0px;
        }
    }
    &.openRight {
        .select-menu-dropdown {
            left: 0px;
        }
    }

    &-dropdown{
        background: $white;
        //overflow: auto;
        border: 1px solid $select-menu-border;
        position: absolute;
        z-index: 100;
        display: none;
        //width: 100%;
        padding: 0 10px;
        @include interstate(regular);
        font-size: 13px;
        max-height: 400px;
        margin-left: 50%;
        transform: translateX(-50%);
        overflow-x: hidden;
        overflow-y: auto;
        &.show {
            display: block;
        }

        .option{
            display: block;
            color: $black;
            // margin: 8px 0px 8px 15px;
            padding: 2px 0;
			text-align: left;
            background-color: unset;
            width: 100%;

			&:hover {
                background-color: #3786f5;
                color: $white;
                width: 100%;
            }

            &.clicked{
                i{
                    visibility: visible;
                }
				color: #3786f5;

				&:hover {
	                color: $white;
	            }
            }

            &.disabled {
                color: $usoMdGray;
                
                &:hover {
                    background-color: none;
                    cursor: default;
                }
            }

            // &:focus {
            //     outline: none;
            // }



            span {
                padding-left: 20px;
            }
        } // .option

        /** dark theme optioh hover style **/
        &.dark {
            width:100%;
            .option{
                &:hover {
                    background-color: $usoDkerBlue2;
                    color: $white;
                }

                &.clicked{
                    color: $usoDkerBlue2;
    
                    &:hover {
                        color: $white;
                    }
                }
            }
            .section {border-bottom:none;}
        } // .dark

        i{
            visibility: hidden;
            font-size: 10px;
            text-align: left;
            position: absolute;
            left: 2px;
            top: 8px;
        }

        button {
            flex: 0 0 auto;
            position: relative;
            white-space: nowrap;
            @include interstate(regular);
            font-size: 14px;

            text-decoration: none;
            border: 0;
            cursor: pointer;
        }

        .section {
            text-align: center;
            border-bottom: 1px solid $dk-gray2;
        }

        /** display options as list */
        @include general-breakpoint(desktop) {
            display: flex;
            justify-content: space-between;
            background: transparent;
            border: none;
            position: relative;
            color: $white;
            margin-left: unset;
            transform: unset;
            width: 100%;
            overflow: visible;
            .option {
                color: $white;
                display: inline-block;
                text-align: center;
                width: auto;
                padding: 0 10px 5px 10px;
                border-bottom: 4px solid transparent;
                &.clicked {
                    color: $white;
                    border-bottom: solid 4px $white;
                }

                &:hover {
                    width: auto;
                }
                
                i {
                    display: none;
                }

                span {
                    padding-left: unset;
                    text-align: center;
                }
            }

            &.dark {
                .option{
                    &:hover {
                        background-color: transparent;
                        color: $white;
                    }
    
                    &.clicked{
                        color: $white;
        
                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }
    } // -dropdown

    /** dark theme filter select title style */
    &.dark {
        .title{
            border: 1px solid #bfbfbf;
            @include interstate(light);
            background-color: $usoDkerBlue2;
            color: $white;
        }
    } // .dark

} // .select-menu-dropdown2tabs
