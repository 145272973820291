//$font-dir: "../../../../assets/fonts";

@font-face {
    font-family: 'Icons';
    src:  url('#{$font-dir}uso-icons.ttf?j85anq') format('truetype'),
        url('#{$font-dir}uso-icons.woff?j85anq') format('woff'),
        url('#{$font-dir}uso-icons.svg?j85anq#uso-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}
  
@mixin uso-icon($ipkg) {
    font-family       : $ipkg !important;
    font-smoothing    : antialiased;
    font-style        : normal;
    font-weight       : normal;
    font-variant      : normal;
    text-transform    : none;
    speak             : none;
    line-height       : 1;
}

// Icons List
$icons: (
    "icon-watsonx": "\e950",
    "icon-thin-check": "\e94f",
    "icon-qr-code": "\e94e",
    "icon-help": "\e94d",
    "icon-grounds-map": "\e944",
    "icon-fanweek": "\e943",
    "icon-tournament": "\e943", // added to prevent disappearing icon for mobile nav
    "icon-2023tournament": "\e93a", // added to prevent disappearing icon for mobile nav
    "icon-2024tournament": "\e93a", // added to prevent disappearing icon for mobile nav
    "icon-2025tournament": "\e93a", // added to prevent disappearing icon for mobile nav
    "icon-wf-rank": "\e940",
    "icon-wf-arrow-out": "\e945",
    "icon-wf-arrow-up": "\e946",
    "icon-wf-ball": "\e947",
    "icon-wf-checkmark": "\e948",
    "icon-wf-court": "\e949",
    "icon-wf-grass": "\e94a",
    "icon-wf-head-to-head": "\e94b",
    "icon-wf-like": "\e94c",
    "icon-email":"\e93f",
    "icon-photo": "\e93e",
    "icon-draws": "\e936",
    "icon-news": "\e937",
    "icon-players": "\e938",
    "icon-schedule": "\e939",
    "icon-scores": "\e93a",
    "icon-shop": "\e93b",
    "icon-tickets": "\e93c",
    "icon-visit": "\e93d",
    "icon-watch": "\e909",
    "icon-blogIcon-scoring": "\e92b",
    "icon-blogIcon-news": "\e928",
    "icon-link-out": "\e92a",
    "icon-blogIcon-watson": "\e927",
    "icon-blogIcon-video": "\e929",
    "icon-share2":"\e926",
    "icon-info-icon":"\e925",
    "icon-play-button":"\e924",
    "icon-down-arrow": "\e907",
    "icon-up-arrow": "\e90c",
    "icon-arrow-large-left": "\e90a",
    "icon-arrow-large-right": "\e90b",
    "icon-arrow-right": "\e902",
    "icon-ballSolid": "\e903",
    "icon-pdf": "\e904",
    "icon-radio-button": "\e905",
    "icon-radio-selected": "\e906",
    "icon-twistie-right": "\e908",
    "icon-video-camera": "\e909",
    "icon-check": "\e900",
    "icon-facebook": "\e910",
    "icon-instagram": "\e911",
    "icon-snapchat": "\e912",
    "icon-twitter": "\e913",
    "icon-youtube": "\e914",
    "icon-menu": "\e915",
    "icon-pagenation-dot-selected": "\e916",
    "icon-pagenation-dot": "\e917",
    "icon-search": "\e918",
    "icon-share": "\e919",
    "icon-twistie-down": "\e91a",
    "icon-twistie-up": "\e91b",
    "icon-arrow-down": "\e91c",
    "icon-arrow-up": "\e91d",
    "icon-close": "\e91e",
    "icon-collapse": "\e91f",
    "icon-expand": "\e920",
    "icon-greater-than": "\e921",
    "icon-dash":"\e90d",
    "icon-gear-solid":"\e90e",
    "icon-gear":"\e90f",
    "icon-increase":"\e922",
    "icon-reduce": "\e923",
    "icon-info": "\e925",
    "icon-triangle-down": "\e941",
    "icon-triangle-up": "\e942",
    "icon-twistie-close": "\e92c",
    "icon-twistie-open": "\e92d",
    "icon-tickets-solid": "\e92e",
    "icon-plans": "\e933",
    "icon-hospitality": "\e932",
    "icon-tickets1": "\e931",
    "icon-playerSearch": "\e930",
    "icon-mobile": "\e92f",
    "icon-star-fill": "\e934",
    "icon-star-outline": "\e935"
);

.icon,
i[class^=icon-] {
    @include uso-icon('Icons');
}

@each $p, $c in $icons {
    $i: index($icons, ($p $c));
    .#{$p}:before {
        content: $c;
    }
}

// special alignment
.icon-greater-than {
    vertical-align: text-bottom;
}