.faq-section {
    @include general-breakpoint(landscape){
        max-width: $sm-alt-max-content-width;
    }

    @include general-breakpoint(smtablet){
        max-width: $md-alt-max-content-width;
    }

    @include general-breakpoint(smdesktop){
        max-width: $md-max-content-width;
    }

    @include general-breakpoint(desktop){ 
        max-width: $lg-max-content-width;
    }    
    @include make-container();
    background-color: $usoBkgGray;
    margin: 0 auto 16px auto;
    padding-bottom: 30px;

    .faq-wrapper {
        @include make-col-ready();
        max-width: 1280px;
        margin: auto;

        h4 {
            font-size: 2rem;
            color: $usoDkGray;
        }
    }
}

.u-position-absolute {
    position: absolute;
}

.u-position-relative {
    position: relative;
}

.accordion__item--has-icon {
    position: relative;
}


.accordion__body {
    padding: 20px;
    display: block;
    animation: fadein 0.35s ease-in;
}

.accordion__body--hidden {
    display: none;
    opacity: 0;
    animation: fadein 0.35s ease-in;
}

.accordion__title > *:last-child,
.accordion__body > *:last-child {
    margin-bottom: 0;
}

.accordion__arrow {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
}

.accordion__arrow::after,
.accordion__arrow::before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: currentColor;
    content: '';
}

.accordion__arrow::before {
    left: 4px;
    transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before,
[aria-selected='true'] .accordion__arrow::before {
    transform: rotate(-45deg);
}

.accordion__arrow::after {
    right: 4px;
    transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after,
[aria-selected='true'] .accordion__arrow::after {
    transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes move-down {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes move-up {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

.accordion__title--animated:hover .accordion__arrow {
    animation-name: move-down;
    animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
    animation-name: move-up;
    animation-duration: 1.5s;
}

.faq-container .a {
    background-color: rgba(193, 198, 200, .5);

    p {
        padding: 10px 20px;

        // @include media-breakpoint-up(sm) {
        //     padding: 10px 40px;
        // }

        margin: 0;

        &:before {
            content: '';
        }
    }
}

.faq-container {
    @include interstate(light);
    color: $dk-gray2;
    max-width: 1280px;
    margin: auto;

    .q {
        @include interstate(regular);
        background-color: $usoltMedGray;
        display: block;
        margin-bottom: 0;
        border: solid 1px $usoLtGray;
        margin-top: 15px;
        padding: 5px 10px;
        font-size: px2rem(16);
        color: $usoDkGray;
        cursor: pointer;
        
        @include media-breakpoint-up(sm) {
            padding: 10px 10px 10px 20px;
        }

        &.expand {
            background-color: $white;
        }

        &[aria-selected='true'], [aria-expanded='true'], &[aria-selected='false'], [aria-expanded='false'] {

            .ui-icon {
                font-size: px2rem(10);
                float: right;
                // background-image: url(/assets/images/misc/faq_expand_close.png);
            }
        }
        // &[aria-selected='false'], [aria-expanded='false'] {
        //     .ui-icon {
        //         background-image: url(/assets/images/misc/faq_expand_open.png);
        //     }
        // }

        h3 {
            font-size: 1em;
            padding-top: 0.5rem;
        }

    }

    .a {
        background-color: $white;
    }

    .ui-icon {
        background-size: contain;
        background-repeat: no-repeat;
        width: 17px;
        height: 1em;
        margin: 2px 5px;
        display: block;
        float: left;

        &.ui-icon-triangle-1-s {
            background-image: url(/assets/images/misc/faq_expand_close.png);
        }

        &.ui-icon-triangle-1-e {
            background-image: url(/assets/images/misc/faq_expand_open.png);
        }
    }
}

.faq-section+.uso-interactive {
    display: block;
    background-color: $lt-gray;
    margin-top: -16px;
}

#year-selector {
    margin: 20px auto;
    padding: 20px 40px;
    float: left;

    >div {
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;
        float: left;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 10px;

        li {
            float: left;
            margin: 10px;
        }
    }
}

h3.year {
    font-size: calc((16/14) * 1em);
    color: #cf102d;
    @include interstate(medium);
}

.years {
    a {
        display: block;
        float: right;
    }
}

.link-btn {
    background: $dark_blue2 url(/assets/images/misc/arrow_right_ltblue.png) no-repeat 95% 50%;
    border: solid 1px $border_color;
    font-size: calc((11/13) * 1em);
    margin: 20px auto;
    padding: 0.5em 6%;
    text-align: center;
    text-transform: uppercase;
    width: 50%;

    a {
        @include interstate(bold);
        color: $button_link_color;
        display: block;
        text-decoration: none;
    }
}
