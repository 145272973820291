.light-blue-bar-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $usoDkBlue;
	padding: 23px 2rem 21px 2rem;
	font-size: 14px;
	border-top: 1px solid $usoMdGray;
	min-height: 60px;
}
.lb-item-wrapper {
	a {
		&:hover {
			text-decoration: none;
		}
	}
}

.lb-events-wrapper {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	font-size: 12px;
}

.lb-event-name {
	list-style-type: none;
	color: $white;
	cursor: pointer;
	padding: 0 1rem;

	&.active {
		text-decoration: underline;
		text-underline-offset: 23px;
		text-decoration-thickness: 3px;
		font-weight: 700;
		color: $white;
	}

	&::after {
		content: attr(data-text);
		content: attr(data-text) / '';
		height: 0;
		display: block;
		visibility: hidden;
		overflow: hidden;
		user-select: none;
		pointer-events: none;
		font-weight: 700;
	}

	&:hover {
		color: $white;
		font-weight: 700;
	}
}

.visit-select-dropdown-wrapper {
	select {
		@include interstate();
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url('/assets/images/icons/dropdown-arrow-ltgray.svg');
		background-repeat: no-repeat;
		background-position-x: 94%;
		background-position-y: 50%;
		background-color: $usoDkBlue;
		color: $white;
		border-radius: 3px;
		border-color: $white;
		text-align: center;
		padding: 2px 7px 2px 4px;
		margin: 8px;
		width: 160px;
	}
}
