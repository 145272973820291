// Based on Flexbox Grid https://github.com/kristoferjoseph/flexboxgrid

@import 'mixins/breakpoint';
@import 'mixins/grid-mixins';
@import 'mixins/flex';
@import 'visibility';
@import 'size';

// No gutters
// No text alignment with justified container classes (center-xs, for example)

.row {
    @include row();
}

.row.reverse {
    @include row-reverse();
}

.row.eq-height {
    @include row-eq-height();
}

.row.auto-height {
    @include row-auto-height();
}

.row.flex-column {
    flex-direction: column;
}

.col {
    @include col();
}

.col.reverse {
    @include col-reverse();
}

@each $prefix in $breakpoint-all-prefixes {
    @include general-breakpoint($prefix) {
        .col.reverse-#{$prefix} {
            @include col-reverse();
        }
        .row.reverse-#{$prefix} {
            @include row-reverse();
        }
    }
}

.first {
    order: -1;
}

.last {
    order: 1;
}

.align-start {
    align-self: flex-start;
}

.align-end {
    align-self: flex-end;
}

.align-center {
    align-self: center;
}

.align-baseline {
    align-self: baseline;
}

.align-stretch {
    align-self: stretch;
}
// Mixin to run inside of for loop - creates col/breakpoint classes * Not for developer use *
@mixin col-factory($thisPrefix) {
    .col-#{$thisPrefix} {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
        padding: $gutter;
    }
    @for $i from 1 through $grid-columns {
        .col-#{$thisPrefix}-#{$i} {
            flex-basis: 100% / $grid-columns * $i;
            max-width: 100% / $grid-columns * $i;
            padding: $gutter;
        }

        .col-#{$thisPrefix}-offset-#{$i} {
            margin-left: 100% / $grid-columns * $i;
        }
    }

    .start-#{$thisPrefix} {
        justify-content: flex-start;
    }

    .center-#{$thisPrefix} {
        justify-content: center;
    }

    .end-#{$thisPrefix} {
        justify-content: flex-end;
    }

    .top-#{$thisPrefix} {
        align-items: flex-start;
    }

    .middle-#{$thisPrefix} {
        align-items: center;
    }

    .bottom-#{$thisPrefix} {
        align-items: flex-end;
    }

    .around-#{$thisPrefix} {
        justify-content: space-around;
    }

    .between-#{$thisPrefix} {
        justify-content: space-between;
    }

    .first-#{$thisPrefix} {
        order: -1;
    }

    .last-#{$thisPrefix} {
        order: 1;
    }
}
// Mixin to run inside of for loop - creates col/breakpoint classes
@each $prefix in $breakpoint-up-prefixes {
    @include general-breakpoint($prefix) {
        @include col-factory($prefix);
    }
}

.col-gutter-lr {
    padding: 0 $gutter;
}

.col-no-gutter {
    padding: 0;
}
