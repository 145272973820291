.modal-overlay-div {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2147483646;
    background-color: rgba(0, 0, 0, .8);
}

.modal-content-div {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2147483646;
    overflow: auto;
    text-align: center;
    cursor: pointer;
}

.modal-content-div:after {
    vertical-align: middle;
    display: inline-block;
    height: 100%;
    margin-left: -.05em;
    content: '';
}

.slamtracker-content{
    .modal-dialog-div {
        .inlineVideo{
            height: 50vh;
            @include general-breakpoint(smtablet){
                height: 85vh;
            }
        }
    }
}

.modal-dialog-div {
    position: relative;
    outline: 0;
    width: auto;
    display: inline-flex;
    vertical-align: middle;
    box-sizing: border-box;
    max-width: auto;
    cursor: default;
    border-radius: 4px;
    img{
        width: 100%; height: 100%;
    }
    .inlineVideo{
        width: 85vw; /*height: 85vh;*/ position: relative; margin: 0 auto; @include aspect-ratio(16, 9);
    }
    .icon-close{ 
        color: $white;
        font-size: 20px;
        margin-left: 10px;
        cursor: pointer;
        position: absolute;
    }
}