.mi-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    z-index: 950;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 316px;
        background-color: white;
        position: relative;
        filter: drop-shadow(0px 2px 4px rgba(0,0,0, 0.5));
        @include general-breakpoint(mobile, max){
            width: 100vw;
        }
        .modal-close{
            font-size: 22px;
            color: $usoLtBlueBG;
            position: absolute;
            right: 17px;
            top: 17px;
            cursor: pointer;
        }
        .modal-header{
            font-size: 20px;
            color: $usoLtBlueBG;
            letter-spacing: 1px;
            text-align: center;
            line-height: 22px;
            margin-top: 50px;
            text-transform: uppercase;
            padding: 0 55px;
        }
        .spacerLine{
            width: 19px;
            border-top: 2px solid $usoDkGray;
            margin: 15px 0 20px 0;
        }
        .modal-body{
            font-size: 14px;
            color: $usoDkGray;
            letter-spacing: 0;
            text-align: center;
            line-height: 18px;         
            padding: 0 33px;   
            max-width: 461px;
            margin: auto;
            @include general-breakpoint(smtablet, max){
                padding: 0 33px 80px 33px;
            }
        }
    }
}