.homeInfo { 
    background-color: $white; 
    // padding: 0px 16px 0px;

    a, a:hover{ 
        text-decoration: none;
    }

    .content {
        padding: 23px 16px 0px;
    }

    // .header{ @include interstate; color: $dk-gray2; padding-bottom: 15px; font-size: px2rem(18); line-height: px2rem(21); }
    // .content{ height: 100%; }
    .bullets{ 
        @include interstate; 
        font-size: px2rem(14); 
        color: $md-gray; 
        margin-left: 15px; 
        padding-left: 5px; 
        height: 100%;

        li{
            padding-bottom: 10px; 
            line-height: 20px;
            

            &.icon-indent {
                /** makes the second line to line up with 
                    the first line when wrapped */
                text-indent: -6px;
                padding-left: 6px;
            }

            a { 
                color: $md-gray; 
                &:hover{ color: $bright-blue;}

                &.home-radio-link {
                    cursor: pointer;
                }
            }

            i[class^=icon-] {
                background-color: $white;
                margin-left: -18px;
                padding-right: 10px;
                color: $usoLtBlue;
            }
        } // light

        &.no-bullets {
            list-style: none;
            margin-left: 0;
        }

        &.two-cols {
            column-count: 2;
        }

        &.four-cols {
            column-count: 4;
        }
    }

    .button{ padding-bottom: 10px;}

    .allStories { 
        @include interstate(light); 
        font-size: px2rem(16); 
        line-height: 19px; 
        color: $bright-blue; 
        display: none;
    }

    // @include general-breakpoint(smtablet){
    //     width: 100%; min-width: 347px; height: 268px; order: 2; margin-top: 22px; padding: 20px 22px;
    //     .header{ font-size: px2rem(18); line-height: px2rem(21); }
    //     .content{ overflow: hidden;}
    //     .headlines{ margin-left: 15px;
    //         li{ line-height: 20px; font-size: px2rem(14);}
    //     }
    //     .allStories{ visibility: hidden;}
    // }

    // @include general-breakpoint(desktop){
    //     order:1; margin-top: 0px; padding: 25px 15px; min-width: 300px;width: 300px; max-width: 300px; height: 600px; flex-grow: 1;
    //     .header{ font-size: px2rem(24); }
    //     .headlines{
    //         li{ font-size: px2rem(16);}
    //     }
    //     .allStories{ visibility: visible;}
    // }

}