//stage item backgrounds
$si-bg-small: '/assets/images/scores/slamtracker/backgrounds/stage_items_sm.jpg';
$si-bg-large: '/assets/images/scores/slamtracker/backgrounds/stage_items_lg.jpg';
$si-bg-img: '/assets/images/scores/slamtracker/backgrounds/stage_items_bkg.png';

.stage-item {
    // background-color: $lt-gray2;
    // background: url($si-bg-small);

    // .item {
    //     background-color: $lt-gray2;
    // }
  &.depth-of-return {
    background: url($si-bg-img) no-repeat;
    background-size: cover;
  }
  &.serve-placement {
    background: url($si-bg-img) no-repeat;
    background-size: cover;
  }
  &.si-wrapper {
    &.si-inner-wrapper .serve-speed-wrapper {
        background:rgba(255,255,255,0.2);
    }
  }
}

@include stage_item_breakpoint(medium) {
    .embedded {
        .stage-item {
            // background-color: $lt-gray2;
            background: url($si-bg-img);
        }
    }
}

/*  stage item includes */

@include stage-common;

@include stage-item-breakpoint(xsmall) {
    @include stageitem-xsmall;
}

@include stage-item-breakpoint(small) {
    @include stageitem-xsmall;
    @include stageitem-small;   // include the small stage item css
}

@include stage-item-breakpoint(medium) {
    .embedded {
        @include stageitem-large;
    }
}
