/** uso-footer styles **/

#uso-footer {
    @include interstate(regular);
    background: $lt-gray;
    background-color: $lt-gray;
    color: $gray;
    padding-bottom: 30px;
    padding-top: 10px;
    // .footer-date {
    //     /* font-size: 1.35vw; */
    //     position: relative;
    //     top: 55%;
    // }
    #footer-icons {
        ul {
            // display: block;
            list-style-type: none;
            height: 25px;
            margin: 0;
            padding: 0;
            display: inline-flex;
            flex-direction: row-reverse;
            flex-wrap: nowrap;
            // justify-content: flex-end;
            align-items: center;
            // align-content: center;
        }
        li {
            // float: right;
            padding: 0;
            margin: 0px 6px;
        }
        li a {
            // display: block;
            // padding: 0 7px;
            text-decoration: none;
            display: inline-flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            vertical-align: middle;

        }
        // .v-snapchat {
        //     margin-top: 3px;
        // }
        // .v-youtube {
        //     margin-top: 4px;
        // }
        // .v-instagram {
        //     margin-top: 2px;
        // }
        // .v-twitter {
        //     margin-top: 3px;
        // }
        // .v-facebook {
        //     margin-top: 4px;
        // }
        // .v-tiktok {
        //     margin-top: 4px;
        // }
    }
    @media (min-width: 0) and (max-width: 648px) {
        #footer-icons {
            visibility: hidden;
        }
    }

    .row {   
        &.top-row {
            justify-content: space-between;
            .logo {
                width: 50%;
                @include general-breakpoint(smdesktop) {
                    width: unset;
                }
            }
            .text-center {
                order: 1;
                margin: 40px auto;
                text-transform: uppercase;
                @include general-breakpoint(smtablet) {
                    margin: 20px auto;
                }
                @include general-breakpoint(tablet) {
                    order: 0;
                    position: relative;
                    top: 35%;
                    margin: 0;
                }
            }
        }
        &.middle-row {
            justify-content: center;
            align-items: center;
            margin-top: 25px;
        }

    }
    #footer-links {
        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            list-style-type: none;
            // height: 25px;
            line-height: 2.5;
            margin: 0;
            padding: 0;
        }
        li {
            float: left;
            padding: 0;
        }
        li a {
            display: block;
            color: $gray;
            padding: 0 8px;
            text-decoration: none;
        }
        li a:hover {
            cursor: pointer;
            color: $cyan;
        }
    }
}

#footer-social-menu {
    display: none;
    width: 100%;
    float: left;
    position: fixed;
    top: 55px;
    bottom: 0;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 105;
    background: $dk-gray2;
    color: $white;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    &.open {
        display: block;
    }
    a {
        color: $white;
        text-decoration: none;
    }
    .close-menu {
        position: absolute;
        left: 10px;
        top: 10px;
    }
    .section {
        display: block;
        width: 100%;
        height: 100px;
        border-top: 1px solid $white;
        text-align: center;
        padding-top: 20px;
    }
}

body.social {
	#uso-header {
    	position: fixed;
    	top: 0;
    	left: 0;
	}

	.menu-container {
		&.menu-opened {
			position:fixed;
			top: 55px;
			left: 0;
			height: 100%;
			overflow: scroll;
			padding-bottom: 55px;
		}
	}

}
