/** visit tabs **/
.visitTabs{
    text-align: center;
    border-bottom: 1px solid $gray;
    padding: 10px 0;
    ul{
      margin-bottom: 0px;
      li{
        display: inline-block;
        line-height: 2em;
        border-right: 1px solid $md-gray;
        padding-right: 7px;
        padding-left: 7px;
        a{
          text-decoration: none;
          color: $md-gray;
          font-size: 1.5em;
          &.active{
            border-bottom: 2px solid $md-gray;
            font-weight: bold;
          }
          &:hover{
            border-bottom: 2px solid $md-gray;
          }
        }
        &:last-child{
          border: none;
        }
      }
    }
  }

  /*** Alpha Dropdown ***/
.alphaBar, .alphaBarMobile {
    height: 60px; text-align: center; border-bottom: 1px solid $gray;
    line-height: 60px; background-color: $white;
    select {
      background: none;
      border: none;
      color: $gray;
      font-size: 1em;
      display: inline-block;
      overflow: hidden;
      position: relative;
      cursor: pointer;
    }
    ul{ padding: 0; margin: 0; display: inline-block;
      li{ list-style: none; font-size: 1.5rem; color: $gray; float: left; margin: 5px;
        a{ text-decoration: none; color: $dk-gray2; }
      }
    }
  }
  .alphaBarMobile {
    @include general-breakpoint(landscape) { display: none; }
  }
  .alphaBar {
    @include general-breakpoint(landscape, max) { display: none; }
  }
  