.slamtracker {
    .unit-toggle {
        position: relative;
        // float: left;
        // width: 100%;
        width: calc(100% / 3);
        text-align: center;
        // margin-bottom: 10px;
        // margin-top: -38px;
        // padding-top: 3px;
        display: table-cell;
        vertical-align: middle;

        label {
            color: $md-gray;
            @include interstate;
            font-size: 12px;
            display: inline-block;
        }
        input[type="radio"] {
            vertical-align: middle;
            outline: none;
            margin: 0px 3px 0px 7px;
                    
            &:checked+label {
                @include interstate(bold);
            }
        }
        a {
            cursor: pointer;
            color: $md-gray;

            i {
                margin-right: 5px;
                font-size: 12px;
            }
            .icon-radio-button {
                display: inline;
            }
            .icon-radio-selected {
                display: none;
            }
            &.selected {
                .icon-radio-button {
                    display: none;
                }
                .icon-radio-selected {
                    display: inline;
                }
            }
            &:hover {
                color: $md-gray;
            }
        }
        .unit-imperial { margin-left: 10px; }
    }
    .fixed {
        .unit-toggle {
            display: none;
        }
    }
}

@include general-breakpoint(smtablet) {
    .slamtracker {
        .unit-toggle {
            width: 20%;
            text-align: center;
            margin: -30px 40% 0px;
            display: block;
        }
    }
}