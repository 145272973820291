/**
 * column layout 
 */

.wrapper .content-main {
	&.player-stats {
		.player-data-toggle {
			font-size: 14px;
			margin-bottom: 20px;
			text-align: center;

			span {
				&.show {
					font-weight: bold;
					border-bottom: solid 1px $md-gray;
				}
			}

			span:first-of-type {
				margin-right: 10px;
			}

			span:last-of-type {
				margin-left: 10px;
			}

			@include general-breakpoint(smtablet) {
				display: none;
			}
		}

		.scores-select-menu {
			margin: auto;
			margin-bottom: 20px;
			border-bottom: solid 1px $md-gray2;
			padding: 5px;

			select {
				appearance: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				background-image: url('/assets/images/icons/dropdown-arrow.svg');
				background-repeat: no-repeat;
				background-position-x: 94%;
				background-position-y: 50%;

				@include interstate();
				position: relative;
				border-radius: 3px;
				width: 220px;
				height: 26px;
				font-size: 14px;
				text-align: center;
				color: $usoLtBlue;
				border-color: $usoLtBlue;
				overflow: hidden;
			}
			.select-menu {
				button {
					text-transform: capitalize;
				}
			}
		}
		.table-title {
			margin-bottom: 15px;
			margin-top: 15px;
			.left-nav-dot,
			.right-nav-dot {
				color: #000;
				opacity: 0.5;
				&.selected {
					opacity: 1;
				}
			}
			i {
				color: $grey;
			}
		}
	}
}

@media only screen and (max-width: $column-break-1) {
	.wrapper .content-main {
		&.player-stats {
			.datatable {
				.cell {
					display: none;
					&.selected {
						display: table-cell !important;
					}
				}
			}
		}
	}
}
