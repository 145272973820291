.breakingNews {
    margin: 0px;
    padding: 9px 10px;
    color: $white;
    font-size: 12px;
    @include interstate;
    display: block;
    position: sticky;
    display: flex;
    flex-direction: column;
    cursor: default;

    @include general-breakpoint(landscape) {
        // margin: 0 -15px;
        font-size: 18px;
        padding: 9px 26px;
        flex-direction: row;
    }

    &.hidden {
        display: none;
    }

    a,a:hover {
        text-decoration: none;
        color: $white;
    }

    .bn-flex-container{
        display: flex;
        flex-grow: 1;
    }

    .bn-icon {
        display: inline-block;
        vertical-align: middle;
        width: 20px;

        @include general-breakpoint(smtablet) {
            width: 30px;
        }
    }

    .bn-link {
        // padding-left: 10px;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .bn-bold-link {
        font-weight: bold;

        &:after {
            content: ' \003E'
        }
    }

    .bn-content {
        flex-grow: 1;
        display: inline-block;
        @include interstate(light);
        width: 60%;
        overflow: hidden;
        vertical-align: middle;
        padding-right: 15px;

        .bn-link {
            cursor: pointer
        }

        &.hoverable {
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        } 

        // @include general-breakpoint(landscape) {
        //     width: 65%;
        // }

        // @include general-breakpoint(smdesktop) {
        //     width: 70%;
        // }

        // @include general-breakpoint(desktop) {
        //     width: 75%;
        // }

        &.textfield {
            width: 100%;
            @include interstate;
            font-size: 16px;
            text-transform: uppercase;
            margin: 0px auto;
            display: flex;
            flex-direction: column;
            @include general-breakpoint(landscape){
                flex-direction: row;
            }

            .msg-area{
                flex: 1 1;
                justify-content: center;
                display: flex;
                flex-direction: column;
            }

            // span{ width: 100%; display: inline-block;}
            input[type="submit"] {
                cursor: pointer;
                width: 25%;
                height: 25px;
                text-transform: uppercase;
                font-size: 12px;
                vertical-align: middle;
                background-color: $md-gray2;
                color: $dk-gray2;
                border-color: $md-gray2;
                vertical-align: middle;
            }

            input[type="text"] {
                width: 70%;
                height: 25px;
                font-size: 12px;
                @include interstate(light);
                border: 0px;
                padding: 5px;
                vertical-align: middle;

                &.error {
                    outline: none;
                    border: 1px solid $red;
                    background-color: #fce4e4;

                    &::placeholder,
                    &::-moz-placeholder,
                    &::-webkit-input-placeholder,
                    &::-ms-input-placeholder {
                        color: $red;
                    }
                }
            }

            @include general-breakpoint(landscape) {
                font-size: 16px;

                // span{ width: 60%; }
                input[type="submit"] {
                    width: 75px;
                    height: 25px;
                    font-size: 12px;
                }

                input[type="text"] {
                    width: 190px;
                    height: 25px;
                }
            }

            @include general-breakpoint(smdesktop) {
                font-size: 22px;

                // span{ width: 65%; }
                input[type="submit"] {
                    width: 100px;
                    height: 30px;
                    font-size: 12px;
                }

                input[type="text"] {
                    width: 200px;
                    height: 30px;
                }
            }

            @include general-breakpoint(desktop) {
                width: 85%;
                font-size: 20px;

                // span{ width: 55%; } 
                input[type="submit"] {
                    width: 130px;
                    height: 40px;
                    font-size: 14px;
                }

                input[type="text"] {
                    width: 330px;
                    height: 40px;
                    font-size: 16px;
                }
            }

            @include general-breakpoint(xldesktop) {
                input[type="text"] {
                    width: 390px;
                }
            }
        }
    }

    .ar-icon {
        position: absolute;
        top: 10px;
        right: 5px;
        cursor: pointer;
    }

    .bn-button {
        display: inline-flex;
        justify-content: flex-end;
        padding-right: 12px;
        white-space: nowrap;
        @include general-breakpoint(landscape){
            justify-content: flex-start;
            flex-direction: column;
        }
    }

    &.newsletter {
        display: flex;
        background-color: $dark-blue3;
        color: $white;
        margin: 0px;

        form {
            display: flex;
            width: 100%;
        }

        .input-area {
            display: flex;
            align-items: center;
            padding-right: 12px;
            margin-top: 10px;
            @include general-breakpoint(landscape) {
                margin-top:0;
            }
        }
    }

    &.article-banner {
        background-color: gray;
        margin-bottom: 30px;
        .alert-subtext {
            text-transform: initial;
            padding-right: 32px;
            font-size: 10px;
            @include general-breakpoint(landscape) {
                font-size: 14px;
            }

            @include general-breakpoint(smdesktop) {
                font-size: 20px;
            }

            @include general-breakpoint(desktop) {
                font-size: 18px;
            }
        }
    }

    &.bn-theme {
        background-color: $dk-gray2;
        color: $white;

        i {
            color: $bright-blue;

            &.icon-close {
                color: $white;
            }
        }
    }

    &.bn-themelight {
        background-color: $md-gray2;
        color: $dark-blue3;
    }

    &.bn-themedark {
        @extend .bn-theme;
    }
}