/* 
* {
margin: 0;
padding: 0;
}
html, body {
height: 100%;
font-family: 'Helvetica Neue', Arial;
}
*/
.active-slide {
}
.hsldr-nav-region {
position: absolute;
height: 2px;
width: 20px;
top: 0px;
left: 0px;
background-color: #000;
}
.hsldr-nav-wrapper {
position: absolute;
bottom: 0;
text-align: center;
left: 50%;
transform: translateX(-50%);
-webkit-transform: translateX(-50%);
}
.hsldr-nav-marker {
content: "";
display: inline-block;
height: 2px;
width: 200px;
background-color: #bbb;
float: left;
}
.hsldr-container {		
position: relative;
width: 100%;
height: 80vh;
max-height: 630px;
overflow: hidden;
opacity: 0;
transition: opacity 0.5s ease-in-out;
}
.hsldr-container.hsldr-has-nav-bar {
height: calc( 80vh + 30px );
}
.hsldr-gallery-container {
position: relative;
left: 0;
transition: all 0.5s ease-in-out;
overflow: hidden;
width: 99999px;
}
.hsldr-image-container {
float: left;
display: -ms-flexbox; /* TWEENER - IE 10 */
display: -webkit-flex; /* NEW - Chrome */
display: flex; 
}
.hsldr-image-container img {
width: auto;
height: 80vh;
max-height: 600px;
padding-right: 1px;
padding-left: 1px;
}
.hsldr-container figure, .hsldr-container li {
position: relative;
}
.hsldr-container figcaption,
.hsldr-container .caption {
position: absolute;
left: 0;
top: 90%;
min-height: 6.1em;
background-color: rgba(255, 254, 254, 0.80);
color: rgb(105, 105, 105);
width: 100%;
padding: 3%;
opacity: 0;
text-align: center;
transform: translateY(-50%);
transition: opacity 1.3s ease-in-out;
@include interstate(light);
font-size: 1.1vw;
// font-weight: 400;
line-height: 1.5em;
}
.hsldr-image-container:before {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
background-color: rgba(0,0,0,0);
content: '';
display: block;
z-index: 0;
transition: background-color 0.3s ease-in-out;
}
.hsldr-container .showcaption:before {
background-color: rgba(0, 0, 0, 0);
z-index: 1;
}
.fade-in {
opacity: 1;
}
/* .info {
content: "";
display: block;
width: 20px;
height: 20px;
cursor: pointer;
visibility: visible;
position: absolute;
right: 10px;
bottom: 10px;
opacity: 1;
z-index: 2;
}
.info:before {
content: "i";
width: 15px;
height: 15px;
display: block;
text-align: center;
font-family: Georgia;
font-style: italic;
color: #fff;
background-color: rgba(0,0,0,0.1);
padding: 2px 3px 5px;
border-radius: 2px;
} */
.showcaption .info:before, .showcaption .info:after {
position: absolute;
left: 11px;
top: 4px;
content: ' ';
height: 15px;
width: 2px;
background-color: #fff;
padding: 0;
}
.showcaption .info:before {
transform: rotate(45deg);
}
.showcaption .info:after {
transform: rotate(-45deg);
}
.hsldr-container .showcaption figcaption,
.hsldr-container .showcaption .caption {
visibility: visible;
opacity: 1;
z-index: 1;
}
.hsldr-next, .hsldr-prev {
position: absolute;
top: 48%;
transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-moz-transform: translateY(-50%);
width: 15px;
height: 30px;
background-color: rgba(0,0,0,0);
color: #fff;
transition: background-color 0.3s ease-in-out;
padding: 15px 20px;
z-index: 4;
}
.hsldr-has-nav-bar .hsldr-next,
.hsldr-has-nav-bar .hsldr-prev {
top: calc( 50% - 15px );
}
.hsldr-next {
right: 10px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
}
.hsldr-next:before, 
.hsldr-next:after,
.hsldr-prev:before,
.hsldr-prev:after {
width: 15px;
height: 2px;
background-color: #fff;
display: block;
content: '';
position: relative;	 
}
.hsldr-next:before {
-ms-transform: rotate(45deg); /* IE 9 */
-webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
-moz-transform: rotate(45deg); /* Chrome, Safari, Opera */
transform: rotate(45deg);
top: 9px;
left: 0;
}
.hsldr-next:after {
-ms-transform: rotate(-45deg); /* IE 9 */
-webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
transform: rotate(-45deg);
-moz-transform: rotate(-45deg); /* Chrome, Safari, Opera */
top: 17px;
left: 0;
}
.hsldr-prev {
left: 0;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
}
.hsldr-prev:before {
-ms-transform: rotate(-45deg); /* IE 9 */
-webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
-moz-transform: rotate(-45deg); /* Chrome, Safari, Opera */
transform: rotate(-45deg);
top: 9px;
left: 0;
}
.hsldr-prev:after {
-ms-transform: rotate(45deg); /* IE 9 */
-webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
-moz-transform: rotate(45deg); /* Chrome, Safari, Opera */
transform: rotate(45deg);
top: 17px;
left: 0;
}
.hsldr-next:hover,
.hsldr-prev:hover {
background-color: rgba(0,0,0,0);
}
span.clear-bottom { height: 65px; display: inline-block; }