/** 
 * head to head content section
 * includes:
 *   Head to head and stats tabs and content
 *   Past Matchups
 *   Shot Quality
 **/

 .slamtracker-headtohead-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include panel-content-background;
    // width: 100%;

    .headtohead-stats-content {
        // width: 373px;
        // max-width: 373px;
        // margin-left: auto;
        // margin-right: auto;

        .page-nav-container {
            margin-bottom: 0;
        }
        .headtohead, .stats {
            &-content {
                padding: 0 0 22px 0;
                display: flex;
                flex-direction: column;
                @include interstate(regular);
                font-size: px2rem(12);

                &-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    min-height: 20px;
                    position: relative;
                    padding: 6px 12px 0 13px;
                    margin: 3px 0;
                    .t1, .t2{
                        @include interstate;
                    }

                    .t1 {
                        // color: $wim-text-purple;
                        width: 50%;
                        text-align: left;
                        align-self: start;
                    }
                    .t2 {
                        // color: $wim-green;
                        width: 50%;
                        text-align: right;
                        align-self: start;
                    }
                    .label {
                        // color: $wim-black;
                        @include interstate;
                        text-transform: uppercase;
                        position: absolute;
                        left: 18%;
                        width: 64%;
                        text-align: center;
                        align-self: start;
                    }
                    &.players {
                        margin: 14px 0;
                        height: 48px;
                        border-top: 1px solid #ECECEC;
                        border-bottom: 1px solid #ECECEC;
                        @include interstate(regular);
                        padding: 0 12px 0 13px;
                        align-items: center;                
                        .t1, .t2, .label{
                            align-self: unset;
                            @include interstate(bold);
                        }
                        &:first-child {
                            margin-top: 0;
                            border-top: none;
                        }
                    }
                }
                &.doubles {
                    padding-top: 0;
                }
                .courtesy-of-text{
                    text-align: center;
                    padding-top: 23px;
                }
            }
        }
        .stats {
            &-content {
                &-row {
                    .t1 {
                        align-self: center;
                    }
                    .t2 {
                        align-self: center;
                    }
                    .label {
                        align-self: center;
                    }
                }
            }
        }
        .headtohead, .stats {
            &-content {
                &-row {
                    .t1, .t2 {
                        width: 35%;
                    }
                }
            }
        }
        .stats {
            padding: 0;

            &-content {
                padding: 0 12px 0 13px;

                &-row {
                    border-top: 1px solid #ECECEC;
                    height: 37px;

                    &:first-child {
                        border-top: none;
                    }
                    .t1 {                        
                        .stat{
                            border: 2px solid rgba(#ffff, 0);
                            border-radius: 15px;
                            padding: 1px 0px;
                            margin-left: -10px;
                            color: $md-gray4;
                            width: 64px;
                            display: inline-block;
                            text-align: center;
                            white-space: nowrap;
                        }
                        &.winner .stat {
                            color: $gray-4a;
                            border: 2px solid $usoLtBlueBG;
                            @include interstate(medium);
                        }
                    }
                    .t2 {
                        color: $gray-4a; 
                        .stat{
                            border: 2px solid rgba(#ffff, 0);
                            border-radius: 15px;
                            padding: 1px 0px;
                            margin-right: -10px;
                            color: $md-gray4;
                            width: 64px;
                            display: inline-block;
                            text-align: center;
                            white-space: nowrap;
                        }
                        &.winner .stat{
                            color: $gray-4a;
                            border: 2px solid $usoYellow;
                            @include interstate(medium);
                        }
                    }
                    
                    &.header {
                        height: 50px;
                        display: flex;
                        .t1, .t2{
                            color: #333333;
                            @include interstate(medium);
                        }
                        // .t1 { color: $wim-text-purple; }
                        // .t2 { color: $wim-green; }
                    }
                    &.win-loss {
                        .t1, .t2{
                            .stat {
                                color: #333333;
                                @include interstate(medium);
                            }
                        }
                    }
                    &.disclaimer {
                        border-top: 1px solid #ECECEC;
                    }
                }

                &.metric {
                    .stats-content-row.metric {
                        display: flex;
                    }
                    .stats-content-row.imperial {
                        display: none;
                    }
                }
                &.imperial {
                    .stats-content-row.imperial {
                        display: flex;
                    }
                    .stats-content-row.metric {
                        display: none;
                    }
                }
            }
        }
    }

    .pastmatchups {
        .pastmatchups-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 16px 13px;
            // border-top: 1px solid #999999;
            
            &:first-child {
                border-top: none;
            }
            &-row {
                @include interstate(regular);
                font-size: px2rem(12);
                color: black;
                .status-container{
                    background-color: $lt-gray;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 16px 0 10px 0;
                }
    
                .tournament {
                    font-size: px2rem(14);
                    text-transform: uppercase;
                    // color: $wim-text-purple;
                    margin-bottom: 3px;
                    text-align: center;
                }
                .round-opponent {
                    @include interstate(regular);
                    margin-bottom: 3px;

                    .round {
                        text-transform: uppercase;
                    }
                }
                .status {
                    margin-bottom: 3px;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                    .p1, .p2{
                        width: 33%;
                        display: flex;
                        position: relative;
                        .flag{
                            margin-right: 5px;
                            img{
                                height: 10px;
                            }
                        }
                        .player{
                            white-space: nowrap;
                        }
                        .icon-check{
                            color: $usoGreen;
                            font-size: 10px;
                            position: absolute;
                            bottom: -15px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                    .p1{
                        padding-left: 15px;
                    }
                    .p2{
                        padding-right: 15px;
                        justify-content: flex-end;
                    }
                }
            }
        }
        .pastmatchups-showmore {
            display: flex;
            height: 80px;
            justify-content: center;
            align-items:center;
            // border-top: 1px solid #999999;

            

            button{
                @include button();
                border: 0;
                width: 157px;
                height: 34px;
            }
        }
    }

    .shotquality {
        &-content {
            padding: 0 12px 10px 13px;

            .shotquality-item {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 14px;

                &-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;

                    &.header {
                        width: 100%;
                        padding-left: calc(25% + 6px);
                        padding-bottom: 5px;
                        text-transform: uppercase;
                        @include interstate(regular);
                        font-size: px2rem(12);
                        // color: $wim-black;
                    }
                    &.p1 {
                        // color: $wim-text-purple;

                        .bar {
                            // background-color: $wim-text-purple;
                        }
                    }
                    &.p2 {
                        // color: $wim-green;

                        .bar {
                            // background-color: $wim-green;
                        }
                    }
                    &.avg {
                        // color: $md-gray2;

                        .bar {
                            // background-color: $md-gray2;
                        }
                    }
                    .label {
                        width: 30%;
                        padding-right: 6px;
                        text-align: right;
                        @include interstate(regular);
                        font-size: px2rem(12);
                        align-self: center;
                    }
                    .bar {
                        max-width: 58%;
                        height: 10px;
                    }
                    .number {
                        width: 12%;
                        padding-left: 6px;
                        @include interstate(regular);
                        font-size: px2rem(18);
                    }
                }
            }
        }
    }

    .disclaimer {
        @include interstate(regular);
        font-size: px2rem(11);
        color: black;
        width: 100%;
        height: 54px !important;
        align-items: center;
        display: flex;
        justify-content: center !important;
        flex-direction: column !important;
    }
}