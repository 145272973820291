.slamtracker {
    &.metric {
        div.metric {
            display: block;
        }
        span.metric {
            display: inline-block;
        }
        .imperial {
            display: none;
        }
    }
    &.imperial {
        div.imperial {
            display: block;
        }
        span.imperial {
            display: inline-block;
        }
        .metric {
            display: none;
        }
    }

    .section-title-header{
        width: 100%;
        height: 57px;
        background-color: white;
        border-bottom: 1px solid $md-gray2;
        padding-left: 15px;
        display: flex;
        font-size: px2rem(16);
        align-items: center;
        @include interstate(bold);
        text-transform: uppercase;
    }

    .divider-header{
        width: 100%;
        background-color: $gray_f3;
        span{
            width: 100%;
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: px2rem(14px);            
            color: $dk-gray2 !important; 
            text-transform: uppercase;
            @include interstate;
        }
    }

    .page-nav-list.slamtracker-headtohead-tabs{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        list-style: none;
        margin-bottom: 0px;
        width: 100%;
        height: 44px;
        padding: 0px;
        margin: auto;
        margin-top: 0px;
        background-color: $lt-gray;
        
        .divider{
            display: none;
        }
        .page-nav-items {
            width: 50%;
            padding: 8px 0 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: px2rem(16px);
            background-color: $gray_f3;
            border-bottom: 3px solid rgba(#ffffff, 0.0);
            span, a{
                line-height: 16px;
            }
            a{                
                @include interstate(light);
                color: $md-gray !important;
            }
            &.active{
                border-bottom: 3px solid $dk-gray2;
                span{
                    color: $dk-gray2 !important; 
                    @include interstate;
                }
            }
        }
    }

    .divider-stripes{
        width: 100%;
        display: flex;
        .left, .right{
            flex: 1 1;
            height: 9px;
        }
        .left{            
            background-color: $usoLtBlue;
        }
        .right{
            background-color: $usoYellow;
        }
    }

    .select-menu {
        //background: $white;
        //border: 2px solid $gray-light;
        color: $dk-gray2;
        width: auto;
        max-width: 303px;
        //width: 250px;
        float: right;
        text-align: center;

        &.no-dd{
            display: none;
        }
        
        .title { 
            color: $dk-gray2;
            letter-spacing: 0px;
            font-size: 12px;
            border-radius: 0px;
            border:none;
            box-shadow: none;
            background:transparent;
        }
        .select-menu-dropdown {
            .option {
                line-height: 20px;
                font-size: 12px;
                &:hover{
                    color: white !important;
                }
            }
        }
    }

    .slamtracker-scoreboard {
        .more {
            .slamtracker-radio {
                &:after {
                    border: none;
                }
            }
        }
    }

    .match-highlights{
        padding: 15px 10px 0 10px;
        background: $gray-f3;
        .highlight-button{
            border: none;
            cursor: pointer;
            background: transparent;
            &:hover{
                background: transparent;
            }
            .highlight-img{
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 100%;
                } 
                .playBtn{
                    width: 51px;
                    height: 51px;
                    top: unset;
                    left: unset;
                    margin-top: unset;
                    margin-left: unset;
                    background-size: 51px;
                }
            }
            .highlight-desc{
                font-size: px2rem(12);
                text-align: left;
                padding: 7px 0;
                @include interstate(light);
            }      
        }
        
        .slick-next,.slick-prev {
            width:45px; height:100%; position: absolute; padding: 0px 3px; z-index: 10;
            &.slick-disabled{ visibility: hidden;}
        }
        .slick-next {
            right: -12px; text-align: right;
            // background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255, 255, 255, 1) 60%);
            background: linear-gradient(to left, #ffffff, rgba(#ffffff, 0));
        }
        .slick-prev {
            left:-12px; text-align: left;
            // background-image: linear-gradient(to left, rgba(255,0,0,0), rgba(255, 255, 255, 1) 60%);
            background: linear-gradient(to right, #ffffff, rgba(#ffffff, 0));
        }

        .slick-next:before {
            @include uso-icon('Icons');
            content:"\e902";
            color: $gray-4a;
            font-weight: bolder;
            font-size: 14px;
        }
        .slick-prev:before {
            @include uso-icon('Icons');
            content:"\e901";
            color: $gray-4a;
            font-weight: bolder;
            font-size: 14px;
        }
        
    }
}

.embedded {
    &.metric {
        div.metric {
            display: block;
        }
        span.metric {
            display: inline-block;
        }
        .imperial {
            display: none;
        }
    }
    &.imperial {
        div.imperial {
            display: block;
        }
        span.imperial {
            display: inline-block;
        }
        .metric {
            display: none;
        }
    }
}

