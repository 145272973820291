// In the Media section
.mi-in-the-media-wrapper {
    overflow: hidden;

    .mi-section-header {
        h4.mi-section-title {
            margin-top: 12px;
        }
        .mi-last-updated {
            display: none;
        }
    }

    @include general-breakpoint(smtablet){
        padding: 29px 0 50px !important;
    }
}

.mi-section {
    .column-layout {
        &.mi-media {
             flex-wrap: wrap;

            .col {
                    width: 100%;
                    
                    &:first-of-type {
                        border-right: none;
                        padding-right: 10px;
                        padding-bottom: 40px;
                    }

                    &:last-of-type {
                        padding-left: 0px;
                    }

                    @include general-breakpoint(smtablet) {

                        &:first-of-type {
                            padding-right: 20px;
                            padding-bottom: 10px;
                            border-bottom: none;
                            border-right: solid 1px $md-gray3;
                            width: 50%;
                        }
    
                        &:last-of-type {
                            padding-left: 20px;
                            padding-bottom: 10px;
                            width: 50%;
                        }
                    }
                }
            }
    }
}

.mi-media {
    .player-name {
        @include interstate();
        color: $uso-lt-blue;
        font-size: px2rem(12);
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        padding-bottom: 10px;

        @include general-breakpoint(smtablet) {
            display: none;
        }
    }

    .slick-slider {
        .slick-slide {
            padding-bottom: 15px;
        }

        .slick-dots {
            bottom: 0;

            li {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                border: solid 1px $gray-dark;
                background: $white;

                button {
                    width: 100%;
                    height: 100%;
                    margin-top: -1px;

                    &:before {
                        content: '';
                        width: 100%;
                        height: 100%;
                    }
                }
                &.slick-active {
                    width: 8px;
                    height: 8px;
                    background: $gray-dark;

                    button {
                        margin-top: 0;
                    }
                }
            }
        }

        // make the slider dots fixed to the bottom
        @include general-breakpoint(smtablet) {
            height: 100%;
            
            .slick-list, .slick-track, .slick-slide {
                height:100%;
            }
            .slick-dots {
                bottom:-15px;
            }
            .slick-next, .slick-prev {
                position: absolute;
                font-size: px2rem(18);
                line-height: 19px;
                top: unset;
                bottom: -18px;
                width: unset;
                transform: none;
                color: unset;
                z-index: 10;
    
                &:before {
                    content: "";
                    opacity: unset;
                    font-size: unset;
                    line-height: unset;
                    color: unset;
                }
                i::before {
                    color: $gray-4a;
                    opacity: 1;
                    font-size: px2rem(20px);
                    
                }
                &.slick-disabled {
                    i::before {
                        opacity: .5;
                    }
                }
            }
            .slick-next {
                right: 42%;
            }
            .slick-prev {
                left: 42%;
            }
        }
        @include general-breakpoint(smdesktop) {
            .slick-next {
                right: 43%;
            }
            .slick-prev {
                left: 43%;
            }
        }
        @include general-breakpoint(smdesktop) {
            .slick-next {
                right: 44%;
            }
            .slick-prev {
                left: 44%;
            }
        }
    }
}

.media-slide-container {
    width: 284px;
    height: auto;
    display: flex !important;
    justify-content: space-between;
}

.mi-media-content {
    // width: 100%;
    font-size: 16px;
    position: relative;
    width: 44%;

    @include interstate(lightit);
    font-size: px2rem(14);
    color: #333333;

    @include general-breakpoint(landscape) {
        width: 47%;
    }

    @include general-breakpoint(smtablet) {
        font-size: px2rem(18);
    }
    
    .media-item {
        margin-bottom: 15px;
    }
    .media-item-text {
        line-height: px2rem(22);

        @include general-breakpoint(smtablet) {
            line-height: px2rem(30);
        }
    }
    .media-item-host, .media-item-year {
        @include interstate();
        font-size: px2rem(12);
        font-style: normal;
        color: $md-gray;
    }

    .media-item-year {
        div {
            display: inline;
        }
    }

    .media-item-info {
        color: $md-gray3;
        font-size: 14px;
    }

    .media-item-link {
        color: $uso-lt-blue;
        font-size: 15px;
        margin-left: 8px;

        a {
            text-decoration: none;
            color: $uso-lt-blue;
    
            &:hover {
                color: $uso-lt-blue;
            }
        }
    }
}

// slamtracker styles
.slamtracker {
    .mi-in-the-media-wrapper {
        margin-left: 14px !important;
        margin-right: 14px !important;
        padding-top: 20px !important;

        .mi-section-title {
            display: none;
        }

        .mi-section-header {
            justify-content: flex-end;
        }
    }
    .mi-media-content {
        font-size: px2rem(14);
        color: #333333;
        width: 43%;

        .media-item-text {
            line-height: 24px;
        }
    }
    .mi-media {
        .slick-slider {
            .slick-next, .slick-prev {
                display: none !important;
            }
        }
    }
}

// webview override for slider height
.webview {
    .mi-media {
        .slick-slider {
            @include general-breakpoint(smtablet) {
                height: auto;

                .slick-list, .slick-track, .slick-slide {
                    height:auto;
                }
            }
        }
    }
}