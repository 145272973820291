
@import './interstate_font';

.monospace {
    font-family: monospace;
}

.strong {
    @include interstate(bold);
}

h1, 
h2, 
h3, 
h4, 
h5 { 
    @include interstate(light); 
}

h1.header {
    padding: 25px 0 17px 16px;
    color: $dk-gray2;
    width: 100%;
    margin: auto auto 0 auto;
    border-bottom: 1px solid $gray;
    @include general-breakpoint(landscape) {
        padding-left: 5%;
        font-size: 2.8rem;
    }
    @include general-breakpoint(landscape) {
        font-size: 3rem; 
    }
}    

// @include general-breakpoint(desktop) {
//     h1.header {
//         border-bottom:none;
//         &:after {
//             border-bottom:1px solid #C1C6C8;
//             position: absolute;
//             width: 100vw;
//             left:calc(0px - ((100vw - 1280px) / 2));
//             bottom:0px;
//             content:"";
//         }
//     }
    
// }

em { font-style: italic;}
