.player-seed {
	//display: flex;
	//@debug: 0 -25px;
	max-width: 1280px;
	margin-bottom: 30px;
	border-top: 1px solid #d8d8d8;
	background-color: #f3f3f3;
	position: relative;

	.seedTitle {
		padding: 10px 10px;
		text-align: center;
		text-transform: uppercase;
		@include general-breakpoint(mobile, max) {
			text-align: initial;
		}
	}
	.mensContainer,
	.womensContainer {
		@include general-breakpoint(smtablet) {
			align-items: center;
		}
		height: auto;
		display: block;
		.slick-slider {
			@include general-breakpoint(smtablet) {
				display: inline-block;
				margin-left: 30px;
			}
		}
		.men,
		.women {
			@include general-breakpoint(smtablet) {
				height: 123px;
			}
			.slick-slider {
				margin-left: unset;
				width: 100%;

				.slick-list {
					.slick-track {
						transform: translate3d(0, 0, 0);
						.slick-slide {
							.player-box {
								.playerName {
									a:hover {
										text-decoration: none;
										.firstName,
										.lastName {
											color: $usoLtBlue;
										}
									}
								}
							}
						}
					}
				}
				@include slick-slider-arrows;
				.slick-arrow {
					&.slick-prev {
						border-left: solid 1px $table-border-grey;
						// border-top: solid 1px $table-border-grey;
						// border-bottom: solid 1px $table-border-grey;
					}
					&.slick-next {
						border-right: solid 1px $table-border-grey;
						// border-top: solid 1px $table-border-grey;
						// border-bottom: solid 1px $table-border-grey;
					}
				}
			}
		}
	}

	.men {
		background-color: #f3f3f3;
		@include general-breakpoint(landscape, max) {
			width: 100%;
		}
	}
	.women {
		background-color: #d8d8d8;
	}
	#playerBox {
		border-bottom: 1px solid #e0e2e3;
		margin: 0 10px;
		//border-right: unset;
		//padding-bottom: 10px;
	}
	#playerBox:last-child {
		border-bottom: none;
	}

	.player-box {
		display: flex !important;
		height: 120px;
		padding-left: 10px;
		background-color: white;
		align-items: center;
		border-right: 1px solid $usoltMedGray;
		min-width: 96px;
		.playerPic {
			img {
				width: auto;
				max-height: 81px;
			}
		}
		.playerInfo {
			margin-left: 15px;
			padding-top: 7px;
			height: 80%;
			.playerName a {
				display: flex;
				flex-direction: column;
				.firstName {
					@include interstate();
					color: $usoDkGray;
					font-size: 1rem;
					text-align: left;
					@include general-breakpoint(landscape, max) {
						font-size: px2rem(9);
					}
				}
				.lastName {
					@include interstate();
					color: $usoDkGray;
					font-size: 1.5rem;
					line-height: 1.5rem;
					margin-bottom: 7px;

					@include general-breakpoint(landscape, max) {
						font-size: px2rem(11);
						line-height: 1.5rem;
					}
				}
			}
			.country {
				white-space: nowrap;
				align-items: center;
				display: flex;
				margin-bottom: 5px;
				font-size: px2rem(10);
				.flag,
				.countryName {
					display: inline-block;
				}
				.flag img {
					width: 16px;
				}
				.countryName {
					@include interstate();
					font-weight: 300;
					color: $usoDkGray;
					margin-left: 5px;
					white-space: normal;
					vertical-align: text-top;
					max-width: 130px;
					font-size: px2rem(10);
					&.longCtyName {
						line-height: 1;
					}
				}
			}
		}
		.number {
			align-items: center;
			height: 75%;
			@include interstate(regular);
			font-size: 1.6em;
			margin-right: 8px;
			span {
				//padding: 0 10px;
			}
		}
		.withFavs {
			.number {
				margin-left: 25px;
			}
			span {
				margin-left: -2px;
			}
			.favorite:after {
				padding-top: 1px;
			}
		}
	}
}
