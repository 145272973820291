.compact-container {
    @include general-breakpoint(landscape) {
      @include make-container();
      padding:0 0 10px 0;
    }
    @include general-breakpoint(tablet) {
      @include make-container-max-widths();
      padding:0 0 10px 0;
    }
    background-color: $usoBkgGray;
    margin-top: 30px;
    padding:0 0 10px 0;

    @include general-breakpoint(landscape, max) {
      margin-top: 0;
    }

    .compact-header {
      border-bottom: solid 1px $usoLtGray;

    h2 {
      background-color: $white;
      border-top: solid 1px $usoLtGray;
      margin-bottom: 0;
      @include interstate(bold);
      font-size: px2rem(16);
      padding: 13px 40px;
      // @include general-breakpoint(landscape) {
      //   font-size: 2.5rem; padding: 20px 40px;
      // }
    }
    > div {
      display: block; padding: 15px 0;
      margin-bottom: 30px;
      

      @include general-breakpoint(landscape) {
        float: right; margin-top: 5px; margin-right: 20px; padding: 0;
        background-color: $white;
        margin-bottom: 0;
      }
      @include general-breakpoint(desktop) {
        margin-top: 30px;
      }

      a {
        display: block; color: $dk-gray2; text-align: center; text-decoration: none; text-transform: uppercase; padding: 10px 10px 10px 10px; width: 150px; margin: auto;
        border: 1px solid $gray; background-color: $white;
        @include general-breakpoint(desktop) {
          border: none; padding: 0 15px 0 0; width: auto; background-color: transparent;
        }
        .icon {
          background: url('/assets/images/nav/back_to_top_gray.png') no-repeat center;
          padding: 0 5px;
          @include general-breakpoint(desktop) {
            background-image: url('/assets/images/nav/back_to_top_gray.png');
          }
  
        }
      }
    }
  }
  }
  .compact-item {
    background-color: $white;
    margin: 20px 20px;
    display: flex;
    flex-direction: column;
    border: solid 1px $usoLtGray;

    @include general-breakpoint(landscape) {
      flex-direction: row;
      padding: 20px;
    }
    @include general-breakpoint(tablet) {
      padding: 20px;
    }
    @include general-breakpoint(desktop) {
      padding: 20px;
    }
    @include clearfix();
    .compact-photo {
      width: 100%;
      img {
        width: 100%;
      }
      @include general-breakpoint(landscape) {
        margin: 0 32px 0 0;
        max-width: 280px;
        min-width: 280px;
      }
      @include general-breakpoint(tablet) {
        margin: 0 32px 0 0;
        max-width: 280px;
        min-width: 280px;
      }
      @include general-breakpoint(desktop) {
        margin: 0 32px 0 0;
        max-width: 320px;
        min-width: 320px;
      }
    }
    .compact-title {
      padding: 10px 20px 0 20px;
      @include general-breakpoint(landscape) {
        padding: 0;
      }
      @include general-breakpoint(tablet) {
        //padding: 20px 20px 0 20px;
      }
      h3 {
        padding-top:0;
        @include interstate(regular); color: $usoDkGray; font-size: px2rem(18);
        @include general-breakpoint(landscape) {
          padding-bottom: 10px;
        }
      }
    }
    .compact-content {
      padding: 0 20px 10px 20px;
      @include interstate(regular); color: $dk-gray2;
      @include general-breakpoint(landscape) {
        padding: 0;
      }
      @include general-breakpoint(tablet) {
        padding: 0;// 20px 20px 20px;
      }
      p { padding-top: 10px;
        font-size: px2rem(16);
        line-height: px2rem(20);
        &:first-child { padding-top: 10px;
          @include general-breakpoint(landscape) {
            padding-top: 0;
          }
        }
      }
      ol, ul, dl { margin-left: 25px; }
    }
    // a { color: $dk-gray; }
  }

  .coc-year {
    font-size: calc((24/14) * 1em);
    border-top: 1px solid $light-grey;
  }

  .coc {
    margin: 10px 0;

    .coc-photo {
      float: left;
      margin-right: 15px;
      display: table-cell;
      padding-top: 120px; 
      margin-top: -120px;
    }

    .coc-description {
      display: table-cell;

      .coc-title {
        font-size: calc((16/13) * 1em);
        color: #cf102d;
        @include interstate(medium);
      }

      ul {
        font-size: calc((16/13) * 1em);
        color: $grey;
        margin-left: 0 ! important;
        list-style: none;
      }
    }
  }

.content-filters {
  &.atoz {
    height: 60px;
    padding: 0;
    border-top: none;
    border-bottom: 1px solid #AFAFAF;
    background-color: $usoDkBlue;
    #atoznav {
      border-top: 1px solid $white;
      @include general-breakpoint(smtablet, max) {
        padding-top: 14px;
      }

      .select-menu {
        @extend .dark; // make it dark theme - use the same style in select-menu.scss
      }

      .text_nav {
        text-align: center;
        line-height: 60px;
        background-color: $usoDkBlue;
        padding: 0;

        a {
            color: $white;
            &.selected, &:hover {
                color: $white;
                border-bottom-color: $white;
            }
        }
        .disabled {
            a {
                color: $usoMdGray;
            }
        }
      }
    }
  }
}

.topic-section {
  background-color: $white;

  @include media-breakpoint-up(sm) {
    @include make-row();
  }
  .topic-container {
    @include media-breakpoint-up(sm) {
      @include make-col-ready();
    }
    .topic-media {
      width: 100%;
      margin-bottom: 20px;
      @include media-breakpoint-up(md) {
        float: left;
        margin: 0 20px 20px -32px;
        position: relative;
        width: 50%;
        &.right {
          float: right;
          margin: 0 -32px 20px 20px;
        }
      }
      .topic-media-image{
        position:relative;
        padding-top:56.25%;
        > div {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          img { width: 100%; }
        }
      }
    }
    .topic-detail {
      @include make-col-ready();
      @include media-breakpoint-up(md) {
        @include make-col(8);
        @include make-col-offset(2);
      }
      > div {
        display: block; padding: 15px 0;
        @include general-breakpoint(landscape) {
          float: right; margin-top: 32px; margin-right: 15px; padding: 0;
        }
        @include general-breakpoint(desktop) {
          margin-top: 32px;
        }
        a {
          display: block; color: $usoDkGray; text-align: center; text-decoration: none; text-transform: uppercase; padding: 10px 10px 10px 10px; width: 150px; margin: auto;
          border: 1px solid $gray; background-color: $white;
          @include general-breakpoint(desktop) {
            border: none; padding: 0 15px 0 0; width: auto; background-color: transparent;
          }
          .icon {
            background: url('/assets/images/nav/back_to_top_gray.png') no-repeat center;
            padding: 0 5px;
            @include general-breakpoint(desktop) {
              background-image: url('/assets/images/nav/back_to_top_gray.png');
            }
          }
        }
      }
      h1, h2, h3 {
        font-size: calc((28/14) * 1em);;
        @include interstate;
        @include media-breakpoint-up(sm) {
          font-size: calc((32/14) * 1em);
        }
        @include media-breakpoint-up(md) {
          margin-top: 32px;
        }
      }
    }
  }
}

.adContainer {
  @include make-row();
  justify-content: center;
  > div {
    margin: auto;
  }
}

/** Visit A to Z override */
.atoz {
  #content {
    &.gray {
      background-color: transparent;
      padding-right: 0;
      padding-left: 0;
    }
  }
}