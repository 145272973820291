.modal-fade-bg {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
}

.insights-prediction-overlay {
    background-color: $white;
    border-radius: 10px;
    padding: 30px;
    position: absolute;
    top: 90px;;
    width: 320px;
    z-index: 100;

    @include general-breakpoint(mobile)  {
        right: 30px;
        width: 380px;
    }

    .watson-logo { 
        background: url("/assets/images/logos/watson-blue-logo.svg") no-repeat 0 0; 
        padding-left: 50px;
        line-height: 34px;
        font-size: px2rem(18);
    }

    .close {
        position: absolute;
        top: 27px;
        right: 20px;
    }
} // .insights-prediction-overlay

.insights-prediction-overlay-row {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    img.player-image {
        width: 140px;
        height: 140px;
    }

    h5, .num {
        @include interstate(medium);
        font-size: px2rem(18);
    }

    .num {
        @include interstate(medium);
        font-size: px2rem(18);
        line-height: 57px;
        text-align: center;

        &::before {
            @extend %likelihood-win-purple-circle;
        }

    }

    .why-link {
        @include interstate;
        font-size: px2rem(18);
        text-align: center;
        margin-top: 40px;
    }

    .prediction-body {
        display: block;
        font-size: px2rem(18);
        line-height: 27px;
    }

     /* favorite star override */
    .favorite {
        position: absolute;
        cursor: pointer;
        z-index: 2;
        
        &:after {
            position: absolute;
            left: -20px;
            content: "\2606";
            font-size: 33px;
            color: $wim-green;
        }
        
        &.favorited {
            &:after {
                content: "\2605";
                font-size: 33px;
                color: #F9E300;
            }
        }
    }
} // .insights-prediction-overlay-row
