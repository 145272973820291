/** hero styles **/

/*** Hero Image - full width image w/ text - news index, detail, stub page ***/
.heroSection {
	width: 100%;
	max-width: 1280px;
	margin: auto;

	@include media-breakpoint-up(xl) {
		max-width: 100%;
	}

	.heroImage {
		width: 100%;
		overflow: hidden;
		position: relative;
		height: 100%;

		@include general-breakpoint(landscape) {
			height: 0;
			padding-top: 56.25%;
		}

		@include media-breakpoint-up(xl) {
			height: 720px;
			padding-top: 0;
		}
		.heroContainer {
			position: relative;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			@include general-breakpoint(landscape) {
				position: absolute;
				background-size: cover;
				background-position: top;
				background-repeat: no-repeat;
			}
			img {
				width: 100%;
				height: 100%;
				position: relative;
				top: 0;
				left: 0;
				object-fit: cover;
				@include general-breakpoint(landscape) {
					height: 100%;
					// position: absolute;
					// z-index: -1;
				}
				@include media-breakpoint-up(xl) {
					//height: 720px;
					object-position: top;
				}
				&.center {
					z-index: auto !important;
					width: 75px !important;
					height: 75px !important;
				}
			}
			.heroInfo {
				position: relative;
				bottom: 0;
				padding: 20px 15px;
				background-color: $gray-f3;
				color: $gray-4a;
				display: block;
				width: 100%;
				@include general-breakpoint(landscape) {
					position: absolute;
					bottom: 0;
					padding: 30px 50px;
					color: $white;
					text-shadow: $black 2px 2px 2px;
					background-color: transparent;
				}
				@include general-breakpoint(desktop) {
					position: absolute;
					bottom: 0;
					padding: 30px 60px;
				}
				a {
					color: $white;
				}
				h1 {
					padding: 0;
					margin-bottom: 10px;
					border: none;
					color: $gray-4a;
					@include interstate(regular);
					font-size: 2.4rem;
					line-height: 2.6rem;
					@include general-breakpoint(landscape) {
						color: $white;
					}
					@include general-breakpoint(tablet) {
						margin-bottom: 15px;
						font-size: 4rem;
						line-height: 4.2rem;
					}
					@include general-breakpoint(desktop) {
						margin-bottom: 25px;
						font-size: 5rem;
						line-height: 5.2rem;
					}
				}
				h2 {
					padding: 0;
					margin-bottom: 10px;
					@include interstate(bold);
					text-transform: uppercase;
					font-size: 1.5rem;
					color: $gray-4a;
					@include general-breakpoint(landscape) {
						color: $white;
					}
					@include general-breakpoint(tablet) {
						font-size: 1.8rem;
					}
					@include general-breakpoint(desktop) {
						font-size: 2rem;
					}
				}
				p {
					display: none;
					padding: 0;
					@include interstate(regular);
					font-size: 1.2rem;
					@include general-breakpoint(landscape) {
						display: block;
						font-size: 1.3rem;
					}
					@include general-breakpoint(desktop) {
						display: block;
						font-size: 1.4rem;
					}
				}
			}
		}
		.heroStub {
			.heroInfo {
				p {
					display: block;
				}
			}
		}
	}

	&.hero-stub {
		@include media-breakpoint-up(xl) {
			background-image: url('/images/pics/large/f_stub_image_2020.jpg');
			img {
				display: none;
			}
		}
	}

	.heroVideo {
		width: 100%;
		overflow: hidden;
		position: relative;
		min-height: 500px;
		padding-bottom: 0;

		&.open {
			position: fixed;
			top: 0;
			height: 100vh;
			z-index: 1000000000;
		}

		@include media-breakpoint-down(sm) {
			min-height: unset;
			max-height: 100vh;
			padding-bottom: 56.25%;
		}
		@include media-breakpoint-up(xl) {
			height: 720px;
			padding-top: 0;
		}

		#videoImage {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			object-fit: cover;
			object-position: top;
		}

		#videoContainer {
			@include media-breakpoint-up(xl) {
				max-width: 1280px;
				margin: auto;
			}
		}

		.nowPlaying {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			padding: 10px 20px;
			color: white;
			@include interstate(regular);
			font-size: 1.2rem;
			background-color: rgba(0, 0, 0, 0.6);
		}

		.videoText {
			color: $white;
			text-shadow: $black 2px 2px 2px;
			position: absolute;
			bottom: 0;
			padding: 20px 15px;

			@include general-breakpoint(landscape) {
				position: absolute;
				bottom: 0;
				padding: 30px 50px;
			}

			@include general-breakpoint(desktop) {
				position: absolute;
				bottom: 0;
				padding: 30px 60px;
			}

			h2 {
				padding: 0;
				margin-bottom: 10px;
				border: none;
				color: $white;
				@include interstate(regular);
				font-size: 3rem;
				line-height: 3.2rem;
				text-transform: none;
				@include general-breakpoint(tablet) {
					margin-bottom: 15px;
					font-size: 4rem;
					line-height: 4.2rem;
				}

				@include general-breakpoint(desktop) {
					margin-bottom: 25px;
					font-size: 5rem;
					line-height: 5.2rem;
				}
			}

			h1 {
				padding: 0;
				margin-bottom: 10px;
				@include interstate(bold);
				text-transform: uppercase;
				font-size: 1.5rem;
				line-height: 1.7rem;

				@include general-breakpoint(tablet) {
					font-size: 1.8rem;
					line-height: 2rem;
				}

				@include general-breakpoint(desktop) {
					font-size: 2rem;
					line-height: 2.2rem;
				}
			}
		}
	}

	/*** .landing flips the behavior of the h1 and h2 tags ***/
	&.landing {
		.heroImage {
			.heroContainer {
				.heroInfo {
					h2 {
						padding: 0;
						margin-bottom: 10px;
						border: none;
						color: $white;
						@include interstate(regular);
						font-size: 3rem;
						line-height: 3.2rem;
						text-transform: none;
						@include general-breakpoint(tablet) {
							margin-bottom: 15px;
							font-size: 4rem;
							line-height: 4.2rem;
						}

						@include general-breakpoint(desktop) {
							margin-bottom: 25px;
							font-size: 5rem;
							line-height: 5.2rem;
						}
					}

					h1 {
						padding: 0;
						margin-bottom: 10px;
						@include interstate(bold);
						text-transform: uppercase;
						font-size: 1.5rem;
						line-height: 1.7rem;

						@include general-breakpoint(tablet) {
							font-size: 1.8rem;
							line-height: 2rem;
						}

						@include general-breakpoint(desktop) {
							font-size: 2rem;
							line-height: 2.2rem;
						}
					}
				}
			}
		}
	}
}
.hero-container {
	@include media-breakpoint-down(sm) {
		min-height: calc(100vh - 155px);
	}

	position: relative;
	margin-bottom: 10px;

	@include media-breakpoint-up(sm) {
		padding-bottom: 56.25%;
	}

	@include media-breakpoint-up(xl) {
		padding-bottom: 0;
		height: 573px;
	}

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	.hero-content {
		@include make-row();
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		.hero-buttons {
			position: absolute;
			bottom: 0;
			left: 32px;
			right: 32px;
			@include make-row();

			@include media-breakpoint-up(sm) {
				position: absolute;
				bottom: 48px;
				left: 48px;
				right: 48px;
			}

			@include media-breakpoint-up(md) {
				position: absolute;
				bottom: 56px;
				left: 48px;
				right: 48px;
			}

			@include media-breakpoint-up(lg) {
				position: absolute;
				bottom: 56px;
				left: 60px;
				right: 60px;
			}

			// .landing-title {
			//     @include make-col-ready();
			//     @include make-col(12);

			//     h1 {
			//         font-size: 2rem;
			//         color: $white;
			//         @include interstate(bold);
			//         text-transform: uppercase;
			//         text-shadow: 2px 2px 2px $black;
			//     }
			//     @include general-breakpoint(landscape) {}
			// }

			.hero-button {
				@include make-col-ready();

				@include media-breakpoint-up(xs) {
					@include make-col(12);
				}

				@include media-breakpoint-up(sm) {
					@include make-col(6);
				}

				@include media-breakpoint-up(md) {
					@include make-col(4);
				}

				text-transform: uppercase;
				margin-top: 8px;
				margin-bottom: 8px;

				@include media-breakpoint-up(sm) {
					margin-top: 16px;
					margin-bottom: 16px;
				}

				a {
					display: block;
					width: 100%;
					color: $white;
					border: 1px solid $white;
					padding: 5px;
					background-color: rgba(0, 0, 0, 0.6);
					text-align: center;
					@include interstate(regular);
					text-decoration: none;
					font-size: 1.5rem;
				}

				&.hero-button-count-4 {
					@include media-breakpoint-up(md) {
						@include make-col(6);
					}
				}
			}
		}

		.title {
			display: none;
			position: relative;
			text-align: center;
			width: 100%;
			height: 44px;
			z-index: 1;

			@include general-breakpoint(landscape) {
				display: none;
				position: absolute;
				top: 0;
				height: auto;
			}

			h1 {
				color: $dark-blue;
				font-size: 18px;
				@include interstate(medium);
				margin-top: 10px;

				@include general-breakpoint(landscape) {
					color: $white;
					font-size: 36px;
					margin: 20px auto;
				}
			}
		}

		.hero-item {
			@include make-col-ready();

			@include media-breakpoint-up(xs) {
				min-height: 135px;
			}

			@include media-breakpoint-up(sm) {
				@include make-col(4);
			}

			.hero-image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					display: block;
					object-fit: cover;

					@include general-breakpoint(landscape) {
						display: inline-block;
					}
				}
			}

			.hero-link {
				position: absolute;
				top: 0;
				bottom: 0;
				padding: 30px 0;
				width: 100%;
				text-align: center;
				left: 0;
				right: 0;
				z-index: 1;

				@include media-breakpoint-up(sm) {
					width: 100%;
					top: auto;
					bottom: 40px;
					left: -16px;
					padding: 10px 0;
				}

				@include media-breakpoint-up(md) {
					bottom: 50px;
				}

				.text {
					color: $white;
					display: block;
					@include interstate(regular);
					text-shadow: 1px 1px 1px $black;

					@include media-breakpoint-up(xs) {
						font-size: 2rem;
						line-height: 2.3rem;
						margin-bottom: 20px;
					}

					@include media-breakpoint-up(sm) {
						font-size: 2.1rem;
						line-height: 2.4rem;
						margin-bottom: 20px;
					}

					@include media-breakpoint-up(md) {
						font-size: 3.2rem;
						line-height: 3.5rem;
						margin-bottom: 20px;
					}
				}
			}
		}
	}

	.hero-image {
		@include make-row();
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		img {
			width: 100%;
			object-fit: cover;
		}

		.title {
			display: block;
			position: absolute;
			bottom: 40px;
			text-align: center;
			width: 100%;
			z-index: 1;

			@include general-breakpoint(landscape) {
				display: inline-block;
				left: 60px;
				bottom: 50px;
				width: auto;
				height: auto;
				text-align: left;
			}

			h1 {
				color: $white;
				@include interstate(regular);
				font-size: 3.2rem;
				text-shadow: 2px 2px 2px $black;
			}
		}
	}
}

.hero_container {
	height: 468px;
	max-height: 468px;
	
	@include media-breakpoint-down(sm) {
		min-height: calc(100vh - 400px);
	}
	position: relative;
	margin-bottom: 10px;
	@include media-breakpoint-up(sm) {
		padding-bottom: 56.25%;
	}
	// @include media-breakpoint-up(xl) {
	// 	padding-bottom: 0;
	// 	height: 500px;
	// 	max-height: 500px;
	// }
	@include general-breakpoint(smtablet, max) {
		margin-bottom: 0;
	}
	@include general-breakpoint(smtablet) {
		height: 500px;
		max-height: 500px;
	}
	@include general-breakpoint(smdesktop) {
		padding-bottom: 0;
	}
	&.buttons {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.hero_content {
		@include make-row();
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0px auto;
		z-index: 20;
		text-align: center;
		justify-content: center;

		.hero_buttons {
			justify-content: center;
			.hero_button_count_3 {
				a:hover {
					background-color: $white;
					color: $usoDkBlue;
					border: 1px solid $usoDkBlue;
					.arrow-icon {
						color: $usoDkBlue;
					}
				}
			}
		}

		.hero_buttons,
		.title_container {
			position: absolute;
			top: 40px;
			width: 100%;
			@include general-breakpoint(landscape) {
				top: 75px;
				@include make-row();

				.hero-button-container {
					display:flex;
					flex-direction: row;
					width:100%;
					flex-wrap: wrap;
					justify-content: center;
					.hero_button {
						margin-bottom:0px;
						flex: 0 0 50%;
        				max-width: 50%;
					}
				}
			}

			.landing_title {
				height: auto;
				padding-bottom: 25px;
				@include interstate(bold);
				font-size: 16px;
				line-height: 19px;
				color: $white;
				@include make-col-ready();
				@include make-col(12);
				h1 {
					font-size: 22px;
					color: $usoYellow;
					@include interstate(bold);
					line-height: 25px;
					padding-bottom: 5px;

					.alt {
						color: $usoYellow;
					}	
				}

				@include general-breakpoint(smtablet) {
				// @include general-breakpoint(landscape) {
					min-height: 150px;
					font-size: 20px;
					line-height: 24px;
					h1 {
						font-size: 28px;
						padding-bottom: 25px;
					}
				}
				&.dark {
					color: $dark-blue3;
					h1 {
						color: $dark-blue3;
					}
				}
				&.light-condensed {
					color: $white;
					font-size: 1.2rem;
					padding: 10px;
					@include general-breakpoint(smtablet) {
						// @include general-breakpoint(landscape) {
						padding: 15px 30px;
						font-size: 1.4rem;
					}
					@include general-breakpoint(tablet) {
						padding: 30px;
					}
					h1,
					h2 {
						font-size: 2rem;
						color: $white;
						@include interstate(condensedbold);
						padding: 5px 0;
						border: none;
						@include general-breakpoint(smdesktop) {
							// @include general-breakpoint(landscape) {
							font-size: 6rem;
							line-height: 6rem;
							padding: 10px 0;
						}
						span {
							&.alt {
								// color: $usoLtBlue;
								color: $usoYellow;
							}
						}
					}
				}
				&.dark-condensed {
					color: $dark-blue3;
					font-size: 1.2rem;
					padding: 10px;
					@include general-breakpoint(smtablet) {
						// @include general-breakpoint(landscape) {
						padding: 15px 30px;
						font-size: 1.4rem;
					}
					@include general-breakpoint(tablet) {
						padding: 30px;
					}
					h1,
					h2 {
						font-size: 2rem;
						color: $dark-blue3;
						@include interstate(condensedbold);
						padding: 5px 0;
						border: none;
						@include general-breakpoint(smtablet) {
							// @include general-breakpoint(landscape) {
							font-size: 3rem;
							padding: 10px 0;
						}
					}
				}
			}
			.hero_button {
				@include make-col-ready();
				@include make-col(12);
				@include general-breakpoint(landscape) {
					@include make-col(6);
				}
				@include general-breakpoint(smtablet) {
					@include make-col(4);
				}
				@include general-breakpoint(tablet) {
					@include make-col(4);
				}
				text-transform: uppercase;
				margin-top: 8px;
				margin-bottom: 8px;
				@include media-breakpoint-up(sm) {
					margin-top: 16px;
					margin-bottom: 16px;
				}
				a {
					display: block;
					width: 100%;
					color: $white;
					border: 1px solid $white;
					border-radius: 7px;
					padding: 5px;
					background-color: $usoDkBlue;
					text-align: center;
					@include interstate(regular);
					text-decoration: none;
					font-size: 20px;
					@include general-breakpoint(smtablet) {
						// @include general-breakpoint(landscape) {
						font-size: 16px;
					}
					@include general-breakpoint(smdesktop) {
						font-size: 20px;
					}
				}
				.navItem {
					display: flex;
					align-items: center;
					justify-content: center;
					.arrow-icon {
						display: inline-block;
						letter-spacing: -4px;
						padding-left: 15px;
						color: $white;
						height: 20px;
						margin-top: -12px;
						@include general-breakpoint(smtablet) {
							// @include general-breakpoint(landscape) {
							margin-top: -7px;
						}
						@include general-breakpoint(smdesktop) {
							margin-top: -12px;
						}
						i {
							font-size: 12px;
							font-weight: bold;
						}
						&.down {
							display: flex;
							flex-direction: column;
							justify-content: center;
							margin-top: 0;
							i {
								font-size: 8px;
							}
						}
					}
				}
				&.hero_button_count_4 {
					@include media-breakpoint-up(md) {
						@include make-col(6);
					}
				}
			}
			.countdown-timer {
				@include interstate(bold);
				font-size: px2rem(24);
				text-transform: uppercase;
				span {
					color: $usoYellow;
				}
		
				@include general-breakpoint(smtablet) {
					font-size: px2rem(30);
				}
			}
		
		}
		.title {
			display: none;
			position: relative;
			text-align: center;
			width: 100%;
			height: 44px;
			z-index: 1;
			@include general-breakpoint(landscape) {
				display: none;
				position: absolute;
				top: 0;
				height: auto;
			}
			h1 {
				color: $dark_blue;
				font-size: 18px;
				@include interstate(medium);
				margin-top: 10px;
				@include general-breakpoint(landscape) {
					color: $white;
					font-size: 36px;
					margin: 20px auto;
				}
			}
		}
		.hero_item {
			@include make-col-ready();
			@include media-breakpoint-up(xs) {
				min-height: 135px;
			}
			@include media-breakpoint-up(sm) {
				@include make-col(4);
			}
			.hero_image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					display: block;
					object-fit: cover;
					@include general-breakpoint(landscape) {
						display: inline-block;
					}
				}
			}
			.hero_link {
				position: absolute;
				top: 0;
				bottom: 0;
				padding: 30px 0;
				width: 100%;
				text-align: center;
				left: 0;
				right: 0;
				z-index: 1;
				@include media-breakpoint-up(sm) {
					width: 100%;
					top: auto;
					bottom: 40px;
					left: -16px;
					padding: 10px 0;
				}
				@include media-breakpoint-up(md) {
					bottom: 50px;
				}
				.text {
					color: $white;
					display: block;
					@include interstate(regular);
					text-shadow: 1px 1px 1px $black;
					@include media-breakpoint-up(xs) {
						font-size: 2rem;
						line-height: 2.3rem;
						margin-bottom: 20px;
					}
					@include media-breakpoint-up(sm) {
						font-size: 2.1rem;
						line-height: 2.4rem;
						margin-bottom: 20px;
					}
					@include media-breakpoint-up(md) {
						font-size: 3.2rem;
						line-height: 3.5rem;
						margin-bottom: 20px;
					}
				}
			}
		}
	}
	.hero_image {
		@include make-row();
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		img {
			width: 100%;
			object-fit: cover;
		}
		.title {
			display: block;
			position: absolute;
			bottom: 40px;
			text-align: center;
			width: 100%;
			z-index: 1;
			@include general-breakpoint(landscape) {
				display: inline-block;
				left: 60px;
				bottom: 50px;
				width: auto;
				height: auto;
				text-align: left;
			}
			h1 {
				color: $white;
				@include interstate(regular);
				font-size: 3.2rem;
				text-shadow: 2px 2px 2px #000;
			}
		}
	}
}

.help-bar {
	.hero_container {
		.hero_content {
			.title_container {
				top: 80px;
			}
		}
	}
}
