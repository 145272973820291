/** terms 
* reversed - players have swapped court sides 
* adDeuce - players have swapped from ad court to deuce 
* swap - player images display in reverse order
*/

/** width and height of whole visualisation **/
$vis-width: 320px; //width of the full court
$vis-height: 136px; //height of the full court

/** dimensions of court areas **/
$doubles-alley-height: 19px; //the top and bottom alleys
$service-box-full-width: 165px; //width of the service box spanning both court sides
$service-box-height: 50px; 
$court-height: $vis-height - ($doubles-alley-height); //middle part of the court in between the doubles lanes

/** player box dimensions **/
$player-box-height: 72px;
$player-img-size: 33px;
$player-img-margin: 5px;
$adDeuce-adjustment: 5px; //move the top player box down slightly to prevent text overlap
$doubles-offset: 7px; //offset for text boxes if not doubles
$serve-swap-offset: 7px; //offset of player headshot for doubles

/** animation timing **/
$changeover-speed: 1s;
$fade-speed: 0.2s;
$serve-swap-speed: 0.2s;

/** court borders for debugging **/
$borders: none; //ex 1px solid white

/** common styles **/
@mixin stVizTextBox(){
    @include interstate(regular);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    border-radius: 3px;
    opacity: 1;
    transition: opacity $fade-speed;
}

.slamtracker .debug{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    div {
        margin: 0 10px;
    }
}

.slamtracker .visualisation-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    .visualisation{
        position: relative;
        display: flex;
        justify-content: center;
        width: $vis-width;
        height: $vis-height;
        background: url("/assets/images/scores/slamtracker/backgrounds/uso_court.png") 50% 50% no-repeat;
        background-size: cover;
        .doubles-alley{
            display: flex;
            justify-content: center;
            width: 100%;
            height: $doubles-alley-height;
            position: absolute;
            
            &.top{
                top: 0px;
            }
            &.bottom{
                bottom: 0px;
            }
            .tiebreak{
                &.visible{
                    opacity: 1;
                }
                transition: opacity $fade-speed;
                opacity: 0;
                position: absolute;
                top: 0px;
                background-color: green; //TODO REPLACE THIS 
                padding: 3px 6px;
                @include interstate(regular);
                font-size: 9px;
                color: black;
                text-transform: uppercase;
            }
        }
        .court{
            width: 100%;
            height: $court-height;
            border: $borders;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: $doubles-alley-height;
            .team-1-info, .team-2-info{
                @include stVizTextBox();
                position: absolute;
                margin: 0px 5px;
                padding: 2px;
                &.hidden{
                    opacity: 0;
                }
                .text{
                    display: block;
                    font-size: 8px;                    
                    &.caps{
                        text-transform: uppercase;
                    }
                    &.larger{
                        font-size: 13px;
                        text-transform: uppercase;
                    }
                }
            }

            &.hideText{
                .team-1-info, .team-2-info, .service-box .vis-notice-container{
                    opacity: 0;
                }
            }

            /** text boxes */
            .team-1-info{
                left: 0px;
                &.reversed{
                    left: unset;
                    right: 0;
                }
                &.adDeuce{
                    bottom: unset;
                    top: $player-box-height - $doubles-offset + 5px;
                    &.doubles{
                        top: $player-box-height + 5px;
                    }
                }
                bottom: $player-box-height + 3px;
                
            }
            .team-2-info{
                right: 0px;
                &.reversed{
                    right: unset;
                    left: 0px;
                }                
                top: $player-box-height - $doubles-offset + 5px;
                &.doubles{
                    top: $player-box-height + 5px;
                }
                &.adDeuce{
                    bottom: $player-box-height + 3px;  
                    top: unset;                  
                }                
            }
            .team-1, .team-2{
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 50%;
                height: $player-box-height;
                overflow: visible;
                opacity: 1;
                &.noPlayers{
                    opacity: 0;
                }
                .player-imgs{
                    display: flex;
                    .ballbounce{
                        height: 36px;
                        opacity: 0;
                        transition: opacity $fade-speed;
                        &.serving{
                            opacity: 1;
                        }
                    }
                    .player-img{
                        position: relative;
                        background-color: white;
                        // border-radius: 50%;
                        height: $player-img-size;
                        padding: 1px;
                        margin: 0 $player-img-margin $player-img-margin $player-img-margin;
                        transition: left $serve-swap-speed, right $serve-swap-speed;
                        img{
                            height: 100%;
                        }
                        &::after{
                            content: '';
                            position: absolute;
                            left: 50%;
                            bottom: -4px;
                            margin: 0 auto;
                            width: 0;
                            height: 0;
                            border-top: 4px solid #fff;
                            border-left: 4px solid rgba(0, 0, 0, 0);
                            border-right: 4px solid rgba(0, 0, 0, 0);
                            transform: translateX(-50%);
                        }
                    }
                }
                .player-name{
                    @include interstate(regular);
                    font-size: 12px;
                    color: white;
                    padding: 0 5px 0px 5px;
                    white-space: nowrap;
                    &.A{
                        margin-bottom: -2px;
                    }
                }
            }

            /* Player headshots and names */
            .team-1{        
                top: $court-height - $player-box-height;
                left: 0px;
                transition: top $changeover-speed, left $changeover-speed, opacity $fade-speed;
                align-items: flex-start;
                &.doubles{
                    .player-imgs .ballbounce{
                        margin-left: -$doubles-offset;
                    }
                    &.reversed{
                        .player-imgs .ballbounce{
                            margin-right: -$doubles-offset;
                        }
                    }
                }
                &.reversed{
                    left: calc($vis-width/2);
                    align-items: flex-end;
                    &.swap{
                        &.doubles{
                            .player-imgs .player-img.A{
                                right: -($player-img-size + ($player-img-margin * 2));
                                left: unset;
                                z-index: 1;
                            }
                            .player-imgs .player-img.B{
                                right: $player-img-size + ($player-img-margin * 2) - $serve-swap-offset;
                                left: unset;
                                z-index: 0;
                            }
                        }
                    }
                    &.doubles{
                        .player-imgs .player-img.A{
                            right: -$serve-swap-offset;
                            left: unset;
                        }
                        .player-imgs .player-img.B{
                            right: 0px;
                            left: unset;
                        }
                    }
                }
                &.adDeuce{
                    top: 0px + $adDeuce-adjustment;
                }
                &.swap{
                    .player-imgs .player-img.A{
                        left: $player-img-size + ($player-img-margin * 2) - $serve-swap-offset;
                        z-index: 1;
                    }
                    .player-imgs .player-img.B{
                        left: -($player-img-size + ($player-img-margin * 2));
                        z-index: 0;
                    }
                }
                .player-imgs .player-img.A{
                    left: 0px;
                }
                .player-imgs .player-img.B{
                    left: -$serve-swap-offset;
                }
            }
            .team-2{        
                top: 0px + $adDeuce-adjustment;
                left: calc($vis-width/2);
                transition: top $changeover-speed, left $changeover-speed, opacity $fade-speed;
                align-items: flex-end;
                &.doubles{
                    .player-imgs .ballbounce{
                        margin-right: -$doubles-offset;
                    }
                    &.reversed{
                        .player-imgs .ballbounce{
                            margin-left: -$doubles-offset;
                        }
                    }
                }
                &.reversed{
                    left: 0px;
                    align-items: flex-start;
                    &.doubles{
                        &.swap{
                            .player-imgs .player-img.A{
                                left: $player-img-size + ($player-img-margin * 2) - $serve-swap-offset;
                                z-index: 1;
                            }
                            .player-imgs .player-img.B{
                                left: -($player-img-size + ($player-img-margin * 2));
                                z-index: 0;
                            }
                        }
                        .player-imgs .player-img.A{
                            left: 0px;
                        }
                        .player-imgs .player-img.B{
                            left: -$serve-swap-offset;
                        }
                    }
                }
                &.adDeuce{
                    top: $court-height - $player-box-height;
                }
                &.doubles{
                    &.swap{
                        .player-imgs .player-img.A{
                            
                            right: -($player-img-size + ($player-img-margin * 2));
                            left: unset;
                            z-index: 1;
                        }
                        .player-imgs .player-img.B{
                            right: $player-img-size + ($player-img-margin * 2) - $serve-swap-offset;
                            left: unset;
                            z-index: 0;
                        }
                    }
                    .player-imgs .player-img.A{
                        right: -$serve-swap-offset;
                        left: unset;
                    }
                    .player-imgs .player-img.B{
                        right: 0px;
                        left: unset;
                    }
                }
            }
            .service-box{
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0px;
                width: $service-box-full-width;
                height: $service-box-height;
                border: $borders;
                overflow: visible;
                .vis-notice-container{
                    position: absolute;
                    opacity: 0;
                    transition: opacity $fade-speed;
                    &.rally, &.duration{
                        margin-top: -3px;
                        .vis-notice{
                            padding: 3px 5px 2px 5px;
                        }
                    }
                    &.winner{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
                        .winner-names{
                            display: flex;
                            flex-direction: column;
                            align-items: center;                        
                            .winnerName{
                                color: black;
                                @include interstate(regular);
                                font-size: 12px;
                            }
                        }
                    }
                    &.visible{
                        opacity: 1;
                    }
                    .changeover-anim{
                        position: absolute;
                        width: 100%;
                        left: 50%;
                        transform: translateX(-50%);                        
                    }
                    
                    .vis-notice{
                        @include stVizTextBox();
                        padding: 5px;
                        font-size: 12px;
                        .winner-text{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;                            
                            .win-icon{
                                margin-top: 1px;
                                margin-right: 5px;                            
                                .wim-icon-wf-checkmark{
                                    font-size: 12px;
                                    color: green; //TODO REPLACE THIS
                                }
                            }
                        }
                        .text{
                            &.caps{
                                font-size: 8px;
                            }
                            &.larger{
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}