.tv_intl {
	.default-wrapper{ margin-top: 30px;}
	
	.table {
		&:first-child {
			margin-top: 0px;
		}
		margin-top: 30px;
		@include interstate(regular);
		font-size: 1rem;
		width: 100% !important;
		color: $grey;
		@include media-breakpoint-up(sm) {
			font-size: 1.1rem;
		}
		@include media-breakpoint-up(md) {
			font-size: 1.2rem;
		}
		th {
			@include interstate(regular);
			font-size: 0.9em;
		}
		th,
		td {
			padding: 0.45rem;
			border: none;
			@include media-breakpoint-up(sm) {
				padding: 0.75rem;
			}
			@include media-breakpoint-up(md) {
				padding: 1rem;
			}
		}
		tbody {
			&.title {
				background-color: $usoBkgGray;
				color: $usoDkGray;
				@include interstate(bold); text-transform: uppercase;
			}
		}

		tbody + tbody {
			border: none !important;
		}
		tfoot {
			tr {
				background-color: $usoBkgGray;
				&.empty {
					height: 1px;
					td {
						padding: 0px;
					}
				}
				td {
					&:first-child,
					&:last-child {
						border: none;
					}
				}
			}
		}
	}

    thead tr, .day:nth-child(odd){
        // background-color: $lt-gray;
        // border-bottom: 1px solid $usoMdGray;
    }
    thead .row-spacer{
        background-color: $white;
        border-bottom: 1px solid $usoMdGray; 
    }
	thead.headerTitle {
		background-color: $white;
		height: 62px;
		border-bottom: 1px solid $usoMdGray; 
		border-top: 1px solid $usoMdGray; 
		margin-top: 30px;
		text-align: center;
		.header{ 
			@include interstate;
			font-size: 18px; line-height: 34px;
			text-transform: uppercase;
			color: $dk-gray2;
		}
	}
    tr.quals{
        background-color: $white; @include interstate(light); color: $usoDkGray;
    }

	tr.dayHeader {
		background-color: $white;
		height: 72px;
		@include general-breakpoint(landscape) {
			height: 90px;
		}
		.header {
			@include interstate(regular);
			text-transform: uppercase;
			color: $usoDkGray;
			margin: 0px auto;
			border-bottom: 1px solid $usoMdGray;
			font-size: 18px;
			line-height: 34px;
			text-align: center;
			vertical-align: middle;

			&.button {
				vertical-align: middle;
				padding: 0 3px;
				text-align: center;
				.uso-button {
					@include general-breakpoint(landscape, max) {
						width: 100%;
						height: auto;
						line-height: 30px;
						font-size: 12px;
					}
				}
			}
		}
		&:first-child {
			.header {
				border-top: none;
			}
		}
	}

	.day,
	.networks {
		background-color: $white;
		// th, td{ border-bottom: 1px solid $usoLtGray;}
		.row-spacer {
			border: none;
		}
		// &:nth-child(odd){
		//   th, td{border: none!important;}
		// }
	}

	.row-spacer {
		padding: 0 !important;
		margin: 0;
		background-color: $usoBkgGray;
		width: 13px;
		@include general-breakpoint(landscape) {
			width: 20px;
		}
	}
}

/** prize money table **/
.prize-money {
	width: 100%;
	margin-top: 15px;
	border: 1px solid $md-gray2;

	tbody {
		background-color: $usoBkgGray;
	}

	tr {
		th {
			background-color: $md-gray2;
			@include interstate(medium);
			text-transform: uppercase;
			padding: 5px 10px;
			text-align: right;
			color: $dk-gray2;
			line-height: 22px;
			font-size: calc((14/13) * 1em);

			&:first-child {
				text-align: left;
			}
		}

		td {
			@include interstate(light);
			padding: 2px 10px;

			p {
				text-align: right;
				padding: 0;

				&:first-child {
					padding: 0;
				}
			}

			&:first-child p {
				text-align: left;
			}
		}

		&:last-child {
			@include interstate(medium);
			color: $bright-blue;

			td:first-child p,
			td p {
				@include interstate(medium);
			}
		}

		&:nth-child(even) {
			background-color: $table-light-grey;
		}
	}

	&.summary {
		margin-top: -17px;

		tr {
			th {
				display: none;
			}

			&:last-child {
				color: $bright-blue;
				background-color: $md-gray2;
				@include interstate(medium);
			}
		}
	}
}

table caption {
	height: 0;
	visibility: hidden;
}

table.blue {
	border: 0;
	margin-top: $general-outer-margin;
	width: 100%;

	tbody {
		tr:nth-child(odd) {
			background-color: $blue2;

			td {
				padding: 0.5em 0;
			}
		}

		tr:nth-child(even) {
			background: $light-blue;
		}
	}

	th,
	td {
		font-size: calc((14/14) * 1em);
		vertical-align: middle;

		p {
			padding-top: 0;

			&:first-child {
				padding-top: 0px;
			}
		}
	}

	th {
		@include interstate(medium);
		font-size: calc((12/14) * 1em);
		text-transform: uppercase;
		padding: 0.5em 0;
	}
}

table.grey {
	td a {
		@include interstate(light);
	}

	td,
	th {
		font-size: 1rem;
		@include media-breakpoint-up(sm) {
			font-size: calc((14/13) * 1em);
		}
	}
}

table.grey {
	border: solid 1px $table-border-grey;
	margin-top: $general-outer-margin;
	margin-bottom: $general-inner-margin;
	width: 100%;

	tbody {
		tr:nth-child(odd) {
			background-color: $table-light-grey;
		}

		tr:nth-child(even) {
			background: $table-med-grey;
		}
	}

	thead tr {
		background: $table-dark-grey;
	}

	th,
	td {
		font-size: 1 rem;
		padding: 10px 5px;
		@include media-breakpoint-up(sm) {
			font-size: calc((14/12) * 1em);
			padding: 10px 25px;
		}
		vertical-align: middle;
		border-left: solid 1px $table-border-grey;
		color: $dk-gray2;

		&.center {
			text-align: center;
		}

		&:first-child {
			border-left: none;
		}

		p {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	th {
		@include interstate(medium);
		font-size: 1rem;
		@include media-breakpoint-up(sm) {
			font-size: calc((14/12) * 1em);
		}
		text-align: left;
	}
}

// .playoff_table {
//     div { font-size: calc((12/12) * 1em); }
// }

// .playoff_table { font-size: calc((12/13) * 1em);
//     .header.mobile div { font-size: 10px; }
// }

/***********
 responsive data tables - playoff registration schedule & results
 includes nested table
**********/

$playoff_content_width: 1220px; /* actual content area in 960px design */
$register_col_width: 986px;
@function regcolcp($width) {
	@return cp($width, $register_col_width);
}
@function playoffcp($width) {
	@return cp($width, $playoff_content_width);
}

.playoff_table {
	color: $dk-gray2;
	@include make-col-ready();
	@include media-breakpoint-up(sm) {
	// 	@include make-col-offset(1);
	// 	@include make-col(10);
		font-size: 1.3rem;
	}
	display: table;
	margin: 10px 0;
	position: relative;
	width: 100%;
	font-size: 1.2rem;
	/* Registration\Schedule cols */
	.playoff_mobileCol {
		width: 80%;
		float: left;
		padding: 0;
		border-right: solid 1px $table_border_grey;
		border-left: solid 1px $table_border_grey;
		.messagetext {
			width: 100%;
			padding: 0.5em ! important;
			text-align: center;
		}
	}
	.playoff_reg_date {
		width: 100%;
	}
	.playoff_reg_city {
		width: 100%;
		overflow: hidden;
	}
	.playoff_reg_venue {
		width: 100%;
	}
	.playoff_reg_close {
		width: 100%;
	}
	.playoff_reg_register {
		width: 20%;
		float: left;
		text-align: center;
		padding: 0.5em 0 !important;
		display: table-cell;
	}

	@include general-breakpoint(landscape) {
		.playoff_mobileCol {
			width: playoffcp(986px);
		}
		.playoff_reg_date {
			width: regcolcp(207px);
			text-align: center;
			float: left;
		}
		.playoff_reg_city {
			width: regcolcp(226px);
			text-align: center;
			float: left;
		}
		.playoff_reg_venue {
			width: regcolcp(389px);
			text-align: center;
			float: left;
		}
		.playoff_reg_close {
			width: regcolcp(164px);
			text-align: center;
			float: left;
		}
		.playoff_reg_register {
			width: playoffcp(234px);
			text-align: center;
			float: left;
		}
	}
	/* Results cols */
	.playoff_res_mobileCol {
		width: 25%;
		float: left;
	}
	.playoff_res_date {
		width: 100%;
		text-align: center;
		float: left;
	}
	.playoff_res_section {
		width: 100%;
		text-align: center;
		float: left;
	}
	.playoff_res_winner {
		width: 75%;
		float: left;
		border-left: solid 1px $table_border_grey;
	}

	@include general-breakpoint(landscape) {
		.playoff_res_mobileCol {
			width: playoffcp(365px);
			&:after {
				content: '';
				position: absolute;
				left: 13.5%;
				border-right: 1px solid $table_border_grey;
				height: 100%;
			}
		}
		.playoff_res_date {
			width: 45%;
		}
		.playoff_res_section {
			width: 55%;
		}
		.playoff_res_winner {
			width: playoffcp(855px);
		}
	}

	.label {
		@include interstate(medium);
		float: left !important;
		padding: 0.3em 0 0.3em 10px;
		width: 40%;
		@include general-breakpoint(landscape) {
			display: none;
		}
	}

	dl.winners {
		dd {
			float: left;
			margin: 0;
			padding: 0.5em 0;
			width: 70%;
		}
		dt {
			@include interstate(medium);
			float: left;
			clear: left;
			padding: 0.5em 0 0.5em 10px;
			width: 30%;
			&:after {
				content: ':';
			}
		}
	}

	.table_title {
		background: $table_dark_grey;
		@include interstate(medium);
		overflow: hidden;
		position: relative;
		width: 100%;
		float: left;
		border-bottom: solid 1px $white;
		line-height: 2.8em;
		padding-left: 10px;
	}
	.header,
	.pl-row,
	.row {
		overflow: hidden;
		position: relative;
		width: 100%;
		float: left;
		@include general-breakpoint(landscape) {
			display: table-row;
		}
		div {
			@include interstate(medium);
			padding: 0;
			vertical-align: middle;
		}
		.playoff_reg_date, .playoff_reg_city, .playoff_reg_venue, .playoff_reg_close, /* playoff registration cols */
      .playoff_res_date, .playoff_res_section  /* playoff results cols */ {
			padding: 0.5em;
		}
		@include general-breakpoint(landscape) {
			.playoff_mobileCol {
				display: table;
				border-right: solid 1px $table_border_grey;
				div {
					display: table-cell;
					float: none;
					border-left: solid 1px $table_border_grey;
					&:first-child {
						border-left: none;
					}
				}
			}
		}
	}
	.header {
		display: none;
		background: $table_dark_grey;
		border-bottom: solid 1px $white;
		&.mobile {
			display: table-row;
			.playoff_mobileCol {
				border-right: solid 1px $white;
				border-left: solid 1px $white;
				&:first-child {
					padding-left: 10px;
				}
			}
		}
		@include general-breakpoint(landscape) {
			display: table-row;
			&.mobile {
				display: none;
			}
			.playoff_mobileCol {
				border-right: solid 1px $white;
				border-left: solid 1px $white;
				div {
					border-left: solid 1px $white;
				}
			}
		}
		.playoff_reg_register,
		.playoff_res_section,
		.playoff_res_winner {
			padding: 0.5em 0;
		}
		.playoff_reg_register {
			padding: 0.4em;
		}
		.playoff_res_winner {
			padding-left: 10px;
			border-left: solid 1px $white;
		}
	}
	.table_title + .header {
		background: $table_border_grey;
	}
	.pl-row,
	.row {
		border: solid 1px $table_border_grey;
		border-bottom: none;
		&.hide {
			display: none;
		}
		&.show {
			display: table-row;
		} /* display:block will create misalignments */
		@include general-breakpoint(landscape) {
			padding-bottom: 0;
		}
		&:nth-child(odd),
		&.odd {
			background-color: $table_light_grey;
		}
		&:nth-child(even),
		&.even {
			background: $table_med_grey;
		}
		div {
			@include interstate(light);
		}
		a {
			display: block;
			padding-top: 0.5em;
			padding-bottom: 0.5em;
		}
		&:last-child {
			border-bottom: solid 1px $table_border_grey;
		} // last row
		.playoff_res_section a {
			padding-top: 0;
		}
	}
}

/** Schedule **/
#tv-schedule {
	/** event schedule **/
	&.event {
		.table {
			@include interstate(regular);
			font-size: 1.1rem;
			width: 100% !important;
			color: $usoDkGray;
			th,
			td {
				padding: 0.75rem;
				border: none;
				text-align: left;
			}
			thead tr {
				background-color: $white;
			}
			tbody + tbody {
				border: none;
			}
			.dayHeader {
				border-bottom: 1px solid $usoLtGray;
				height: 90px;
				text-transform: uppercase;
				font-size: 18px;
				line-height: 34px;
				.header {
					text-align: center;
					vertical-align: middle;
				}
			}
			.title {
				background-color: $usoBkgGray;
				@include interstate(bold);
				font-size: 14px;
				line-height: 16px;
				text-transform: uppercase;
			}
			.day {
				background-color: $white;
				@include interstate(light);
				font-size: 16px;
				line-height: 22px;
				tr:first-child {
					td {
						border-top: 1px solid $usoLtGray;
					}
				}
				tr {
					&.live {
						@include interstate(bold);
					}
				}
				td {
					border-bottom: 1px solid $usoLtGray;
				}
				i {
					font-size: 10px;
					padding-left: 9px;
				}
				.row-spacer {
					background: $usoBkgGray;
					border: none !important;
				}
			}
			.empty {
				background-color: $usoBkgGray;
			}
		}
		//     .tv_live_ind:before {
		//       width: 25px;
		//       content: '';
		//       height: 20px;
		//       background: url(/assets/images/misc/live_tv_indicator.png);
		//       background-repeat: no-repeat;
		//       background-size: 17px;
		//       background-position: 0 7px;
		//       display: inline-block;
		//       position: absolute;
		//     }
		//     .tv_legend {
		//       width: 25px;
		//       content: '';
		//       height: 20px;
		//       background: url(/assets/images/misc/live_tv_indicator.png);
		//       background-repeat: no-repeat;
		//       background-size: 17px;
		//       background-position: 0px 10px;
		//       display: inline-block;
		//       position: relative;
		//     }
		//     .tv_sched_live {
		//       font-weight: bold;
		//     }
		//    .content-wrap .blue{ background-color: $blue; }
		//    .filterContainer{ border-bottom: 1px solid $light_blue;
		//      .filter{ height: 45px; float: left; }
		//      .print-button{ display: none;
		//        @include general-breakpoint(tablet) {float: left; display: inline-block; margin-left: 120px;}
		//      }
		//      .filter-overlay{ top: 127px;
		//        @include general-breakpoint(landscape) {top: 79px; }
		//      }
		//    }
		//    .general_content { display: inline-block; width: 100%;
		//      @include general-breakpoint(tablet) { width: calc(100% - 320px); }
		//    }
		//    .moreNews{
		//      /* display: none; */
		//      @include general-breakpoint(tablet) { display: inline-block; float: right; width: 320px; }
		//      &.blue{
		//        .chip_content{ background-color: $blue; }
		//      }
		//    }
		//    // .header{ display: table-row; }
		//    .sch_btn {
		//      width: 100%;
		//      margin: auto auto 2rem auto;
		//      text-align: center;
		//      // @include general-breakpoint(landscape) { width: 28%; margin:0 5px 0 0; }
		//    }
		//    .sch_btn.wide + table .row-spacer {
		//      background-color: #fff;
		//    }
		//    .ticket_table{ text-align: center;
		//      .header.mobile {
		//        @include general-breakpoint(landscape) { display: none; }
		//        display: table-row;
		//        width: 100%;
		//        height: 30px;
		//        .sch_session {
		//          width: 55% ! important;
		//          position: absolute;
		//        }
		//        .sch_match {
		//          position: absolute;
		//          right: 0;
		//          width: 45% ! important;
		//        }
		//      }
		//      .hide{ display: none; @include general-breakpoint(landscape) { display: inline-block;} }
		//      .sch_session{ width: 10%; }
		//      .sch_day, .sch_date{ width: 5%; }
		//      .sch_day, .sch_date, .sch_session{display: table-cell;  @include general-breakpoint(landscape) { width: 15%;}
		//        .mobile{ visibility: hidden;
		//        /*some weirdness happening via mobile - amb082817*/
		//        /*@include general-breakpoint(landscape) { visibility: hidden;}*/
		//        }
		//      }
		//      .sch_time{ display: table-cell; width: 20%; }
		//      .sch_match{ display: table-cell; width: 35%; }
		//      .ticket_row{ height: 100%; display: table-row;
		//        @include general-breakpoint(landscape) { height: 40px; }
		//        &.light{ background: $light_blue2; }
		//        &.dark{ background-color: $blue2; }
		//      }
	}
}

.special-table {
	display: none;
	color: #000;
	@include media-breakpoint-up(lg) {
		display: table;
		font-size: 1rem;
	}
	thead {
		background-color: $table-dark-grey;
		color: #000;
		border-bottom: 1px solid #000;
		font-size: 1.1rem;
		text-align: center;
	}
	.col-vertical {
		text-align: center;
		span {
			writing-mode: vertical-lr;
			transform: rotate(180deg);
			font-size: 1.2rem;
			font-weight: bolder;
		}
	}
	td:nth-child(n + 4) {
		vertical-align: top;
	}

	&.mobile {
		display: table;
		margin-left: -20px;
		margin-right: -20px;
		font-size: 1.1rem;
		@include media-breakpoint-up(lg) {
			display: none;
		}
		thead {
			font-size: 1.4rem;
		}
		tbody {
			border: 1px solid #000;
			> tr:first-child {
				border-top: 2px solid #000;
			}
			tr:nth-child(-n + 2) {
				> td {
					width: 55%;
				}
			}
			tr {
				td {
					padding: 2px;
				}
			}
			tr:nth-child(n + 3) td {
				padding: 5px;
			}
		}
	}

	.special-table-blue {
		background-color: $table_med_grey;
	}
}

//international tv schedule
.tv_intl {
	min-height: 300px;
	@include media-breakpoint-down(md) {
		.hosp_intro {
			p {
				font-size: 1.2rem;
			}
		}
	}

	.submenu-details-wrapper {
		.tv {
			.area {
				font-size: 14px;
			}
		}
	}
	.table {
		margin-top: 0px;
	}
	.countries-wrapper{
		margin-top: 40px;
		table{ width: 100%;}
	}
	.intl-broadcasters-select-wrapper {
		display: flex;
		justify-content: center;
		padding: 10px 0;
		background-color: $usoBkgGray;
		text-align-last: center;

		select {
			position: relative;
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			background-image: url('/assets/images/icons/dropdown-arrow.svg');
			background-repeat: no-repeat;
			background-position-x: 94%;
			background-position-y: 50%;
			background-color: $white;

			@include interstate();
			width: 220px;
			height: 26px;
			font-size: 14px;
			text-align: center;
			border-radius: 3px;
			border-color: $usoLtBlue;
			color: $usoLtBlue;
		}
	}

	.title {
		.row-spacer {
			width: 2%;
		}
		.region {
			width: 20%;
		}
		.country {
			width: 41%;
		}
		.channel {
			width: 35%;
		}
	}
	.networks {
		min-height: 300px;
		tr:first-child {
			td {
				border-top: 1px solid $usoLtGray;
			}
			.row-spacer {
				border-top: none;
			}
		}
		td {
			border-bottom: 1px solid $usoLtGray;
			padding: 15px 5px;
			color: $usoDkGray;
			font-size: 12px;
			line-height: 14px;
			@include media-breakpoint-up(sm) {
				padding: 20px 10px;
				font-size: 16px;
				line-height: 22px;
			}
			@include interstate(light);
			.td-spacer {
				@include media-breakpoint-up(sm) {
					width: 80%;
				}
			}
			&.channel {
				.title {
					display: block;
					padding-bottom: 10px;
				}
				.info {
					display: flex;
					flex-direction: column;
					@include media-breakpoint-up(lg) {
						flex-direction: row;
					}
					.img {
						flex: 1 1 100%;
						padding-bottom: 15px;
						img {
							width: 100%;
						}
						@include media-breakpoint-up(sm) {
							flex: 1 1 65%;
							img {
								width: 85%;
							}
						}
					}
					.watch {
						flex: 1 1 100%;
						display: flex;
						justify-content: center;
						@include media-breakpoint-up(sm) {
							flex: 0 0 35%;
						}
						.uso-button {
							min-width: 92px;
							width: 92px;
							height: 31px;
							padding: 0px;
						}
					}
				}
			}
		}
	}
}
