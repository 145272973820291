.slamtracker-weather {
    float: left;
    width: 100%;
    background: $lt-gray;
    padding: 16px;
    margin-bottom: 15px;
    text-align: center;
    @include interstate;
    border-top: 20px solid $md-gray2;

    .weather-title {
        width: 100%;
        text-align: center;
        color: $black;
        font-size: 14px;
        margin-bottom: 10px;

        .weather-time {
            color: $md-gray;
            font-size: 12px;
            padding: 3px 0;
            text-transform: uppercase;
        }
    }
    .weather-content {
        display: table;
        width: 100%;
        margin: 0;
    }
    .weather-row {
        display: table-row;
        width: 100%;
        
        &.images {
            height: 39px;
            text-align: center;
            margin-bottom: 5px;
        }
        &.data {
            .weather-cell {
                padding-top: 6px;
            }
        }
    }
    .weather-cell {
        display: table-cell;
        vertical-align: middle;
    }
    .temperature {
        width: 30%;
        color: $md-gray;
        text-align: center;

        .image {
            background: url('/assets/images/scores/slamtracker/icon-temperature.png');
            width: 39px;
            height: 39px;
            margin: 0 auto;
        }
        .number {
            font-size: 36px;
            line-height: 35px;
            position: relative;
            padding-right: 5px;
            display: inline-block;

            &:after {
                content: '\000B0';
                position: absolute;
                top: 3px;
                right: -3px;
                font-size: 16px;
                line-height: 16px;
            }
        }
    }
    .wind {
        width: 40%;
        color: $md-gray;
        font-size: 24px;
        line-height: 21px;

        .image {
            background: url('/assets/images/scores/slamtracker/icon-wind.png');
            width: 60px;
            height: 28px;
            margin: 0 auto;
        }
        .speed {
            display: inline-block;
            position: relative;
            padding-right: 6px;
            border-right: 1px solid $md-gray;
        }
        .direction {
            display: inline-block;
            padding-left: 5px;

            .number {
                position: relative;

                &:after {
                    content: '\000B0';
                    position: absolute;
                    top: 0px;
                    right: -3px;
                    font-size: 9px;
                    line-height: 9px;
                }
            }
        }
        .text {
            text-transform: uppercase;
            font-size: 9px;
            line-height: 9px;
        }
    }
    .humidity {
        width: 30%;
        color: $md-gray;
        text-align: center;
        font-size: 24px;
        line-height: 21px;

        .image {
            background: url('/assets/images/scores/slamtracker/icon-humidity.png');
            width: 35px;
            height: 23px;
            margin: 0 auto;
        }
        .relative {
            display: inline-block;
            position: relative;
            padding-right: 10px;

            .number {
                position: relative;
                padding-right: 10px;

                &:after {
                    content: '%';
                    font-size: 9px;
                    line-height: 9px;
                    position: absolute;
                    top: 4px;
                    right: 0px;
                }
            }
            &:after {
                content: '';
                position: absolute;
                width: 1px;
                height: 70%;
                top: 15%;
                right: 0px;
                background-color: $md-gray;
            }
        }   
        .precipitation {
            display: inline-block;
            padding-left: 10px;

            .number {
                position: relative;

                &:after {
                    content: '"';
                    font-size: 9px;
                    line-height: 9px;
                    position: absolute;
                    top: 4px;
                    right: -5px;
                }
            }
        }
        .text {
            text-transform: uppercase;
            font-size: 9px;
            line-height: 9px;
        }
    }
}