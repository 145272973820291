

.column-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    padding: 0 $side-margin-small;
    background-color: $white;

    .one-col,
    .two-col,
    .three-col,
    .four-col {
        width: calc(100% - #{$item-h-margin} * 2);
        flex: 0 0 auto; //margin: 7px 5px 7px 5px;
        margin: $item-v-margin $item-h-margin $item-v-margin $item-h-margin;
    }

    @media only screen and (min-width: $column-break-1) {
        padding: 0 $side-margin-med;
        .one-col {
            width: calc(50% - #{$item-h-margin} * 2);
        }
        .two-col,
        .three-col,
        .four-col {
            width: calc(100% - #{$item-h-margin} * 2);
        }

        .one-col,
        .two-col,
        .three-col,
        .four-col {
            &.clear-two {
                //clear: left;
            }
        }
    }

    @media only screen and (min-width: $column-break-2) {
        // padding: 0 $side-margin-large;//need to change margins for entire site - amb062222
    padding: 0px 0px;
        .one-col {
            width: calc(25% - #{$item-h-margin} * 2);
        }
        .two-col {
            width: calc(50% - #{$item-h-margin} * 2);
        }
        .three-col {
            width: calc(75% - #{$item-h-margin} * 2);
        }
        .four-col {
            width: calc(100% - #{$item-h-margin} * 2);
        }

        .one-col,
        .two-col,
        .three-col,
        .four-col {
            &.clear-two {
                clear: none;
            }
            &.clear-four {
                //clear: left;
            }
        }
    }

    @media only screen and (min-width: $column-break-3) {
        // padding: 0 $side-margin-xlarge;//need to change margins for entire site - amb062222
        padding: 0px 0px;
    }
}