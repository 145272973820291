.fanweek-hero {
    background: transparent url('/assets/images/misc/fanweek_background_image.jpg') no-repeat 0 0;
    background-size: cover;
    min-height: 697px;
    color: $white;
    text-align: center;
    padding: 30px 20px;

    /** make the hero section wider than parents */
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);

    @include general-breakpoint(smtablet) {
        min-height: 500px;
    }

    h2 {
        @include interstate(bold);
        font-size: px2rem(42);
        color: $white;
        text-transform: uppercase;

        span {
            color: $usoYellow;
        }

        @include general-breakpoint(smtablet, max) {
            font-size: px2rem(32);
            span {
                display: block;
            }
        }
    }

    h3 {
        @include interstate(bold);
        font-size: px2rem(16);

        @include general-breakpoint(smtablet) {
            font-size: px2rem(20);
        }
    }

    h4 {
        @include interstate(light);
        font-size: px2rem(24);

        span {
            @include interstate(bold);
        }

        @include general-breakpoint(smtablet) {
            font-size: px2rem(30);
        }
    }

    p {
        font-size: px2rem(16);
        max-width: 970px;
        margin: auto;

        @include general-breakpoint(smtablet) {
            font-size: px2rem(18);
        }
    }

    a.fanweek {
        @include interstate(bold);
        font-size: px2rem(14);
        color: $dark-blue3;
        border-radius: 8px;
        background-color: $usoYellow;
        text-align: center;
        text-transform: uppercase;
        line-height: 60px;
        height: 60px;
        display: block;
        margin: auto;
        margin-top: 30px;
        width: auto;
        padding-left: 30px;
        padding-right: 30px;
        max-width: 320px;
        border: 2px solid transparent;

        @include general-breakpoint(smtablet) {
            font-size: px2rem(14);
            max-width: 420px;
        }
        
        &:hover{
            background-color: #fff;
            color: #418FDE !important;
            border: 2px solid $usoLtBlueBG;
            text-decoration: none;
        }
  
    }
}

.fanweek-wrapper {
    @extend .wrapper; // use the same global margin as it's set at .wrapper and .page-content

    /** override the breakpoit to be larger desktop from the ones in gloal.scss to support 
        Fan Week calendar's dropdown menu */
    .above-tablet {
        display: none;

        @include general-breakpoint(smtablet) {
            display: flex;
        }
    }

    .below-tablet {
        display: none;
        
        @include general-breakpoint(smtablet, max) {
            display: flex;
        }
    }
}

/** override to make header hero 100vs */
#uso-main .wrapper {
    &.fanweek {
        max-width: unset;
    }
}

#uso-main {
    .page-content {
        &.tourn-fanweek {
            max-width: unset;
            overflow: visible;
            margin-top: -80px !important;

            @include general-breakpoint(smtablet) {
                margin-top: -200px !important;
            }

            @include general-breakpoint(smdesktop) {
                margin-top: -300px !important;
            }
        }
    }
    .schedule-of-events {
        .page-content {
            &.tourn-fanweek {
                margin-top: -207px !important;

                @include media-breakpoint-up(sm) {
                    margin-top: -260px !important;
                }

                @include general-breakpoint(landscape) {
                    margin-top: -260px !important;
                }

                @include general-breakpoint(smtablet) {
                    margin-top: -269px !important;
                }
            }
        }
    }
}

/** .fanweek only override for general content, such as premium-section */
.wrapper {
    &.fanweek {
        .contentWrapper-border {
            +.premium-section {
                margin-top: 0 !important;
            }
        }
        .premium-section {
            @extend .wrapper; // use the same global margin as it's set at .wrapper and .page-content
            margin: 20px auto !important;

            .premium-content {
                background-color: $white;
                margin-right: 0;
                margin-left: 0;

                .image {
                    max-width: unset;
                    align-self: center;

                    position: relative;
                    width: 100%;
                    min-height: 1px;
                    padding-right: 15px;
                    padding-left: 15px;
                }

                .text {
                    padding: 18px 20px;

                    @include general-breakpoint(desktop) {
                        padding: 28px 40px;
                    }
                    h2 {
                        text-transform: uppercase;
                        font-size: px2rem(18);
                    }

                    p {
                        font-size: px2rem(14);
                    }
                }
            } // .premium-content
            .contentWrapper {
                width: 100%;
            }
        } // .premium-section

        .hospitality_content, 
        .chip-container {
            @extend .wrapper; // use the same global margin as it's set at .wrapper and .page-content
            margin: 20px auto!important;
        }

        .uso-button {
            &.wider {
                @include general-breakpoint(tablet, max) {
                    width: 230px;
                }
            }
        }
    } // .fanweek
    &.fan-week-page {
        flex-direction: column;

        .hero_container {
            z-index: 0;
            
            .hero_content {
                .title_container {
                    top: 20px;

                    .landing_title {
                        h1 {
                            text-transform: uppercase;
                            color: $white;

                            .alt {
                                color: #FFD400;
                            }
                        }
                        p {
                            @include interstate(light);
                        }
                    }
                    @include general-breakpoint(smtablet) {
                        top: 28px;

                        .landing_title {
                            h1 { 
                                font-size: 38px; 
                                line-height: 45.6px;
                                padding-bottom: 0;
                            }
                            p {
                                font-size: px2rem(18);
                                line-height: 21.6px;
                            }
                        }
                    }
                    @include draws-breakpoint(sidepanel) {
                        top: 28px;

                        .landing_title {
                            h1 { 
                                font-size: 42px; 
                                line-height: 50.4px;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .faq-section {
            // @extend .wrapper; // use the same global margin as it's set at .wrapper and .page-content
            margin: 30px auto;
            background-color: $usoBkgGray;

            .faq-wrapper {
                padding-right: 0;
                padding-left: 0;
                
                h4 {
                    @include interstate(bold);
                    font-size: px2rem(18);
                    text-transform: uppercase;
                    margin-bottom: 25px;
                }
            } // .faq-wrapper
        } // .faq-section

    }
    &.arthur_ashe_kids_day {
        flex-direction: column;
    }
} //.wrapper

.visit-sub.fanweek-filters{
    padding-top: 10px;
    align-self: center;
    @include general-breakpoint(desktop, max){
        padding: 5px 0;
    }
    @include general-breakpoint(smtablet, max){
       text-align: center;
    }
}

.tourn-fanweek{
    &.uso-tickets-calendar {
        display: block;
        margin-bottom: 5px;
        width: 100%;
        overflow: hidden;
        z-index: 20;
    }

    /** override what's in _general_content.scss */
    .selectDatesCalendar {
        display: flex;
        flex-direction: column;

        .ticket_row_text {
            @include interstate(bold);
            font-size: px2rem(16);
            color:$white;
            text-transform: uppercase;
            text-align: center;
            width: 200px;
            margin: 0 auto 40px;

            @include general-breakpoint(smtablet) {
                width: 528px;
                font-size: px2rem(20);
            }
        }
        .ticket_row {
            padding-top: 0;
            position: relative;
            // width: 370px;
            height: 71px;
            margin: 0 auto;
            padding-bottom: 0;

            &:before {
                content: 'FAN WEEK';
                position: absolute;
                left: 0;
                top: -24px;
                height: 24px;
                line-height: 23px;
                width: 100%;
                background-color: #001D67;
                color: white;
                text-align: center;
                @include interstate;
                font-size: 14px;
                border: 1px solid $gray_8;
                border-bottom: 0;
            }
            @include general-breakpoint(smtablet) {
                // width: 462px;
            }
        }
        .date {
            height: 100%;
            width: calc(370px / 7);
            padding: 0;

            @include general-breakpoint(landscape) {
                width: calc(462px / 7);
            }
            &.select {
                height: 71px;
            }

            .date-as-calendar {
                height: 100%;
                background: transparent;
                
                .month {
                    top: 6px;
                    line-height: 10px;
                    font-size: px2rem(10);                }
                .day {
                    top: 23px;
                    line-height: 22px;
                    font-size: px2rem(22);
                }
                .weekday{
                    bottom: 8px;
                    line-height: 10px;
                    font-size: px2rem(10);
                }
            }            
        }
    }

    .selectDatesInfo {
        background-color: $usoBkgGray;
        width: 100%;
        padding: 0;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 49px auto 0;

        @include general-breakpoint(smtablet) {
            width: 728px;
            margin-top: 60px;
        }

        @include general-breakpoint(smdesktop) {
            width: 970px;
        }

        @include draws-breakpoint(sidepanel) {
            width: 1260px;           
        }

        .filters{
            display: flex;
            align-items: center;
            height: 53px;
            justify-content: center;
            border: 1px solid $usoLtGray;

            .below-tablet {
                width: 100%;
                height: 100%;
                justify-content: space-around;
                align-items: center;
            }
            .above-tablet {
                width: 100%;
                height: 100%;
            }
            @include general-breakpoint(smtablet) {
                height: 60px;
            }

            .selected-location {
                color: $white;
                padding-right: 20px;
                text-align: right;

                @include general-breakpoint(desktop) {
                    display: none;
                }
                
                @include general-breakpoint(smtablet, max) {
                    padding-left: 20px;
                    font-size: px2rem(12);
                }

                span {
                    @include interstate(bold);
                    display: inline-flex;
                    height: 56px;
                    position: relative;
                    padding-left: 10px;
                    padding-right: 10px;
                    text-align: center;
                    align-items: center;

                    @include general-breakpoint(smtablet) {
                        height: 68px;
                    }

                    &:after {
                        content: '';
                        display: block;
                        border-bottom: solid 4px $white;
                        position: absolute;
                        left: -1px;
                        bottom: 2px;
                        width: 100%;
                    }
                }
            }

            .filter-dropdown {
                height: 25px;
                margin-bottom: 0;
                background-color: $usoDkerBlue2;
                line-height: 22px;
                width: 145px;
                    
                select {
                    line-height: 25px;
                    font-size: px2rem(14);
                }
            }

            .fanweek-filters {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;

                .filter-item {
                    margin: 0 20px;
                    cursor: pointer;
                    @include interstate(light);
                    font-size: px2rem(12);

                    &::after {
                        content: attr(data-text);
                        content: attr(data-text) / '';
                        height: 0;
                        display: block;
                        visibility: hidden;
                        overflow: hidden;
                        user-select: none;
                        font-weight: 700;
                    }

                    &:hover {
                        text-decoration: none;
                        color: #fff;
                        font-weight: 700 !important;
                    }

                    &.active {
                        @include interstate(bold);
                        text-decoration: underline;
		                text-underline-offset: 22px;
		                text-decoration-thickness: 3px;
                        color: #fff;
                    }
                }
            }
        }

        .gridList{
            position: absolute;
            right: 12px;
            top: 25px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .gridListLabel{
                color: #4A4A4A;
                @include interstate(regular);
                font-size: 1.3em;
                margin-right: 5px;
            }
        }

        .fanweeekMobile{
            .fanweekWrapper {
                @include general-breakpoint(tablet, max){
                    &.list{
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }
        }

        .fanweekWrapper {
            .noEvents{
                text-align: center;
                color: #4A4A4A;
                padding-top: 10px;
            }
            .date{
                height: 75px;
                position: relative;
                background: #fff;
                border: 1px solid #418FDE;
                padding: 5px;
                margin: 10px 0px;
                cursor: pointer;

                &.mobile {
                    display: block;
                }
            }
            .fanweek-date {
                .arrow_down {
                    margin-right: 10px;
                    margin-top: 25px;
                    width: 17px;
                    height: 9px;
                    background: url("/images/nav/chevron-down.png") 0 0;
                    display: inline-block;
                    cursor: pointer;
                    background-size: 17px 9px;

                    &.open {
                        background: url("/images/nav/chevron-up.png")0 0;
                        background-size: 17px 9px;
                    }
                }

                &.mobile {
                    display: block;

                    @include media-breakpoint-up(sm) {
                        //display: none;
                    }
                }
            }

            .ticket_row {
                display: block;

                &.disabled {
                    border-color: #7b8caa;
                    cursor: default;

                    .date {
                        cursor: default;
                    }

                    .day,
                    .month,
                    .weekday,
                    .arrow_down {
                        color: #7b8caa;
                    }
                }
            }

            .stadium_ticket {
                display: inline-block;
                width: 100%;
                height: auto;
                background-color: $white;
                position: relative;
                margin: 5px 0px;
                border: 1px solid $md-gray;
                text-align: left;
                overflow: hidden;
                vertical-align: top;

                /* @include general-breakpoint(tablet) { width: 48%; margin: 10px 5px;height: 315px; } */
                /* @include general-breakpoint(tablet) { width: 48%; height: 245px; } */
                &.show {
                    display: inline-block;
                }

                .titleHeader {
                    width: 100%;
                    background-color: $lt-gray;
                    padding: 5px;
                    border-bottom: 1px solid $md-gray;

                    h2 {
                        display: inline-block;
                        width: 60%;
                        margin: 0 0 0 20px;
                        @include interstate(light);
                        font-size: calc((16/14) * 1em);
                        color: $dk-gray2;

                        @include media-breakpoint-up(sm) {
                            font-size: calc((24/14) * 1em);
                            width: 80%;
                        }

                        @include media-breakpoint-up(md) {
                            font-size: calc((28/14) * 1em);
                            width: 80%;
                        }
                    }

                    h3 {
                        display: inline-block;
                        width: 60%;
                        margin: 0 0 0 20px;
                        padding: 0;
                        @include interstate(light);
                        font-size: calc((14/14) * 1em);
                        color: $dk-gray2;

                        @include media-breakpoint-up(sm) {
                            font-size: calc((18/14) * 1em);
                            width: 80%;
                        }

                        @include media-breakpoint-up(md) {
                            font-size: calc((24/14) * 1em);
                            width: 80%;
                        }

                        a {
                            color: $dk-gray2;
                        }
                    }

                    .amt {
                        float: right;
                        text-align: right;
                        width: 30%;
                        display: inline-block;
                        font-size: calc((16/14) * 1em);
                        @include interstate(light);
                        color: $white;

                        @include general-breakpoint (tablet) {
                            font-size: calc((18/14) * 1em);
                            width: 20%;
                        }
                    }
                }

                .info {
                    display: inline-block;
                    padding: 5px;
                    @include interstate(light);
                    font-size: calc((14/14) * 1em);
                    width: 94%;
                    margin: 0 3%;
                    border-top: 1px solid gray;

                    @include general-breakpoint (tablet) {
                        font-size: calc((16/14) * 1em);
                    }

                    @include general-breakpoint (tablet) {
                        padding: 10px;
                        width: 45%;
                        margin: 0 2%;
                        border: none;
                    }

                    .ticket_row {
                        display: block;
                        padding-top: 10px;
                        text-align: left;
                    }

                    .ticket_name {
                        display: block;
                        padding-right: 5px;
                    }

                    .ticket_desc {
                        padding-left: 10px;
                        font-size: 1em;

                        @include general-breakpoint (tablet) {
                            padding-left: 20px;
                        }

                        ul {
                            padding-top: 0;
                            margin-left: 20px;

                            li {
                                line-height: 1.6em;
                            }
                        }
                    }

                    &.compact-info {
                        width: 100% ! important;
                        padding: 10px 2% ! important;
                        margin: 0 ! important;
                    }
                }

                .tickets {
                    display: block;
                    width: 100%;
                    padding: 5px;
                    vertical-align: top;
                    @include interstate(regular);

                    @include general-breakpoint (tablet) {
                        display: inline-block;
                        width: 50%;
                    }

                    .single_ticket {
                        padding: 12px 0px;
                        font-size: calc((16/14) * 1em);
                    }

                    .session {
                        display: inline-block;
                        width: 10%;
                        text-align: center;
                        vertical-align: top;
                    }

                    .time {
                        display: inline-block;
                        width: 30%;
                        text-align: center;
                        vertical-align: top;

                        @include general-breakpoint (tablet) {
                            width: 22%;
                        }

                        @include general-breakpoint (tablet) {
                            width: 22%;
                        }
                    }

                    .event {
                        display: inline-block;
                        vertical-align: top;
                        width: 60%;

                        @include general-breakpoint (tablet) {
                            width: 45%;
                        }

                        @include general-breakpoint (tablet) {
                            width: 45%;
                        }
                    }

                    .link {
                        display: block;
                        text-align: center;
                        margin: 20px 0 0 0;

                        @include media-breakpoint-up(sm) {
                            display: inline-block;
                            vertical-align: top;
                            width: 20%;
                            margin: 0;
                        }

                        a {
                            text-decoration: none;
                            cursor: pointer;
                            text-transform: uppercase;
                            white-space: nowrap;
                            color: $white;
                            background-color: $bright_blue;
                            padding: 5px 10px;
                            @include interstate;

                            @include media-breakpoint-up(md) {
                                padding: 5px 10px;
                            }
                        }
                    }
                }

                .highlight {
                    font-size: calc((8/14) * 1em);
                    /*color: #7b8caa*/
                    color: $dk-gray2;
                    text-transform: uppercase;
                    @include interstate(light);

                    @include general-breakpoint (tablet) {
                        font-size: calc((10/14) * 1em);
                    }

                    @include general-breakpoint (tablet) {
                        font-size: calc((12/14) * 1em);
                    }

                    &.link {
                        display: none;
                    }
                }

            }
        }
    }

    .filtersWrapper {
        .filters {
            width: 100%;
        }
    }

    .fanweekWrapper{
        &.grid{
            /*display: grid;
            grid-template-columns: 384px 384px 384px;
            grid-column-gap: 32px;
            grid-row-gap: 32px;*/
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .fanweek.section{
                width: 296px;
                margin: 0 16px 32px 16px;
                @include general-breakpoint(tablet, max){
                    width: 334px;
                }
                @include general-breakpoint(smtablet){
                    margin: 0 10px 30px 10px;
                }
                @include draws-breakpoint(sidepanel) {
                    width: 393px;
                }
            }
            .fanweek.section .content{
                .description{
                    flex-direction: column;
                    .left{
                        padding-right: 0;
                        padding-bottom: 12px;
                    }
                    .right{
                        padding-left: 0;
                        padding-top: 12px;
                        border-left: none;
                        border-top: 1px solid #C1C6C8;
                    }
                }
            }
        }
        &.list{
            @include general-breakpoint(smtablet, max){
                 margin: 16px;
            }

            .fanweek.section{
                // margin-bottom: 32px;
                @include general-breakpoint(smtablet) {
                    border-bottom: solid 1px $md-gray2;
                }
            }
        }
        .fanweek{
            &.section{
                display: flex;
                flex-direction: column;
                background-color: white;
                text-align: left;

                @include general-breakpoint(smtablet, max){
                    flex-direction: column;
                    padding: 0;
                    margin-bottom: 40px;
                    max-width: 344px;
                    min-width: 300px;
                }
                @include general-breakpoint(smtablet) {
                    margin-bottom: 30px;
                }
                @include general-breakpoint(smdesktop) {
                    margin-bottom: 50px;
                }

                .image{
                    width: 100%;
                    height: 193px;
                    cursor: pointer;

                    img{
                        width: 100%;
                        aspect-ratio: 16 / 9;
                    }

                    @include general-breakpoint(smtablet) {
                        height: 188px;
                    }

                    @include general-breakpoint(smdesktop) {
                        height: 166px;
                    }

                    @include draws-breakpoint(sidepanel) {
                        height: 221px;
                    }
                }

                .content-container {
                    height: 502px;
                    max-height: 502px;
                    border: 1px solid #C1C6C8;
                    border-top: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                }
                .content{
                    display: flex;
                    flex-direction: column;
                    padding: 16px;
                    padding-bottom: 0;
                    
                    &.info {
                        height: auto;
                        max-height: 238px;
                    }

                    @include general-breakpoint(tablet, max){
                        &.no-top-padding {
                            padding: 0 16px 20px;
                        }
                    }

                    .title{
                        @include interstate(bold);
                        font-size: px2rem(18);
                        color: $usoDkGray;
                        padding-bottom: 18px;
                    }

                    .fanweek-date,
                    .location  {
                        @include interstate(regular);
                        font-size: px2rem(14);
                        padding-bottom: 6px;
                    }

                    .location {
                        @include interstate(bold);
                        text-transform: uppercase;
                    }

                    .time {
                        @include interstate(light);
                        padding-bottom: 4px;
                    }

                    .description{
                        @include interstate(light);
                        // height: 100px;
                        // max-height: 100px;
                        font-size: px2rem(14);
                        flex: 1;
                        padding-right: 18px;
                        display: -webkit-box; 
                        -webkit-box-orient: vertical; 
                        -webkit-line-clamp: 2; 
                        overflow: hidden; 
                        text-overflow: ellipsis; 

                        p {
                            margin-bottom: 0;

                            &:first-child {
                                padding-top: 8px;
                            }
                        }

                        @include general-breakpoint(tablet, max){
                            flex-direction: column;
                            padding-right: 0px;
                        }
                    }
                }
                .content-spacer {
                    flex-grow: 1;
                }
                .content-button {
                    height: 90px;
                    max-height: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                
                    a, &.learn-more span{
                        font-size: px2rem(16);
                        @include button();

                        @include general-breakpoint(smtablet) {
                            max-width: 280px;
                        }
                    }
                }
                .content-link-buttons {
                    border-top: 1px solid #C1C6C8;
                    height: 203px;
                    min-height: 203px; 
                    max-height: 203px; 
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    line-height: 20px;
                    padding: 0 20px 20px;

                    .link-button {
                        margin: 20px auto 0;
                        max-width: 100%;
                    }
                    a {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        text-transform: uppercase;
                        @include interstate();
                        font-size: 14px;
                        color: #3879BB;
                        &:hover {
                            text-decoration: none;
                            span {
                                text-decoration: underline;
                            }
                        }
                    }
                    i {
                        font-size: 30px;
                        margin-right: 6px;

                        &.icon-grounds-map {
                            margin-right: 2px;
                        }
                    }
                    .fanweek-tickets-link {
                        a {
                            @include interstate(bold);
                        }
                    }
                    .free-event {
                        @include interstate(bold);
                        font-size: px2rem(16);
                        color: $dk-gray2;

                        i {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        @include general-breakpoint(smtablet) {
            padding: 10px;
        }
    }
}

/** Fan Week Template Pages Styles **/
#uso-main {

    section.wrapper {
        .calendar-event-section {
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            @include general-breakpoint(smtablet) {
                width: 729px;
            }
            @include general-breakpoint(smdesktop) {
                width: 970px;
            }
            @include draws-breakpoint(sidepanel) {
                width: 1260px;
            }

            .calendar-event-header {
                width: 100%;
                min-height: 142px;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include interstate();
                background-color: $usoDkBlue;
                color: $white;

                &.blue {
                    background-color: $usoDkBlue;
                    color: $white;
                }
                &.gray {
                    background-color: $lt-gray4;
                    color: $dk-gray2;
                    border-bottom: 1px solid $usoLtGray;
                }
                .title {
                    font-size: px2rem(24);
                }
                .date-time {
                    text-transform: uppercase;
                    font-size: px2rem(14);
                }

                @include general-breakpoint(lgmobile) {
                    padding: 20px;
                }
                @include general-breakpoint(smtablet) {
                    .title {
                        font-size: px2rem(30);
                    }
                    .date-time {
                        font-size: px2rem(20);
                    }
                }
                @include general-breakpoint(smdesktop) {
                    .title {
                        font-size: px2rem(36);
                    }
                }
                @include draws-breakpoint(sidepanel) {
                    .title {
                        font-size: px2rem(40);
                    }
                    .date-time {
                        font-size: px2rem(20);
                    }
                }
            }
            .calendar-event-details {
                width: 100%;
                padding: 20px;
                @include interstate(light);
                background-color: $white;
                color: $dk-gray2;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .location {
                    font-size: px2rem(18);
                    margin-bottom: 30px;
                }
                .more-info {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .calendar-event-free {
                        i {
                          margin-right: 8px;  
                        }
                        .info-item-text {
                            font-size: px2rem(16);
                            @include interstate(bold);
                        }
                    }
                    .calendar-event-map {
                        color: $usoLtBlueBG;
                        @include interstate();
                        font-size: px2rem(16);

                        i {
                            font-size: 30px;
                            margin-right: 2px;
                        }
                        a {
                            display: flex;
                            align-items: center;
                            &:hover {
                                text-decoration: none;
                                span {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    .calendar-event-tickets {
                        margin-right: 20px;
                   
                        .calendar-event-btn {
                            @include button();
                            width: 116px;
                            border-color: none;

                            @include general-breakpoint(smtablet) {
                                width: 200px;
                            }
                        }
                    }
                }
                .description {
                    font-size: px2rem(14);
                }

                @include general-breakpoint(smtablet) {
                    .location {
                        font-size: px2rem(20);
                    }
                    .more-info {
                        justify-content: flex-start;
                        
                        .calendar-event-map, .calendar-event-free {
                            margin-right: 30px;
                        }
                    }
                    .description {
                        font-size: px2rem(16);
                    }
                }
                @include general-breakpoint(smdesktop) {
                    .description {
                        font-size: px2rem(18);
                    }
                }
            }
        }
    }
    section.wrapper {
        .wrapper-section {
            display: flex;
            flex-direction: column;
            background-color: $white;
            padding-bottom: 30px;
            margin-top: 30px;

            @include general-breakpoint(smtablet) {
                width: 729px;
                margin: 40px auto 0 !important;
            }

            @include general-breakpoint(smdesktop) {
                width: 970px;
                margin: 40px auto 0 !important;
            }

            @include draws-breakpoint(sidepanel) {
                width: 1260px;
            }

            .contentWrapper {
                padding-top: 20px;
                margin-top: 0;

                .title-bar {
                    border-bottom: none;
                    padding-bottom: 20px;
                    height: unset;

                   h3 {
                        font-size: px2rem(18px);
                    }
                }
                @include general-breakpoint(smtablet) {
                    width: 100%;
                }
            }
            .video {
                padding: 0 15px;

                @include general-breakpoint(smtablet) {
                    padding: 0 21px;
                    // margin: 40px auto 0 !important;
                }
    
                @include general-breakpoint(smdesktop) {
                    padding: 0 30px;
                    // margin: 40px auto 0 !important;
                }

                .playVideo {
                    position: relative;
                }
                img {
                    width: 100%;
                    aspect-ratio: 16 /9;
                }
                .playBtn {
                    left: calc(50% - 25px);
                    top: calc(50% - 25px);
                    margin-top: 0;
                    margin-left: 0;
                    width: 50px;
                    height: 50px;
                    background-size: 50px;

                    @include general-breakpoint(smtablet) {
                        left: calc(50% - 51px);
                        top: calc(50% - 51px);
                        width: 102px;
                        height: 102px;
                        background-size: 102px;
                    }
                }
            }
            .photo {
                padding: 0 15px;
                margin-top: 26px;

                img {
                    width: 100%;
                    aspect-ratio: 16 /9;
                }
                .caption { 
                    display: none;
                }
                a .credit, .credit {
                    margin-top: 10px;
                    @include interstate(light);
                    color: $dk-gray2;
                    font-size: px2rem(12);
                }
                @include general-breakpoint(smtablet) {
                    padding: 0 21px;
                }
                @include general-breakpoint(smdesktop) {
                    padding: 0 30px;
                }
            }
        }
    }    
    section.wrapper.fan-week-page {
        .premium-section {
            margin: 30px 0 0;
            &.overlay {
                .premium-content {
                    padding-top: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }
            @include general-breakpoint(smtablet) {
                width: 729px;
                margin: 40px auto 0 !important;
            }
            @include general-breakpoint(smdesktop) {
                width: 970px;
            }
            @include draws-breakpoint(sidepanel) {
                width: 1260px;
            }
            
            &.overlay {
                .premium-content {
                    .image-bg {
                        width: 100%;
                        height: 302px;

                        img {
                            width: 100%;
                            height: 100%;
                            display: none;
                        }
                        .content-container {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                        }
                        .caption {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: transparent;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: 30px 20px;

                            h2 {
                                color: $white;
                                text-transform: uppercase;
                                @include interstate(bold);
                                font-size: px2rem(20);
                            }
                            .text {
                                p {
                                    color: $white;
                                    @include interstate(light);
                                    font-size: px2rem(14);
                                    line-height: 20px;
                                    text-align: center;
                                }
                            }
                            a {
                                button {
                                    width: 224px;
                                    @include button();
                                    border: none;
                                    font-size: px2rem(16);
                                }
                                &:hover {
                                    text-decoration: none;
                                    color: #2478CC;
                                    
                                    button {
                                        border: 2px solid #2478CC;
                                    }
                                }
                            }

                        }
                    }
                }

                @include media-breakpoint-up(sm) {
                    .premium-content {
                        .image-bg {
                            .caption {
                                .text {
                                    width: 100%;
                                    max-width: 100%;
                                    margin-bottom: 20px;
                                    flex: unset;
                                }
                            }
                        }
                    }
                }

                @include general-breakpoint(landscape) {
                    max-width: 100%;
                    .premium-content {
                        .image-bg {
                            margin: 0;
                        }
                    }
                }

                @include general-breakpoint(smtablet) {
                    .premium-content {
                        .image-bg {
                            height: 267px;
                            margin: 0;

                            .caption {
                                flex-direction: row;
                                padding-top: 30px;
                                align-items: flex-start;
                                
                                h2 {
                                    flex: 0 0 40%;
                                    height: 100%;
                                    font-size: px2rem(38);
                                }
                                .content-container {
                                    flex: 0 0 60%;
                                }
                                .text {
                                    flex: 0 0 100%;
                                    max-width: 100%;

                                    p {
                                        text-align: left;
                                        padding-left: 0;
                                        font-size: px2rem(16);
                                    }
                                }
                                a {
                                    .uso-button {
                                        margin-left: 0;
                                        margin-top: 20px;
                                        width: 200px;
                                        float: none;
                                    }
                                }
                            }
                        }
                    }
                }
                @include general-breakpoint(smdesktop) {
                    .premium-content {
                        .image-bg {
                            .caption {
                                h2 {
                                    flex: 0 0 30%;
                                }
                                .content-container {
                                    flex: 0 0 70%;
                                }
                            }
                        }
                    }
                }
                @include draws-breakpoint(sidepanel) {
                    .premium-content {
                        .image-bg {
                            .caption {
                                h2 {
                                    flex: 0 0 23%;
                                }
                                .content-container {
                                    flex: 0 0 77%;
                                }
                            }
                        }
                    }
                }
            }
            &.right {
                .image {
                    a {
                        display: flex;
                        width: 35%;
                        margin: 20px auto 0;
                    }
                }
                .text {
                    padding: 20px;
                    padding-top: 0;
                    @include interstate();
                    font-size: px2rem(14);
                    text-align: center;
                }
                a {
                    .uso-button {
                        font-size: px2rem(16);
                    }
                }
                @include general-breakpoint(landscape) {
                    max-width: 100%;
                }
                @include general-breakpoint(smtablet) {
                    .image {
                        flex: 0 0 40%;
                        padding-left: 0;
                        padding-right: 0;

                        a {
                            width: 100%;
                        }
                    }
                    .text {
                        flex: 0 0 60%;
                        max-width: 60%;
                        text-align: left;
                        padding: 20px 0;
                        

                        .button-wrapper {
                            text-align: left;

                            .uso-button.wider {
                                width: 200px;
                            }
                        }
                    }
                }
                @include general-breakpoint(smdesktop) {
                    .image {
                        flex: 0 0 30%;

                        a {
                            width: 70%;
                            margin: 15px auto 0;
                        }
                    }
                    .text {
                        flex: 0 0 70%;
                    }
                }
                @include draws-breakpoint(sidepanel) {
                    .image {
                        flex: 0 0 23%;

                        a {
                            width: 78%;
                            margin: 10px auto 0;
                        }
                    }
                    .text {
                        flex: 0 0 77%;
                        max-width: 75%;
                    }
                }
            }
        }
        .calendar-section {
            margin: 30px 0 0;
            padding: 20px 20px 45px;
            background-color: $lt-gray4;
            position: relative;

            @include general-breakpoint(smtablet) {
                width: 729px;
                margin: 40px auto 0 !important;
            }
            @include general-breakpoint(smdesktop) {
                width: 970px;
            }
            @include draws-breakpoint(sidepanel) {
                width: 1260px;
            }

            /** slider style overide */
            .slick-track { display: flex !important; }  
            .slick-slide {
                margin: 0 10px;
                width: 283px !important;

                &:first-child {
                    margin-left: 0;
                }
                @include general-breakpoint(smtablet) {
                    width: 295px !important;
                }
                @include general-breakpoint(smdesktop) {
                    width: 400px !important;
                }
                @include draws-breakpoint(sidepanel) {
                    width: 525px !important;
                }
            }
            .slick-slide { 
                height: inherit !important; 
            }
            .slick-slide > div { 
                height: 100%; 
                width: 100%;
            }
            .slick-dots {
                bottom: -30px;
                margin: 0;

                li {
                    width: 10px;
                    height: 10px;

                    button {
                        width: 100%;
                        height: 100%;

                        &:before {
                            @include uso-icon('Icons');
                            content: '\e917';
                            color: $usoDkGray;
                            opacity: 1;
                        }
                    }
                    &.slick-active {
                        button {
                            &:before {
                                @include uso-icon('Icons');
                                content: '\e916';
                                color: $usoDkGray;
                            }
                        }
                    }
                }
            }
            .slick-next, .slick-prev {
                bottom: -32px;
                top: unset;
                transform: none;
                z-index: 1;

                &.slick-disabled {
                    cursor: default;
                }
            }
            .slick-prev {
                left: 0px;
            }
            .slick-next {
                right: 0px;
            }
            @include general-breakpoint(lgmobile, max) {
                .slick-next, .slick-prev {
                    display: none !important;
                }
            }
            @include general-breakpoint(smtablet) {
                .slick-prev {
                    left: 50px;
                }
                .slick-next {
                    right: 50px;
                }
            }
            @include general-breakpoint(smdesktop) {
                .slick-prev {
                    left: 100px;
                }
                .slick-next {
                    right: 100px;
                }
            }
            @include draws-breakpoint(sidepanel) {
                .slick-prev {
                    left: 200px;
                }
                .slick-next {
                    right: 200px;
                }
            }
            .slick-next:before {
                @include uso-icon('Icons');
                content:"\e902";
                color: $usoDkGray;
                font-weight: bolder;
                font-size: 14px;
            }
            .slick-prev:before {
                @include uso-icon('Icons');
                content:"\e901";
                color:$usoDkGray;
                font-weight: bolder;
                font-size: 14px;
            }
            .calendar-item-wrapper {
                height: 100%;
            
                .calendar-item {
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    text-align: left;
                    background-color: $white;
                    border: 1px solid $md-gray2;
                    width: 283px;
                    // height: 560px;
                    height: 100%;

                    @include general-breakpoint(smtablet){
                        flex-direction: column;
                        padding: 0;
                        width: 295px;
                        // height: 632px;
                    }
                    @include general-breakpoint(smdesktop) {
                        width: 400px;
                        // height: 629px;
                    }
                    @include draws-breakpoint(sidepanel) {
                        width: 525px;
                        // height: 674px;
                    }

                    .image{
                        width: 100%;

                        img{
                            width: 100%;
                            aspect-ratio: 4 / 3;
                        }

                        @include general-breakpoint(smtablet) {
                            // height: 188px;
                        }

                        @include general-breakpoint(smdesktop) {
                            // height: 166px;
                        }

                        @include draws-breakpoint(sidepanel) {
                            // height: 221px;
                        }
                    }

                    .content{
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        padding: 20px 15px;

                        &.info {
                            // height: 238px;
                            // max-height: 238px;
                            min-height: 100px;
                        }

                        @include general-breakpoint(tablet, max){
                            &.no-top-padding {
                                padding: 0 16px 20px;
                            }
                        }

                        .title{
                            @include interstate();
                            font-size: px2rem(18);
                            color: $usoDkGray;
                            padding-bottom: 13px;
                        }

                        .fanweek-date,
                        .location  {
                            @include interstate();
                            font-size: px2rem(14);
                            padding-bottom: 6px;
                        }

                        .location {
                            margin-top: 4px;
                        }

                        .time {
                            @include interstate(light);
                            padding-bottom: 4px;
                        }

                        .description{
                            @include interstate(light);
                            font-size: px2rem(14);
                            flex: 1;
                            padding-right: 18px;
                            margin-bottom: 20px;
                            // display: -webkit-box; 
                            // -webkit-box-orient: vertical; 
                            // -webkit-line-clamp: 3; 
                            // overflow: hidden; 
                            // text-overflow: ellipsis;

                            ul {
                                margin-left: 0;
                                padding-inline-start: 30px;
                            }
                            p:first-child {
                                padding-top: 0;
                            }

                            @include general-breakpoint(tablet, max){
                                flex-direction: column;
                                padding-right: 0px;
                            }
                        }
                        .link-button {
                            margin-top: 10px;
                            max-width: 90%;
                        
                            a {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                @include interstate();
                                font-size: 14px;
                                color: #3879BB;
                            }
                            i {
                                font-size: 30px;
                                margin-right: 6px;
                            
                                &.icon-grounds-map {
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }

        section.contentWrapper + section.calendar-section {
            margin-top: 0 !important;
        }
    }
}

/** FanWeekHighlights */
.fanweek-highlights {
    &-wrapper {
        background-color: $white;
        @extend .wrapper;
        margin: 20px auto;

        h3.title {
            @include interstate(regular);
            border-bottom: solid 1px $usoLtGray;
            color: $usoDkGray;
            font-size: px2rem(18);
            text-transform: uppercase;
            padding: 16px;
    
            @include general-breakpoint(smdesktop) {
                padding: 20px;
            }
        }
    } // -wrapper

    &-content {
        padding: 18px;

        .fanweek{
            &.section{
                display: flex;
                background-color: white;
                text-align: left;
                flex-direction: column;
                padding-right: 18px;
                margin-bottom: 32px;
                overflow-x: hidden;

                .image{
                        margin-right: 0px;
                        width: 100%;
                        overflow: hidden;
                    }

                    img{
                            width: 100%;
                    }
                    .color-bg {
                        background-color: $usoDkBlue;
                        color: $white;

                        .content {
                            background-color: $usoDkBlue;
                            height: auto;
                        }

                        .title {
                            @include interstate(bold);
                            font-size: px2rem(16);
                            color: $white !important;
                        }
                    }

                .content{
                    display: flex;
                    flex-direction: column;
                    // flex: 1 0 auto;
                    background-color: $usoBkgGray;
                    padding: 14px;

                    &.fixed-height {
                        min-height: 300px;
                        // overflow-y: scroll;
                    }

                    .title{
                        @include interstate(bold);
                        font-size: px2rem(16);
                        color: $white;
                        padding-bottom: 12px;
                    }

                    .fanweek-date,
                    .location  {
                        @include interstate(regular);
                        font-size: px2rem(14);
                    }

                    .time {
                        @include interstate(light);
                    }

                    .description{
                        color: $usoDkGray;
                        // display:flex;
                        // flex: 1 0 auto;
                        // flex-direction: column;
                        // min-height: 210px;
                        padding-right: 0px;

                        p {
                            @include interstate(light);
                            font-size: px2rem(14);
                            line-height: px2rem(18);

                            &:first-child {
                                padding-top: 0;
                            }
                        }

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                    
                    .button a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-height: 40px;
                        font-size: px2rem(16);
                        background: $usoLtBlueBG;
                        color: $white;
                        @include interstate(regular);
                        text-transform: uppercase;
                        text-align: center;
                        margin-top: 10px;
                        cursor: pointer;
                        border-radius: 8px;
                        border: 2px solid transparent;

                        // @include general-breakpoint(smtablet) {
                        //     max-width: 280px;
                        // }
                        &:hover{
                            background-color: #fff;
                            color: #418FDE !important;
                            border: 2px solid $usoLtBlueBG;
                            text-decoration: none;
                          }
                    }
                    .time {
                        margin-top: 10px;
                    }
                }
            }
        }
        
        /** slider style overide */
        .slick-track { display: flex !important; }  
        .slick-slide { height: inherit !important; 	
            display: flex !important;
            justify-content: center;
            align-items: center;
         }
        .slick-slide > div { 
            height: 100%; 
            width: 100%;
        }
        .slick-dots {
            bottom: -10px;
            margin: 0;

            li {
                button {
                    &:before {
                        @include uso-icon('Icons');
                        content: '\e917';
                        color: $usoDkGray;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            @include uso-icon('Icons');
                            content: '\e916';
                            color: $usoDkGray;
                        }
                    }
                }
            }
        }
        .slick-next, .slick-prev {
            bottom: -5px;
            top: unset;
            transform: none;
            z-index: 1;

            &.slick-disabled {
                cursor: default;
            }
        }
        .slick-prev {
            left: 20px;
        }
        .slick-next {
            right: 20px;
        }
        .slick-next:before {
            @include uso-icon('Icons');
            content:"\e902";
            color: $usoDkGray;
            font-weight: bolder;
            font-size: 14px;
        }
        .slick-prev:before {
            @include uso-icon('Icons');
            content:"\e901";
            color:$usoDkGray;
            font-weight: bolder;
            font-size: 14px;
        }
    }
} //fanweek-highlights

/** created for 2024 fan-week section **/
.fan-week {
    .hero_container {
        .hero_content {
            .hero_buttons {
                .hero_button {
                    margin-left: auto;
                    margin-right: auto;

                    a {
                        @include button(yellow);
                        border: none;
                        height: 48px;
                        @include interstate(bold);
                        margin: 0 auto;
                    }
                    .navItem {
                        .arrow-icon {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.fanweek-schedule-modal {
    button.session-btn {@include button(basic)}
  
    .modal-overlay-div {
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 40, 140, .9);
    }
    .modal-content-div {
        overflow: hidden;
    }
    .modal-dialog-div {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        position: relative;
        top: 80px;
        width: calc(100vw - 30px);
        max-width: 345px;
        margin: 0px auto;

        @include general-breakpoint(smdesktop) {
            max-width: 539px;
        }
    }
    .modal-content-wrapper {
        .fanweek-section {
            background-color: white;
            text-align: left;
        }
        .image{
            width: 100%;
            height: 193px;

            img{
                width: 100%;
                aspect-ratio: 16 / 9;
            }

            @include general-breakpoint(smtablet) {
                height: 188px;
            }

            @include general-breakpoint(smdesktop) {
                height: 166px;
            }

            @include draws-breakpoint(sidepanel) {
                height: 221px;
            }
        }
        .content-container {
            border: 1px solid #C1C6C8;
            border-top: none;
        }
        .content{
            display: flex;
            flex-direction: column;
            padding: 10px 20px;
            
            @include general-breakpoint(tablet, max){
                &.no-top-padding {
                    padding: 0 16px 20px;
                }
            }

            .title{
                @include interstate(bold);
                font-size: px2rem(18);
                color: $usoDkGray;
                padding-bottom: 18px;
            }

            .fanweek-date,
            .location  {
                @include interstate(regular);
                font-size: px2rem(14);
                padding-bottom: 6px;
            }

            .location {
                @include interstate(bold);
                text-transform: uppercase;
            }

            .time {
                @include interstate(light);
                padding-bottom: 4px;
            }

            .description{
                @include interstate(light);
                font-size: px2rem(14);
                flex: 1;
                padding-right: 18px; 

                p:first-child {
                    padding-top: 8px;
                }

                @include general-breakpoint(tablet, max){
                    flex-direction: column;
                    padding-right: 0px;
                }
            }
        }
    
        .modal-btn-wrapper {
            display:flex;
            flex-direction: row;
            gap: 20px;
            justify-content: center;
            margin:0px auto;
            bottom: 16px;
            width: 100%;
            
            button.session-btn {
                width: 171px;
            }
        }
    }
    .close-icon {
        font-size: 15px;
        color: white;
        line-height: 15px;
        margin-bottom: 20px;
        text-align: right;

        .icon-close {
            margin-left: 0;
            right: 0;
            top: -8px;
            font-size: 16px;
        }
    }
  }
