$black: #000;
$gray: #b9b9b9;
$gray-light: #d8d8d8;
$gray-lighter: #f2f2f2;
$gray-dark: #818a8f;
$gray-darker: #747678;
$gray-darkest: #2d2e34;
$dark-blue: #21282d;
$white: #fff;
$dark-pink: #722040;
$red: #d3394c;
$light-greyish-red: #f1e5e6;

// colors
$blue: #365583;
$blue2 : #2b4872;
$cyan: #3b8ede;
$dark-blue: #0c223f;
$dark-blue2: #243e61;
$light-blue: #4a6690;
$light-blue2: #405e89;
$bright-blue: #418FDE;
$red: #ca122a;
$red_live: #C8102E;
$grey: #858a83;
$light-grey: #ebebeb;
$light-grey2: #8091aa;
$gray: #858a83;
$lt-gray: #f3f3f3;
$lt-gray2: #f3f3f3;
$lt-gray3: #f3f3f3;
$neon-yellow: #cedf00;
$pale-yellow: #a6a496;

//2018
$lt-gray: #F0F2F3;
$lt-gray2: #F3F3F3;
$lt-gray3: #d8d8d8;
$lt-gray4: #F8F8F8;
$md-gray: #888B8D;
$md-gray2: #C1C6C8;
$md-gray3: #9b9b9b;
$md-gray4: #bbbbbb;
$md-gray5: #979797;
$dk-gray: #D5D8DA;
$dk-gray2: #4A4A4A;
$dk-gray3: #666666;
$gray_d8: #d8d8d8;
$gray-4a: #4a4a4a;
$gray-f3: #f3f3f3;
$gray-d8: #d8d8d8;
$gray-8: #888b8d;

$dark-blue3: #00288C;
$uso-lt-blue: #4FA2E5;

$borde-color: #6a89b9;
$button-dark-color: #223a5b;
$button-link-color: #a7cbff;
$button-yellow: #ffd200;


$text-color: $dk-gray2;
$date-selected-color: #3b5273;
$selected-color: #ffff00;

$status-color: #cedf00;

$trans-black: rgba(0, 0, 0, .6); 

$scores-check-color: #6CC24A;
$scores-tennisball-color: $md-gray2;

$select-menu-text: $dk-gray2;
$select-menu-border: $dk-gray2;

$table-light-grey: #fafafa;
$table-med-grey: #f4f3f3;
$table-dark-grey: #d8d8d8;
$table-border-grey: #e5e5e5;

$powerrank-purple:#54008B;
$powerrank-bar:#54008B; //#B5DD5A;
$powerrank-bar-bg: #FFFFFF;
$powerrank-text: #333333;
$powerrank-movement-up: #22D707;
$powerrank-movement-down:#D00000;
$powerrank-table-border:#ECECEC;
$powerrank-divider: #BBBBBB;
$likelihoodwin-purple: #B164FF;
$likelihoodwin-caret-left: #1664FF;
$likelihoodwin-caret-center: #5268FF;
$likelihoodwin-caret-right: #996DFF;

$watson-bg-default: #5D69FF;
$watson-bg-start: #A56EFF;
$watson-bg-end: #0062FF;

$wf-icon-color: #746bff;




//uso 2022 colors
$usoDkerBlue2: #001D67;
$usoDkerBlue: #2C4899;
$usoDkBlue: #00288C;
$usoLtBlue: #418FDE;
$usoLtBlueBG: #2478CC;
$usoGreen: #6CC24A;
$usoYellow: #FFD400;
$usoOrange: #E87722;
$usoRed: #C8102E;
$usoDkGray: #4a4a4a;
$usoMdGray: #888b8d;
$usoLtGray: #C1C6C8;
$usoBkgGray: #F3F3F3;
$usoltMedGray: #E0E2E3;
$favorite-background-color: #FFF4BF; 
$favorite-background-color-light: #fffbe8;
$favorite-border-bottom: #ffeb8d;
$live-color: #D0021B;
$draws-highlight-color: #D3E4F5;


/** AI Draws */
$toggle-button-bg-color-on: #cde0f4;
$toggle-button-on-border: $usoLtBlueBG;
$toggle-button-bg-color-off: #F1F1F1;
$toggle-button-off-border: $md-gray2;

$favorable: #3F8623;
$difficult: #C8102E;
$neutral: #B75B15;

$draw-analysis-bar-bg: $usoltMedGray;
$favorable-bg:#DFE9D8;
$neutral-bg: #FBD39C;
$difficult-bg: #F9B8B4;
$postmatch-bg: #cbcec6;

$favorable-stroke: $usoltMedGray;
$difficult-stroke: $usoltMedGray;
$neutral-stroke: $usoltMedGray;
$postmatch-stroke: $usoltMedGray;

$datatab-primary-bg: $usoDkerBlue2;
$datatab-secondary-bg: $usoDkBlue;