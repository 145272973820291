// Grid
$grid-columns: 12; // Set number of columns in the grid

$xs-max: 640px; // Set xs breakpoint's max width
$sm-max: 991px; // Set sm breakpoint's max width
$md-max: 1024px; // Set md breakpoint's max width
$lg-max: 1500px; // Set lg breakpoint's max width

$gutter: 0;

$sm-start: ($xs-max + 1); // Set sm breakpoint's min width
$md-start: ($sm-max + 1); // Set md breakpoint's min width
$lg-start: ($md-max + 1); // Set lg breakpoint's min width
$xl-start: ($lg-max + 1); // Set xl breakpoint's min width

$content-well-max-width: "none"; // Set the max-width of the content well

// Breakpoints
// Create breakpoint range statements to be used in media queries
$breakpoint-xs-up: "only screen";
$breakpoint-xs-only: "only screen and (max-width: #{$xs-max})"; // 0 -> xs-max range
$breakpoint-sm-up: "only screen and (min-width: #{$sm-start})"; // sm-start -> up range
$breakpoint-sm-only: "only screen and (min-width: #{$sm-start}) and (max-width: #{$sm-max})"; // sm-start -> sm-max range
$breakpoint-md-up: "only screen and (min-width: #{$md-start})"; // md-start -> up range
$breakpoint-md-only: "only screen and (min-width: #{$md-start}) and (max-width: #{$md-max})"; // md-start -> md-max range
$breakpoint-lg-up: "only screen and (min-width: #{$lg-start})"; // lg-start -> up range
$breakpoint-lg-only: "only screen and (min-width: #{$lg-start}) and (max-width: #{$lg-max})"; // lg-start -> lg-max range
$breakpoint-xl-up: "only screen and (min-width: #{$xl-start})"; // xl-start -> up range

$breakpoint-sm-down: "only screen and (max-width: #{$sm-max})"; // sm -> down
$breakpoint-md-down: "only screen and (max-width: #{$md-max})"; // md -> down
$breakpoint-lg-down: "only screen and (max-width: #{$lg-max})"; // lg -> down

$breakpoints-all: ($breakpoint-xs-only, $breakpoint-sm-up, $breakpoint-sm-only, $breakpoint-md-up, $breakpoint-md-only, $breakpoint-lg-up, $breakpoint-lg-only, $breakpoint-xl-up);
$breakpoint-ups: ($breakpoint-sm-up, $breakpoint-md-up, $breakpoint-lg-up, $breakpoint-xl-up);
$breakpoint-all-prefixes: ("xs", "xs-only", "sm", "sm-only", "md", "md-only", "lg", "lg-only", "xl");
$breakpoint-up-prefixes: ("xs", "sm", "md", "lg", "xl");

$breakpoints: (
    "xs": $breakpoint-xs-up,
    "xs-only": $breakpoint-xs-only,
    "sm": $breakpoint-sm-up,
    "sm-only": $breakpoint-sm-only,
    "sm-down": $breakpoint-sm-down,
    "md": $breakpoint-md-up,
    "md-only": $breakpoint-md-only,
    "md-down": $breakpoint-md-down,
    "lg": $breakpoint-lg-up,
    "lg-only": $breakpoint-lg-only,
    "lg-down": $breakpoint-lg-down,
    "xl": $breakpoint-xl-up
);
