.page-submenu-wrapper {
	background-color: $usoDkerBlue2;
	width: 100%; /*height: 108px; margin-top: 20px;*/
	padding-top: 10px; /*margin-top: 10px;*/

	.page-submenu {
		display: flex;
		flex-direction: row;
		gap: 5px;
		justify-content: center;
		align-content: baseline;
		// min-height: 40px;
		padding-top: 10px;
		@include general-breakpoint(desktop) {
			white-space: nowrap;
		}
		.item {
			color: $white;
			// padding: 0px 10px 10px;
			line-height: 16px;
			font-size: 14px;
			@include interstate(light);

			&::after {
				content: attr(data-text);
				content: attr(data-text) / '';
				height: 0;
				display: block;
				visibility: hidden;
				overflow: hidden;
				user-select: none;
				pointer-events: none;
				font-weight: 900;
			}

			&:hover {
				@include interstate(bold);
				cursor: pointer;
				color: $white;
			}
			&.selected {
				text-decoration: underline;
				text-underline-offset: 21px;
				text-decoration-thickness: 3px;
				// border-bottom: 3px solid $white;
				@include interstate(bold);
				cursor: pointer;
				color: $white;

				&.unclickable {
					cursor: default;
				}
			}
			a {
				text-decoration: none;
				color: inherit;
			}
		}
		.events-wrapper {
			width: 80%;
			display: flex;
			flex-direction: row;
			gap: 5px;
			justify-content: center;
			align-content: baseline;
			&.mobile {
				width: 35%;
				// margin-left: 15px;
				justify-content: left;
				color: $white;
				// padding: 0px 10px 10px;
				line-height: 16px;
				font-size: 14px;
				@include interstate(light);
			}
			&.selected {
				text-decoration: underline;
				text-underline-offset: 24px;
				text-decoration-thickness: 3px;
				// border-bottom: 3px solid $white;
				@include interstate(bold);
				cursor: pointer;
				color: $white;
			}
		}
		.dropdown-wrapper {
			width: 20%;
			&.mobile {
				width: 65%;
				// justify-content: right;
				text-align: right;
				// padding-right: 15px;
			}
			.scores-select-menu {
				padding: 0px;
				width: 100%;
				.select-menu .title {
					background-color: $usoDkBlue;
					color: $white;
					font-weight: normal;
				}
			}
		}
	}
	.submenu-details-wrapper {
		border-top: 1px solid $md-gray;
		background-color: $usoDkBlue;
		width: 100%;
		// padding-top: 10px;
		padding: 23px 16px;
		.details {
			display: flex;
			flex-direction: row;
			justify-content: center;
			padding: 0px 20px;
			align-items: center;
			.scores-sub {
				display: flex;
				.scores-sub-item {
					color: #fff;
					line-height: 16px;
					font-size: 14px;
					margin: 0 20px;
					@include interstate(light);
					&.selected {
						text-decoration: underline;
						text-underline-offset: 23px;
						text-decoration-thickness: 3px;
						cursor: pointer;
						@include interstate(bold);
					}
					&::after {
						content: attr(data-text);
						content: attr(data-text) / '';
						height: 0;
						display: block;
						visibility: hidden;
						overflow: hidden;
						user-select: none;
						pointer-events: none;
						font-weight: 700;
					}
					&:hover {
						@include interstate(bold);
					}
					a {
						color: #fff;
						text-decoration: none;
					}
				}
			}
			.favorites-toggle {
				min-width: 110px;
				@include general-breakpoint(smtablet) {
					width: 15%;
				}
				position: absolute;
				left: 10px;
				margin-bottom: 10px;
			}
			.dates,
			.shows {
				width: 40%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-evenly;
				color: $white;
				@include interstate(light);
				font-size: 12px;
				line-height: 18px;
				align-items: flex-end;
				white-space: nowrap;
			}
			a {
				color: $white;
				&:hover {
					color: $white;
					text-decoration: none;
				}
			}
			.days,
			.item,
			.all {
				text-align: center;
				// padding-bottom: 10px;
				&::after {
					content: attr(data-text);
					content: attr(data-text) / '';
					height: 0;
					display: block;
					visibility: hidden;
					overflow: hidden;
					user-select: none;
					pointer-events: none;
					font-weight: 700;
				}

				&:hover {
					// cursor: pointer;
					@include interstate(bold);
				}
				.label {
					padding-top: 3px;
					text-transform: uppercase;
				}
			}

			.all {
				&:hover {
					cursor: pointer;
				}
			}

			.selected {
				text-decoration: underline;
				text-underline-offset: 25px;
				text-decoration-thickness: 3px;
				@include interstate(bold);
				// line-height: 18px;
				// border-bottom: 3px solid $white;
			}
			.icons {
				width: 15%;
				color: $white;
				font-size: 24px;
				text-align: right;
				position: absolute;
				right: 0px;
				padding-right: 10px;
				i {
					padding: 0px 10px;
				}
				a {
					color: $white;
					&:hover {
						color: $white;
						text-decoration: none;
						cursor: pointer;
					}
				}
			}
			.tv {
				color: $white;
				@include interstate(light);
				width: 60%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-evenly;
				font-size: 12px;
				// line-height: 15px;
				align-items: flex-end;
				a {
					color: $white;
					&:hover {
						color: $white;
						text-decoration: none;
						cursor: pointer;
					}
				}
				.area {
					text-transform: capitalize;
					text-align: center;
					// padding: 0px 10px;
					// padding-bottom: 15px;
				}
				.selected {
					@include interstate(bold);
					// line-height: 18px;
					// border-bottom: 3px solid $white;
				}
			}
			&.mobile {
				display: flex;
				position: relative;
				.mobile-favorites {
					position: absolute;
					top: -6px;
					left: 6px;
					width: 100%;
				}
				.mobile-submenu {
					display: flex;
					justify-content: center;
					// margin-top: 45px;
					width: 100%;
				}
				.favorites-toggle,
				.icons {
					width: 50%;
					display: inline;
					float: left;
				}
				.icons {
					width: 50%;
					display: inline;
					float: right;
				}
				.dates,
				.shows {
					width: 100%;
				}
				.draws {
					.round-wrapper {
						.item {
							.short {
								padding: 0px 5px;
							}
						}
					}
				}
			}
			.draws {
				color: $usoLtGray;
				@include interstate(light);
				text-transform: uppercase;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 0px;
				height: 100%;
				line-height: 1.6em;
				.bracket-wrapper,
				.round-wrapper {
					display: flex;
					flex-direction: row;
					text-align: center;
					font-size: px2rem(12);
					height: 100%;
				}
				.item {
					padding: 0px;
					margin: 0px 5px;
					cursor: pointer;
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 100%;
					&:hover {
						color: $white;
						@include interstate(bold);
						border-bottom: solid 0px $white;
					}
					&.selected {
						color: $white;
						@include interstate(bold);
						border-bottom: solid 3px $white;
						text-decoration: none;
						cursor: default;
						align-self: flex-end;
						height: calc(100% - 3px);
					}
					@include general-breakpoint(landscape) {
						margin: 0px 10px;
					}
				}

				.bracket-wrapper {
					.item {
						width: 60px;
						display: flex;
						flex-direction: row;
						align-items: center;
					}
				}
				.separator {
					width: 1px;
					height: calc(100% - 30px);
					border-left: dashed 1px $white;
				}
				.round-wrapper {
					.item {
						width: auto;
						font-size: px2rem(18);
						.short {
							padding: 0px 15px;
						}
						@include general-breakpoint(smtablet) {
							.short {
								padding: 0px 5px;
							}
						}
					}

					.item-prefix {
						font-size: px2rem(12);
					}
				}
			}
		}
	}

	.page-submenu-filter-wrapper {
		width: 100%;
		display: block;
		.filter-details,
		.favorites-details {
			height: 112px;
			padding: 5px 10px;
			// border-top: 20px solid $usoBkgGray;
		}
		.filter-details {
			background-color: $usoBkgGray;
		}
		.favorites-details {
			background-color: $white;
			border-bottom: 1px solid $md-gray2;
			padding: 0px;

			.no-players {
				@include interstate();
				display: block;
				text-align: center;
				line-height: 48px;
			}

			@include general-breakpoint(smtablet, max) {
				height: 115px;

				.no-players {
					line-height: 115px;
				}
			}

			&.sticky {
				border-top: 1px solid $md-gray2;
				position: fixed;
				top: 56px;
				left: 0;
				width: 100%;
				z-index: 100;

				@include general-breakpoint(smtablet, max) {
					top: 56px;
				}
			}
		}
	}

	&.draws-mode {
		padding-top: 0px;
		.page-submenu-wrapper {
			max-height: 40px;
			align-items: center;
		}
		.blue-bar-wrapper {
			height: 63px;
		}
		.events-wrapper {
			width: 100%;
			// margin-right: 20px;
			// width: 84px;
			@include interstate();
			@media screen and (min-width: 425px) and (max-width: 768px) {
				width: 76%;
			}
			@media screen and (min-width: 769px) {
				width: 100%;
			}
			&.mobile {
				display: none;
				@include general-breakpoint(landscape) {
					display: flex;
				}
			}
		}
		.dropdown-wrapper {
			width: auto;
			margin-left: 20px;
			padding-bottom: 10px;
			&.mobile {
				padding-bottom: 10px;
			}
		}

		.submenu-details-wrapper {
			padding: 0;
			.details {
				height: 68px;
				justify-content: center;
				padding: 0px;
				width: 100%;
				&.mobile {
					display: flex;
					flex-direction: column;
					height: 100px;
					.mobile-favorites {
						display: flex;
						justify-content: space-between;
						margin-top: 10px;
						width: 100%;
						align-items: center;
						position: relative;
						left: 0px;
					}
					.favorites-toggle {
						width: auto;
						margin-bottom: 0px;
					}
					.icons {
						width: auto;
					}
					.mobile-submenu {
						margin-top: 0px;
					}
				}
				.favorites-toggle {
					position: relative;
					width: 25%;
				}
				.icons {
					position: relative;
					right: auto;
					padding-right: 0px;
					width: 25%;
				}
			}
		}
	}

	// &.scores-mode {
	// 	//margin-top:0px;
	// }
}

.schedule-mode {
	padding-top: 0px;
	@include interstate();
	.page-submenu {
		padding: 23px 11px 21px 2rem;
		align-items: center;
		.events-wrapper.mobile.selected {
			text-underline-offset: 26px;
		}
		.dropdown-wrapper.mobile {
			width: 58%;
		}
		.drawsPdf {
			@media screen and (max-width: 500px) {
				position: absolute;
				right: 0px;
			}
		}
	}
	.submenu-details-wrapper {
		background-color: $usoDkBlue !important;
	}
	.daynav-wrapper {
		.tournament-day-dropdown-wrapper {
			padding: 10px;
		}
	}
}

/** override using higher structure */
.history,
.us_open_podcast,
// .videolanding,
.us_open_at_home {
	.page-submenu-wrapper .details {
		min-height: 13px;
	}
	.page-submenu-wrapper .details.mobile {
		.selected-location {
			display: none;
		}
	}
}

.photosPage,
.newsindex,
.latest {
	.page-submenu-wrapper .details,
	.page-submenu-wrapper .details.mobile {
		/** page has its own content and height, hide the element coming from PageSubMenu */
		display: none;
	}
}

.news-page-mobile-dropdown-wrapper select,
.schedule-page-mobile-dropdown-wrapper select {
	appearance: none;
	position: relative;
	width: 145px;
	height: 25px;
	// padding: 6px 16px 6px 4px;
	background-color: transparent;
	border-radius: 4px;
	color: #fff;
	font-size: 1rem;
}

// .schedule-page-mobile-dropdown-wrapper select {
// 	width: 142px;
// }

.news-dropdown-menu,
.schedule-dropdown-menu {
	text-align: center;
	border-radius: 3px;
	color: transparent;
	border-color: #fff;
	margin-top: -4px;
	text-align-last: center;
}

.news-menu-arrow {
	position: absolute;
	font-size: 18px;
	line-height: 10px;
	display: inline-block;
	position: relative;
	top: 0px;
	right: 20px;
	&:after {
		content: '\25BE';
	}
	color: #fff;
}

.newsWithFilter {
	&.submenu-details-wrapper {
		display: none;
	}
}

.news-mode {
	@include interstate();
	.newsWithoutFilter {
		&.submenu-details-wrapper {
			min-height: 60px;
			padding: 0;
		}
	}
}

.visit-mode {
	.blue-bar-menu-wrapper,
	.light-blue-bar-wrapper {
		@include interstate();
	}

	.light-blue-bar-wrapper {
		.visit-select-dropdown-wrapper {
			.visit-select-menu {
				width: 220px;
			}
		}
	}
	.submenu-details-wrapper {
		display: none;
	}
	.lb-event-name {
		&.active,
		&.hover,
		&::after {
			@include interstate(bold);
		}
	}
	padding-top: 0px !important;
}

.fan-week-mode {
	.submenu-details-wrapper {
		display: none;
	}
}

.scores-mode {
	padding-top: 0px;
	.blue-bar-wrapper {
		@include interstate();
		border-top: 1px solid #888b8d;
		height: 60px;
		.event-name {
			font-size: 12px;
			&.active {
				text-underline-offset: 22px; //21px
				@include general-breakpoint(smtablet) {
					text-underline-offset: 21px;
				}
			}
		}
	}
}

.players-mode {
	.blue-bar-wrapper {
		@include interstate();
		font-size: 12px;
		border-top: 1px solid #888b8d;
	}
}

.watch-mode {
	.light-blue-bar-wrapper {
		.visit-select-dropdown-wrapper {
			@include general-breakpoint(smtablet, max) {
				width: 340px;
				margin-left: auto;
				margin-right: auto;

				.visit-select-menu {
					width: 100%;
				}
			}			
		}
	}
}

.watch-mode,
.fan-week-mode {
	@include interstate();
	.watch-blue-bar-wrapper {
		display: flex;
		height: 60px;
		align-items: center;
		justify-content: space-around;

		.watch-blue-bar-dropdown-selected {
			height: 25px;
			background-color: $usoDkBlue;
			color: $white;
			border-radius: 4px;
			text-align: center;
			text-indent: unset;
			width: 145px;
			text-align-last: center;
		}
	}
	.pagesub-favorites {
		display: none;
	}
}
