/** schedule styles **/
#schedule {
	.column-layout {
		background: $usoBkgGray;
		@include general-breakpoint(smdesktop, max) {
			padding: 0px;
		}
	}
	.content-main {
		@include interstate;
	}
	.schedule-clock {
		//width: 250px;
		float: right;
		display: none;
		height: 100%;
		overflow: hidden;
	}
	.drawsPdf {
		text-decoration: none;
		// display: none;
		margin-right: 0;

		&:hover {
			color: $white;
		}
		@include general-breakpoint(smtablet) {
			position: absolute;
			display: block;
			right: 20px;
		}
	}
	.print {
		//position:absolute;
		//top:0px;
		//left:0px;
		color: $white;
		font-size: 24px;
		display: none;
		cursor: pointer;
		z-index: 1;
	}
	.cal-widget {
		text-align: center;
	}
	.sponsor {
		display: none;
		@include general-breakpoint(smtablet) {
			display: block;
			background-color: $usoBkgGray;
			padding: 15px 10px;
			width: 45%;
		}
		@include general-breakpoint(desktop) {
			width: 25%;
		}
	}
	.future-tickets-schedule {
		background-color: $white;
		border: 1px solid $usoLtGray;
	}
	.future-events {
		padding: 20px;
		.future-events-wrapper {
			width: 100%;
			background-color: $white;
			.future-event {
				display: flex;
				padding: 10px 20px;
				border-top: 1px solid $usoLtGray;
				@include interstate(light);
				&:last-child {
					border-bottom: 1px solid $usoLtGray;
				}
				.start-time {
					margin-right: 78px;
					@include general-breakpoint(tablet, max) {
						margin-right: 30px;
						align-items: center;
						white-space: nowrap;
					}
				}
			}
		}
		.future-events-header {
			padding: 10px 20px;
			display: flex;
			@include interstate(bold);
			.time {
				margin-right: 100px;
				@include general-breakpoint(tablet, max) {
					margin-right: 46px;
				}
			}
		}
	}
	.schedule-info {
		text-transform: uppercase;
		color: $dk-gray2;
		text-align: left;
		height: auto;
		display: inline-block;
		// display: flex;
		// flex-direction: row;
		justify-content: center;
		padding: 10px 20px;
		background-color: $usoBkgGray;
		width: 100%;
		@include general-breakpoint(smtablet) {
			width: 55%;
		}
		@include general-breakpoint(desktop) {
			width: 75%;
		}
		.day {
			font-size: 18px;
			@include interstate(bold);
		}
		.revised {
			font-size: 14px;
			@include interstate;
		}
	}
	.schedule-comments {
		display: flex;
		flex-direction: column;
		padding: 20px;
		text-align: center;
		width: 100%;
		margin: 0 auto;
		@include interstate;
		font-size: 16px;
		line-height: 22px;
		.see-tickets {
			margin-top: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			.see-tickets-btn {
				text-transform: uppercase;
				@include interstate(bold);
				font-size: 12px;
			}
		}
	}
	.schedule-court {
		position: relative;
		display: inline-block;
		width: 100%;
		margin-bottom: 20px;

		.courtName {
			position: relative;
			color: #4a4a4a;
			text-align: center;
			vertical-align: middle;
			text-transform: uppercase;
			border-top: 1px solid #c1c6c8;
			border-bottom: 1px solid #c1c6c8;
			background: $white;
			font-size: 16px;
			line-height: 21px;
			display: inline-block;
			width: 100%;
			padding: 15px;
			//@include gotham_ssm_medium;
			//@include background-rgba(#FFFFFF,.5);
			&:hover {
				cursor: pointer;
			}
		}
		.schedule-content {
			display: inline-flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin: 0px auto;
			width: 100%;
			background: $usoBkgGray;
			padding: 0px 10px;
			.one-col {
				width: 100%;
				margin: 10px auto;
				@include general-breakpoint(smtablet) {
					width: 48%;
					margin: 10px 5px;
				}
				@include general-breakpoint(tablet) {
					width: 31%;
					margin: 10px;
				}
				.lazy-container {
					min-width: 1px;
					position: relative;
					min-height: 154px;
				}
				.match-box-container {
					.match-box {
						.match-status {
							&.empty {
								.event-round:before {
									content: '';
								}
							}
						}
					}
				}
			}

			.row {
				float: none;
				display: table;
				table-layout: fixed;
				min-width: 100%;
				width: auto;

				&.mobile {
					height: 24px;
					line-height: 24px;
					padding: 0px 10px;
					.scores {
						padding-left: 5px;
					}
				}
				&.teams {
					padding: 5px 10px;
					// padding-top:5px;
					text-align: left;
				}
			}
			ul {
				margin: 10px;
				li {
					margin-bottom: 20px;
				}
			}
		}
		.match {
			width: 100%;
			background-color: #ffffff;

			.header {
				background-color: #f3f3f3;
				color: #4a4a4a;
				//height: 32px;
				//line-height:32px;
				text-transform: uppercase;
				font-size: 12px;
				&.scores {
					display: none;
				}
			}
			.content {
				background-color: #ffffff;
				color: #4a4a4a;
				vertical-align: middle;
				font-size: 14px;
				padding: 0px 0px 0 5px;
				line-height: 20px;
				@include interstate(light);
				&.status {
					display: none;
				}
			}
			.schedule-player {
				padding-left: 10px;
				padding-right: 5px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				position: relative;
				&.favorite {
					background-color: $favorite-background-color;
					// color:$white;
					// a {color:$white;}
					&:before {
						content: '';
						background: transparent url(/assets/images/misc/icon-favorite-selected.png) no-repeat 0 11px;
						background-size: 10px;
						//display: none;
						height: 25px;
						left: -4px;
						top: -8px;
						position: absolute;
						width: 10px;
					}
				}
				&.search {
					border: 3px $usoLtBlue solid;
					animation: blink 1.5s;
					animation-iteration-count: 3;
				}
			}
			.match-info {
				padding: 10px 15px;
				text-align: left;
				//border-top:solid 1px #C1C6C8;
				//border-bottom:solid 1px #C1C6C8;

				.match-info-notBefore {
					font-style: italic;
					font-size: 10px;
					padding-bottom: 4px;
				}

				.event {
					margin: 5px 0px;
				}
				.scores {
					display: none;
				}
			}
			.scores {
				float: left;
				font-size: 12px;
				word-spacing: 5px;
				white-space: nowrap;
				text-align: right;
			}
			.status {
				float: right;
				font-size: 12px;
				@include interstate(light);
				padding-left: 5px;
				color: #418fde;
			}
			.schedule-team {
				padding-left: 0px;
				a {
					color: #4a4a4a;
					text-decoration: none;
				}

				a:hover {
					color: #418fde;
					text-decoration: underline;
				}

				.winner-of-holder {
					display: flex;
					flex-wrap: wrap;

					.winner-of {
						flex: 0 0 100%;
					}
					.winner-of1 {
						flex: 1 0 20px;
					}
					.winner-of2 {
						flex: 1 0 20px;
					}

					.winner-versus {
						flex: 0 0 50px;
					}
				}
			}
			.versus {
				height: 26px;
				line-height: 26px;
				@include interstate(light);
				font-style: italic;
				font-size: 12px;
			}
		}
	}
	.footer-comments {
		color: $dk-gray2;
		font-weight: bold;
		//padding-bottom: 15px;
		border: 1px solid #c1c6c8;
		width: 100%;

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		min-height: 35px;
		padding: 5px 0 5px 10px;
	}

	.schedule-cta-row {
		@include general-breakpoint(mobile) {
			display: flex;
			justify-content: space-around;
		}

		&.tablet,
		&.smdesktop,
		&.lgdesktop {
			display: none;
		}
	}

	.cta-button-wrapper {
		display: flex;

		&.lgdesktop {
			justify-content: flex-end;
		}
		&.smdesktop {
			justify-content: center;
		}
		&.tablet {
			justify-content: flex-end;
			align-items: center;
		}
	}

	.mi-cta-button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1px 0;
		margin: 0 auto 20px auto;
		width: 120px;
		height: 40px;
		line-height: 18px;
		border: 1px solid $bright-blue;
		background-color: $white;
		color: $bright-blue;
		@include interstate;
		cursor: pointer;
		font-size: 12px;
		text-transform: uppercase;

		a {
			color: $bright-blue;

			&:hover {
				color: $bright-blue !important;
			}
		}
	}

	.tickets-cta-button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1px 0;
		margin: 0 auto 20px auto;
		width: 120px;
		height: 40px;
		line-height: 18px;
		border: 1px solid $bright-blue;
		background-color: $bright-blue;
		color: $white;
		@include interstate;
		cursor: pointer;
		font-size: 12px;
		text-transform: uppercase;

		a {
			color: $white;

			&:hover {
				color: $white !important;
			}
		}
	}

	.highlights-cta-button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1px 0;
		margin: 0 auto 20px auto;
		width: 120px;
		height: 40px;
		line-height: 18px;
		color: $bright-blue;
		@include interstate;
		cursor: pointer;
		font-size: 16px;
		font-weight: 400 !important;

		&:before {
			content: url('/assets/images/misc/highlight_play_icon.svg');
			margin-top: 3px;
			margin-right: 3px;
		}

		a {
			color: $bright-blue;

			&:hover {
				color: $bright-blue;
				text-decoration: underline;
			}
		}
	}

	.schedule-buttons-cta {
		width: 100%;
		clear: both;
		padding-bottom: 10px !important;

		a {
			display: block;
			text-decoration: none;

			&:hover {
				color: $bright-blue;
			}
		}

		.schedule-buttons-row {
			display: flex;
			justify-content: space-between;
		}

		.highlights-cta-button {
			width: 120px;
			height: 40px;
			font-size: 12px;
			line-height: 18px;
			padding-top: 2px !important;
			margin: 10px auto 0 !important;
		}
		&.tablet,
		&.lgdesktop {
			display: none;
		}

		@include general-breakpoint(smtablet) {
			width: auto;
			padding-bottom: 0 !important;

			.tickets-cta-button {
				margin: 0 !important;
				position: absolute;
				right: 10px;
				top: -7px;
			}
			.highlights-cta-button {
				font-size: 16px;
				margin: 0 !important;
				position: absolute;
				right: 110px;
				top: -10px;
			}
			&.mobile,
			&.smdesktop,
			&.lgdesktop {
				display: none;
			}
			&.tablet {
				display: block;
			}
		}
		@include general-breakpoint(smdesktop) {
			float: none;
			display: table;
			table-layout: fixed;
			width: 100%;

			.tickets-cta-button {
				width: 150px;
				height: 37px;
				margin: 5px auto 24px !important;
				position: static;
				right: unset;
				top: unset;

				.bolder {
					display: inline;
				}
			}
			.highlights-cta-button {
				font-size: 16px;
				width: 120px;
				height: 40px;
				margin: 5px auto 24px !important;
				position: static;
				right: unset;
				top: unset;
			}
			&.tablet,
			&.lgdesktop {
				display: none;
			}
			&.smdesktop {
				display: table-row;
			}
		}
		@include general-breakpoint(desktop) {
			width: 15%;

			.tickets-cta-button {
				width: 150px;
				height: 37px;
				line-height: 15px;
				padding-top: 2px !important;
				margin: 0 !important;

				.bolder {
					display: block;
				}
			}
			.highlights-cta-button {
				font-size: 16px;
				width: 120px;
				height: 40px;
				line-height: 18px;
				padding-top: 2px !important;
				margin: 0 !important;
			}
			&.mobile,
			&.tablet,
			&.smdesktop {
				display: none;
			}
			&.lgdesktop {
				display: table-cell;
				vertical-align: middle;
			}
		}
	}

	&.webview {
		.schedule-court {
			.match {
				.status {
					&.content {
						&.mobile {
							float: none;
							display: flex;
							justify-content: flex-end;
							padding: 5px 10px 0 0;
						}
					}
				}
				.scores {
					&.header {
						padding-top: 0;

						@include general-breakpoint(smtablet) {
							display: block;
						}
					}
				}
			}
			.schedule-content {
				.row {
					&.teams,
					&.mobile {
						.schedule-mi-cta {
							display: none;
						}
					}
				}
				.button-row {
					display: flex;
					justify-content: center;
					align-items: center;

					.mi-cta-button {
						width: 110px;
						height: 35px;
						margin: 10px 5px;
					}

					.tickets-cta-button {
						width: 110px;
						height: 35px;
						margin: 10px 5px;
					}

					.highlights-cta-button {
						font-size: 12px;
						margin: 10px 5px;
					}
				}
			}
		}

		@include general-breakpoint(smtablet) {
			.schedule-court {
				.match {
					.status {
						&.content {
							display: table-cell;

							&.mobile {
								display: none;
							}
						}
					}

					.scores {
						&.header {
							display: table-cell;
						}
					}
				}
			}
		}
	}
}

@include general-breakpoint(mobile) {
	#schedule {
		.schedule-court {
			.match {
				.header {
					&.scores {
						display: block;
						background-color: $white;
						font-size: 12px;
						word-spacing: 5px;
						white-space: nowrap;
						text-align: left;
						padding: 10px 0 0 10px;
						font-weight: 700;
					}
				}

				.status {
					&.mobile {
						padding: 10px 10px 0 0;
					}
				}

				.schedule-player {
					padding-left: 0;
				}

				.highlights-wrapper {
					position: absolute;
					display: block;
					top: 120px;
					right: 0;

					.highlights-cta-button {
						font-size: 12px;
						width: 110px;
					}
				}
			}

			.schedule-content {
				.row {
					&.mobile {
						display: none;
					}

					&.smdesktop {
						.highlights-cta-button {
							display: none;
						}
					}
				}

				.cta-button-wrapper {
					div {
						margin: 10px 5px;
					}
				}
			}
		}
	}
}

@include general-breakpoint(smtablet) {
	//#schedule .schedule-court .match .schedule-player.favorite:before
	#schedule {
		//h1.header {height:100px; line-height: 100px; position:relative;}
		.print {
			display: block;
		}
		.schedule-court {
			.match {
				position: relative;
				.scores {
					display: none;

					&.header {
						display: none;
					}
				}
				.status {
					&.mobile {
						display: none;
					}
				}
				.match-info {
					.scores {
						float: right;
						display: block;
						font-size: 16px;
					}
					&.header {
						font-size: 16px;
					}
				}
				.status {
					font-size: 16px;
				}
				.versus {
					font-size: 14px;
				}
				.name,
				.nation {
					font-size: 16px;
				}

				.highlights-wrapper {
					display: none;
				}
			}
			.courtName {
				font-size: 18px;
			}
			.schedule-content {
				padding: unset;
				.row {
					&.mobile {
						display: block;
						position: absolute;
						right: 0px;
						top: calc(50% + 4px);
					}
					&.smdesktop {
						padding: 0;
					}
				}

				.cta-button-wrapper {
					&.smdesktop {
						display: none;
					}

					.mi-cta-button {
						margin-right: 20px;
					}

					.highlights-cta-button {
						display: flex;
					}

					div {
						margin: 0;
					}
				}
			}
		}
	}
}

@include general-breakpoint(smdesktop) {
	#schedule {
		//width: 90%;
		// margin: 0px auto;

		#scheduleDropdown {
			display: none;
		}
		#scheduleNav {
			display: block;
		}

		.schedule-clock {
			position: absolute;
			top: 0px;
			right: 0px;
			display: block;
		}
		.schedule-info {
			//float:left;
			//width: calc(100% - 250px);
			// position: relative;
			//top:40px;
		}
		.schedule-court {
			.courtName {
				position: relative;
				float: none;
				display: inline-block;
				width: 100%;
				height: auto !important;
			}
			.schedule-content {
				float: none;
				// display: inline-block;
				width: 100%;
				border-right: none;
				display: inline-flex;
				flex-direction: row;
				flex-wrap: wrap;

				.row {
					float: none;
					display: table;
					table-layout: fixed;
					width: 100%;
					padding-bottom: 10px;
					&.teams {
						min-height: 60px;
						padding-top: 0px;
					}
					&.mobile {
						display: none;
					}
				}

				.cta-button-wrapper {
					&.lgdesktop {
						display: none;
					}
					&.smdesktop {
						display: flex;

						.highlights-cta-button {
							display: flex;
						}
					}

					div {
						margin: 0 10px 15px 10px;
					}
				}
			}

			.match {
				float: none;
				display: table;
				table-layout: fixed;
				.header {
					float: none;
					display: table-cell;
					vertical-align: middle;
					&.match-info {
						width: auto;
						white-space: nowrap;
					}
					&.scores {
						display: table-cell;
						background-color: #f3f3f3;
						width: auto;
						text-align: right;
						white-space: nowrap;
						font-size: 16px;
						padding: 0 10px 0 0;
						font-weight: 500;
					}
				}
				.content {
					float: none;
					display: table-cell;
					vertical-align: middle;
					width: calc(100% - 10px);
					font-size: 16px;
					&.status {
						display: table-cell;
					}
				}
				.match-info {
					width: calc(80% - 10px);
					font-size: 12px;

					.match-info-notBefore {
						font-size: 12px;
					}
					.scores {
						display: none;
					}
				}
				.scores {
					font-size: 14px;
					width: 20%;
					padding-right: 10px;
				}

				.schedule-team {
					width: 35%;
					vertical-align: middle;

					&:first-child {
						width: auto;
						min-width: calc(35% - 10px);
					}

					.winner-of-holder {
						display: flex;
						flex-wrap: wrap;

						.winner-of {
							flex: 0 0 100%;
						}
						.winner-of1 {
							flex: 0 0 100%;
						}
						.winner-of2 {
							flex: 0 0 100%;
						}

						.winner-versus {
							flex: 0 0 100%;
						}
					}
				}
				.name,
				.nation {
					font-size: 16px;
				}
				.seed {
					font-size: 16px;
				}
				.versus {
					width: 12%;
					text-align: center;
					vertical-align: middle;
					padding-left: 0px;
					font-size: 14px;
				}
				.status {
					width: 20%;
					text-align: right;
					font-size: 16px;
				}
			}
		}
	}
}

@include general-breakpoint(desktop) {
	#schedule {
		.print {
			display: block;
			left: $page-margin-large;
		}
		.schedule-court {
			.match {
				.schedule-team {
					width: 30%;
					&:first-child {
						width: auto;
						min-width: calc(30% - 10px);
					}

					.winner-of-holder {
						display: flex;
						flex-wrap: wrap;

						.winner-of {
							flex: 0 0 100%;
						}
						.winner-of1 {
							flex: 1 0 20px;
						}
						.winner-of2 {
							flex: 1 0 20px;
						}

						.winner-versus {
							flex: 0 0 50px;
						}
					}
				}
				.status {
					width: 15%;
				}
			}

			.schedule-content {
				.one-col {
					margin: 10px;
				}
				.row {
					&.smdesktop {
						display: none;
					}

					.cta-button-wrapper {
						display: flex;

						div {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

/** practice schedule styles **/
.practiceSchedule {
	.schedule-section {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		position: relative;
		width: auto;
	}
	.column-layout {
		background: $usoBkgGray;
	}
	.no-schedule-section {
		background-color: $white;
		border: 1px solid $usoLtGray;
		margin: 50px 0;
		width: 100%;

		.daynotfound {
			display: flex;
			flex-direction: column;
			padding: 20px;
			text-align: center;
			width: 100%;
			margin: 0 auto;
			@include interstate;
			font-size: 16px;
			line-height: 22px;
		}
	}
	.schedule-date {
		position: relative;
		width: 100%;
		padding: 15px 10px;
		margin-bottom: 20px;
		display: block;
		background-color: $usoBkgGray;
		h3.full {
			font-size: 18px;
			width: 100%;
			padding: 10px 0px;
			text-align: left;
			color: $usoDkGray;
			text-transform: uppercase;
			@include interstate;
			display: none;
			@include general-breakpoint(tablet) {
				display: block;
			}
		}
		h3.mobile {
			font-size: 16px;
			padding: 10px 0px;
			text-align: center;
			display: block;
			width: 100%;
			@include general-breakpoint(tablet) {
				display: none;
			}
		}

		&.webview {
			background-color: $white;
		}
	}
	.schedule-court {
		position: relative;
		width: 100%;
		margin-bottom: 20px;
		display: block;
		background-color: $usoBkgGray;
		@include general-breakpoint(tablet) {
			display: table;
			table-layout: fixed;
		}
		.courtName {
			@include interstate(regular);
			position: relative;
			color: $dk-gray2;
			text-align: center;
			text-transform: uppercase;
			background: $white;
			font-size: 16px;
			line-height: 21px;
			display: block;
			width: 100%;
			border-top: 1px solid $usoLtGray;
			border-bottom: 1px solid $usoLtGray;
			padding: 20px 10px;
			// @include general-breakpoint(tablet){ display: table-cell; width: 20%; vertical-align: middle; padding: 0px;}
		}
		.schedule-content {
			// border: 1px solid $md-gray2;
			display: block;
			width: 100%;
			border-top: none;
			margin-bottom: 20px;
			// @include general-breakpoint(tablet){ display: table-cell; width: 80%;border-left:none; border-top: 1px solid $md-gray2;}

			.schedule-header {
				background-color: $usoBkgGray;
				padding: 10px 5%;
				@include interstate(bold);
				font-size: 16px;
				text-transform: uppercase;
				color: $usoDkGray;
				.header-title-time,
				.header-title-players {
					display: inline-block;
				}
				.header-title-time {
					width: 25%;
					@include general-breakpoint(smtablet) {
						width: 50%;
					}
				}
				.header-title-players {
					width: 75%;
					@include general-breakpoint(smtablet) {
						width: 50%;
					}
				}
			}
			.schedule-time {
				display: block;
				width: 95%;
				margin: 0px auto;
				background-color: $white;
				border-bottom: 1px solid $usoLtGray;
				.content-row {
					display: block;
					padding: 10px;
					&.header {
						background-color: $lt-gray2;
					}
					.header-title-time,
					.header-title-players {
						@include interstate(regular);
						font-size: 16px;
						display: inline-block;
						text-transform: uppercase;
					}
					.time,
					.players {
						@include interstate(light);
						color: $dk-gray2;
						font-size: 16px;
						display: inline-block;
						.flag {
							display: inline;
							padding-right: 10px;
						}
						.info {
							&.favorite {
								background: $favorite-background-color;
							}
							&.search {
								border: 3px $usoLtBlue solid;
								animation: blink 1.5s;
								animation-iteration-count: 3;
							}
						}
					}
					.header-title-time,
					.time {
						width: 30%;
						vertical-align: top;
						@include general-breakpoint(smtablet) {
							width: 50%;
						}
					}
					.header-title-players,
					.players {
						width: 70%;
						@include general-breakpoint(smtablet) {
							width: 50%;
						}
					}
				}
			}
		}
	}
	.error-text {
		text-align: center;
		margin-top: 50px;
	}
	&.webview {
		.content-main {
			.contentTitle {
				padding: 20px;
			}
		}
		.schedule-court .schedule-content .schedule-time .content-row {
			.header-title-time,
			.time {
				width: 15%;
			}
			.header-title-players,
			.players {
				padding-left: 20px;
				width: 85%;
				.info {
					padding-bottom: 10px;
				}
			}
		}
	}
}

/** tourn schedule **/
.tourn-schedule {
	.daynav-wrapper {
		display: none;
	}

	.schedule-ad {
		margin-bottom: 30px;
	}
	.displaySchedule {
		width: 100%;
		background-color: $usoBkgGray;
		.header {
			background-color: $white;
			height: 62px;
			@include interstate(regular);
			text-transform: uppercase;
			color: $usoDkGray;
			margin: 0px auto;
			border-bottom: 1px solid $usoMdGray;
			font-size: 18px;
			line-height: 34px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;

			// @include general-breakpoint(landscape) {
			// 	height: 62px;
			// }
		}
		.background {
			background-color: $usoBkgGray;
			padding-bottom: 20px;
			.title {
				width: 90%;
				margin: 0px 15px;
				display: flex;
				flex-direction: row;
				padding-top: 20px !important;
				padding-bottom: 10px !important;
				color: $usoDkGray;
				@include interstate(bold);
				text-transform: uppercase;
				font-size: 12px;
				line-height: 14px;
				text-align: left;
				.sid {
					width: 20%;
				}
				.qual-day-color {
					width: 12%;
				}
				.gate,
				.start {
					width: 22%;
				}
				.tourn-schedule-event {
					width: 33%;
				}

				@include general-breakpoint(smtablet) {
					width: auto;
					margin: 0px 20px;
				}
				@include general-breakpoint(desktop) {
					.qual-day-color {
						width: 8%;
					}
					.tourn-schedule-event {
						width: 37%;
					}
				}
				.quals {
					.session-info {
						.sid {
							@include general-breakpoint(mobile) {
								width: 15% !important;
							}
							@include general-breakpoint(smtablet) {
								width: 20% !important;
							}
							@include general-breakpoint(smdesktop) {
								width: 22% !important;
							}
							@include general-breakpoint(desktop) {
								width: 20% !important;
							}
						}
					}
				}
			}

			.quals,
			.maindraw {
				height: 100%;
				width: 90%;
				margin: 0px 15px;
				background-color: $white;
				border-top: 1px solid $md-gray2;
				border-bottom: 1px solid $md-gray2;
				margin-bottom: 10px;
				display: flex;
				flex-direction: row;
				.session-info {
					padding: 10px;
					width: 100%;
					display: flex;
					flex-direction: row;
					@include interstate;
					color: $usoDkGray;
					font-size: 12px;
					line-height: 14px;
					font-weight: 300;
					text-align: left;
					.sid {
						width: 22%;
					}
					.gate,
					.start {
						width: 25%;
					}
					.tourn-schedule-event {
						width: 38%;
						flex: 1 1 auto;
						ul {
							list-style-type: none;
							margin: 0;
							padding: 0;
							li {
								&:not(:first-child) {
									margin-top: 7px;
								}
							}
						}
					}
				}
				.qual-day-color {
					background: $usoDkBlue;
					color: $white !important;
					border-bottom: 4px solid $usoYellow !important;
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 64px;
					width: 54px;
					@include interstate;
					font-size: 10px;
					font-weight: 400;
					line-height: 12px;
					letter-spacing: 0.125px;
					text-align: center;
					text-transform: uppercase;

					.date {
						width: 50%;
						overflow-wrap: break-word;
						text-align: center;
						margin: 0 auto;
						span {
							font-size: 12px;
							line-height: 14.4px;
							font-weight: 700;
						}
						.qual-date-day {
							white-space: nowrap;
						}
					}
				}

				@include general-breakpoint(smtablet) {
					width: auto;
					margin: 0px 20px;
					margin-bottom: 10px;
					// align-items: center;
					.qual-day-color {
						line-height: 14px;
						height: 51px;
						width: 81px;
						.date {
							overflow-wrap: normal;
							width: 40%;
						}
					}
					.session-info {
						align-items: center;
						padding: 10px 15px;
						justify-content: unset;
						font-size: 14px;
						line-height: 16px;
						.sid {
							width: 20%;
						}
						.gate,
						.start {
							width: 22%;
						}
						.tourn-schedule-event {
							width: 30%;
							ul {
								li {
									&:not(:first-child) {
										margin-top: 3px;
									}
								}
							}
						}
					}
				}
			}

			.maindraw {
				.main-day-color {
					background: $usoDkBlue;
					color: $white;
					border-bottom: 4px solid $usoYellow !important;
					display: flex;
					flex-direction: column;
					justify-content: center;
					@include interstate;
					font-size: 10px;
					font-weight: 400;
					line-height: 12px;
					letter-spacing: 0.125px;
					text-align: center;
					text-transform: uppercase;
					height: auto;
					width: 53px;

					.date {
						overflow-wrap: break-word;
						text-align: center;
						margin: 0 auto;
						font-size: 10px;
						line-height: 12px;
						span {
							font-size: 28px;
							line-height: 30px;
						}
						&.mobile {
							display: inline;
							width: 80%;
						}
						&.desktop {
							display: none;
						}
					}

					@include general-breakpoint(smtablet) {
						line-height: 14px;
						width: 82px;
						.date {
							span {
								font-size: 28px;
								line-height: 30px;
							}
							&.mobile {
								display: none;
							}
							&.desktop {
								display: inline;
							}
						}
					}
				}
				.session-info {
					display: flex;
					flex-direction: column;
					padding: 0px;
					text-align: left;
					.content {
						width: 100%;
						.session {
							padding-left: 20px;
							width: 100%;
							display: flex;
							flex-direction: row;
							border-bottom: 1px solid $usoLtGray;
							&:last-child {
								border-bottom: none;
							}
							.sid {
								width: 24%; //will be mobile bp
								@include general-breakpoint(smtablet) {
									width: 23% !important;
								}
								@include general-breakpoint(smdesktop) {
									width: 27% !important;
								}
								@include general-breakpoint(desktop) {
									width: 24% !important;
								}
								@include general-breakpoint(lgdesktop) {
									width: 24% !important;
								}
							}
							.session-content {
								border-top: 1px solid $usoLtGray;
								&:first-child {
									border-top: none;
								}
								.gate,
								.start {
									width: 27%;
								}
							}
							.sid,
							.gate,
							.start,
							.tourn-schedule-event {
								padding: 20px 0;
							}
						}
					}
					.ticket-container {
						display: flex;
						justify-content: center;
						width: 100%;
						height: 60px;
						padding: 20px 0px;
						align-items: center;
						text-align: center;
						vertical-align: middle;
						border-top: 1px solid $md-gray2;
						// margin-top: 10px;
						color: $light-blue;
						@include interstate(bold);
						text-transform: uppercase;
						font-size: 14px;
						line-height: 17px;
						i {
							font-size: px2rem(21);
							margin-right: 5px;
						}
						a {
							display: flex;
							align-items: center;
							text-decoration: none;
							padding: 0px 10px;
						}
					}
					@include general-breakpoint(smtablet) {
						.content .session {
							font-size: 14px;
							line-height: 22px;
						}
					}
				}
			}
		}
	}
	.footer {
		width: 100%;
		background-color: $usoBkgGray;
		display: flex;
		flex-direction: row;
		.notes {
			margin: 10px 20px;
			width: 50%;
			font-size: 12px;
			line-height: 14px;
			font-style: italic;
			&.left {
				text-align: left;
			}
			&.right {
				text-align: right;
			}
		}
	}
}

@keyframes blink {
	50% {
		border-color: $white;
	}
}

/** TV Schedule **/
#tv-schedule {
	.submenu-details-wrapper {
		.tv {
			.area {
				font-size: 14px;
			}
		}
	}
	.content-wrap .blue {
		background-color: $blue;
	}
	.coverage-info {
		position: relative;
		width: 100%;
		padding: 15px 20px;
		margin-bottom: 20px;
		display: block;
		background-color: $usoBkgGray;
		@include interstate;
		color: $usoDkGray;
		text-transform: uppercase;
		padding-left: 50px;
		h2 {
			@include interstate(bold);
			font-size: 18px;
			line-height: 24px;
			display: inline-block;
		}
		span {
			text-transform: uppercase;
			font-size: 14px;
			line-height: 24px;
			padding-left: 20px;
			@include interstate;
			display: block;
		}
	}
	// .general_content { display: inline-block; width: 100%;
	//   @include general-breakpoint(tablet) { width: calc(100% - 320px); }
	// }
	// .moreNews{
	//   /* display: none; */
	//   @include general-breakpoint(tablet) { display: inline-block; float: right; width: 320px; }
	//   &.blue{
	//     .chip_content{ background-color: $blue; }
	//   }
	// }

	// .header{ font-weight:bold; }
	// .legend{
	//   font-size:12px;
	//   margin-bottom:25px;
	//   padding-left:25px;
	//   margin-left:17px;
	//   display: none;
	//   @include general-breakpoint(landscape) { display: block;}
	//   &:before{
	//     width: 25px;
	//     content: '';
	//     height: 20px;
	//     background: url("/images/misc/live_tv_indicator.png");
	//     background-repeat: no-repeat;
	//     background-size: 17px;
	//     background-position: 0 2px;
	//     display: inline-block;
	//     position: absolute;
	//     left: 40px;
	//   }
	//   .live{
	//     font-weight: bold;
	//     text-transform: uppercase;
	//   }
	// }
	// .listings_table{ text-align: center;
	//   .live{
	//     div{
	//       font-weight:bold;
	//     }
	//     .live_ind{
	//       width: 17px;
	//       height: 40px;
	//       display: inline-block;
	//       background: url("/images/misc/live_tv_indicator.png");
	//       background-repeat: no-repeat;
	//       background-size: 17px;
	//       background-position:0 17px;
	//     }

	//   }
	//   .hide{ display: none; @include general-breakpoint(landscape) { display: inline-block;} }
	//   .air_date{ width: 10%; text-align:left; padding-left: 5px;
	//     @include general-breakpoint(tablet) { padding-left: 0; }
	//   }
	//   .air_time{ width: 5%; text-align:left; }
	//   .avail_matches{ width: 30%; text-align:left;}
	//   .avail_matches{display: table-cell;  @include general-breakpoint(landscape) { width: 25%;}
	//     .mobile{ @include general-breakpoint(landscape) { visibility: hidden;} }
	//   }
	//   .air_date{display: table-cell;  @include general-breakpoint(landscape) { width: 10%;padding-left:10px;}
	//     .mobile{ @include general-breakpoint(landscape) { visibility: hidden;} }
	//   }
	//   .air_time{display: table-cell;  @include general-breakpoint(landscape) { width: 10%;}
	//     .mobile{ @include general-breakpoint(landscape) { visibility: hidden;} }
	//   }
	//   .network{ display: table-cell; width: 5%; text-align:left; }
	//   .live_ind{ display: table-cell; width: 5%; }
	//   .row{ height: 100%; display: table-row;
	//     @include general-breakpoint(landscape) { height: 40px; }
	//     &.light{ background: $light_blue2; }
	//     &.dark{ background-color: $blue2; }
	//   }
	// }
}
