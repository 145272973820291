/** radio **/

#radioPage {
	background-color: #004b85;
	height: 100vh;
	width: 100%;
	background: url('https://photo-assets-cdt.usopen.org/images/pics/misc/radio_landing_bg.jpg') no-repeat center center
		fixed;
	background-size: cover;
	display: flex;
	flex-direction: column;
	position: relative;
	.screenReaderMsg {
		text-indent: -9999px;
	}
	.radio-body {
		flex-grow: 1;
	}
	.radio-page-container {
		display: flex;
		width: 200px;
		background-color: #f3f3f3;
		margin: 75px auto 0;
		align-items: stretch;
		justify-content: space-between;

		@include general-breakpoint(tablet) {
			width: 250px;
		}
	}
	.radio-icon {
		width: 65px;
		height: 65px;
		// margin: auto;
		margin: 0;
		border-radius: 0 3px 3px 0;
		background: url('/assets/images/radio/not_playing.png') no-repeat center center;

		&.playing {
			background: url('/assets/images/radio/playing.png') no-repeat center center;
			cursor: pointer;
		}

		&.hoverable {
			cursor: pointer;
		}

		@include general-breakpoint(tablet) {
			width: 85px;
			height: 85px;
		}
	}
	.kicker {
		// position: absolute;
		// top: 65px;
		// left: 15px;
		@include interstate(medium);
		text-transform: uppercase;
		color: $white;
		// padding: 5px;
		font-size: 14px;
		display: flex;
		// flex-grow: 1;
		justify-content: center;
		align-items: center;
		width: 135px;
		height: 65px;
		border-radius: 3px 0 0 3px;
		&.on-air {
			background: $usoRed;
		}
		&.now-playing {
			background: $usoLtBlueBG;
		}
		&.off-air {
			background: #b1b3b3;
		}
		@include general-breakpoint(tablet) {
			width: 185px;
			height: 85px;
			font-size: 16px;
		}
	}
	.react-ecp {
		height: 1px;
		width: 1px;
	}

	.top {
		width: 100%;
		height: 56px;
		background-color: $lt-gray;
		display: flex;
		justify-content: space-between;
		align-items: center;
		#eventLogo {
			max-width: 150px;
			text-decoration: none;
		}
		#amexLogo {
			margin-right: 10px;
			width: 40px;
			height: 40px;
			background: url('/assets/images/radioPlayer/amex_logo_sm.png') no-repeat 0px 0;
			background-size: 40px 40px;
			text-indent: -9999px;
		}
	}
	.radio-blue-bar {
		width: 100%;
		background-color: $usoDkerBlue2;
		height: 61px;
		display: flex;
		justify-content: center;
		.radio-tabs {
			color: $white;
			@include interstate();
			font-size: px2rem(16px);
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 40px;
			cursor: pointer;
			a {
				color: white;
				&:hover {
					text-decoration: none;
				}
			}

			.watch-tab,
			.listen-tab {
				&::after {
					content: attr(data-text);
					content: attr(data-text) / '';
					height: 0;
					display: block;
					visibility: hidden;
					overflow: hidden;
					user-select: none;
					font-weight: 700;
				}

				&:hover {
					text-decoration: none;
					color: #fff;
					font-weight: 700 !important;
				}

				&.selected {
					@include interstate(bold);
					text-decoration: underline;
					text-underline-offset: 22px;
					text-decoration-thickness: 3px;
					color: $white;
				}
			}
		}
	}
	.watch-tab-content {
		.inlineVideo {
			position: relative;
			min-width: 375px;
			width: 100%;
			margin-top: 20px;
			@include general-breakpoint(smdesktop) {
				max-width: 800px;
				margin: 0 auto;
			}
			@include general-breakpoint(lgdesktop) {
				max-width: 1100px;
			}
		}
	}
}

#radioContent {
	h3 {
		@include interstate(bold);
		color: $gray_4a;
		font-size: 32px;
		margin-top: 124px;
		text-transform: uppercase;
		text-align: center;
		width: 273px;
		border-bottom: solid 1px $gray_4a;
		margin-left: auto;
		margin-right: auto;

		@include general-breakpoint(tablet) {
			font-size: 56px;
			width: 460px;
		}
	}

	h4 {
		color: $gray_4a;
		font-size: 14px;
		text-align: center;
		white-space: nowrap;

		@include general-breakpoint(tablet) {
			font-size: 24px;
		}
	}

	.stubContent {
		font-size: px2rem(16);
		text-align: center;
		padding: 40px 20px 20px 20px;

		@include general-breakpoint(smdesktop) {
			font-size: px2rem(20);
			padding: 60px 20px 20px 20px;
		}
	}

	.description {
		font-size: px2rem(14);
		text-align: center;
		padding: 60px 20px 20px 20px;
		max-width: 500px;
		margin: auto;
	}
}

.radioLanding {
	width: 100%;
	min-height: 300px;
	font-size: 15px;
	background: transparent;
	@include general-breakpoint(landscape) {
		min-height: 480px;
	}
	.logo {
		background: transparent url(/assets/images/radioPlayer/radio_logo.png) no-repeat center 20px;
		background-size: 295px 60px;
		width: 100%;
		text-indent: -9999px;
		height: 90px;
	}
	.msg {
		padding: 30px;
		text-align: center;
		&.off {
			@include interstate(bold);
		}
		.uso-button {
			text-shadow: 0 0 0 transparent;
		}
	}
	.btn {
		background: $cyan;
		border: solid 1px $white;
		border-radius: 5px;
		text-transform: uppercase;
		text-align: center;
		width: 130px;
		margin: 30px auto;
		a {
			@include interstate;
			color: white;
			display: block;
			padding: 10px;
			width: 100%;
		}
	}
}
