/** draws styles **/
$columnB-offset: calc(81px + 10px); // height of prizemoney and tix header thing + margin-bottom + 10px;
$columnC-offset: calc(#{$columnB-offset} + 166px + 13px);

$matchbox-height: 166px;

// $columnA-offset-bracket: calc(20px); // 1/2 height of matchbox - 1/2 bottom margin
$columnA-offset-bracket: calc(43px); // 1/2 height of matchbox - 1/2 bottom margin
$columnB-offset-bracket: calc(#{$columnA-offset-bracket} + 40px + 5px); // 1/2 height of matchbox - 1/2 bottom margin
$columnC-offset-bracket: calc(#{$columnB-offset-bracket} + 80px + 10px);
$columnD-offset-bracket: calc(#{$columnC-offset-bracket} + calc(2 * 80px) + calc(2 * 10px));
$columnE-offset-bracket: calc(#{$columnD-offset-bracket} + calc(4 * 80px) + calc(4 * 10px));
$columnF-offset-bracket: 20px; //calc(#{$columnE-offset-bracket} + calc(8 * 80px) + calc(8 * 10px));
$columnG-offset-bracket: calc(#{$columnF-offset-bracket} + 80px + 60px); //calc(16 * 80px) + calc(16 * 10px));
$columnF_2-offset-bracket: calc(#{$columnG-offset-bracket} + 158px + 60px); //calc(16 * 80px) + calc(16 * 10px));

$matchbox-height-bracket: 80px;

$side-margin: 34px;
$side-margin-bracket: 10px;

$left-offset-bracket: -90px;

@function getMarginBottom($breakpoint: mobile, $column: A, $view: round) {
	@if $view == round {
		@if $column == A {
			@if $breakpoint == mobile or $breakpoint == landscape {
				@return 20px;
			} @else if $breakpoint == smdesktop {
				@return 10px;
			}
		} @else if $column == B {
			@if $breakpoint == smtablet {
				@return calc(167px + 20px + 20px); // matchbox height + twice column A's margin bottom
			} @else if $breakpoint == smdesktop {
				@return calc(167px + 10px + 10px);
			}
		} @else if $column == C {
			@if $breakpoint == smdesktop {
				@return calc((167px * 3) + (10px * 4)); // alot
			}
		}
	} @else {
		@if $column == A {
			@return 10px;
		} @else if $column == B {
			@return calc(80px + (10px * 2));
		} @else if $column == C {
			@return calc((80px * 3) + (10px * 4)); // alot
		} @else if $column == D {
			@return calc((80px * 8)); //+ (10px * 5) alot
		} @else if $column == E {
			@return calc((80px * 17)); //   alot
		} @else if $column == F {
			@return 90px; //calc((80px * 35)); //  + (10px * 7) alot
		} @else if $column == G {
			@return 0px; //calc((80px * 70) ); //+ (10px * 8) alot
		}
	}
}

/***
*    draws match boxes wrapper styles
*    columnB and columnC are hidden in mobile view
*    columnC is hidden in tablet view
*/
.drawsList-wrapper {
	width: 100%;
	.bracket & {
		// min-width: 1260px;
		// width: 1260px;
		// overflow-x: auto;
		// overflow-y: hidden;
    .draws-bracket-wrapper{
    min-width: 1260px;
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
      .labels-container{
      &.fixed {
        position: fixed;
        z-index: 3;
        top: 226px;
      }
    }
    
    }
    .drawsList{
      // height: 100vh;
      scrollbar-width: none; //firefox
      -ms-overflow-style: none; //edge
      &::-webkit-scrollbar {display: none;} //chrome - ios
    }


	/** prevent double click set the shorter height that doesb't allow to scroll */
	.react-transform-component, .react-transform-wrapper, .disable-dbl-tap-zoom {
		height: unset !important;
	}

	}
	.drawsList.isZoomedOut {
		// height: calc(100vh + 400px);
		// overflow: unset;
	}
}

.drawsList {
	// display: flex;
	flex-grow: 1;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	background: $usoBkgGray;
	margin-bottom: 20px;

	&.labels {
		margin-bottom: 0px;

		// &.fixed {
		// 	position: fixed;
		// 	z-index: 3;
		// 	top: 226px;

		// 	&.fixed-favorites-sticky {
		// 		top: 336px;
		// 	}
		// }
	}

	.bracket & {
		// height: 100%;
		//max-height: 100vh;
		// min-height: 100%;
		overflow-y: scroll;
		background: $white;
		position: relative;
		.team-info {
			padding-left: 4px;
			.flag {
				flex: 0 0 19px;
				margin-right: 2px;
			}
			.seed {
				margin-left: 2px;
			}

			/** need to put important to override favorite class, which already has important,
			    to make draw analysis precedence */
			&.favorable {
				background-color: $favorable-bg !important;
			}
	
			&.neutral {
				background-color: $neutral-bg !important;
			}
	
			&.difficult {
				background-color: $difficult-bg !important;
			}

			&.post-match-color {
                background-color: $postmatch-bg !important;
            }
		}
		&.labels {
			border-bottom: solid 1px $usoMdGray;
			height: 40px;
			//margin-bottom:20px;
			overflow-y: hidden;
			background: $usoBkgGray;
			min-height: auto;

			.roundGroup {
				&.columnF,
				&.columnG {
					border-left: none;
					background: none;
				}
				&.columnF {
					h3.draws-prize-money {
						padding-left: 10px;
						text-align: center;
						width: 50%;
						float: left;
					}
				}
				&.columnG {
					h3.draws-prize-money {
						padding-right: 10px;
						text-align: center;
						width: 50%;
						float: right;
					}
				}
			}
		}
	}

	.roundGroup {
		box-sizing: border-box;
		//width: calc(100% - 20px);
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		h3.draws-prize-money {
			color: $dk-gray2;
			margin: 0px auto;
			font-size: 14px;
			line-height: 60px;
			text-align: center;
			text-transform: uppercase;
			margin-bottom: 27px;
			line-height: 30px;
			display: flex;
			flex-direction: column;
			width: 100%;
			.round-name {
				font-size: px2rem(18);
			}
			.round-prize-money {
				display: block;
				line-height: 15px;
				height: 15px;
				@include interstate;
				font-size: 12px;
			}
			.ticket-container {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				text-transform: uppercase;
				font-size: px2rem(12);
				height: 21px;
				line-height: 21px;
				@include interstate(bold);
				margin-top: 10px;
				width: 100%;
				hr {
					width: calc((100% / 3) - 20px);
				}
				i {
					font-size: px2rem(21);
					margin-right: 5px;
				}
				a {
					display: flex;
					align-items: center;
					text-decoration: none;
					padding: 0px 10px;
				}
			}
		}

		// override style of match-box-draws
		.draw-container {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			height: 100%;
		}

		.drawGroup.winner {
			.team-info {
				border-bottom: solid 1px $md-gray;
			}
		}

		&.columnA {
			.drawGroup {
				margin-bottom: getMarginBottom(mobile, A);
			}
		}

		&.columnB {
			display: none;
			.drawGroup {
				&.challenge {
					display: none;
				}
			}
		}

		&.columnC {
			display: none;
			.drawGroup {
				&.winner {
					display: block;
					margin-top: 24.5px;
					margin-bottom: 24.5px;
				}
			}
		}
		&.columnD,
		&.columnE,
		&.columnF,
		&.columnF,
		&.columnG {
			display: none;
		}

		@include general-breakpoint(smtablet) {
			width: calc(50% - 9px);
			width: 50%;
			padding-left: 18px;
			&:first-child {
				padding-left: 0px;
			}
			h3 {
				font-size: 18px;
				&.draws-prize-money {
					.round-prize-money {
						line-height: 20px;
						height: 20px;
						font-size: 13px;
					}
				}
			}

			&.columnB {
				display: block;

				.drawGroup:first-of-type {
					//margin-top: calc(81px + 27px + 10px); // height of prizemoney and tix header thing + margin-bottom + 10px;
					margin-top: $columnB-offset;
				}

				.drawGroup {
					margin-bottom: getMarginBottom(smtablet, B);

					&.winner {
						margin-top: 100px;
					}

					&.qualifier {
						&:first-of-type {
							margin-top: 45px;
						}
						margin-bottom: 68px;
					}
				}
			}
		}

		@include general-breakpoint(smdesktop) {
			// width: calc((100%/3) - (50px/3));
			width: calc((100% / 3) - 15px);
			width: calc(100% / 3);
			padding-left: 30px;
			//&:first-child {padding-left: 0px;}

			&.columnA {
				.drawGroup {
					margin-bottom: getMarginBottom(smdesktop, A);
				}
			}

			&.columnB {
				display: block;

				.drawGroup:first-of-type {
					//margin-top: calc(81px + 27px + 10px); // height of prizemoney and tix header thing + margin-bottom + 10px;
					margin-top: $columnB-offset;
				}

				.drawGroup {
					margin-bottom: getMarginBottom(smdesktop, B);
				}
			}

			&.columnC {
				display: block;

				.drawGroup:first-of-type {
					//margin-top: calc(82px + 167px + 10px + 10px);
					margin-top: $columnC-offset;
				}

				.drawGroup {
					margin-bottom: getMarginBottom(smdesktop, C); //getMarginBottom(smdesktop, C);

					&.winner {
						margin-top: 250px;
					}

					&.qualifier {
						&:first-of-type {
							margin-top: 100px;
						}
						margin-bottom: 184px;
					}
				}
			}
		}

		@include general-breakpoint(lgdesktop) {
			width: calc((100% / 3) - 17px);
			width: calc(100% / 3);
			padding-left: $side-margin;
		}

		// bracket view styles
		.bracket & {
			margin-left: $left-offset-bracket;
			width: 233px;
			position: relative;
			&:first-child {
				padding-left: 0px;
			}
			h3.draws-prize-money {
				margin: 0px auto;
				height: 40px;
				font-size: px2rem(12);
				line-height: 13px;
				@include interstate;
				justify-content: center;
				.round-name {
					@include interstate;
					font-size: px2rem(12);
				}
				.round-prize-money {
					line-height: 18px;
					@include interstate(light);
					font-size: px2rem(10);
				}
				.ticket-container {
					display: none;
				}
			}
			.draw-container {
				position: relative;
			}
			&.columnA {
				margin-left: 0px;
				padding-left: 0;
				.drawGroup {
					margin-left: 0px;
					margin-bottom: getMarginBottom(smdesktop, A, bracket);
					&:first-of-type {
						margin-top: $columnA-offset-bracket;
					}
					&:last-child {
						margin-bottom: 0px;
					}
				}
			}
			&.columnB {
				display: block;
				margin-left: $side-margin-bracket;
				padding-left: 0px;
				.drawGroup {
					margin-left: 0px;
					margin-bottom: getMarginBottom(smtablet, B, bracket);
					&:first-of-type {
						margin-top: $columnB-offset-bracket;
					}
					&:last-child {
						margin-bottom: 0px;
					}
					&.challenge {
						display: none;
					}
					&.winner {
						margin-top: 100px;
					}
					&.qualifier {
						&:first-of-type {
							margin-top: 45px;
						}
						margin-bottom: 68px;
					}
				}
			}
			&.columnC {
				display: block;
				padding-left: 0px;
				.drawGroup {
					margin-bottom: getMarginBottom(smtablet, C, bracket); //getMarginBottom(smdesktop, C);
					&:first-of-type {
						margin-top: $columnC-offset-bracket;
					}
					&:last-child {
						margin-bottom: 0px;
					}
					&.winner {
						margin-top: 250px;
					}
					&.qualifier {
						&:first-of-type {
							margin-top: 100px;
						}
						margin-bottom: 184px;
					}
				}
			}
			&.columnD {
				display: block;
				padding-left: 0px;
				.drawGroup {
					margin-bottom: getMarginBottom(smtablet, D, bracket); //getMarginBottom(smdesktop, C);
					&:first-of-type {
						margin-top: $columnD-offset-bracket;
					}
					&:last-child {
						margin-bottom: 0px;
					}
					&.winner {
						margin-top: 250px;
					}
					&.qualifier {
						&:first-of-type {
							margin-top: 100px;
						}
						margin-bottom: 184px;
					}
				}
			}
			&.columnE {
				display: block;
				padding-left: 0px;
				.drawGroup {
					margin-bottom: getMarginBottom(smtablet, E, bracket); //getMarginBottom(smdesktop, C);
					&:first-of-type {
						margin-top: $columnE-offset-bracket;
					}
					&:last-child {
						margin-bottom: 0px;
					}
					&.winner {
						margin-top: 250px;
					}
					&.qualifier {
						&:first-of-type {
							margin-top: 100px;
						}
						margin-bottom: 184px;
					}
				}
			}
			&.columnF {
				display: block;
				padding-left: 0px;
				background: $usoBkgGray;
				position: absolute;
				right: 0px;
				top: 0px;
				width: 316px;
				height: 100%;
				border-left: solid 1px $usoMdGray;
				margin-left: 4px;
				.drawGroup {
					display: flex;
					flex-direction: row;
					justify-content: center; //margin-bottom: getMarginBottom(smtablet, F, bracket);
					&:first-of-type {
						position: absolute;
						top: $columnF-offset-bracket;
						margin: 0px auto;
						left: 0px;
						right: 0px;
					}
					&:last-child {
						position: absolute;
						top: $columnF_2-offset-bracket;
						left: 0px;
						right: 0px;
					}
					&.winner {
						margin-top: 250px;
					}
					&.qualifier {
						&:first-of-type {
							margin-top: 100px;
						}
						margin-bottom: 184px;
					}
				}
			}
			&.columnG {
				display: block;
				padding-left: 0px;
				background: $usoBkgGray;
				position: absolute;
				right: 0px;
				top: 0px;
				width: 316px;
				height: 100%;
				border-left: solid 1px $usoMdGray;
				margin-left: 4px;
				.drawGroup {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					align-items: center;
					position: absolute;
					margin-bottom: getMarginBottom(smtablet, G, bracket);
					z-index: 1;
					&:first-of-type {
						top: $columnG-offset-bracket;
						left: 0;
						right: 0px;
						margin: 0px auto;
						height: 158px;
						background: #d8d8d8;
					} //margin-top:$columnG-offset-bracket;}
					&:last-child {
						margin-bottom: 0px;
					}
					&.winner {
						margin-top: 250px;
					}
					&.qualifier {
						&:first-of-type {
							margin-top: 100px;
						}
						margin-bottom: 184px;
					}
					.round-name {
						@include interstate(bold);
						font-size: px2rem(11);
						line-height: 18px;
						text-transform: uppercase;
					}
					.winner-container {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						width: 233px;
						height: 32px;
						.bio-photos {
							display: flex;
							flex-direction: row;
							justify-content: flex-start;
							img.player-image.xsmall {
								width: 23px;
								height: auto;
							}
						}
						.winner-details {
							width: 100%;
							margin-left: 8px;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							@include interstate(bold);
							font-size: px2rem(10);
							text-align: center;
							.prize-money {
								background: #ffffff;
								border: solid 1px $usoMdGray;
								margin-top: 0px;
							}
							.team-name {
							}
						}
					}
				}
			}
		}
	}

	@include general-breakpoint(smdesktop) {
		padding: 0px 20px;
	}
}

/** bracket view has a different layout for Draw Analysis component above 1280px 
*   set a special layout style here to handle it
*/
.draw-analysis-outer-wrapper {
	@include interstate();
	width: 100%;

	.bracket & {
	}
	/** override spacing */
	.draw-analysis-switch {
		@include interstate();
		padding: 14px 19px;
		transform: translateX(-5px);
		text-transform: uppercase;
		border-bottom: 1px solid $md-gray2;
		position: relative;

		.toggle-button {
			margin-right: auto;

			strong {
				font-weight: normal;
			}
		}

		.draw-analysis-switch-content {
			display: flex;
			align-items: center;
		}

		.updated {
			margin-left: 140px;
			font-size: px2rem(10);
			margin-right: 10px;

			div {
				display: inline-block;
			}
		}

		.help {
			color: $usoLtBlueBG;
			font-size: px2rem(20);
			&:hover {
				cursor: pointer;
			}
		}
	}

	.draw-analysis-outer-content {
		min-height: 160px;
		padding: 0 10px;
		position: relative;

		&.collapsed {
			min-height: 87px;
		}

		&.fixed {
			border-bottom: solid 1px $usoltMedGray;
			border-top: solid 1px $usoltMedGray;
			position: fixed;
			padding: 0 30px;
			top: 56px;
			background: white;
			width: 100%;
			z-index: 3;

			&.fixed-favorites-sticky {
				top: 166px;
			}
		}

		.webview & {
			&.fixed {
				top: 0;

				&.fixed-favorites-sticky {
					top: 0px;
				}
			}
		}
	}

	.name-container {
		// width: 75%;
		display: flex;
		padding: 20px 0 10px 0;
		width: 350px;
		position: relative;

		.player-img-container {
			display: none;
		}

		.flag-image {
			padding-right: 10px;
		}

		.name {
			@include interstate();
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			cursor: pointer;
			max-width: 100%;

			&.intro {
				color: $black;
				font-size: px2rem(16);
				text-transform: uppercase;
				text-align: center;
				width: 100%;
			}

			b {
				font-size: px2rem(16);
			}
		}

		.profile-pic {
			display: none;
		}

		a,
		a:visited {
			color: $usoLtBlueBG;
		}
	} // name-container

	.profile-link {
		@include interstate();
		margin-left: auto;
		text-transform: uppercase;

		&::after {
			content: '\003E';
			display: inline-block;
			padding-left: 7px;
		}
	}

	.toggle-da-content {
		position: absolute;
		left: 90%;
		top: 100%;
		z-index: 20;

		.close-btn-container {
			color: $usoLtBlueBG;

			svg {
				transform: scale(0.6);
			}

			&.plus-icon {
				transform: rotate(45deg);
			}

			&.with-circle {
				border-radius: 50%;
				border: solid 1px $usoLtBlueBG;
			}

			#closeSVG-inner {
				stroke: $usoLtBlueBG;
			}
		}
	}

	/** override */
	.draw-analysis-wrapper {
		margin-top: 0;

		.draw-analysis-inner-wrapper {
			padding: 0;

			.profile-link {
				display: none; // show over desktop
			}
		}

		.updated {
			display: none;

			&.mobile-show,
			&.show {
				display: none;
			}
		}

		/** above tablet show help icon on the bracket view 
		    and position it in the same area as the switch */
		.help {
			display: none; // need to be placed outside of the container
		}

		.intro {
			.draw-label {
				display: none;
			}

			.silo {
				display: none;
			}
			
			p {
				padding-right: 30px;
			}
		}

		p.extra-space {
			margin-top: 20px;
		}
	}

	@include general-breakpoint(smdesktop) {
		.toggle-da-content {
			display: none;
		}

		.draw-analysis-outer-content {
			display: flex;
			min-height: 130px;
			padding: 0 20px;
			max-width: 970px;

			&.collapsed {
				min-height: 160px;
			}
		}

		.name-container {
			min-width: 250px;
			width: 250px;
			font-size: px2rem(14);

			.player-img-container {
				display: block;
				margin-right: 14px;
				filter: drop-shadow(1px 2px 3px #666);
			}

			.profile-link {
				position: absolute;
				top: 60px;
				left: 62px;
				font-size: px2rem(12);
			}

			.flag-image,
			.name {
				padding-top: 10px;
			}
		}

		.draw-analysis-wrapper {
			max-width: unset;
			display: flex;
			padding-top: 20px;

			.draw-analysis-inner-wrapper {
				display: flex;
				justify-content: space-between;

				.silo:first-of-type {
					min-width: 390px; // the bar width is 350px
					width: 390px;
					padding-right: 20px;
					padding-left: 20px;
					border-left: solid 1px $usoltMedGray;
					border-right: solid 1px $usoltMedGray;
					margin-bottom: 12px;
				}

				p {
					padding-left: 20px;
				}

				.profile-link {
					display: block;
				}

				&.intro p {
					padding-top: 10px;
					padding-right: 0;
				}
			}

			p.extra-space {
				margin-top: 10px;
			}
		}

		.power-index-button {
			margin-left: 10px;
		}
	}

	@include general-breakpoint(desktop) {
		.draw-analysis-outer-content {
			display: flex;
			justify-content: space-between;
			min-height: 100px;
			padding: 0 20px;
			max-width: unset;
		}

		.name-container {
			width: auto;
			padding-right: 20px;

			.profile-link {
				// display: none;
			}

			.profile-pic {
				display: inline-block;
				margin-right: 14px;
			}
		}

		.draw-analysis-wrapper {
			max-width: unset;
			display: flex;
			flex: 1;
			align-items: center;
			padding-top: unset;

			.draw-analysis-inner-wrapper {
				display: flex;
				justify-content: space-between;
				padding-right: 20px;

				.profile-link {
					display: block;
				}
			}
		}

		.silo {
			.profile-link {
				display: inline-block;
				padding-top: 20px;
			}
		}
	}
} // .draw-analysis-outer-wrapper

.content-main.draws {
	.select-menu-dropdown {
		overflow-x: hidden;
	}

	.scores-select-menu {
		background-color: $lt-gray;
		border-bottom: solid 1px $md-gray2;
		padding: 12px 0;

		.select-menu {
			button.title {
				text-align: center;
			}
		}
	}

	.column-layout {
		.four-col {
			margin: 0;
			position: relative;
		}
	}
}

.scores-select-menu {
	padding: 12px 0;
	text-align: center;
}

.draws-message {
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	text-align: center;
	width: 80%;
	margin: 50px auto;
	color: white;
}

@include general-breakpoint(mobile) {
	.stub_box {
		top: 10px;
		left: calc(50% - 140px);
		width: 280px;
		.text {
			font-size: 16px;
		}
	}
}

@include general-breakpoint(smtablet) {
	.stub_box {
		left: calc(50% - 250px);
		width: 500px;
		.text {
			font-size: 24px;
		}
	}
}

.draws-nav-column-titles {
	@include general-breakpoint(mobile) {
		.long {
			display: none;
		}
		.short {
			display: block;
		}
	}
	@include general-breakpoint(smtablet) {
		.long {
			display: none;
		}
		.short {
			display: block;
		}
	}
	@include general-breakpoint(desktop) {
		.long {
			display: block;
		}
		.short {
			display: none;
		}
	}
}

.icon-left-arrow:before,
.icon-right-arrow:before {
	cursor: pointer;
}

.draws-arrow-left {
	color: $gray;
	font-size: 2em;
	position: absolute;
	top: 80px;
	left: 20px;

	&:hover {
		cursor: pointer;
	}

	&.hide {
		display: none;
	}
}

.draws-arrow-right {
	color: $gray;
	font-size: 2em;
	position: absolute;
	top: 80px;
	right: 20px;

	&:hover {
		cursor: pointer;
	}

	&.hide {
		display: none;
	}
}

.wrapper {
	&.scorespage {
		position: relative;
		margin: 0px auto;
		max-width: 1260px;

		.page-submenu-wrapper {
			.blue-bar-wrapper {
				background-color: $usoDkBlue;
			}
			.submenu-details-wrapper {
				background-color: $usoDkerBlue2;
			}
		}
	}

	&.draws {
		.page-submenu-wrapper {
			.blue-bar-wrapper {
				background-color: $usoDkerBlue2;
			}
			.submenu-details-wrapper {
				background-color: $usoDkBlue;
			}
		}
		.scores-select-menu {
			padding: 4px 0 0 0;
		}

		.loading-indicator {
			position: fixed;
			top: calc(100vh - 50%);
		}

		&.bracket {
			.column-layout {
				background: $white;
				overflow: scroll;
			}

			.drawsList {
				// min-width: 2400px;
				// max-height: calc(100vh)
			}
			.react-transform-wrapper {
				width: unset;
        overflow: scroll;
				.react-transform-component {
					width: unset;
          // height: 100%;
				}
			}
		}

		.fadeOverlay { // used on bracket view deep link
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 10;
			background-color: $black;
			opacity: 0.4;
			display: none;
		
			&.show {
				display: block;
			}
		}
	}
}

.drawsPdf {
	display: block;
	color: $white;
	font-size: 28px;
	width: 25px;
	margin-right: 11px;
	margin-left: 16px;
	text-align: center;
	line-height: 1;

	&:hover {
		cursor: pointer;
	}

	span {
		display: block;
		font-size: 0.5em;
		line-height: 0.5em;
	}

	@include general-breakpoint(smtablet) {
		display: block;
		margin-right: 0;
	}
}

//webview draws
.webview {
	.draws {
		.page-submenu-wrapper.Draws-mode {
			padding-top: 0px;
			.page-submenu {
				display: none;
			}
			.submenu-details-wrapper {
				.details {
					height: auto;
					&.mobile {
						height: auto;
					}
				}
			}
		}
	}

	#sub_header.draws {
		max-width: 1260px;
		text-align: right;
		padding: 5px 20px;
		display: flex;
		justify-content: flex-end;
		height: 40px;
		background: #ffffff;

		#sponsor {
			width: auto;
			float: none;
			.presented_by {
				align-items: flex-end;
				display: flex;
				a {
					width: 100px;
					margin-left: 10px;
					img {
						width: 100%;
						margin: 0px;
					}
				}
			}
		}
	}
}
