.blue-bar-dropdown-wrapper {
	.blue-bar-dropdown-selected {
		@include interstate();
		cursor: pointer;
		border: 1px solid $white;
		border-radius: 4px;
		color: $white;
		margin-left: 4rem;
		min-width: 10rem;
		text-indent: 5px;
		height: 25px;
		width: 145px;
		font-size: px2rem(12px);
		text-align: center;
		text-align-last: center;

		@media screen and (max-width: 499px) {
			margin-left: 0;
		}
	}
	select {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url('/assets/images/icons/dropdown-arrow-ltgray.svg');
		background-repeat: no-repeat;
		background-position-x: 94%;
		background-position-y: 50%;
		background-color: $usoDkBlue;
	}
}
