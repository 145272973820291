.catch-up-article{
    // margin-bottom: -74px;
    @include panel-content-background;
    padding-top: 15px;
    
    &.noArticle{
        margin-bottom: 0px;        
        .ai-catch-up-box{
            bottom: 0;
        }
    }
    &.noBox{
        margin-bottom: 0px;
        .article-link{
            .article-title{
                bottom: 10px;
            }
        }
    }
    position: relative;
    .article-link{
        height: 100%;
        position: relative;
        width: calc(100% - 20px);
        max-width: 343px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        border: 1px solid $usoLtGray;
        // padding: 4px 16px 13px 16px;
        // box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .25);        
        z-index: 2;
        &:hover{
            text-decoration: none !important;
        }
        button{
            width: 100%;
            // height: 423px;
            position: relative;
            cursor: pointer;            
            .article-image{
                // height: 100%;
                width: 100%;
                // position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                img{
                    // height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;            
                }
            }
            .article-title{
                // position: absolute;
                width: 100%;
                // bottom: 85px;
                // z-index: 2;
                padding: 15px 10px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: left;
                left: 0;
                .title{
                    @include interstate(bold);
                    font-size: px2rem(14);
                    margin-bottom: 10px;
                }
                .description{
                    @include interstate;
                    font-size: px2rem(12);
                }
                .title, .description{                    
                    // text-transform: uppercase;
                    color: $gray-4a;
                    // width: calc(100% - 20px);
                    // max-width: $panel_content_width;
                }
            }
        }
    }
}

.st-ai-catch-up-box {
    width: calc(100% - 20px);
    max-width: $panel_content_width;
    margin-left: auto;
    margin-right: auto;
    padding: 4px 16px 13px 16px;
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 25%);
    background-color: white;
    position: relative;
    // bottom: 74px;
    z-index: 2;

    &.blue-border {
        border-top: 3px solid $usoLtBlueBG;
    }

    .ai-catch-up-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            text-transform: uppercase;
            @include interstate;
            font-size: px2rem(12);
            color: black;
        }
        .subtext{
            max-width: 60%;
            color: black;
            display: flex;
            align-items: center;
            line-height: 32px;
            font-size: px2rem(9);
            @include interstate(light);

            .watsonx-logo {
                font-size: px2rem(12);
            }
            i {
                margin-left: 5px;
                font-size: px2rem(14);
            }
            .question {
                display: none;
            }
        }
    }
    .ai-catch-up-content {
        ul {
            padding-left: 16px;
            margin-top: 5px;

            li {
                @include interstate(light);
                font-size: px2rem(12);
                line-height: 15px;
                margin-top: 15px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.recap-content {
    .ai-catch-up-box {
        margin-bottom: 15px;
    }
}