$ttime: 0.5s;

.conciergeSection{
    .conciergeButton{
        position: fixed;
        background: url('/assets/images/misc/guest_info_button.png') no-repeat 0 0;
        background-size: 50px;
        width: 50px;
        height: 50px;
        bottom: 90px;
        right: 20px;
        cursor: pointer;
        z-index: 999999;
    }

    .conciergeWrapper{
        position: fixed;
        right: 20px;
        bottom: 0;
        z-index: 999999;
        margin: 0;
        padding: 0;
        width: 0px;
        height: 0px; 
        -webkit-transition: width $ttime, height $ttime; 
        transition: width $ttime, height $ttime;
        &.open {
            width: 312px;
            height: calc(70vh + 30px); //iframe height plus nav height
        }
        @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) { display: none; }

        .conciergeIframe {
            border: 0;
            width: 0;
            height: 0;
            -webkit-transition: width $ttime, height $ttime; 
            transition: width $ttime, height $ttime;
            &.open {
                width: 312px;
                height: 70vh;
            }
        }

        .navigationArea{
            position: relative;
            height: 30px;
            width: 0;
            background-color: #f3f3f3;
            display: none;
            -webkit-transition: width $ttime; 
            transition: width $ttime;
            &.open{
                width: 312px;
                display: block;
            }
            .close{
                position: relative;
                top: 3px;
                right: 10px;
                font-size: 24px;
                color: rgb(116, 120, 123);
            }
        }
    }
}