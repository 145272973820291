/** Interactive map **/
.interactive_map {
    padding-top: 0px;
    width: 100%;
    height: 1000px;
    //height: calc(100vh - 154px);

    .infoBoxTitle {
        display: block;
        text-align: center;
        font-weight: bold;
    }

    .infoBoxDescription {
        display: block;
        margin-bottom: 5px;
    }

    .catBtns {
        //width: 70px;
        position: absolute;
        z-index: 1;
        text-align: center;
        margin-top: 80px;
        margin-left: 10px;
        //height: calc(100vh - 232px);
        height: calc(1000px - 80px);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .catBtn {
            height: 80px;
            display: block;
            color: white !important;
            background: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100px;
            overflow: visible;
            white-space: nowrap;
            padding: 0;
            margin: 0;
            border: none;
            &:focus{ outline: none; }

            img {
                width: 50px;
                height: 50px;
            }
        }
    }

    #map {
        width: 100%;
        height: 100%;
    }
}