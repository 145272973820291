/** sub-header styles **/
#sub_header,
.next-article {
    @include interstate(light);
    width: 100%; height: auto;  margin: 0px auto; background-color: $lt-gray2; color: $gray;
    // @include general-breakpoint(landscape){ padding: 0 25px; }
    .centerMargins{ padding: 0 5px;
        // margin: 0px 5px 15px;
    //     @include general-breakpoint(landscape){ margin: 0px 20px 20px; }
    //     @include general-breakpoint(xldesktop){ margin: 0px 69px 20px; }
    }
    #breadcrumbs,
    .breadcrumbs { display: none; height: 25px; line-height: 14px; font-size: 11px; margin-top: 15px;
        @include general-breakpoint(landscape) {
            width: 50%; padding: 0 25px; text-transform: capitalize; display: inline-block; 
            overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
        }
        a { color: $gray; text-decoration: none; }
        .pagetitle { color: $grey; font-weight: bold;}
    }
    .page_header{ width: 100%; background-color: $white; display: flex; padding: 10px 20px;
        @include general-breakpoint(landscape){ padding: 10px 25px;}
        .header{
            width: 60%; text-align: left; font-size: 24px; line-height: 29px; @include interstate(light); color: $usoDkGray;
        }
    }
    #sponsor {  padding: 0;
        height:100%; width: 40%;
        img { vertical-align: middle; }
        @include general-breakpoint(mobile) { 
            display: inline-block;text-align: center;
            .presented_by {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                // padding-top: 6px; --- amb; player profile conflict
                align-items: baseline;
                height:100%;
                overflow: hidden;
                padding-left: 20px;
            }
        }
        @include general-breakpoint(landscape) { display: inline-block; text-align: right; float: right;
            img{margin-left: 20px;}
        }	
        @include general-breakpoint(smtablet) {
            .presented_by {
                justify-content: flex-end;                
            }
        }
        @include general-breakpoint(landscape, max){
            .presented_by{
                span{
                    margin-right: 10px;
                }
                a{
                    display: inline-block;
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }
  }

#sub_header{
    // .live-status {
    //     opacity: 1;
    //     -webkit-transition: opacity 1s ease-in-out;
    //     -moz-transition: opacity 1s ease-in-out;
    //     -ms-transition: opacity 1s ease-in-out;
    //     -o-transition: opacity 1s ease-in-out;
    //     transition: opacity 1s ease-in-out;
    //     transition-delay: 1s;
    //     > div {
    //         transform: scale(1) translateY(0px);
    //         @for $i from 1 through $liveItems {
    //             &:nth-child(#{$i}) {
    //                 transition-delay: #{0.07 * $i+0.2}s;
    //             }
    //         }
    //     }
    // }
    .live-status {
        opacity: 1;
        position: relative;
        @include interstate;
        // font-weight: normal;
        text-transform: uppercase;
        // font-weight: 100;
        text-decoration: none;
        font-size: 1.5em;
        width: initial;
        line-height: initial;
        display: inline-flex;
        vertical-align: top;
        float: right;
        height: 55px;
        align-items: center;

        .isLive, .isRadio {
            // border-bottom: 1px solid #C1C6C8;
            padding-left: 20px;
            display: flex;
            align-items: center;

            @include general-breakpoint(landscape) {
                border-bottom: none;
            }

            &:hover {
                background-color: $dk-gray;
            }

            span {
                margin-bottom: 0;
                font-size: 9px;
                &.onAir::before, &.offAir::before {
                    color: $white;
                    display: inline-block;
                    font-size: 9px;
                    margin-right: 3px;
                    text-align: center;
                    white-space: nowrap;
                    width: 40px;
                    padding: 1px 0;
                }

                &.onAir {
                    color: $red_live;

                    &::before {
                        content: "ON AIR";
                        background: $red_live;
                        font-weight: bold;
                    }
                }

                &.offAir {
                    color: $md-gray;

                    &::before {
                        content: "OFF AIR";
                        background: $md-gray;
                    }
                }
            }
        }
    }
}
  