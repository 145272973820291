/** uso-button **/
.uso-button {
    background-color: $white;
    min-width: 130px;
    max-width: 300px;
    height: auto;
    color: $md-gray;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    border: 1px solid $md-gray;
    @include interstate(regular);
    text-decoration: none;
    line-height: 20px;
    text-align: center;
    padding: 10px;

    &.white {
        @include general-breakpoint(tablet) {
            background-color: $md-gray;
            color: $white ! important;
        }

        a {
            color: $white ! important;
        }
    }
    &.transFill { 
        @include media-breakpoint-up(xs) {
            color: $md-gray ! important; 
            background-color: $white; 
            border-color: $md-gray ! important; 
        }
        @include media-breakpoint-up(sm) {
            color: $white ! important;
            background-color: rgba(0, 0, 0, .6);
            border-color: $white;
        }
    }
    &.blue {
        background-color: $usoLtBlueBG;
        color: $white ! important;
        border: 2px solid transparent;
        &:hover{
            background-color: #fff;
            color: #418FDE !important;
            border: 2px solid $usoLtBlueBG;
        }
    }
    &.dkerBlue {
        color: $usoDkerBlue !important;
        border-color: $usoDkerBlue;

        a {
            color: $usoDkerBlue;

            &:hover {
                color: $bright-blue;
            }
        }

        &:hover {
            border-color: $bright-blue;
        }
    }

    &.uppercase {
        text-transform: uppercase;
    }
    &.wider{
        width: 280px;
    }
    &.round{
        border-radius: 7px;
    }
    &.full-width {
        width: 100%;
        max-width: unset;
    }
    &.compact {
        line-height: 16px;
        font-size: 12px;
        padding: 5px;
    }
    button {
        all: unset;
    }
}

a.uso-button {
    color: $md-gray !important;

    &.white {
        a {
            color: $white !important;
        }
    }

    &.transFill {
        a {
            color: $white !important;
        }
    }

    &.blue {
        a {
            color: $white !important;
        }
    }
}

.uso-primary-button a{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    font-size: px2rem(16);
    background: $usoLtBlueBG;
    color: $white;
    @include interstate(regular);
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 8px;
    border: 2px solid transparent;

    // @include general-breakpoint(smtablet) {
    //     max-width: 280px;
    // }
    &:hover{
        background-color: #fff;
        color: #418FDE !important;
        border: 2px solid $usoLtBlueBG;
        text-decoration: none;
      }
}