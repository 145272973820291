.factors { /** just for desktop */
    justify-content: space-between;
    display: flex;

    .silo {
        width: 49%;
    }

    .mi-inner-body {
        padding: 0;
        padding-bottom: 15px;
    }
}

.mi-win-factors, .have-your-say {
    @include interstate();
    border-top: none;
    border-radius: 7px;
    background-color: $white;
    font-size: px2rem(16);

    @include general-breakpoint(smdesktop)  {
        border: solid 1px $md-gray2;
        font-size: px2rem(16);
        height: 100%;
    }

    &.noborder {
        border: none;
    }

    .mi-section-header {
        margin-bottom: 0;
        padding-bottom: 16px;

        .mi-section-title {
            @include watsonBackgroundColor;
            // background-color: $usoDkBlue;
            border-top-right-radius: 7px 7px;
            border-top-left-radius: 7px 7px;
            color: $white;
            display: block;
            line-height: 50px;
            text-transform: uppercase;
            text-align: center;
            width: 100%;

            @include general-breakpoint(smtablet, max) {
                /** so the Have Your Say header text size matches with the Button Have Your Say */
                font-size: px2rem(18);
            }
        }

        .mi-last-updated {
            text-align: right;
            display: block;
            margin-top: 10px;
            margin-right: 10px;
        }
    }

    .mi-inner-body {
        margin-left: 16px;
        margin-right: 16px;

        @include general-breakpoint(smtablet) {
            margin-left: 48px;
            margin-right: 48px;
        }

        &.voted {
            padding-bottom: 0;
        }
    }

    .row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @include general-breakpoint(smtablet) {

        }
    }

    .col {
        color: $dk-gray2;

        &.icon {
            @include uso-icon('Icons');
            color:  $wf-icon-color;
            border: none;
            font-size: px2rem(36);
            height: 36px;
            width:36px;
        }

        &.arrow-out {
            &::before{
                content: "\e945";
                height: 100%;
            }
        }

        &.grass {
            &::before{
                content: "\e94a";
                height: 100%;
            }
        }

        &.checkmark {
            &::before{
                content: "\e948";
                height: 100%;
            }
        }

        &.court {
            &::before{
                content: "\e949";
                height: 100%;
            }
        }

        &.arrows-up {
            &::before{
                content: "\e946";
                height: 100%;
            }
        }

        &.likes {
            &::before{
                content: "\e94c";
                height: 100%;
            }
        }

        &.ball {
            &::before{
                content: "\e947";
                height: 100%;
            }
        }

        &.head-to-head {
            &::before{
                content: "\e94b";
                height: 100%;
            }
        }

        &.rank {
            &::before{
                content: "\e940";
                height: 100%;
            }
        }

        &.copy {
            margin-left: 15px;
            flex-basis: calc(100% - 51px);
        }

        @include general-breakpoint(smtablet)  {
            
            &.icon {
                font-size: px2rem(48);
                height: 48px;
                width:48px;
            }

            &.copy {
                flex-basis: calc(100% - 63px);
            }
        }
    } // .col
} // .mi-win-factors, have-your-say

.have-your-say {

    border: solid 1px $md-gray2;

    @include general-breakpoint(desktop, max) {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mi-section-header {
        .mi-section-title {
            @include watsonBackgroundColor;
            color: $white;
            opacity: 1;
        }
    }

    /** bootstrap override */
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      color: $white;
      text-decoration: none;
      opacity: .9;
    }

    p {
        text-align: center;
    }

    .prediction {
        @include interstate();
        color: #888B8D;
        padding-bottom: 20px;
        margin-bottom: 24px;

        span {
            color: $usoDkGray;
        }

        &.voted {
            border-bottom: solid 1px $md-gray5;
        }
    }

    h5 {
        @include interstate(bold);
        text-transform: uppercase;
        font-size: px2rem(16);
        font-weight: normal;
        text-align: center;
        margin-bottom: 4px;
        color: $usoDkBlue; // same color for all three

        // &.team1 {
        //     color: $team1_color;
        // }

        // &.team2 {
        //     color: $team2_color;
        // }

        // &.watson {
        //     color: $dk-gray2;
        // }
    }

    .generic-button {
        width: 100%;
        border-radius: 7px;

        span {
            display: block;
            font-size: px2rem(12);
        }
        
        &.rounded-corner {
            .border {
                border-radius: 7px;
                width: 100%;
            }

            .content {

                &:hover, &:active {
                    border-radius: 7px;
                }
            }
        }

        &.header-button {
            @include watsonBackgroundColor;
            color: $white;
            font-size: px2rem(18);
            height: 50px;

            .content {
                color: $white;
            }
        }
        
        &.share {
            .byline--share-wrapper {
                right: 100px;
            }

            i {
                padding-right: 15px;
            }
        }
    }

    .fadeOverlay { // used on Have Your Say vote
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        background-color: $black;
        opacity: 0.4;
        display: none;

        &.show {
            display: block;
        }
    }

    .user-slider {
        position: relative;
        min-height: 40px;
        padding-top: 50px;
        margin: auto;

        .bug {
            border: solid 1px $usoLtGray;
            border-radius: 5px;
            font-size: px2rem(12);
            left: 20%; // later dynamically assigned
            position: absolute;
            padding: 6px 8px;
            white-space: nowrap;

            &:after {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                background: $white;
                border-right:1px solid $usoLtGray;
                border-bottom:1px solid $usoLtGray;
                position: absolute;
                left: 45%;
            }

            &.voted {
                border-color: $md-gray5;

                &:after {
                    border-color: $md-gray5;
                }
            }

            &.watson {
                background: url('/assets/images/logos/watson-blue-logo.svg') no-repeat 6px 2px;
                background-size: 20px 18px;
                margin-left: -46px; /* offset the position so the arrow pointer can be positioned at the graph 0 */
                padding-left: 31px;
                top: 13px;

                &:after {
                    bottom: -7px;
                    transform: rotate(45deg);
                }

                /* commentng out in case they want to use team color,
                     same border color for all three, team1, team2, and watson for 2022 */
                // &.voted {
                //     border-color: $dk-gray2;
                    
                //     &:after {
                //         border-color: $dk-gray2;
                //     }
                // }

                /** make the bubble down arrow set to left side or right side so Watson bubble doesn't get cut off */
                &.left {
                    margin-left: -16px; /* offset the position so the arrow pointer can be positioned at the graph 0 */
                    
                    &:after {
                        left: 15%;
                    }
                }

                &.right {
                    margin-left: -73px; /* offset the position so the arrow pointer can be positioned at the graph 0 */
                    
                    &:after {
                        left: 73%;
                    }
                }
            }

            &.fan {
                top: 77px;
                margin-left: -33px; /* offset the position so the arrow pointer can be positioned at the graph 0 */

                /* commentng out in case they want to use team color,
                     same border color for all three, team1, team2, and watson for 2022 */
                // &.team1 {
                //     border-color: $team1_color;

                //     &:after {
                //         border-color: $team1_color;
                //     }
                // }

                // &.team2 {
                //     border-color: $team2_color;

                //     &:after {
                //         border-color: $team2_color;
                //     }
                // }

                &:after {
                    top: -7px;
                    transform: rotate(225deg);
                }

                /** make the bubble down arrow set to left side or right side so Watson bubble doesn't get cut off */
                &.left {
                    margin-left: -16px; /* offset the position so the arrow pointer can be positioned at the graph 0 */
                    
                    &:after {
                        left: 15%;
                    }
                }

                &.right {
                    margin-left: -60px; /* offset the position so the arrow pointer can be positioned at the graph 0 */
                    
                    &:after {
                        left: 70%;
                    }
                }
            }
        }

        .players {
            @include interstate();
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: solid 1px $usoLtGray;
            font-size: px2rem(16);
            position: relative;

            @include general-breakpoint(smtablet) {
                margin-top: 28px;
                margin-bottom: 24px;
            }
            
            .col {
                width: calc((100% - 20px)/2);
                text-align: center;
            }

            .team1, .team2 {
                color: $usoDkGray;
                font-weight: bold;
            }

            // .team1 {
            //     font-weight: bold;
            //     color: $team1_color;
            // }

            .v {
                position: absolute;
                @include interstate();
                color: $gray-8;
                text-align: center;
                min-width: 20px;
                left: 49%;
                width: auto;
            }

            // .team2 {
            //     font-weight: bold;
            //     color: $team2_color;
            // }

            &.team1, &.team2 {
                position: relative;
                white-space: normal;
                padding-left: 10px;
                padding-right: 10px;

                &:after {
                    content: '';
                    display: block;
                    bottom: -7px;
                    width: 12px;
                    height: 12px;
                    background: $white;
                    border-right:1px solid $usoLtGray;
                    border-bottom:1px solid $usoLtGray;
                    transform: rotate(225deg);
                    position: absolute;
                }
            }

            &.team1 {
                &:after {
                    left: 25%;
                }
            }

            &.team2 {
                &:after {
                    right: 25%;
                }
            }
        }

        &.voted, &.voting-closed {
            .players {
                margin-top: 50px;
            }
        }

        /** rc-slider style override */

        .rc-slider-rail {
            background-color: $md-gray2;
            height: 10px;
        }

        .rc-slider-mark-text {
            visibility: hidden;
        }

        .rc-slider-handle {
            background: transparent url(/assets/images/icons/icon-handle.png) no-repeat center center;
            background-size: 26px 26px;
            border: solid 10px transparent;
            margin-top: -18px;
            width: 42px;
            height: 42px;
            opacity: 1;

            &:focus-visible {
                border-color: none;
                box-shadow: none;
            }
        }

        .rc-slider-disabled {
            background-color: transparent;
            
            .rc-slider-handle {
                background: none;
                border: solid 10px transparent;
                margin-top: -13px;
                width: 42px;
                height: 42px;
                opacity: 1;

                &:before {
                    border: solid 2px $md-gray5;
                    content: 'You';
                    color: $md-gray5;
                    display: block;
                    font-size: px2rem(11);
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: $white;
                    line-height: 30px;
                    text-align: center;
                }
            }
        }

        .rc-slider-handle-dragging {
            box-shadow: none;
        }

        .rc-slider-step {
            height: 2px;
        }

        .rc-slider-dot { 
            border: 0; 
            border-radius: 0;
            border-left: 1px solid #b0b0b0;
            height: 7px;
            margin: 0;
            margin-bottom: -5px;
            width: 1px;

            &:first-child, &:last-child {
                border-left: none !important;
            }
        }

        .rc-slider-dot-active {
            border-color: none !important;
        }

        /** override handler depending on which player is favored */
        &.team1 {
            .rc-slider-handle {
                border: solid 10px rgba(65,143,222,0.20);
            }

            .rc-slider-disabled {
                .rc-slider-handle {
                    border: none;

                    /* commentng out in case they want to use team color,
                     same color for team1 and team2 for 2022 */
                    // &:before {
                        // color: $team1_color;
                        // border: solid 2px $team1_color;
                    // }
                }
            }
        }

        &.team2 {
            .rc-slider-handle {
                border: solid 10px rgba(255,212,0,0.20);
            }

            .rc-slider-disabled {
                .rc-slider-handle {
                    border: none;
                    /* commentng out in case they want to use team color,
                     same color for team1 and team2 for 2022 */
                    // &:before {
                    //     color: $team2_color;
                    //     border: solid 2px $team2_color;
                    // }
                }
            }
        }

        /** hide the handle as the user didn't vote, no value to show in the slider */
        &.voting-closed {
            .rc-slider-handle {
                display: none;
            }

            .rc-slider-disabled {
                .rc-slider-handle {
                    border: none;
                    &:before {
                        border: none
                    }
                }
            }
        }
    } // .user-slider
} // .have-your-say

/** slamtracker override */
.slamtracker {
    .mi-win-factors, .have-your-say {
        font-size: px2rem(14);
        height: auto;
    
        .col {
            &.icon {
                font-size: px2rem(36);
                height: 36px;
                width:36px;
            }

            &.copy {
                font-size: px2rem(14);
            }
        }
    }

    .mi-win-factors {
        border: none;
        
        .mi-section-header.tab-style {
            .mi-last-updated {
                margin-left: auto;
            }
        }

        .mi-inner-body {
            @include general-breakpoint(desktop) {
                margin-left: 16px;
                margin-right: 16px;
            }
            padding-bottom: 0;
        }

        .mi-section-title {
            display: none;
        }
    }
    
    .have-your-say {
        margin-left: 16px;
        margin-right: 16px;

        h5 {
            font-size: px2rem(14);
        }

        .mi-section-header {
            .mi-section-title {
                font-size: px2rem(14);
            }
        }

        .prediction {
            @include interstate();
            color: #888B8D;
            padding-bottom: 20px;
            margin-bottom: 24px;
    
            @include general-breakpoint(smtablet) {
                padding-bottom: 20px;
                margin-bottom: 24px;
            }
        }
        
        .players {
            font-size: px2rem(14);
        }

        .mi-inner-body {
            @include general-breakpoint(desktop) {
                margin-left: 16px;
                margin-right: 16px;
            }
        }
    }
}

/** slamtracker panel */
.stpanel-container {
    .mi-win-factors {
        .mi-section-header.tab-style {
            .mi-last-updated {
                margin-left: auto;
            }
        }
        
        .row, .have-your-say .row {
            padding-bottom: 20px;
            margin-bottom: 0;
        }
    }
}

/** for test tool - WinFactorsFinder */
.win-factors-finder {
    border: none;

    .row {
        display: flex;
        flex-flow: wrap;
        font-size: px2rem(18);

        &.flex {
            display: inline-flex;
            margin-right: 30px;
        }

        .col {
            padding: 15px;
            min-width: 70px;

            i {
                vertical-align: middle;
            }

            .matchNum {
                font-weight: bold;
                font-size: px2rem(26);
                display: block;
                text-align: center;
            }

            .player-img-container {
                display: inline-block;

                .player-image {
                    width: 60px;
                    margin-right: 15px;;
                }
            }
        }
    }
}