/** home page **/
.homepage{
    .subPageContent{
        width: 100%; height: 100%; // padding-top: 30px; 
        background-color: transparent;
    }

  .rolexblock{
    iframe{
      width: 100%;
      height: 70px;
    }
  }
  /*homepage bxslider overwrite*/
  .slick-slider { margin: 0 auto!important;
    img{ max-width: none!important; }
    .single-image img{ width: 100%; height: 100%;
      @include media-breakpoint-up(xl) { height: 720px; object-fit: cover;  object-position: top;
        &.center-obj { object-position: center; }
        &.bottom-obj { object-position: bottom; }
      }
    }
    .single-image .fullscreenable > div { z-index: 999; }
    .slick-dots {
        background-color: rgba(0, 0, 0, 0.56);
        padding: 6px 5px 0px 5px;
        @include border-radius-all(15px); 
    }
    .slick-list { left: 0px !important; border: 0px none !important; /* height: 415px !important;*/ box-shadow: none!important;
      @include media-breakpoint-up(xl) { height: 720px; }
      .slick-slide {
          position: relative;
      }
    }
    a[class^="caption-"] {
        position: absolute;
        width: 100%;
    }
    .caption-bottom-left{
      .bx-caption{bottom: 32px; span{bottom:32px;}}
    }
    .caption-top-left{
      .bx-caption{bottom:auto; top: 32px; span{top:32px;}}
    }
    .caption-bottom-right{
      .bx-caption{bottom: 32px; right: 32px; left:auto; span{bottom:32px; right: 32px; left:0; text-align:right;}}
    }
    .caption-top-right{
      .bx-caption{bottom:auto; top: 32px; right: 32px; left:auto; span{top:32px; right: 32px; left:0; text-align:right;}}
    }
    .caption-top-center{
      .bx-caption{bottom:auto; top: 32px; left:auto; right:auto; margin: 0 auto; span{top: 32px; text-align:center;}}
    }
    .caption-bottom-center{
      .bx-caption{bottom:32px; top: auto; left:auto; right:auto; margin: 0 auto; span{text-align:center;}}
    }
    .caption-middle-left{
      .bx-caption{ top: 0; left:auto; right: 32px; span{top: 50%; transform: translateY(-50%);}}
    }
    .caption-middle-center{
      .bx-caption{ top: 0; left:auto; right:auto; margin: auto; span{top: 50%; transform: translateY(-50%);text-align:center; left: 0; right: 0;}}
    }
    .caption-middle-right{
      .bx-caption{top: 0; right: 32px; left:auto; margin: auto 0; span{top: 50%; transform: translateY(-50%); right: 32px; left:auto; text-align:right;}}
    }
    .bx-caption { 
        position: absolute;
        bottom: 30px;
        left: 0;
        //width: 100%;
        background: none; 
        margin-left: 0px;
      //@include general-breakpoint(desktop) {margin-left: 340px;}
      span{ 
        color: #fff;
        display: block;
        padding: 10px;
        font-size: 1.9rem !important; 
        @include interstate(regular);
        @include general-breakpoint(landscape) { 
            font-size: 3rem !important; 
        }
        @include general-breakpoint(tablet) { 
            font-size: 4.5rem !important; 
        }
    }
    }
    // media wall next up video num
    .videoNum {
      font-size: 1.9rem;
      color: $white;
      text-shadow: 2px 2px 2px $black;
      margin-bottom: -15px;
      padding-left: 30px;
      text-align: left;

      @include general-breakpoint(landscape) {
        padding-left: 15px;
        font-size: 2.9rem;
      }

      @include general-breakpoint(tablet) {
        font-size: 4rem;
      }

      &::before {
        content: '';
        background: url('/assets/images/misc/playlist_button_mediawall.png') no-repeat 0 0;
        background-size: 100%;
        display: inline-block;
        width: 20px;
        height: 26px;
        margin-right: 5px;
        position: relative;
        top: 10px;

        @include general-breakpoint(landscape) {
            width: 30px;
            height: 36px;
          }
  
        @include general-breakpoint(tablet) {
          background-size: 100%;
          width: 58px;
          height: 53px;
        }
      }
    }    
    .slick-dots { display:table; margin:0 auto; bottom: 15px!important; width: auto!important;
        li { position: relative; background: none !important; width: 10px !important; height: 10px !important; border-radius: 7px !important; border: solid 2px #fff;
          &:before{
            content: ' ';
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            top: -7px;
            left: -8px;
          }
          &.slick-active{ background: $lt-gray !important; }
        }
        
          display: inline-block;
          background-color: rgba(0, 0, 0, 0.56);
          padding: 6px 5px 0px 5px;
          @include border-radius-all(15px);
        
    }
  }
  
  }
  
  #hpNews{ position: relative;
  
    .playBtn { /* top: 160px; left: 38%; */ width: 75px; height: 75px; background-size: 75px; /* margin-top: 0px; margin-left: 0px; */ cursor: pointer;z-index: 50000;
      @include general-breakpoint(landscape) { /*left: 43%;*/ }
      @include general-breakpoint(desktop) { /*left: 59%;*/ }
    }
    .closeOverlay{ background-color: #000; width: 60px; float: right; height: 25px; text-align: right; padding: 5px;
      a{ color: white; text-decoration: none; }
      img{ width: 16px; height: 12px; padding-right: 5px;}
    }
    .relatedThumbs{display: block; /*width: 960px; height: 460px;*/ width: 100%; height: 100%; vertical-align: bottom; /*overflow: hidden; position: absolute;*/ z-index: 60000; top: 0px; left:0px; margin: 0 auto;
      //@include general-breakpoint(desktop) { left: $main_menu_width; width: 960px; height: 460px; top: 40px;}
      .relatedThumbsContainer{ //overflow: hidden; height: 375px;
        @include general-breakpoint(tablet){ height: auto; }
  
        .thumbnail{
          position: relative;
          width: 100%;
          display: block;
          img{ width: 100% !important; }
          .caption{position: absolute; top: 12px; left: 12px; color: white; font-size: 1.9em; text-align:left;}
        }
       }
      .darkBG{ width: 100%; height: 100%; position: absolute; background-color: rgba(0, 0, 0, 0.6); z-index: -5; }
      .col-md-8 .videos .playBtn{ top: -200px; left: 100px;}
      .videos{ display: inline-block; width: 100%; position: relative;
        //@include general-breakpoint(desktop) { margin-top: 5px; margin-bottom: 0px; }
        img{ width: 100%; height: 100%; }
        .caption{position: absolute; top: 12px; left: 12px; color: white; font-size: 1.4em; text-align: left;}
        .playBtn{ visibility: visible; top: -80px; left: 25px; margin-top: -80px;margin-left: 33%;position: relative; }
        &:hover{ cursor: pointer;
          //img{border: 2px solid $white;}
          //.caption{ @include interstate(bold); }
          // .playBtn{ visibility: visible; background-position: 0px -75px; }
        }
      }
      &.hide{ display: none; }
    }
  
    #inlineOverlay{ display: none; background: #000000; width: 100%; height: 100%; color: $white; vertical-align: bottom; overflow: hidden; position: absolute; z-index: 60000; top: 0px/*70px*/; padding: 0px; margin: 0 auto; left: auto;
      /*@include general-breakpoint(landscape) { top: 70px; left:20%; width: 480px; height: 290px; }
      @include general-breakpoint(tablet) { left:26%; }
      @include general-breakpoint(desktop) { top: 35px; left:15%; width: 600px; height: 360px; }*/
      &.show{ display: block; }
      #inlineVideo{ position: /*relative*/static; top: 22px;
        .ecp.playerwrapper{ height: 95%!important; top: initial; bottom: 0px; }
      }
    }
  }
  
 /** media wall styles **/
.trans_slider{
    @include general-breakpoint(desktop) { /* width: 1280px; */ position: relative; }
}
  .slick-track{ height: auto; //height: 415px; overflow: hidden;
    //@include general-breakpoint(tablet) { height: 460px; }
    .panel a{ height:inherit; width:inherit; display:block }
    .imgDate{ color: $white; margin-left: 0px; font-size: 16px; position: absolute; z-index: 10; top: 50px;
      left: 10px; display: none;
      @include general-breakpoint(desktop) {margin-left: 340px; display: none;}
    }
    .imgCrop_container{ width: 100%; text-align: center; overflow: hidden;
      &:after { content: ''; position: absolute; top: 0; right: 0; bottom: 0; left: 0; height:415px;
      @include general-breakpoint(tablet) { height: 460px; }
      background-image: transparent; /* For browsers that do not support gradients */
      // background-image: -webkit-linear-gradient($dark_blue, transparent 45%); /* For Safari 5.1 to 6.0 */
      // background-image: -o-linear-gradient($dark_blue, transparent 45%); /* For Opera 11.1 to 12.0 */
      // background-image: -moz-linear-gradient($dark_blue, transparent 45%); /* For Firefox 3.6 to 15 */
      // background-image: linear-gradient($dark_blue, transparent 45%); /* Standard syntax */
      }
    }
    .imgSize{ position: relative; left: 100%; margin-left: -200%; display: inline-block !important; width: initial;
      &.hide{ display: none;}
    }
    .bx-caption span{
      position: absolute;
      bottom: 32px;
      left: 32px;
      text-shadow: 2px 2px 2px $black;
    }
  }
  