
#age-gate-container {
    width: 100%;
    padding: 10px 10px 0 20px;
    background-color: $white;
    text-align: center;
    img {
    width: 45%;
    max-width: 290px;
    margin-bottom: 25px;
    margin-right: 20px;
    }
    #grey_goose_agegate {
    //clear: both;
    #age-gate {
        //background-color: #f5f5f5;
        padding: 5px 5px;
        select {
        margin-right: 10px;
        @include general-breakpoint(landscape){
            width: 28%;
            max-width: 200px;
            height: 35px;
            background: url("/assets/images/nav/chevron-down-blk.png") 0 0 no-repeat #e9e9e9;
            border: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 28px;
            font-size: 1.1em;
            background-size: 17px 9px;
            background-position: 5px 12px;
        }
        }
        
    }
    input[type="submit"] {
        //float: right;
        text-transform: uppercase;
        @include general-breakpoint(landscape){
            width: 10%;
        }
        height: 35px;
        font-size: 16px;
        cursor: pointer;
        padding:5px 15px;
        background:#f5f5f5;
        color: #8b8a8d;
        border:1px solid #ccc;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        margin-top: 15px;
        }
    }
}