$general-fav-card-margin-small: 5px;
$general-fav-card-margin: 10px;
$general-fav-card-margin-large: 15px;

.home-favorites {
	.title-bar {
		h3 {
			&.star {
				display: inline-block;
				position: relative;
				padding-left: 25px;

				&:before {
					@include uso-icon('Icons');
					position: absolute;
					left: 0;
					top: -3px;
					content: '\e934';
					font-size: px2rem(20);
					color: $usoYellow;
				}

				&:after {
					@include uso-icon('Icons');
					position: absolute;
					left: 0px;
					top: -3px;
					content: '\e935';
					font-size: px2rem(20);
					color: $usoMdGray;
				}
			}
		}
		.find-player {
			align-self: center;
			text-align: center;

			@include general-breakpoint(smtablet) {
				font-size: px2rem(14);
			}

			a {
				color: $dk-gray2;
				text-decoration: none;

				&:after {
					@include uso-icon('Icons');
					content: '\e921';
					display: inline-block;
					font-size: px2rem(10);
					padding-left: 8px;
					vertical-align: middle;
				}
			}

			&.right {
				text-align: right;
			}
		}

		.toggle-icon {
			align-self: center;
			text-align: right;

			&:hover {
				cursor: pointer;
			}
		}

		i {
			color: $dk-gray2;
			font-size: px2rem(12);
		}
	} // title-bar

	.title-bar > * {
		flex-basis: 100%;
	}

	&-content {
		background-color: $white;
		padding: 10px;
	} // &-content

	.welcome {
		// background: transparent url(/assets/images/misc/favorite-welcome.png) no-repeat 0 0;
		background-color: $usoDkBlue;
		background-size: cover;
		height: 110px;
		width: 100%;
		a {
			text-decoration: none;
		}

		@include general-breakpoint(smtablet) {
			display: flex;
			height: 114px;
			justify-content: center;
			a {
				display: flex;
				align-items: center;
				padding: 50px 20px;
			}
		}
		@include general-breakpoint(smtablet, max) {
			padding: 20px 10px;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			a {
				display: flex;
				flex-direction: column-reverse;
				align-items: center;
				justify-content: center;
				img {
					margin-top: 10px;
					width: 230px;
				}
				p {
					font-size: 16px;
					font-weight: 700;
				}
			}
		}

		p {
			@include interstate(regular);
			align-self: center;
			color: $white;
			display: block;
			// font-size: px2rem(18);
			font-size: 16px;
			line-height: px2rem(16);
			margin-bottom: 0;
			// padding: 0 20px;
			text-align: center;

			@include general-breakpoint(smtablet) {
				// font-size: px2rem(16);
				font-size: 18px;
				line-height: px2rem(18);
				display: inline-flex;
				margin-left: 20px;
				text-align: center;
			}
		}
	} // .welcome

	.favorite-card {
		border: solid 1px $table-border-grey;
		font-size: px2rem(12);
		width: 190px;

		@include general-breakpoint(smtablet) {
			font-size: px2rem(14);
			width: 208px;
		}

		.row {
			a {
				color: $usoDkGray;
			}
		}

		.player-img-container {
			margin: $general-fav-card-margin-small;
			width: 50px;
			height: 56px;

			img {
				border: solid 1px #d3d5d2;
				width: auto;
				height: 56px;
			}
		}

		.player-name {
			font-size: px2rem(12);
			line-height: px2rem(16);
			width: calc(100% - 20px);

			.last-name {
				display: block;
			}
		}

		.flag-image {
			width: 14px;
			height: 10px;
			display: none;
		}

		.player-status {
			@include interstate(bold);
			font-size: px2rem(12);
			min-height: 21px;
			text-transform: uppercase;

			&.in-progress {
				color: $live-color;

				&:after {
					content: '';
					display: inline-block;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background-color: $live-color;
					margin-left: 3px;
				}
			}

			&.upcoming {
				color: $usoLtBlueBG;
			}

			&.final-match {
				color: $usoDkGray;
			}
		}

		.bio-link {
			font-size: px2rem(12);
			margin: $general-fav-card-margin-small;
			text-transform: uppercase;
			display: none;

			@include general-breakpoint(smtablet) {
				margin: $general-fav-card-margin;
			}

			a,
			a:visited {
				color: $usoDkGray;
			}
		}

		.top {
			background-color: $favorite-background-color-light;
			display: flex;
			min-height: 66px;
			position: relative;

			.row {
				margin: $general-fav-card-margin-small;

				.row {
					margin: 0;
				}
			}

			span.favorite {
				&:after,
				&:before {
					font-size: px2rem(20);
				}
			}

			.player-status {
				position: absolute;
				bottom: 0px;
			}
		}

		.bottom {
			@include interstate(regular);
			font-size: px2rem(12);
			margin: $general-fav-card-margin $general-fav-card-margin $general-fav-card-margin-large
				$general-fav-card-margin;
			min-height: 75px;
			display: none;

			.player-status {
				@include interstate(bold);
				font-size: px2rem(14);
			}

			p {
				margin-bottom: 0;
			}
		}
	} // favorite-card

	/** card expanded styles */
	.favorites-list {
		height: 66px;

		&.open {
			height: 198px;

			.favorite-card {
				/** when expanded, hide the player status from the top container 
                *   it will be displayed in the bottom container 
                */
				.top {
					border-bottom: solid 1px $favorite-border-bottom;

					@include general-breakpoint(smtablet) {
						.row {
							margin: $general-fav-card-margin;

							.row {
								margin: 0;
							}
						}
					}

					.player-img-container {
						@include general-breakpoint(smtablet) {
							margin: $general-fav-card-margin $general-fav-card-margin-small $general-fav-card-margin
								$general-fav-card-margin;
						}
					}

					.player-status {
						display: none;
					}

					.flag-image {
						display: block;
					}
				}

				.bottom,
				.bio-link {
					display: block;
				}
			}
		}

		.slick-slider {
			@include slick-slider-arrows;
			.slick-arrow {
				&.slick-prev {
					border-left: solid 1px $table-border-grey;
					border-top: solid 1px $table-border-grey;
					border-bottom: solid 1px $table-border-grey;
				}
				&.slick-next {
					border-right: solid 1px $table-border-grey;
					border-top: solid 1px $table-border-grey;
					border-bottom: solid 1px $table-border-grey;
				}
			}
		}
	} // favorites-list
} // home-favorite
