/* colors */
$ov_button_bg : #EF7F44;

$tl_highlight_color : #FFFFFF;
$tl_separator_color : #B7B7B7;
$tl_text_color : #FFFFFF;
$tl_border_color : #666666;
$gs_background : #667BA0;
$gs_line_color : #667BA0; 

// stage item colors
$stage_item_color: $md-gray;
// $stage_item_color: $white;
$team1_color:#418FDE;
$team1_color_rgb:rgba(65, 143, 222, 1);
$team2_color:#FFD400;
$team2_color_rgb:rgba(255, 212, 0, 1);
$field_color:#BDE403;
$field_color_rgb:rgba(189,228,3,1);
$panel_separator:#FFFFFF;
$tl_highlight_color : #333333;
$donut_fill_color: #DADCDD;

// dimensions
// $submenu_height: 31px;
// $submenu_padding: 20px;
// $submenu: $submenu_height + $submenu_padding;
// $menu_nav: 47px;
// $purple_line: 9px;
// $footer: 28px;
// $layout_height:31px;
// $layout_margin:10px;
// $layout:$layout_height + $layout_margin;

$page_header_height_mobile: 56px;
$slamtracker_tabs_height: 38px;
$momentum_header_height: 42px;
$momentum_toolbar_height:42px;
$pinned_scoreboard_height:113px;
$featured_scoreboard_hight: 170px;
$panel_content_width: 343px;

@mixin panel-content-background {
  background-color: $white;
}

/* breakpoints */
@mixin embed_breakpoint($break, $type : min) {
  @if $type == min {
    @if $break == xsmall {        // 320px
      @media only screen and (min-width: 320px) { @content; }
    } @else if $break == small {  // 640px
      @media only screen and (min-width: 640px) { @content; }
    } @else if $break == medium { // 768px
      @media only screen and (min-width: 768px) { @content; }
    } @else if $break == large {  // 1024px
      @media only screen and (min-width: 1024px) { @content; }
    } @else if $break == mxlarge {  // 1240px
      @media only screen and (min-width: 1240px) { @content; } //robin made this up for draws text overlap (medium-extra-large)
    } @else if $break == xlarge { // 1601px
      @media only screen and (min-width: 1601px) { @content; }
    } @else if $break == xxlarge { // 1601px + sidepanel_width
      @media only screen and (min-width: #{1601px + $sidepanel_width}) { @content; }
    } @else if $break == sidepanel { // 1366px
      @media only screen and (min-width: #{1024px + $sidepanel_width}) { @content; } // specifically for minimum width at which optional sidepanel is visible
    } @else {
      @media only screen and (min-width: $break) { @content; }
    }
  } @else if $type == max {
    @if $break == xsmall {        // 319px
      @media only screen and (max-width: 319px) { @content; }
    } @else if $break == mxsmall {  // 399px
      @media only screen and (max-width: 399px) { @content; } //robin made this up for stats text overlap (medium-extra-small)
    } @else if $break == small {  // 639px
      @media only screen and (max-width: 639px) { @content; }
    } @else if $break == medium { // 767px
      @media only screen and (max-width: 767px) { @content; }
    } @else if $break == large {  // 1023px
      @media only screen and (max-width: 1023px) { @content; }
    } @else if $break == xlarge { // 1600px
      @media only screen and (max-width: 1600px) { @content; }
    } @else {
      @media only screen and (max-width: $break) { @content; }
    }
  }
}

/* usopen breakpoints */
@mixin stage_item_breakpoint($break) {
  @if $break == xsmall {
    @media only screen and (max-width: 320px) { @content;}
  }
  @else if $break == small {  
    @media only screen and (min-width: 321px) { @content; }
  }
  @else if $break == medium { 
    @media only screen and (min-width: 768px) { @content; }
  } 
  @else if $break == large { 
    @media only screen and (min-width: 960px) { @content; }
  }
  @else if $break == xlarge { 
    @media only screen and (min-width: 1280px) { @content; }
  }
}

/* stage item fonts */
@mixin si_text_font {@include interstate};
@mixin si_title_font {@include interstate(bold)};
@mixin si_heading_font {@include interstate(bold)};
@mixin si_subheading_font {@include interstate(bold)};
@mixin si_donut_font {@include interstate};
@mixin si_numberbox_font {@include interstate(medium)};
@mixin si_winning_font {@include interstate(bold)};

/* momentum vars */
$momentum_toolbar_border_color: #979797;
$momentum_setlabel_bg_color: #F5F5F5;
$momentum_setlabel_color: #000000;
$momentum_setlabel_border_color: #979797;
$momentum_game_separator_color: #F3F3F3;
$momentum_game_color: #888B8D;
$momentum_notification_border_color: #ADACAC;
$momentum_notification_duration_color: #636161;
$momentum_notification_score_color: #000000;
$momentum_notification_sentence_color: #000000; 