// https://getbootstrap.com/docs/4.0/layout/grid/
// https://getbootstrap.com/docs/4.0/utilities
// BOOTSTRAP responsive utility (no longer supported)
// https://v4-alpha.getbootstrap.com/layout/responsive-utilities/
// BOOTSTRAP 4 ELEMENT VISIBILITY unofficial documentation (replaces responsive utility)
// https://stackoverflow.com/questions/35351353/missing-visible-and-hidden-in-bootstrap-v4
// CUSTOM VARIABLE CONFIGURATIONS
//      BREAKPOINTS on line 167 in grid/_variables.scss
//      MAXWIDTH    on line 183            "
//      COLUMNS     on line 197            "
//      GUTTER      on line 198            "
/* GRID DESIGN SPEC SNAPSHOT
 |<xxxxxxxxxxxxxxxxxxxxxxxxx SCREEN WIDTH xxxxxxxxxxxxxxxxxxxxxxxxx>|
 |<xx>|------------------ LEFT/RIGHT OFFSET -------------------|<xx>|
 |----|<x>|--------|<x>|--------|<x>|--------|<x>|-- GUTTERS --|----|
 |----|---|<xxxxxx>|---|<xxxxxx>|---|<xxxxxx>|---|-- COLUMNS --|----|

SCREEN WIDTH 1280px, OFFSET 68px, GUTTERS 32px, COLUMNS 72px (12 COLS)	
SCREEN WIDTH 1024px, OFFSET 12px, GUTTERS 32px, COLUMNS 48px (12 COLS)
SCREEN WIDTH  768px, OFFSET  0px, GUTTERS 32px, COLUMNS 56px (08 COLS)
SCREEN WIDTH  320px, OFFSET 16px, GUTTERS 32px, COLUMNS 48px (04 COLS)
*/

#uso-header {
    position: relative;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2147483643;
    .container {
        max-width: 100%;
    }
    .row {
        height: 56px;
    }
}

#uso-footer {
    width: 100%;
    margin: 0 auto;
    .container {
        max-width: 1280px;
    }
}

// mobile
@include media-breakpoint-between(xs, sm) {
    #uso-header { 
        .middle-col { 
            img {
                margin: 0 auto;
            }
        }
    }
    #uso-footer {
        #footer-container {
            height: 330px;
            .top-row {
                height: 20%;
            }
            .middle-row {
                height: 50%;
            }
            .bottom-row {
                height: 30%;
            }
        }
    }
}

// portrait & landscape
@include media-breakpoint-between(sm, lg) {
    #uso-footer {
        #footer-container {
            height: 165px;
            .top-row {
                height: 35%;
            }
            .middle-row {
                height: 45%;
            }
            .bottom-row {
                height: 20%;
            }
        }
    }
}

// desktop
@include media-breakpoint-between(lg, xl) {
    #uso-footer {
        #footer-container {
            height: 190px;
            .top-row {
                height: 35%;
            }
            .middle-row {
                height: 35%;
            }
            .bottom-row {
                height: 30%;
            }
        }
    }
}

// widescreen
@include media-breakpoint-up(xl) {
    #uso-footer {
        #footer-container {
            height: 190px;
            .top-row {
                height: 35%;
            }
            .middle-row {
                height: 35%;
            }
            .bottom-row {
                height: 30%;
            }
        }
    }
}
