/** Checkbox styles **/
$cbox-size: 18px;
$cbox-border: 2px;
$cbox-mark: "\2715";
$cbox-txt-clr: #000000;
$cbox-border-clr: #4F82BA;
$cbox-bg-clr: #F7F7F7;

$rdo-mark: "\2022";

.checkbox,
.radio {
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    position: relative;
    min-height: $cbox-size + -($cbox-border * 2);
    box-sizing: border-box;
    padding: $cbox-border;
    margin-left: -($cbox-border);
    cursor: pointer;
    line-height: 1;
    color: $cbox-txt-clr;
    outline: none;

    &:focus {
        &:not(.disabled) {
            background-color: $cbox-bg-clr;
        }
    }

    &.top {
        @extend .checkbox;
        align-items: flex-start;
    }

    &.bottom {
        @extend .checkbox;
        align-items: flex-end;
    }

    > *:first-child {
        flex-grow: 1;
    }

    > input {
        display: none;
    }

    &:after {
        content:"";
        padding: 1px 0;
        border: $cbox-border solid $cbox-border-clr;
        width: $cbox-size + $cbox-border;
        height: $cbox-size + $cbox-border;
        margin-top: 0 - calc($cbox-border/2);
        font-size: $cbox-size - ($cbox-border * 2);
        line-height: 1;
        text-align: center;
        border-radius: 2px;
        flex-grow: 0;
        color: $cbox-txt-clr;
    }

    &.checked {
        &:after {
            content: $cbox-mark;
        }
    }

    &-right {
        @extend .checkbox;

        &:after {
            margin-left: calc($cbox-size/2);
        }

    }

    &-left {
        @extend .checkbox;

        &:after {
            order: -1;
            margin-right: calc($cbox-size/2);
        }
    }

    &.disabled {
        pointer-events: none;

        &:after {
            opacity: 0.5;
        }
    }
}

.radio {
    &-right,
    &-left {
        &:after {
            @extend .checkbox, :after;
            border-radius: 100% !important;
        }

        &:before {
            position: absolute;
            content: "";
            top: 50%;
            right: 0;
            width: $cbox-size - calc($cbox-size/3);
            height: $cbox-size - calc($cbox-size/3);
            background-color: $cbox-txt-clr;
            border-radius: 100%;
            margin-top: 0 - calc($cbox-border/2);
            transform: translateX(-50%) translateY(-50%);
            opacity: 0;
            transition: opacity .275s ease-in-out;
        }

        &.checked {
            &:after {
                content: "";
            }

            &:before {
                opacity: 1;
            }
        }
    }

    &-left {
        &:before {
            right: auto;
            left: 0;
            transform: translateX(50%) translateY(-50%);
        }
    }
}
