.slamtracker-keys, .mi-player-keys {
    display: block;
    width: 100%;
    margin: 0px auto;

    .keys_content {
        width: 100%;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px 0px 10px 0px;
        display: inline-block;

        .header {
            @include interstate;
            text-align: center;
            font-size: 13px;
            height: 40px;
            line-height: 40px;
            width: 100%;
            border-bottom: 1px solid #ECECEC;
            display: block !important;

            img { 
                width: 17px;
                height: 10px;
                margin-right: 7px;
            }
            .player-names {
                @include interstate(bold);
                // font-weight: normal;
                // color: $wim-green;
                text-transform: uppercase;
            }
        }
        .team_content { 
            margin-top: 20px;

            .team {
                display: block;

                .key { 
                    width: 100%;

                    .boxes {
                        height: 45px;
                        vertical-align: middle;
                        margin-top: 10px;

                        .set {
                            float: left;
                            width: 48px;
                            height: 48px;
                            margin: 0px;
                            background: url('/assets/images/scores/slamtracker/key.png') 50% 50% no-repeat;
                            background-size: contain;
                            border: none;

                            &.unmet {
                                background: url('/assets/images/scores/slamtracker/key_not_met.png') 50% 50% no-repeat;
                                background-size: contain;
                            }
                            &.keymet {
                                background: url('/assets/images/scores/slamtracker/key_met.png') 50% 50% no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                }
            }
        }
        &.team_2 {
            margin-top: 10px;

            .header {
                border-top: 1px solid #ECECEC;
            }
        }
    }
    .summary {
        width: 240px;
        margin: 0px auto;

        .text {
            text-align: left;
            font-size: 12px;
        }
    }
}