#draws-nav-base {
    background-color: $lt-gray;
}

.draws-nav{
    position:relative;
    width:100%;
    max-width: 516px;
    margin: 16px auto 30px auto;
    box-sizing: border-box;

    @include general-breakpoint(tablet,max){
        height:75px;
    }

    &-column-titles{
        height:24px;
        font-size:12px;
        width:calc(100% - 90px);
        text-transform:uppercase;
        display:flex;
        margin-left:45px;
        text-align:center;
		justify-content: center;

        @include general-breakpoint(tablet,max){
            font-size:10px;
            height:17px;
            width:100%;
            margin:0;
        }

        div{
            flex:1 1 calc(100% / 7);
			flex-basis: 100%;
            color: $md-gray3;
            max-width:calc(100% / 7);
            align-self: center;

            &.clicked{
                border: solid 1px $md-gray4;
                color: $white;
            }
        }

        .title-buffer{
            max-width:unset;
            flex:1 1 0;
			flex-basis: auto;
        }
    }

    .draws-nav-chart{
        display:flex;
        i{
            font-size:40px;
            flex: 0 0 45px;
            color:$gray;
            align-self:center;

            @include general-breakpoint(tablet,max){
                display:none;
            }

            &.disabled{
                opacity:.4;
            }
        }

        .icon-arrow-large-right{
            text-align:right;
        }

        .icon-arrow-large-left{
            text-align:left;
        }

        &-bracket{
            height:67px;
            flex:1 1 auto;
            display:flex;
			justify-content: center;

            @include general-breakpoint(tablet,max){
                height:58px;
            }

            .bracket-buffer{
                width:0;
                flex:1 1 0;
            }

            .bracket-round{
                max-width:calc(100% / 7);
                width:calc(100% / 7);
                flex:1 1 auto;
                display:flex;
                flex-direction:column;

                .sub-round-container{
                    margin-bottom:2px;
                    background-color: $md-gray2;
                    border: solid 1px $md-gray2;
                    flex:1 1 auto;
                    position:relative;
                    display:flex;
					flex-direction:column;
					cursor: pointer;

                    &.clicked{
                        background-color: $white;
                        border: solid 1px $md-gray4;

                        .line::before{
                            border-color: $md-gray2;
                        }
                    }

                    .line{
                        position:relative;
                        flex: 1 1 auto;
                    }

                    .line::before{
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        border-top: 1px solid $white;
                        width: 90%;
                        transform: translateY(-50%);
                        margin-left:5%;
                    }
                }

                .sub-round-container:last-child{
                    margin:0;
                }
            }
        }

    }

}


// for webview specific override
.webview {
    .draws-nav {
        .icon-arrow-large-left, .icon-arrow-large-right {
            visibility: hidden;
        }
    }
}