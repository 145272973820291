$live_blog_content_width: 678px;

.live-blog {
	height: 100%;
	&-sub-header {
		display: none;
		@include general-breakpoint(smdesktop) {
			display: block;
		}
	}
	&.webview {
		width: 100%;
		height: 100%;
		.daynav {
			background-color: $usoDkerBlue2;
			.title {
				background-color: $usoDkerBlue2;
				color: $white;
				border: 1px solid $white;
				.menu-arrow {
					color: $white;
				}
			}
			&.scores-select-menu {
				.option {
					&.selected {
						border-bottom: 3px solid $white;
					}
					&:focus {
						outline: none;
					}
				}
			}
		}
	}

	.pageTitle {
		padding: 20px;
		margin: 0px;
		@include interstate(light);
		color: $dk-gray2;
		font-size: px2rem(22);
		line-height: px2rem(32);
		@include general-breakpoint(smdesktop) {
			background-color: $white;
			padding: 20px 40px;
			font-size: px2rem(48);
			line-height: px2rem(58);
			border-top: 1px solid #e0e1e2;
			border-bottom: 1px solid #e0e1e2;
		}
	}
	.content {
		display: block;
		// display: flex; flex-wrap: nowrap; flex-direction: column;
		@supports ((position: -webkit-sticky) or (position: sticky)) {
			.article-sticky-ad {
				padding-top: 10px;
				margin: 0px auto;
				text-align: center;
				z-index: 1;
				@include general-breakpoint(desktop) {
					position: sticky;
					float: right;
					top: 0px;
					padding-top: 89px;
					padding-bottom: 131px;
					margin-left: 40px;
				}
			}
		}
		.rightRail {
			order: 2;
			flex-grow: 1;
			width: 400px;
			margin: 25px;
			display: none;
		}
		.blog {
			order: 1;
			flex-grow: 2;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			margin: 10px;

			.blogInsert {
				display: table;
				width: 100%;
				text-align: center;
				.commentRow,
				.footerRow {
					display: table-row;
				}
				.commentIcon {
					display: table-cell;
					vertical-align: top;
					height: 100%;
					width: 25px;
					position: relative;
					&:after {
						position: absolute; /*top: 34px;*/
						content: '';
						width: 1px;
						height: 100%;
						background-color: $usoLtGray;
					}
					.iconType,
					.iconLine {
						display: block;
						text-align: center;
					}
					.iconType {
						/*margin-top: 10px;*/
						color: $bright-blue;
						font-size: 14px;
						background-color: $white;
						height: 25px;
						border-radius: 15px;
						i {
							top: 5px;
							position: relative;
						}
					}
				}
				.footerRow .commentIcon:after {
					top: 6px;
				}

				.comment {
					background-color: $white;
					padding: 10px;
					border: 1px solid #e0e1e2;
					margin-left: 10px;
					text-align: left;
					a {
						text-decoration: none;
						&:hover {
							text-decoration: none;
						}
					}
					.title {
						@include interstate(bold);
						font-size: px2rem(18);
						line-height: px2rem(21);
						color: $black;
						padding: 10px;
						border-bottom: 1px solid $gray-8;

						&.video-title {
							cursor: pointer;
						}
					}
					.body {
						padding: 0px;
						@include interstate;
						font-size: px2rem(15);
						line-height: 22px;
						color: $dk-gray2;
						.desc {
							@include interstate;
							font-size: px2rem(14);
							line-height: px2rem(17);
							color: $dk-gray2;
							width: 100%;
							padding-top: 10px;
							height: 100%;
							// overflow: hidden; height: 95px;
							&.active {
								height: auto;
								overflow: unset;
							}
						}
						.photo {
							width: 100%;
							height: auto;
							position: relative;
							padding-top: 5px;
							img {
								width: 100%;
								height: 100%;
							}
							.playBtn {
								width: 41px;
								height: 41px;
								background-size: 41px;
								margin-top: 20%;
								margin-left: 40%;
								top: 0;
								left: 0;
							}
							&.video-image {
								cursor: pointer;
							}
						}
						.caption {
							background-color: $lt-gray2;
							font-size: px2rem(12);
							line-height: px2rem(15);
							@include interstate;
							color: $dk-gray2;
							padding: 10px;
						}
						.showMore {
							color: $bright-blue;
							@include interstate;
							text-transform: uppercase;
							font-size: px2rem(15);
							line-height: px2rem(22);
							cursor: pointer;
							padding-top: 10px;
						}
						.video {
							.media-wrapper {
								cursor: pointer;
							}
						}
					}
					&.twitter,
					&.instagram,
					&.youtube,
					&.facebook {
						.body {
							.desc {
								height: 100%;
								width: 100%;
								display: block;
								margin: 0px auto;
								justify-content: center;
								@include general-breakpoint(smtablet, max) {
									iframe {
										max-width: fit-content;
									}
								}
								@include general-breakpoint(smtablet) {
									display: flex;
									align-content: center;
									justify-content: center;
								}
							}
						}
					}
					&.youtube,
					&.facebook {
						.body {
							.desc {
								@include general-breakpoint(smtablet, max) {
									iframe {
										max-width: fit-content;
										min-width: unset !important;
										height: 100%;
									}
								}
							}
						}
					}
					&.facebook {
						@include general-breakpoint(smtablet, max) {
							width: 85vw;
						}
					}
					&.twitter {
						.body {
							.desc {
								display: block;
								margin: 0px auto;
								justify-content: center;
								.twitter-tweet {
									width: 95% !important;
									margin: 0px auto;
									justify-content: center;
								}
							}
						}
					}
					&.pr {
						.player-pr {
							display: flex;
							flex-wrap: wrap;
							min-height: 100px;
							.player-pr-title {
								width: 100%;
								display: flex;
								flex-wrap: wrap;
								@include general-breakpoint(smtablet) {
									justify-content: space-between;
									align-items: baseline;
									flex-wrap: nowrap;
								}
								.mi-last-updated {
									margin-top: 0px;
									margin-right: 0px;
								}
								.contents-in-header-title {
									display: inline-block;
									padding: 10px;
									color: $dk-gray2;
									i.icon-info {
										margin-left: 10px;
										font-size: 40%;
									}
								}
							}
							.mi-pr-info-wrapper {
								display: flex;
								width: 100%;
								flex-wrap: wrap;
								@include general-breakpoint(smtablet) {
									justify-content: space-between;
									flex-wrap: nowrap;
								}
								.mi-pr-player-info-wrapper {
									width: 50%;
									display: flex;
									justify-content: space-evenly;
									max-width: 100%;
									flex-grow: 1;
									img.player-image,
									.player1,
									.player2 {
										width: 70px;
										margin-left: 0;

										@include general-breakpoint(smtablet) {
											width: 117px;
											margin-left: 0;
										}
										@include general-breakpoint(smdesktop) {
											width: 180px;
											margin-left: 0;
										}
										@include general-breakpoint(desktop) {
											width: 220px;
											margin-left: 0;
										}
										height: auto;
									}
									.mi-pr-player-info {
										width: 127px;
									}
									.mi-pr-player-container a:hover {
										color: $bright-blue;
									}
								}

								.mi-pr-ranks-section,
								.mi-pr-ranks {
									width: 100%;
									@include general-breakpoint(smtablet) {
										position: relative;
									}

									.ranking-cell {
										font-size: px2rem(38);

										@include general-breakpoint(smtablet) {
											font-size: px2rem(68);
										}
										@include general-breakpoint(desktop) {
											font-size: px2rem(78);
										}

										&.rank-label {
											font-size: px2rem(16) !important;
											@include general-breakpoint(smtablet) {
												font-size: px2rem(20) !important;
											}
										}
									}
								}
							}
						}

						.mi-section .above-tablet {
							/** the new power index by numbers */
							.mi-section-title {
								display: none;
							}
						}
					}
					&.pr_ltw {
						display: flex;
						flex-wrap: wrap;
						min-height: 100px;
						.title {
							width: 100%;
						}
						.contents-in-header {
							display: none;
						}
						.slamtracker-only {
							display: none;
						}
						.LikelihoodToWinBanner {
							margin-top: 15px;

							.likelihood-win-btn {
								@include general-breakpoint(smtablet) {
									width: 85%;
								}
							}
						}
						.match-insights-content {
							.mi-tags,
							.mi-in-the-media-wrapper,
							.mi-by-the-numbers-wrapper,
							.mi-footer,
							.back-to-all-mi,
							.blog-hide,
							.have-your-say,
							.card-head {
								display: none;
							}
							.mi-section {
								@include general-breakpoint(smtablet, max) {
									margin: 0;
									padding: 10px 0;
								}
							}
							.mi-section .mi-power-ranking-wrapper {
								border-bottom: none;
								padding-bottom: 20px;
								width: 100%;
								max-width: $live_blog_content_width;
								margin: 0px;
							}
							.mi-power-ranking-wrapper {
								border-bottom: none;
								margin-left: 0px !important;
								margin-right: 0px !important;
								.mi-last-updated {
									margin-top: 10px;
									margin-right: 0px;
									justify-content: flex-end;
								}
							}
							.mi-pr-player-info-wrapper {
								// max-width: $live_blog_content_width;
								width: 100%;
								display: flex;
								justify-content: space-evenly;
								max-width: 100%;
								flex-wrap: nowrap;
								margin-top: 0px;

								@include general-breakpoint(tablet) {
									flex-wrap: nowrap;
								}

								@include general-breakpoint(smdesktop) {
									justify-content: space-between;
									width: 90%;
									margin: auto;
								}

								img.player-image,
								.player1,
								.player2 {
									width: 50px;
									@include general-breakpoint(smtablet) {
										width: 90px;
									}
									@include general-breakpoint(smdesktop) {
										width: 117px;
									}
									@include general-breakpoint(desktop) {
										width: 180px;
									}
									height: auto;
								}
								.mi-pr-player-info {
									@include general-breakpoint(smtablet) {
										height: auto;
										display: flex;

										@include general-breakpoint(smdesktop) {
											justify-content: space-between;
										}
									}

									.slamtracker-only {
										display: block;

										@include general-breakpoint(smtablet) {
											display: none;
										}
									}
								}
								.mi-pr-player-container a:hover {
									color: $bright-blue;
									cursor: pointer;
								}
								.player-name a {
									text-decoration: none;
									cursor: pointer;
									&:hover {
										color: $bright-blue;
									}
								}

								.likelihood-win-btn {
									width: 200px;
									margin: 20px auto;

									@include general-breakpoint(smdesktop) {
										width: 250px;
									}

									@include general-breakpoint(desktop) {
										width: 350px;
									}

									.btn {
										width: 100%;
										height: auto;
										margin-top: -10px;
									}

									@include general-breakpoint(smtablet) {
										.btn {
											margin-top: unset;
										}
									}
								}
								.player2 .likelihood-win-btn {
									@include general-breakpoint(smdesktop) {
										transform: translateX(-130px);
									}
									@include general-breakpoint(desktop) {
										transform: translateX(-160px);
									}
								}

								.mi-pr-player-country-name {
									padding-bottom: 15px;

									@include general-breakpoint(smtablet, max) {
										font-size: px2rem(10);
									}
								}
							}
							.embedded-wrapper {
								.mi-pr-player-info-wrapper {
									justify-content: space-between;
									width: 80%;
									margin: auto;
								}
							}

							/** likelihood banner holder */
							.above-tablet {
								.mi-pr-player-info-wrapper {
									justify-content: space-between;
									width: 100%;

									.likelihood-win-btn {
										width: 220px;
										font-size: px2rem(10);
									}

									.mi-pr-player-info {
										// width: 137px;
										width: 30%;
										margin: auto;
									}
								}
							}

							.mi-pr-ranks-wrapper {
								width: 100%;
								a {
									width: 100%;
								}
								.mi-pr-ranks {
									width: 100%;
									max-width: $live_blog_content_width;
									@include general-breakpoint(smtablet) {
										position: relative;
									}
									.ranking-cell {
										font-size: px2rem(38);

										@include general-breakpoint(smtablet) {
											font-size: px2rem(68);
										}
										@include general-breakpoint(desktop) {
											font-size: px2rem(78);
										}

										&.rank-label {
											font-size: px2rem(16);
											@include general-breakpoint(smtablet) {
												font-size: px2rem(20);
											}
										}
									}
								}
							}
							/** MI desktop style for all breakpoints */
							.silo {
								width: 100%;
							}
							.mi-win-factors,
							.below-desktop .mi-win-factors {
								border: solid 1px $md-gray2;
								font-size: px2rem(16);
								// height: 100%;
								margin-left: 29px;
								margin-right: 29px;

								.mi-section-header {
									.mi-section-title {
										@include watsonBackgroundColor;
										// background-color: $usoDkBlue;
										border-top-right-radius: 7px 7px;
										border-top-left-radius: 7px 7px;
										color: $white;
										display: block;
										line-height: 50px;
										text-transform: uppercase;
										text-align: center;
										width: 100%;
									}
								}
							}
						}

						@include general-breakpoint(smdesktop, max) {
							#match-insights .PowerIndexChart {
								margin-top: 10px;

								.probability {
									font-size: px2rem(14);

									@include general-breakpoint(smtablet) {
										font-size: px2rem(24);
									}

									@include general-breakpoint(smdesktop) {
										&.team1 {
											margin-right: 10px;
										}

										&.team2 {
											margin-left: 10px;
										}
									}
								}

								.ct-chart {
									width: 70px;
									height: 70px;

									@include general-breakpoint(smtablet) {
										width: 100px;
										height: 100px;

										&:before {
											font-size: px2rem(26);
											top: 39px;
											left: 33px;
										}
									}

									@include general-breakpoint(smdesktop) {
										width: 140px;
										height: 140px;

										&:before {
											font-size: px2rem(36);
											top: 59px;
											left: 43px;
										}
									}
								}

								.winnerCheck {
									&.team1 {
										left: 3px;

										@include general-breakpoint(smtablet) {
											left: 0px;
										}

										@include general-breakpoint(smdesktop) {
											left: 0px;
											bottom: -40px;
										}

										@include general-breakpoint(desktop) {
											left: -20px;
										}
									}

									&.team2 {
										right: 3px;

										@include general-breakpoint(smtablet) {
											right: 0px;
										}

										@include general-breakpoint(smdesktop) {
											right: 0px;
											bottom: -40px;
										}

										@include general-breakpoint(desktop) {
											right: -20px;
										}
									}
								} // .winnerCheck
							}
						}
					}
				}
				.time {
					display: table-cell;
					color: $gray-8;
					font-size: px2rem(14);
					line-height: px2rem(15);
					margin: 10px;
					padding: 10px 15px 25px;
					text-align: left;
				}

				.media-16x9 {
					padding-bottom: 56.25%;
					position: relative;
					margin: auto;
					width: 100%;

					.media-wrapper {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;

						.inlineVideo {
							width: 100%;
							float: left;
							text-align: center;
							position: relative;
							//margin: 10px 0px 20px 0px;
							padding-bottom: 56.25%;
							height: 0;

							img {
								width: 100%;
							}
						}
					}
				}
			}
		}

		@media only screen and (max-width: 424px) {
			.comment.twitter,
			.comment.instagram {
				padding: 10px 0px !important;
				.body {
					padding: 0px !important;
				}
			}
		}

		@include general-breakpoint(landscape) {
			.blog {
				margin: 20px 0px;
				.blogInsert {
					.commentIcon {
						width: 30px;
						.iconType {
							font-size: 16px;
							height: 30px;
							border-radius: 20px;
						}
					}
					.comment {
						margin: 0px 10px;
						width: calc(100% - 10px);
						.body {
							padding: 10px;
							.desc {
								font-size: px2rem(15);
								line-height: px2rem(22);
								// height: 55px; overflow: hidden;
							}
							.photo {
								.playBtn {
									top: 50%;
									left: 50%;
									margin-top: -50px;
									margin-left: -50px;
									width: 75px;
									height: 75px;
									background-size: 75px;
								}
							}
						}
					}
				}
			}
		}

		@include general-breakpoint(desktop) {
			flex-direction: row;
			.rightRail {
				display: flex;
			}
			.blog {
				margin: 20px 0px;
				.blogInsert {
					// .commentIcon{ padding-left: 75px;}
					.comment {
						margin: 0px 15px 0px 25px;
						width: calc(100% - 25px);
						// .body{
						// .photo{ width: 635px; }
						// }
					}
					.time {
						padding: 10px 25px 25px;
					}
				}
			}
		}

		@include general-breakpoint(xldesktop) {
			.blog {
				flex: 1;
				justify-content: space-around;
				// .blogInsert{
				//     .comment{ width: calc(100% - 25px);}
				// }
			}
		}
	}

	.schedulefilter {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $usoDkerBlue2;
		height: 55px;
		.daynav-wrapper {
			&.disable {
				display: none;
			}
			.select-menu {
				color: $white;
				.tablet.select-menu-dropdown {
					overflow: hidden;
					button[data-type='label'] {
						@include interstate(bold);
						border: none;
					}
					.option {
						border-top: none;
						border-right: none;
						border-left: none;
						background-color: transparent;
						color: $white;
						width: 42px;
						&.selected {
							text-decoration: none;
							@include interstate(bold);
							border-bottom: 3px solid $white;
						}
						.option-name {
							&:hover {
								@include interstate(bold);
							}
						}
					}
				}
			}
			.daynav {
				&.scores-select-menu {
					&.dark {
						background-color: #001d67;
						.title {
							border: 1px solid $white;
							background-color: transparent !important;
							color: $white !important;
						}
					}
				}
			}
		}
	}
	.scores-box-container {
		.event-notes {
			line-height: 12px;
		}
	}
}

.blog-spacing {
	padding: 20px;
}

.live-blog-menu-select {
	@include interstate();
	border: 1px solid #fff;
	background-color: transparent;
	color: #fff;
	border-radius: 4px;
	font-size: 12px;
	height: 25px;
	width: 175px;
	text-indent: 10px;
}

.live-menu-arrow {
	position: absolute;
	font-size: 18px;
	line-height: 10px;
	display: inline-block;
	position: relative;
	top: 0px;
	right: 20px;
	&:after {
		content: '\25BE';
	}
	color: #fff;
}
.live-blog-menu-select-wrapper {
	background-color: #001d67;
}
.live-blog-menu-select-wrapper select {
	appearance: none;
	position: relative;
}
