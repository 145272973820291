/** 
 * styles for 
  -favorite star
  -favorites-toggle
*/

/** for FavoriteStar component*/
span.favorite {
	display: block;
	position: relative;
	cursor: pointer;
	@include uso-icon('Icons');

	&:after {
		position: absolute;
		left: 0;
		content: '\e935';
		font-size: 33px;
		color: $usoMdGray;
	}

	&.favorited:after {
		content: '\e934';
	}

	&.black {
		&:after {
			color: $black;
		}
	}
	&.ltgray {
		&:after {
			color: $usoLtGray;
		}
	}
	&.white {
		&:after {
			color: $white;
		}
	}
	&.yellow {
		&:after {
			color: $usoYellow;
		}
	}

	/** add an outline border when favorited */
	&.favorited {
		&.outline {
			&:before {
				font-size: 33px;
				position: absolute;
				left: 0;
				content: '\e934';
			}

			&:after {
				/** this makes the outline */
				color: $usoMdGray;
				content: '\e935';
				left: 0px;
				top: 0px;
			}

			&.black {
				&:before {
					color: $black;
				}
			}
			&.ltgray {
				&:before {
					color: $usoLtGray;
				}
			}
			&.white {
				&:before {
					color: $white;
				}
			}
			&.yellow {
				&:before {
					color: $usoYellow;
				}
			}
		}
	}
}

.pagesub-favorites {
	display: flex;
	background-color: #fff;
	border: 1px solid #e0e2e3;
	.icon-arrow-right {
		font-size: 8px;
		color: #000;
	}
	.favorites-toggle.on.outline:after {
		left: 4px;
		top: 4px;
		color: $usoLtBlue;
		font-size: 29px;
	}
}

/** My Favorites toggle component style */
.favorites-toggle {
	font-size: 12px;
	font-weight: 700;
	height: 44px;
	line-height: 44px;
	position: relative;
	display: flex;
	text-align: center;
	width: 90%;
	@include general-breakpoint(smtablet){
		width: 95%;
	}

	@media screen and (max-device-width: 767px) {
		line-height: 15px;
	}
	&:hover {
		cursor: pointer;
	}

	&.uppercase {
		text-transform: uppercase;
	}

	.view-favorites-button {
		position: absolute;
		left: 40px;
		color: #4a4a4a;
		text-transform: uppercase;
	}
	&:hover:before {
		color: $usoLtBlue;
	}
	&:before {
		@include uso-icon('Icons');
		content: '\e935';
		padding-right: 5px;
		font-size: 28px;
		color: $usoDkGray;
		position: relative;
		top: 4px;
		left: 4px;
	}

	&.on {
		color: $usoYellow;

		&:before {
			content: '\e934'; // fill
			color: $usoYellow;
		}

		&.outline {
			// &:hover::before {
			// 	content: '\e934';
			// 	outline: 1px solid $usoLtBlue;
			// 	color: $usoYellow;
			// }
			&:before {
				content: '\e934';
				color: $usoYellow;
			}

			&:after {
				/** this makes the outline */
				@include uso-icon('Icons');
				color: $usoMdGray;
				content: '\e935';
				padding-right: 5px;
				font-size: 28px;
				color: $usoLtGray;
				left: 0px;
				top: 0px;
				position: absolute;
			}

			&.ltgray {
				&:before {
					color: $usoLtGray;
				}
			}
			&.white {
				&:before {
					color: $white;
				}
			}
			&.yellow {
				&:before {
					color: $usoYellow;
				}
			}
		}
	}

	.favorite-label {
		white-space: nowrap;
	}

	&.white {
		color: $white;
		&:after {
			color: $white;
		}
	}
	&.yellow {
		color: $usoYellow;
		&:after {
			color: $usoYellow;
		}
	}

	&.ltgray {
		color: $usoLtGray;
		&:after {
			color: $usoLtGray;
		}
	}
}

.multi {
	.favorites-toggle {
		margin-top: -40px;
	}
}

.favorites-edit-wrapper{display: flex; width: 10%; align-items: center;
	@include general-breakpoint(smtablet){
		width: 5%;
	}
}

.favorites-edit {
	@include interstate();
	position: relative;
	font-weight: 400;
	color: #4a4a4a;
	text-decoration: none;
	&:hover {
		text-decoration: none;
		color: $usoLtBlue;
	}
}

.favorites-mid-message {
	color: $usoDkGray;
	@include interstate(light);
	position: absolute;
	width: 100%;
	margin: 0 auto;
}

.favorites-button-wrapper {
	@include interstate();
	cursor: default;
	@media screen and (max-device-width: 767px) {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		.view-favorites-button {
			left: 5px;
			position: relative;
			@include interstate(bold);
		}
		.favorites-mid-message {
			position: relative;
			width: auto;
			margin: 0 0 0 5px;
			.hideIcon {
				display: none;
			}
		}
	}
}
