.stpanel-container {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
    background-color: $white;
    width: 100%;
    border: 1px solid $gray_8;

    .match-box .live-buttons {
        .error-indicator { display:none; }
    }
    .stpanel-content {
        width: 100%;
        @include panel-content-background;
        
        @include general-breakpoint(smtablet){
            width: 385px;
        }
        height: 100%;
        background: $white;
        // border: 1px solid $gray_8;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        // overflow: hidden;
        overflow-y: scroll;

        ::-webkit-scrollbar { width: 0 !important } /* Chrome, Safari, Opera */
        -ms-overflow-style: none; /*/Internet Explorer, Edge */
        scrollbar-width: none; /* Firefox 64 */

        &-close {
            width: 100%;
            min-height: 29px;
            height: 29px;
            padding-right: 9px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background-color: $usoltMedGray;

            i {
                color: $gray_4a;
                cursor: pointer;
                @include interstate(bold);
            }
        }
        &-title {
            width: 100%;
            min-height: 57px;
            height: 57px;
            padding: 9px 15px;
            background-color: $white;
            border-top: 1px solid #C1C6C8;
            border-bottom: 1px solid #C1C6C8;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            
            h3 {
                margin-bottom: 0px;
                color: $dk-gray2;
                font-size: 20px;
                letter-spacing: 0.6px;
            }
            .st-link {
                color: $usoLtBlueBG;
                cursor: pointer;
                font-size: 14px;

                &:hover {
                    text-decoration: underline;
                }
                i {
                    font-size: 8px;
                }
            }
        }
        &-ad {
            width: 100%;
            min-height: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &.open {
        display: block;
        z-index: 2147483641;
        // overflow: hidden;
    }

    @include general-breakpoint(landscape) {
        // background-color: rgba(0,0,0,.9);
        background-color: $white;
        width: 100%;
    }

    @include general-breakpoint(smtablet) {
        position: fixed;
        right: 0;
        width: 385px;
        z-index: 10;

        .stpanel-content {
            width: 100%;
        }
    }
}

.slamtracker-prematch {
    width: 100%;
    height: 100%;
    background-image: url("/assets/images/scores/slamtracker/backgrounds/slamtracker-tab-stub.png");
    background-size: cover;

    .message {
        background-color: rgba(0,0,0,.8);
        width: 80%;
        padding: 35px 0;
        margin: 70px auto 0 auto;
        color: white;
        @include interstate(regular);
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}