 /** sponsors page **/
 .sponsorsContainer {
    //  @include make-container();
     @include interstate(light);
     @include media-breakpoint-up(sm) {
        padding-bottom: 30px;
     }
     .sponsorsCol {
        background-color: $white;
        padding: 10px;
        @include media-breakpoint-up(sm) {
            padding-bottom: 20px;
        }        
     }

     h3 {
        display: flex;
        padding:10px 15px;
        position: relative;
        // box-shadow: 0px 8px 15px -18px $usoMdGray;
        border-top: 4px solid $usoDkGray;
        border-bottom: solid 1px $usoltMedGray;
        background-color: $white;
       
        &.no-shadow {
            box-shadow: none;
        }
        align-self: center;
        color: $usoDkGray;
        text-transform: uppercase;
        @include interstate(bold);
        font-size: px2rem(16);
        line-height: 20px;
        margin-bottom: 0;
        &:empty {
            border-top: none;
            margin-top: 0 !important;
        }
     }

     .blue_divide {
        display: none;
        //  width: 80%;
        //  border-bottom: 1px solid $dk-gray2;
        //  margin: 0px auto;
        &+h3 {
            margin-top: 10px;
            @include media-breakpoint-up(sm) {
                margin-top: 20px;
            }                    
        }
     }

     .sponsorImage {
         @include make-col-ready();
     }

     .sponsorText,
     .sponsorContent {
         @include make-col-ready();
         padding: 10px;
         font-size: calc((14/12) * 1em);
         display: inline-block;
         line-height: 22px;

         p {
             padding-bottom: 5px;
             font-size: calc((14/12) * 1em);
         }

         strong {
            @include interstate(bold);
         }

         a {
             @include interstate(bold);
             color: $cyan;
             text-decoration: none;
         }
     }

    //  .sponsorsTable {
    //      width: 100%;
    //      border: none;
    //      display: table;
    //      margin: 15px 0px;

    //      .group {
    //          display: table-row-group;
    //      }

    //      .sponsor-row {
    //          display: table-row;
    //      }

    //      .colLeft,
    //      .colRight {
    //          width: 50%;
    //          text-align: center;
    //          /*display: table-cell;*/
    //          /*float: left;*/
    //          padding: 10px 0px;
    //          vertical-align: middle;

    //          img {
    //              width: 50%;
    //              height: auto;

    //              @include general-breakpoint(landscape) {
    //                  width: auto;
    //                  height: auto;
    //              }
    //          }
    //      }

    //      .colCenter {
    //          width: 100%;
    //          text-align: center;
    //          height: 100%;
    //          padding: 10px;
    //          display: table-caption;
    //          caption-side: bottom;

    //          img {
    //              width: 20%;
    //              height: auto;

    //              @include general-breakpoint(landscape) {
    //                  width: auto;
    //                  height: auto;
    //              }
    //          }
    //      }
    //  }

     .sponsorsTable {
        border: none;
        margin: 10px auto;
        display: flex;
        flex-direction: row;
        width: 75%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        a, span {
            width: 100%;
            @include general-breakpoint(landscape) {
                width: 50%;
            }
            text-align: center;
            margin: 20px 0px;
        }
     }
     &.tvSponsors {
         text-align: center;

         img {
             width: 320px;
             height: auto;

             @include general-breakpoint(landscape) {
                 width: 400px;
             }
         }
     }
 }