

// Root font-size:
$root-font: 16px;

// Sizes
$sizes: (2, 4, 8, 16, 32, 64, 80, 128, 160, 256);

// Buttons
$button-sizes: (xs, sm, md, lg, xl);
$button-font: (12, 14, 20, 24, 32);
$button-padV: (8, 16, 24, 32, 64);
$button-padH: (16, 32, 48, 64, 128);


// spacing, margins, and heights used for layouts
$sb-height: 75px;
$mobile-sb-height: 96px;

$nav-height: 76px;
$nav-width: 385px;
$header-height: $nav-height + $sb-height;

$mobile-nav-height: 56px;
$mobile-nav-width: 280px;
$mobile-header-height: $mobile-nav-height;
$max-mobile-nav-width: 375px;

$mobile-columns: 6;

$footer-height: 100px;
$header-footer-height: $header-height + $footer-height;
$max-content-width: 1440px;
$content-height: calc(100vh - #{$header-height}); 

//$sm-content-margin: 15px;
//$lg-content-margin: 30px;

$xs-content-margin: calc(1.69492% * 2);
$sm-content-margin: 1.69492%;  // not sure how 2018 came up with percentage margin, but using here to match - awt
$lg-content-margin: 1.69492%;

$three-col-gutter-width-sm:18px;
$three-col-gutter-width-lg:24px;

$four-col-gutter-width-sm:12px;
$four-col-gutter-width-lg:24px;

$two-col-gutter-width-sm:18px;
$two-col-gutter-width-lg:24px;

$one-col-gutter-width-sm:18px;
$one-col-gutter-width-lg:24px;

$article-max-content: 992px;




/** -- 2022 setting -- */
$sm-gutter: 16px; // mobile side gutter
$md-gutter: 20px; // normal gutter size

$sm-alt-max-content-width: 608px; // alternate width for 768px breakpoint
$sm-max-content-width: 672px; // for 768px breakpoint
$md-alt-max-content-width: 728px; // alternate width for 768px breakpoint
$md-max-content-width: 970px;
$lg-max-content-width: 1260px;

//------------


$font-dir: '/assets/fonts/'; 

$root-font: 12px;

$header-height: 60px;
$subheader-height: 55px;
$pageheader-height: 83px;
$footer-height: 180px;
$live-bar-min-height: 50px;
$fixed-width: 1280px;
$content-width: 960px;

$mobile-nav-width: 280px;
$mobile-footer-height: 60px; 
$main-menu-width: 320px;

$general-outer-margin: 20px;
$general-inner-margin: 10px;
/***
    margin mapping
    window size     margin size
    ----------------------------
    mobile          small
    tablet          med
    desktop         large
    above 1280      xlarge
*/
$page-margin-small: 16px; 
$page-margin-med: 80px; 
$page-margin-large: 48px; 
$page-margin-xlarge: 68px; 

/* content width
 *  small  - 320px
 *  med    - 768px
 *  large  - 1024px
 *  xlarge - 1280px
 */
$content-width-small: 288px;
$content-width-med: 672px;
$content-width-large: 928px;
$content-width-xlarge: 1216px;

//side margin
$side-margin-small: $page-margin-small;
$side-margin-med: calc((100% - #{$content-width-med}) / 2);
$side-margin-large: calc((100% - #{$content-width-large}) / 2);
$side-margin-xlarge: calc((100% - #{$content-width-xlarge}) / 2);

//column break points
$column-break-1: 768px;
$column-break-2: 1024px;
$column-break-3: 1280px;

// layout/columns
$item-v-margin: 7px;
$item-h-margin: 32px;

// Grid container max sizes
// [md] llg is added to avoid header contents overlapping with each other
$container-max-widths: (
    sm: 289px,
    md: 931px,
    llg: 1110px,
    lg: 1216px,
    xl: 1216px
);

$spacer: 16px;

$sizing: (0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 32, 40, 48, 56, 64, 72, 80);

$link-color: #418FDE;

// Color variables
@import "colors";

// Grid grid-variables
@import "grid/grid-variables";

