.tournament-day-dropdown-selected {
	width: 220px;
	height: 26px;
	font-size: 14px;
	text-align: center;
	border-radius: 3px;
	color: $usoLtBlue;
	border-color: $usoLtBlue;
}

.tournament-day-dropdown-wrapper {
	select {
		@include interstate();
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url('/assets/images/icons/dropdown-arrow.svg');
		background-repeat: no-repeat;
		background-position-x: 94%;
		background-position-y: 50%;
		background-color: $white;
	}
}
