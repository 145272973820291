$thumb_width: 128px;
$thumb_height: 72px;

$side_paddig_sm: 15px;
$side_paddig_md: 46px;
$side_paddig_lg: 48px;

#uso-topStories {
   background: $white;
   clear: both;
   overflow: hidden;

   h3 {
       a, &:hover {
          color: #212529; 
       }
   }

   #uso-topStories-content {
       max-width: 1280px;
       margin: auto;
       padding-left: $side_paddig_sm;
       padding-right: $side_paddig_sm;
       
       @include media-breakpoint-between(md, lg) {
            padding-left: $side_paddig_md;
            padding-right: $side_paddig_md;
       }

       @include media-breakpoint-up(lg) {
           padding-left: $side_paddig_lg;
           padding-right: $side_paddig_lg;
       }
   }

   header {
        color: $dk-gray2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 16px;

       h2 {
            font-size: 36px;
            // font-weight: normal;
       }
    
       time {
            font-size: 16px;
       }
    
       #sponsor {
            color: $md-gray;
            font-size: 10px;
            min-width: 20%;
            padding-top: 15px;

            @include media-breakpoint-up(md) {
                text-align: right;
            }

            .presented_by {
                a {
                    margin-left: 10px;
                }

                img {
                    height: 34px;
                }
            }
       }

       // tablet
       @include media-breakpoint-between(md, lg) {
            align-items: center;
            flex-direction: row;
            padding-top: 36px;

            h2 {
                font-size: 40px;
            }

            time {
                font-size: 20px;
                padding-left: 10px;
                padding-right: 10px;
            }

            #sponsor {
                padding-top: 0;
            }
        }

        // above desktop
        @include media-breakpoint-up(lg) {
            align-items: center;
            flex-direction: row;

            h2 {
                font-size: 48px;
            }

            time {
                font-size: 24px;
            }

            #sponsor {
                font-size: 12px;

                .presented_by {
                    img {
                        height: 34px;
                    }
                }
            }
        }
   } // header

   .uso-stories {
        border-bottom: solid 1px $gray_d8;
        border-top: solid 1px $gray_d8;
        display: flex;
        margin-top: 16px;
        flex-direction: column;
        
        ul.uso-mainStory {
            display: flex;
            list-style: none;
            flex-direction: column;
            margin-bottom: 0rem;

            li {
                display: flex;
                flex-direction: row;
                padding: 16px;

                img {
                    display: block;
                    width: $thumb_width;
                    height: $thumb_height;
                    flex-shrink: 0;
                }

                h3 {
                    font-size: 14px;
                    margin: 0 16px;
                }

                p {
                    display: none;
                }

                .uso-btn {
                    a, a:hover {
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
        } // uso-mainStory
        
        ul.uso-storyList {
            display: flex;
            flex-direction: column;
            list-style: none;

            li {
                border-top: solid 1px $gray_d8;
                display: flex;
                flex-direction: row;
                padding: 16px;

                img {
                    width: $thumb_width;
                    height: $thumb_height;
                    flex-shrink: 0;
                }

                h3 {
                    font-size: 14px;
                    margin: 0 16px;
                }
            }
        }  // uso-storyList

        // tablet
        @include media-breakpoint-between(md, lg) {
            margin-top: 30px;
            flex-direction: row-reverse;

            ul.uso-mainStory {
                flex-basis: calc((4/8)*100%); // (gridNums/totalGridNum)*100% <-- calculate the percentage for the container
                margin-bottom: 1rem;

                li {
                    flex-direction: column;
                    padding-top: 0px;
                    padding-right: 0px;

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                        flex-shrink: 0;
                    }

                    h3 {
                        display: none;
                    }
    
                    p {
                        display: block;
                    }

                    .desc {
                        color: $md-gray;
                        font-size: 16px;
                        padding: 32px;
                        margin-bottom: 0px;

                        p {
                            padding: 0;
                            margin: 0;
                        }
                    }

                    .uso-btn {
                        margin: 0 auto;
                    }
                }
            }

            ul.uso-storyList {
                flex-basis: calc((4/8)*100%); // (gridNums/totalGridNum)*100% <-- calculate the percentage for the container

                li {
                    &:first-child {
                        border-top: none;
                    }
                }
            }

        } // media-breakpoint-between(md, lg)

        @include media-breakpoint-between(lg, xl) {

            ul.uso-mainStory {
                flex-basis: calc((8/12)*100%); // (gridNums/totalGridNum)*100% <-- calculate the percentage for the container

                li {
                    padding-top: 0px;
                    padding-right: 0px;
                    
                    .mainStoryContent {
                        position: relative;
                    }

                }
            }

            ul.uso-storyList {
                flex-basis: calc((4/12)*100%); // (gridNums/totalGridNum)*100% <-- calculate the percentage for the container

                li {
                    &:first-child {
                        border-top: none;
                    }
                }
            }

        } // media-breakpoint-between(lg)

        @include media-breakpoint-up(xl) {
            margin-top: 30px;
            flex-direction: row-reverse;

            ul.uso-mainStory {
                flex-basis: calc((8/12)*100%); // (gridNums/totalGridNum)*100% <-- calculate the percentage for the container
                margin-bottom: 1rem;

                li {
                    flex-direction: column;
                    padding-top: 0px;
                    padding-right: 0px;

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                        flex-shrink: 0;
                    }

                    h3 {
                        color: $white;
                        display: block;
                        font-size: 40px;
                        line-height: 1.0em;
                        margin: 0 32px 25px 32px;
                        position: absolute;
                        bottom: 100%;
                        text-shadow: 2px 2px 2px $black;
                        a, a:hover {
                            color: $white;
                            text-decoration: none;
                        }
                    }
    
                    p {
                        display: block;
                    }

                    .desc {
                        color: $md-gray;
                        font-size: 16px;
                        width: 80%;
                        padding: 32px;
                    }


                    .mainStoryContent {
                        position: relative;
                        display: flex;
                    }

                    .uso-btn {
                        margin: auto;
                        padding: 0 15px;
                    }
                }
            }

            ul.uso-storyList {
                flex-basis: calc((4/12)*100%); // (gridNums/totalGridNum)*100% <-- calculate the percentage for the container

                li {
                    &:first-child {
                        border-top: none;
                    }
                }
            }

        } // media-breakpoint-up(xl) 
        .uso-top-video {
            position: relative;
            cursor: pointer;
            &.story-video {
                .playBtn {
                    margin-top: -20px;
                    margin-left: -18px;
                    width: 40px;
                    height: 40px;
                    background-size: 40px;            
                }
            }
            @include media-breakpoint-down(md) {
                .playBtn {
                    margin-top: -20px;
                    margin-left: -18px;
                    width: 40px;
                    height: 40px;
                    background-size: 40px;            
                }
            }
        }

   } // uso-stories

    .uso-storyFooter {
        font-size: 14px;
        padding: 14px 0;
        text-align: right;
        
        a {
            text-decoration: none;
        }

        @include media-breakpoint-between(lg, xl) {
            font-size: 24px;
            padding: 24px 0;
        }

        @include media-breakpoint-up(xl) {
            font-size: 24px;
            padding: 24px 0;
        }

    } // uso-storyFooter
        
} // uso-topStories