

/** ButtonTextLink styles */
.button-text-link {
	
	button{
		all: unset;
	}

	font-family: inherit;
	font-size: inherit;
	color: $usoLtBlueBG;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	&:focus-visible{
		outline: -webkit-focus-ring-color auto 1px;
	}

	&.center {
		text-align: center;
	}

	&.bold {
		font-weight: bold;
	}

	&.disabled{
		cursor:not-allowed;
		pointer-events:none;
		opacity: 0.6;
	}
}