/*** chip wall ***/
#hpChips, #newsChips, #generalChips { width: 100%;position: relative; background-color: $lt-gray;
    padding-top: 30px; padding-bottom: 15px;
    @include general-breakpoint(landscape) {
      padding-top: 50px; padding-bottom: 20px;
    }
  }
  /*** Mass Relevance Social Chips ***/
  #hpSocial { float: left; width: 100%; position: relative; background-color: $lt-gray;
    .social { max-width: 1280px; margin: auto; }
  }
  /*** Ad hoc chip container ***/
  .chip-container {

    margin-top: 30px;
    position: relative;

    &.content-border {
        border-top: solid 4px $usoLtBlue;

        &-dkblue {
            border-color: $usoDkBlue;
        }

        &-blue {
            border-color: $usoLtBlue;
        }

        &-ltblue {
            border-color: $usoLtBlue;
        }

        &-green {
            border-color: $usoGreen;
        }

        &-yellow {
            border-color: $usoYellow;
        }

        &-orange {
            border-color: $usoOrange;
        }

        &-red {
            border-color: $usoRed;
        }
    } // .homeContent-border

    h2 {
        display: flex;
        padding:10px 15px;
        position: relative;
        // box-shadow: 0px 8px 15px -18px $usoMdGray;
        border-bottom: solid 1px $usoltMedGray;
        background-color: $white;
       
        &.no-shadow {
            box-shadow: none;
        }
        align-self: center;
        color: $usoDkGray;
        text-transform: uppercase;
        @include interstate(bold);
        font-size: px2rem(16);
        line-height: 20px;
        margin-bottom: 0;
    }

    width: 100%;
    position: relative;
    background-color: $lt-gray;
    // padding-top: 30px;
    padding-bottom: 15px;
    @include general-breakpoint(landscape) {
      // padding-top: 40px;
      padding-bottom: 20px;
    }
    .chip_content {
      max-width: 1280px;
      margin: auto;
    }
    // h2,
    h3 {
      margin: 0 auto 8px;
      padding: 0;
      text-align: left;
      @include interstate(light);
      color: $dk-gray2;
      width: 100%;
      max-width: 300px;
      font-size: calc((28/11) * 1em);
      @include general-breakpoint(landscape) {
        max-width: 634px;
      }
      @include general-breakpoint(tablet) {
        font-size: calc((32/11) * 1em);
        max-width: 802px;
      }
      @include general-breakpoint(desktop) {
        max-width: 1220px;
      }
    }
  
  }
  /*** Ad hoc chip container ***/
  // .chip-container {
  //   width: 100%;
  //   position: relative;
  //   background-color: $lt-gray;
  //   padding-top: 30px;
  //   padding-bottom: 15px;
  //   @include general-breakpoint(landscape) {
  //     padding-top: 40px;
  //     padding-bottom: 20px;
  //   }
  //   .chip_content {
  //     max-width: 1280px;
  //     margin: auto;
  //   }
  //   h2,
  //   h3 {
  //     margin: 0 auto 8px;
  //     padding: 0;
  //     text-align: left;
  //     @include interstate(light);
  //     color: $dk-gray2;
  //     width: 100%;
  //     max-width: 300px;
  //     font-size: calc((28/11) * 1em);
  //     @include general-breakpoint(landscape) {
  //       max-width: 634px;
  //     }
  //     @include general-breakpoint(tablet) {
  //       font-size: calc((32/11) * 1em);
  //       max-width: 802px;
  //     }
  //     @include general-breakpoint(desktop) {
  //       max-width: 1220px;
  //     }
  //   }
  // }
  /*** Hide type on news page b/c they're all the same type ***/
  #newsChips {
    .photoContainer {
      .type { display: none; }
    }
  }
  .chip_content{ max-width:1280px; text-align: center; margin: auto;
    // @include general-breakpoint(tablet) { text-align: left; }
    a, a:hover{ text-decoration: none; }
    .msg { margin: 0 auto; text-align: center; }
    .chip{
      // cursor: pointer;
      background-color: $white;
      width: 300px;
      margin: 10px auto;
      height: 404px;
      @include general-breakpoint(landscape){ margin: 17px; }
      @include general-breakpoint(tablet){width: 383px; height: 426px; }
      display: inline-block;
      position: relative;
      overflow: hidden;
      vertical-align: bottom;
      border: 1px solid $dk-gray;
      .photoContainer{
        width: 100%;
        height: 42.04%;
        @include general-breakpoint(tablet){
          height: 50.5%; /* 16:9 ratio */
        }
        position: relative;
        .playBtn{
          position: absolute;
          background: url(/assets/images/misc/video_play_icon.png) no-repeat 0 0;
          top: 50%;
          left: 50%;
          margin-top: 0px;
          margin-left: 0px;
          width: 40px;
          height: 40px;
          background-size: 40px;
          z-index: 1;
          transform: translate(-50%, -50%);
        }
        img{height: 100%; width: 100%;}
        .type{
          position: absolute;
          top: 12px;
          left: 12px;
          color: white;
          @include interstate(bold);
          font-size: 1.2rem;
          line-height: 1.2rem;
          text-transform: capitalize;
        }
      }
      .caption{
        text-align: left;
        color: $dk-gray2;
        @include interstate(regular);
        // overflow: hidden;
        max-height: 5.4rem;
        line-height: 2.2rem;
        font-size: 1.3rem;
        letter-spacing: 0;

        // @include interstate(bold);
        font-size: px2rem(16);
        line-height: 19px;
        // @include general-breakpoint(tablet){
        //   max-height: 6.4rem;
        //   line-height: 2.6rem;
        //   font-size: 2.2rem;
        // }
        padding: 16px 16px 0px 16px;
        margin-bottom: 17px;
      }
      .date, .credit, .description {
        text-align: left;
        color: $dk-gray2;
        @include interstate(light);
        padding: 0 16px;
        font-size: 1.3rem;
      }
      .description {
        margin-top: -12px;
        font-size: .9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.2rem;
        line-clamp: 7;
        -webkit-line-clamp: 7;
        @include general-breakpoint(tablet){
          line-clamp: 6;
          -webkit-line-clamp: 6;
        }
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: 1.5rem;
        // @include general-breakpoint(tablet){ font-size: 1.1rem; }
      }

      
      .uso-button{
        position: absolute;
        // margin: 0 auto;
        border-radius: 7px;
        background-color: #418FDE;
        color: #fff !important;
        font-size: 14px;
        cursor: pointer;
        width: calc(100% - 30px);
        max-width: 100%;
        bottom: 15px;
        left: 15px;
        border: 2px solid transparent;
        &:hover{
          background-color: #fff;
          color: #418FDE !important;
          border: 2px solid $usoLtBlueBG;
        }
      }     
      &.ad{ background-color: transparent; height: 50px; width: 100%; margin: 17px 0; border: none; text-align: center;
        @include general-breakpoint(smtablet){ background-color: $white; height: 300px; width: 300px; margin: 17px; }
        @include general-breakpoint(tablet) { height: 384px; width: 384px;}
        & > div{
          top: 50%;
          transform: perspective(1px) translateY(-50%);
          position: relative;
        }
      }
      &.expanded{ /*background-color: green;*/ width: 300px;
        @include general-breakpoint(landscape) { width: 620px; }
      }
      &.news {
        .photoContainer{ width: 100%; height: 56.25%; /* 16:9 ratio */ text-align: center; overflow: hidden;
          .title { color: $white; font-size: 24px; @include interstate(); display: block; position: relative; bottom: 40px; text-align: left; padding: 5px 10px; z-index: 2; }
          img{ position: relative; left: 100%; margin-left: -200%; display: inline-block !important; width: initial;}
        }
        .caption{color: $white; font-size: 17px; @include interstate(); display: block; position: relative; bottom: 65px; text-align: left; padding: 5px 10px; z-index: 2;}
        .date{ @include interstate(); color: $white; font-size: 14px; position: relative; bottom: 85px; text-align: left; padding: 5px 10px;z-index: 2;}
    }

    //new chip styles for 2022
    &.photo-chip {
      .caption { 
        @include interstate(regular); 
        font-size: px2rem(12); 
        position:relative; 
        line-height: px2rem(16); 
        height: 107px; 
        max-height: 107px;
        @include general-breakpoint(tablet, max){
          height: 54px; 
          max-height: 54px;
          line-clamp: 3;
          -webkit-line-clamp: 3;
        }
        padding: 10px 16px 0 16px;        
        text-overflow: ellipsis;
        line-clamp: 6;
        -webkit-line-clamp: 6;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
        overflow: hidden;
        // &:after{
        //   content: "";
        //   text-align: right;
        //   position: absolute;
        //   bottom: 0;
        //   right: 0;
        //   width: 40%;
        //   height: px2rem(16);
        //   background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
        // }
      }
      .credit {@include interstate(light); font-size: px2rem(11); line-height: 14px;}
      .date {display:none;}
    }
    &.video-chip {
      i {
        display:block; 
        top: 50%;
        left: 50%; 
        transform: translate(-50%, -50%);
      }

      // @include general-breakpoint(tablet) {
      //   i {top:25%}
      // }
    }

    .tile-footer {
      display:flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      height:43px;
      justify-content: space-between;
      padding:0px 20px;
      background-color: $usoBkgGray;
      position: absolute;
      bottom:0px;
      left:0px;
      width:100%;
      border-top:solid 1px $usoLtGray;

      i {display: block; color: #4a4a4a; font-size: 1.75rem;position: relative;left: auto;right: auto;transform: none;top: auto;}
      .subtype {@include interstate(bold); font-size: px2rem(12); color: $usoDkGray; text-transform: uppercase; letter-spacing: 2.4px; }
    }

    .videoImg{
        // .playBtn {
        //   position: absolute; background: url('/assets/images/misc/video_play_icon.png') no-repeat 0 0;top: 50%;left: 50%; margin-top: -50px; margin-left: -50px; width: 75px; height: 75px; background-size: 75px; z-index: 1;
        // }
      }
    }
    .sm_chip{ width: 125px; margin: 10px 8px; height: 104px; display: inline-block; position: relative; overflow: hidden;
      @include general-breakpoint(landscape) { width: 140px; height: 117px; }
      img{ width: 100%; height: 100%;}
    }
    &.guide_chips{
      .chip{
        .title { line-height: .9em; }
        .date { bottom: 77px;}
      }
  
    }
  }
  
  #hpLoad, #newsLoad, #vloadMore { clear: both; margin-bottom: 30px; overflow: hidden; background-color: $lt-gray;
    padding-bottom: 30px;
    a { display: block; width: 240px; margin: 0 auto; text-decoration: none;}
    .label {
      margin: 0px auto;
      width: 128px;
      height: 40px;
      font-size: 1.2em;
      line-height: 40px;
      background: #418FDE;
      color: #FFFFFF;
      font-family: Helvetica,Arial,sans-serif;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      border: none;
    }
    a:hover {
      .label {
        color: $white;
      }
    }
    &.photos { padding-top: 30px; background-color: $white;}
  }
  .playBtn { position: absolute; background: url('/assets/images/misc/video_play_icon.png') no-repeat 0 0;top: 50%;left: 50%; margin-top: -50px; margin-left: -50px; width: 75px; height: 75px; background-size: 75px; z-index: 1;
  }

  .news_sub{
    background-image: url('/assets/images/misc/newsletter-bkg.png');
    .news_input{ 
      color: $white; 
      font-size: 1rem;
      line-height: 1rem;
      //padding: 12px;
      //@include general-breakpoint(tablet){
        padding: 32px;
      //}
      h3{
        @include interstate(regular);
        color: $uso-lt-blue;
        text-transform: uppercase;
        font-size: 1.9rem;
          line-height: 2.3rem;
        @include general-breakpoint(tablet){
          font-size: 3rem;
          line-height: 3rem;
        }
      }
      p{
        @include interstate(regular);
        margin-bottom: 0;
        font-size: 1.3rem;
          line-height: 1.7rem;
        @include general-breakpoint(tablet){
          font-size: 1.6rem;
            line-height: 2rem;
        }
      }
      div[class^="col-"], div[class*="col-"] {
        min-height: 140px;
        @include general-breakpoint(tablet){
          min-height: 160px;
        }
      }
    }
    .newsEmail{  
      padding: 5px 0;
      /*@include general-breakpoint(tablet){
        padding: 5px; 
        position: relative;
        top: 50%;
        transform: perspective(1px) translateY(-50%);
      }*/ //old, pre chip config
      legend { display:none; }
      input[type=text]{
        background-color: #FFFFFF;
        border: none;
        font-size: 1.2rem;
        text-align: center;
        margin-bottom:12px;
        color: #888B8D;
        padding: 4px 0 6px 0;
        width: 100%;
        height: 30px;
        @include general-breakpoint(tablet){
          //margin: -1px -4px 0px 5px;
          //width: 80%;
          margin: 32px 0 32px 0;
        }
      }
      .btnSubmit{ 
        display: block;
        margin: 0 auto;
        /*@include general-breakpoint(tablet){
          display: inline-block;
          margin: 0;
          width: 15%;
          padding: 7px 0;
        }*/ // old, pre chip config
        background-color: #418FDE;
        color: #FFFFFF;
        border: none;
        font-size: 0.9rem;
        cursor: pointer;
        padding: 7px 32px;
        text-transform: uppercase;
        height: 30px;
      }
    }
  }