@mixin data-tab-width($numColumns: 1, $totalWidth: 100%) {
    width: calc(#{$totalWidth} / #{$numColumns})
}

.data-tabs {
    width: 100%;
    //text-transform: uppercase;
    text-align: center;
    background-color: $datatab-primary-bg; //$usoDkBlue;
    color: $white;
    margin: 0px auto;
    height:33px;
    line-height: 33px;
    font-size: px2rem(14);
    @include interstate;
    display:flex;
    flex-direction: row;
    justify-content: center;
    .tab {
        cursor: pointer;
        height:100%;
        padding:0px 10px;
        color: $white;
        font-weight:300;
        &:first-child {
            border-left:none;
        }
        &.show {
            //color: $white;
            @include interstate(bold);
            span {
                border-bottom: solid 3px $white;
                display:inline-block;
                height:100%;
            }
        }
        &:hover {
            //color: $white;
            //@include interstate(bold);
            font-weight:500;
        }
    }

    &.secondary {
        margin-top:0px;
        padding-top:20px;
        height:60px;
        background-color: $datatab-secondary-bg;
        border-top: solid 1px #979797;
        font-size:px2rem(12);
    }
}