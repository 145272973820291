.help-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 40, 140, .8);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.webview {
        background-color: transparent;
        z-index: 0;
    }
    
    .help-modal-inner {
        position: static;
    }
    .modal-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        max-width: 335px;
        min-height: 220px;
        background-color: $usoBkgGray;
        position: relative;
        border-radius: 7px;
        // filter: drop-shadow(0px 2px 4px rgba(0,0,0, 0.5));

        .modal-close{
            font-size: 14px;
            position: absolute;
            right: 11px;
            top: 11px;
            cursor: pointer;
            z-index: 2
        }
        .modal-body{
            @include ibmplexsans();
            font-size: 14px;
            color: $black;
            letter-spacing: 0;
            text-align: left;
            line-height: 18px;         
            padding: 20px 35px 20px 20px;   
            padding-bottom: 30px;
        }
    }

    &.android {
        display: block;
        top: 10px;

        .modal-container{ 
            margin: auto;
        }
    }
}

.webview {
    .wrapper.info-overlay {
        &.android {
            // background-color: rgba(0, 40, 140, .8);
            height: 500px;
        }
    }
}