/** scorebox-grid styles **/
.column-layout.scoresgrid {
    height: auto;
    min-height: 457px;
    width: 100%;
    //max-width: $fixed-width;
    margin:0px auto;
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top:30px;
    //padding-left:0px;
    //padding-right:0px;
    background-color: $lt-gray2;
    
    .one-col {
        width: calc(100% - #{$item-h-margin});
        margin: 0px auto 37px;
        margin-left: calc(#{$item-h-margin} / 2);
        margin-right: calc(#{$item-h-margin} / 2);
    }

    @include general-breakpoint(landscape) {
        flex-direction: row;
        
        .one-col {
            flex: 0 0 auto;
            width:calc(50% - #{$item-h-margin});
        }
    }

    @include general-breakpoint(desktop) {
        .one-col {
            width:calc(33.3% - #{$item-h-margin});
        }
    }
}
