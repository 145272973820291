.news-content {
	width: 100%;
	min-height: 500px;
	overflow: hidden;
}

// .site_content {
//     width: 100%;
//     min-height: 500px;
//     overflow: hidden;
// }

.news-tile {
	color: black;
	width: 100%; //height:275px;
	position: relative;
	// cursor: pointer;
	// padding-top: 50px;
	padding-bottom: 20px;
	min-height: 500px;
	overflow: hidden;
	background-color: transparent;

	.newsChips.related-cnt {
		.chip_content {
			.caption:hover {
				color: $usoLtBlue;
			}
			.chip,
			.news-ad {
				margin: 15px 7px;
			}
			.chip.ad {
				.adunitContainer {
					text-align: center;
				}
			}
		}
	}
	.modal-overlay-div {
	}
	.modal-content-div {
		.modal-dialog-div {
			width: 100%;
			height: 100%;
			@include general-breakpoint(smtablet) {
				width: 96%;
				height: 85%;
			}
			// @include general-breakpoint(smdesktop) {
			//   width: 97%;
			//   height: 70%;
			// }
			.close-icon {
				z-index: 2147483646;
				position: absolute;
				margin-top: 20px;
				margin-right: 20px;
				right: 0;
				color: $white;

				@include general-breakpoint(smtablet, max) {
					text-align: center;
					float: left;
					width: 36px;
					height: 36px;
					border-radius: 32px;
					margin-right: 7px; /*space between*/
					background-color: black;
				}

				@include general-breakpoint(smtablet, max) {
					margin-top: 8px;
				}

				i {
					font-size: 18px;
					color: white;
					@include interstate(bold);
					@include general-breakpoint(smtablet, max) {
						line-height: 38px;
					}
				}
			}

			.fullscreenable {
				.amp-player {
					@include general-breakpoint(smtablet, max) {
						background: black;
					}
				}
			}
		}
	}
	.newsChips {
		max-width: 1280px;
		text-align: center;
		margin: auto;
		flex-direction: column;
		flex: 1;
		.chip_content {
			.chip,
			.news-ad {
				background-color: #ffffff;
				margin: 14px 10px;
				width: 350px;
				height: 350px;
				@include general-breakpoint(smtablet) {
					width: 296px;
					height: 296px;
				}
				@include general-breakpoint(desktop) {
					width: 392px;
					height: 392px;
				}
				//@include general-breakpoint(tablet){width: 384px; height: 384px; }
				display: inline-block;
				position: relative;
				overflow: hidden;
				vertical-align: bottom;
				text-align: left;
				cursor: pointer;

				.caption {
					padding: 20px 16px 0px 16px;
					min-height: 60px;
					font-size: px2rem(14);
				}

				.date,
				.credit {
					font-size: px2rem(12);
					line-height: 1;
				}

				@include general-breakpoint(desktop, max) {
					.caption {
						padding: 10px 16px 0px 16px;
						margin-bottom: 13px;
						line-height: 1;
						min-height: 40px;
					}
				}
				@include general-breakpoint(smdesktop, max) {
					.caption {
						min-height: 40px;
					}
				}
				@include general-breakpoint(smtablet){
					.caption{
						text-overflow: ellipsis;
						overflow: hidden;
						-webkit-box-orient: vertical; 
						-webkit-line-clamp: 2;
						display: -webkit-box;
					}
				}
				&.hovering {
					border: 1px solid #2478cc;
					transform: translate(-2px, -2px);
					box-shadow: 0 2px 4px $gray-light;
				}
			}
			
			.chip {
				&.photo-chip {
					cursor: default;
				}
			}

			.news-ad {
				//text-align: center !important;
				@include general-breakpoint(landscape, max) {
					width: 320px;
					height: 50px;
					padding: 0px;
				}

				.adunitContainer {
					@include general-breakpoint(desktop) {
						.caption {
							padding: 3px;
						}
					}
				}
				@include general-breakpoint(desktop) {
					display: inline-flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		.photoContainer {
			width: 100%;
			height: 56.25% !important;
			position: relative;
		}
		.playBtn {
			position: absolute;
			background: url('/assets/images/misc/video_play_icon.png') no-repeat 0 0;
			top: 33%;
			left: 50%;
			margin-top: -50px;
			margin-left: -50px;
			width: 75px;
			height: 75px;
			background-size: 75px;
			z-index: 1;
		}

		&.video-tag {
			user-select: none;
			-webkit-tap-highlight-color: transparent;
			i {
				display: block;
			}
		}

		.new-tag {
			text-transform: uppercase;
			width: 50px;
			height: 15px;
			color: $black;
			text-align: center;
			font-size: 10px;
			position: absolute;
			top: 0px;
			padding-top: 1.5px;
		}

		.type-tag {
			color: $white;
			text-transform: uppercase;
			height: 15px;
			line-height: 15px;
			font-size: 10px;
			padding: 0 10px;
			text-align: center;
			position: absolute;
			right: 0px;
			top: -7px;
			z-index: 5;
			white-space: nowrap;
		}

		.duration-tag {
			width: 45px;
			height: 15px;
			position: absolute;
			right: 0px;
			color: $white;
			margin-top: -15px;

			font-size: 10px;
			text-align: center;
			line-height: 14px;
		}

		i {
			color: $white;
			font-size: 56px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: none;
		}

		img {
			width: 100%;
			position: absolute;
			// top: 50%;
			transform: translateY(0%);
		}
		&.photoContainer {
			width: 100%;
			height: 56.25%;
			position: relative;
		}

		.image-container {
			position: relative;
			width: 100%;
			padding-bottom: 66.66%;
			height: 0px;
			background-color: $white;
			background-size: cover;
			overflow: hidden;

			&.wide {
				padding-bottom: 56.25%;
			}
		}
		&.video-tag {
			.image-container {
				box-shadow: inset 0 0 0 1px black;
			}
		}
		a {
			color: #007bff;
			text-decoration: none;
			background-color: transparent;
		}

		.news-timestamp {
			text-align: left;
			color: #afafaf;
			font-family: 'interstate', Helvetica, Arial, sans-serif;
			font-weight: 400;
			padding-left: 16px;
			font-size: 1.3rem;
			padding-top: 16px;
			padding-right: 16px;
			padding-bottom: 0px;
			padding-left: 16px;
			letter-spacing: 1px;
		}
		.news-title {
			color: #afafaf;
			font-family: 'interstate', Helvetica, Arial, sans-serif;
			font-weight: 500;
			overflow: hidden;
			max-height: 6.4rem;
			line-height: 2.6rem;
			font-size: 2.2rem;
			padding-top: 16px;
			padding-right: 16px;
			padding-bottom: 0px;
			padding-left: 16px;
			line-height: 1.3em;
			//overflow: hidden;
			letter-spacing: 1.5px;
		}
		.news-author {
			text-align: left;
			color: #afafaf;
			font-family: 'interstate', Helvetica, Arial, sans-serif;
			font-weight: 400;
			font-size: 1.3rem;
			padding-top: 16px;
			padding-right: 16px;
			padding-bottom: 0px;
			padding-left: 16px;
			line-height: 1.3em;
			//overflow: hidden;
			letter-spacing: 1.5px;
		}
		.news-ad {
			text-align: center !important;
			.adunitContainer {
				@include general-breakpoint(desktop) {
					.caption {
						padding: 3px;
					}
				}
			}
		}
		// .news-ad {
		//     width: 100%;
		//     background-color: transparent;
		//     margin: 17px auto;
		//     height: auto;
		//     @include general-breakpoint(smtablet){ width: 300px; height: 300px; margin: 17px; }
		//     @include general-breakpoint(tablet){width: 384px; height: 384px; }
		//     display: inline-block;
		//     position: relative;
		//     overflow: hidden;
		//     vertical-align: bottom;
		//     border: none;
		// }
		.load-more-button {
			margin: 0px auto;
			width: 128px;
			height: 40px;
			font-size: 1.2em;
			line-height: 40px;
			background: #418fde;
			color: #ffffff;
			font-family: Helvetica, Arial, sans-serif;
			text-transform: uppercase;
			text-align: center;
			cursor: pointer;
			border: none;
		}
	}

	.no-news {
		@include interstate();
		width: 100%;
		position: relative;
		background-color: #f3f3f3;
		padding-top: 50px;
		padding-bottom: 20px;
		text-align: center;
		min-height: 500px;
	}

	.lazy-placeholder {
		.image-container {
			//background-color: $gray-300;
		}
	}
}
