html {
    @include wh(100%, 100%);
    font-size: .75em;
    -webkit-text-size-adjust: none;
    /* Prevent font scaling in landscape while allowing user zoom */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; // http://jaydenseric.com/blog/css-font-smoothing-for-firefox-mac
}

body {
    @include wh(100%, 100%);
    font-family: Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color:transparent;
    // @include general-breakpoint(desktop) {
    //     background: transparent;
    // }
}

.skip-nav {
    display: block;
    height: 0;
    margin: 0;
    overflow: hidden;
    width: 0;
}

.clear-decorate {
    text-decoration: none;
    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}

#uso-header {
    &.sticky {
        position:fixed;
        top:0;
        left:0;
       
        ~ .page-wrap {
            margin-top: $header_height;
        }
    }
}

.content-body.home{
    .topAd{
        margin-bottom: 30px;
    }
}

.topAd{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    background: transparent!important;
    &:empty {
        display: none;
    }
    @include scores-ad-breakpoint(medium) {
        min-height:110px;
        margin:20px 0px;
    }
}

.outer-wrap {
    position: relative;
    width: 100%;
    height: auto;
    overflow-x: hidden;
    min-height: 100vh;

    .page-wrap {
        position: relative;
        width: 100%;
        z-index: 100;
        //margin-top: $header-height;
        display: block;
        min-height: 200px;
        @include general-breakpoint(desktop) {
            min-height: calc( 100vh - 190px);
            height: 100%;
            display: flex;
            flex-direction: row;
            overflow: hidden;
        }
        &.blue {
            background-color: $blue;
        }
        .content-wrap {
            width: 100%;
            /*&.focus {
                // background-color: yellow; @include transition-timing-function(ease-in);
            } */
            @include general-breakpoint(tablet) {
                width: 100%;
            }
            @include general-breakpoint(desktop) {
                height: 100%;
                display: block;
                float: left;
                width: $content-width;
                position: relative;
                left: 0;
                -webkit-box-ordinal-group: 2;
                -moz-box-ordinal-group: 2;
                /* ie9 - css flexbox support*/
                flex: 1 auto;
                -webkit-order: 2;
                -ms-flex-order: 2;
                order: 2;
            }
            &.blue {
                background-color: $blue;
            }
        }
    }
}

#root{
    background-attachment: fixed; background-size: cover;  background-position: center;background-repeat: repeat-y; // background image info for all pages
}

#uso-main {
    width: 100%;
    margin: 0 auto;
    background-color: transparent;
    // padding-bottom: 10px;
    color: $usoDkGray;
    min-height: calc(100vh - 300px);
    // display: flex;
    justify-content: center;
    align-items: center;
    // .row {
    //     min-height: 100px;
    // }

    // .table {
    //     width: 75%;
    //     margin: 0 auto;
    // }
    .title {
        // font-size: xx-large;
    }
    .clear-bottom {
        margin-bottom: 35px;
    }
    h1.header{ 
        display: none; //removed - added to subheader; add attribute to subheader_attributes: page_header - amb07162022
    }
    // .header-wrapper{ width: 100%; padding-bottom: 25px;
    //     h1.header{
    //         height:auto; line-height: auto; text-align: left; padding: 25px 0 17px 16px; padding-left: 5%; font-size: 2.8rem; background-color: $white;
    //     }
    // }

    .wrapper, .page-content {
        position: relative; margin: 0px auto; 

        @include general-breakpoint(mobile){
            // padding: 0px 16px;
        }

        @include general-breakpoint(landscape){
            max-width: $sm-alt-max-content-width;
        }

        @include general-breakpoint(smtablet){
            max-width: $md-alt-max-content-width;
        }

        @include general-breakpoint(smdesktop){
            max-width: $md-max-content-width;
        }

        @include general-breakpoint(desktop){ 
            max-width: $lg-max-content-width;
        }
    }
}

#content {
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-size: 1.2rem;

    @include general-breakpoint(landscape) {
      @include make-container();
    }
    p {
      &:first-child { margin-top: 1rem;}
    }
}
.gray {
    background-color: $lt-gray;
}

.white-bg {
    background-color: $white;
}

.content-main {
    width: 100%;
    position:relative;
    display: block;
    background-color:transparent;
    @include clearfix;
    min-height: calc(100vh - 306px);

    .contentTitle{ color: $dk-gray2; max-width: 1280px; width: 100%; margin-bottom: 30px; font-size: 24px; @include interstate(light); 
        @include general-breakpoint(smtablet){ font-size: 32px;}
    }

    .error-indicator {
        color:$dk-gray2;
        @include interstate;
        font-size:16px;
        margin:40px auto;
        max-width: 1280px;
    }
}

.general_content {
    //image transition
    .image-wrapper{
        overflow: hidden !important;
        img {
            transition: transform 0.5s ease;
            &:hover {
                transform: scale(1.15);
            }
        }
    }
}

/** set max-width content wrapper - 2022 */
.content-body, .uso-native {
    display: flex;
    width: 100%;
    margin: auto;
    position: relative;

    @include general-breakpoint(smtablet) {
        max-width: $sm-max-content-width;
    }

    @include general-breakpoint(smdesktop) {
        max-width: $md-max-content-width;
    }

    @include general-breakpoint(desktop) {
        max-width: $lg-max-content-width;
    }

    //remove margins need full width for mobile designs - amb06022022
    // @include general-breakpoint(smtablet, max) {
    //     /** up till 767px. set the side margin */
    //     margin: auto $sm-gutter;
    // }

    .left-col {
        /** for home page, only used for aboe 1280px */
       width: cp(220px, $lg-max-content-width);
       margin-right: $md-gutter;
    }

    .main-col {
        width: 100%;

        a{ text-decoration: none;
            &:hover{ text-decoration: none; color: $bright-blue!important; cursor: pointer;}
        }

        //image transition
        .image-wrapper{
            overflow: hidden !important;
            img {
                transition: transform 0.5s ease;
                &:hover {
                    transform: scale(1.15);
                }
            }
        }

        .acv {
            .image-wrapper {
                position: relative;
                cursor: pointer;
            }
            .captionContainer {
                .caption {
                    &.video, .video-description {
                        cursor: pointer;
                    }
                }
            }
        }

        // gutter not needed full width for mobile designs - amb06022022
        @include general-breakpoint(landscape){
            width: calc(100% - (#{$sm-gutter} * 2));
        }

        @include general-breakpoint(smdesktop) {
            width: cp(640px, $md-max-content-width);
        }

        @include general-breakpoint(desktop) {
            width: cp(701px, $lg-max-content-width);
        }
    }

    .right-col {
        /** right col is used 1024 and above */
        width: cp(300px, $md-max-content-width);
        margin-left: $md-gutter;

        @include general-breakpoint(desktop) {
            width: cp(310px, $lg-max-content-width);
        }
    }
}


/* espn button style */
.espn-frame-link{
	display: flex;
    justify-content: center;
    padding: 20px 0;
}

.mobile-only {
    @include general-breakpoint(smtablet) {
        display: none;
    }
}

.above-tablet {
    @include general-breakpoint(tablet, max) {
        display: none;
    }
}

.above-desktop {
    display: none;

    @include general-breakpoint(smdesktop) {
        display: block;
    }
}

.below-desktop {
    display: none;
    
    @include general-breakpoint(smdesktop, max) {
        display: block;
    }
}

/** vertically align center flex element */
.vmiddle {
    align-self: center;
}

.react-ecp .amp-overlays{
    .amp-captioning{
        .amp-caption-area{
            @include general-breakpoint(landscape, max){
                margin-bottom: 30px;
            }
            .amp-caption{
                background: black;
                font-size: 1.6rem;
                @include general-breakpoint(landscape, max){
                    font-size: 2.1rem;
                }
            }
        }
    }
}


.difficult-color {
	color: $difficult;
}

.favorable-color {
	color: $favorable;
}

.neutral-color {
	color: $neutral;
}

/** anyclip override **/
#anyclip-float-container {
    .acw {
      .ac-floated-player {
        z-index: 2147483650 !important;
      }
    }
  }

// .uso-content { 
//     font-size: 1.3rem;
//     @include make-col-ready();
//     @include media-breakpoint-up(xs) {
//       @include make-col(12);
//     }
//     @include media-breakpoint-up(sm) {
//         @include make-col(10);
//         @include make-col-offset(1);
//       }
//       @include media-breakpoint-up(xl) {
//         max-width: 1280px;
//         margin: auto;
//     }  
//     .rich-text { color: $md-gray; }
  
//     h1, 
//     h2, 
//     h3, 
//     h4, 
//     h5, 
//     h6 {
//       @include interstate(regular);
//       color: $dk-gray2;
//     }
//     h1 { font-size: 2.8em; padding: 10px 5px; }
//     h2 { font-size: 2.4rem; padding: 10px 5px; }
//     h3 { font-size: 2rem; }
//     h4 { font-size: 1.6rem; }
//     h5 { font-size: 1.3rem; }
//     h6 { font-size: 1rem; }
//     ol, 
//     ul, 
//     dl { margin-left: 30px; }
//     p {
//         margin-bottom: 1rem;
//         &:first-child {
//             padding-top: 1.5rem;
//         }
//     }
//   }