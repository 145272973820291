.draws {
	.lazy-container {
		min-width: 1px;
		position: relative;
		min-height: 167px;
		//.match-box-container {position: absolute; top:0px; left:0px;}
	}
	.match-box-container {
		position: relative;
		z-index: 1;
	}
	.match-box .match-status .team-info {
		&.highlight,
		&.favorite.hightlight {
			background-color: $draws-highlight-color !important;
		}
	}
	.match-box .prematch-button.bottom {
		padding-top: 0px;
		a {
			width: 100%;
		}
	}

	.columnA .connector-round {
		display: none;
	}
	.connector-round {
		width: 60px;
		height: 178px;
		position: absolute;
		left: -40px;
		// top: -10px;
		top: -3px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		.left-side {
			width: 50%;
			height: 100%;
			border-top: solid 1px $usoLtGray;
			border-bottom: solid 1px $usoLtGray;
			border-right: solid 1px $usoLtGray;
		}
		.right-side {
			height: 50%;
			width: 50%;
			border-bottom: solid 1px $usoLtGray;
			position: absolute;
			top: 0px;
			right: 0px;
		}
	}
	.columnC .connector-round {
		top: -91px;
		height: calc(167px + 167px + 11px + 10px);
	}
	.drawGroup {
		position: relative;
		&.winner {
			.team-info.team-one.singles,
			.team-info.team-one.doubles,
			.team-info.team-two.singles,
			.team-info.team-two.doubles {
				display: none;
				&.winner {
					display: block;
				}
			}
			.match-box {
				.match-status {
					.team-info {
						.match-results {
							display: none;
						}
					}
					.event-notes {
						display: none;
					}
				}
			}

			.connector-round {
				.left-side {
					height: calc(50% - 10px);
					border-bottom: 0px;
				}
				.right-side {
					height: calc(50% - 10px);
				}
			}
		}
	}

	&.bracket {
		.lazy-container {
			min-height: 80px;
		}
		.match-box-container {
			z-index: 2;
		}
		.match-box {
			min-height: auto;
			width: 233px;
			.match-status {
				.event-info,
				.event-court {
					display: none;
				}
				.team-info .match-results {
					flex: 0 0 calc(148px - 24px);
				}
				.team-info .match-results .results-wrapper .pts {
					display: none;
				}
				.team-info .team-name {
					width: calc(100% - 148px + 27px);
					padding-right: 4px;
				}
				.team-info.team-one {
					border-top: solid 1px $usoltMedGray;
				}
			}
			.match-box-buttons {
				display: none;
			}
		}
		.columnB {
			.connector-round {
				height: 100px;
				width: 233px;
			}
		}
		.columnC {
			.connector-round {
				height: calc(160px + 20px);
				top: -50px;
				left: 80px;
				width: 100px;
			}
		}
		.columnD {
			.connector-round {
				height: calc(340px + 20px);
				top: -140px;
				left: 80px;
				width: 100px;
			}
		}
		.columnE {
			.connector-round {
				height: calc(700px + 20px);
				top: -320px;
				left: 80px;
				width: 100px;
			}
		}
		.columnF {
			.connector-round {
				left: 100px;
				z-index: 1;
			}
		}
		.columnG {
			.connector-round {
				display: none;
			}
		}

		&.rounds_6,
		&.rounds_7 {
			.columnF {
				.drawGroup {
					.connector-round {
						left: 130px;
						z-index: 1;
						height: 80px;
						top: -70px;

						.right-side {
							display: none;
						}
					}
					&:first-child {
						.connector-round {
							left: 130px;
							z-index: 1;
							height: 80px;
							top: 70px;

							.right-side {
								display: none;
							}
						}
					}
				}
			}
			.columnG {
				.connector-round {
					display: none;
				}
			}
		}
		// .columnF {
		//     .connector-round {
		//         height: calc(160px + 20px);
		//         top: -50px;
		//         left: 80px;
		//         width: 100px;
		//     }
		// }
		//.connector-round {height:100px;}
		//.connector-round {display:none;}
	}
}
