/** match-box styles **/
.match-box-container {
    &.usePlayerImages {
        display: flex;
        align-items: center;
        justify-content: center;

        .match-box {
            width: 100%;
            padding: 0;
        }
        .team-img-container {
            display: none;
        }
        @include general-breakpoint(smtablet) {
            margin-bottom: 10px;

            .match-box {
                padding: 0 20px;
            }
            .team-img-container {
                @at-root .webview #{&}{
                    display: none !important;
                }
                display: flex;
                padding-bottom: 5px;
                margin-top: 45px;

                &.team-one {
                    border-bottom: 9px solid #418FDE;
                }
                &.team-two {
                    border-bottom: 9px solid #FFD400;
                }
                .player-img-container {
                    &:first-child {
                        float: left;
                    }
                    &:last-child {
                         float: right;
                    }
                }
                &.doubles {
                    .player-img-container {
                        &.first-child {
                            margin-right: 2px;
                        }
                    }
                }
            }
        }
    }
}

.match-box {
    @include interstate;
    min-height: 166px;
    display: flex;
    flex-direction: column;
    
    .match-status {
        position:  relative;
        padding-top:  0;
        padding-left:  0;
        display:  flex;
        flex-flow:  column;
        flex: 0 0 auto;

        .event-info {
            @include interstate(bold);
            color: $usoDkGray;
            font-size: 10px;
            padding: 0;
            height: 21px;
            display: flex;
            justify-content: space-between;

            .event-type, .event-round {
                text-transform:  uppercase;
            }
            .event-round {
                margin-right: 0;

                &:before {
                    content: " - ";
                }
            }
            .results-info-status {
                i {
                    color: $live-color;
                    font-size: 7px;
                }
            }
        }
        .event-court{
            display: flex;
            justify-content: space-between;
            color: $usoDkGray;
            font-size: 12px;
            border: solid 1px $usoLtGray; 
            background: $white;
            height: 27px;
            padding: 0 8px 0 10px;
            margin-bottom: 1px;
            align-items: center;

            &:empty {
                &:after {content:"\00A0"}
            }
            span {
                &:empty {
                    &:after {content:"\00A0"}
                }
            }
            .duration {
                font-size: 10px;
                @include interstate(bold);
            }
        }
        .team-info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 40px;
            border: 1px solid $usoltMedGray;
            background: $white;
            color: $dk-gray2;
            position: relative;
            padding-left: 10px;

            &.winner {
                .team-name {
                    @include interstate(bold);
                }
            }
            &.search {
                background-color: $favorite-background-color !important;
                border: 3px $usoLtBlue solid;
                animation: blink 1.5s;
                animation-iteration-count: 3;
            }
            &.favorite {
                background-color: $favorite-background-color !important;

                &:before {
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 3px;
                }
            }
            .team-name {
                display: flex;
                flex: 0 0 auto;
                flex-direction: column;
                justify-content: center;
    			width: calc(100% - 148px); // 124 is width of scores (129 + 19)
            }
            &.loser{
                background-color: $lt-gray4;
            }
            &.team-two{
                border-top: none;
            }
            &.singles {
                .team-name {
                    .member-one, .member-two {
						cursor: pointer;
                        height: 100%;
                        
                        .name:hover {
                            color: $usoLtBlue;
                        }

                        .name {
                            .button-text-link {
                                color: $dk-gray2;
                                text-overflow: ellipsis;

                                div {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    width: 100%;
                                    white-space: nowrap;
                                }
                                
                                button {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    margin-left: 3px;
                                    cursor: pointer;
                                    max-width: 100%;
                                    color: $dk-gray2;
                                  }
                              }
                      
                              &.ai-opponent-name {
                                  color: $usoLtBlue;
                                  font-style: italic;

                                  &:hover {
                                    cursor: default;
                                  }
                              }
                        }

                        .likely2play-label {
                            font-size: px2rem(10);
                            font-style: italic;
                            color: $usoLtBlue;
                            position: absolute;
                            right: 20px;

                            &:hover {
                                cursor: default;
                            }
                        }
                    }
                    .member-one {
                        display: flex;
                    }
                    .member-two {
                        display: none;
                    }
                
                    .view-more-opponents {
                        display: block;
                        font-size: 12px;
                        margin-left: 28px;
                        margin-bottom: 7px;
                        margin-top: 5px;
                    }
            
                    .view-more-opponents-cta {
                        font-size: 12px;
                        color: $usoLtBlueBG;
                        font-style: italic;
                        margin-left: 6px;
                        margin-top:  6px;
                        margin-bottom: 7px;
            
                        .button-text-link {
                            color: $usoLtBlueBG;
                            white-space: nowrap; // the name box is smaller. force not to wrap the text

                            button {
                                all: unset;
                            }
                        }
                    }

                }
            }
            &.doubles {
                .member-one, .member-two {
					cursor: pointer;
					display: flex;
					height: 50%;
                }
                .name {
                    line-height:  16px;
                    height: 16px;
                    
                    &:hover {
                        color: $usoLtBlue;
                    }
                }
                .seed {
                    line-height:  19px;
                    height: 19px;
                }
            }

            &.favorable {
                background-color: $favorable-bg;

                &:hover {
                    cursor: pointer;
                }
            }

            &.neutral {
                background-color: $neutral-bg;

                &:hover {
                    cursor: pointer;
                }
            }

            &.difficult {
                background-color: $difficult-bg;

                &:hover {
                    cursor: pointer;
                }
            }

            &.post-match-color {
                background-color: $postmatch-bg;

                &:hover {
                    cursor: pointer;
                }
            }
            
            .match-results {
                display: flex;
                flex: 0 0 148px;
                align-items: center;
                height: 100%;

                .results-wrapper {
                    display: flex;
                    align-items: center;

                    .crticon {
                        display: flex;
                        align-items: center;
                        flex: 0 0 17px;
                    }
                    .pts, .set {
                        height:  31px;
                        line-height:  31px;
                        font-size:  14px;
                        text-align: center;

                        .scores {
                            .tiebreak {
                                position:  relative;
                                top:  -9px;
                                font-size:  9px;
                            }
                        }
                    }
                    .set {
                        flex: 0 0 20px;

                        &.won {
                            @include interstate(bold);
                        }
                    }
                    .pts {
                        visibility:  hidden;
                        margin-right: 5px;
                        flex: 0 0 24px;
                    }
                }
            }
        }        
        .flag {
            flex: 0 0 26px;
            height:  100%;
            align-items: center;
            
            img {
                width:  16px;
                height:  10px;
            }
        }
        .name, .seed {
            height:  100%;
        }
        .name {
            flex:  0 1 auto;
            overflow:  hidden;
            text-overflow:  ellipsis;
            white-space:  nowrap;
            color: $dk-gray2;
            font-size: 12px;

			a {
                color: $dk-gray2;
                display: flex;
                flex: 0 0 100%;
                overflow:  hidden;
	            text-overflow:  ellipsis;
				white-space:  nowrap;
				width: 100%;
			}
        }
        .seed {
            margin-left: 5px;
            flex: 0 0 15px;
            color: $md-gray;
            font-size: 10px;
            @include interstate;

            &.hide {
                display: none;
			}
                      
            &.ai-opponent-name {
                color: $usoLtBlue;
                font-style: italic;
            }
        }
        .crticon {
            height: 31px;
			font-size: 10px;
			margin-right: 2px;

            i {
                display: none;
            }

            &.currentServer {
                z-index: 20;
                position: relative;

                .icon-ballSolid {
                    color:  $scores-check-color;
                    display: inline;
                }
                .icon-check { display: none; }

                &.animation {
                    @include general-breakpoint(smdesktop) {
                        animation: myAnim 2s linear 0s 3 normal none;
                    }
                }
            }
            &.winner {
				padding-top: 3px;

                .icon-check {
                    color:  $scores-check-color;
                    display: inline;
                }
                .icon-ballSolid { display: none; }
            }
        }
        .event-notes {
            text-transform:  uppercase;
            color:  $dk-gray2;
            font-size:  9px;
            position: relative;

            .update {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        &.live{
            .team-info {
                .match-results {
                    .results-wrapper {
                        .pts {
                            margin-right: 5px;
                            display: inline;
                            visibility:  visible;

                            &.latest {
                                position: relative;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    border: 1px solid #979797;
                                }
                            }
                        }
                    }
                }
            }
		}
    }
    .match-box-buttons {
        margin-top: 7px;
        display: flex;
        font-size: 8px;
        flex: 0 0 auto;
        height: 31px;

        .slamtracker-button {
            width: 100%;
            background-color: $usoLtBlueBG;
        }
        .slamtracker-button_text {
            text-transform: uppercase;
            color: $white;
            @include interstate;
            font-size: 12px;
            display: flex;
            align-items: center;
            
            .arrow-icon {
                letter-spacing: -4px;
                padding-left: 6px;

                i{
                    @include interstate(bold);
                    font-size: 9px;
                }
            }
        }
        .match-insights_text {
            color: $bright_blue;
            text-transform: uppercase;
        }

        .disabled {
            opacity: .5;
            cursor:default;
        }
    }
    .blue-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $usoLtBlueBG;
        border-radius: 6px;
        padding: 0px;
        height: 100%;
        cursor: pointer;

        &:hover{
            background-color: $white !important;
            color: $usoLtBlueBG !important;
            border: 2px solid $usoLtBlueBG !important;

            .slamtracker-button_text{
                color: $usoLtBlueBG !important;
            }
        }
        &.live {
            background-color: $dark-blue3;

            &:hover{
                background-color: $white !important;
                color: $dark-blue3 !important;
                border: 2px solid $dark-blue3 !important;

                .slamtracker-button_text{
                    color: $dark-blue3 !important;
                }
            }
        }
    }
    .live-buttons {
        height: 30px;
        z-index: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
    .live-video, .live-radio {
        width: 170px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $dk-gray2;
        border: 1px solid #6F6F6F;
        background-color: $white;
        border-radius: 6px;
        font-size: 12px;
        cursor: pointer;
        padding: 0px;
        margin: 0 5px;

        i {
            margin-right: 5px;
            color: $live-color;
            font-size: 8px;
        }
        &:hover {
            background-color: $dark-blue3 !important;
            color: $white !important;
            border: 1px solid $white !important;
            text-decoration: none;
        }
        &.disabled {
            color: #ACB3B5 !important;
            background-color: $usoltMedGray !important;
            cursor: default;
            opacity: 1;

            i {
                color: #ACB3B5;
            }
        }
    }
    .live-radio {
        text-transform: uppercase;
    }
    .live-video {
        text-transform: none;
    }
    .buy-tickets-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 100px;
        height: 55px;
        background: $white;
        border: solid 1px #418FDE;
        color: #418FDE;
        font-size: 12px;
    }

    &.error {
        .match-status {
            position: relative;

            .team-info {
                &.team-one {
                    border-bottom: 0;
                }
            }
            .error-message {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 75px;
                text-align: center;
                @include interstate(medium);
                font-size: 14px;
            }
        }
    }
}

@keyframes myAnim {              
    50% {
        transform: translateX(-20px);
    }
        
    25%,
    75% {
        transform: translateX(-10px);
    }

    0%,
    100% {
        transform: translateX(0);
    }
}

.slamtracker {
    .content-allmatches {
        .slamtracker-match-box-container {
            position: relative;

            .live-buttons {
                display: none;
            }
        }
        .match-box {
            .live-video {
                text-decoration: none;
            }
        }
    }
    .featured-match {
        .match-box-container {
            &.usePlayerImages {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
        
                .match-box {
                    width: 100%;
                    padding: 0;
                }
                .team-img-container {
                    display: none;
                }
                @include general-breakpoint(landscape) {
                    .match-box {
                        .match-status {
                            .team-info {
                                padding-left: 0;
            
                                &.team-one {
                                    border-left: 9px solid #418FDE;
                                }
                                &.team-two {
                                    border-left: 9px solid #FFD400;
                                }
                                .team-name {
                                    padding-left: 20px;
                                }
                            }
                        }
                    }
                }
                // @include general-breakpoint(smtablet) {
                //     .match-box {
                //         padding: 0 11px;
                //         border: none;

                //         .match-status {
                //             height: auto;
                //             background-color: unset;
                //             padding: 0;
        
                //             .event-info {
                //                 justify-content: center;
                //                 position: relative;
                //                 height: 46px;
        
                //                 .event-type, .event-round {
                //                     font-size: 18px;
                //                     color: $black;
                //                     @include interstate;
                //                 }
                //                 .event-court {
                //                     display: block;
                //                     width: 100%;
                //                     font-size: 12px;
                //                     color: $dk-gray2;
                //                 }
                //                 .results-info-status {
                //                     display: inline-block;
                //                     width: 100%;
                //                     text-align: right;
                //                     position: absolute;
                //                     right: 0px;
                //                     bottom: 0px;
                //                 }
                //             }
                //         }
                //         .live-buttons {
                //             position: static;
                //         }
                //     }
                //     .team-img-container {
                //         display: flex;
                //         margin-top: 46px;
        
                //         .player-img-container {
                //             &:first-child {
                //                 // float: left;
                //                 // margin-right: 2px;
                //             }
                //             &:last-child {
                //                 //  float: right;
                //             }
                //             img {
                //                 &.player-image {
                //                     &.small {
                //                         width: 68px;
                //                         height: auto;
                //                     }
                //                 }
                //             }
                //         }
                //         &.doubles {
                //             .player-img-container {
                //                 &.first-child {
                //                     margin-right: 2px;
                //                 }
                //             }
                //         }
                //     }
                // }
            }
        }
        .match-box {
            .match-status {
                background-color: $white; 
                padding: 6px;

                .event-info {
                    .results-info-status {
                        // right: 5px;
                    }
                }
            }
            &.prematch {
                min-height: auto;
        
                @include general-breakpoint(smtablet) {
                    min-height: 154px;
                }
            }
            .prematch-container {
                display: flex;
                flex-direction: row;
                border: 1px solid $usoltMedGray;
                margin-bottom: 5px;
                height: 80px;
                margin-bottom: 0px;
            }
            .prematch-teams {
                display: flex;
                flex-direction: column;
                height: 100%;
                width: calc(100% - 122px);
        
                .team-info {
                    border: none;
        
                    &.team-one {
                        border-bottom: 1px solid $usoltMedGray;
                    }
                    &.team-two {
                        margin-bottom: 0;
                    }
                }
                .team-name {
                    width: 100% !important;
                }
            }
            .prematch-button {
                background-color: $white;
                height: 100%;
                width: 122px;
                display: flex;
                justify-content: center;
                align-items: center;
        
                a {
                    width: 101px;
                    height: 39px;
                    text-transform: uppercase;
                    color: $white;
                    text-decoration: none;
                }
                &.side { 
                    display: flex; 
                    background-color: $white;
                    
                    a {
                        width: 101px;
                        height: 39px;
                    }
                }
                &.bottom { 
                    display: none; 
                    width: 100%;
                    background-color: unset;
                    padding-top: 10px;
                    height: 30px;
                    margin-top: 3px;
                    
                    a {
                        width: 170px;
                        height: 100%;
                        margin-top: 0px;
                    }
                }
                @include general-breakpoint(smtablet) {
                    &.bottom { display: none; } 
                }
            }
        }
        @include general-breakpoint(smdesktop) {
            margin-top: 10px;
        }
    }
}

.stpanel-container {
    .slamtracker {
        .featured-match {
            .match-box {
                min-height: unset;
                
                .match-status {
                    .event-info {
                        justify-content: space-between;
                        
                        .results-info-status {
                            display: unset;
                            width: unset;
                            text-align: unset;
                            position: unset;
                            right: unset;
                            bottom: unset;
                        }
                    }
                    .team-info {
                        .team-name {
                            height: unset;
                        }
                    }
                }
            }
        }
    }
}

.grounds-map-matchbox {
    width: 100%;
    height: 100%;
    padding: 22px 16px;
    background-color: $lt-gray;

    &.no-content {
        text-align: center;
        color: $md-gray;
        font-size: 16px;
        @include interstate(bold);
    }
}
