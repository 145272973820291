.slamtracker-overview {
	float: left;
	padding-bottom: 15px;

	&.content {
		height: auto !important;
		min-height: 200px;
	}

	.mi-cta {
		display: flex;

		// .mi-cta-button {
		//     padding: 1px 10px;
		//     margin: 0 auto 20px auto;
		//     border: 1px solid $bright-blue;
		//     color: $bright-blue;
		//     @include interstate;
		//     cursor: pointer;

		//     .bolder {
		//         @include interstate(medium600);
		//     }
		// }
	}

	.bio-data-table {
		width: 96%;
		margin: 0px 2%;

		// display: table;
		// border-collapse: collapse;
	}

	position: relative;

	.overview-row {
		margin: 0px auto;
		// float: left;
		// display: table-row;
		display: flex;
		align-items: center;
		width: 100%;
		padding: 6px 0;
		border-bottom: 1px solid #ececec;

		&:first-child {
			border-bottom: none;
		}
		&.border-top {
			border-top: 1px solid #ececec;
		}
		.label {
			//float: left;
			// display: table-cell;
			width: 30%;
			// height: 32px;
			// vertical-align: middle;
			text-align: center;
			font-size: 12px;
			@include interstate(light);
			color: #333333;
		}
		.data {
			// float: left;
			// display: table-cell;
			// vertical-align: middle;
			// height: 32px;
			width: 35%;
			font-size: 12px;
			@include interstate;
			color: #333333;

			&.team1 {
				text-align: left;
			}
			&.team2 {
				text-align: right;
			}
		}
		&.name {
			// margin-bottom: 3px;

			.data {
				color: $dk-gray2;
				@include interstate(bold);
				font-size: 12px;
				// height: 32px;
				width: 50%;

				&.team1 {
					text-align: left;
				}
				&.team2 {
					text-align: right;
				}
			}
			.label {
				display: none;
			}
		}
		&.image {
			padding-top: 9px;
			padding-bottom: 10px;

			.team1 {
				float: left;
				width: 50%;
				text-align: left;

				img {
					border-top: 9px solid $team1_color;
				}
			}
			.team2 {
				float: left;
				width: 50%;
				text-align: right;

				img {
					border-top: 9px solid $team2_color;
				}
			}
		}
		&.buttons {
			margin-bottom: 32px;
			border-bottom: none;
			justify-content: space-between;

			&.playerArow {
				margin-bottom: 20px;
			}
		}
		&.singles {
			&.buttons {
				&.playerArow {
					margin-bottom: 10px;
				}
			}
		}
		&.doubles {
			&.buttons {
				&.playerBrow {
					margin-bottom: 10px;
				}
			}
		}
	}
	.profilebutton {
		display: inline-block;
		background-color: $usoLtBlue;
		color: #fff;

		text-decoration: none;
		text-transform: uppercase;
		font-size: 10px;
		height: 20px;
		line-height: 18px;
		padding: 0px 6px;
		@include interstate;
		cursor: pointer;
		span {
			color: white;
		}
		&:hover {
			color: $bright-blue;
			border: 1px solid $bright-blue;
			background-color: $white;
			span {
				color: $bright-blue;
			}
		}

		&.team1 {
			text-align: left;
		}
		&.team2 {
			text-align: right;
		}
	}
	.courtesy-of-text {
		text-align: center;
	}

	@include general-breakpoint(smtablet) {
		.overview-row {
			&.image {
				display: none;
			}
			&.name {
				height: 40px;
				// line-height: 40px;
				// border-bottom: 1px solid #ECECEC;
				// margin-bottom: 10px;
			}
		}
	}
}

.slamtracker-matchup {
	width: 100%;
	height: auto !important;
	border-bottom: 20px solid $md-gray2;
	padding: 15px 13px;

	.matchup-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: flex-start;

		.player-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex-basis: 80px;

			&.player1 {
				img {
					border-bottom: 8px solid $usoLtBlue;
				}
			}
			&.player2 {
				img {
					border-bottom: 8px solid $usoYellow;
				}
			}

			.player-image {
				cursor: pointer;
				img {
					height: 80px;
					width: auto;
					box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
					// height: auto;
				}
			}
			.player-name {
				@include interstate;
				font-size: 12px;
				color: $usoDkGray;
				margin-top: 10px;
				cursor: pointer;

				a {
					text-decoration: none;
				}
			}

			@include general-breakpoint(smtablet) {
				display: none;
			}
		}
		.matchup-info {
			width: 180px;
			padding: 0 10px;

			.text {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.status {
					color: $usoDkBlue;
					text-transform: uppercase;
					@include interstate(bold);
				}
				.momentum-text {
					color: $usoLtBlueBG;
					font-size: 13px;
					text-align: center;
					line-height: 14px;
					@include interstate(regular);
					cursor: pointer;
				}
			}
			.mi-btn {
				@include interstate(bold);
				border-radius: 7px;
				@include watsonBackgroundColor;
				color: $white;
				font-size: px2rem(12);
				margin: auto;
				margin-bottom: 12px;
				line-height: px2rem(15);
				position: relative;
				text-align: center;
				text-transform: uppercase;
				width: 100%;
				height: 46px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 0 15px;
				cursor: pointer;

				.more {
					text-transform: none;
					font-size: px2rem(12);
					@include interstate(regular);
					line-height: px2rem(16);
					margin-top: 4px;
					display: none;
				}
			}

			@include general-breakpoint(smtablet) {
				width: 100%;
				padding: 0;

				.text {
					display: none;
				}
				.mi-btn {
					padding: 0;
					height: 54px;
					margin-bottom: 0;

					.more {
						display: block;
					}
				}
			}
		}
	}

	@include general-breakpoint(smtablet) {
		border-bottom: 1px solid $md-gray2;
	}
}

.stpanel-container {
	.slamtracker-overview {
		.profilebutton {
			display: table-cell;
			padding-top: 15px;
			display: inline-block;
			color: $bright-blue;
			border: 1px solid $bright-blue;
			text-decoration: none;
			text-transform: uppercase;
			font-size: 10px;
			height: 20px;
			line-height: 18px;
			padding: 0px 6px;
			@include interstate;
			cursor: pointer;

			&.team1 {
				text-align: left;
			}
			&.team2 {
				text-align: right;
			}
		}
	}

	.slamtracker-matchup {
		border-bottom: 20px solid $md-gray2;

		.matchup-container {
			.player-container {
				display: flex;
			}
			.matchup-info {
				width: 180px;
				padding: 0 10px;

				.text {
					display: flex;
				}
				.mi-btn {
					padding: 0 15px;
					height: 46px;
					margin-bottom: 12px;

					.more {
						display: none;
					}
				}
			}
		}
	}
}
