.livescores-content {
	background: transparent;
	border-bottom: 1px solid #c1c6c8;
	.no-matches {
		min-height: 310px;
		text-align: center;
		padding: 20px 0;
		font-size: px2rem(16);

		.message {
			padding: 30px;
			@include interstate;
			display: inline-block;
			border-radius: 5px;
			margin-top: 20px;
			font-size: px2rem(20);
		}
	}
}

.mip-ad-container,
.schedule-ad-container,
.st-ad-container {
	// width:100vw;
	width: 100%;
	position: relative;
	min-height: 50px;
	text-align: center;
	font-weight: bold;
	font-size: 2em;
	background: transparent;
	padding: 30px 0;

	&.schedule-ad {
		background: transparent;
	}
	@include scores-ad-breakpoint(medium) {
		min-height: 90px;
		> div {
			//padding:7px 0px;
		}
	}
}
.schedule-ad-container {
	background: transparent;
}
.match-content {
	background-color: transparent;
	border-top: solid 1px $md-gray5;
}

.column-layout.resultsfilter {
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: space-evenly;
	flex-wrap: nowrap;
	padding: 0px;
	background-color: #f3f3f3;
	.daynav-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 44px;
		z-index: 1;
	}
	.events-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 44px;
		z-index: 2;
		border-bottom: solid 1px $md-gray2;
	}
}

.column-layout.schedulefilter {
	justify-content: center;
	background-color: $lt-gray3;
	align-items: center;
	height: 48px;
	.daynav-wrapper {
	}
}
@include general-breakpoint(smtablet, max) {
	.column-layout.resultsfilter {
		padding: 0px;
	}
}

@include general-breakpoint(smtablet) {
}

@include general-breakpoint(smdesktop) {
	.column-layout.resultsfilter {
		flex-direction: row;
		height: 56px;
		justify-content: space-around;
		padding: inherit;

		.daynav-wrapper {
			width: auto;
			height: 56px;
		}
		.events-wrapper {
			width: auto;
			border-bottom: none;
			height: 56px;
		}
	}
	.column-layout.schedulefilter {
		flex-wrap: nowrap;
		height: 56px;
	}
}

@include general-breakpoint(desktop) {
	.column-layout.resultsfilter {
		justify-content: space-around;
	}
}

.column-layout.scoresgrid {
    .error-indicator, .message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		@include interstate;
		text-align: center;
		font-size: px2rem(20);
		color: $dk-gray2;
	}
}
