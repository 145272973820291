.media-wrapper-container {
    .media-16x9 {
        padding-bottom: 56.25%;
        position: relative;
        margin: auto;
        width: 100%;

        .media-wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            .inlineVideo {
                width: 100%;
                float: left;
                text-align: center;
                position: relative;
                //margin: 10px 0px 20px 0px;
                padding-bottom: 56.25%;
                height: 0;

                img {
                    width: 100%;
                }
            }
        }
    }
    .load-more-wrapper {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    
        .load-more span{
            font-size: px2rem(16);
            @include button();

            @include general-breakpoint(smtablet) {
                width: 200px;
            }
        }
    }
}