.slamtracker-momentum {
    position: relative;
    width:100%;
}
#momentum_panel {
    height:calc(100% - 56px);  // height of toolbar and player name header
    
    .webview & {
        min-height: calc(100% - 56px);
        height: auto;
        max-height: calc(100vh - 56px - 150px - 78px);
    }
}
.momentum-panel {
    // padding: 11px;
    padding:0 0 11px 0px;

    &.selected {
        //position: fixed;
        //top: 0px;
        //left:0px;
        width: 100%;
        //height: 100vh;
        background: white;
        z-index: 4;
        overflow: hidden;
        
        .slamtracker-momentum {
            height: 100%;
        }
    }
    .content {
        //padding: 0px 11px 11px 11px;
    }
    .score-container {
        text-align: center;
        font-size: .7em;
        margin-bottom: 11px;
        margin-top: 25px;
        @include interstate(medium);

        .sentence {
            .team1 {color:$team1_color}
            .team2 {color:$team2_color}
        }
        .set-scores {
            span {
                margin-left:7px;
                &:first-child{margin-left:0px;}
                &.team1 {color:$team1_color;}
                &.team2 {color:$team2_color;}
            }
        }
    }
    .team-container {
        display: flex;
        flex-basis: auto;
        flex-flow: row;
        
        .team-content {
            display: flex;
            flex-flow: row;
            width: 50%;
            flex: 1;

            &.team1 {
                color:$team1_color;
                justify-content: flex-end;
            }
            &.team2 {
                color: $team2_color;
                justify-content: flex-start;
                .player1 {
                    border-left:solid 1px fuchsia;
                }
            }
            &.singles {
                .player-content {
                    width:70%;
                }
            }
        }
        .player-content {
            text-align: center;
            @include interstate(medium);
            font-size: .7em;
            text-transform: uppercase;
            margin-bottom: 10px;
            width: 45%;

            .player-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 0px 5px;
            }
        }
        .icon-container {
            width: 10%;
            position: relative;

            .crtIcon {
                position: absolute;
                left: 0px;
                right: 0px;
                margin: 0px auto;
                padding: 0px;
            }   
            // .winner {color: $green-check-color; bottom:10px;}
            // .currentServer {color:$yellow-tennisball-color; bottom:10px;}
        }
    }
    .momentum-container {
        margin-bottom: 10px;
        width:100%;
        .graph-container {
            width:100%;
            .set {
                &:first-child {
                    .set-header {
                        .set-label {
                            //background:none;
                        }
                    }
                }
            }
        }
        .set {
            border-radius: 3px;
            position: relative;
            margin-bottom: 4px;
            //padding: 0px 3px;

            #notification-flash {
                animation: newPointAnimation .5s ease-in 10s forwards ;
                position: absolute;
                top:0px;
                left:0px;
                background:$white;
                color:black;
                width:calc(100% - 10px);
                border:solid 1px $momentum_notification_border_color;
                margin:5px;
                z-index: 1;
                opacity: 1;

                padding: 5px;
                height: auto;
                display: flex;
                flex-direction: column;

                &.team1 {
                    border-top:solid 4px $team1_color;
                }
                &.team2 {
                    border-top:solid 4px $team2_color;
                }
                .label {
                    text-align: center;
                    font-weight: bold;
                    font-size: px2rem(10);
                    text-transform: uppercase;
                }
                .info {
                    margin-top:3px;
                    display:flex;
                    flex-direction: row;
                    font-size:px2rem(11);
                    @include interstate(light);

                    .info-item {
                        padding:0px 3px;
                        &.elapsed-time {
                            color:$momentum_notification_duration_color;
                        }
                        &.pointScore {
                            color:$momentum_notification_score_color;
                            @include interstate;
                        }
                        &.sentence {
                            color:$momentum_notification_sentence_color;
                        }
                    }
                }
            }
              
            @keyframes newPointAnimation {
                to   { 
                    opacity: 0;
                    border:none;
                    overflow: hidden;
                }
            }
            
            /* this is the team who won the set */
            &.team1 {
                // border: solid 2px;
                // border-color: #8EBD91;
                // background-color: rgba(213,231,185,0.18);

                .set-header {
                    .set-label {
                        //left: 0px;
                        font-size: .8em;
                        //color: $md-gray;
                        //@include interstate(bold);
                    }
                    // .set-winner { color: $md-gray; }
                    // .set-duration { color: $md-gray; }
                    // .set-score { color: $md-gray; }
                }
            }
            &.team2 {
                // border:solid 2px;
                // border-color: #C3A8D4; 
                // background: rgba(84,0,139,0.03);
                
                .set-header {
                    .set-label {
                        //right: 0px;
                        font-size: .8em;
                        //color: $md-gray;
                        //@include interstate(bold);
                    }
                    // .set-winner { color: $md-gray; }
                    // .set-duration{ color: $md-gray; }
                    // .set-score{ color: $md-gray; }
                }
            }
            .set-header {
                position: relative;
                display:flex;
                flex-direction:column;
                .set-label {
                    background:$momentum_setlabel_bg_color;
                    color:$momentum_setlabel_color;
                    text-align: center;
                    order:1;
                    display:flex;
                    flex-direction: row;
                    justify-content: center;
                    position: relative;
                    height:22px;
                    line-height: 22px;
                    padding:0px 11px;
                    text-transform: capitalize;
                }
                .set-number {
                    font-size:px2rem(11);
                    text-transform: uppercase;
                    //color:$black;
                    //@include interstate(medium);
                }
                .set-duration {
                    right: 11px;
                    margin-right:0px;
                    position: absolute;
                    top:0px;
                    font-size:px2rem(8);
                    //color:$black;
                    text-transform: uppercase;
                }
                .set-info {
                    text-align: center;
                    color: $md-gray;
                    text-transform: uppercase;
                    order:2;
                    @include interstate;
                    border-bottom:solid 1px $momentum_setlabel_border_color;
                    margin:0px 11px;
                    .set-winner{ font-size: px2rem(8); margin-right: 5px; }
                    //.set-duration{ font-size: .6em; text-transform: uppercase; }
                    .last-point{ font-size:px2rem(8);}
                    .set-score{ font-size:px2rem(8);}
                }
                &:first-child {
                    + .game {
                        border-top:none;
                    }
                }
            }
            .game {
                border-top:solid 1px $momentum_game_separator_color;
                position: relative;
                width:calc(100% - 22px);
                margin:0px 11px;
                color: $momentum_game_color;
                text-transform: uppercase;
                &:first-child { border: none; }
                &.team1 {
                    //color: $md-gray;
                    .game-label { left: 0; }
                }
                &.team2 {
                    //color: $md-gray;
                    .game-label { right: 0; }
                }
                .game-label {
                    position: absolute;
                    // text-transform: uppercase;
                    font-size: px2rem(10);
                    //left:0px;
                    top: calc(50% - 7px);
                    //@include interstate;
                    //text-transform: capitalize;
                }
            }
            .point {
                padding: 1px 0px; 
                width:100%;
                .bar-container {
                    display: flex;
                    flex-flow: row;
                    min-height: 2px;
                    height: 2px;
                    padding:0px 75px;
                    width:100%;
                    /* this is the team who won the point */

                    &.zoom-match {
                        min-height: 3px;
                        height:3px;
                    }
                    &.zoom-set {
                        min-height:6px;
                        height:6px;
                    }
                    &.zoom-game {
                        cursor:pointer;
                        min-height:20px;
                        height:20px;
                        justify-content: center;
                        padding:0px 10px; 
                        flex-wrap: wrap;
                        
                        .pointScore {
                            width:50px;
                            font-size:px2rem(10);
                            color:$black;
                            text-align: center;
                        }
                        .team1, .team2 {
                            width: calc(50% - 45px);
                            .annotation {
                                top:7px;
                            }
                        }
                        .team2 {
                            .annotation {
                                left:0px;
                            }
                        }
                        .even {
                            &:first-child {
                                &.team1 {
                                    border:none;
                                    &.winner {
                                        //border-right:$team1_color;
                                    }
                                }
                            }
                            &.team2 {
                                border:none;
                                &.winner {
                                    //border-left:solid 1px $team2_color;
                                }
                                .annotation {
                                    left:0;
                                }
                            }
                        }
                    }
                    .annotation {
                        &.team2 {background:rgba(255,212,0,.10)}
                        &.team1 {background:rgba(74,144,226, 0.10)}

                        // after is the letter annotation
                        &:after{
                            content:"";
                            position: absolute;
                            font-size:px2rem(9);
                            top: -4px;
                            color:$black;
                            font-weight: bold;
                            width:25px;
                            padding:0px 2px;
                            text-align: center;
                        }
                        // before is the video annotation
                        &:before{
                            content:"";
                            position: absolute;
                            font-size:px2rem(8);
                            top: -2px;
                            color:$black;
                            font-weight: bold;
                            width:25px;
                            padding:0px 2px;
                            text-align: center;
                            color:$dark_blue3;
                            @include uso-icon('Icons');
                        }
                        &.HighlightVideo {
                            &:before {
                                content:"\e924";
                            }
                        }
                        &.UnforcedError {
                            &:after {
                                content:"U"
                            }
                            &.HighlightVideo {
                                &:after {
                                    content:"U"
                                }
                                &:before {
                                    width:12px;
                                    content:"\e924"
                                }
                            }
                        }
                        &.DoubleFault {
                            &:after {
                                content:"D"
                            }
                            &.UnforcedError {
                                &:after {
                                    content:"D"
                                }
                            }
                            &.HighlightVideo {
                                &:after {
                                    content:"D"
                                }
                                &:before {
                                    content:"\e924"
                                }
                            }
                        }
                        &.Winner {
                            &:after {
                                content:"W"
                            }
                            &.DoubleFault {
                                &:after {
                                    content:"W"
                                }
                            }
                            &.UnforcedError {
                                &:after {
                                    content:"W"
                                }
                            }
                            &.HighlightVideo {
                                &:after {
                                    content:"U"
                                }
                                &:before {
                                    content:"\e924"
                                }
                            }
                        }
                        &.Ace {
                            &:after {
                                content:"A"
                            }
                            &.Winner {
                                &:after {
                                    content:"A"
                                }
                            }
                            &.DoubleFault {
                                &:after {
                                    content:"A"
                                }
                            }
                            &.UnforcedError {
                                &:after {
                                    content:"A"
                                }
                            }
                            &.HighlightVideo {
                                &:after {
                                    content:"A"
                                }
                                &:before {
                                    content:"\e924"
                                }
                            }
                        }
                        &.BreakPointWon {
                            &:after {
                                content:"B"
                            }
                            &.Ace {
                                &:after {
                                    content:"B"
                                }
                            }
                            &.Winner {
                                &:after {
                                    content:"B"
                                }
                            }
                            &.DoubleFault {
                                &:after {
                                    content:"B"
                                }
                            }
                            &.UnforcedError {
                                &:after {
                                    content:"B"
                                }
                            }
                            &.HighlightVideo {
                                &:after {
                                    content:"B"
                                }
                                &:before {
                                    content:"\e924"
                                }
                            }
                        }
                    }
                    .team1 {
                        width: 50%;
                        position:relative;

                        .annotation{
                            position: absolute;
                            top:0px;
                            height:6px;
                            right:0px;
                            width:100%;
                            &:after {
                                left: -18px;
                            }
                            &:before {
                                left: -28px;
                            }
                        }
                        .bar { 
                            border-radius: 2px;
                            height: 100%; 
                            float: right
                        }
                    }
                    .team2 {
                        position: relative;
                        width: 50%;
                        .annotation{
                            position: absolute;
                            top:0px;
                            left:0px;
                            height:6px;
                            width:100%;
                            &:after {
                                right: -18px;
                            }
                            &:before {
                                right: -28px;
                            }
                            
                        }
                        .bar {
                            border-radius: 2px; 
                            height: 100%; 
                            float: left;
                        }
                    }
                    .even {
                        position: relative;
                        width: 50%;
                        text-align: center;

                        .annotation{
                            position: absolute;
                            top:0px;
                            //left:0px;
                            height:6px;
                            width:100%;
                            &.team1 {
                                left:0px;
                            }
                            &.team2 {
                                left:calc(100% + 2px);
                            }
                            
                        }
                        
                        &:first-child {
                            &.team1 {
                                border-right:solid 1px $team1_color;
                            }
                            &.team2 {
                                border-right:solid 1px $team2_color;
                            }
                            .annotation {
                                &.team1 {
                                    left:0px;
                                }
                                &.team2 {
                                    left:calc(100% + 2px);
                                }
                            }
                        }
                        &:nth-child(2) {
                            .annotation {
                                &.team1 {
                                    left:0px;
                                    &:after {
                                        left:auto;
                                        right:-25px;
                                    }
                                }
                                &.team2 {
                                    left:0px;
                                }
                            }
                        }

                        .bar {
                            //max-width: calc(100% - 5px);
                            &.team1 {background: $team1_color;}
                            &.team2 {background: $team2_color;}
                        }
                        //border-left:solid 1px transparent;
                    }
                    .bar {
                        max-width: calc(100% - 5px);
                        &.team1 { background: $team1_color; }
                        &.team2 { background: $team2_color; }
                    }
                }
                &.expanded {
                    .bar-container {
                        &.zoom-game {
                            height: auto;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            border:solid 1px black;
                            margin-top:3px;
                            margin-bottom:5px;
                            >.team1,>.team2,>.even,>.pointScore {
                                height:20px;
                            }
                        }
                    }
                }
                .point-info {
                    display:flex;
                    width:100%;
                    .elapsed-time {
                        width:40px;
                        color:#636161;
                        font-size: px2rem(10);
                        text-transform: none;
                    }
                    .sentence {
                        width:calc(100% - 40px);
                        text-align: center;
                        @include interstate(light);
                        font-size: px2rem(11);
                        color:#000000;
                        text-transform: none;
                    }
                }
                .point-callout {
                    background-color: rgba(255, 212, 0, 0.13);
                    text-align: center;
                    padding: 4px 8px;
                    color: #333333;
                    width: 100%;
                    vertical-align: middle;
                    margin-bottom: 8px;

                    .callout-title {
                        @include interstate;
                        text-transform: uppercase;
                        font-size: px2rem(11);
                        color:#000000;
                    }
                    .callout-team {
                        @include interstate(light);
                        font-size: px2rem(12);
                        color: #333333;
                    }
                }
            }

        }
        &.zoom-set {
            .set {
                .point {
                    padding: 2px 0px; 
                }
            }
        }
        &.zoom-game {
            .set {
                .game {
                    border-top:solid 4px #F5F5F5; 
                    .game-label {
                        top:5px;
                    }
                    .game-info {
                        text-align: center;
                        font-size:px2rem(10);
                        height:25px;
                        line-height: 25px;
                    }
                }
            }
        }
    }
    .helper-container {
        text-align: center;
        color: $white; 
        //font-size: 12px;
        padding: 15px;
        background: url('/assets/images/scores/slamtracker/backgrounds/momentum_bkg.jpg') no-repeat;
        background-size: cover;
        min-height: 335px;
        .title { 
            #uso-main & {
                height: 24px; 
                text-transform: uppercase; 
                margin-bottom: 12px; 
                font-size: px2rem(12);
            }
        }
        .helper-text { margin-bottom: 24px; }
        .example-container {
            display: flex;
            flex-flow: row;

            .title { 
                height: 13px; 
                @include interstate(bold); 
            }
            .diagram-container {
                width: 100%;
                display: flex;
                flex-flow: column;

                .label {
                    font-size: .9em;
                    height: 13px;
                    margin-bottom: 12px;
                    @include interstate(bold);

                }
                .diagram {
                    width: 71px; 
                    height: 202px;
                    margin: 0px auto;
                }
            }
            .label{
                width: 100%;
                height: 24px; 
                @include interstate;
                font-size: .9em;
                text-transform: uppercase;
                
                &.team1 {
                    color: $white; 
                    position: relative; 
                    top: 68px;
                }
                &.team2 {
                    color: $white; 
                    position: relative; 
                    top: 68px;
                }
            }
        }
    }

    .server-wrapper {
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        text-align: right;
        height:12px;
        line-height: 12px;
        margin:5px 0px;
        align-items: center;
        &.team2 {
            justify-content: flex-end;
            text-align: left;
        }
    }

    .server-indicator {
        font-size: px2rem(8);
        //@include interstate(medium);
        text-transform: uppercase;
        width:50%;
        color:$black;
        i {
            font-size:px2rem(12);
            //padding:0px 5px; 
        }
        &.team1 {
            padding-right:25px;
            i {
                float:right;
                padding-left: 5px;
                color:$team1_color;

            }
        }
        &.team2 {
            padding-left:25px;
            i {
                float:left;
                padding-right:5px;
                color:$team2_color;
            }
        }
    }
}
@include general-breakpoint(smtablet) {
    .momentum-panel {
        &.selected {
            position: relative;
            top: auto;
            left: auto;
            height: 100%;
            // overflow: auto;
            // -webkit-overflow-scrolling: touch;
            overflow: hidden;
        }
        .score-container { margin-top: 0px; }
        .slamtracker-momentum { height: 100%; }
        .close-button { display: none; }
    }
}