/** related-content styles **/
.latest-content {
    display:flex;
    flex-wrap:wrap;
    .news-tile2{
        padding:0px 6px;
        margin:0;
        .new-tag,.type-tag{
            display:none;
        }
        .news-title{
            margin-bottom:15px;
        }
        &.one-col {
            .news-tile2 {
                width:100%;
            }
        }
        &.two-col {
            .news-tile2 {
                width:50%;
            }
        }
        &.three-col {
            .news-tile2 {
                width:33%;
            }
            @include general-breakpoint(desktop){
                .news-tile2 {
                    width:50%
                }
            }
            @include general-breakpoint(tablet){
                .news-tile2 {
                    width:100%
                }
            }
        }
        &.four-col {
            .news-tile2 {
                width:25%;
            }
            @include general-breakpoint(desktop){
                .news-tile2 {
                    width:50%
                }
            }
            @include general-breakpoint(tablet){
                .news-tile2 {
                    width:100%
                }
            }
        }
    }

}
