.slamtracker-matchstats-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    $row-height: 30px;
    @include panel-content-background;
    margin-top: 10px;

    .slamtracker-matchstats-tabs {
        height: 48px;

        .page-nav-list {
            // border-bottom: 1px solid $md-gray4;
            padding: 0;

            li.page-nav-items {
                padding-top: 14px;
                
                &.disabled {
                    &.active {
                        border-bottom: none;
                    }
                    span { color: $md-gray2; }
                }
            }
            &.disabled {
                opacity: 1;
            }
        }
    }
    .scores-select-menu { 
        padding: 0;
        .select-menu {
            display: block;
            margin: 20px auto;
            width: 350px;
            height: 28px;
            float: unset;

            button {
                color: $usoLtBlueBG !important;
                text-transform: unset;
                width: 100%;
                &.title {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    padding: 0 12px;
                    align-items: center;
                    border: 1px solid $usoLtBlueBG !important;
                    background-color: $white;
                    text-transform: capitalize;
                    position: relative;
                    .menu-arrow {
                        // position: static;
                        right: 11px;
                    }
                    .title-header {
                        font-size: 12px;
                        text-transform: capitalize;
                        text-align: center;
                    }
                }
            }
        }
    }
    .overall {
        padding: 0px;
        width: 95%;
        margin: 5px auto;

        .stats-header-row {
            width: 100%;
            height: 40px;
            border-bottom: 1px solid #ECECEC;
            display: flex;
            justify-content: space-between;
            
            .stats-label { font-size: 16px; }
            .stats-player {
                @include interstate();
                font-size: 12px;
                color: $powerrank-text;
                
                &.doubles { line-height: 18px; }
            }
            .t1 {
                height: 100%;
                align-content: center;

                .stats-player { 
                    @include interstate();
                    text-align: left;
                }
            }
            .t2 {
                height: 100%;
                align-content: center;

                .stats-player { 
                    text-align: right;
                    @include interstate();
                }
            }
        }
        .stats-row {
            height: $row-height;
            position: relative;
            // border-bottom: 1px solid #ECECEC;
            display: flex;
            justify-content: space-between;
            @include interstate;

            &.hide {
                display: none;
            }
            &:last-child {
                border-bottom: none;
            }
            &.in-attack {
                height: unset;

                .in-attack-content {
                    flex-direction: column;
                    width: 304px;
                    margin: 0 auto;

                .in-attack-header {
                    @include interstate;
                    font-size: px2rem(12);
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 39px;

                    i { 
                        margin-left: 5px;
                        font-size: px2rem(16);
                    }
                }
                .in-attack-values, .in-attack-labels {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                }
                .in-attack-values {
                    @include interstate;
                    font-size: px2rem(14);
                    color: black;
                    height: 28px;
                    align-items: center;
                    background-color: #F5F5F5;

                    .p1, .p2, .neutral {
                        height: 100%;
                        align-content: center;
                        text-align: center;
                    }
                    .p1, .p2 {
                        // width: 70px;
                        min-width: 10%;
                        color: $white;
                    }
                    .p1 {
                        background-color: $usoLtBlueBG;
                    }
                    .p2 {
                        background-color: $usoYellow;                        
                    }
                }
                .in-attack-labels {
                    height: 39px;
                    padding-top: 8px;
                    @include interstate(light);
                    font-size: px2rem(12);
                    text-transform: uppercase;
                }
                }
            }
        }
        .stats-data{
            width: 30%;
            height: $row-height;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
            z-index: 2;
            color: $gray_4a;
            &.t1, &.t2{
                text-align: center;
                .stat{
                    border: 2px solid rgba(#ffff, 0);
                    border-radius: 15px;
                    color: $md-gray4;
                    width: 95%;
                    max-width: 95px;
                }
            }

            &.t1 {
                &.leading .stat{
                    color: $gray-4a;
                    border: 2px solid $usoLtBlueBG;
                    @include interstate(medium);
                }
            }
            &.t2 {
                &.leading .stat{
                    color: $gray-4a;
                    border: 2px solid $usoYellow;
                    @include interstate(medium);
                }
            }
        }
        .stats-label{
            width: 50%;
            height: $row-height;
            line-height: $row-height;
            padding: 0px;
            text-align: center;
            font-size: 11px;
            position: absolute;
            left: 25%;
            z-index: 2;
            white-space: nowrap;
            color: $gray-4a;
            text-transform: uppercase;
        }
        .tooltip-inattack {
            position: absolute;
            width: 240px;
            height: 62px;
            @include interstate;
            font-size: px2rem(12);
            color: $white;
            right: 73px;
            top: 36px;
            cursor: pointer;
            padding: 8px 6px;
            background: $usoLtBlueBG;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            text-transform: none;
            z-index: 5;
        
            &::before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                top: -8px;
                right: 60px;
                box-sizing: border-box;
        
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid $usoLtBlueBG;
            }
        }
    }
    .serve, .return {
        width: 95%;
        margin: 5px auto;

        .stats-caption-table, .stats-header-table {
            width: 100%;
            display: flex;
            justify-content: space-between;
            border-collapse: collapse;
        }
        .stats-header-table {
            background: #F5F5F5;
        }
        .stats-header-caption {
            border: none;
            font-size: 16px;
            height: 50px;
            line-height: 50px;
            padding: 0px;
            width: calc(100% / 3);
            vertical-align: middle;
            @include interstate;
            display: flex;
            align-items: center;

            &.label {
                text-align: center;
                color: $usoLtBlueBG;
                text-transform: uppercase;
            }
            &.t1 {
                font-size: 12px;
                @include interstate();
                color: $powerrank-text;
            }
            &.t2 {
                @include interstate();
                font-size: 12px;
                text-align: right;
                justify-content: flex-end;
                color: $powerrank-text;
            }
            .doubles {
                line-height: 18px;
            }
        }
        .stats-header-cell {
            color: $gray-4a;
            height: 37px;
            width: 14%;
            font-size: 12px;
            @include interstate;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            &.first{
                order: unset;
            }
        }
        .stats-header-spacer {
            width: 95px;
            height: 37px;           
        }
        .stats-data-spacer{
            width: 95px;
            height: 37px;
        }
        .stats-table {
            border-collapse: collapse;
            width: 100%;
        }
        .stats-row {
            width: 100%;
            height: 34px;
            text-align: center;
            margin-bottom: 0px;
            display: flex;
            justify-content: space-around;
            position: relative;
            
            &.border {
                border-bottom: 1px solid #ECECEC !important;
                border-top: none !important;
                border-left: none !important;
                border-right: none !important;
            }
            &:first-child {
                border-top: 0px;
            }
            .stats-data {
                height: 34px;
                @include interstate;
                font-size: 12px;
                width: 14%;
                display: flex;
                align-items: center;
                justify-content: center;
                &.first{
                    order: unset;
                }
                // &.t1{
                //     color: $usoLtBlueBG;
                // }
                // &.t2{
                //     color: $usoYellow;                    
                // }
            }
            .stats-label {
                width: 100%;
                position: absolute;
                text-align: center;
                height: 34px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                @include interstate;
                font-size: 12px;
                color: $gray-4a;;
                text-transform: uppercase;
                vertical-align: middle;
            }
        }
    }
    .rally {
        padding: 0px;

        .stats-caption-table, .stats-header-table, .stats-sub-header-table {
            display: table;
            width: 100%;
            border-collapse: collapse;
        }
        .stats-header-table, .stats-sub-header-table {
            background: #F5F5F5;
        }
        .stats-sub-header-table {
            border-top: 2px solid rgba(255, 255, 255, 0.08);
        }
        .stats-caption-table{
            margin-top: 20px;
            display: flex;
            justify-content: center;
        }

        .stats-header-caption {
            font-size: 12px;
            height: 50px;
            padding: 0px;
            width: 40%;
            cursor: pointer;
            display: block;
            border: none;
            .border{
                display: none;
            }
            &:hover, &.selected{
                text-decoration: underline;
            }

            &.label {
                @include interstate;
                text-align: center;
                color: $md-gray2;
                width: 20%;
                text-transform: uppercase;
            }
            &.t1, &.t2{
                @include interstate();
                color: $powerrank-text;
                background-color: transparent;
                &:active{
                    background-color: transparent;
                }
            }
            &.t1 {
                font-size: 12px;
                
                .content{
                    // color: $usoLtBlueBG;
                    text-align: right;
                    padding-right: 0px;
                    margin-right: -1px;
                }
                .stats-player{
                    border-right: 1px solid $gray-light;
                    padding-right: 12px;
                    min-height: 25px;
                    text-align: right;
                }
                // &:active .content{
                //     color: $usoLtBlueBG;
                // }

            }
            &.t2 {
                font-size: 12px;
                .stats-player{
                    min-height: 25px;
                    text-align: left;
                    padding-left: 12px;
                }
                
                .content{
                    // color: $usoYellow;
                    text-align: left;
                }
                &:active .content{
                    // color: $usoYellow;
                }
            }
        }
        .stats-header-cell {
            color: $gray-4a;
            height: 37px;
            font-size: 11px;
            @include interstate;
            text-align: center;
            overflow: hidden;
            display: table-cell;
            width: calc(80% / 6);
            vertical-align: middle;
        }
        .stats-header-spacer {
            width: 20%;
            height: 37px;
            display: table-cell;
        }
        .stats-header-cell-small {
            width: calc(80% / 12);
            display: table-cell;
            vertical-align: middle;
            color: $gray-4a;
            text-align: center;
            font-size: 11px;
            @include interstate;
            text-transform: uppercase;
        }
        .stats-table {
            display: table;
            border-collapse: collapse;
            width: 100%;
            position: relative;
            &.t1, &.t2{
                .stats-row{
                    .stats-data{
                        @include interstate;
                        font-size: 12px;
                    }
                }
            }
            // &.t1{
            //     .stats-row{
            //         .stats-data{
            //             color: $usoLtBlueBG;
            //         }
            //     }
            // }
            // &.t2{
            //     .stats-row{
            //         .stats-data{
            //             color: $usoYellow;
            //         }
            //     }
            // }
        }
        .stats-row {
            &.border{
                border-bottom: 1px solid #ECECEC;
            }
            width: 100%;
            height: 34px;
            text-align: center;
            margin-bottom: 0px;
            display: table-row;
            position: relative;

            &:first-child {
                border-top: 0px;
            }
            .stats-data {
                width: calc(80% / 12);
                height: 34px;
                @include interstate();
                font-size: 16px;
                display: table-cell;
                vertical-align: middle;
            }
            .stats-label {
                width: 100%;
                position: absolute;
                text-align: center;
                height: 34px;
                display: flex;
                justify-content: center;
                align-items: flex-end;

                @include interstate;
                font-size: 12px;
                color: $gray-4a;;
                text-transform: uppercase;
                 vertical-align: middle;
            }
        }
    }
    .filter-container {
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: 35px;
        line-height: 35px;
        background: $white;
        font-size: 14px;
        @include interstate;
        padding-left: 1%;
        padding-right: 1%;
        margin-top: 33px;
        margin-bottom: 13px;

        form {
            padding: 0px;
            margin: 0px;
        }
        .serve-selection {
            width: 48%;
            text-align: left;
            color: $usoLtBlueBG;
            display: flex;
            justify-content: space-evenly;

            .radioBtn .checkmark{
                width: 13px;
                height: 13px;
                top: 50%;
                transform: translateY(-50%);
                left: 7px;
                &::after{
                    width: 5px;
                    height: 5px;
                }
            }
            span{
                font-size: 11px;
                white-space: nowrap;
                padding-left: 3px;
            }
        }
    }
    .directional-serve-stats {
        margin-top: 20px;
        
        .stats-header-row {
            width: 100%;
            height: 40px;
            border-bottom: 1px solid #ECECEC;
            display: table;
            border-collapse: collapse;

            button{
                &:active{
                    background: transparent;
                }
            }

            .generic-button_wrapper{
                width: 50%;
                display: table-cell;
                vertical-align: middle;
            }
            .stats-header-cell {
                width: 50%;
                display: table-cell;
                vertical-align: middle;
                cursor: pointer;
                border: none;
                background: transparent;
                .border{
                    display: none;
                }
                &:hover, &.selected{
                    text-decoration: underline;
                }
            }
            .stats-label { font-size: 16px; }
            .stats-player {
                @include interstate();
                color: $powerrank-text;
                font-size: 12px;
                                
                .short { display: none; }
                .long {
                    font-size: 12px;
                    display: block;
                }
                &.doubles { line-height: 18px; }
            }
            .t1 {
                height: 47px;
                .content{
                    padding-right: 0;
                    margin-right: -1px;
                }
                .stats-player { 
                    // color: $usoLtBlueBG;
                    text-align: right;
                    padding-left: 9px; 
                    .long{
                        border-right: 1px solid $gray-light;
                        padding-right: 12px;
                        min-height: 25px;
                    }
                }
            }
            .t2 {
                height: 47px;
                .stats-player { 
                    text-align: left;
                    padding-right: 9px; 
                    // color: $usoYellow;
                }
            }
        }
        .serve-stats-container {
            background: $white;
        }
        .p1container, .p2container {
            position: relative;
            width: 100%;
            height: 337px;
            padding-top: 30px;
            padding-bottom: 68px;
            background: url('/assets/images/scores/slamtracker/stats_dss_bg_small.png') 50% 30px no-repeat;
        }
        .stat-selection{
            padding: 0;
            width: 48%;

            .select-menu{
                margin: 0px;
                width: 100%;
                max-width: 303px;
            }
        }
        .serve-stats {
            width: 100%;
            height: 337px;
            position: relative;
            float: left;
            border: none;

            .box {
                height: 36px;
                width: 37px;
                line-height: 36px;
                position: absolute;
                top: 154px;
                text-align: center;
                font-size: 13px;
                @include interstate;

                &.multiline {
                    line-height: 18px;
                }
            }
            .p1dw {
                left: calc(50% - 91px);
            }
            .p1dc {
                left: calc(50% - 48px);
            }
            .p1db {
                top: 196px;
                left: calc(50% - 69px);
            }
            .p1ac {
                right: calc(50% - 44px);
            }
            .p1aw {
                right: calc(50% - 87px);
            }
            .p1ab {
                top: 196px;
                right: calc(50% - 66px);
            }
            .p2dw {
                left: calc(50% - 91px);
            }
            .p2dc {
                left: calc(50% - 48px);
            }
            .p2db {
                top: 196px;
                left: calc(50% - 69px);
            }
            .p2ac {
                right: calc(50% - 44px);
            }
            .p2aw {
                right: calc(50% - 87px);
            }
            .p2ab {
                top: 196px;
                right: calc(50% - 66px)
            }
            .flag { display: none; }
            .name {
                position: absolute;
                bottom: 0px;
                left: 0px;
                text-align: center;
                width: 100%;
                height: 38px;
                line-height: 38px;
                color: $white;
                font-size: 20px;
                @include interstate;
                text-transform: uppercase;
                background: $usoLtBlueBG;
                border-top: 2px solid $white;

                img { display: inline-block; padding: 0px 5px;}
            }
            .deuce {
                position: absolute;
                left: 0px;
                bottom: 73px;
                width: 50%;
                padding-right: 28px;
                text-align: right;
                color: $white;
                font-size: 12px;
                text-transform: uppercase;
                @include interstate();
            }
            .advantage {
                position: absolute;
                right: 0px;
                bottom: 73px;
                width: 50%;
                padding-left: 7px;
                text-align: left;
                color:$white;
                font-size: 12px;
                text-transform: uppercase;
                @include interstate();
            }
        }
    }
}

.webview {
    .slamtracker-matchstats-content {
        .scores-select-menu {
            .select-menu {
                padding: 0;
            }
        }
    }
}