
.slamtracker-momentum {
    .momentum-toolbar {
        width:100%;
        display:flex;
        flex-direction: row;
        justify-content: space-evenly;
        height:$momentum_toolbar_height;
        line-height: $momentum_toolbar_height;
        padding:0px 15px;
        //background:white;
        background:#F3f3f3;
        border-bottom:solid 1px $momentum_toolbar_border_color;

        .zoom-level {
            width: calc(100% / 3);
            text-align: left;
            display:flex;
            flex-direction: row;
            justify-content: flex-start;
            line-height: $momentum_toolbar_height;
            
            .zoom-wrapper {
                position: relative;
                display:flex;
                width:auto;
                &:before {
                    position: absolute;
                    top:-10px;
                    text-align: center;
                    width:100%;
                    margin:0px auto;
                    //@include gotham(medium);
                    font-size:px2rem(9);
                    text-transform: uppercase;
                    color: #888B8D;
                }
            }
            .icon-increase, .icon-reduce {
                position: relative;
                font-size: px2rem(18);
                line-height: $momentum_toolbar_height;
                color: #888B8D;
                padding:0px 5px;
                cursor: pointer;
                z-index: 1;
                &.disabled {
                    color:$gray;
                    cursor: default;
                }
            }
            .zoom-item {
                padding:0px 1px;
                //cursor: pointer;
                &.zoom-match, &.zoom-set, &.zoom-game {
                    //font-size:px2rem(10);
                    color:$gray;
                    &:before {
                        content: ' \2501';
                        font-size:10px;
                    }
                    &.selected {
                        color:#000000;
                    }
                }
            }
        }

        &.zoom-game {
            .zoom-wrapper {
                &:before {
                    content:"Game"
                }
            }
        }
        &.zoom-set {
            .zoom-wrapper {
                &:before {
                    content:"Set"
                }
            }
        }
        &.zoom-match {
            .zoom-wrapper {
                &:before {
                    content:" Match"
                }
            }
        }
        .filter-container {
            width: calc(100% / 3);
            display: flex;
            flex-direction: row;
            justify-content: center;

            .set-filter {
                //width:30%;
                text-align: center;
                &.disabled {
                    .select-menu {
                        .title {
                            color:#b9b9b9
                        }
                    }
                }
            }
            .game-filter {
                //width:30%;
                text-align: center;
                &.disabled {
                    .select-menu {
                        .title {
                            color:#b9b9b9
                        }
                    }
                }
            }

            .select-menu {
                .title {
                    font-size: px2rem(10);
                    padding: 0px 15px;
                    height: $momentum_toolbar_height;
                    line-height: $momentum_toolbar_height;
                }
                .menu-arrow {
                    position: absolute;
                    top: 16px;
                    right: 0px;
                }
            }
            .select-menu-dropdown {
                padding: 0 2px;
                display: none;
                flex-direction: column;
                min-width: 130px;
                &.show {
                    display:flex;
                    position: absolute;
                    top:45px;
                }
                .option {
                    margin: 8px 0 4px;
                    align-self: center;
                    padding: 0 20px 0 8px;
                    display: flex;
                    color: #888B8D;
                    i {
                        flex: 0 0 auto;
                        margin-right: 5px;
                        margin-top: 4px;
                        text-align: unset;
                        position: inherit;
                        left: unset;
                        top: unset;
                    }
                    span {
                        flex: 1 0 auto;
                        font-size:px2rem(10);
                    }
                }
                //font-size: px2rem(ll);
            }
        }
       
        .settings {
            width: calc(100% / 3);
            text-align: right;
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            i {
                height: $momentum_toolbar_height;
                line-height: $momentum_toolbar_height;
            }
            .icon-gear {font-size:px2rem(18); color: #888B8D;}
            .icon-gear-solid {font-size:px2rem(18); color: #888B8D;}

            .settings-toggle {
                cursor: pointer;
            }
        }
        .content-main.fixed & {
            position:fixed;
            top:calc(#{$page_header_height_mobile} + #{$pinned_scoreboard_height} - 1px);
            left:0px;
            z-index: 5;
            border-top:solid 2px  #888B8D;
            border-top:none;
        }

        &.zoom-game {
            justify-content: space-between;

            .zoom-level {width:auto}
            .settings {width:auto}
            .filter-container {width:auto}
        }
    
       
    }
    .settings-wrapper {
        //position: absolute;
        //top:calc(#{$momentum_toolbar_height});
        //width: 100%;
        //z-index: 2;
        background-image: url("/assets/images/scores/slamtracker/backgrounds/momentum_settings_bkg.jpg") ;
        background-size:cover;
        background-attachment: fixed;
        color:$white;
        text-align: center;
        font-size: px2rem(12);
        padding: 10px 20px 60px;
        //overflow-y:auto;
        //-webkit-overflow-scrolling: touch;
        //height:calc(100vh - 73px - 150px - 11px - 20px - 7px - #{$momentum_toolbar_height});
        //max-height:100vh;

        // min-height: calc(100% - 30px);
        // height: auto;

        .settings-arrow {
            position: absolute;
            top:10px;
            left:100px;
        }
        h4 {
           // @include gotham(bold);
            font-size: px2rem(12);
            text-transform:uppercase;
            margin-block-start: px2rem(10);
            margin-block-end:px2rem(10);
        }
        hr {
            margin:20px 0px;
        }
    
        .settings-options {
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            padding:0px 20px;
            
            .settings-item {
                position:relative;
                width:54px;
                height:25px;
                border-radius:90px;
                background-color:rgba(255,255,255,.4);
                border:solid 1px  #888B8D;
                margin:18px 10px;
                
                .label {
                    position:relative;
                    bottom:-10px;
                    display:inline-block;
                    width:calc(54px + 20px);
                    left:-10px;
                    text-transform: uppercase;
                    font-size:px2rem(8);
                    //@include gotham(medium);
                }
                .toggle {
                    width:17px;
                    height:17px;
                    position: relative;
                    top:3px;
                    left:3px;
                    border-radius: 90px;
                    border-color: #888B8D;
                    background:$white;
                    .label {
                        width:100%;
                        height:100%;
                        top:0px;
                        left:0px;
                        color:$black;
                        @include interstate(medium);
                    }
                }
    
                &.selected {
                    background-color:rgba(255,255,255,1);
                    .toggle {
                        background-color: #00288C;
                        left:32px;
                        .label {
                            color:$white;
                        }
                    }
                }
            }
        }
    }
    .settings-wrapper + #momentum_panel {
        //visibility: hidden;
        display:none;
        //height:0px;
        //height:calc(100vh - 73px - 150px - 11px - 20px - 7px);
    }
    @include general-breakpoint(tablet) {
        .settings-wrapper {
            //min-height: calc(100vh - 261px - 56px - 56px - 56px - 11px);
            //height:auto;
            //top:calc(56px + 56px);
        }
    }
}

.content-main {
    &.fixed {
        .slamtracker-momentum {
            .settings-wrapper {
                position: fixed;
                height: calc(100vh - 56px - 113px - 41px);
                top: calc(56px + 113px + 41px);
                left: 0px;

                + #momentum_panel {
                    display:block;
                    height:calc(100% - 56px - 56px)
                }
            }
            
        }
    }
}
