h1+.help-container {
    margin-top: -1rem;
}

.tickets-header {
    position: relative;
}

.help-container {
  position: absolute;
  z-index:100;
  top: 0;
  background-color: $usoDkerBlue2;
  color: $white;
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid $white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  text-align: center;
  width: 100%;    
  font-size: 1.3rem;

    @include general-breakpoint(landscape) {
        padding-top: 15px;
        padding-bottom: 15px;
        justify-content: center;
    }

    @include general-breakpoint(tablet) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    a {
      color: $white;
      display: block;
      text-decoration: none;
      // margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }

      @include general-breakpoint(landscape) {
        margin-right: 7%;
      }
    }

    span {
        background: transparent no-repeat 0 0;
        background-size: 25px;
        float: left;
        margin-top: 0;
        margin-right: 5px;
        text-indent: -9999px;
        overflow: hidden;
        width: 28px;
        min-height: 24px;

        @include general-breakpoint(landscape) {
          background-size: 30px;
          margin-right: 10px;
          width: 32px;

          &.phone {
            background-size: 25px;
          }
        }
    }

    .chat {
        background-image: url('/assets/images/misc/help_icons_chat_white.png');
    }

    .email {
        background-image: url('/assets/images/misc/help_icons_email_white.png');
    }

    .phone {
        background-image: url('/assets/images/misc/help_icons_phone_white.png');
        margin-right: 0;
    }

    .schedule, .calendar {
      background-image: url('/assets/images/misc/help_icons_schedule_white.png');
      margin-top: 3px;
    }

    @include general-breakpoint(tablet) {
        font-size: 1.4rem;

        span {
            width: 40px;
            // margin-top: 8px;
        }

        //   .chat {  background-position: 0 3px; padding-left: 33px; }
        //   .email { background-position: -5px -26px; padding-left: 55px; }
        //   .phone { background-position: -40px -53px; padding-left: 40px; }
    }

    &.top {
        margin-top: 0;
        background-color: transparent;
        position: absolute;
        display: none;

        @include general-breakpoint(landscape) {
            width: 45%;
            top: 50px;
            right: 0;
            font-size: calc((14/14) * 1em);
            display: block;

            a {
                line-height: 30px;
            }

            span {
                background-size: 16px;
                height: 16px;
                width: 18px;

                &.phone {
                    margin-top: 6px;
                }
            }
        }

        @include general-breakpoint(tablet) {
            width: 50%;
            top: 50px;
            right: 0;
            font-size: calc((18/14) * 1em);

            a {
                line-height: 36px;
            }

            span {
                background-size: 30px;
                height: 36px;
                width: 40px;

                &.phone {
                    margin-top: 3px;
                }
            }
        }

        @include general-breakpoint(desktop) {
            width: 45%;
        }
    }
}

.ticket_plans {
  .hero_container {
    .hero_content {
      .hero_buttons {
        top: 70px;
        
        .hero_button {
          a {
            text-transform: none;
            @include interstate(bold);
            border-width: 2px;
            font-size: 18px;
            white-space: nowrap;

            // .navItem {
            //   .arrow-icon {
            //     transform: rotate(90deg);
            //     margin-top: -20px;
            //     padding-bottom: 27px;
            //     letter-spacing: -6px;
            //   }
            // }
          }
        }
        @include general-breakpoint(smtablet){
          justify-content: center;

          .landing_title {
            min-height: 75px;
          }
          .hero_button {
            flex: 0 0 334px; //RLL 8/2022 - width came from sketch file
            max-width: 334px;
          }
        }
      }
    }
    @media screen and (min-device-width: 639px) and (max-device-width: 959px) {
			margin-bottom: -250px;
			&.buttons {
				padding-bottom: 83.25%;
			}
		}
		@media screen and (min-device-width: 375px) and (max-device-width: 639px) {
			// margin-bottom: -250px;
			&.buttons {
				min-height: calc(100vh - 280px) !important;
			}
		}
    @include general-breakpoint(smtablet) {
      margin-bottom: -150px;
    }
    @include general-breakpoint(desktop) {
			margin-bottom: -40px;
		}
  }
}

$theme-green: $usoGreen;
$theme-orange: $usoOrange;
$theme-yellow: $usoYellow;
$theme-red: $usoRed;
$theme-blue: $usoDkBlue;


.individual_tickets {
  button.session-btn, button.load-more-button {@include button(basic); border: none;
  }
    button.load-more-button {margin: 30px auto; }
    .general_content {

    .landing_title {
      h1 {
        text-transform: uppercase;
        color: $white;

        .alt {
            color: #FFD400;
        }
      }
      p {
        @include interstate(light);
     }
    }
    
    @mixin heroContainerStyles($num:0) {
      @if ($num == 1) {
        margin-bottom: calc((1 * 55px)) !important;
      } @else if ($num > 1) {
        margin-bottom: calc((#{$num} * 55px) + 11px) !important;
      }
    }
   
    @include general-breakpoint(smtablet, max) {
      .hero_container.buttons {
        min-height: 400px;
        max-height: 540px;
        &[data-num-buttons="1"] {
          @include heroContainerStyles(1);
        }
        &[data-num-buttons="2"] {
          @include heroContainerStyles(2);
        }
        &[data-num-buttons="3"] {
          @include heroContainerStyles(3);
        }
        &[data-num-buttons="4"] {
          @include heroContainerStyles(4);
        }
        &[data-num-buttons="5"] {
          @include heroContainerStyles(5);
        }
        &[data-num-buttons="6"] {
          @include heroContainerStyles(6);
        }
        &[data-num-buttons="7"] {
          @include heroContainerStyles(7);
        }
        &[data-num-buttons="8"] {
          @include heroContainerStyles(8);
        }

       //.hero_container .hero_content .title_container .hero_button
       .title_container {
        height: calc(100% - 70px);
        display: flex;
        flex-wrap: wrap;

          .hero-button-container {
              position: absolute;
              width:100%;
              top: calc(100% + 21px);
            }
        }
       .hero_button {
        max-width: 100%;
        margin-bottom:0px;
        margin-top:0px;
        margin-bottom:11px;
       }
      }
    }


    @include general-breakpoint(smtablet) {
      .hero_container.buttons {
        .hero_button {
          max-width: 50%;
          flex: 0 0 50%;
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
    .hero_content {
      .title_container {
        top: 70px;
        height: calc(100% - 70px);
        display: flex;
        flex-wrap: wrap;
      }

      @include general-breakpoint(landscape) {
        .title_container {
          .hero-button-container {
            display:flex;
            flex-direction: row;
            width:100%;
            flex-wrap: wrap;
            justify-content: center;
            align-content: flex-start;

            .hero_button {
              flex: 0 0 100%;
              margin-bottom: 11px;
            }
          }
        }
      }
    }
    .hero_image {
        .title {
          // margin-top: -145px;
        }
    }
    .uso-tickets-calendar {
        z-index: 20;
        @extend .wrapper; // include the global margin set up
        //top: -10px;
        margin-bottom: -10px;
        margin-top:-10px !important;

        @include general-breakpoint(landscape) {
          left: 50%;
          transform: translate(-50%);
        }

        @include general-breakpoint(smtablet) {
          // position: absolute !important;
          z-index: 100;
          // top: -230px;
        }

        @include general-breakpoint(desktop) {
          // top: -250px;
        }
    }

    // .uso-ticket-calendar + section {
    //   margin-top: -75px;
    // }

    .date-as-calendar-mobile, .date-as-calendar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height:100%;
      align-items: center;

      @include general-breakpoint(smtablet) {
        padding: 0px 7px;
      }

      .weekday, .day, .month, .fw-week, .fw-day {     
        display: block;
        line-height: 1.0;
        height: 1em;
        left: 0;
        right: 0;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        margin: 3px; 
        @include interstate(light);
        position:relative;
        top:auto;
        bottom:auto;
    
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 20px;

        span {
          width: calc(50% - 12px);
        }
        span:nth-child(2) {
          width:24px;
        }
        
      }
    }

    // .date-as-calendar{
    //   overflow:inherit; display: flex; flex-direction: column; align-items: center; justify-content: center;height: 100%;
    //   .fw-week{ bottom: 0px;}
    // }

    .premium-section{
        @include general-breakpoint(landscape){top: unset;}
    }
    .selectDatesInfo {
        .ticketWrapper {
          background:none;
        }
        .date {
          display: inline-flex !important;
          flex-direction: column;
          justify-content: center;
          //width: calc((100% / 7)* 2);
          height: 81px;
          border: 1px solid #d8d8d8;
          @include general-breakpoint(smtablet) {
            width: calc((100% / 14));
          }
          &.fanweek {
            width:115px;
          }
          
        }
    }
    .titleHeader.date-group {
      height:80px;
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:100%;
      gap: 20px;
      color: $text-color;
      text-transform: uppercase;
      .header-month {font-size: px2rem(16); @include interstate(light); }
      .header-day {font-size: px2rem(36); @include interstate(exlight); }
      .header-week {font-size: px2rem(16); @include interstate(light);}
    }
    }
    .selectTimeWrapper {
      width: 342px;
      margin:0px auto;
    }
    .selectTime {background:none; width:100%;
      .tickets-select-menu {margin:0px auto; width:100%; position:relative;
        .tickets-dropdown-select {width:100%; border-color: $usoLtGray}
        .tickets-menu-arrow {
          position: absolute;
          //right: 200px;
          height: calc(100% - 2px);
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
    .helper-wrapper {
      margin-top: 25px;
      margin-bottom:34px;
    }
    .helper-text {
      @include interstate(bold);
      font-size: px2rem(18);
      color: $dark-blue3;
      line-height: px2rem(21.6);
    }
    .helper-instruction {
      @include interstate(light);
      font-size: px2rem(14);
      line-height: px2rem(16.8);
    }
    .calendar-wrapper {
      max-width: 728px;
      margin:0px auto 17px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &:before {
        content: 'TOURNAMENT';
        left: 0;
        top: -24px;
        height: 24px;
        line-height: 23px;
        width: 100%;
        background-color: #001D67;
        color: white;
        text-align: center;
        font-family: "interstate", Helvetica, Arial, sans-serif;
        font-weight: 500 !important;
        font-size: 14px;
        border: 1px solid #888b8d;
        border-bottom: 0;
        display: inline-block;
      }
      &.fanweek {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:before {
          content: 'FAN WEEK';
          width: 115px;
        }
      }
      
    }
    .calendar-header {
      background-color: $usoDkerBlue2;
      height:24px;
      color: $white;
      @include interstate(light);
      line-height: 24px;
      &.fanweek {width: 115px;}
      margin:0px auto;
    }
    .stadium-ticket-wrapper {
      margin-top:50px;
      &.fanweek {
        margin-top: 30px;
      
      }
    }
    .theme-card-wrapper {

    }
    .theme-card {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      /* height: 100%; */
      position: relative;
      line-height: normal;
      border-radius: 8px;
      border: solid 1px $dk-gray;
      border-top: solid 10px $dk-gray;
      width:calc(100% - 20px);
      margin:10px auto;
      background-color: white;
      text-align: center;
      padding:20px;

      &.theme-blue {border-top-color: $theme-blue;}
      &.theme-green {border-top-color: $theme-green;}
      &.theme-red {border-top-color: $theme-red;}
      &.theme-orange {border-top-color: $theme-orange;}
      &.theme-yellow {border-top-color: $theme-yellow;}

      .title {
        @include interstate(bold);
        font-size: px2rem(24);
        line-height: 22px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom:20px;
      }
      .description, .description p {
        @include interstate(exlight);
        font-size: px2rem(16);
        line-height: 19px;
      }
      .link {
        margin-top:20px; 
        @include interstate(bold); 
        text-transform: uppercase; 
        font-size: px2rem(16); 
        line-height: 18px;
      }
    }
    .session-group {
      display:flex;
      flex-direction: column;
      gap: 30px;
      margin-top:20px;
      margin-bottom:20px;

      &:empty:before {
        content: 'There are no events available for the selected date and time period.';
      }

      @include general-breakpoint(smtablet) {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
      }

      .ticket-session-card {
        background-color: $white;
        //height:439px;
        display:flex;
        flex-direction: column;

        @include general-breakpoint(smtablet) {
          width: calc((100% / 2) - 20px);
        }

        @include general-breakpoint(smdesktop) {
          width: calc((100% / 3) - 20px);
          
        }

        &.fanweek {
          .card-info-wrapper {
            height:172px;
          }
          @include general-breakpoint(smtablet) {

          }
          .session-title {display:none;}
        }
      }
      .card-info-wrapper {
        padding: 16px;
        position: relative;
        height:100%;
        height: 228px;
      }
      .session-event {
        @include interstate(bold);
        font-size: px2rem(18);
        line-height:20px;
        margin-bottom: 16px;
      }

      .session-title {
        @include interstate(bold);
        font-size: px2rem(16);
        line-height:20px;
        margin-top:18px;
        margin-bottom:16px;
      }
      
      .session-date, .session-number {
        font-size: px2rem(14);
        line-height:18px;
        margin-top:8px;
      }

      .card-btn-wrapper {
        display:flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
        margin:0px auto;
        bottom: 16px;
        position: absolute;
        width: calc(100% - 32px);
        left: 16px;
        button.info{
          @include button(oppbasic);
        }
        button.session-btn, .btn-tix-link {
          width: calc((100% / 2) - 10px);
        }
        .btn-tix-link{
          text-decoration: none;
          cursor: pointer;
          .session-btn.tix{ width: 100%!important;}
        }
      }
    }
   
  }

.individual-tickets-modal {
  button.session-btn {@include button(basic); border: none;}

  .modal-overlay-div {
    z-index: 100;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 40, 140, .9);
  }
  .modal-content-div {
    overflow:hidden;
  }
  .modal-dialog-div {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    position: relative;
    top: 80px;
    width: calc(100vw - 30px);
    max-width: 345px;
    margin: 0px auto;

    @include general-breakpoint(smdesktop) {
      max-width: 539px;
    }
  }
  .modal-content-wrapper {
    background-color: white;
    border-top: solid 10px $usoYellow;
    text-align: left;
    .container {
      padding:10px 20px;
    }
    .section-title {
      height: 30px;
      background: #00288c;
      @include interstate(bold);
      font-size: px2rem(14);
      text-transform: uppercase;
      padding: 0px 22px;
      line-height: 16px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      color: $white;
    }
    .event {
      @include interstate(bold);
      font-size: px2rem(18);
      line-height: 20px;
      margin-top:10px;
    }
    .session {
      @include interstate(light);
      font-size: px2rem(16);
      line-height: 20px;
    }
    .title {
      @include interstate(bold);
      font-size: px2rem(14);
      height: 34px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .date {
      @include interstate(light);
      font-size: px2rem(14);
      line-height: 18px;
    }

    .theme-wrapper {
      .title {
        font-size: px2rem(16);
        line-height: 19px;
        margin-bottom: 7px;
        margin-top:24px;
      }
      .description, .description p {
        @include interstate(exlight);
        font-size: px2rem(14);
        line-height: 17px;
      }
    }

    .description-wrapper {
      border-bottom: solid 1px $dk-gray;
      .container {
        background-color: $lt-gray;
        ul {
          padding-inline-start: 20px;
          @include interstate(exlight);
          font-size: px2rem(14);
          line-height: 18px;
          li {margin-bottom: 7px;}
        }
      }
    }

    .modal-btn-wrapper {
      display:flex;
      flex-direction: row;
      gap: 20px;
      justify-content: center;
      margin:0px auto;
      bottom: 16px;
      width: 100%;
      button.session-btn {
        width: 171px;
      }
    }
   
  }
  .close-icon {
    font-size: 15px;
    color: white;
    line-height: 15px;
    margin-bottom: 20px;
    text-align: right;

    .icon-close {
      margin-left: 0;
      right: 0;
      top: -8px;
      font-size: 16px;
    }
  }
}

/****** Abailable Tickets Table using Divs
   >460 - same loook as table.blue style (create table using divs)
   320 - shift layout
*******/
$ticket_content_width: 1240px; /* actual content area in 960px design */

@function tickcp($width) { @return cp($width, $ticket_content_width); }

.ticket_plan_wrapper { display: block; position: relative; width: 100%;
  h3 { padding-left: $general_inner_margin;
    &:first-child { padding-bottom: $general_inner_margin; padding-top: $general_inner_margin; }
  }
  @include general-breakpoint(landscape){
    padding: $general_outer_margin; margin-top: 0; padding-bottom: 0;
    h3 { padding-left: 0;
      &:first-child { padding-bottom: 0; padding-top: 0; }
    }
  }
  /* individual tickets cols */
  .ticket_ticket_type, .ticket_event, .ticket_events, .ticket_view_button { width: 100%; overflow: hidden; vertical-align: middle; }
  .ticket_single_date { width: 35%; display: inline-block; }
  .ticket_session { width: 40%; display: inline-block; @include interstate(light); font-size: calc((18/14) * 1em); }
  .ticket_time { width: 25%; display: inline; }
  .ticket_view_button{ text-align: center;
    a { text-decoration: none; }
    span { border-radius: 3px; font-size: calc((13/14) * 1em); padding: 5px $general_inner_margin; text-transform: uppercase; white-space: nowrap;
      &.ticket_see { background-color: $button_dark_color;}
    }
  }
  @include general-breakpoint(landscape) {
    .ticket_single_date { width: tickcp(80px); }
    .ticket_session { width: tickcp(105px); text-align: center; }
    .ticket_time {width: tickcp(172px); text-align: center; }
    .ticket_ticket_type {width: tickcp(302px);}
    .ticket_event{width: tickcp(430px);}
    .ticket_events{width: tickcp(430px);}
    .ticket_view_button{width: tickcp(144px);
      span { font-size: calc((10/14) * 1em); padding: 3px $general_inner_margin; }
    }
  }

  /* package types cols */
  .ticket_package_type { width: 50%; }
  .ticket_total_sessions, .ticket_starting_price, .ticket_status, .ticket_purchase_button { width: 100%; overflow: hidden; }
  .ticket_dates { min-width: 140px; text-align: center; position: absolute; top: $general_inner_margin; left: 50%;   }
  .ticket_purchase_button { width: 100%; text-align: center;
    a { text-decoration: none; }
    span { border: solid 1px $white; border-radius: 3px; font-size: calc((13/14) * 1em); padding: 5px $general_outer_margin; white-space: nowrap;
      &.ticket_buy_now { background-color: $red;}
      &.ticket_deposit { background-color: $cyan;}
    }
  }
  @include general-breakpoint(landscape) {
    .ticket_package_type { width: tickcp(352px); }
    .ticket_total_sessions { width: tickcp(137px); text-align: center; }
    .ticket_dates { width: tickcp(213px); position: relative; top: 0; left: 0; }
    .ticket_starting_price { width: tickcp(196px); text-align: center; }
    .ticket_status{ width: tickcp(159px); text-align: center; }
    .ticket_purchase_button { width: tickcp(175px);
      span { font-size: calc((10/14) * 1em); padding: 3px $general_inner_margin; }
    }
  }
  .ticket_session_num { @include interstate(light); color: $white; font-size: calc((18/18) * 1em); padding: 0; text-align: left; text-transform: uppercase; vertical-align: middle;
    @include general-breakpoint(landscape) { display: none; }
  }
  .label { @include interstate(medium); float: left !important; color: $white; font-size: calc((10/14) * 1em); padding: 0.3em 0 0.3em $general_inner_margin; text-transform: uppercase; vertical-align: middle; width: 50%;
    @include general-breakpoint(landscape) { display: none; }
  }
  /* override label width for indivisual ticket page */
  .ticket_ticket_type, .ticket_event, .ticket_events  {
    .label { width: 35%; }
  }
  .ticket_table { display: table; margin: $general_inner_margin 0; position: relative; width: 100%; }
  .header, .ticket_row { overflow: hidden; position: relative;  width: 100%;
    @include general-breakpoint(landscape){ display: table-row; }
    div { @include interstate(medium); font-size: calc((12/14) * 1em); padding: 0.7em 0; text-transform: uppercase; vertical-align: middle;
      @include general-breakpoint(landscape){ display: table-cell; }
    }
    .ticket_package_type { padding-left: $general_outer_margin; }
  }
  .header { display: none;
     @include general-breakpoint(landscape){ display: table-row; }
     .ticket_single_date { text-align: center; }
  }
  .ticket_row { padding-bottom: $general_inner_margin;
        &.hide { display: none; }
        &.disabled { display: none; }
        &.show { display: table-row;} /* display:block will create misalignments */
      @include general-breakpoint(landscape){ padding-bottom: 0; }
      &:nth-child(odd),&.odd { background-color: $blue2; }
      &:nth-child(even),&.even { background: $light_blue; }
      div { @include interstate(right); font-size: calc((14/14) * 1em); padding: 0.3em 0; text-transform: none;
        .ticket_date_range { display: inline-block; padding: 0; } /* overwrite display:table that's applied in .ticket_plan_wrapper .ticket_row div */
       }
       a { display: block; padding-top: 0.5em; padding-bottom: 0.5em; }
      .ticket_single_date { padding-left: $general_inner_margin; padding-top: $general_inner_margin;
        @include general-breakpoint(landscape){ padding-left: 5px; padding-top: 0; }
      }
      .ticket_ticket_type { @include interstate(medium); }
      .ticket_session, .ticket_time { font-size: calc((18/14) * 1em); padding-top: $general_inner_margin;
        @include general-breakpoint(landscape){ font-size: calc((14/14) * 1em); padding-top: 0; }
      }
      .ticket_package_type { font-size: calc((18/14) * 1em); padding-right: $general_outer_margin; padding-left: $general_inner_margin;
        @include general-breakpoint(landscape){ padding-right: 0; padding-left: $general_outer_margin; }
      }
      .ticket_status { color: $status_color; }
  }

  .noTickets { width: 100%; overflow: hidden; font-size: 1.3em; text-align: center; padding: 30px; }
}
/****************************************/
/* calendar looks like dates - square */
/*
  .date-as-calendar === no layout change throughout the screensize
  .date-as-calendar-range === from date through date, prints out dates in <landscape
  .date-as-calendar-responsive == prints out dates in <landscape
*/
.date-as-calendar { @include interstate; background: $white; /*border: 2px solid $bright_blue;  color: $bright_blue; */ display: inline-block; overflow: hidden; position: relative; 
  // height: 70px; width: 40px; 
  .weekday, .day, .month, .fw-week, .fw-day { display: block; line-height: 1.0; height: 1em; left: 0; right: 0; position: absolute; text-transform: uppercase; width: 100%; text-align: center; color: $usoDkGray; }
  .month { @include interstate(regular); font-size: px2rem(10);  top: 5px; padding: 0.1em 0; }
  .day, .fw-day { @include interstate(light);  font-size: px2rem(24); top: 17px;}
  .weekday, .fw-week { @include interstate(regular); bottom: 10px; font-size: px2rem(10); }
  .fw-week { @include interstate(light);  font-size: px2rem(14);line-height: 16px;}
  
}
.date-as-calendar-range, .date-as-calendar-responsive { @include interstate; color: $white; display: inline-block; overflow: hidden; position: relative;
  .weekday, .day, .month, .fw-day, .fw-week { display: inline-block; text-transform: uppercase; }
  .month { @include interstate(light); font-size: calc((14/14) * 1em);  padding: 0.1em 0; }
  .day { @include interstate(regular);  font-size: calc((24/14) * 1em);  }
  .weekday {display: none; }
}
.date-as-calendar-mobile{ display: flex; color: $dk-gray2;
  flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: 100%;
  @include general-breakpoint(landscape){ /*display: none;*/ }
  .weekday, .day, .month, .fw-week, .fw-day {     
    display: block;
    line-height: 1.0;
    height: 1em;
    left: 0;
    right: 0;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    margin: 3px; 
    @include interstate(light);

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }
  .month { font-size: calc((10/14) * 1em);  top: 5px; padding: 0.1em 0; }
  .day { font-size: calc((22/14) * 1em); top: 0.75em; }
  .weekday { bottom: 10px; font-size: calc((10/14) * 1em); }
  .fw-week{ font-size: 13px; line-height: 10px;}
  .fw-day { font-size: 22px; line-height: 22px; }
}
@include general-breakpoint(smtablet, max){
  .date-as-calendar{
    overflow:inherit; display: flex; flex-direction: column; align-items: center; justify-content: center;height: 100%;
    .fw-week{ bottom: 0px;}
  }
}
@include general-breakpoint(landscape){
  .date-as-calendar-range { @include interstate; background: $blue; color: $white; display: inline-block; height: 4.3em; overflow: hidden; position: relative; width: 3.6em;
    .weekday, .day, .month { display: block; line-height: 1.0; height: 1em; left: 0; right: 0; position: absolute; text-transform: uppercase; width: 100%; text-align: center; }
    .month { @include interstate(light); font-size: calc((12/14) * 1em);  top: 5px; }
    .day { @include interstate(regular);  top: 0.75em; }
    .weekday { @include interstate(light); bottom: 0; font-size: calc((12/14) * 1em); }
  }
  .date-as-calendar{
      .fw-week{bottom: 10px;}
  }
}
.ticket_date_range { padding: 0; margin: 0;
  @include general-breakpoint(landscape){ background: $blue; border: 2px solid $white; }
  .dash { display: inline-block; margin-top: -16%; vertical-align: middle;
    @include general-breakpoint(landscape){margin-top: -45%;  }
  }
}
@include general-breakpoint(landscape){
  .date-as-calendar-responsive { @include interstate; background: $blue; border: 2px solid $white; color: $white; display: inline-block; height: 4.3em; overflow: hidden; position: relative; width: 3.6em;
    .weekday, .day, .month, .fw-week, .fw-month, .fw-weekday { display: block; line-height: 1.0; height: 1em; left: 0; right: 0; position: absolute; text-transform: uppercase; width: 100%; text-align: center; }
    .month { @include interstate(light); font-size: calc((12/14) * 1em);  top: 5px; padding: 0.1em 0; }
    .day { @include interstate(regular);  font-size: calc((24/14) * 1em); top: 0.75em; }
    .weekday { @include interstate(light); bottom: 0; font-size: calc((12/14) * 1em); }
  }
}
/****************************************/
/* calendar looks like button - rectangle */
/*
  .date-as-button === no layout change throughout the screensize
  .date-as-button-responsive == prints out dates in <landscape
*/
.date-as-button {
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  @include interstate;
  color: $white;
  background-color: $bright_blue;
  margin: 22% 0 11% 0;
  overflow: visible;
  .month {
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    color:  $md-gray;
    height: auto;
  }
  .weekday, .day {
    position: relative;
    display: inline;
    bottom: auto;
    line-height: 40px;
    font-size:  1rem;
    left: auto;
    right:  auto;
    text-align: unset;
    top:  auto;
    margin: .2rem;
    @include general-breakpoint(tablet) { font-size: calc((16/14) * 1em); }
  }
}

.text_section {
    padding-top: 20px; 
    padding-bottom: 20px; 
    font-size: 1.4rem; 
    color: $dk-gray2; 
    margin: -10px auto 0 auto; 
    max-width: 1280px;
    // @include media-breakpoint-up(sm) {
    //   @include make-row();
    //   justify-content: center !important;
    // }
    .rich-text {
      @include make-col-ready();
      // @include media-breakpoint-up(sm) {
      //   @include make-col(12);
      // }
      margin: auto;
    }
    &.gray { max-width: 100%;
      > div {
        max-width: 1280px;
        margin: auto;
      }
    }
    &.white { background-color: $white; }
    p + p {
        margin-bottom: 0;
    }
}

.accordion {
    overflow: hidden;
    width: 100%;
    
    .hero-rt{
      position: absolute;
      top: 85px;
      left: 0px;
      right: 0px;
      color: $white;
      background: transparent;
      z-index: 20;
      td{
        img{ width: 187px; height: auto;
          @include general-breakpoint(smtablet){width: 213px; height: 93px; }
        }
      }
      td:first-child{
        img{ width: 160px; height: auto;
          @include general-breakpoint(smtablet){width: 200px; height: auto; }
        }
      }
    }
    .m16x9 { padding: 0px;}
}

.accordion-container { 
  margin: 0px auto;  z-index: 20; position: relative; 

  @include general-breakpoint(smtablet){margin-top: -80px;}

    .accordion-header {
      background: $white no-repeat 95% 50%;
      color: $usoDkGray;
      @include interstate(regular); font-size: 18px; line-height: 34px; text-transform: uppercase;
      border: solid 1px #C1C6C8;
      margin-top: 20px;
      padding: 0.5em;
      width: 100%;
      padding-top: 10px;
      cursor: pointer;
      &[aria-selected='true'], [aria-expanded='true'], &[aria-selected='false'], [aria-expanded='false'] {
        .ui-icon{ 
          font-size: px2rem(10);
          float: right;
          color: $usoLtBlue;
        }
      }
      h3 {
          padding-top: 0.5rem;
      }
    }
    .accordion-cnt {
      // padding: 5px; 
      background-color: $usoBkgGray; width: 100%; padding: 0px 20px 20px; display: inline-block;
      @include general-breakpoint(smtablet){display: inline-flex; flex-direction: row; flex-wrap: wrap; gap: 20px 15px;
      justify-content: space-around; align-content: space-around; }

      .premium-section{ 
        top: 0px!important; 
        padding-bottom: 20px;
        @include general-breakpoint(smtablet){ 
          margin: 0px!important;
          width: 44%!important;
          padding-bottom: unset;
        }
        .premium-content{ background-color: $usoLtBlue; color: $white;max-height: 395px;overflow: hidden; padding-left: 10px;
          .image{ 
            max-width: 40%; z-index: 10;
            img{ width: auto; height: 200px!important;
              @include general-breakpoint(smtablet){height: 190px!important;}
              @include general-breakpoint(tablet){height: 338px!important;}
            }
          }
          .text{ position: absolute; right: 0; width: 70%; padding: 0px; max-width: unset;
            @include general-breakpoint(smdesktop){ width: 60%;}
            @include general-breakpoint(desktop){width: 70%;}

            h2{@include interstate(bold); font-size: 16px; line-height: 20px; text-transform: uppercase;color: $white; text-align: center; margin-bottom: 0px;padding: 5px 0px;
              @include general-breakpoint(tablet){ font-size: 20px; line-height: 28px; }
            }
            h3{display: none;}
            p{ @include interstate(light); color: $white; background-color: $usoDkBlue; border: 1px solid $white; border-radius: 5px; height: 150px;display: inline-block; text-align: center; vertical-align: middle; font-size: 14px; line-height: 20px; padding: 10px 10px 10px 20px;
              @include general-breakpoint(smtablet){ padding: 10px; padding-left: 30px;}
              @include general-breakpoint(smdesktop){ padding: 15px; padding-left: 40px; font-size: 14px; height: 260px;}
              @include general-breakpoint(desktop){ font-size: 18px; line-height: 24px; padding-left: 60px;}
            }
          }
        }
      }

      .siteDetailContainer { 
        background-color: $usoBkgGray; width: 100%;
        @include general-breakpoint(smtablet){ padding: 0px;}
        @include general-breakpoint(tablet){ padding: unset;}
        
        .siteDetail{ flex: 0 0 100%; max-width: 100%; padding-left: 0px; padding-right: 0px;
          p{ text-align: center; @include interstate(light); font-size: 14px; line-height: 20px; 
            @include general-breakpoint(smtablet){ font-size: 16px; line-height: 22px;}
            @include general-breakpoint(smdesktop){ font-size: 18px; line-height: 25px;}
          }
        }
      }

      .wrapped {
        background-color: $usoBkgGray;
        
        img {
          display: inline;
          vertical-align: top;
          @include general-breakpoint(landscape){
            width: 50%;
          }
          @include general-breakpoint(tablet){
            width: 33%;
          }
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
}