/** chinese-scoreboard styles **/

.chinese {
    background:transparent;
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    .site-wrap {width:100%; height:100%;}
    
    .router > div {width:100%; height:100%;
        > .outer-wrap {
            width:100%; height:100%;
            .page {width:100%; height:100%; display:table;}
        }
    }
    
}

.page-container {
    width:375px;
    height:160px;
    transition:all 10ms ease-in-out !important;
    position: relative;
    top: 0;
    display:table-cell;
    vertical-align: middle;
}
#scoreboard-container {
	position: relative;
    width:375px;
    height:160px;
    background: #FFFFFF;
    background-position: 0 bottom;
    background-size: cover;
    margin:0px auto;

    #mipFeatured {
        width: 100%;
        height:80%;
        padding: 0px 0px;
        position: relative;
    
        .eventinfo {
            width: 100%;
            height:25%;
            line-height: 2em;
            text-align: center;
            color: #000000;
            font-weight: bold; 
            text-transform: uppercase;
            white-space: nowrap;
            overflow:hidden;
            text-overflow: ellipsis;
            padding-left:65px;
            padding-right:65px;
            padding-top:14px;
            font-size:.8em;
            .titleSup {
                text-transform: none;
            }
        }
        
        
    
        .teaminfo {
            width: 100%;
            height:30%;
            display:flex;
            padding:0px 1.4%;
            flex-flow: row nowrap;
            &.teamOne {background:#2491da;}
            &.teamTwo {background:#ffd239;}
            .name {
                //color: #FFFFFF;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width:calc(60% - 5px);
                position:relative;
                display:table;
                height:100%;
                
                .displayName,.seed {display:table-cell; vertical-align: middle;}
                .seed {width:100%;}
                &.doubles {
                    position:relative;
                    top: 0%;
                    font-size:.8em;
                    line-height:1.3em;
                    padding-top:.7em;
                    display:unset;
                    .displayName, .seed{display:unset;}
                }
        
                a {
                    text-decoration: none;
                }
            }
            .scoreContainer {
                display:flex;
                flex-flow: row nowrap;
                position:relative;
                width:calc(40% + 5px);
                .courtIcon {
                    float:left;
                    width: 20%;
                    text-align: center;
                    i {display:none; }
                    &.currentServer {
                        color:black;
                        display:flex;
                        flex-direction: column;
                        justify-content: space-around;
                        .icon-ballSolid{display:block;} 
                    }
                    &.winner {
                        color:black;
                        display:flex;
                        flex-direction: column;
                        justify-content: space-around;
                        .icon-check{display:block;}
                    }
                }
        
                .pts {
                    position: relative;
                    width: 20%;
                    text-align: center;
                    overflow: hidden;
                    display:flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
                .latestScore {
                    .scores {
                        padding:5px;
                        border-radius:5px;
                        background:rgba(255,255,255,0.5);
                    }
                }
        
                .set1,.set2,.set3,.set4,.set5 {
                    position: relative;
                    width: calc(60% / 5);
                    text-align: center;
                    display:flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
        
                .scores {
                    font-size: 1.3em;
                    width: 100%;
                }
            }
            
        }
    
        .info {
            width:100%;
            display:flex;
            flex-flow: row nowrap;
            clear:both;
            height:15%;
            padding:0px 1.4%;
            span {position:relative; top:0px}
            #status {
                width: calc(60% - 5px);
                margin: 0px;
                text-align: left;
                color: #000000;
                font-size: .8em;
                text-transform: uppercase;
                position:relative; 
                display:flex;
                flex-direction: column;
                justify-content: space-around;
            }
           
            .scoreContainer {
                display:flex;
                flex-flow: row nowrap;
                position:relative;
                width:calc(40% + 5px);
                
                .pts {
                    float:left;
                    width: 40%;
                    text-align: center;
                    font-size: .9em; 
                    span {width:50%; display:block; float:right;}
                    padding-top:4px;
                }
                .set1, .set2, .set3, .set4, .set5 {
                    position: relative;
                    float:left;
                    width: calc(60% / 5);
                    text-align: center;
                    font-size: .9em;
                    padding-top:4px;
                }
            }
            
        }
        sup, sup.alt {
            font-size: .6em;
            color: #FFFFFF;
        }
        
    }
    
    #mipFeaturedNav {
        position: relative;
        z-index: 11;
        width:100%;
        height:20%;
        background:rgba(255,255,255,0);
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        
        .nav {
            margin: 0px auto;
            display:flex;
            justify-content: center;
        }
        .mip_prev {
            height: 100%;
            cursor: pointer;
            &:before {
                @include uso-icon('Icons');
                content:"\e901";
                color:black;
                position: relative;
                top:2px;
            }
        }
    
        .counter {
            //float: left;
            width: 60px;
            height: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 1.1em;
            color: #000000;   
        }
    
        .mip_next {
            height: 100%;
            //background: url('/assets/images/zh_CN/scoreboard/arrow_right.png') no-repeat 50% 50%;
            background-position:left;
            cursor: pointer;
            display:table-cell;
            &:before {
                @include uso-icon('Icons');
                content:"\e902";
                color:black;
                position: relative;
                top:2px;
            }
        }
    }
    
    /** featured match **/
    
    .eventLogo {
        position: absolute;
        top: 15px;
        left: 4px;
        width: 15%;
        height: auto;
        background-image: url('/assets/images/header/usopen-header-logo.png');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        z-index: 1;
    }
    
    .ibmLogo {
        position: absolute;
        right: 1%;
        bottom: 24%;
        width: 50px;
        height: 17px;
        background-repeat: no-repeat;
        background-image: url('/assets/images/zh_CN/scoreboard/ibmLogoBlue.png');
        background-position: right;
        background-size:contain;
    }
    
    #working {
        float: left;
        width: 325px !important;
        height: 142px !important;
        padding: 9px 25px;
        background: url('/assets/images/zh_CN/scoreboard/message_background.png') no-repeat;
        overflow: hidden;
        position: relative;
        display: none;
        
        #mipMessageDiv {
            color: #ffffff;
            font-size: 2.5em;
            text-align: center;
            padding: 20px;
            display: block;
        }
    }


}