.app_landing_content { width: 100%; overflow: hidden; max-width:1280px; margin:0px auto;
    // @include general-breakpoint(desktop) { width: $content_width; }
    h1, h2, h3, h4, h5 { color: $cyan;}
    h1 { font-size: calc((24/13) * 1em); padding-top: 15px; }
    h2 { font-size: calc((20/13) * 1em); padding-top: 15px; }
    h3 { font-size: calc((16/13) * 1em); padding-top: 15px;
      &.clear { clear: both; }
    } 
    div { font-size: calc((14/13) * 1em); }
    p { font-size: calc((14/13) * 1em); padding-top: 10px; padding-bottom: 10px; }
    a { color: $cyan; text-decoration: none; }

    .app_landing_container { @include interstate(light);
      // display: inline-block; 
      // float: left; 
      padding: 0px 10px; 
      width: 100%;
      margin:0px auto;
      @include general-breakpoint(tablet) { 
        width: calc(100% - 320px); padding-left: 15px; padding-right: 10px;
        &.chip { padding-left: 10px;
          #generalChips { margin: 0 -10px; width: calc(100% + 20px);}
        }
      }
      @include general-breakpoint(desktop) { 
        width:100%;
      }
      h3 { font-size: 32px; padding-top: 15px;}

      ul, ol { margin-left: 30px; font-size: 14px; line-height: 20px; }
      b, strong { @include interstate(bold); }
      hr { float: left; background-color: #c3c5c8; border: none; clear: both; color: #c3c5c8; height: 1px; margin: 20px 0; width: 100%;}
      .rightAlign { float: right; width: 50%;
        img { max-width: 100%; }
        &.gutter {
          @include general-breakpoint(tablet) {margin-left: 20px;}
        }
      }
      .leftAlign { float: left; width: 50%; margin: 0 20px 20px 0;
        img { max-width: 100%; }
      }
      .inline { display: block;
        h3 { display: block; }
      }
      .inlineImg { width: 100%; display: block; margin-top: 15px;
        img { float: none; max-width: 100%; }
      }
      @include general-breakpoint(landscape) {
        .inline { display: inline-block;
          h3 { display: inline-block; }
        }
        .inlineImg { width: auto; display: inline; float: left; margin-right: 20px;
          img { float: left; max-width: 100%; }
        }
      }
      .inlinePhoto{ width:100%; float: left; text-align: center;
        img{ width: 300px; height: auto; margin: 0 0 20px 0;
          @include general-breakpoint(landscape) { width: 610px; height: auto; }
        }
      }
      .link { margin-top: -5px; font-size: calc((14/13) * 1em);  padding-bottom: 10px;
        a { @include interstate(medium);
          &:after { content: '\00bb'; padding-left: 5px; }
        }
      }
      // .two-column-desktop { width: 100%; margin: 20px 0; padding: 0;
      //   @include general-breakpoint(tablet){ @include column-count(2); column-gap: 23px; }
      // }
    }
    /* mobile pages */
    .full_mobile_img{
      img{
        width: 100%;
      }
      display: flex;
      width: 100%;
      justify-content: center;
      align-items:center;
      text-align: center;     
    }
    .side_by_side, .orphen { width: 100%;
      @include general-breakpoint(tablet) {
        float: left; width: 50%;
      }
      .divice { width : 100%; text-align: center;
        img { max-width: 100%; margin: 0 auto; }
      }
      .ftr { margin: 0 20px; text-align: center;
        img { width: 150px; max-width: 100%; }
      }
      img { text-align: center; max-width: 100%; }
    }
    .orphen  { width: 100%;
      @include general-breakpoint(tablet) { width: 50%; margin: 0 auto; float: none; padding-top: 30px; overflow: hidden; }
    }
    .appDetails {
      .divice { width: 100%;  text-align: center; padding-top: 20px;

        @include general-breakpoint(tablet) { 
          width : 28%; float: left; 
      }
        img { max-width: 80%; margin: 0 auto; }
      }

      .divice-full { width: 100%;  text-align: center;
        @include general-breakpoint(tablet) { width : 28%; float: left; }
        img { max-width: 100%; margin: 0; }
      }
      .desc { width: 100%; float: left; padding-top: 20px;
        @include general-breakpoint(tablet) { width: 72%; }
      }
      .ftr { margin: 0 20px; text-align: center;
        img { width: 150px; max-width: 100%; }
      }
    }
    .denote { font-size: 12px; }

    .q { font-size: calc((18/14) * 1em); padding-top: 20px; }
    .a {
      p { padding-top: 0; margin-left:17px; text-indent:-15px;
        &:before { content: ' \25CF'; font-size: 10px; padding-right: 20px; }
      }
    }
  }